import React, { useState, useEffect, useRef } from "react";

import "react-table/react-table.css";
import { withRouter } from "react-router";
import GoalTrackingService from "../../api/GoalTrackingService";
import Toaster from "../common/Toaster";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import PageWrapper from "./foodtracking.style";
import moment from "moment";
import { Popover, PopoverBody } from "reactstrap";
import CommonModel from "../common/CommonModel";
import EditLongTerm from "./EditLongTerm";
import EditShortTerm from "./EditShortTerm";
import { hasAccess } from "util/sidebar";
import { MODULE_NAMES, PERMISSION_TYPE } from "helper/constant";

const GoalTracking = props => {
  let { result, permissions } = props;
  const hasParam = props.match.params.id;

  const [goalTrackingData, setGoalTrackingData] = useState(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const toaster = useRef();
  const [longModal, setLongModal] = useState(false);
  const [shortModal, setShortModal] = useState(false);
  const [editGoalData, setEditGoalData] = useState("");

  useEffect(() => {
    if (result.goal_trackings) {
      setGoalTrackingData(result.goal_trackings[0]);
    }
  }, []);

  const handleTick = (data, check) => {
    GoalTrackingService.trackShortTermGoal({
      user_id: hasParam,
      goal_tracking_id: data.id,
      is_set: check ? 1 : 0
    })
      .then(result => {
        let data = EncryptDecrypt.decrypt(result.data);
        if (data.status) {
          setGoalTrackingData(data.data);
          //props.pageRefresh();
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current) toaster.current.error(err?.message);
        }
        console.log("handleTick -> error", err);
      });
  };

  const changeDate = (goal_tracking_id, is_forward) => {
    GoalTrackingService.goalTrackingDetail({
      goal_tracking_id: goal_tracking_id,
      is_forward: is_forward
    })
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setGoalTrackingData(data.data);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(error => {
        console.log("handleTick -> error", error);
      });
  };

  const deleteGoal = goal_id => {
    GoalTrackingService.deleteGoal({
      user_goal_id: goal_id
    })
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current) toaster.current.success(data.message);
          setTimeout(() => {
            props.pageRefresh();
          }, 1500);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(error => {
        console.log("handleTick -> error", error);
      });
  };

  const archiveGoal = goal_id => {
    GoalTrackingService.achiveGoal({
      user_goal_id: goal_id
    })
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current) toaster.current.success(data.message);
          setTimeout(() => {
            props.pageRefresh();
          }, 1500);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(error => {
        console.log("handleTick -> error", error);
      });
  };

  const editGoal = goal => {
    if (props.type === "short") {
      setShortModal(true);
    } else {
      setLongModal(true);
    }
    setEditGoalData(goal);
  };

  function toggle1() {
    setLongModal(false);
    props.pageRefresh();
  }

  function toggle2() {
    setShortModal(false);
    props.pageRefresh();
  }

  return (
    <PageWrapper>
      <div>
        <div className={"setbox"}>
          <div className="d-flex">
            <div style={{ width: "98%" }}>
              <h6 className="p-2">{result.goal_sentence}</h6>
            </div>
            <div
              className="text-right"
              style={{ width: "2%", cursor: "pointer" }}
            >
              <i
                className="fas fa-ellipsis-v fs-16"
                id={`${"goal" + result.id}`}
              ></i>
            </div>
          </div>
          <Popover
            className="roy-menu"
            innerClassName="roy-inner-content"
            placement="bottom-end"
            target={`${"goal" + result.id}`}
            trigger="legacy"
            isOpen={popoverOpen}
            toggle={() => setPopoverOpen(!popoverOpen)}
          >
            <PopoverBody onClick={() => setPopoverOpen(!popoverOpen)}>
              {
                <div>
                  {hasAccess(
                    MODULE_NAMES.CLIENT_TRACKING,
                    PERMISSION_TYPE.EDIT,
                    permissions
                  ) && (
                    <div
                      className="roy-menu-list"
                      onClick={() => editGoal(result)}
                    >
                      Edit
                    </div>
                  )}
                  {hasAccess(
                    MODULE_NAMES.CLIENT_TRACKING,
                    PERMISSION_TYPE.DELETE,
                    permissions
                  ) && (
                    <div
                      className="roy-menu-list"
                      onClick={() => deleteGoal(result.id)}
                    >
                      Delete
                    </div>
                  )}
                  <div
                    className="roy-menu-list"
                    onClick={() => archiveGoal(result.id)}
                  >
                    Archive
                  </div>
                </div>
              }
            </PopoverBody>
          </Popover>
          {props.type === "short" && (
            <div>
              <div className="d-flex justify-content-around date-box">
                <div>
                  {goalTrackingData &&
                    moment(result.created_at).format("YYYY-MM-DD") <
                      goalTrackingData.start_date && (
                      <div onClick={() => changeDate(goalTrackingData.id, 0)}>
                        <i
                          className="fas fa-arrow-left"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </div>
                    )}
                </div>
                {}{" "}
                <div>
                  {goalTrackingData &&
                    result.timeframe === "Day" &&
                    moment(goalTrackingData.start_date).format("MM/DD/YYYY")}
                  {goalTrackingData &&
                    result.timeframe !== "Day" &&
                    moment(goalTrackingData.start_date).format("MM/DD/YYYY") +
                      "-" +
                      moment(goalTrackingData.end_date).format("MM/DD/YYYY")}
                </div>
                <div>
                  {goalTrackingData &&
                    result.timeframe === "Day" &&
                    moment().format("YYYY-MM-DD") >
                      goalTrackingData.start_date && (
                      <div onClick={() => changeDate(goalTrackingData.id, 1)}>
                        <i
                          className="fas fa-arrow-right"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </div>
                    )}
                  {goalTrackingData &&
                    result.timeframe !== "Day" &&
                    moment().format("YYYY-MM-DD") >
                      goalTrackingData.end_date && (
                      <div onClick={() => changeDate(goalTrackingData.id, 1)}>
                        <i
                          className="fas fa-arrow-right"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </div>
                    )}
                </div>
              </div>
              <div className="d-flex mt-3 w-100" style={{ overflow: "scroll" }}>
                {goalTrackingData &&
                  goalTrackingData.display_used_quantity > 0 &&
                  Array.apply(
                    0,
                    Array(goalTrackingData.display_used_quantity)
                  ).map(data => {
                    return (
                      <div
                        className="mr-3 mb-2"
                        onClick={() => handleTick(goalTrackingData, false)}
                      >
                        <i
                          className="far fa-check-circle bg-color"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </div>
                    );
                  })}
                {goalTrackingData &&
                  goalTrackingData.display_total_quantity -
                    goalTrackingData.display_used_quantity >
                    0 &&
                  Array.apply(
                    0,
                    Array(
                      goalTrackingData.display_total_quantity -
                        goalTrackingData.display_used_quantity
                    )
                  ).map(data => {
                    return (
                      <div
                        className="mr-3 mb-2"
                        onClick={() => {
                          handleTick(goalTrackingData, true);
                        }}
                      >
                        <i
                          className="far fa-check-circle no-bg-color"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
          {props.type === "long" && (
            <div className="d-flex justify-content-around date-box">
              {moment(result.created_at).format("MM/DD/YYYY")}
            </div>
          )}
        </div>
      </div>
      <Toaster ref={toaster} />
      {longModal && (
        <CommonModel
          modal={longModal}
          toggle1={toggle1}
          size="lg"
          children={<EditLongTerm toggle1={toggle1} goalData={editGoalData} />}
        />
      )}
      {shortModal && (
        <CommonModel
          modal={shortModal}
          toggle2={toggle2}
          size="lg"
          children={<EditShortTerm toggle2={toggle2} goalData={editGoalData} />}
        />
      )}
    </PageWrapper>
  );
};

export default withRouter(GoalTracking);
