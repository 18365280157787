import React from "react";

const SharedUserDetail = props => {
  let { user, onSubmit } = props;
  return (
    <div
      style={{
        padding: "5px 10px",
        background: "#aece38",
        color: "#fff",
        margin: "5px",
        borderRadius: "10px"
      }}
    >
      {user.first_name + " " + user.last_name + " [" + user.id + "]"}
      <span className="ml-2 cursor-pointer" onClick={() => onSubmit(user.id)}>
        <i className="fas fa-times" />
      </span>
    </div>
  );
};

export default SharedUserDetail;
