import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    visit_note: Yup.string()
      .trim()
      .notRequired(),
    service_start_time: Yup.string()
      .required("This field is required")
      .nullable(),
    service_end_time: Yup.string()
      .required("This field is required")
      .nullable(),
    method_of_service: Yup.string().required("This field is required"),
    payment_method: Yup.string().required("This field is required"),
    billable_unit: Yup.string().required("This field is required"),
    service_location_id: Yup.string().required("This field is required"),
    cpt_code: Yup.string().when("payment_method", result => {
      if (result == "Insurance Funded" || result == "Client Funded") {
        return Yup.string()
          .required("This field is required")
          .nullable();
      } else {
        return Yup.string().nullable();
      }
    }),
    type_of_service: Yup.string().when("payment_method", result => {
      if (result == "Insurance Funded" || result == "Client Funded") {
        return Yup.string()
          .required("This field is required")
          .nullable();
      } else {
        return Yup.string().nullable();
      }
    }),
    client_pos: Yup.string().when("payment_method", result => {
      if (result == "Insurance Funded" || result == "Client Funded") {
        return Yup.string()
          .required("This field is required")
          .nullable();
      } else {
        return Yup.string().nullable();
      }
    }),
    state: Yup.number().required("This field is required"),
    // diagnose: Yup.array().when("payment_method", result => {
    //   if (result == "Insurance Funded") {
    //     return Yup.array().required("This field is required");
    //   }
    // }),
    primary_diagnosis_code_id: Yup.array().when("payment_method", result => {
      if (result == "Insurance Funded" || result == "Client Funded") {
        return Yup.string()
          .required("This field is required")
          .nullable();
      } else {
        return Yup.string().nullable();
      }
    })
  }),
  mapPropsToValues: props => {
    return {
      id:
        props.editedData && props.editedData.action === "edit"
          ? props.editedData && props.editedData.id
          : "",
      visit_notes:
        props.editedData &&
        props.editedData.action === "edit" &&
        props.editedData.visit_notes.length > 0
          ? props.editedData.visit_notes[
              props.editedData.visit_notes.length - 1
            ].visit_note
          : "",
      method_of_service:
        props.editedData && props.editedData.action === "edit"
          ? props.editedData.visit_bill &&
            props.editedData.visit_bill.method_of_services
          : "",
      type_of_service:
        props.editedData && props.editedData.action === "edit"
          ? props.editedData.visit_bill &&
            props.editedData.visit_bill.type_of_service
          : "",
      service_start_time:
        props.editedData && props.editedData.action === "edit"
          ? props.editedData.visit_bill &&
            props.editedData.visit_bill.service_start_time
          : "",
      service_end_time:
        props.editedData && props.editedData.action === "edit"
          ? props.editedData.visit_bill &&
            props.editedData.visit_bill.service_end_time
          : "",
      payment_method:
        props.editedData && props.editedData.action === "edit"
          ? props &&
            props.editedData.visit_bill &&
            props.editedData.visit_bill.payment_method
          : "",
      billable_unit:
        props.editedData && props.editedData.action === "edit"
          ? props &&
            props.editedData.visit_bill &&
            props.editedData.visit_bill.billable_unit
          : "",
      cpt_code:
        props.editedData && props.editedData.action === "edit"
          ? props.editedData.visit_bill &&
            props.editedData.visit_bill.cpt_code &&
            props.editedData.visit_bill.cpt_code.id &&
            props.editedData.visit_bill.cpt_code.id
          : "",
      state:
        props.editedData && props.editedData.action === "edit"
          ? props.editedData.visit_bill &&
            props.editedData.visit_bill.state &&
            props.editedData.visit_bill.state.id
          : "",
      diagnose:
        props.editedData && props.editedData.action === "edit"
          ? props.editedData.visit_bill &&
            props.editedData.visit_bill.visit_bills_diagnoses
          : [],
      client_pos:
        props.editedData && props.editedData.action === "edit"
          ? props.editedData.visit_bill &&
            props.editedData.visit_bill.client_pos
          : "",
      notes_for_medical_biller:
        props.editedData && props.editedData.action === "edit"
          ? props.editedData.visit_bill &&
            props.editedData.visit_bill.notes_for_medical_biller
          : "",
      service_location_id:
        props.editedData && props.editedData.action === "edit"
          ? props.editedData.visit_bill &&
            props.editedData.visit_bill.service_location_id
          : "",
      primary_diagnosis_code_id:
        props.editedData && props.editedData.action === "edit"
          ? props.editedData.visit_bill &&
            props.editedData.visit_bill.primary_diagnosis_code_id
          : ""
    };
  },
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true,
  isInitialValid: props =>
    props.editedData && props.editedData.action === "edit" ? true : false
});

export default formikEnhancer;
