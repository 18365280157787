import styled from "styled-components";

const PageWrapper = styled.div`
  .ReactTable .rt-thead .rt-tr {
    text-align: left;
  }
  .food-container {
    display: flex;
    flex-wrap: wrap;
  }
  .comment-list {
    height: 500px;
    overflow: scroll;
  }
  .food-container .food-img {
    width: 100%;
    height: 210px;
  }
  .food-container .food-box {
    background-color: #80808014;
  }
  .food-container .food-title {
    padding: 10px;
    text-align: center;
    font-size: 1.2rem;
  }
  .box-size {
    height: auto;
    width: 80%;
  }
  .module-header {
    display: flex;
    align-items: center;
  }

  .custom-react-table-theme-class {
    .rt-tbody {
      .rt-td {
        font-family: "muli-medium";
        color: #757575;
      }
    }
  }

  .Table__itemCount {
    font-size: 14px;
  }

  .Table__pagination {
    display: flex;
    justify-content: flex-end;
    padding: 20px 10px 0px;
  }

  .Table__pageButton {
    font-size: 18px;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: #757575 !important;
    margin: 0 5px;
  }

  .Table__pageButton:disabled {
    cursor: not-allowed;
    color: gray;
  }

  .Table__pageButton--active {
    font-weight: bold;
    background: #58585a !important;
    color: #fff !important;
    min-width: 30px;
    height: 30px;
    border-radius: 6px;
  }

  .tabl-search {
    &:focus {
      outline: 0;
    }
  }

  .back-icon {
    position: absolute;
    right: 30px;
    bottom: 27px;
    color: rgba(255, 255, 255, 0.85) !important;
  }

  .-sort-desc {
    box-shadow: none !important;
    &:before {
      content: "▼";
      float: right;
      margin-right: 14px;
      color: #58585a !important;
    }
  }

  .-sort-asc {
    box-shadow: none !important;
    &:before {
      content: "▲";
      float: right;
      margin-right: 14px;
      color: #58585a !important;
    }
  }
  .react-action-class {
    button {
      height: auto !important;
      width: auto !important;
    }
  }
  .profile-box {
    width: 8%;
    border-radius: 50%;
    height: 35px;
  }
  .tbl-loader {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    .lds-ring {
      div {
        width: 8px !important;
        height: 45px !important;
        margin: 0px !important;
        border-color: #5c258d transparent transparent transparent !important;
      }
    }
  }

  .food-img {
    height: 450px;
  }
  .comment-admin-box {
    background-color: #a1cfdc;
    padding: 10px;
    border-radius: 10px;
  }
  .comment-box {
    background-color: #e7e5e5;
    padding: 10px;
    border-radius: 10px;
  }
  .food-box-border {
    border: 1px solid #3e3a3a5c;
    border-radius: 5px;
    box-shadow: 1px 3px #c8c8c8;
    margin-right: 40px;
  }
  .cmnt-box-border {
    border: 1px solid #3e3a3a5c;
    border-radius: 5px;
    box-shadow: 1px 3px #c8c8c8;
    margin-right: 20px;
  }
  .close-arw {
    position: absolute;
    right: 5px;
    top: 0px;
  }
  .Card-3 {
    background: #aece38;
    height: 200px;
    width: 200px;
    cursor: pointer;
    color: white;
    font-size: 30px;
  }
  .Card-3:hover {
    opacity: 0.9;
  }
  .react-form-inputs {
    border-radius: 0px !important;
    font-size: 14px !important;
    border: 1px solid #b5a9a99e !important;
  }
  .bg-color {
    font-size: 2.4rem;
    color: green;
    background-color: #0080002b;
    border-radius: 50%;
  }
  .no-bg-color {
    font-size: 2.4rem;
    border-radius: 50%;
  }
  .date-box {
    background-color: #80808042;
  }
  .setbox {
    background-color: #80808014;
    padding: 10px;
    border-bottom: solid 1px #2e2c2c;
    width: 100%;
  }
  .long-setbox {
    background-color: #80808014;
    padding: 10px;
    border-bottom: solid 1px #2e2c2c;
    width: 100%;
    overflow: scroll;
  }
`;

export default PageWrapper;
