import axios from "axios";
import { baseUrl } from "../helper/constant";
import Header from "./Header";
import EncryptDecrypt from "./EncryptDecrypt";

export default class ServiceMangementServices {
  static list(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/service/list`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static add(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/service/add`, EncryptDecrypt.encrypt(params));
  }

  static edit(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/service/update`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static view(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/service/view`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static status(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/service/update-status`,
      EncryptDecrypt.encrypt(params)
    );
  }
}
