import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import RdServices from "../../api/RdServices";
import Loader from "../common/Loader";
import CommonModel from "../common/CommonModel";
import Toaster from "../common/Toaster";
import { MODULE_NAMES, PERMISSION_TYPE, TABLE } from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import moment from "moment";
import DeleteModal from "../common/DeleteModal";
import BusinessServiceAgreementModal from "./BusinessServiceAgreementModal";
import { hasAccess } from "util/sidebar";

const BusinessServiceAgreementTab = props => {
  const { permissions } = props;
  const [tblData, settblData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("created_at");
  const [sortBy, setSortBy] = useState("DESC");
  const [id, setId] = useState(undefined);
  const [modal, setModal] = useState(undefined);
  const [modal1, setModal1] = useState(undefined);
  const [editedData, setEditedData] = useState(undefined);

  const toaster = useRef();

  const hasParam = props.match.params.id;

  function toggle() {
    setModal(false);
  }

  function toggle1() {
    setModal1(false);
  }

  function handleDownload(presignedUrl, fileName) {
    const link = document.createElement("a");
    link.href = presignedUrl;
    link.download = fileName;
    link.target = "_blank";
    link.click();
  }

  const formAction = (action, data = null) => {
    // Here you can view the data and make forward action for edit data
    if (action === "edit") {
      setEditedData(data);
      setModal(true);
    } else if (action === "add") {
      setId(data);
      setModal(true);
      setEditedData(null);
    }
  };

  useEffect(() => {
    if (
      hasAccess(
        MODULE_NAMES.PROVIDER_MANAGEMENT_EDIT_BUSINESS_SERVICES_AGREEMENT,
        PERMISSION_TYPE.VIEW,
        permissions
      )
    ) {
      getList(field, sortBy, searchText, activePage + 1);
      setPages(10);
    }
  }, []);

  const submitFormHandler = () => {
    setTimeout(() => {
      setModal(false);
      getList(field, sortBy, searchText, activePage + 1);
    }, 2000);
  };

  const deleteClick = data => {
    setId(data.id);
    setModal1(true);
  };

  function deleteHandler() {
    setLoader(true);
    const params = {
      id: id
    };
    RdServices.businessAgreementDelete(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current) toaster.current.success(data.message);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
        setLoader(false);
        setModal1(false);
        setTimeout(() => {
          getList(field, sortBy, searchText, activePage + 1);
        }, 1000);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
        setLoader(false);
      });
  }

  const columns = [
    {
      Header: "ID",
      accessor: "id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 100,
      sortable: true
    },
    {
      Header: "File",
      accessor: "image",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 350,
      sortable: false
    },
    {
      Header: "Effective Date",
      accessor: "effective_date",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      Cell: props => moment(props.original.effective_date).format("MM/DD/YYYY"),
      width: 200,
      sortable: true
    },
    {
      Header: "Expire Date",
      accessor: "expire_date",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      Cell: props => moment(props.original.expire_date).format("MM/DD/YYYY"),
      width: 200,
      sortable: true
    },
    {
      Header: "Action",
      accessor: "action",
      className: "",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 300,
      Cell: colProps => (
        <div className="react-action-class">
          {hasAccess(
            MODULE_NAMES.PROVIDER_MANAGEMENT_EDIT_BUSINESS_SERVICES_AGREEMENT,
            PERMISSION_TYPE.EDIT,
            permissions
          ) && (
            <Button
              className="c-btn c-success mr-10"
              onClick={() => formAction("edit", colProps.original)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-edit" />
              </div>
            </Button>
          )}
          {hasAccess(
            MODULE_NAMES.PROVIDER_MANAGEMENT_EDIT_BUSINESS_SERVICES_AGREEMENT,
            PERMISSION_TYPE.DELETE,
            permissions
          ) && (
            <Button
              className="c-btn c-danger"
              onClick={() => deleteClick(colProps.original)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-trash" />
              </div>
            </Button>
          )}
          <Button
            className="c-btn c-info"
            onClick={() =>
              handleDownload(
                colProps.original.image_url,
                colProps.original.image
              )
            }
          >
            <div className="fs-14 medium-text">
              <i className="fas fa-download" />
            </div>
          </Button>
        </div>
      ) // Custom cell components!
    }
  ];

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  function getList(
    field = "created_at",
    sortBy = "DESC",
    search = "",
    page = "1"
  ) {
    const params = {
      sort_by: field,
      order: sortBy,
      search: search,
      page: page,
      rd_id: hasParam
    };
    setLoader(true);
    RdServices.businessServiceAgreementList(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count / TABLE.LIMIT));
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
          setLoader(false);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
        setLoader(false);
      });
  }

  return (
    <div>
      <div className="module-header">
        <div className="flex-1 fs-16 demi-bold-text"></div>
        {!loader && !tblData.length && (
          <div>
            {hasAccess(
              MODULE_NAMES.PROVIDER_MANAGEMENT_EDIT_BUSINESS_SERVICES_AGREEMENT,
              PERMISSION_TYPE.ADD,
              permissions
            ) && (
              <Button
                className="c-btn c-primary ma-5"
                onClick={() => formAction("add")}
              >
                <i className="fas fa-plus mr-10" />
                Add Business Services Agreement
              </Button>
            )}
          </div>
        )}
      </div>
      <ReactTable
        style={{
          border: "none",
          boxShadow: "none"
        }}
        data={tblData}
        columns={columns}
        defaultPageSize={10}
        minRows={2}
        manual
        defaultFilterMethod={(filter, row) => {
          const id = filter.pivotId || filter.id;

          return row[id] !== undefined
            ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
            : true;
        }}
        className="-striped -highlight custom-react-table-theme-class"
        pages={pages}
        page={activePage}
        PaginationComponent={Pagination}
        loading={loader}
        LoadingComponent={Loader}
        onFetchData={onChangePageGetData}
        onPageChange={pageIndex => {
          setActivePage(pageIndex);
          getList(field, sortBy, searchText, pageIndex + 1);
        }}
        onSortedChange={sortProperties => {
          const sort = sortProperties[0].desc ? "DESC" : "ASC";
          setSortBy(sort);
          setActivePage(0);
          setField(sortProperties[0].id);
          getList(sortProperties[0].id, sort, searchText, 1);
          // alert("sort handler", sortProperties);
          // const orderByDirection = sortProperties[0].desc ? 1 : 0;

          // updateMapStateInfoOrderByColumn(sortProperties[0].id, orderByDirection);
        }}
      />
      <Toaster ref={toaster} />
      <CommonModel
        modal={modal}
        toggle={toggle}
        children={
          <BusinessServiceAgreementModal
            onSubmit={submitFormHandler}
            editedData={editedData}
            action={editedData ? "edit" : "add"}
            toggle={toggle}
            {...props}
            pageRefresh={() => {
              getList(field, sortBy, searchText, activePage + 1);
              setPages(10);
            }}
          />
        }
        header={
          editedData
            ? "Edit Business Services Agreement"
            : "Add Business Services Agreement"
        }
      />
      <CommonModel
        modal={modal1}
        toggle1={toggle1}
        children={
          <DeleteModal
            text={"Are you sure you want to delete this business agreement?"}
            deleteHandler={deleteHandler}
            toggle={toggle1}
          />
        }
      />
    </div>
  );
};
export default withRouter(BusinessServiceAgreementTab);
