import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import Loader from "../common/Loader";
import Toaster from "../common/Toaster";
import { PROVIDER_TYPE, TABLE } from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import moment from "moment-timezone";
import Button from "components/button/Button";
import CommonModel from "../common/CommonModel";
import { DateRangePicker } from "materialui-daterange-picker";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
import ClientServices from "../../api/ClientServices";
import FinancialReportServices from "api/FinancialReportServices";

const ROLE_SERVICE_ID = {
  4: 1,
  5: 2
};

const FinancialReportViewTable = props => {
  const toaster = useRef(null);
  const [tblData, settblData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [field, setField] = useState("created_at");
  const [sortBy, setSortBy] = useState("DESC");
  const [dateRange, setDateRange] = useState({});
  const [modal, setModal] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [RDDropdownOpen, setRDDropdownOpen] = useState(false);
  const [RDId, setRDId] = useState(null);
  const [RDName, setRDName] = useState(null);
  const [RDList, setRDList] = useState([]);
  useEffect(() => {
    if (props.auth && props.auth.admin_role_id !== 3) {
      const params1 = {
        type: "",
        practice_id:
          props.auth && props.auth?.admin_role_id === 6 && props.auth?.practice
            ? props.auth?.practice
            : undefined,
        id:
          props.auth &&
          (props.auth?.admin_role_id === 4 || props.auth?.admin_role_id === 5)
            ? ROLE_SERVICE_ID[props.auth?.admin_role_id]
            : undefined
      };
      ClientServices.RDList(params1).then(result => {
        let data = EncryptDecrypt.decrypt(result.data);
        setRDList(data.data);
      });
    }
    setRDId(
      props.auth && props.auth.admin_role_id !== 3 ? null : props?.auth?.id
    );
  }, []);

  useEffect(() => {
    getList(field, sortBy, "", activePage + 1, "");
    setPages(10);
  }, [RDId, dateRange.startDate, dateRange.endDate]);

  const toggle = () => {
    setOpen(!open);
    setModal(!open);
  };

  const viewAction = val => {
    props.history.push(`/financial-report/view/${val?.uploaded_csv_id}`);
  };

  async function handleDownload(uploaded_csv_id) {
    const params = {
      uploaded_csv_id: uploaded_csv_id
    };
    try {
      await FinancialReportServices.download(params);
    } catch (error) {
      if (toaster.current) toaster.current.error("Something went wrong!");
    }
  }

  const columns = [
    {
      Header: "Uploaded Date",
      accessor: "created_at",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: false,
      width: 232,
      sortable: true,
      Cell: ({ value }) =>
        value ? <div>{moment(value).format("MM/DD/YYYY")}</div> : <div>-</div>
    },
    {
      Header: "Uploaded By",
      accessor: "uploader_details",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 232,
      Cell: ({ value }) =>
        value ? (
          <div>{value?.first_name + " " + value?.last_name}</div>
        ) : (
          <div>-</div>
        )
    },
    {
      Header: "Provider",
      accessor: "admin",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 232,
      Cell: ({ value }) =>
        value ? (
          <div>{value?.first_name + " " + value?.last_name}</div>
        ) : (
          <div>-</div>
        )
    },
    {
      Header: "Service",
      accessor: "admin.rd_detail.service.name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 180
    },
    {
      Header: "Action",
      accessor: "action",
      className: "",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 300,
      Cell: props => (
        <div className="react-action-class">
          <Button
            className="c-btn c-info mr-10"
            onClick={() => viewAction(props.original)}
          >
            <div className="fs-14 medium-text" title="View">
              <i className="fas fa-eye" />
            </div>
          </Button>

          <Button
            className="c-btn c-info mr-10"
            onClick={() =>
              handleDownload(
                // props?.original?.id,
                props?.original?.uploaded_csv_id
              )
            }
          >
            <div className="fs-14 medium-text" title="Download">
              <i className="fas fa-download" />
            </div>
          </Button>
        </div>
      ) // Custom cell components!
    }
  ];

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  const SORT_BY_KEYS = {
    "admin.rd_detail.service.name": "service_id",
    uploader_details: "uploader_full_name",
    admin: "admin_full_name",
    created_at: "created_at"
  };

  function getList(field = "", sortBy = "ASC", search = "", page = "1") {
    const params = {
      sort_by: SORT_BY_KEYS[field],
      order: sortBy,
      search: search,
      page: page,
      start_date: dateRange.startDate
        ? moment(dateRange.startDate).format("YYYY-MM-DD")
        : null,
      end_date: dateRange.endDate
        ? moment(dateRange.endDate).format("YYYY-MM-DD")
        : null,
      rd_id:
        props?.auth && props?.auth?.admin_role_id !== 3
          ? RDId
          : props?.auth?.id,
      practice_id:
        props?.auth?.admin_role_id === 6 && props?.auth?.practice
          ? props?.auth?.practice
          : undefined,
      service_id:
        props.auth &&
        (props.auth?.admin_role_id === 4 || props.auth?.admin_role_id === 5)
          ? ROLE_SERVICE_ID[props.auth?.admin_role_id]
          : undefined
    };

    setLoader(true);
    FinancialReportServices.list(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);

        if (data?.status) {
          settblData(data?.data?.rows);
          setPages(Math.ceil(data?.data?.count / TABLE.LIMIT));
        } else {
          if (toaster.current) toaster.current.error(data?.message);
        }
        setLoader(false);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err?.message);
        setLoader(false);
      });
  }

  const toggleRD = () => setRDDropdownOpen(prevState => !prevState);

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Financial Report
            </div>
            {props.auth && props.auth.admin_role_id !== 3 && (
              <>
                <div className="fs-14  mr-10">
                  <Dropdown
                    isOpen={RDDropdownOpen}
                    toggle={toggleRD}
                    disabled={!RDList.length}
                    title="Please select service first!"
                  >
                    <DropdownToggle
                      caret
                      size="md"
                      className={!RDList.length && "cursor-not-allowed"}
                    >
                      {RDName ? RDName : "Providers"}
                    </DropdownToggle>
                    <DropdownMenu
                      modifiers={{
                        setMaxHeight: {
                          enabled: true,
                          order: 890,
                          fn: data => {
                            return {
                              ...data,
                              styles: {
                                ...data.styles,
                                overflow: "auto",
                                maxHeight: "300px"
                              }
                            };
                          }
                        }
                      }}
                    >
                      <DropdownItem
                        key="-1"
                        onClick={() => {
                          setActivePage(0);
                          setRDId("");
                          setRDName("All");
                        }}
                      >
                        All
                      </DropdownItem>
                      {RDList.length > 0 &&
                        RDList.map((result, i) => {
                          return (
                            <DropdownItem
                              key={i + 1}
                              onClick={() => {
                                setActivePage(0);
                                setRDId(result.id);
                                setRDName(
                                  `${result.first_name} ${result.last_name}`
                                );
                              }}
                            >
                              {result.first_name} {result.last_name}{" "}
                              {result?.rd_detail?.type
                                ? `(${PROVIDER_TYPE[result?.rd_detail?.type]})`
                                : "(-)"}
                            </DropdownItem>
                          );
                        })}
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </>
            )}
          </div>
          <div className="roe-card-header module-header">
            <div className="d-flex">
              <div className="mr-10">
                <span className="fs-16">Date</span>
                <br></br>
                <Button
                  className="btn btn-secondary btn-md"
                  onClick={() => toggle()}
                >
                  {dateRange.startDate
                    ? `${moment(dateRange.startDate).format(
                        "MM/DD/YYYY"
                      )} - ${moment(dateRange.endDate).format("MM/DD/YYYY")}`
                    : "Select Date"}
                </Button>
                {dateRange && dateRange.startDate && (
                  <Button
                    className="btn btn-secondary btn-md ml-2"
                    onClick={() => {
                      setActivePage(0);
                      setDateRange({});
                    }}
                  >
                    Reset date
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              LoadingComponent={Loader}
              onFetchData={onChangePageGetData}
              onPageChange={pageIndex => {
                setActivePage(pageIndex);
                getList(field, sortBy, "", pageIndex + 1, "");
              }}
              onSortedChange={sortProperties => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getList(sortProperties[0].id, sort, "", 1, "");
                // alert("sort handler", sortProperties);
                // const orderByDirection = sortProperties[0].desc ? 1 : 0;

                // updateMapStateInfoOrderByColumn(sortProperties[0].id, orderByDirection);
              }}
            />
          </div>
          <CommonModel
            modal={modal}
            toggle={toggle}
            size="lg"
            children={
              <DateRangePicker
                open={open}
                toggle={() => toggle()}
                value={[dateRange.startDate, dateRange.endDate]}
                onChange={range => {
                  setActivePage(0);
                  setDateRange(range);
                  // setModal(!modal)
                  toggle();
                }}
                closeOnClickOutside={true}
              />
            }
            // header={editedData ? "" : "Add Registration Code"}
          />
        </div>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};

export default withRouter(FinancialReportViewTable);
