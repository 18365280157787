import { ANS_KEYS, FORMS_KEYS } from "helper/constant";
import React from "react";

const FormElement = ({ question, answer }) => {
  return (
    <div className="p-2 ">
      <div className="fs-16 demi-bold-text">{question}</div>
      <div>
        <i className="fas fa-arrow-right pr-1"></i>
        {answer ? answer : "N/A"}
      </div>
    </div>
  );
};

const PhqForm = props => {
  return (
    <div>
      <div className="fs-18 bold-text pb-2">PHQ-9</div>
      <FormElement
        question={FORMS_KEYS[3]["little_interest"]}
        answer={ANS_KEYS[props?.data?.little_interest]}
      />
      <FormElement
        question={FORMS_KEYS[3]["feeling_down"]}
        answer={ANS_KEYS[props?.data?.feeling_down]}
      />
      <FormElement
        question={FORMS_KEYS[3]["trouble_falling_asleep"]}
        answer={ANS_KEYS[props?.data?.trouble_falling_asleep]}
      />
      <FormElement
        question={FORMS_KEYS[3]["feeling_tired"]}
        answer={ANS_KEYS[props?.data?.feeling_tired]}
      />
      <FormElement
        question={FORMS_KEYS[3]["poor_appetite"]}
        answer={ANS_KEYS[props?.data?.poor_appetite]}
      />
      <FormElement
        question={FORMS_KEYS[3]["feeling_bad_about_yourself"]}
        answer={ANS_KEYS[props?.data?.feeling_bad_about_yourself]}
      />
      <FormElement
        question={FORMS_KEYS[3]["trouble_concentrating"]}
        answer={ANS_KEYS[props?.data?.trouble_concentrating]}
      />
      <FormElement
        question={FORMS_KEYS[3]["moving_or_speaking"]}
        answer={ANS_KEYS[props?.data?.moving_or_speaking]}
      />
      <FormElement
        question={FORMS_KEYS[3]["better_off_dead"]}
        answer={ANS_KEYS[props?.data?.better_off_dead]}
      />
      <FormElement
        question={FORMS_KEYS[3]["how_difficult_those_problems"]}
        answer={props?.data?.how_difficult_those_problems.map((data, i) => {
          return (
            <span>
              {ANS_KEYS[data]}
              {i !== props?.data?.how_difficult_those_problems?.length - 1 &&
                ", "}
            </span>
          );
        })}
      />
    </div>
  );
};

export default PhqForm;
