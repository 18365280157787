import axios from "axios";
import { baseUrl } from "../helper/constant";
import Header from "./Header";
import EncryptDecrypt from "./EncryptDecrypt";
import fileDownload from "js-file-download";

const LOOKUP_URLS = {
  INSURANCE: "advancemd/insurance-lookup",
  PROVIDER: "advancemd/rd-lookup",
  INSURANCE_EX: "advancemd/insurance-lookup-csv",
  PROVIDER_EX: "advancemd/rd-lookup-csv",
  SERVICE_LOC: "advancemd/service-location-lookup",
  SERVICE_LOC_EX: "advancemd/service-location-lookup-csv",
  CPT: "advancemd/cpt-lookup",
  CPT_EX: "advancemd/cpt-lookup-csv",
  DIAGNOSIS: "advancemd/diagnoses-lookup",
  DIAGNOSIS_EX: "advancemd/diagnoses-lookup-csv"
};

const LOOKUP_FILE_NAME = {
  PROVIDER_EX: "ProviderLookup.csv",
  DIAGNOSIS_EX: "DiagnosisLookup.csv",
  SERVICE_LOC_EX: "ServiceLocationLookup.csv",
  CPT_EX: "CPTLookup.csv"
};

export default class CommonServices {
  static getCountryCode() {
    Header.setHeaders();
    return axios
      .get(`${baseUrl}/user/country_code/list`)
      .then(countryCodes => countryCodes.data);
  }

  static getCategoryList(id) {
    Header.setHeaders();
    return axios
      .post(`${baseUrl}/category/dropdown`, { id })
      .then(countryCodes => countryCodes.data);
  }

  static getUsers() {
    Header.setHeaders();
    return axios
      .get(`${baseUrl}/user/dropdown`)
      .then(countryCodes => countryCodes.data);
  }

  static countrycitystatedropdown(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/country/dropdown`, params);
  }

  // static googleAutoComplete(searchterm) {
  //   var config = {
  //     "Remote Address": "216.58.199.138:443"
  //   };
  //   return axios.get(
  //     `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${searchterm}&key=AIzaSyATWjRZ-Oz07UBNdE7e6OaL87eWsi6Ig2I&sessiontoken=1234567890`,
  //     config
  //   );
  // }

  static dashboardCount(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/admin/dashboard`, params);
  }

  static rdDashboardCount(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/rd/dashboard`, params);
  }

  static addVisitInAdvanceMd(params) {
    Header.setHeaders();
    return axios.get(`${baseUrl}/advancemd/add-visit-in-advancemd`);
  }

  static addInitialVisitInRd(params) {
    Header.setHeaders();
    return axios.get(`${baseUrl}/admin/add-event-type-rd`);
  }

  static reconnectRDCalendly(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/rd/reconnect-calendly`, params);
  }

  static getAwaySettings(params) {
    Header.setHeaders();
    return axios.get(`${baseUrl}/away-message/settings`);
  }

  static setAwaySettings(params) {
    Header.setHeaders();
    return axios.put(
      `${baseUrl}/away-message/edit-settings`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static commonLookupList(params, type) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/${LOOKUP_URLS[type]}`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static commonLookup(params, type) {
    Header.setHeaders();
    return axios
      .post(`${baseUrl}/${LOOKUP_URLS[type]}`, EncryptDecrypt.encrypt(params))
      .then(res => {
        fileDownload(res.data, LOOKUP_FILE_NAME[type]);
      });
  }

  static getEmailNotificationSettings() {
    Header.setHeaders();
    return axios.get(`${baseUrl}/provider-notification/status`);
  }

  static updateEmailNotificationSettings(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/provider-notification/change-status`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static getAccessPermissions() {
    Header.setHeaders();
    return axios.post(`${baseUrl}/roles-permission/get-access`);
  }

  static iboPracticeDropdownList(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/practice-management/list-all-practice`,
      EncryptDecrypt.encrypt(params)
    );
  }
}
