import moment from "moment-timezone";

export const getCalendlyStatusAndTime = info => {
  if (!info?.calendly_connected) {
    return {
      status: "Not Connected",
      time: utcToLocal(info?.calendly_connected_date),
      adminReconnect: false,
      rdCalendlyReConnect: true
    };
  }
  if (!info?.new_visit_webhook) {
    return {
      status: "Not Connected",
      time: utcToLocal(info?.new_visit_connected_date),
      adminReconnect: true,
      rdCalendlyReConnect: false
    };
  }
  if (!info?.cancel_visit_webhook) {
    return {
      status: "Not Connected",
      time: utcToLocal(info?.cancel_visit_connected_date),
      adminReconnect: true,
      rdCalendlyReConnect: false
    };
  }
  const times = [
    info?.new_visit_connected_date,
    info?.cancel_visit_connected_date,
    info?.calendly_connected_date
  ];
  let maxDate = times.reduce((a, b) => {
    return new Date(a.MeasureDate) > new Date(b.MeasureDate) ? b : a;
  });

  return {
    status: "Connected",
    time: utcToLocal(maxDate),
    adminReconnect: false,
    rdCalendlyReConnect: false
  };
};

const utcToLocal = date => {
  return moment.tz(date, moment.tz.guess()).format("MM/DD/YYYY hh:mm A");
};
