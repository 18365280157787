import React from "react";
import PageWrapper from "./category.style";
import { connect } from "react-redux";
import AppointmentScheduleDetail from "components/appointmentschedule/AppointmentScheduleDetail";
import PageTitle from "components/common/PageTitle";

const AppointmentViews = props => {
  return (
    <PageWrapper {...props}>
      <div className="pos-relative">
        <PageTitle
          props={props}
          title="sidebar.appointment_management"
          className="plr-15"
          breadCrumb={[
            {
              name: "sidebar.dashboard",
              url: "/dashboard"
            },
            {
              name: "sidebar.appointment_management",
              url: "/appointment"
            },
            {
              name: "action.view"
            }
          ]}
        />
        <div
          className="back-icon fs-15 demi-bold-text cursor-pointer"
          onClick={() => props.history.push("/appointment")}
        >
          <i className="fas fa-step-backward"></i> Back
        </div>
      </div>
      <div className="user-tbl">
        <AppointmentScheduleDetail {...props} />
      </div>
    </PageWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(AppointmentViews);
