import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    state: Yup.array().of(
      Yup.object().shape({
        state_id: Yup.string().required("This field is required"),
        valid_from: Yup.date()
          .required("This field is required")
          .nullable(),
        valid_to: Yup.date()
          .required("This field is required")
          .nullable()
          .min(Yup.ref("valid_from"), "Valid to can't be before Valid from")
      })
    )
  }),
  mapPropsToValues: props => ({
    state: []
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
