import axios from "axios";
import { baseUrl } from "../helper/constant";
import Header from "./Header";
import EncryptDecrypt from "./EncryptDecrypt";

export default class BioMetricsTracking {
  static list(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/tracking/list`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static edit(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/tracking/edit`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static view(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/tracking/view`,
      EncryptDecrypt.encrypt(params)
    );
  }
}
