import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import GroupServices from "../../api/GroupServices";
import Loader from "../common/Loader";
import CommonModel from "../common/CommonModel";
import RegistrationCodeModal from "../group/RegistrationCodeModal";
//import Moment from "react-moment";
import Toaster from "../common/Toaster";
import {
  MODULE_NAMES,
  PERMISSION_TYPE,
  SERVICE_TYPE,
  TABLE,
  USER_WEB_MH_URL,
  USER_WEB_URL
} from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import moment from "moment";
import { RD } from "helper/constant";
import { hasAccess } from "util/sidebar";

const RegistrationCodeTable = ({ service, ...props }) => {
  const { permissions } = props;
  const [tblData, settblData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("created_at");
  const [sortBy, setSortBy] = useState("DESC");
  const [id, setId] = useState(null);
  const [modal, setModal] = useState(false);
  const [editedData, setEditedData] = useState(null);

  const toaster = useRef();

  const hasParam = props.match.params.id;

  function toggle() {
    setModal(false);
  }

  const formAction = (action, data = null) => {
    // Here you can view the data and make forward action for edit data
    if (action === "edit") {
      setId(data.id);
      setModal(true);
      const params = {
        id: data.id
      };
      GroupServices.registrationCodeView(params).then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        setEditedData(data.data);
      });
    } else if (action === "add") {
      setId(data);
      setModal(true);
      setEditedData(null);
    }
  };

  useEffect(() => {
    if (
      hasAccess(MODULE_NAMES.GROUP_REG_CODES, PERMISSION_TYPE.VIEW, permissions)
    ) {
      getList(field, sortBy, searchText, activePage + 1);
      setPages(10);
    }
  }, []);

  const submitFormHandler = () => {
    setTimeout(() => {
      setModal(false);
      getList(field, sortBy, searchText, activePage + 1);
    }, 1500);
  };

  const activeInactiveStatusHandler = oData => {
    const params = {
      is_active: !oData.is_active,
      id: oData.id,
      group_id: hasParam
    };
    setLoader(true);
    GroupServices.registrationCodeStatus(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current)
            toaster.current.success(
              oData.is_active === 1
                ? "Code has been deactivated successfully."
                : "Code has been activated successfully."
            );
          getList(field, sortBy, searchText, activePage + 1, 1);
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
          setLoader(false);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
        setLoader(false);
      });
  };

  const copyText = data => {
    let text = document.getElementById(data.code);
    text.style.display = "block";
    text.select();
    try {
      let status = document.execCommand("Copy");
      if (!status) {
        console.log("Cannot copy text");
      } else {
        if (toaster.current) toaster.current.success("Copied!");
        console.log("Copied");
      }
    } catch (err) {
      console.log("error");
    }
    text.style.display = "none";
  };

  const copyURL = (data, service_id) => {
    let text = document.getElementById("url" + data.code + service_id);
    text.style.display = "block";
    text.select();
    try {
      let status = document.execCommand("Copy");
      if (!status) {
        console.log("Cannot copy text");
      } else {
        if (toaster.current) toaster.current.success("Copied!");
        console.log("Copied");
      }
    } catch (err) {
      console.log("error");
    }
    text.style.display = "none";
  };

  const columns = [
    {
      Header: "Code",
      accessor: "code",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 150,
      sortable: true
    },
    {
      Header: "Description",
      accessor: "description",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 200,
      sortable: true
    },
    {
      Header: "Registrations",
      accessor: "registrations",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 150,
      sortable: true
    },
    {
      Header: "Date Created",
      accessor: "created_at",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      Cell: props => moment(props.original.created_at).format("MM/DD/YYYY"),
      width: 150,
      sortable: true
    },
    {
      Header: "Status",
      accessor: "is_active",
      className: "",
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      filterable: false,
      width: 150,
      Cell: cellProps => (
        <div className="react-action-class">
          {props.authData.adminRoleId !== RD ? (
            cellProps.original.is_active ? (
              <div id={`active-${cellProps.original.state_id}`}>
                <Button
                  style={{ minWidth: "80px" }}
                  className="c-btn c-info mr-10"
                  onClick={() =>
                    activeInactiveStatusHandler(cellProps.original)
                  }
                  disabled={
                    !hasAccess(
                      MODULE_NAMES.GROUP_REG_CODES,
                      PERMISSION_TYPE.STATUS,
                      permissions
                    )
                  }
                >
                  <div className="fs-14 medium-text">
                    <i className="fas fa-toggle-off mr-6" /> Active
                  </div>
                </Button>
              </div>
            ) : (
              <div id={`deactive-${cellProps.original.state_id}`}>
                <Button
                  style={{ minWidth: "80px" }}
                  className="c-btn c-warning mr-10"
                  onClick={() =>
                    activeInactiveStatusHandler(cellProps.original)
                  }
                  disabled={
                    !hasAccess(
                      MODULE_NAMES.GROUP_REG_CODES,
                      PERMISSION_TYPE.STATUS,
                      permissions
                    )
                  }
                >
                  <div className="fs-14 medium-text">
                    <i className="fas fa-toggle-on mr-6" /> Inactive
                  </div>
                </Button>
              </div>
            )
          ) : cellProps.original.is_active ? (
            "Active"
          ) : (
            "Inactive"
          )}
        </div>
      ) // Custom cell components!
    },
    {
      Header: "Action",
      accessor: "action",
      className: "",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: service === SERVICE_TYPE.GENERAL ? 600 : 400,
      Cell: colProps => (
        <div className="react-action-class">
          {hasAccess(
            MODULE_NAMES.GROUP_REG_CODES,
            PERMISSION_TYPE.EDIT,
            permissions
          ) ? (
            <Button
              className="c-btn c-success mr-10"
              onClick={() => formAction("edit", colProps.original)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-edit" />
              </div>
            </Button>
          ) : (
            ""
          )}
          <Button
            className="c-btn c-success mr-10"
            onClick={() => copyText(colProps.original)}
          >
            <div className="fs-14 medium-text">
              <i className="fas fa-copy" /> Copy Code
            </div>
          </Button>
          <textarea
            id={colProps.original.code}
            style={{ display: "none" }}
            value={colProps.original.code}
          ></textarea>
          {service !== SERVICE_TYPE.GENERAL ? (
            <>
              <Button
                className="c-btn c-success mr-10"
                onClick={() => copyURL(colProps.original, service)}
              >
                <div className="fs-14 medium-text">
                  <i className="fas fa-copy" /> Copy URL
                </div>
              </Button>
              <textarea
                id={`url` + colProps.original.code + service}
                style={{ display: "none" }}
                value={`${
                  service === SERVICE_TYPE.NUTRITION
                    ? USER_WEB_URL
                    : USER_WEB_MH_URL
                }/register?code=${colProps.original.code}`}
              ></textarea>
            </>
          ) : (
            <>
              <Button
                className="c-btn c-success mr-10"
                onClick={() =>
                  copyURL(colProps.original, SERVICE_TYPE.NUTRITION)
                }
              >
                <div className="fs-14 medium-text">
                  <i className="fas fa-copy" /> Copy Nutrition URL
                </div>
              </Button>
              <textarea
                id={`url` + colProps.original.code + SERVICE_TYPE.NUTRITION}
                style={{ display: "none" }}
                value={
                  USER_WEB_URL + "/register?code=" + colProps.original.code
                }
              ></textarea>
              <Button
                className="c-btn c-success mr-10"
                onClick={() =>
                  copyURL(colProps.original, SERVICE_TYPE.MENTAL_HEALTH)
                }
              >
                <div className="fs-14 medium-text">
                  <i className="fas fa-copy" /> Copy Mental Health URL
                </div>
              </Button>
              <textarea
                id={`url` + colProps.original.code + SERVICE_TYPE.MENTAL_HEALTH}
                style={{ display: "none" }}
                value={
                  USER_WEB_MH_URL + "/register?code=" + colProps.original.code
                }
              ></textarea>
            </>
          )}
        </div>
      ) // Custom cell components!
    }
  ];

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  function getList(
    field = "created_at",
    sortBy = "DESC",
    search = "",
    page = "1"
  ) {
    const params = {
      sort_by: field,
      order: sortBy,
      search: search,
      page: page,
      group_id: hasParam
    };
    setLoader(true);
    GroupServices.registrationCodeList(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count.length / TABLE.LIMIT));
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
          setLoader(false);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
        setLoader(false);
      });
  }

  return (
    <div>
      <div className="module-header">
        <div className="flex-1 fs-16 demi-bold-text"></div>
        <div className="mr-10">
          <input
            value={searchText}
            onChange={e => {
              setSearchText(e.target.value);
              const search = e.target.value;
              setLoader(true);
              setActivePage(0);
              setTimeout(() => {
                getList(field, sortBy, search, 1);
              }, 500);
            }}
            type="text"
            placeholder="Search..."
            className="fs-14 medium-text plr-10 form-control react-form-input"
          />
        </div>
        <div>
          {hasAccess(
            MODULE_NAMES.GROUP_REG_CODES,
            PERMISSION_TYPE.ADD,
            permissions
          ) ? (
            <Button
              className="c-btn c-primary ma-5"
              onClick={() => formAction("add")}
            >
              <i className="fas fa-plus mr-10" />
              Add Registration Code
            </Button>
          ) : (
            ""
          )}
        </div>
      </div>
      <ReactTable
        style={{
          border: "none",
          boxShadow: "none"
        }}
        data={tblData}
        columns={columns}
        defaultPageSize={10}
        minRows={2}
        manual
        defaultFilterMethod={(filter, row) => {
          const id = filter.pivotId || filter.id;

          return row[id] !== undefined
            ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
            : true;
        }}
        className="-striped -highlight custom-react-table-theme-class"
        pages={pages}
        page={activePage}
        PaginationComponent={Pagination}
        loading={loader || !service}
        LoadingComponent={Loader}
        onFetchData={onChangePageGetData}
        onPageChange={pageIndex => {
          setActivePage(pageIndex);
          getList(field, sortBy, searchText, pageIndex + 1);
        }}
        onSortedChange={sortProperties => {
          const sort = sortProperties[0].desc ? "DESC" : "ASC";
          setSortBy(sort);
          setActivePage(0);
          setField(sortProperties[0].id);
          getList(sortProperties[0].id, sort, searchText, 1);
          // alert("sort handler", sortProperties);
          // const orderByDirection = sortProperties[0].desc ? 1 : 0;

          // updateMapStateInfoOrderByColumn(sortProperties[0].id, orderByDirection);
        }}
      />
      <Toaster ref={toaster} />
      <CommonModel
        modal={modal}
        toggle={toggle}
        children={
          <RegistrationCodeModal
            onSubmit={submitFormHandler}
            editedData={editedData}
            action={editedData ? "edit" : "add"}
            toggle={toggle}
            {...props}
          />
        }
        header={editedData ? "Edit Registration Code" : "Add Registration Code"}
      />
    </div>
  );
};
export default withRouter(RegistrationCodeTable);
