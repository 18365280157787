import React from "react";

const CptCodeViewDetails = props => {
  const codeDetails = props.location.state;
  return (
    <div className="plr-15">
      <div className="roe-card-style mtb-15">
        <div className="roe-card-header module-header">
          <div className="flex-1 fs-16 demi-bold-text">
            <span className="hash"># </span> View CPT Code
          </div>
        </div>
        <div className="roe-card-body">
          <div className="pt-2">
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>Name</div>
            <div>{codeDetails.name}</div>
          </div>
          <div className="pt-2">
            <div>
              <div style={{ fontWeight: "bolder", textAlign: "left" }}>
                Insurance
              </div>

              {codeDetails.cpt_insurances.map((insuranceDetail, id) => {
                return (
                  <div key={id}>
                    {insuranceDetail.insurance &&
                      insuranceDetail.insurance.name}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CptCodeViewDetails;
