import axios from "axios";
import { baseUrl } from "../helper/constant";
import Header from "./Header";
import EncryptDecrypt from "./EncryptDecrypt";

export default class ReferralsServices {
  static listReferral(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/client/list-referral`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static addReferral(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/client/add-referral`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static listExternalInternalProvider(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/client/list-external-internal-provider`,
      EncryptDecrypt.encrypt(params)
    );
  }
}
