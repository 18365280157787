import React, { useEffect, useRef, useState } from "react";
import { compose } from "redux";
import enhancer from "../userDocument/validator";
import CategoryServices from "api/CategoryServices";
import EncryptDecrypt from "api/EncryptDecrypt";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
import { PLACEHOLDER } from "helper/constant";
import ClientServices from "api/ClientServices";
import Button from "components/button/Button";
import RoyTooltip from "components/common/RoyTooltip";
import { Document, PDFViewer, Page } from "@react-pdf/renderer";
import classNames from "classnames";
import Toaster from "components/common/Toaster";

const AddDocument = props => {
  const [serviceList, setServiceList] = useState([]);
  const [serviceDropdownOpen, setServiceDropdownOpen] = useState(false);
  const toggleService = () => setServiceDropdownOpen(prevState => !prevState);
  const toaster = useRef(null);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [categoryLabel, setCategoryLabel] = useState(null);
  const hasParam = props.match.params.id;
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [docFile, setDocFile] = useState(null);

  const {
    handleChange,
    handleBlur,
    submitCount,
    errors,
    values,
    touched,
    editedData,
    setFieldValue
  } = props;

  useEffect(() => {
    CategoryServices.serviceDropdown({ is_general: true })
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setServiceList([...data.data]);
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      });
    setCategoryLabel(props?.editedData?.service?.name);
  }, []);

  const handleFileChange = (e, setValue) => {
    const fileObj = e.target.files[0];
    if (fileObj.size > 10 * 1024 * 1024) {
      if (toaster.current)
        toaster.current.error("Please upload a file smaller than 10 MB");
      return false;
    }
    if (fileObj) {
      setValue("document", fileObj);
      setValue("name", fileObj.name);

      setFile(fileObj);
    } else {
      setError(null);
      setFile(null);
    }
  };

  function getPreviewUrl() {
    if (file) {
      // Use FileReader to create a data URL for the file
      if (
        file?.type?.includes("openxmlformats-officedocument") ||
        file?.type?.includes("text/csv")
      ) {
        return;
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          // Set the preview state to the data URL
          setPreview(reader.result);
        };
        reader.readAsDataURL(file);
        // Return the preview state
        return preview;
      }
    } else {
      return editedData.document;
    }
  }

  const handleDocument = () => {
    let { values, isValid, handleSubmit } = props;
    if (isValid) {
      if (props.editedData.type == "add") {
        let docObj = {
          user_id: hasParam,
          name: values.name,
          service_id: values.category
        };

        const formdata = new FormData();
        const encryptData = EncryptDecrypt.encrypt(docObj);
        formdata.append("data", encryptData?.data);
        if (values?.document) formdata.append("data1", values?.document);

        setLoading(true);
        ClientServices.addDocument(formdata)
          .then(result => {
            setLoading(false);
            props.toggle();
            props.pageRefresh();
          })
          .catch(err => {
            if (err?.response?.status === 403) {
              if (toaster.current) {
                toaster.current.error("You cannot access this user info!");
                setTimeout(() => {
                  props.history.push("/client");
                }, 3000);
              }
            } else {
              if (toaster.current) toaster.current.error(err?.message);
            }
          });
      } else {
        let docObj = {
          name: values.name,
          id: editedData.id,
          service_id: values.category
        };
        setLoading(true);
        ClientServices.editDocument(docObj).then(result => {
          setLoading(false);
          props.toggle();
          props.pageRefresh();
        });
      }
    }

    handleSubmit();
  };
  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <div className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </div>
      );
    } else {
      return <span />;
    }
  };

  return (
    <div>
      <form>
        <div className="form-group col-md-12">
          <label
            className={classNames(
              "fs-16 medium-text c-primary demi-bold-text mt-15 wp-17 cursor-pointer plr-8 ptb-6 border-radius-8",
              editedData.type === "edit" && "cursor-not-allowed"
            )}
            disabled={editedData.type === "edit"}
          >
            Choose Document
            <input
              id="document"
              className="file-upload__input"
              name="document"
              type="file"
              onChange={e => {
                handleFileChange(e, setFieldValue);
              }}
              required={true}
              accept="image/png, image/jpeg, image/jpg,.doc, .docx,.pdf"
              disabled={editedData.type === "edit"}
            />
          </label>
          {error && <p style={{ color: "red" }}>{error}</p>}
          <Error field="document" />

          <div>
            {file ? (
              <>
                {file?.type?.includes("openxmlformats-officedocument") ||
                file?.type?.includes("text/csv") ||
                file?.type?.includes("application/vnd") ? (
                  <div>{file?.name}</div>
                ) : (
                  <>
                    {file?.type.startsWith("image/") ? (
                      <img src={getPreviewUrl()} alt={file.name} width="300" />
                    ) : (
                      <>
                        <div>
                          <PDFViewer
                            src={getPreviewUrl() + "#toolbar=0"}
                            width="300"
                            height="300"
                          >
                            <Document>
                              <Page> </Page>
                            </Document>
                          </PDFViewer>
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {values?.document ? (
                  values?.name?.includes("pdf") ? (
                    <div>
                      <PDFViewer
                        src={getPreviewUrl() + "#toolbar=0"}
                        width="300"
                        height="300"
                      >
                        <Document>
                          <Page> </Page>
                        </Document>
                      </PDFViewer>
                    </div>
                  ) : (
                    <>
                      {values?.name?.includes("jpeg") ||
                      values?.name?.includes("png") ||
                      values?.name?.includes("jpg") ? (
                        <>
                          <img
                            src={values.document}
                            alt={values.name}
                            width="300"
                          />
                        </>
                      ) : (
                        <>{editedData?.name}</>
                      )}
                    </>
                  )
                ) : (
                  <>
                    <div>
                      <img
                        src={PLACEHOLDER}
                        alt=""
                        className="mtb-15"
                        style={{
                          width: "200px",
                          borderRadius: "4%",
                          height: "150px",
                          background: "#404040"
                        }}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </div>

          <label className="fs-16 medium-text pt-3">Name</label>
          <input
            id="name"
            className="form-control react-form-input"
            type="text"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Error field="name" />
        </div>
        <div className="form-group col-md-12">
          <span className="fs-16">Category</span>
          <Dropdown isOpen={serviceDropdownOpen} toggle={toggleService}>
            <DropdownToggle caret size="md" style={{ width: "150px" }}>
              {categoryLabel ? categoryLabel : "Select Category"}
            </DropdownToggle>
            <RoyTooltip
              id={`bold`}
              title={
                "If you select General, all providers across all services will have access to this document"
              }
              placement="right"
            >
              <i
                id={`bold`}
                className="fas fa-info-circle pl-3 cursor-pointer"
              ></i>
            </RoyTooltip>
            <DropdownMenu
              modifiers={{
                setMaxHeight: {
                  enabled: true,
                  order: 890,
                  fn: data => {
                    return {
                      ...data,
                      styles: {
                        ...data.styles,
                        overflow: "auto",
                        maxHeight: "300px"
                      }
                    };
                  }
                }
              }}
            >
              {serviceList.length > 0 &&
                serviceList.map((result, i) => {
                  return (
                    <DropdownItem
                      key={i}
                      onClick={() => {
                        setCategoryLabel(result.name);
                        setFieldValue("category", result.id);
                      }}
                    >
                      {result.name}
                    </DropdownItem>
                  );
                })}
            </DropdownMenu>
          </Dropdown>
        </div>
        <div className="col-12 text-center pt-10">
          <Button
            type="button"
            loading={loading}
            disabled={loading}
            className="c-btn c-info form-button fs-16 demi-bold-text mr-10"
            style={{ maxWidth: "200px" }}
            onClick={() => {
              handleDocument();
            }}
            dataStyle="expand-right"
          >
            Submit
          </Button>
          <Button
            type="button"
            className="c-btn c-secondary form-button fs-16 demi-bold-text"
            style={{ maxWidth: "200px" }}
            onClick={props.toggle}
          >
            Close
          </Button>
        </div>
      </form>
      <Toaster ref={toaster} />
    </div>
  );
};
export default compose(enhancer)(AddDocument);
