import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import Loader from "../common/Loader";
import Toaster from "../common/Toaster";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import CommonModel from "components/common/CommonModel";
import RewardsManagementServices from "api/RewardsMangementServices";
import { ORDER_STATUS, TABLE } from "helper/constant";
import moment from "moment";
import ViewOrderModal from "./ViewOrderModal";

const OrderManagementTable = props => {
  const toaster = useRef();
  const [tblData, settblData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [modal, setModal] = useState(false);
  const [orderDetails, setOrderDetails] = useState({});
  const [isExporting, setIsExporting] = useState(false);

  useEffect(() => {
    getList(field, sortBy, searchText, activePage + 1);
    setPages(10);
  }, []);

  const viewDetailHandler = orderId => {
    const params = {
      order_id: orderId.id
    };
    setLoader(true);
    RewardsManagementServices.viewOrderDetails(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setLoader(false);
          setOrderDetails({ ...data.data });
          setModal(true);
        } else {
          if (toaster.current) toaster.current.error(data.message);
          setLoader(false);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
        setLoader(false);
      });
  };

  function toggle() {
    setModal(!modal);
  }

  const columns = [
    {
      Header: "ID",
      accessor: "id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 70,
      sortable: true
    },
    {
      Header: "Order Date",
      accessor: "order_date",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 150,
      sortable: true,
      Cell: props =>
        props.original.order_date
          ? moment
              .utc(props.original.order_date)
              .local()
              .format("MM/DD/YYYY")
          : ""
    },
    {
      Header: "Order ID",
      accessor: "iredeem_order_id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 150,
      sortable: true
    },
    {
      Header: "Order Status",
      accessor: "order_status",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 150,
      sortable: true,
      Cell: props => (
        <div className="react-action-class">
          {ORDER_STATUS[`${props.original?.order_status}`]}
        </div>
      )
    },
    {
      Header: "Points",
      accessor: "point",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 150,
      sortable: true
    },
    {
      Header: "Client Name",
      accessor: "first_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 230,
      sortable: true,
      Cell: props =>
        props.original.user
          ? props.original.user.first_name + " " + props.original.user.last_name
          : ""
    },
    {
      Header: "Client Email",
      accessor: "email",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 230,
      sortable: true,
      Cell: props =>
        props.original?.user?.email ? props.original.user.email : ""
    },
    {
      Header: "Action",
      accessor: "action",
      className: "",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 100,
      Cell: props => (
        <div className="react-action-class">
          <Button
            className="c-btn c-info mr-10"
            onClick={() => {
              viewDetailHandler(props?.original);
            }}
          >
            <div className="fs-14 medium-text" title="View">
              <i className="fas fa-eye" />
            </div>
          </Button>
        </div>
      ) // Custom cell components!
    }
  ];

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  function getList(
    field = "created_at",
    sort_by = "DESC",
    search = "",
    page = "1"
  ) {
    const params = {
      order: sort_by,
      sort_by: field,
      search: search,
      page: page
    };

    setLoader(true);
    RewardsManagementServices.listOrder(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count / TABLE.LIMIT));
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
          setLoader(false);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
        setLoader(false);
      });
  }

  function exportList(field = "title", sortBy = "DESC", search = "") {
    setIsExporting(true);
    const params = {
      sort_by: field,
      order: sortBy,
      search: searchText
    };
    RewardsManagementServices.exportOrders(params)
      .then(res => {
        setIsExporting(false);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error("Something went wrong!");
        setIsExporting(false);
      });
  }

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Order List
            </div>
            <div className="mr-10">
              <input
                value={searchText}
                onChange={e => {
                  setSearchText(e.target.value);
                  const search = e.target.value;
                  setLoader(true);
                  setActivePage(0);
                  setTimeout(() => {
                    getList(field, sortBy, search, 1);
                  }, 500);
                }}
                type="text"
                placeholder="Search..."
                className="fs-14 medium-text plr-10 form-control react-form-input"
              />
            </div>
            <div>
              <Button
                className="c-btn c-primary ma-5"
                onClick={() => exportList(field, sortBy)}
                disabled={!tblData?.length || isExporting}
              >
                <i className="fas fa-download mr-10" />
                {!isExporting ? "Export" : "Exporting..."}
              </Button>
            </div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              LoadingComponent={Loader}
              onFetchData={onChangePageGetData}
              onPageChange={pageIndex => {
                setActivePage(pageIndex);
                getList(field, sortBy, searchText, pageIndex + 1);
              }}
              onSortedChange={sortProperties => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getList(sortProperties[0].id, sort, searchText, 1);
              }}
            />
          </div>
        </div>
      </div>
      <Toaster ref={toaster} />

      <CommonModel
        modal={modal}
        toggle={toggle}
        children={
          <ViewOrderModal orderDetails={orderDetails} toggle={toggle} />
        }
        header={"Order Details"}
      />
    </div>
  );
};

export default withRouter(OrderManagementTable);
