import axios from "axios";
import { baseUrl } from "../helper/constant";
import Header from "./Header";
import EncryptDecrypt from "./EncryptDecrypt";
import FileDownload from "js-file-download";

export default class RewardsManagementServices {
  static list(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/reward/list-product`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static add(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/reward/add-product`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static edit(params) {
    Header.setHeaders();
    return axios.put(
      `${baseUrl}/reward/edit-product`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static status(params) {
    Header.setHeaders();
    return axios.put(
      `${baseUrl}/reward/update-product-status`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static tierList() {
    Header.setHeaders();
    return axios.get(`${baseUrl}/reward/list-tier`);
  }

  static editTier(params) {
    Header.setHeaders();
    return axios.put(
      `${baseUrl}/reward/edit-tier`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static delete(params) {
    Header.setHeaders();
    return axios.delete(
      `${baseUrl}/reward/delete-product`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static listOrder(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/reward/list-order`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static viewOrderDetails(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/reward/view-order`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static listiRedeemProduct(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/reward/list-iredeem-product`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static listRewardActivity(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/reward/list-reward-activity`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static editRewardsAcivity(params) {
    Header.setHeaders();
    return axios.put(
      `${baseUrl}/reward/edit-reward-activity`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static updateRewardModuleStatus(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/tier/update-module-all-tier`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static getModuleStatus(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/reward/module-permissions`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static exportOrders(params) {
    Header.setHeaders();
    return axios
      .post(
        `${baseUrl}/reward/export-order-csv`,
        EncryptDecrypt.encrypt(params)
      )
      .then(res => {
        FileDownload(res.data, "Orders.csv");
      });
  }
}
