import React, { useEffect, useRef, useState } from "react";
import { compose } from "redux";
import enhancer from "./validator";
import Button from "../button/Button";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import Toaster from "../common/Toaster";
import FaxManagementServices from "api/FaxMangementServices";
import Loader from "components/common/Loader";

const FaxForm = props => {
  const {
    handleChange,
    handleBlur,
    errors,
    setFieldValue,
    touched,
    submitCount,
    values,
    action,
    history,
    handleSubmit
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [adminList, setAdminList] = useState([]);

  useEffect(() => {
    if (values.adminAccess.length) {
      setFieldValue("adminAccess", [...values.adminAccess]);
    } else {
      let adminAccess = [];
      adminAccess.push(props?.history?.location?.state?.data);
      setFieldValue("adminAccess", adminAccess);
    }
  }, []);

  useEffect(() => {
    setLoader(true);
    const params = { sort_by: "ASC", field: "first_name" };
    FaxManagementServices.adminList(params).then(res => {
      let data = EncryptDecrypt.decrypt(res.data);
      setAdminList([...data.data.rows]);
      setLoader(false);
    });
  }, []);

  const checkHandleChange = e => {
    const targtValue = parseInt(e.currentTarget.value);
    let adminAccess = [...values.adminAccess];
    let objIndex = adminAccess.findIndex(obj => obj === targtValue);
    if (objIndex !== -1) {
      adminAccess.splice(objIndex, 1);
      setFieldValue("adminAccess", adminAccess);
    } else {
      adminAccess.push(targtValue);
      setFieldValue("adminAccess", adminAccess);
    }
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };
  const toaster = useRef();

  const changeFormat = e => {
    var x = e.target.value
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    let no = "(" + x[1] + ") " + x[2] + "-" + x[3];
    setFieldValue("number", no);
  };

  const handleFormSubmit = () => {
    let { values, isValid, handleSubmit } = props;
    setIsLoading(true);

    if (isValid) {
      if (action === "add") {
        const params = {
          number: values.number,
          description: values.description,
          adminAccess: values.adminAccess
        };
        FaxManagementServices.add(params)
          .then(res => {
            let data = EncryptDecrypt.decrypt(res.data);

            if (data.status) {
              if (toaster.current) toaster.current.success(data.message);
              setTimeout(() => {
                history.push("/fax");
              }, 1500);
              setIsLoading(false);
            } else {
              if (toaster.current) toaster.current.error(data.message);
              setIsLoading(false);
            }
          })
          .catch(err => {
            if (toaster.current) toaster.current.error(err.response);
            setIsLoading(false);
          });
      } else if (action === "edit") {
        const params = {
          id: values.id,
          number: values.number,
          description: values.description,
          adminAccess: values.adminAccess
        };
        FaxManagementServices.edit(params)
          .then(res => {
            let data = EncryptDecrypt.decrypt(res.data);

            if (data.status === 1) {
              if (toaster.current) toaster.current.success(data.message);
              setTimeout(() => {
                history.push("/fax");
              }, 1500);
              setIsLoading(false);
            } else {
              if (toaster.current) toaster.current.error(data.message);
              setIsLoading(false);
            }
          })
          .catch(err => {
            if (toaster.current) toaster.current.error(err.response);
            setIsLoading(false);
          });
      }
    } else {
      setIsLoading(false);
    }
    handleSubmit();
  };

  return (
    <div>
      {loader ? (
        <Loader loading={loader} />
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="flex-x">
            <div className="wa-40">
              <div className="col-md-40 form-group">
                <label className="fs-16 medium-text">
                  Number<span className="asterisk">*</span>
                </label>
                <input
                  type="text"
                  className="form-control react-form-input"
                  id="number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.number}
                  placeholder="Add Fax Number"
                  onKeyUp={value => changeFormat(value)}
                />
                <Error field="number" />
              </div>

              <div className="form-group">
                <label className="fs-16 medium-text">
                  Description <span className="asterisk">*</span>{" "}
                </label>
                <textarea
                  className="form-control react-form-input"
                  rows="4"
                  onChange={handleChange}
                  value={values?.description}
                  onBlur={handleBlur}
                  placeholder="Description"
                  id="description"
                ></textarea>
                <Error field="description" />
              </div>
            </div>
            <div className="form-group pl-50">
              <label className="fs-16 medium-text">Access</label>

              <div className="form-check ">
                {adminList &&
                  adminList.map((type, i) => {
                    return (
                      <div key={i} className="mt-1">
                        <input
                          type="checkbox"
                          name={`${type.id}`}
                          onChange={checkHandleChange}
                          value={type.id}
                          checked={
                            values.adminAccess &&
                            values.adminAccess.includes(type.id)
                              ? "checked"
                              : false
                          }
                          className="checkbox"
                        />{" "}
                        <label>{type.first_name + " " + type.last_name}</label>
                        <br />
                      </div>
                    );
                  })}
                <Error field="adminAccess" />
              </div>
            </div>
          </div>

          <div>
            <Button
              type="submit"
              className="c-btn c-info form-button fs-16 demi-bold-text"
              style={{ maxWidth: "125px" }}
              onClick={handleFormSubmit}
              loading={isLoading}
              disabled={isLoading}
              dataStyle={"expand-right"}
            >
              Submit
            </Button>
          </div>
        </form>
      )}
      <Toaster ref={toaster} />
    </div>
  );
};

export default compose(enhancer)(FaxForm);
