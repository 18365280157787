import React, { useEffect, useRef, useState } from "react";
import { StreamChat } from "stream-chat";
import {
  Chat,
  Channel,
  ChannelList,
  MessageList,
  MessageInput,
  Window,
  InfiniteScroll
} from "stream-chat-react";
import { useChecklist } from "./ChecklistTasks";
import "stream-chat-react/dist/css/index.css";
import "./App.css";
import { connect } from "react-redux";
import {
  CreateChannel,
  CustomMessage,
  MessagingChannelHeader,
  MessagingChannelList,
  MessagingChannelPreview,
  MessagingInput,
  MessagingThread
} from "../../components/message";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import AdminServices from "../../api/AdminServices";
import { RD, STREAM_API_KEY } from "../../helper/constant";
import Button from "components/button/Button";
import classNames from "classnames";
import CommonServices from "api/CommonServices";
import Toaster from "components/common/Toaster";

const urlParams = new URLSearchParams(window.location.search);

const Messgeing = props => {
  const toaster = useRef(null);
  const [providerEmailStatus, setProviderEmailStatus] = useState(null);
  const [isStatusLoading, setIsStatusLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isMobileNavVisible, setMobileNav] = useState(false);
  const [theme, setTheme] = useState("light");

  AdminServices.registerInGetStream()
    .then(res => {
      let data = EncryptDecrypt.decrypt(res.data);
      if (data.status) {
        // console.log('Updated info in getstream.io')
      } else {
        console.log("Error while updating in getstream.io");
      }
    })
    .catch(err => {
      console.log("Error while updating in getstream.io");
    });

  const user =
    props.auth.admin_role_id === 3
      ? `RD_${props.auth.id.toString()}`
      : `AD_${props.auth.id.toString()}`;
  const userName = props.auth.first_name + " " + props.auth.last_name;
  const userToken = props.auth.stream_io_token;
  const targetOrigin =
    urlParams.get("target_origin") || process.env.REACT_APP_TARGET_ORIGIN;
  const noChannelNameFilter = urlParams.get("no_channel_name_filter") || true;
  const skipNameImageSet = urlParams.get("skip_name_image_set") || false;
  const filters = noChannelNameFilter
    ? { type: "messaging", members: { $in: [user] } }
    : { type: "messaging" };
  const options = { state: true, watch: true, presence: true };
  const sort = {
    last_message_at: -1,
    updated_at: -1,
    cid: 1
  };

  const userToConnect = { id: user, name: userName };

  if (skipNameImageSet) {
    delete userToConnect.name;
    delete userToConnect.image;
  }

  const chatClient = StreamChat.getInstance(STREAM_API_KEY);
  chatClient.connectUser(userToConnect, userToken);

  useChecklist(chatClient, targetOrigin);

  useEffect(() => {
    if (props?.auth?.admin_role_id === RD) {
      setIsStatusLoading(true);
      CommonServices.getEmailNotificationSettings()
        .then(res => {
          const data = EncryptDecrypt.decrypt(res.data);

          if (data.status) {
            setProviderEmailStatus(data?.data?.notification_status);
          } else {
            if (toaster.current) toaster.current.error(data.message);
          }
          setIsStatusLoading(false);
        })
        .catch(err => {
          if (toaster.current)
            toaster.current.error(
              err?.message ? err?.message : "Something went wrong!"
            );
          setIsStatusLoading(false);
        });
    }
  }, [props?.auth?.admin_role_id]);

  useEffect(() => {
    const handleThemeChange = ({ data, origin }) => {
      // handle events only from trusted origin
      if (origin === targetOrigin) {
        if (data === "light" || data === "dark") {
          setTheme(data);
        }
      }
    };
    window.addEventListener("message", handleThemeChange);

    return () => window.removeEventListener("message", handleThemeChange);
  }, []);

  useEffect(() => {
    const mobileChannelList = document.querySelector("#mobile-channel-list");
    if (isMobileNavVisible && mobileChannelList) {
      mobileChannelList.classList.add("show");
      document.body.style.overflow = "hidden";
    } else if (!isMobileNavVisible && mobileChannelList) {
      mobileChannelList.classList.remove("show");
      document.body.style.overflow = "auto";
    }
  }, [isMobileNavVisible]);

  useEffect(() => {
    /*
     * Get the actual rendered window height to set the container size properly.
     * In some browsers (like Safari) the nav bar can override the app.
     */
    const setAppHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    };
    setAppHeight();
    window.addEventListener("resize", setAppHeight);
    return () => window.removeEventListener("resize", setAppHeight);
  }, []);

  const toggleMobile = () => setMobileNav(!isMobileNavVisible);

  const handleProviderEmailStatus = () => {
    setIsStatusLoading(true);
    CommonServices.updateEmailNotificationSettings({
      notification_status: !providerEmailStatus
    })
      .then(res => {
        const data = EncryptDecrypt.decrypt(res?.data);
        if (data.status) {
          setProviderEmailStatus(prev => !prev);
          if (toaster.current) toaster.current.success(data.message);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
        setIsStatusLoading(false);
      })
      .catch(err => {
        if (toaster.current)
          toaster.current.error(
            err?.message ? err?.message : "Something went wrong!"
          );
        setIsStatusLoading(false);
      });
  };

  return (
    <>
      {props?.auth?.admin_role_id === RD && (
        <div className="flex-x align-center right pb-1 font-bold background-transparent">
          <div className="mr-2">Notification Emails:</div>
          {!isStatusLoading && providerEmailStatus === null ? (
            <span className="color-setting-error">Error</span>
          ) : (
            <Button
              className={classNames(
                "c-btn min-w-130",
                isStatusLoading
                  ? "c-primary"
                  : !providerEmailStatus
                  ? "c-danger"
                  : "c-info"
              )}
              onClick={handleProviderEmailStatus}
              disabled={isStatusLoading}
              loading={isStatusLoading}
            >
              <div className="fs-14 medium-text">
                <i
                  className={classNames(
                    "fas fa-toggle-off mr-6",
                    !providerEmailStatus && "fa-toggle-on"
                  )}
                />{" "}
                {isStatusLoading
                  ? "Loading.."
                  : providerEmailStatus
                  ? "Active"
                  : "Inactive"}
              </div>
            </Button>
          )}
        </div>
      )}
      <Chat client={chatClient} theme={`messaging ${theme}`}>
        <div id="mobile-channel-list" onClick={toggleMobile}>
          <ChannelList
            filters={filters}
            sort={sort}
            options={options}
            Paginator={InfiniteScroll}
            List={props => (
              <MessagingChannelList
                {...props}
                onCreateChannel={() => {
                  setIsCreating(!isCreating);
                }}
              />
            )}
            Preview={props => (
              <MessagingChannelPreview
                key={props.channel.id}
                {...props}
                {...{ setIsCreating }}
              />
            )}
          />
        </div>
        <div>
          <Channel maxNumberOfFiles={10} multipleUploads={true}>
            {isCreating && (
              <CreateChannel
                toggleMobile={toggleMobile}
                onClose={() => setIsCreating(false)}
                auth={props.auth}
              />
            )}
            <Window>
              <MessagingChannelHeader
                theme={theme}
                toggleMobile={toggleMobile}
                settingsOpen={props?.location?.state?.settings || false}
              />
              <MessageList
                messageActions={[
                  "edit",
                  "delete",
                  "flag",
                  "mute",
                  "react",
                  "reply"
                ]}
                Message={CustomMessage}
                TypingIndicator={() => null}
              />
              <MessageInput focus Input={MessagingInput} />
            </Window>
            <MessagingThread />
          </Channel>
        </div>
      </Chat>
      <Toaster ref={toaster} />
    </>
  );
};
const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};
export default connect(mapStateToProps, null)(Messgeing);
