import React, { useRef, useState } from "react";
import EncryptDecrypt from "api/EncryptDecrypt";
import Button from "components/button/Button";
import RewardsManagementServices from "api/RewardsMangementServices";
import Toaster from "components/common/Toaster";

const exceptThisSymbols = ["e", "E", "+", "-", "."];

const EditTierModal = props => {
  const toaster = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [tierData, setTierData] = useState([...props?.editedData]);

  const handleFormSubmit = () => {
    setIsLoading(true);
    RewardsManagementServices.editTier({ tier_details: [...tierData] })
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current) toaster.current.success(data.message);
          setTimeout(() => {
            props.toggle();
            props.pageRefresh();
            setIsLoading(false);
          }, 1000);
        } else {
          if (toaster.current) toaster.current.error(data.message);
          setIsLoading(false);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
        setIsLoading(false);
      });
  };

  const handleTierChange = (id, min, max, display) => {
    const newTier = [...tierData];
    const index = newTier.findIndex(el => el.id === id);
    if (index !== -1) {
      newTier[index].max_point = Number(max);
      newTier[index].min_point = Number(min);
      newTier[index].is_display = display;
    }
    setTierData([...newTier]);
  };

  return (
    <div className="p-2">
      <form>
        {tierData?.map(val => (
          <div className="row">
            <div className="form-group col-md-3 ">
              <label className="fs-16 medium-text">{val?.name}</label>
            </div>
            <div className="form-group col-md-3">
              <label className="fs-16 medium-text">
                Min Points <span className="asterisk">*</span>
              </label>
              <input
                type="number"
                className="form-control react-form-input"
                id="min_point"
                onChange={e =>
                  handleTierChange(
                    val?.id,
                    e.target.value,
                    val?.max_point,
                    val?.is_display
                  )
                }
                value={val.min_point}
                placeholder="Add point"
                onKeyDown={e =>
                  exceptThisSymbols.includes(e.key) && e.preventDefault()
                }
              />
            </div>
            <div className="form-group col-md-3">
              <label className="fs-16 medium-text">
                Max Points <span className="asterisk">*</span>
              </label>
              <input
                type="number"
                className="form-control react-form-input"
                id="max_point"
                value={val.max_point}
                placeholder="Add point"
                onChange={e =>
                  handleTierChange(
                    val?.id,
                    val?.min_point,
                    e.target.value,
                    val?.is_display
                  )
                }
                onKeyDown={e =>
                  exceptThisSymbols.includes(e.key) && e.preventDefault()
                }
              />
            </div>
            <div className="form-group col-md-3">
              <div className="flex">
                <div className="pr-2" style={{ paddingTop: 4 }}>
                  <div
                    style={{
                      height: 14,
                      width: 14,
                      borderRadius: 4,
                      backgroundColor: "#d4d4d4",
                      backgroundColor: val?.is_display ? "#3f3f40" : "#d4d4d4"
                    }}
                    role="button"
                    onClick={e =>
                      handleTierChange(
                        val?.id,
                        val?.min_point,
                        val?.max_point,
                        !val?.is_display
                      )
                    }
                  />
                </div>
                <label className="fs-16">To Display</label>
              </div>
            </div>
          </div>
        ))}
        <div>
          <Button
            type="submit"
            className="c-btn c-info form-button fs-16 demi-bold-text"
            style={{ maxWidth: "125px" }}
            onClick={handleFormSubmit}
            loading={isLoading}
            disabled={isLoading}
            dataStyle={"expand-right"}
          >
            Submit
          </Button>
        </div>
      </form>
      <Toaster ref={toaster} />
    </div>
  );
};
export default EditTierModal;
