import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router";
import { compose } from "redux";
import enhancer from "./validator";
import GoalTracking from "../../api/GoalTrackingService";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import moment from "moment-timezone";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
import Toaster from "../common/Toaster";
import Button from "../button/Button";

const GoalQuestion = props => {
  const hasParam = props.match.params.id;
  const { submitCount, errors, touched } = props;

  let [questionList, setQuestionList] = useState([]);
  let [selectedId, setSelectedId] = useState(0);
  let [categoryOpen, setCategoryOpen] = useState(false);
  let [userGoalId, setUserGoalId] = useState(0);
  let [quantity, setQuantity] = useState(null);
  let [otherAnswer, setOtherAnswer] = useState(null);

  const toaster = useRef();

  const toggleCategory = result => {
    setSelectedId(result);
    setCategoryOpen(!categoryOpen);
  };

  useEffect(() => {
    GoalTracking.createShortTermGoal({
      user_id: hasParam,
      category_id: props.valuess.goal_category.id,
      timezone: moment.tz.guess()
    })
      .then(result => {
        let data = EncryptDecrypt.decrypt(result.data);
        setUserGoalId(data.data.user_goal_id);
        setQuestionList([...questionList, data.data.question_data]);
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current) toaster.current.error(err?.message);
        }
      });
  }, []);

  const selectAnswer = (ans, que) => {
    let index = questionList.findIndex(question => question.id === que.id);
    if (
      que.question_type === "Quantity" &&
      (quantity === null || quantity === 0)
    ) {
      toaster.current.error("Please add quantity");
    } else if (que.question_type === "Final" && ans.answer === "No") {
      toaster.current.error("Please create correct goal");
    } else if (
      ans.answer === "Other" &&
      (otherAnswer === "" || otherAnswer === null)
    ) {
      questionList[index].current_answer = ans;
      setQuestionList(questionList);
      questionList.splice(index + 1, questionList.length);
    } else {
      questionList[index].current_answer = ans;
      setQuestionList(questionList);

      questionList.splice(index + 1, questionList.length);

      GoalTracking.saveUserAnswer({
        user_goal_id: userGoalId,
        question_id: que.id,
        answer_id: ans.id,
        quantity: que.question_type === "Quantity" ? quantity : null,
        other_answer: ans.answer === "Other" ? otherAnswer : null
      }).then(result => {
        let data = EncryptDecrypt.decrypt(result.data);
        if (que.question_type === "Final" && ans.answer === "Yes") {
          toaster.current.success("Goal has been created successfully");
          setTimeout(() => {
            props.toggle1();
          }, 1800);
        } else {
          setQuestionList([...questionList, data.data]);
        }
      });
    }
  };

  const updateQty = (qty, que) => {
    setQuantity(qty);
    let index = questionList.findIndex(question => question.id === que.id);
    setQuestionList(questionList);

    questionList.splice(index + 1, questionList.length);
    if (questionList[index].current_answer) {
      GoalTracking.saveUserAnswer({
        user_goal_id: userGoalId,
        question_id: que.id,
        answer_id: questionList[index].current_answer.id,
        quantity: que.question_type === "Quantity" ? qty : null,
        other_answer:
          questionList[index].current_answer.answer === "Other"
            ? otherAnswer
            : null
      }).then(result => {
        let data = EncryptDecrypt.decrypt(result.data);
        setQuestionList([...questionList, data.data]);
      });
    }
  };

  return (
    <div>
      {questionList.map((question, index) => {
        return (
          <div key={index} className="mt-3">
            <div>{question.question}</div>
            <div className="d-flex align-items-center">
              {question.question_type === "Quantity" && (
                <input
                  type="number"
                  onChange={e => updateQty(e.target.value, question)}
                  min="1"
                  className="form-control react-form-input w-25"
                  style={{ marginTop: "5px", marginRight: "5px" }}
                ></input>
              )}
              <Dropdown
                isOpen={selectedId === index ? categoryOpen : false}
                toggle={() => toggleCategory(index)}
                key={`goal_types_${index}`}
                id={question.id}
                className="mt-1"
              >
                <DropdownToggle caret size="md">
                  {question.current_answer
                    ? question.current_answer.answer
                    : "Select Answer"}
                </DropdownToggle>
                <DropdownMenu>
                  {question.goal_question_answers &&
                    question.goal_question_answers.map((answer, i) => {
                      return (
                        <DropdownItem
                          key={`goal_typess_${i}`}
                          id={`goal_typess_${i}`}
                          onClick={e => selectAnswer(answer, question)}
                          value={answer}
                        >
                          {answer.answer}
                        </DropdownItem>
                      );
                    })}
                </DropdownMenu>
              </Dropdown>
              {(question.question_type === "Action" ||
                question.question_type === "Category") &&
                question.current_answer &&
                question.current_answer.answer === "Other" && (
                  <>
                    <input
                      type="text"
                      onChange={e => setOtherAnswer(e.target.value)}
                      className="form-control react-form-input w-25"
                      style={{ marginTop: "5px", marginLeft: "5px" }}
                      value={otherAnswer}
                    ></input>
                    <Button
                      type="button"
                      className="c-btn c-info form-button fs-16 demi-bold-text mr-10"
                      style={{
                        maxWidth: "100px",
                        marginTop: "5px",
                        marginLeft: "5px"
                      }}
                      onClick={e =>
                        selectAnswer(question.current_answer, question)
                      }
                    >
                      Save
                    </Button>
                  </>
                )}
            </div>
          </div>
        );
      })}
      <Toaster ref={toaster} />
    </div>
  );
};
export default compose(enhancer)(withRouter(GoalQuestion));
