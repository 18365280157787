import React, { useState, useEffect, useRef, forwardRef } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import VisitService from "../../api/VisitService";
import Loader from "../common/Loader";
import Toaster from "../common/Toaster";
import {
  TABLE,
  RD,
  APPOINTMENT_STATUS_LABEL,
  APPOINTMENT_STATUS,
  SERVICE_TYPE,
  SERVICE_NAME,
  PAID_STATUS_TYPE,
  PAYMENT_METHOD,
  VISIT_STATUS,
  MODULE_NAMES,
  PERMISSION_TYPE
} from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import CommonModel from "../common/CommonModel";
import AddVisitNote from "components/visittracking/AddVisitNote";
import ViewError from "./ViewErrorModal";
import moment from "moment-timezone";
import { DateRangePicker } from "materialui-daterange-picker";
import CompleteBillingForm from "components/visittracking/CompleteBillingForm";
import ViewCompleteBill from "components/visittracking/ViewCompleteBill";
import completeImg from "../../assets/images/green-check-mark.png";
import warningImg from "../../assets/images/warning.png";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { DownloadPdf } from "./PdfDocument";
import { ViewAddEditVisitNote } from "./ViewAddEditVisitNote";
import Select from "react-select";
import UpdateStatusModal from "components/common/UpdateStatusModal";
import { hasAccess } from "util/sidebar";
import FinancialStatus from "./FinancialStatus";

const WorkaroundContainer = forwardRef(function({ loading }, ref) {
  return <div ref={ref}>{loading ? "Loading..." : "Download PDF"}</div>;
});

const VisitManagement = props => {
  const { permissions } = props;
  const downloadRef = useRef(null);
  const [tblData, settblData] = useState();
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("created_at");
  const [sortBy, setSortBy] = useState("DESC");
  const [modal, setModal] = useState(false);
  const [viewNoteModal, setViewNoteModal] = useState(false);
  const [viewWarning, setViewWarning] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [dateRange, setDateRange] = useState({});
  const [open, setOpen] = useState(false);
  const [visitIds, setVisitIds] = useState([]);
  const [isExportingReport, setIsExportingReport] = useState(false);
  const [exportData, setExportData] = useState(null);
  const [customModal, setCustomModal] = useState(false);
  const [selectedFood, setSelectedFood] = useState(null);
  const [paidStatusId, setPaidStatusId] = useState(null);
  const [visitId, setVisitId] = useState(null);
  const [visitBillId, setVisitBillId] = useState(null);
  const [finStatusModal, setFinStatusModal] = useState(false);
  const [status, setStatus] = useState([]);
  // const [dateRange, setDateRange] = useState([null, null]);
  // const [startDate, endDate] = dateRange;
  useEffect(() => {
    if (
      hasAccess(
        MODULE_NAMES.CLIENT_VISIT_HISTORY,
        PERMISSION_TYPE.VIEW,
        permissions
      )
    ) {
      getList(field, sortBy, searchText, activePage + 1);
    }
  }, [dateRange.startDate, dateRange.endDate, status]);

  const toaster = useRef();

  useEffect(() => {
    if (
      hasAccess(
        MODULE_NAMES.CLIENT_VISIT_HISTORY,
        PERMISSION_TYPE.VIEW,
        permissions
      )
    ) {
      getList(field, sortBy, searchText, activePage + 1);
      setPages(10);
    }
  }, []);

  const getAmdErrorIcon = (service, step) => {
    if (step === 0) {
      return "NO_ICON";
    }
    if (service === 1) {
      if (step === 10) {
        return "NO_ICON";
      } else if (step === 11) {
        return "COMPLETE_ICON";
      } else {
        return "WARNING_ICON";
      }
    }
    if (service === 2) {
      if (step === 10 || step === 11) {
        return "COMPLETE_ICON";
      } else {
        return "WARNING_ICON";
      }
    }
  };

  function toggle() {
    setModal(!modal);
  }
  function paidStatusToggle() {
    setCustomModal(false);
  }

  const handleSelectVisit = (id, status) => {
    if (status === "completed") {
      let update = [...visitIds];
      const index = update.findIndex(vId => id === vId);
      if (index !== -1) {
        update.splice(index, 1);
      } else {
        update.push(id);
      }
      setVisitIds([...update]);
    }
  };

  const handleResetSelectedVisit = () => {
    setVisitIds([]);
  };

  const handleSelectAllCompletedVisits = () => {
    let update = [...visitIds];
    !!tblData.length &&
      tblData.forEach(data => {
        if (!update.includes(data.id) && data.status === "completed") {
          update.push(data.id);
        }
      });
    setVisitIds([...update]);
  };

  function toggleNote() {
    setViewNoteModal(!viewNoteModal);
  }
  function toggle1() {
    setModal2(!modal2);
    setOpen(!open);
  }
  function toggle2() {
    setModal1(!modal1);
  }
  function viewToggle() {
    setViewModal(!viewModal);
    setOpen(!open);
  }
  function toggleWarning() {
    setViewWarning(!viewWarning);
  }

  const toggleFinStatusModal = () => {
    setFinStatusModal(prev => !prev);
  };

  const handleJoinURL = data => {
    if (!data?.location) {
      if (toaster.current)
        toaster.current.error(
          "Sorry, no video link is available for this visit. Please contact your client directly."
        );
    } else if (data?.location?.type === "custom") {
      if (toaster.current) toaster.current.info("Please contact your client.");
    } else {
      let apptTime = moment.tz(
        moment(data?.appointment_date + " " + data?.appointment_time).format(
          "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
        ),
        moment.tz.guess()
      );

      let apptEndTime = moment.tz(
        moment(
          data?.appointment_end_date + " " + data?.appointment_end_time
        ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        moment.tz.guess()
      );

      let currTime = moment(new Date());

      let diffInMinsStart = apptTime.diff(currTime, "minutes");

      let diffInMinsEnd = currTime.diff(apptEndTime, "minutes");

      if (diffInMinsEnd > 0) {
        toaster.current.info("Visit has already ended.");
        return;
      }
      if (diffInMinsStart < 5) {
        window.open(data?.location?.join_url, "_blank");
      } else {
        toaster.current.info(
          "You cannot join your meeting at this time. Please join at the scheduled day and time of your visit."
        );
      }
    }
  };

  const statusUpdate = (id, evt) => {
    if (evt.value === "yes") {
      setPaidStatusId(id);
      setCustomModal(true);
    } else {
      setCustomModal(false);
    }
  };

  const updateStatusHandler = (bill_id, fromModal = false) => {
    const params = {
      id: bill_id
    };
    setLoader(true);
    if (fromModal) setCustomModal(false);
    VisitService.updateStatus(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current) toaster.current.success(data.message);
          getList(field, sortBy, searchText, activePage + 1);
          setTimeout(() => {
            setLoader(false);
          }, [1000]);
        } else {
          if (toaster.current) toaster.current.error(data.message);
          setLoader(false);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
        setLoader(false);
      });
  };

  const statusOptions = [
    {
      value: APPOINTMENT_STATUS.CANCELLED,
      label: APPOINTMENT_STATUS_LABEL.canceled
    },
    {
      value: APPOINTMENT_STATUS.COMPLETED,
      label: APPOINTMENT_STATUS_LABEL.completed
    },
    {
      value: APPOINTMENT_STATUS.LATE_CANCELLED,
      label: APPOINTMENT_STATUS_LABEL.latecancel
    },
    {
      value: APPOINTMENT_STATUS.NO_SHOW,
      label: APPOINTMENT_STATUS_LABEL.noshow
    },
    {
      value: APPOINTMENT_STATUS.PENDING,
      label: APPOINTMENT_STATUS_LABEL.pending
    }
  ];

  const options = [
    { value: "no", label: "No" },
    { value: "yes", label: "Yes" }
  ];

  const columns = [
    {
      Header: "Select",
      accessor: "id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 100,
      sortable: false,
      Cell: props => (
        <div
          className="flex justify-content-center align-items-center"
          style={{ paddingTop: 4 }}
        >
          {props.original.status === "completed" ? (
            <div
              style={{
                height: 14,
                width: 14,
                borderRadius: 4,
                backgroundColor: visitIds.includes(props.value)
                  ? "#3f3f40"
                  : "#d4d4d4",
                cursor: "pointer"
              }}
              role="button"
              onClick={() =>
                handleSelectVisit(props.value, props.original.status)
              }
            />
          ) : (
            "-"
          )}
        </div>
      )
    },
    {
      Header: "ID",
      accessor: "id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 150,
      sortable: true
    },
    {
      Header: "Appointment Date",
      accessor: "appointment_date",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 200,
      sortable: true,
      Cell: props =>
        props.original.appointment_type === "Manual"
          ? moment
              .tz(
                moment(
                  props.original.appointment_date +
                    " " +
                    props.original.appointment_time
                ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                // props?.original?.timezone
                moment.tz.guess()
              )
              .format("MM/DD/YYYY")
          : moment
              .tz(props.original.event_detail.start_time, moment.tz.guess())
              .format("MM/DD/YYYY")
    },
    {
      Header: "Appointment Time",
      accessor: "appointment_time",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 200,
      sortable: true,
      Cell: props =>
        `${
          props.original.appointment_type === "Manual"
            ? moment
                .tz(
                  moment(
                    props.original.appointment_date +
                      " " +
                      props.original.appointment_time
                  ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                  moment.tz.guess()
                )
                .format("h:mm A")
            : moment
                .tz(props.original.event_detail.start_time, moment.tz.guess())
                .format("h:mm A")
        } (${moment()
          .tz(moment.tz.guess())
          .zoneAbbr()})`
    },
    {
      Header: "Appointment Type",
      accessor: "appointment_type",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 200,
      sortable: true
    },
    {
      Header: "Status",
      accessor: "status",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 150,
      Cell: props => APPOINTMENT_STATUS_LABEL[props.original.status]
    },
    {
      Header: "Service",
      accessor: "service_type",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 150,
      Cell: props =>
        props.original.service_id
          ? SERVICE_NAME[props.original.service_id]
          : "-"
    },
    {
      Header: "Paid Status",
      accessor: "is_paid_in_advancemd",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 150,
      Cell: prop => (
        <div>
          {prop.original.service_id === SERVICE_TYPE.NUTRITION &&
          props?.auth?.service_id !== SERVICE_TYPE.MENTAL_HEALTH ? (
            prop.original?.visit_bill !== null ? (
              <Select
                style={{ width: "80px" }}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                isDisabled={
                  props?.auth?.admin_role_id === 3 ||
                  prop?.original?.status !== VISIT_STATUS["completed"] ||
                  prop?.original?.visit_bill?.is_paid_in_advancemd ||
                  (prop.original?.visit_bill?.payment_method !==
                    PAYMENT_METHOD["Client Funded"] &&
                    prop.original?.visit_bill?.payment_method !==
                      PAYMENT_METHOD["Group Funded"] &&
                    prop.original?.visit_bill?.payment_method !==
                      PAYMENT_METHOD["Consumer Model"])
                }
                value={{
                  value: `${
                    prop.original?.visit_bill?.is_paid_in_advancemd === "true"
                      ? "yes"
                      : "no"
                  }`,
                  label:
                    PAID_STATUS_TYPE[
                      `${prop.original?.visit_bill?.is_paid_in_advancemd}`
                    ]
                }}
                onChange={evt =>
                  statusUpdate(prop?.original?.visit_bill?.id, evt)
                }
                options={options}
              />
            ) : (
              "-"
            )
          ) : (
            "-"
          )}
        </div>
      )
    },
    {
      Header: "Visit note",
      accessor: "visit_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 200,
      sortable: false,
      Cell: prop => (
        <div>
          {prop.original.service_id === SERVICE_TYPE.NUTRITION &&
          props?.auth?.service_id !== SERVICE_TYPE.MENTAL_HEALTH ? (
            <Button
              className="c-btn c-success mr-10"
              onClick={() => {
                setViewNoteModal(!viewNoteModal);
                setSelectedFood({ ...prop.original });
              }}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-edit" />
              </div>
            </Button>
          ) : (
            "-"
          )}
        </div>
      )
    },
    {
      Header: "Billing Complete",
      accessor: "visit_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 300,
      sortable: false,
      Cell: colProps =>
        colProps.original.service_id === SERVICE_TYPE.NUTRITION ? (
          <>
            {colProps.original.is_billing_complete ? (
              <div>
                <Button
                  className="c-btn c-success mr-10"
                  onClick={() => {
                    setViewModal(!viewModal);
                    setSelectedFood(colProps.original);
                  }}
                >
                  View Billing
                </Button>
                {props?.auth?.admin_role_id !== RD ||
                (props?.auth?.admin_role_id === RD &&
                  moment(colProps?.original?.visit_bill?.submitted_date)
                    .utc()
                    .local()
                    .format("YYYY-MM-DD") ===
                    moment()
                      .utc()
                      .local()
                      .format("YYYY-MM-DD") &&
                  props?.auth?.adminType === "employee") ||
                (props?.auth?.admin_role_id === RD &&
                  props?.auth?.adminType === "ibo") ? (
                  <Button
                    className="c-btn c-success mr-10"
                    onClick={() => {
                      setModal1(!modal1);
                      setSelectedFood({ action: "edit", ...colProps.original });
                    }}
                    // disabled={
                    //   (colProps?.original?.visit_bill?.payment_method ===
                    //     PAYMENT_METHOD["Insurance Funded"] &&
                    //     colProps?.original?.visit_bill?.advancemd_step ===
                    //       11) ||
                    //   ((colProps?.original?.visit_bill?.payment_method ===
                    //     PAYMENT_METHOD["Client Funded"] ||
                    //     colProps?.original?.visit_bill?.payment_method ===
                    //       PAYMENT_METHOD["Group Funded"]) &&
                    //     colProps?.original?.visit_bill?.is_paid_in_advancemd ===
                    //       true)
                    // }
                  >
                    Edit Billing
                  </Button>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <Button
                className="c-btn c-info mr-10"
                onClick={() => {
                  setModal1(!modal1);
                  setSelectedFood({ action: "add", ...colProps.original });
                }}
                disabled={
                  colProps.original.appointment_date >
                    moment()
                      .utc()
                      .format("YYYY-MM-DD") ||
                  colProps.original.status === APPOINTMENT_STATUS.CANCELLED ||
                  colProps.original.status === APPOINTMENT_STATUS.NO_SHOW ||
                  colProps.original.status === APPOINTMENT_STATUS.LATE_CANCELLED
                    ? true
                    : false
                }
              >
                Add Billing
              </Button>
            )}
          </>
        ) : (
          "-"
        )
    },
    {
      Header: "Action",
      accessor: "",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 200,
      sortable: false,
      Cell: props => (
        <>
          {hasAccess(
            MODULE_NAMES.CLIENT_VISIT_HISTORY,
            PERMISSION_TYPE.EDIT,
            permissions
          ) ? (
            <Button
              className="c-btn c-success mr-10"
              onClick={() => {
                setSelectedFood({ ...props.original });
                setModal(!modal);
              }}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-edit" />
              </div>
            </Button>
          ) : (
            ""
          )}
          {getAmdErrorIcon(
            Number(props?.original?.service_id),
            Number(props?.original?.visit_bill?.advancemd_step)
          ) === "COMPLETE_ICON" && (
            <Button
              className="c-btn c-focus mr-10 "
              onClick={() => {
                setVisitId(props?.original?.id);
                setVisitBillId(props?.original?.visit_bill?.id);
                toggleFinStatusModal();
              }}
              title="Financial Stats"
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-file-invoice-dollar pright-2 pleft-2" />
              </div>
            </Button>
          )}
        </>
      )
    },
    {
      Header: "AdvanceMd Status",
      accessor: "visit_bill.advancemd_step",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 200,
      sortable: false,
      Cell: ({ original }) => {
        return (
          original?.visit_bill &&
          original?.service_id && (
            <>
              {getAmdErrorIcon(
                Number(original?.service_id),
                Number(original?.visit_bill?.advancemd_step)
              ) === "WARNING_ICON" && (
                <img
                  style={{
                    cursor: "pointer",
                    width: "20px"
                  }}
                  title="Visit Status"
                  src={warningImg}
                  onClick={() => {
                    setSelectedFood({ ...original });
                    setViewWarning(!viewWarning);
                  }}
                />
              )}
              {getAmdErrorIcon(
                Number(original?.service_id),
                Number(original?.visit_bill?.advancemd_step)
              ) === "COMPLETE_ICON" && (
                <img
                  style={{
                    width: "20px"
                  }}
                  title="Visit Status"
                  src={completeImg}
                />
              )}
            </>
          )
        );
      }
    }
  ];

  if (props?.auth?.admin_role_id === RD) {
    let visitURL = {
      Header: "Visit URL",
      accessor: "location",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      width: 150,
      Cell: ({ original, value }) =>
        original?.appointment_type !== "Manual" &&
        original?.status === "active" &&
        value?.type !== "physical" && (
          <Button
            className="c-btn c-success mr-10"
            onClick={() => {
              handleJoinURL(original);
            }}
          >
            <div className="fs-14 medium-text " title="View">
              <i className="fas fa-calendar-check mr-2" />
              Join URL
            </div>
          </Button>
        )
    };
    columns.splice(5, 0, visitURL);
  }

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  function getList(
    field = "created_at",
    sortBy = "DESC",
    search = "",
    page = "1",
    group_id = null,
    client_id = null
  ) {
    const hasParam = props.match.params.id;
    const params = {
      sort_by: field,
      order: sortBy,
      search: search,
      page: page,
      userId: hasParam,
      id: client_id,
      start_date: dateRange.startDate
        ? moment(dateRange.startDate).format("YYYY-MM-DD")
        : null,
      end_date: dateRange.endDate
        ? moment(dateRange.endDate).format("YYYY-MM-DD")
        : null,
      timezone: moment.tz.guess(),
      status: status ? status.map(status => status.value) : []
    };
    setLoader(true);
    VisitService.visitList(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);

        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count.length / TABLE.LIMIT));
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
          setLoader(false);
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current) toaster.current.error(err?.message);
        }
        setLoader(false);
      });
  }

  const exportPatient = () => {
    if (!visitIds.length) return;
    const hasParam = props.match.params.id;
    const params = {
      user_id: hasParam,
      appointment_ids: String(visitIds),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };
    setIsExportingReport(true);
    VisitService.exportPatientReport(params)
      .then(res => {
        const response = EncryptDecrypt.decrypt(res.data);
        setExportData(response?.data);
        setTimeout(() => {
          download();
          setExportData(null);
          setIsExportingReport(false);
        }, 2000);
        setVisitIds([]);
      })
      .catch(err => {
        setVisitIds([]);
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current)
            toaster.current.error("Could not export patient report!");
        }
        setIsExportingReport(false);
      });
  };

  const download = () => {
    downloadRef.current.click();
  };

  const statusChange = e => {
    setActivePage(0);
    setStatus(e);
  };

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span>Client Appointment List
            </div>
            <div
              className="fs-14 mr-10"
              style={{ width: "25%", zIndex: "100" }}
            >
              <Select
                options={statusOptions}
                isMulti
                onChange={statusChange}
                placeholder={<div>Visit Status</div>}
              />
            </div>
            <div className="d-flex">
              {/* <div className=" d-flex ml-10">
                <DatePicker
                  selected={startDate}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  onChange={date => setStartDate(date)}
                  placeholderText="Select start date"
                  className="form-control react-form-input"
                />

                <DatePicker
                  selected={endDate}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  onChange={date => setEndDate(date)}
                  placeholderText="Select end date"
                  className="form-control react-form-input"
                />
              </div> */}
            </div>
            <div>
              <Button
                className="c-btn c-primary"
                disabled={!visitIds.length || isExportingReport}
                onClick={exportPatient}
                title={
                  !visitIds.length && "Select a completed appointment first!"
                }
                loading={isExportingReport}
              >
                <i className="fas fa-file-pdf mr-10" />
                {isExportingReport
                  ? "Downloading. . . . . "
                  : "Export Patient Record"}
              </Button>
            </div>
            <div style={{ display: "none" }}>
              <PDFDownloadLink
                document={<DownloadPdf data={exportData} />}
                fileName={`Patient_Export_${props.match.params.id}.pdf`}
                style={{ display: "none" }}
              >
                {({ blob, url, loading, error }) => (
                  <WorkaroundContainer ref={downloadRef} loading={loading} />
                )}
              </PDFDownloadLink>
            </div>
            <div className="d-flex ml-10">
              <div className="d-flex align-items-center flex-1 fs-16 demi-bold-text">
                <span className="hash"># </span>
                {dateRange.startDate &&
                  `${moment(dateRange.startDate).format(
                    "MM/DD/YYYY"
                  )} -${moment(dateRange.endDate).format("MM/DD/YYYY")}`}
              </div>
              <Button
                className="c-btn c-primary ma-5"
                onClick={() => toggle1()}
              >
                Select Date / Month
              </Button>
              {dateRange && dateRange.startDate && (
                <Button
                  className="c-btn c-primary ma-5"
                  onClick={() => {
                    setActivePage(0);
                    setDateRange({});
                  }}
                >
                  Reset date
                </Button>
              )}
            </div>
            {/* {props?.auth?.admin_role_id !== 3 && ( */}
            <div className="d-flex ml-10">
              {hasAccess(
                MODULE_NAMES.CLIENT_VISIT_HISTORY,
                PERMISSION_TYPE.ADD,
                permissions
              ) ? (
                <Button
                  className="c-btn c-primary ma-5"
                  onClick={() => {
                    setSelectedFood(null);
                    setModal(!modal);
                  }}
                  disabled={
                    (!!props.location?.state &&
                      !props.location?.state?.mental_health_provider?.admin
                        ?.id &&
                      !props.location?.state?.nutrition_provider?.admin?.id) ||
                    false
                  }
                >
                  <i className="fas fa-plus mr-10" />
                  Add Appointment
                </Button>
              ) : (
                ""
              )}
            </div>
            {/* )} */}
          </div>
          <div className="d-flex">
            {!!tblData?.length && (
              <div className="ml-10">
                <Button
                  className="c-btn c-primary ma-5"
                  onClick={handleSelectAllCompletedVisits}
                >
                  Select All on this page
                </Button>
              </div>
            )}
            {!!visitIds?.length && (
              <div className="ml-10">
                <Button
                  className="c-btn c-primary ma-5"
                  onClick={handleResetSelectedVisit}
                >
                  Unselect All
                </Button>
              </div>
            )}
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;
                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              LoadingComponent={Loader}
              onFetchData={onChangePageGetData}
              onPageChange={pageIndex => {
                setActivePage(pageIndex);
                getList(field, sortBy, searchText, pageIndex + 1);
              }}
              onSortedChange={sortProperties => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getList(sortProperties[0].id, sort, searchText, 1);
                // alert("sort handler", sortProperties);
                // const orderByDirection = sortProperties[0].desc ? 1 : 0;

                // updateMapStateInfoOrderByColumn(sortProperties[0].id, orderByDirection);
              }}
            />
          </div>
        </div>
      </div>

      <Toaster ref={toaster} />
      {modal && (
        <CommonModel
          modal={modal}
          toggle={toggle}
          size="lg"
          children={
            <AddVisitNote
              // onSubmit={submitFormHandler}
              editedData={selectedFood}
              toggle={toggle}
              {...props}
              pageRefresh={() => {
                getList(field, sortBy, searchText, activePage + 1);
                setPages(10);
              }}
            />
          }
          // header={editedData ? "" : "Add Registration Code"}
        />
      )}
      {viewNoteModal && (
        <ViewAddEditVisitNote
          data={selectedFood}
          toggle={toggleNote}
          {...props}
          pageRefresh={() => {
            getList(field, sortBy, searchText, activePage + 1);
            setPages(10);
          }}
          viewNoteModal={viewNoteModal}
          getClientHeader={props?.getClientHeader}
        />
      )}
      {modal1 && (
        <CommonModel
          modal={modal1}
          toggle={toggle2}
          size="xl"
          children={
            <CompleteBillingForm
              // onSubmit={submitFormHandler}
              editedData={selectedFood}
              toggle={toggle2}
              {...props}
              pageRefresh={() => {
                getList(field, sortBy, searchText, activePage + 1);
                setPages(10);
              }}
            />
          }
          // header={editedData ? "" : "Add Registration Code"}
        />
      )}
      {open && (
        <CommonModel
          modal={modal2}
          toggle={toggle1}
          size="lg"
          children={
            <DateRangePicker
              open={open}
              toggle={() => toggle1()}
              onChange={range => {
                setDateRange(range);
                // setModal(!modal)
                toggle1();
              }}
              closeOnClickOutside={true}
            />
          }
          // header={editedData ? "" : "Add Registration Code"}
        />
      )}
      {viewModal && (
        <CommonModel
          modal={viewModal}
          toggle={viewToggle}
          size="xl"
          children={
            <ViewCompleteBill
              editedData={selectedFood}
              viewToggle={viewToggle}
              {...props}
            />
          }
          // header={editedData ? "" : "Add Registration Code"}
        />
      )}
      {viewWarning && (
        <CommonModel
          modal={viewWarning}
          toggle={toggleWarning}
          size="xl"
          children={
            <ViewError
              editedData={selectedFood}
              toggle={toggleWarning}
              {...props}
            />
          }
        />
      )}

      <CommonModel
        modal={customModal}
        toggle={paidStatusToggle}
        children={
          <UpdateStatusModal
            text={"Are you sure you want to update the paid status. "}
            activeInactiveStatusHandler={() =>
              updateStatusHandler(paidStatusId, true)
            }
            activeToggle={paidStatusToggle}
          />
        }
      />

      <CommonModel
        modal={finStatusModal}
        toggle={toggleFinStatusModal}
        size="xl"
        children={
          <FinancialStatus
            id={visitId}
            visitBillId={visitBillId}
            toggle={toggleFinStatusModal}
          />
        }
      />
    </div>
  );
};

export default withRouter(VisitManagement);
