import React, { useRef, useEffect, useState } from "react";
import { compose } from "redux";
import enhancer from "../visittracking/validator";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import moment from "moment-timezone";
import VisitTracking from "./visittracking.style";
import ClientServices from "../../api/ClientServices";
import { SERVICE_TYPE } from "helper/constant";
import { convertToFixedOffset, diffMinutes } from "components/roeeditor/utils";
import Toaster from "components/common/Toaster";
const billUnitArr = [
  {
    key: 0,
    value: "0 units - Not billable"
  },
  {
    key: 1,
    value: "1 unit - 8-22 minutes"
  },
  {
    key: 2,
    value: "2 units - 23-37 minutes"
  },
  {
    key: 3,
    value: "3 units - 38-52 minutes"
  },
  {
    key: 4,
    value: "4 units - 53-67 minutes"
  },
  {
    key: 5,
    value: "5 units - 68-82 minutes"
  },
  {
    key: 6,
    value: "6 units - 83-97 minutes"
  },
  {
    key: 7,
    value: "7 units - 98-112 minutes"
  },
  {
    key: 8,
    value: "8 units - 113-127 minutes"
  }
];
const paymentArr = [
  "Insurance Funded",
  "Group Funded",
  "Client Funded",
  "Other - Free Consultation"
];
const CompleteBillingForm = props => {
  const toaster = useRef(null);
  const hasParam = props.match.params.id;
  const [editedData, setEditedData] = useState(null);

  const [paymentId, setPaymentId] = useState(null);

  const [diagnoseList, setDiagnoseList] = useState([]);
  const [billUnit, setBillUnit] = useState([]);

  useEffect(() => {
    if (props.editedData.visit_bill) {
      setDiagnoseList(props.editedData.visit_bill.visit_bills_diagnoses);
    }
    let obj = billUnitArr.find(
      o => o.key === props.editedData.visit_bill.billable_unit
    );
    setBillUnit(obj["value"]);
  }, [props.editedData]);

  useEffect(() => {
    if (hasParam) {
      const params = {
        id: hasParam,
        appointment_id: props?.editedData?.id
      };
      ClientServices.view(params)
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          setEditedData(data.data);
        })
        .catch(err => {
          if (err?.response?.status === 403) {
            if (toaster.current) {
              toaster.current.error("You cannot access this user info!");
              setTimeout(() => {
                props.history.push("/client");
              }, 3000);
            }
          } else {
            if (toaster.current) toaster.current.error(err?.message);
          }
        });
    }
  }, []);

  const getProviderName = () => {
    let providerName = null;
    if (editedData?.appointment) {
      providerName =
        editedData?.appointment?.admin?.first_name +
        " " +
        editedData?.appointment?.admin?.last_name;
    }
    return providerName;
  };

  return (
    <VisitTracking {...props}>
      <div
        className="close-arw float-right cursor-pointer"
        onClick={props.viewToggle}
      >
        <i
          className="fa fa-times-circle-o fa-lg"
          onClick={props.viewToggle}
          aria-hidden="true"
        ></i>
      </div>
      <form className="row col-12 pa-24">
        <div className="col-6">
          <div className="form-group  d-flex">
            <label>Insurance Carrier:</label>
            <div className="roe-card-body ml-2">
              {" "}
              {props.editedData &&
                props.editedData.visit_bill &&
                props.editedData.visit_bill.user_insurance &&
                props.editedData.visit_bill.user_insurance.insurance &&
                props.editedData.visit_bill.user_insurance.insurance.name}
            </div>
          </div>
          <div className="form-group  d-flex">
            <label>Member ID:</label>
            <div className="roe-card-body ml-2">
              {" "}
              {props.editedData &&
                props.editedData.visit_bill &&
                props.editedData.visit_bill.user_insurance &&
                props.editedData.visit_bill.user_insurance.member_id}
            </div>
          </div>
          <div className="form-group d-flex">
            <label>Group ID:</label>
            <div className="roe-card-body ml-2">
              {" "}
              {props.editedData &&
                props.editedData.visit_bill &&
                props.editedData.visit_bill.user_insurance &&
                props.editedData.visit_bill.user_insurance.group_id}
            </div>
          </div>
          <div className="form-group d-flex">
            <label>Service Date:</label>
            <div className="roe-card-body ml-2">
              {props?.editedData?.appointment_type === "Manual"
                ? moment
                    .tz(
                      moment(
                        props?.editedData?.appointment_date +
                          " " +
                          props?.editedData?.appointment_time
                      ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                      moment.tz.guess()
                    )
                    .format("MM/DD/YYYY")
                : moment
                    .tz(
                      props?.editedData?.event_detail.start_time,
                      moment.tz.guess()
                    )
                    .format("MM/DD/YYYY")}
            </div>
          </div>
          <div className="form-group d-flex">
            <label>Service Start Time:</label>
            <div className="roe-card-body ml-2">
              {convertToFixedOffset(
                moment(
                  props.editedData.appointment_date +
                    " " +
                    props.editedData.visit_bill.service_start_time
                ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                moment.tz.guess()
              )}{" "}
              {moment()
                .tz(moment.tz.guess())
                .format("z")}
            </div>
          </div>
          <div className="form-group d-flex">
            <label>Service End Time:</label>
            <div className="roe-card-body ml-2">
              {convertToFixedOffset(
                moment(
                  props.editedData.appointment_date +
                    " " +
                    props.editedData.visit_bill.service_end_time
                ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                moment.tz.guess()
              )}{" "}
              {moment()
                .tz(moment.tz.guess())
                .format("z")}
            </div>
          </div>
          <div className="form-group d-flex">
            <label>Service Duration:</label>
            <div className="roe-card-body ml-2">
              {props?.editedData
                ? diffMinutes(
                    moment(
                      props?.editedData?.appointment_date +
                        " " +
                        props?.editedData?.visit_bill?.service_start_time
                    ).format("HH:mm"),
                    moment(
                      props?.editedData?.appointment_date +
                        " " +
                        props?.editedData?.visit_bill?.service_end_time
                    ).format("HH:mm")
                  ) + " minutes"
                : "-"}
            </div>
          </div>
          <div className="form-group d-flex">
            <label>Method of Service: </label>
            <div className="roe-card-body ml-2">
              {props.editedData &&
              props.editedData.visit_bill &&
              props.editedData.visit_bill.method_of_services &&
              props.editedData.visit_bill.method_of_services === "Virtual"
                ? "Virtual (GT - 95 Modifier)"
                : "In Person"}
            </div>
          </div>
          <div className="form-group d-flex">
            <label>Payment Method:</label>
            <div className="roe-card-body ml-2">
              {props.editedData &&
                props.editedData.visit_bill &&
                props.editedData.visit_bill.payment_method}
            </div>
          </div>
          <div className="form-group d-flex">
            <label>Billable Units:</label>
            <div className="roe-card-body ml-2">
              {props.editedData && props.editedData.visit_bill && billUnit}
            </div>
          </div>
          {props.editedData &&
            props.editedData.visit_bill &&
            props.editedData.visit_bill.payment_method ===
              "Insurance Funded" && (
              <div className="form-group d-flex">
                <label>Requires Prequalification? :</label>
                <div className="roe-card-body ml-2">
                  {props.editedData &&
                  props.editedData.visit_bill &&
                  props.editedData.visit_bill.is_prequalification
                    ? "Yes"
                    : "No"}
                </div>
              </div>
            )}
        </div>
        <div className="col-6">
          <div className="form-group d-flex">
            <label>Provider:</label>
            <div className="roe-card-body ml-2">
              {props.editedData && getProviderName()}
            </div>
          </div>
          <div className="form-group d-flex">
            <label>Service Location:</label>
            <div className="roe-card-body ml-2">
              {props.editedData &&
                props.editedData.visit_bill &&
                props.editedData.visit_bill.service_location_detail &&
                `${props.editedData.visit_bill.service_location_detail?.name}`}
            </div>
          </div>

          <div className="form-group d-flex">
            <label>State:</label>
            <div className="roe-card-body ml-2">
              {props.editedData &&
                props.editedData.visit_bill &&
                props.editedData.visit_bill.state &&
                props.editedData.visit_bill.state?.name}
            </div>
          </div>
          <div className="form-group d-flex">
            <label>Notes for Medical Biller:</label>
            <div className="roe-card-body ml-2">
              {props.editedData &&
                props.editedData.visit_bill &&
                props.editedData.visit_bill.notes_for_medical_biller}
            </div>
          </div>
          {props.editedData &&
            props.editedData.visit_bill &&
            (props.editedData.visit_bill.payment_method ===
              "Insurance Funded" ||
              props.editedData.visit_bill.payment_method === "Client Funded" ||
              props.editedData.visit_bill.payment_method ===
                "Consumer Model") && (
              <>
                <div className="form-group d-flex">
                  <label>Type of Service: </label>
                  <div className="roe-card-body ml-2">
                    {props.editedData &&
                      props.editedData.visit_bill &&
                      props.editedData.visit_bill.type_of_service}
                  </div>
                </div>
                <div>
                  <div className="form-group d-flex">
                    <label>CPT Code:</label>
                    <div className="roe-card-body ml-2">
                      {props.editedData &&
                        props.editedData?.visit_bill &&
                        props.editedData?.visit_bill?.cpt_code &&
                        props.editedData?.visit_bill?.cpt_code?.name}
                    </div>
                  </div>
                </div>
                <div className="form-group d-flex">
                  <label>Where is client located?: </label>
                  <div className="roe-card-body ml-2">
                    {props.editedData &&
                      props.editedData.visit_bill &&
                      props.editedData.visit_bill.client_pos}
                  </div>
                </div>
                <div>
                  <div className="form-group d-flex">
                    <label>Primary Diagnosis Code:</label>
                    <div className="roe-card-body ml-2">
                      {props.editedData &&
                        props.editedData.visit_bill &&
                        props.editedData.visit_bill.primary_diagnosis &&
                        props.editedData.visit_bill.primary_diagnosis?.name}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="form-group ">
                    <label>Secondary Diagnosis Codes:</label>
                    <div className="col-md-12">
                      <ol>
                        {diagnoseList &&
                          diagnoseList.map((type, i) => {
                            return <li key={i}>{type.diagnoses_code.name}</li>;
                          })}
                      </ol>
                    </div>
                  </div>
                </div>
              </>
            )}
        </div>
      </form>
      <Toaster ref={toaster} />
    </VisitTracking>
  );
};
export default compose(enhancer)(CompleteBillingForm);
