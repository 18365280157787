import axios from "axios";
import { baseUrl } from "../helper/constant";
import Header from "./Header";
import EncryptDecrypt from "./EncryptDecrypt";
import FileDownload from "js-file-download";

export default class RdServices {
  static list(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/rd/list`, EncryptDecrypt.encrypt(params));
  }

  static add(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/rd/add`, EncryptDecrypt.encrypt(params));
  }

  static edit(params) {
    Header.setHeaders();
    return axios.put(`${baseUrl}/rd/edit`, params);
  }
  static editSetting(params) {
    Header.setHeaders();
    return axios.put(
      `${baseUrl}/rd/edit-setting`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static view(params) {
    Header.setHeaders();
    return axios.get(`${baseUrl}/rd/view`, EncryptDecrypt.encrypt(params, 1));
  }

  static delete(params) {
    Header.setHeaders();
    return axios.get(`${baseUrl}/rd/delete`, EncryptDecrypt.encrypt(params, 1));
  }

  static status(params) {
    Header.setHeaders();
    return axios.get(`${baseUrl}/rd/status`, EncryptDecrypt.encrypt(params, 1));
  }

  static approve(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/rd/approved`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static listCategory(params = {}) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/rd/list-category`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static rdInsuranceList(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/rd/list-rd-insurance`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static rdCategoryList(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/rd/list-rd-category`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static editInsurance(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/rd/edit-insurance`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static addCategory(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/rd/add-category`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static editState(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/rd/edit-state-license`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static rdStateList(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/rd/list-rd-state`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static stateList(params) {
    Header.setHeaders();
    return axios.get(`${baseUrl}/rd/list-state`);
  }
  static appointmentScheduleList(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/appointment/list`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static goalList(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/rd/list-goal-unit`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static goalView(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/rd/view-goal-unit`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static goalAdd(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/rd/add-goal-unit`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static goalEdit(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/rd/edit-goal-unit`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static summary(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/rd/performance-summary`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static acitivityList(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/rd/activity`, EncryptDecrypt.encrypt(params));
  }

  static sendWelcomeMail(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/rd/send-welcome-email`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static cdrList(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/rd/list-cdr`, EncryptDecrypt.encrypt(params));
  }

  static cdrView(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/rd/view-cdr`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static cdrAdd(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/rd/add-cdr`, params);
  }

  static cdrEdit(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/rd/edit-cdr`, params);
  }

  static cdrDelete(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/rd/delete-cdr`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static liabilityInsuranceList(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/rd/list-liability-insurance`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static businessServiceAgreementList(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/rd/list-agreement`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static liabilityInsuranceView(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/rd/view-liability-insurance`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static liabilityInsuranceAdd(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/rd/add-liability-insurance`, params);
  }

  static businessAgreementAdd(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/rd/add-agreement`, params);
  }

  static businessAgreementEdit(params) {
    Header.setHeaders();
    return axios.put(`${baseUrl}/rd/update-agreement`, params);
  }

  static liabilityInsuranceEdit(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/rd/edit-liability-insurance`, params);
  }

  static liabilityInsuranceDelete(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/rd/delete-liability-insurance`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static businessAgreementDelete(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/rd/delete-agreement`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static caqhList(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/rd/list-caqh`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static caqhView(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/rd/view-caqh`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static caqhAdd(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/rd/add-caqh`, EncryptDecrypt.encrypt(params));
  }

  static caqhEdit(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/rd/edit-caqh`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static caqhDelete(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/rd/delete-caqh`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static lookup(params) {
    Header.setHeaders();
    return axios
      .get(`${baseUrl}/advancemd/rd-lookup`, EncryptDecrypt.encrypt(params))
      .then(res => {
        FileDownload(res.data, "RDLookup.csv");
      });
  }

  static serviceLocationList(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/rd/list-service-location`,
      params && EncryptDecrypt.encrypt(params, 1)
    );
  }

  static timezoneList(params) {
    Header.setHeaders();
    return axios.get(`${baseUrl}/rd/list-timezone`);
  }

  static languageList(params) {
    Header.setHeaders();
    return axios.get(`${baseUrl}/rd/list-language`);
  }

  static stateLicenseList(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/rd/list-state-license`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static stateLicenseView(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/rd/view-state-license`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static stateLicenseAdd(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/rd/add-state-license`, params);
  }

  static stateLicenseEdit(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/rd/edit-state-license`, params);
  }

  static stateLicenseDelete(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/rd/delete-state-license`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static stateLicenseStatus(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/rd/status-state-license`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static referralCodeList(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/rd/list-referral-code`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static referralCodeAdd(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/rd/add-referral-code`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static referralCodeEdit(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/rd/edit-referral-code`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static referralCodeView(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/rd/view-referral-code`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  //to ask
  static referralCodeStatus(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/rd/change-status-referral-code`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static listCalendlyUrl(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/rd/list-calendly-url`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static calendlyURLAdd(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/rd/add-calendly-url`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static calendlyURLEdit(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/rd/edit-calendly-url`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static calendlyURLDelete(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/rd/delete-calendly-url`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static pendingBillingList(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/pending-bills/list`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static pendingBillingCsv(params) {
    Header.setHeaders();
    return axios
      .post(
        `${baseUrl}/pending-bills/export-csv`,
        EncryptDecrypt.encrypt(params)
      )
      .then(res => {
        FileDownload(res.data, "PendingBillingList.csv");
      });
  }
}
