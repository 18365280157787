import axios from "axios";
import {baseUrl} from "../helper/constant";
import Header from "./Header";
import EncryptDecrypt from "./EncryptDecrypt"

export default class StateServices {
  static list(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/admin/state/list`, EncryptDecrypt.encrypt(params));
  }

  static add(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/admin/state/add`, EncryptDecrypt.encrypt(params));
  }
}
