import React, { useRef, useState } from "react";
import { ForgotIcon } from "helper/constant";
import AdminServices from "../../../api/AdminServices";
import Toaster from "../../../components/common/Toaster";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import enhancer from "./enhancer/ResetPasswordValidator";
import EncryptDecrypt from "../../../api/EncryptDecrypt";
import showPwdImg from "../../../assets/images/show-password.svg";
import hidePwdImg from "../../../assets/images/hide-password.svg";

const ChangePassword = props => {
  const loginContainer = {
    //backgroundImage: `url(${loginBack})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0,
    backgroundColor: "#AECE38"
  };

  const {
    errors,
    touched,
    submitCount,
    isValid,
    handleSubmit,
    values,
    handleChange,
    handleBlur
  } = props;

  const toaster = useRef();
  // const [password, setPassword] = useState();

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span></span>;
    }
  };

  function submitHandler(e) {
    e.preventDefault();
    if (isValid) {
      const params = {
        new_password: values.newPassword,
        email: props && props.email,
        otp: props && props.otp,
        otp_token: props && props.otp_token
      };
      AdminServices.resetpassword(params)
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          if (data.status) {
            if (toaster.current) toaster.current.success(data.message);
            setTimeout(() => {
              props.history.push("./login");
            }, 500);
          } else {
            if (toaster.current) toaster.current.error(data.message);
          }
        })
        .catch(err => {
          if (toaster.current) toaster.current.error(err.response);
        });
      handleSubmit();
    }
  }

  const [isRevealNewPwd, setIsRevealNewPwd] = useState(false);
  const [isRevealConfirmPwd, setIsRevealConfirmPwd] = useState(false);

  return (
    <div>
      <div className="container-fluid" style={loginContainer}>
        <div className="form-container">
          <div className="login-icon">
            <img src={ForgotIcon} alt="icon" height="100px" />
          </div>
          <div className="login-title">Reset Password</div>

          <div className="text-center form-info-text plr-24 mt-16">
            Enter Your new password
          </div>

          <form className="pa-24" onSubmit={submitHandler}>
            <div className="form-group">
              <div style={{ position: "relative" }}>
                <input
                  id="newPassword"
                  type={isRevealNewPwd ? "text" : "password"}
                  onPaste={e => e.preventDefault()}
                  className="form-control react-form-input"
                  aria-describedby="emailHelp"
                  placeholder="Enter Password"
                  // value={values.newPassword}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <img
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    width: "20px",
                    right: "8px",
                    top: "8px"
                  }}
                  title={isRevealNewPwd ? "Hide password" : "Show password"}
                  src={isRevealNewPwd ? hidePwdImg : showPwdImg}
                  onClick={() => setIsRevealNewPwd(prevState => !prevState)}
                />
              </div>
              <Error field="newPassword" />
            </div>
            <div className="form-group">
              <div style={{ position: "relative" }}>
                <input
                  id="confirmPassword"
                  type={isRevealConfirmPwd ? "text" : "password"}
                  onPaste={e => e.preventDefault()}
                  // value={values.confirmPassword}
                  className="form-control react-form-input"
                  aria-describedby="emailHelp"
                  placeholder="Confirm Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <img
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    width: "20px",
                    right: "8px",
                    top: "8px"
                  }}
                  title={isRevealConfirmPwd ? "Hide password" : "Show password"}
                  src={isRevealConfirmPwd ? hidePwdImg : showPwdImg}
                  onClick={() => setIsRevealConfirmPwd(prevState => !prevState)}
                />
              </div>
              <Error field="confirmPassword" />
            </div>
            <button type="submit" className="btn form-button">
              Reset Password
            </button>
          </form>
        </div>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};

export default compose(withRouter, enhancer)(ChangePassword);
