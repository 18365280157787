import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import ReportServices from "../../api/ReportServices";
import Loader from "../common/Loader";
import Toaster from "../common/Toaster";
import {
  TABLE,
  SERVICE_TYPE,
  PROVIDER_TYPES,
  DEFAULT_RD_DROPDOWN,
  PROVIDER_TYPE
} from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
import Button from "components/button/Button";
import ClientServices from "../../api/ClientServices";
import CategoryServices from "api/CategoryServices";
import moment from "moment";
import CommonModel from "components/common/CommonModel";
import { DateRangePicker } from "materialui-daterange-picker";

const ClientDetailTable = props => {
  const toaster = useRef(null);
  const [tblData, settblData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("id");
  const [sortBy, setSortBy] = useState("ASC");
  const [groupList, setGroupList] = useState([]);
  const [groupId, setGroupId] = useState(null);
  const [groupName, setGroupName] = useState(null);
  const [parentDropdownOpen, setParentDropdownOpen] = useState(false);
  const [RDList, setRDList] = useState([]);
  const [RDId, setRDId] = useState(null);
  const [RDDropdownOpen, setRDDropdownOpen] = useState(false);
  const [RDName, setRDName] = useState(null);
  const [serviceDropdownOpen, setServiceDropdownOpen] = useState(false);
  const [serviceId, setServiceId] = useState(null);
  const [serviceName, setServiceName] = useState(null);
  const [serviceList, setServiceList] = useState([]);
  const [statusId, setStatusId] = useState(null);
  const [statusName, setStatusName] = useState(null);
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [dateRange, setDateRange] = useState({});
  const [modal, setModal] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [typeName, setTypeName] = useState(null);
  const [typeValue, setTypeValue] = useState(null);
  const [typeDropdownOpen, setTypeDropdownOpen] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [filteredRDList, setFilteredRDList] = useState([]);

  useEffect(() => {
    const groupParams = {
      service_id: props.admin_role_id < 3 ? serviceId : props?.service_id
    };
    ClientServices.groupList(groupParams).then(result => {
      let data = EncryptDecrypt.decrypt(result.data);
      setGroupList(data.data);
    });
  }, [serviceId]);

  useEffect(() => {
    const params1 = {
      type: "",
      practice_id:
        props?.admin_role_id === 6 && props?.practice
          ? props?.practice
          : undefined,
      id: props.admin_role_id > 3 ? props?.service_id : undefined
    };

    ClientServices.RDList(params1).then(result => {
      let data = EncryptDecrypt.decrypt(result.data);
      setRDList(data.data);
    });
  }, []);

  useEffect(() => {
    let filtered = [...RDList];

    if (serviceId) {
      filtered = filtered.filter(rd => {
        return (
          rd?.rd_detail?.service_id === serviceId ||
          rd?.id === Number(DEFAULT_RD_DROPDOWN)
        );
      });
    }

    if (typeValue) {
      filtered = filtered.filter(rd => {
        return rd?.rd_detail?.type === typeValue;
      });
    }

    // if (!serviceId && !typeValue) filtered = [];

    setFilteredRDList([...filtered]);
  }, [serviceId, typeValue, RDList]);

  useEffect(() => {
    CategoryServices.serviceDropdown()
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setServiceList([...data.data]);
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      });
  }, []);

  useEffect(() => {
    getList(field, sortBy, searchText, activePage + 1);
    setPages(10);
  }, [
    groupId,
    RDId,
    serviceId,
    statusId,
    typeValue,
    dateRange.startDate,
    dateRange.endDate
  ]);

  const toggle = () => {
    setOpen(!open);
    setModal(!open);
  };

  const toggleRD = () => setRDDropdownOpen(prevState => !prevState);

  const toggleOne = () => setParentDropdownOpen(prevState => !prevState);

  const toggleStatus = () => setStatusDropdownOpen(prevState => !prevState);

  const toggleType = () => setTypeDropdownOpen(prevState => !prevState);

  const toggleService = () => setServiceDropdownOpen(prevState => !prevState);

  const columns = [
    {
      Header: "Client ID",
      accessor: "id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 150
    },
    {
      Header: "First Name",
      accessor: "first_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 150
    },
    {
      Header: "Last Name",
      accessor: "last_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 150
    },
    {
      Header: "Status",
      accessor: "is_active",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",
      sortable: false,
      width: 150,
      Cell: ({ value }) => <div>{!!value ? "Active" : "Inactive"}</div>
    },
    {
      Header: "Street Address",
      accessor: "address",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200
    },
    {
      Header: "City",
      accessor: "city",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200
    },
    {
      Header: "State",
      accessor: "state.name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200
    },
    {
      Header: "Zip",
      accessor: "zipcode",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 150
    },
    {
      Header: "DOB",
      accessor: "dob",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 150,
      Cell: props =>
        props.original.dob
          ? moment(props.original.dob).format("MM/DD/YYYY")
          : ""
    },
    {
      Header: "Goal",
      accessor: "",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      width: 100,
      Cell: props => (
        <div className="react-action-class">
          {props.original.user_goals.length}
        </div>
      )
    },
    {
      Header: "Email",
      accessor: "email",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200
    },
    {
      Header: "Group",
      accessor: "group.name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200
    },

    {
      Header: "Insurance Carrier",
      accessor: "`user_insurance->insurance`.`name`",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200,
      Cell: props => (
        <div className="react-action-class">
          {props.original.user_insurance &&
          props.original.user_insurance.insurance
            ? props.original.user_insurance.insurance.name
            : ""}
        </div>
      )
    },
    {
      Header: "Member ID",
      accessor: "`user_insurance`.`member_id`",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 150,
      Cell: props => (
        <div className="react-action-class">
          {props.original.user_insurance
            ? props.original.user_insurance.member_id
            : ""}
        </div>
      )
    },
    {
      Header: "Group ID",
      accessor: "`user_insurance`.`group_id`",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 150,
      Cell: props => (
        <div className="react-action-class">
          {props.original.user_insurance
            ? props.original.user_insurance.group_id
            : ""}
        </div>
      )
    },
    {
      Header: "Primary subscriber",
      accessor: "`user_insurance`.`is_primary_subscriber`",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200,
      Cell: props => (
        <div className="react-action-class">
          {props.original.user_insurance
            ? props.original.user_insurance.is_primary_subscriber
            : ""}
        </div>
      )
    },
    {
      Header: "Primary subscriber First Name",
      accessor: "`user_insurance`.`primary_subscriber_first_name`",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 300,
      Cell: props => (
        <div className="react-action-class">
          {props.original.user_insurance
            ? props.original.user_insurance.primary_subscriber_first_name
            : ""}
        </div>
      )
    },
    {
      Header: "Primary subscriber Last Name",
      accessor: "`user_insurance`.`primary_subscriber_last_name`",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 300,
      Cell: props => (
        <div className="react-action-class">
          {props.original.user_insurance
            ? props.original.user_insurance.primary_subscriber_last_name
            : ""}
        </div>
      )
    },
    {
      Header: "Primary subscriber DOB",
      accessor: "`user_insurance`.`primary_subscriber_dob`",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 250,
      Cell: props => (
        <div className="react-action-class">
          {props.original.user_insurance &&
          props.original.user_insurance.primary_subscriber_dob &&
          props.original.user_insurance.primary_subscriber_dob != "0000-00-00"
            ? moment(
                props.original.user_insurance.primary_subscriber_dob
              ).format("MM/DD/YYYY")
            : ""}
        </div>
      )
    },
    {
      Header: "Visit Program",
      accessor: "`group->group_visit_programs->visit_program`.`name`",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200,
      Cell: props => (
        <div className="react-action-class">
          {props.original.group &&
          props.original.group.group_visit_programs.length > 0
            ? props.original.group.group_visit_programs[0].visit_program.name
            : ""}
        </div>
      )
    },
    {
      Header: "Scale Program",
      accessor: "`group->group_scale_programs->scale_program`.`name`",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200,
      Cell: props => (
        <div className="react-action-class">
          {props.original.group &&
          props.original.group.group_scale_programs.length > 0
            ? props.original.group.group_scale_programs[0].scale_program.name
            : ""}
        </div>
      )
    },
    {
      Header: "Nutrition Provider",
      accessor: "`nutrition_provider->admin`.`first_name`",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 200,
      sortable: false,
      Cell: props => (
        <div className="react-action-class">
          {props.original?.nutrition_provider?.admin
            ? props.original?.nutrition_provider?.admin?.first_name +
              " " +
              props.original?.nutrition_provider?.admin?.last_name
            : ""}
        </div>
      )
    },
    {
      Header: "Registration Code (N)",
      accessor: "`nutrition_status->registration_code`",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 200,
      sortable: false,
      Cell: props => (
        <div className="react-action-class">
          {props.original?.nutrition_status
            ? props.original?.nutrition_status?.registration_code
            : ""}
        </div>
      )
    },
    {
      Header: "Mental Health Provider",
      accessor: "`mental_health_provider->admin`.`first_name`",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 200,
      sortable: false,
      Cell: props => (
        <div className="react-action-class">
          {props.original?.mental_health_provider?.admin
            ? props.original?.mental_health_provider?.admin?.first_name +
              " " +
              props.original?.mental_health_provider?.admin?.last_name
            : ""}
        </div>
      )
    },
    {
      Header: "Registration Code (MH)",
      accessor: "`mental_health_status->registration_code`",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 200,
      sortable: false,
      Cell: props => (
        <div className="react-action-class">
          {props.original?.mental_health_status
            ? props.original?.mental_health_status?.registration_code
            : ""}
        </div>
      )
    },
    {
      Header: "Registration Date",
      accessor: "created_at",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 150,
      Cell: props =>
        props.original.created_at
          ? moment(props.original.created_at).format("MM/DD/YYYY")
          : ""
    },
    {
      Header: "Days since last visit - Nutrition",
      accessor: "nutrition_last_visit",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",
      sortable: false,
      width: 250,
      Cell: ({ value }) => (
        <div>
          {value && value?.length
            ? Math.floor(
                moment(moment().format("YYYY-MM-DD"))
                  .utc()
                  .diff(moment(value[0]?.appointment_date), "days", true)
              )
            : "-"}
        </div>
      )
    },
    {
      Header: "Days since last visit - Mental Health",
      accessor: "mental_health_last_visit",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",
      sortable: false,
      width: 290,
      Cell: ({ value }) => (
        <div>
          {value && value?.length
            ? Math.floor(
                moment(moment().format("YYYY-MM-DD"))
                  .utc()
                  .diff(moment(value[0]?.appointment_date), "days", true)
              )
            : "-"}
        </div>
      )
    },
    {
      Header: "Days until next visit - Nutrition",
      accessor: "nutrition_next_visit",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",
      sortable: false,
      width: 250,
      Cell: ({ value }) => (
        <div>
          {value && value?.length
            ? Math.floor(
                moment(value[0]?.appointment_date).diff(
                  moment(moment().format("YYYY-MM-DD")).utc(),
                  "days",
                  true
                )
              )
            : "-"}
        </div>
      )
    },
    {
      Header: "Days until next visit - Mental Health",
      accessor: "mental_health_next_visit",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",
      sortable: false,
      width: 290,
      Cell: ({ value }) => (
        <div>
          {value && value?.length
            ? Math.floor(
                moment(value[0]?.appointment_date).diff(
                  moment(moment().format("YYYY-MM-DD")).utc(),
                  "days",
                  true
                )
              )
            : "-"}
        </div>
      )
    },
    {
      Header: "Status - Nutrition",
      accessor: "nutrition_status",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",
      sortable: false,
      width: 150,
      Cell: ({ value }) => (
        <div>{!!value?.is_active ? "Active" : "Inactive"}</div>
      )
    },
    {
      Header: "Status - Mental Health",
      accessor: "mental_health_status",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",
      sortable: false,
      width: 180,
      Cell: ({ value }) => (
        <div>{!!value?.is_active ? "Active" : "Inactive"}</div>
      )
    }
  ];

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  function exportList(
    field = "name",
    sortBy = "DESC",
    search = "",
    page = "1"
  ) {
    const params = {
      sort_by: field,
      order: sortBy,
      service_id: props.admin_role_id <= 2 ? serviceId : props?.service_id,
      search: search,
      page: page,
      group_id: groupId,
      rd_id:
        props?.admin_role_id && props?.admin_role_id !== 3 ? RDId : props?.id,
      is_active: statusId,
      start_date: dateRange.startDate
        ? moment(dateRange.startDate).format("YYYY-MM-DD")
        : null,
      end_date: dateRange.endDate
        ? moment(dateRange.endDate).format("YYYY-MM-DD")
        : null,
      rd_type: typeValue,
      practice_id:
        props?.admin_role_id === 6 && props?.practice
          ? props?.practice
          : undefined
    };
    setIsExporting(true);
    ReportServices.clientDetailListCsv(params)
      .then(res => {
        setIsExporting(false);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error("Something went wrong!");
        setIsExporting(false);
      });
  }

  const formAction = (action, data = null) => {
    // Here you can view the data and make forward action for edit data
    if (action === "export") {
      exportList(field, sortBy, searchText, activePage + 1);
    }
  };

  function getList(field = "name", sortBy = "DESC", search = "", page = "1") {
    const params = {
      sort_by: field,
      order: sortBy,
      search: search,
      service_id: props.admin_role_id <= 2 ? serviceId : props?.service_id,
      page: page,
      group_id: groupId,
      rd_id:
        props?.admin_role_id && props?.admin_role_id !== 3 ? RDId : props?.id,
      is_active: statusId,
      start_date: dateRange.startDate
        ? moment(dateRange.startDate).format("YYYY-MM-DD")
        : null,
      end_date: dateRange.endDate
        ? moment(dateRange.endDate).format("YYYY-MM-DD")
        : null,
      rd_type: typeValue,
      practice_id:
        props?.admin_role_id === 6 && props?.practice
          ? props?.practice
          : undefined
    };

    setLoader(true);
    ReportServices.clientDetailList(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count.length / TABLE.LIMIT));
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
        setLoader(false);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.message);
        setLoader(false);
      });
  }

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Client Details Report
            </div>
            <div>
              <Button
                className="c-btn c-primary ma-5"
                onClick={() => formAction("export")}
                disabled={!tblData?.length || isExporting}
              >
                <i className="fas fa-download mr-10" />
                {isExporting ? "Exporting.." : "Export"}
              </Button>
            </div>
          </div>
          <div className="roe-card-header module-header">
            <div className="d-flex">
              <div className="fs-14 mr-10">
                <span className="fs-16">Date</span>
                <br></br>
                <Button
                  className="btn btn-secondary btn-md"
                  onClick={() => toggle()}
                >
                  {dateRange.startDate
                    ? `${moment(dateRange.startDate).format(
                        "MM/DD/YYYY"
                      )} - ${moment(dateRange.endDate).format("MM/DD/YYYY")}`
                    : "Select Date"}
                </Button>
                {dateRange && dateRange.startDate && (
                  <Button
                    className="btn btn-secondary btn-md ml-2"
                    onClick={() => {
                      setActivePage(0);
                      setDateRange({});
                    }}
                  >
                    Reset date
                  </Button>
                )}
              </div>

              <div className="fs-14 mr-10">
                <span className="fs-16">Group</span>
                <Dropdown isOpen={parentDropdownOpen} toggle={toggleOne}>
                  <DropdownToggle caret size="md">
                    {groupName ? groupName : "Select Group"}
                  </DropdownToggle>
                  <DropdownMenu
                    modifiers={{
                      setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: data => {
                          return {
                            ...data,
                            styles: {
                              ...data.styles,
                              overflow: "auto",
                              maxHeight: "300px"
                            }
                          };
                        }
                      }
                    }}
                  >
                    <DropdownItem
                      key="0"
                      onClick={() => {
                        setActivePage(0);
                        setGroupId("");
                        setGroupName("All");
                      }}
                    >
                      All
                    </DropdownItem>
                    {groupList.length > 0 &&
                      groupList.map((result, i) => {
                        return (
                          <DropdownItem
                            key={i}
                            onClick={() => {
                              setActivePage(0);
                              setGroupId(result.id);
                              setGroupName(result.name);
                            }}
                          >
                            {result.name}
                          </DropdownItem>
                        );
                      })}
                  </DropdownMenu>
                </Dropdown>
              </div>
              {props.admin_role_id !== 3 && props?.admin_role_id !== 6 && (
                <div className="fs-14 mr-10">
                  <span className="fs-16">Type</span>
                  <Dropdown isOpen={typeDropdownOpen} toggle={toggleType}>
                    <DropdownToggle caret size="md">
                      {typeName ? typeName : "Type"}
                    </DropdownToggle>
                    <DropdownMenu
                      modifiers={{
                        setMaxHeight: {
                          enabled: true,
                          order: 890,
                          fn: data => {
                            return {
                              ...data,
                              styles: {
                                ...data.styles,
                                overflow: "auto",
                                maxHeight: "300px"
                              }
                            };
                          }
                        }
                      }}
                    >
                      <DropdownItem
                        key="-1"
                        onClick={() => {
                          setRDId(null);
                          setRDName(null);
                          setTypeName("All");
                          setTypeValue("");
                          setActivePage(0);
                        }}
                      >
                        All
                      </DropdownItem>
                      {PROVIDER_TYPES.map((result, i) => {
                        return (
                          <DropdownItem
                            key={i}
                            onClick={() => {
                              setRDId(null);
                              setRDName(null);
                              setTypeName(`${result.name}`);
                              setTypeValue(`${result.value}`);
                              setActivePage(0);
                            }}
                          >
                            {result.name}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </Dropdown>
                </div>
              )}
              {props.admin_role_id < 3 && (
                <>
                  <div className="fs-14  mr-10">
                    <span className="fs-16">Service</span>
                    <Dropdown
                      isOpen={serviceDropdownOpen}
                      toggle={toggleService}
                    >
                      <DropdownToggle caret size="md">
                        {serviceId ? serviceName : "All"}
                      </DropdownToggle>
                      <DropdownMenu
                        modifiers={{
                          setMaxHeight: {
                            enabled: true,
                            order: 890,
                            fn: data => {
                              return {
                                ...data,
                                styles: {
                                  ...data.styles,
                                  overflow: "auto",
                                  maxHeight: "300px"
                                }
                              };
                            }
                          }
                        }}
                      >
                        <DropdownItem
                          key="-1"
                          onClick={() => {
                            setActivePage(0);
                            setServiceId("");
                            setServiceName("All");
                            setRDId("");
                            setRDName("");
                          }}
                        >
                          All
                        </DropdownItem>
                        {serviceList.length > 0 &&
                          serviceList.map((result, i) => {
                            return (
                              <DropdownItem
                                key={i}
                                onClick={() => {
                                  setActivePage(0);
                                  setServiceId(result.id);
                                  setServiceName(`${result.name}`);
                                  setRDId("");
                                  setRDName("");
                                }}
                              >
                                {result.name}
                              </DropdownItem>
                            );
                          })}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </>
              )}
              {props.admin_role_id !== 3 && (
                <div className="fs-14  mr-10">
                  <span className="fs-16">Providers</span>
                  <Dropdown isOpen={RDDropdownOpen} toggle={toggleRD}>
                    <DropdownToggle caret size="md">
                      {RDName ? RDName : "Providers"}
                    </DropdownToggle>
                    <DropdownMenu
                      modifiers={{
                        setMaxHeight: {
                          enabled: true,
                          order: 890,
                          fn: data => {
                            return {
                              ...data,
                              styles: {
                                ...data.styles,
                                overflow: "auto",
                                maxHeight: "300px"
                              }
                            };
                          }
                        }
                      }}
                    >
                      <DropdownItem
                        key="-1"
                        onClick={() => {
                          setActivePage(0);
                          setRDId("");
                          setRDName("All");
                        }}
                      >
                        All
                      </DropdownItem>

                      {filteredRDList.length > 0 &&
                        filteredRDList.map((result, i) => {
                          return (
                            <DropdownItem
                              key={i + 1}
                              onClick={() => {
                                setActivePage(0);
                                setRDId(result.id);
                                setRDName(
                                  `${result.first_name} ${result.last_name}`
                                );
                              }}
                            >
                              {result.first_name} {result.last_name}{" "}
                              {result?.rd_detail?.type
                                ? `(${PROVIDER_TYPE[result?.rd_detail?.type]})`
                                : "(-)"}
                            </DropdownItem>
                          );
                        })}
                    </DropdownMenu>
                  </Dropdown>
                </div>
              )}

              <div className="fs-14  mr-10">
                <span className="fs-16">Status</span>
                <Dropdown isOpen={statusDropdownOpen} toggle={toggleStatus}>
                  <DropdownToggle caret size="md">
                    {statusId || statusId === 0 ? statusName : "All"}
                  </DropdownToggle>
                  <DropdownMenu
                    modifiers={{
                      setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: data => {
                          return {
                            ...data,
                            styles: {
                              ...data.styles,
                              overflow: "auto",
                              maxHeight: "300px"
                            }
                          };
                        }
                      }
                    }}
                  >
                    <DropdownItem
                      key="0"
                      onClick={() => {
                        setActivePage(0);
                        setStatusId(null);
                        setStatusName("All");
                      }}
                    >
                      All
                    </DropdownItem>

                    <DropdownItem
                      key="1"
                      onClick={() => {
                        setActivePage(0);
                        setStatusId(1);
                        setStatusName("Active");
                      }}
                    >
                      Active
                    </DropdownItem>
                    <DropdownItem
                      key="2"
                      onClick={() => {
                        setActivePage(0);
                        setStatusId(0);
                        setStatusName("Inactive");
                      }}
                    >
                      Inactive
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              LoadingComponent={Loader}
              onFetchData={onChangePageGetData}
              onPageChange={pageIndex => {
                setActivePage(pageIndex);
                getList(field, sortBy, searchText, pageIndex + 1);
              }}
              onSortedChange={sortProperties => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getList(sortProperties[0].id, sort, searchText, 1);
                // alert("sort handler", sortProperties);
                // const orderByDirection = sortProperties[0].desc ? 1 : 0;

                // updateMapStateInfoOrderByColumn(sortProperties[0].id, orderByDirection);
              }}
            />
          </div>
          <CommonModel
            modal={modal}
            toggle={toggle}
            size="lg"
            children={
              <DateRangePicker
                open={open}
                toggle={() => toggle()}
                onChange={range => {
                  setActivePage(0);
                  setDateRange(range);
                  // setModal(!modal)
                  toggle();
                }}
                closeOnClickOutside={true}
              />
            }
          />
        </div>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};

export default withRouter(ClientDetailTable);
