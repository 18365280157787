import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    insurance_id: Yup.string()
      .nullable()
      .required("This field is required"),
    member_id: Yup.string()
      .required("This field is required")
      .matches(/^[a-z0-9]+$/i, "Must contain numbers and letters only"),
    group_id: Yup.string()
      .notRequired()
      .matches(/^[a-z0-9]+$/i, "Must contain numbers and letters only"),
    subscriber: Yup.string().required("This field is required"),
    is_dependent: Yup.bool()
      .default(false)
      .required("This field is required"),
    subscriber_data: Yup.object()
      .nullable()
      .notRequired()
      .when("is_dependent", {
        is: is_dependent => is_dependent == true,
        then: Yup.object()
          .shape({
            primary_subscriber_first_name: Yup.string()
              .matches(/^[A-Za-z0-9 ]+$/, "Cannot include special characters")
              .nullable()
              .required("This field is required"),
            primary_subscriber_last_name: Yup.string()
              .matches(/^[A-Za-z0-9 ]+$/, "Cannot include special characters")
              .nullable()
              .required("This field is required"),
            primary_subscriber_dob: Yup.date()
              .nullable()
              .required("This field is required"),
            primary_subscriber_gender: Yup.string()
              .nullable()
              .required("This field is required")
          })
          .required("This field required")
      }),
    front_insurance_image: Yup.string().notRequired(),
    back_insurance_image: Yup.string().notRequired(),
    primary_subscriber_first_name_new: Yup.string()
      .trim("Cannot include leading and trailing spaces")
      .strict(true),
    primary_subscriber_last_name_new: Yup.string()
      .trim("Cannot include leading and trailing spaces")
      .strict(true)
  }),
  mapPropsToValues: props => ({
    id: props.editedData ? props.editedData.id : "",
    insurance_id:
      props.editedData && props.editedData.type == "edit"
        ? props.editedData.insurance
        : "",
    member_id:
      props.editedData && props.editedData.member_id
        ? props.editedData.member_id
        : "",
    group_id:
      props.editedData && props.editedData.group_id
        ? props.editedData.group_id
        : "",
    subscriber:
      props.editedData &&
      props.editedData.type == "edit" &&
      props.editedData.is_primary_subscriber
        ? props.editedData.is_primary_subscriber
        : "",
    is_dependent:
      props.editedData &&
      props.editedData.type == "edit" &&
      props.editedData.is_primary_subscriber == "Self"
        ? false
        : true,
    front_insurance_image:
      props.editedData && props.editedData.front_insurance_image
        ? props.editedData.front_insurance_image
        : "",
    back_insurance_image:
      props.editedData && props.editedData.back_insurance_image
        ? props.editedData.back_insurance_image
        : "",
    subscriber_data: {
      primary_subscriber_first_name:
        props.editedData && props.editedData.type == "edit"
          ? props.editedData.primary_subscriber_first_name
          : "",
      primary_subscriber_last_name:
        props.editedData && props.editedData.type == "edit"
          ? props.editedData.primary_subscriber_last_name
          : "",
      primary_subscriber_dob:
        props.editedData && props.editedData.type == "edit"
          ? props.editedData.primary_subscriber_dob
          : new Date(),
      primary_subscriber_gender:
        props.editedData && props.editedData.type == "edit"
          ? props.editedData.primary_subscriber_gender
          : "",
      primary_subscriber_first_name_new:
        props.editedData && props.editedData.type == "edit"
          ? props.editedData.primary_subscriber_first_name
          : "",
      primary_subscriber_last_name_new:
        props.editedData && props.editedData.type == "edit"
          ? props.editedData.primary_subscriber_last_name
          : ""
    }
  }),

  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true,
  isInitialValid: props => (props.editedData.type === "edit" ? true : false)
});

export default formikEnhancer;
