import React, { useRef, useEffect, useState } from "react";
import { compose } from "redux";
import enhancer from "../visittracking/validator";
import Button from "../button/Button";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import VisitTracking from "./visittracking.style";
import ClientServices from "../../api/ClientServices";
import VisitBilling from "../../api/VisitBillingService";
import RoyTooltip from "components/common/RoyTooltip";

import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
import { RD, SERVICE_TYPE, VISIT_STATUS } from "helper/constant";
import { convertToFixedOffset, diffMinutes } from "components/roeeditor/utils";
import Toaster from "components/common/Toaster";

const monthArr = [
  {
    key: "Virtual",
    value: "Virtual (GT - 95 Modifier)",
    posType: ["At home - 10", "Other - 02"]
  },
  {
    key: "In Person",
    value: "In Person",
    posType: ["Office - 11", "Place of Employment - 18"]
  }
];

const serviceType = ["Individual Session", "Group Session"];
const billUnitArr = [
  {
    key: 0,
    value: "0 units - Not billable",
    start_time: 0,
    end_time: 7
  },
  {
    key: 1,
    value: "1 unit - 8-22 minutes",
    start_time: 8,
    end_time: 22
  },
  {
    key: 2,
    value: "2 units - 23-37 minutes",
    start_time: 23,
    end_time: 37
  },
  {
    key: 3,
    value: "3 units - 38-52 minutes",
    start_time: 38,
    end_time: 52
  },
  {
    key: 4,
    value: "4 units - 53-67 minutes",
    start_time: 53,
    end_time: 67
  },
  {
    key: 5,
    value: "5 units - 68-82 minutes",
    start_time: 68,
    end_time: 82
  },
  {
    key: 6,
    value: "6 units - 83-97 minutes",
    start_time: 83,
    end_time: 97
  },
  {
    key: 7,
    value: "7 units - 98-112 minutes",
    start_time: 98,
    end_time: 112
  },
  {
    key: 8,
    value: "8 units - 113-127 minutes",
    start_time: 113,
    end_time: 127
  }
];

const paymentArr = [
  "Insurance Funded",
  "Group Funded",
  "Client Funded",
  "Consumer Model",
  "Other - Free Consultation"
];
let data = [];
const checkedArr = [];

const CompleteBillingForm = props => {
  const hasParam = props.match.params.id;
  const toaster = useRef(null);
  const {
    values,
    handleChange,
    handleBlur,
    errors,
    setFieldTouched,
    touched,
    submitCount,
    setFieldValue,
    isValid,
    handleSubmit
  } = props;

  const [editedData, setEditedData] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);
  const [parentDropdownOpen, setParentDropdownOpen] = useState(false);
  const [serviceDropdownOpen, setServiceDropdownOpen] = useState(false);
  const [posDropdownOpen, setPosDropdownOpen] = useState(false);
  const [paymentDropdownOpen, setPaymentDropdownOpen] = useState(false);
  const [CPTDropdownOpen, setCPTDropdownOpen] = useState(false);
  const [diagnosesDropdownOpen, setDiagnosesDropdownOpen] = useState(false);
  const [billDropdownOpen, setBillDropdownOpen] = useState(false);
  const [parentCategoryId, setParentCategoryId] = useState(null);
  const [userInsuranceId, setUserInsuranceId] = useState(null);
  const [serviceId, setserviceId] = useState(null);
  const [posId, setPosId] = useState(null);
  const [paymentId, setPaymentId] = useState(null);
  const [CPTCode, setCPTCode] = useState(null);
  const [CPTCodename, setCPTCodeName] = useState(null);
  const [diagnosesCode, setDiagnosesCode] = useState(null);
  const [diagnosesCodeName, setDiagnosesCodeName] = useState(null);
  const [isBill, setBill] = useState(false);
  const [radioBtn, setRadioBtn] = useState(false);
  const [userInsuranceDropdownOpen, setUserInsuranceDropdownOpen] = useState(
    false
  );
  const [CPTList, setCPTList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [billUnit, setBillUnit] = useState(null);
  const [stateId, setStateId] = useState(null);
  const [insuranceId, setInsuranceId] = useState(null);
  const [userInsuranceList, setUserInsuranceList] = useState([]);
  const [diagnoseList, setDiagnoseList] = useState([]);
  const [otherDiagnoseList, setOtherDiagnoseList] = useState([]);
  const [diagnoseId, setDiagnoseId] = useState([]);
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(null);

  const [serviceLocationName, setServiceLocationName] = useState("");

  const [serviceLocationList, setServiceLocationList] = useState([]);
  const [
    serviceLocationDropdownOpen,
    setServiceLocationDropdownOpen
  ] = useState(false);
  const [serviceDuration, setServiceDuration] = useState("");
  const [secondaryRecentCodes, setSecondaryRecentCodes] = useState([]);
  const [secondaryOtherCodes, setSecondaryOtherCodes] = useState([]);

  const toggleOne = () => setParentDropdownOpen(prevState => !prevState);
  const toggleTwo = () => setPaymentDropdownOpen(prevState => !prevState);
  const toggleThree = () => setCPTDropdownOpen(prevState => !prevState);
  const toggleDiagnoses = () =>
    setDiagnosesDropdownOpen(prevState => !prevState);
  const toggleFour = () => setBillDropdownOpen(prevState => !prevState);
  const toggleFive = () => setServiceDropdownOpen(prevState => !prevState);
  const toggleSix = () => setPosDropdownOpen(prevState => !prevState);
  const toggleSeven = () =>
    setUserInsuranceDropdownOpen(prevState => !prevState);
  const serviceLocationToggle = () =>
    setServiceLocationDropdownOpen(prevState => !prevState);

  useEffect(() => {
    setFieldValue("service_start_time", new Date());
    setFieldTouched("service_start_time", true, true);

    if (props?.editedData?.visit_bill) {
      let obj1 = monthArr.find(
        o => o.key === props?.editedData?.visit_bill?.method_of_services
      );
      setParentCategoryId(obj1);
      setserviceId(props?.editedData?.visit_bill?.type_of_service);
      setPosId(props?.editedData?.visit_bill?.client_pos);
      setPaymentId(props?.editedData?.visit_bill?.payment_method);

      if (
        props?.editedData?.visit_bill?.service_location_detail?.name &&
        props?.editedData?.visit_bill?.service_location_id
      ) {
        setServiceLocationName(
          props?.editedData?.visit_bill?.service_location_detail?.name
        );
        setFieldValue(
          "service_location_id",
          props?.editedData?.visit_bill?.service_location_id
        );

        setFieldTouched("service_location_id", true, true);
      }

      if (
        props?.editedData?.visit_bill?.service_date &&
        props?.editedData?.visit_bill?.service_start_time
      ) {
        setStartTime(
          new Date(
            convertToFixedOffset(
              moment(
                props?.editedData?.visit_bill?.service_date +
                  " " +
                  props?.editedData?.visit_bill?.service_start_time
              ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
              moment.tz.guess(),
              "YYYY/MM/DD HH:mm:ss"
            )
          )
        );
        setFieldValue(
          "service_start_time",
          new Date(
            convertToFixedOffset(
              moment(
                props?.editedData?.visit_bill?.service_date +
                  " " +
                  props?.editedData?.visit_bill?.service_start_time
              ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
              moment.tz.guess(),
              "YYYY/MM/DD HH:mm:ss"
            )
          )
        );
        setFieldTouched("service_start_time", true, true);
      }
      if (
        props?.editedData?.visit_bill?.service_date &&
        props?.editedData?.visit_bill?.service_end_time
      ) {
        setEndTime(
          new Date(
            convertToFixedOffset(
              moment(
                props?.editedData?.visit_bill?.service_date +
                  " " +
                  props?.editedData?.visit_bill?.service_end_time
              ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
              moment.tz.guess(),
              "YYYY/MM/DD HH:mm:ss"
            )
          )
        );
        setFieldValue(
          "service_end_time",
          new Date(
            convertToFixedOffset(
              moment(
                props?.editedData?.visit_bill?.service_date +
                  " " +
                  props?.editedData?.visit_bill?.service_end_time
              ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
              moment.tz.guess(),
              "YYYY/MM/DD HH:mm:ss"
            )
          )
        );
        setFieldTouched("service_end_time", true, true);
      }
      let obj = billUnitArr.find(
        o => o.key === props?.editedData?.visit_bill?.billable_unit
      );

      if (obj) setBillUnit(obj["value"]);

      setStateId(props?.editedData?.visit_bill?.state_id);
      setBill(props?.editedData?.is_billing_complete);
      setRadioBtn(props?.editedData?.visit_bill?.is_prequalification);
      let data = props?.editedData?.visit_bill?.visit_bills_diagnoses.map(
        result => {
          return result.diagonoses_id;
        }
      );
      if (props?.editedData?.visit_bill?.cpt_code) {
        setCPTCode(props?.editedData?.visit_bill?.cpt_code?.id);
        setCPTCodeName(props?.editedData?.visit_bill?.cpt_code?.name);
      }
      setDiagnoseId([...data]);
      setInsuranceId(props?.editedData?.visit_bill?.user_insurance?.id);
      setUserInsuranceId(props?.editedData?.visit_bill?.user_insurance);

      if (props?.editedData?.visit_bill?.primary_diagnosis_code_id) {
        setFieldValue(
          "primary_diagnosis_code_id",
          props?.editedData?.visit_bill?.primary_diagnosis_code_id
        );
        setDiagnosesCode(
          props?.editedData?.visit_bill?.primary_diagnosis_code_id
        );
        setDiagnosesCodeName(
          props?.editedData?.visit_bill?.primary_diagnosis
            ? props?.editedData?.visit_bill?.primary_diagnosis.name
            : ""
        );
      }
    } else {
      setStateId(editedData && editedData?.user?.state_id);
      setFieldValue("service_start_time", new Date());
      setFieldTouched("service_start_time", true, true);
    }
  }, [props.editedData]);

  useEffect(() => {
    if (hasParam) {
      const params = {
        id: hasParam,
        appointment_id: props?.editedData?.id
      };

      ClientServices.view(params)
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          setEditedData(data?.data);
          if (!insuranceId) {
            setInsuranceId(data?.data && data?.data?.user_insurance.id);
          }

          const newParam = {
            insurance_id:
              data?.data &&
              data?.data.user_insurance &&
              data?.data?.user_insurance?.insurance &&
              data?.data?.user_insurance?.insurance?.id
          };
          VisitBilling.CPTCodeList(newParam).then(res => {
            let data = EncryptDecrypt.decrypt(res.data);
            setCPTList(data?.data);
          });
        })
        .catch(err => {
          if (err?.response?.status === 403) {
            if (toaster.current) {
              toaster.current.error("You cannot access this user info!");
              setTimeout(() => {
                props.history.push("/client");
              }, 3000);
            }
          } else {
            if (toaster.current) toaster.current.error(err?.message);
          }
        });

      VisitBilling.stateList().then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        setStateList(data?.data);
      });
      const diaganoseListParams = {
        client_id: hasParam
      };
      VisitBilling.diagnoseList(diaganoseListParams)
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          setDiagnoseList(data?.data);
          setOtherDiagnoseList(data?.data?.diagnosisCodes);
          setSecondaryRecentCodes(data?.data?.recentlyUsedOtherDiagnosisCodes);
          setSecondaryOtherCodes(data?.data?.otherDiagnosisCodes);

          if (
            props.editedData &&
            props?.editedData?.visit_bill &&
            props?.editedData?.visit_bill?.primary_diagnosis_code_id
          ) {
            setOtherDiagnoseList(
              data?.data?.diagnosisCodes?.filter(
                v =>
                  v.id !=
                  props?.editedData?.visit_bill?.primary_diagnosis_code_id
              )
            );
            setSecondaryRecentCodes(
              data?.data?.recentlyUsedOtherDiagnosisCodes?.filter(
                v =>
                  v.id !=
                  props?.editedData?.visit_bill?.primary_diagnosis_code_id
              )
            );
            setSecondaryOtherCodes(
              data?.data?.otherDiagnosisCodes?.filter(
                v =>
                  v.id !=
                  props?.editedData?.visit_bill?.primary_diagnosis_code_id
              )
            );
          }
        })
        .catch(err => {
          if (err?.response?.status === 403) {
            if (toaster.current) {
              toaster.current.error("You cannot access this user info!");
              setTimeout(() => {
                props.history.push("/client");
              }, 3000);
            }
          } else {
            if (toaster.current) toaster.current.error(err?.message);
          }
        });

      VisitBilling.userInsuranceList({ user_id: hasParam })
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          setUserInsuranceList(data?.data);
        })
        .catch(err => {
          if (err?.response?.status === 403) {
            if (toaster.current) {
              toaster.current.error("You cannot access this user info!");
              setTimeout(() => {
                props.history.push("/client");
              }, 3000);
            }
          } else {
            if (toaster.current) toaster.current.error(err?.message);
          }
        });
    }
  }, []);

  useEffect(() => {
    if (props?.editedData && editedData) {
      const providerListParams = {
        admin_id: editedData?.appointment?.admin?.id
      };

      VisitBilling.providerServiceLocationList(providerListParams).then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        setServiceLocationList(data?.data);
        if (data?.data.length === 1 && props?.editedData?.action !== "edit") {
          setServiceLocationName(data?.data[0]?.service_location?.name);
          setFieldValue(
            "service_location_id",
            data?.data[0]?.service_location_id
          );

          setFieldTouched("service_location_id", true, true);
        }
      });
    }
  }, [props?.editedData, editedData]);

  useEffect(() => {
    if (startTime && endTime) {
      setServiceDuration(
        diffMinutes(
          moment(startTime).format("HH:mm"),
          moment(endTime).format("HH:mm")
        )
      );
    } else {
      setServiceDuration(0);
    }
  }, [startTime, endTime]);

  const calculateBillingUnit = (start_time = null, end_time = null) => {
    let billable_unit_text = billUnitArr[0].value;
    let billable_unit = 0;
    if (start_time && end_time) {
      //removed because of error instead used above serviceDuration State
      // let diff = moment(moment(end_time).format("YYYY-MM-DD HH:mm")).diff(
      //   moment(moment(start_time).format("YYYY-MM-DD HH:mm")),
      //   "minutes"
      // );

      let diff1 = moment(
        `${moment().format("YYYY-MM-DD")} ${moment(end_time).format("HH:mm")}`
      ).diff(
        `${moment().format("YYYY-MM-DD")} ${moment(start_time).format(
          "HH:mm"
        )}`,
        "minutes"
      );

      for (const unit of billUnitArr) {
        if (unit.start_time <= diff1 && unit.end_time >= diff1) {
          billable_unit = unit.key;
          billable_unit_text = unit.value;
          break;
        }
      }
    }

    setBillUnit(billable_unit_text);
    setFieldValue("billable_unit", billable_unit);
    setFieldTouched("billable_unit", true, true);
  };

  const handleNote = () => {
    // e.preventDefault()
    if (isValid && serviceDuration > 0) {
      if (isBill) {
        setLoading(true);
        let editNote = {
          insurace_id: userInsuranceId?.id,
          state_id: stateId,
          method_of_services: parentCategoryId["key"],
          payment_method: paymentId,
          cpt_id: CPTCode,
          billable_unit: values?.billable_unit,
          id: props?.editedData?.visit_bill?.id,
          diagnoses_code_arr: diagnoseId,
          is_prequalification: radioBtn,
          type_of_service: serviceId,
          service_start_time: moment.tz(startTime, "UTC").format("HH:mm:ss"),
          service_end_time: moment.tz(endTime, "UTC").format("HH:mm:ss"),
          client_pos: posId,
          notes_for_medical_biller: values?.notes_for_medical_biller,
          service_location_id: values?.service_location_id,
          primary_diagnosis_code_id: diagnosesCode
        };

        VisitBilling.editVisitBill(editNote)

          .then(result => {
            props.pageRefresh();
            props.toggle();
            setLoading(false);
          })
          .catch(error => {
            setLoading(false);
          });
      } else {
        setLoading(true);
        let editNote = {
          rd_id: editedData?.appointment?.admin?.id,
          calendly_appointment_id: props?.editedData?.id,
          service_date: props?.editedData?.appointment_date,
          insurace_id: insuranceId,
          state_id: stateId,
          method_of_services: parentCategoryId["key"],
          payment_method: paymentId,
          cpt_id: CPTCode,
          diagnoses_code_arr: diagnoseId,
          group_id:
            editedData &&
            `${editedData?.rdDetails?.find(detail => detail?.service_id === 1)
              ?.group?.id || ""}`,
          billable_unit: values?.billable_unit,
          user_id: hasParam,
          is_prequalification: radioBtn,
          type_of_service: serviceId,
          service_start_time: moment.tz(startTime, "UTC").format("HH:mm:ss"),
          service_end_time: moment.tz(endTime, "UTC").format("HH:mm:ss"),
          client_pos: posId,
          notes_for_medical_biller: values?.notes_for_medical_biller,
          service_location_id: values?.service_location_id,
          primary_diagnosis_code_id: diagnosesCode
        };

        VisitBilling.addVisitBill(editNote)
          .then(result => {
            props.pageRefresh();
            props.toggle();
            setLoading(false);
          })
          .catch(err => {
            if (err?.response?.status === 403) {
              if (toaster.current) {
                toaster.current.error("You cannot access this user info!");
                setTimeout(() => {
                  props.history.push("/client");
                }, 3000);
              }
            } else {
              if (toaster.current) toaster.current.error(err?.message);
            }
            setLoading(false);
            console.log("handleNote -> error", err);
          });
      }
    } else {
      setLoading(false);
    }

    handleSubmit();
  };

  const checkHandleChange = id => {
    setStateId(id);
  };
  const checkDiagnoseChange = event => {
    var isChecked = event.target.checked;
    const targtValue = parseInt(event.currentTarget.value);
    setDiagnoseId(prev =>
      prev.some(data => data === targtValue)
        ? prev.filter(data => data !== targtValue)
        : [...prev, targtValue]
    );

    if (isChecked) {
      checkedArr.push(targtValue);
      setFieldValue("diagnose", [...checkedArr]);
    } else {
      checkedArr.map((result, i) => {
        if (result === parseInt(event.currentTarget.value)) {
          checkedArr.splice(i, 1);
        }
      });
      setFieldValue("diagnose", [...checkedArr]);
    }
  };

  const manageOtherDiagnosesCode = id => {
    setDiagnoseId(prev => prev.filter(data => data !== id));

    checkedArr.map((result, i) => {
      if (result === parseInt(id)) {
        checkedArr.splice(i, 1);
      }
    });

    setFieldValue("diagnose", [...checkedArr]);
    setOtherDiagnoseList(diagnoseList?.diagnosisCodes?.filter(v => v.id != id));
    setSecondaryRecentCodes(
      diagnoseList?.recentlyUsedOtherDiagnosisCodes?.filter(v => v.id != id)
    );
    setSecondaryOtherCodes(
      diagnoseList?.otherDiagnosisCodes?.filter(v => v.id != id)
    );
  };

  const getProviderName = () => {
    let providerName = null;
    if (editedData?.appointment) {
      providerName =
        editedData?.appointment?.admin?.first_name +
        " " +
        editedData?.appointment?.admin?.last_name;
    }
    return providerName;
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const checkCurrAndApptDate = () => {
    if (props?.editedData?.appointment_type === "Manual") {
      return (
        moment()
          .utc()
          .local()
          .format("MM/DD/YYYY") !==
        moment
          .tz(
            moment(
              props?.editedData?.appointment_date +
                " " +
                props?.editedData?.appointment_time
            ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            moment.tz.guess()
          )
          .format("MM/DD/YYYY")
      );
    } else {
      return (
        moment()
          .utc()
          .local()
          .format("MM/DD/YYYY") !==
        moment
          .tz(props?.editedData?.event_detail.start_time, moment.tz.guess())
          .format("MM/DD/YYYY")
      );
    }
  };

  return (
    <VisitTracking {...props}>
      <form className="row col-12 pa-24">
        <div className="col-6">
          <div className="form-group d-flex">
            <label>Client Name:</label>
            <div className="roe-card-body ml-2">
              {editedData && `${editedData.first_name} ${editedData.last_name}`}
            </div>
          </div>
          <div className="form-group d-flex">
            <label>Client's Group:</label>
            <div className="roe-card-body ml-2">
              {editedData &&
                `${editedData?.rdDetails?.find(
                  detail => detail?.service_id === 1
                )?.group?.name || "-"}`}
            </div>
          </div>
          {props?.editedData?.is_billing_complete ? (
            <>
              <div className="form-group d-flex">
                <label>Insurance:</label>
                <div className="roe-card-body ml-2">
                  <Dropdown
                    isOpen={userInsuranceDropdownOpen}
                    toggle={toggleSeven}
                  >
                    <DropdownToggle caret size="md">
                      {userInsuranceId
                        ? userInsuranceId.insurance.name
                        : "Select Insurance"}
                    </DropdownToggle>
                    <DropdownMenu>
                      {userInsuranceList.map((result, i) => {
                        return (
                          <DropdownItem
                            key={i}
                            onClick={() => {
                              setUserInsuranceId(result);
                              setFieldValue("user_insurance_id", result);
                              setFieldTouched("user_insurance_id", true, true);
                            }}
                          >
                            {result.insurance.name}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
              <div className="form-group  d-flex">
                <label>Insurance Member ID:</label>
                <div className="roe-card-body ml-2">
                  {userInsuranceId && userInsuranceId.member_id}
                </div>
              </div>
              <div className="form-group d-flex">
                <label>Insurance Group ID:</label>
                <div className="roe-card-body ml-2">
                  {userInsuranceId && userInsuranceId.group_id}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="form-group  d-flex">
                <label>Insurance Carrier:</label>
                {/* <span style={{ textDecoration: "underline", color: "blue" }} href="#" id="TooltipExample">tooltip</span> */}

                <div className="roe-card-body ml-2">
                  {editedData &&
                    editedData.user_insurance &&
                    editedData.user_insurance.insurance &&
                    editedData.user_insurance.insurance.name}

                  <div className="form-group">
                    <input
                      type="radio"
                      name="valid"
                      onChange={() => {}}
                      defaultChecked={true}
                    />{" "}
                    Yes, this insurance is still valid. <br />
                    <RoyTooltip
                      id={`previous`}
                      title={
                        "Clicking no will redirect you to the Insurance tab."
                      }
                      placement="bottom"
                    >
                      <input
                        type="radio"
                        name="valid"
                        onChange={() => {
                          setTimeout(() => {
                            props.toggle();
                            props.toggleOne("5");
                          }, 1500);
                        }}
                        id="previous"
                      />{" "}
                      No, this insurance has changed.
                    </RoyTooltip>
                  </div>
                </div>
              </div>
              <div className="form-group  d-flex">
                <label>Insurance Member ID:</label>
                <div className="roe-card-body ml-2">
                  {editedData &&
                    editedData.user_insurance &&
                    editedData.user_insurance.member_id}
                </div>
              </div>
              <div className="form-group d-flex">
                <label>Insurance Group ID:</label>
                <div className="roe-card-body ml-2">
                  {editedData &&
                    editedData.user_insurance &&
                    editedData.user_insurance.group_id}
                </div>
              </div>
            </>
          )}

          <div className="form-group d-flex">
            <label>Service Date:</label>
            <div className="roe-card-body ml-2">
              {`${
                props?.editedData?.appointment_type === "Manual"
                  ? moment
                      .tz(
                        moment(
                          props?.editedData?.appointment_date +
                            " " +
                            props?.editedData?.appointment_time
                        ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                        moment.tz.guess()
                      )
                      .format("MM/DD/YYYY")
                  : moment
                      .tz(
                        props?.editedData?.event_detail.start_time,
                        moment.tz.guess()
                      )
                      .format("MM/DD/YYYY")
              } (${moment()
                .tz(moment.tz.guess())
                .zoneAbbr()})`}
            </div>
          </div>
          <div className="form-group">
            <label>Service Start Time:</label>
            <div className="roe-card-body">
              <DatePicker
                showTime={{ user12hours: true }}
                selected={startTime}
                onChange={date => {
                  setEndTime(null);
                  setFieldValue("service_end_time", null);
                  setStartTime(date);
                  setFieldValue("service_start_time", date);
                  setFieldTouched("service_start_time", true, true);
                  calculateBillingUnit(date, endTime);
                }}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={1}
                dateFormat="hh:mm a"
                timeFormat="hh:mm a"
                timeCaption="Time"
                className="custom-datepicker form-control react-form-input"
                calendarClassName="custom-calender-class"
                disabled={
                  props?.editedData?.visit_bill?.advancemd_step === 11 ||
                  (props?.auth?.admin_role_id === RD &&
                    props?.editedData?.visit_bill?.submitted_date &&
                    checkCurrAndApptDate())
                }
              />
              <Error field="service_start_time" />
            </div>
          </div>
          <div className="form-group">
            <label>Service End Time:</label>
            <div className="roe-card-body">
              <DatePicker
                minTime={
                  startTime
                    ? new Date().setHours(
                        startTime?.getHours(),
                        startTime?.getMinutes() + 1,
                        0
                      )
                    : new Date().setHours(
                        new Date()?.getHours(),
                        new Date()?.getMinutes() + 1,
                        0
                      )
                }
                maxTime={new Date().setHours(23, 59, 59)}
                showTime={{ user12hours: true }}
                selected={endTime}
                disabled={
                  (startTime &&
                    moment(startTime).format("HH:mm") === "23:59") ||
                  !startTime ||
                  props?.editedData?.visit_bill?.advancemd_step === 11 ||
                  (props?.auth?.admin_role_id === RD &&
                    props?.editedData?.visit_bill?.submitted_date &&
                    checkCurrAndApptDate())
                }
                onChange={date => {
                  setEndTime(date);
                  setFieldValue("service_end_time", date);
                  setFieldTouched("service_end_time", true, true);
                  calculateBillingUnit(startTime, date);
                }}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={1}
                dateFormat="hh:mm a"
                timeFormat="hh:mm a"
                timeCaption="Time"
                className="custom-datepicker form-control react-form-input"
                calendarClassName="custom-calender-class"
              />
              <Error field="service_end_time" />
              {serviceDuration <= 0 && endTime && (
                <div className="error-msg">
                  End time should not be less than start time
                </div>
              )}
            </div>
          </div>
          <div className="form-group">
            <label>Service Duration:</label>
            <div className="roe-card-body">
              <input
                type="text"
                className="form-control react-form-input wa-20"
                id="service_duration"
                value={serviceDuration > 0 ? `${serviceDuration} minutes` : ""}
                placeholder="Service Duration"
                readOnly={true}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="roe-card-body">
              <input
                type="checkbox"
                name={`is_billable`}
                onChange={e => {
                  if (e.target.checked) {
                    calculateBillingUnit();
                  } else {
                    calculateBillingUnit(startTime, endTime);
                  }
                }}
                value={values?.is_billable}
                checked={values?.billable_unit === 0 ? "checked" : false}
                className="checkbox"
              />
              {" This visit is not billable"}
            </div>
          </div>
          <div className="form-group ">
            <label>Method of Service: </label>
            <Dropdown isOpen={parentDropdownOpen} toggle={toggleOne}>
              <DropdownToggle caret size="md">
                {parentCategoryId ? parentCategoryId["value"] : "Select Method"}
              </DropdownToggle>
              <DropdownMenu>
                {monthArr.map((result, i) => {
                  return (
                    <DropdownItem
                      key={i}
                      onClick={() => {
                        setParentCategoryId(result);
                        setFieldValue("method_of_service", result["key"]);
                        setFieldTouched("method_of_service", true, true);
                        setPosId("");
                        setFieldValue("client_pos", "");
                      }}
                    >
                      {result["value"]}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
            <Error field="method_of_service" />
          </div>
          <div className="form-group ">
            <label>Payment Method:</label>
            <Dropdown isOpen={paymentDropdownOpen} toggle={toggleTwo}>
              <DropdownToggle caret size="md">
                {paymentId ? paymentId : "Select Payment"}
              </DropdownToggle>
              <DropdownMenu>
                {paymentArr.map((result, i) => {
                  return (
                    <DropdownItem
                      key={`payment_${i}`}
                      onClick={() => {
                        setPaymentId(result);
                        setFieldValue("payment_method", result);
                        setFieldTouched("payment_method", true, true);
                      }}
                    >
                      {result}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
            <Error field="payment_method" />
          </div>

          <div className="form-group">
            <label>Billable Units :</label>
            <input
              type="text"
              className="form-control react-form-input"
              id="billable_unit"
              onChange={handleChange}
              onBlur={handleBlur}
              value={billUnit}
              placeholder="Billable Unit"
              readOnly={true}
            />
            <Error field="billable_unit" />
          </div>
          {paymentId && paymentId === "Insurance Funded" && (
            <div className="form-group">
              <label>Requires Prequalification ? :</label> <br />
              <input
                type="radio"
                name="requires"
                value="true"
                onChange={() => setRadioBtn(true)}
                checked={radioBtn ? true : false}
              />{" "}
              Yes <br />
              <input
                type="radio"
                name="requires"
                value="false"
                onChange={() => setRadioBtn(false)}
                checked={!radioBtn ? true : false}
              />{" "}
              No
            </div>
          )}
        </div>
        <div className="col-6">
          <div className="form-group d-flex">
            <label>Provider:</label>
            <div className="roe-card-body ml-2">
              {editedData && getProviderName()}
            </div>
          </div>
          <div className="form-group">
            <label className="fs-16">Service Location:</label>
            <Dropdown
              isOpen={serviceLocationDropdownOpen}
              toggle={serviceLocationToggle}
            >
              <DropdownToggle caret size="md">
                {serviceLocationName
                  ? serviceLocationName
                  : "Select Service Location"}
              </DropdownToggle>
              <DropdownMenu>
                {serviceLocationList.map((result, i) => {
                  return (
                    <DropdownItem
                      key={`serviceLoction_${i}`}
                      onClick={() => {
                        setServiceLocationName(result?.service_location?.name);
                        setFieldValue(
                          "service_location_id",
                          result?.service_location_id
                        );

                        setFieldTouched("service_location_id", true, true);
                      }}
                    >
                      {result?.service_location?.name}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
            {/* <input
              type="text"
              className="form-control react-form-input"
              id="service_location_id"
              onChange={handleChange}
              onBlur={handleBlur}
              value={serviceLocationName}
              placeholder="Add Service Location"
              readOnly={true}
            /> */}
            <Error field="service_location_id" />
          </div>

          <div className="form-group">
            <label>Select Client State:</label>
            <div className="box-height  col-md-12">
              {stateList &&
                stateList.map((type, i) => {
                  return (
                    <div key={type.id} className="mt-2">
                      <table style={{ width: "100%" }}>
                        <tbody>
                          <tr>
                            <td style={{ width: "25%" }}>
                              <input
                                type="checkbox"
                                name={`state_id${type.id}`}
                                onChange={() => {
                                  checkHandleChange(type.id);
                                  setFieldValue("state", type.id);
                                  setFieldTouched("state", true, true);
                                }}
                                value={type?.id}
                                checked={
                                  stateId === type?.id ? "checked" : false
                                }
                                className="checkbox"
                              />

                              {" " + type?.name}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  );
                })}
            </div>
            <Error field="state" />
          </div>
          <div className="form-group">
            <label className="fs-16 medium-text">
              Notes for Medical Biller
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="notes_for_medical_biller"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values?.notes_for_medical_biller}
              placeholder="Add Notes for medical biller"
            />
            <Error field="notes_for_medical_biller" />
          </div>
          {paymentId &&
            (paymentId === "Insurance Funded" ||
              paymentId === "Client Funded" ||
              paymentId === "Consumer Model") && (
              <>
                <div className="form-group ">
                  <label>Type of Service: </label>
                  <Dropdown isOpen={serviceDropdownOpen} toggle={toggleFive}>
                    <DropdownToggle caret size="md">
                      {serviceId ? serviceId : "Select Type"}
                    </DropdownToggle>
                    <DropdownMenu>
                      {serviceType.map((result, i) => {
                        return (
                          <DropdownItem
                            key={i}
                            onClick={() => {
                              setserviceId(result);
                              setFieldValue("type_of_service", result);
                              setFieldTouched("type_of_service", true, true);
                            }}
                          >
                            {result}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </Dropdown>
                  <Error field="type_of_service" />
                </div>
                <div className="form-group">
                  <label>Select Diagnoses:</label>

                  <div>
                    <div className="form-group ">
                      <h6>Primary Diagnosis Code</h6>
                      <Dropdown
                        isOpen={diagnosesDropdownOpen}
                        toggle={toggleDiagnoses}
                      >
                        <DropdownToggle caret size="md">
                          {diagnosesCode
                            ? diagnosesCodeName
                            : "Select Diagnosis Code"}
                        </DropdownToggle>
                        <DropdownMenu
                          modifiers={{
                            setMaxHeight: {
                              enabled: true,
                              order: 890,
                              fn: data => {
                                return {
                                  ...data,
                                  styles: {
                                    ...data.styles,
                                    overflow: "auto",
                                    maxHeight: "340px",
                                    width: "565px"
                                  }
                                };
                              }
                            }
                          }}
                        >
                          <DropdownItem header className="diagnose-code-header">
                            Recently Used Diagnosis Codes
                          </DropdownItem>
                          {diagnoseList?.recentlyUsedDiagnosisCodes?.length ? (
                            diagnoseList?.recentlyUsedDiagnosisCodes?.map(
                              (result, i) => {
                                return (
                                  <DropdownItem
                                    key={i}
                                    onClick={() => {
                                      setDiagnosesCode(result.id);
                                      setDiagnosesCodeName(result.name);
                                      setFieldValue(
                                        "primary_diagnosis_code_id",
                                        result.name
                                      );
                                      setFieldTouched(
                                        "primary_diagnosis_code_id",
                                        true,
                                        true
                                      );
                                      manageOtherDiagnosesCode(result.id);
                                    }}
                                  >
                                    {result.name}
                                  </DropdownItem>
                                );
                              }
                            )
                          ) : (
                            <div className="flex justify-content-center">-</div>
                          )}
                          <DropdownItem header className="diagnose-code-header">
                            All Other Diagnosis Codes
                          </DropdownItem>
                          {diagnoseList?.diagnosisCodes?.length ? (
                            diagnoseList?.diagnosisCodes?.map((result, i) => {
                              return (
                                <DropdownItem
                                  key={i}
                                  onClick={() => {
                                    setDiagnosesCode(result.id);
                                    setDiagnosesCodeName(result.name);
                                    setFieldValue(
                                      "primary_diagnosis_code_id",
                                      result.name
                                    );
                                    setFieldTouched(
                                      "primary_diagnosis_code_id",
                                      true,
                                      true
                                    );
                                    manageOtherDiagnosesCode(result.id);
                                  }}
                                >
                                  {result.name}
                                </DropdownItem>
                              );
                            })
                          ) : (
                            <div className="flex justify-content-center">-</div>
                          )}
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                    <Error field="primary_diagnosis_code_id" />
                  </div>
                  <h6>Secondary Diagnosis Code</h6>
                  <div className="box-height  col-md-12">
                    <div className="diagnose-code-header mt-2">
                      Recently Used Diagnosis Codes
                    </div>
                    {secondaryRecentCodes?.length ? (
                      secondaryRecentCodes.map((type, i) => {
                        return (
                          <div key={type.id} className="mt-2">
                            <table style={{ width: "100%" }}>
                              <tbody>
                                <tr>
                                  <td style={{ width: "25%" }}>
                                    <input
                                      type="checkbox"
                                      name={`diagnose_${type.id}`}
                                      onChange={checkDiagnoseChange}
                                      value={type.id}
                                      checked={
                                        diagnoseId &&
                                        diagnoseId.includes(type.id)
                                          ? "checked"
                                          : false
                                      }
                                      className="checkbox"
                                    />

                                    {" " + type.name}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        );
                      })
                    ) : (
                      <div className="flex justify-content-center">-</div>
                    )}
                    <div className="diagnose-code-header mt-2">
                      All Other Diagnosis Codes
                    </div>
                    {secondaryOtherCodes.length ? (
                      secondaryOtherCodes.map((type, i) => {
                        return (
                          <div key={type.id} className="mt-2">
                            <table style={{ width: "100%" }}>
                              <tbody>
                                <tr>
                                  <td style={{ width: "25%" }}>
                                    <input
                                      type="checkbox"
                                      name={`diagnose_${type.id}`}
                                      onChange={checkDiagnoseChange}
                                      value={type.id}
                                      checked={
                                        diagnoseId &&
                                        diagnoseId.includes(type.id)
                                          ? "checked"
                                          : false
                                      }
                                      className="checkbox"
                                    />

                                    {" " + type.name}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        );
                      })
                    ) : (
                      <div className="flex justify-content-center">-</div>
                    )}
                  </div>
                  <Error field="diagnose" />
                </div>
                <div>
                  <div className="form-group ">
                    <label>CPT Code:</label>
                    <Dropdown isOpen={CPTDropdownOpen} toggle={toggleThree}>
                      <DropdownToggle caret size="md">
                        {CPTCode ? CPTCodename : "Select CPT code"}
                      </DropdownToggle>
                      <DropdownMenu>
                        {CPTList.map((result, i) => {
                          return (
                            <DropdownItem
                              key={i}
                              onClick={() => {
                                setCPTCode(result.id);
                                setCPTCodeName(result.name);
                                setFieldValue("cpt_code", result.name);
                                setFieldTouched("cpt_code", true, true);
                              }}
                            >
                              {result.name}
                            </DropdownItem>
                          );
                        })}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                  <Error field="cpt_code" />
                </div>
                <div className="form-group ">
                  <label>Where is client located? </label>
                  <Dropdown isOpen={posDropdownOpen} toggle={toggleSix}>
                    <DropdownToggle caret size="md">
                      {posId ? posId : "Select"}
                    </DropdownToggle>
                    <DropdownMenu>
                      {parentCategoryId &&
                        parentCategoryId["posType"].map((result, i) => {
                          return (
                            <DropdownItem
                              key={i}
                              onClick={() => {
                                setPosId(result);
                                setFieldValue("client_pos", result);
                                setFieldTouched("client_pos", true, true);
                              }}
                            >
                              {result}
                            </DropdownItem>
                          );
                        })}
                    </DropdownMenu>
                  </Dropdown>
                  <Error field="client_pos" />
                </div>
              </>
            )}
        </div>

        <div className="col-12 text-center">
          <Button
            type="button"
            loading={loading}
            className="c-btn c-info form-button fs-16 demi-bold-text mr-10"
            style={{ maxWidth: "200px" }}
            onClick={e => handleNote(e)}
            disabled={loading}
          >
            {loading
              ? "Loading..."
              : props?.editedData?.action === "edit"
              ? "Complete billing form"
              : "Save Billing"}
          </Button>
          <Button
            type="button"
            className="c-btn c-secondary form-button fs-16 demi-bold-text"
            style={{ maxWidth: "200px" }}
            onClick={props.toggle}
          >
            Close
          </Button>
        </div>
      </form>
      <Toaster ref={toaster} />
    </VisitTracking>
  );
};
export default compose(enhancer)(CompleteBillingForm);
