import React, { Fragment, useState, useEffect, useRef } from "react";
import { compose } from "redux";

import enhancer from "../viewclient/reassign-rd";
import FoodTracking from "../foodtracking/FoodTracking";
import GoalTracking from "../GoalTracking/GoalTracking";
import BioMetricsTracking from "../bioMetricsTracking/BioMetricTracking";
import ActivityMetricTracking from "../activityMetricsTracking/ActivityMetricTracking";

import PageWrapper from "./foodtracking.style";
import FeelingsTracking from "components/FeelingsTracking/FeelingsTracking";
import ThoughtsTracking from "components/ThoughtsTracking/ThoughtsTracking";
const trackArr = ["Goal", "Food", "Activity", "Biometric"];

const SERVICE_NAME = {
  1: "Nutrition",
  2: "Mental health"
};

const TRACKING = {
  "?meal": "Food",
  "?goal": "Goal",
  "?feelings": "Feelings",
  "?thoughts": "Thoughts"
};

const Tracking = props => {
  const [selectTracking, setSelectTracking] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [viewTracking, setViewTracking] = useState("ALL");
  const toggleMenu = () => setMenuOpen(!menuOpen);

  useEffect(() => {
    if (props.location.search) {
      setSelectTracking(TRACKING[props.location.search]);
      props.history.push(props.location.pathname);
    }
  });

  useEffect(() => {
    if (props?.auth?.admin_role_id >= 3 && props?.auth?.service_id) {
      setViewTracking(SERVICE_NAME[Number(props?.auth?.service_id)]);
    }
  }, [props?.auth]);

  return (
    <PageWrapper>
      {!selectTracking && (
        <div className="d-flex flex-wrap">
          {(viewTracking === "ALL" || viewTracking === SERVICE_NAME[1]) && (
            <>
              <div
                className="d-flex justify-content-center align-items-center track-3 p-3 text-center mr-4 mtb-2 flex-column"
                onClick={() => setSelectTracking("Goal")}
              >
                <i className="fa fa-trophy fa-2x" aria-hidden="true"></i>
                <div className="font-style-2">Goal</div>
              </div>

              <div
                className="d-flex justify-content-center align-items-center track-3 p-3 text-center  mr-4 mtb-2 flex-column"
                onClick={() => setSelectTracking("Food")}
              >
                <i className="fas fa-utensils fa-2x"></i>
                <div className="font-style-2">Food</div>
              </div>
              <div
                className="d-flex justify-content-center align-items-center track-3 p-3 text-center  mr-4 mtb-2 flex-column"
                onClick={() => setSelectTracking("Biometric")}
              >
                <i className="fas fa-map-marked-alt fa-2x"></i>

                <div className="font-style-2">Biometric</div>
              </div>
              <div
                className="d-flex justify-content-center align-items-center track-3 p-3 text-center  mr-4 mtb-2 flex-column"
                onClick={() => setSelectTracking("Activity")}
              >
                <i className="fas fa-running fa-2x"></i>
                <div className="font-style-2">Activity</div>
              </div>
            </>
          )}
          {(viewTracking === "ALL" || viewTracking === SERVICE_NAME[2]) && (
            <>
              <div
                className="d-flex justify-content-center align-items-center track-mh p-3 text-center  mr-4 mtb-2 flex-column"
                onClick={() => setSelectTracking("Feelings")}
              >
                <i className="fas fa-smile fa-2x"></i>
                <div className="font-style-2">Feelings</div>
              </div>
              <div
                className="d-flex justify-content-center align-items-center track-mh p-3 text-center  mr-4 mtb-2 flex-column"
                onClick={() => setSelectTracking("Thoughts")}
              >
                <i className="fas fa-lightbulb fa-2x"></i>
                <div className="font-style-2">Thoughts</div>
              </div>
            </>
          )}
        </div>
      )}
      {selectTracking && (
        <div
          className="font-style-2 m-3"
          style={{ width: "10%", cursor: "pointer" }}
          onClick={() => setSelectTracking(null)}
        >
          {" "}
          <i className="fas fa-arrow-left"></i> Back
        </div>
      )}
      {selectTracking === "Goal" ? (
        <GoalTracking {...props} />
      ) : selectTracking === "Food" ? (
        <FoodTracking {...props} />
      ) : selectTracking === "Activity" ? (
        <ActivityMetricTracking {...props} />
      ) : selectTracking === "Biometric" ? (
        <BioMetricsTracking {...props} />
      ) : selectTracking === "Feelings" ? (
        <FeelingsTracking {...props} />
      ) : selectTracking === "Thoughts" ? (
        <ThoughtsTracking {...props} />
      ) : (
        ""
      )}
    </PageWrapper>
  );
};
export default compose(enhancer)(Tracking);
