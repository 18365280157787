import Pagination from "components/common/Pagination";
import Toaster from "components/common/Toaster";
import React, { useEffect, useRef, useState } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import CommonModel from "../common/CommonModel";
import { withRouter } from "react-router";
import AddReferralModal from "./AddReferralModal";
import ViewNoteModal from "./ViewNoteModal";
import EncryptDecrypt from "api/EncryptDecrypt";
import { TABLE, PROVIDER } from "../../helper/constant";
import Loader from "components/common/Loader";
import moment from "moment";
import ReferralsServices from "api/ReferralsService";

const Referrals = props => {
  const [referralTblData, setReferralTblData] = useState([]);
  const [referralLoader, setReferralLoader] = useState(false);
  const [pages1, setPages1] = useState(0);
  const [activePage1, setActivePage1] = useState(0);
  const [referralModal, setReferralModal] = useState(false);
  const [viewNoteModal, setViewNoteModal] = useState(false);
  const [referralNote, setReferralNote] = useState(null);
  const toaster = useRef();

  const hasParam = props.match.params.id;

  function referralToggle() {
    setReferralModal(false);
  }

  function noteToggle() {
    setViewNoteModal(false);
  }

  useEffect(() => {
    if (!referralModal) {
      getReferralList(activePage1 + 1);
      setPages1(10);
    }
  }, [referralModal]);

  function getReferralList(page = "1") {
    setReferralLoader(true);
    const params = {
      page: page,
      user_id: hasParam
    };

    ReferralsServices.listReferral(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);

        if (data.status) {
          setReferralTblData(data.data.rows);
          setPages1(Math.ceil(data.data.count / TABLE.LIMIT));
          setReferralLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
          setReferralLoader(false);
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current) toaster.current.error(err?.message);
        }
        setReferralLoader(false);
      });
  }

  const columns = [
    {
      Header: "Created",
      accessor: "created_at",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",
      width: 200,
      Cell: props => moment(props.original.created_at).format("MM/DD/YYYY")
    },
    {
      Header: "Type",
      accessor: "type",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",
      width: 250,
      Cell: props => (
        <div>
          {props?.original?.type ? PROVIDER[props?.original?.type] : ""}
        </div>
      )
    },
    {
      Header: "Referred by",
      accessor: "admin",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",
      width: 250,
      Cell: props => (
        <div>
          {props?.original?.external_rd ? (
            <div>
              {props?.original?.external_rd?.first_name +
                " " +
                props?.original?.external_rd?.last_name}
            </div>
          ) : (
            <div>
              {props?.original?.admin?.first_name +
                " " +
                props?.original?.admin?.last_name}
            </div>
          )}
        </div>
      )
    },
    {
      Header: "Service",
      accessor: "service",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",
      width: 250,
      Cell: props => <div>{props?.original?.service.name}</div>
    },
    {
      Header: "Referred to",
      accessor: "referred_to",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",
      width: 250,
      Cell: props => (
        <div>
          {props?.original?.referred_to_provider &&
            props?.original?.referred_to_provider?.first_name +
              " " +
              props?.original?.referred_to_provider?.last_name}
        </div>
      )
    },

    {
      Header: "Action",
      accessor: "action",
      className: "",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 200,
      Cell: colProps => (
        <div className="react-action-class">
          <Button
            className="c-btn c-success mr-10"
            onClick={() => formAction1(colProps.original)}
          >
            <div className="fs-14 medium-text">View Notes</div>
          </Button>
        </div>
      )
    }
  ];

  const formAction1 = data => {
    if (props.auth.admin_role_id <= 3) {
      setViewNoteModal(true);
      setReferralNote(data.referral_note);
    } else {
      toaster.current.error(
        "You don’t have permission to view the referral notes for this patient. "
      );
    }
  };

  return (
    <div>
      <section>
        <div className="plr-15">
          <div className="roe-card-style mtb-15">
            <div className="roe-card-header module-header">
              <div className="flex-1 fs-16 demi-bold-text">
                <span className="hash"># </span> Referrals
              </div>
              <div>
                <Button
                  className="c-btn c-primary ma-5"
                  onClick={() => setReferralModal(true)}
                >
                  <i className="fas fa-plus mr-10" />
                  Add Referral
                </Button>
              </div>
            </div>
            <div className="roe-card-body">
              <ReactTable
                style={{
                  border: "none",
                  boxShadow: "none"
                }}
                noDataText="No referrals yet!"
                data={referralTblData}
                columns={columns}
                defaultPageSize={10}
                minRows={2}
                manual
                className="-striped -highlight custom-react-table-theme-class"
                pages={pages1}
                page={activePage1}
                PaginationComponent={Pagination}
                loading={referralLoader}
                LoadingComponent={Loader}
              />
            </div>
          </div>
        </div>
      </section>

      <CommonModel
        modal={referralModal}
        toggle={referralToggle}
        children={
          <AddReferralModal
            {...props}
            toggle={referralToggle}
            pageRefresh={() => {
              getReferralList(activePage1 + 1);
            }}
          />
        }
      />
      <CommonModel
        modal={viewNoteModal}
        toggle={noteToggle}
        children={
          <ViewNoteModal
            {...props}
            toggle={noteToggle}
            referralNote={referralNote}
          />
        }
        header={"Referral Notes:"}
      />
      <Toaster ref={toaster} />
    </div>
  );
};

export default withRouter(Referrals);
