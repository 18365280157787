import React from "react";
import PageWrapper from "./pendingbill.style";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";
import PendingBillingTable from "components/pendingBilling/PendingBillingTable";

const PendingBilling = props => {
  return (
    <PageWrapper {...props}>
      <PageTitle
        props={props}
        title="sidebar.pendingBill"
        className="plr-15"
        breadCrumb={[
          {
            name: "sidebar.dashboard",
            url: "/dashboard"
          },
          {
            name: "sidebar.pendingBill"
          }
        ]}
      />
      <div className="user-tbl">
        <PendingBillingTable {...props} />
      </div>
    </PageWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.auth,
    ...state.themeChanger,
    auth: state.auth
  };
};

export default connect(mapStateToProps, null)(PendingBilling);
