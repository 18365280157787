import styled from "styled-components";

const UserWrapper = styled.div`
  .Grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 0.5fr));
    grid-gap: 20px 20px;
    grid-auto-rows: 140px;
  }
  .Card {
    background-color: #eb3349; /* fallback for old browsers */
    height: 110px;
    cursor: pointer;
  }
  .Card:hover {
    opacity: 0.9;
  }
  .font-style {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: 100;
  }
  .font-style-2 {
    text-align: center !important;
    font-size: 30px;
    font-weight: 600;
    color: #fff;
  }
  .Card-2 {
    background-color: #00b4db; /* fallback for old browsers */

    height: 110px;
    cursor: pointer;
  }
  .Card-2:hover {
    opacity: 0.9;
  }
  .Card-3 {
    background: #f7971e; /* fallback for old browsers */
    height: 110px;
    cursor: pointer;
  }
  .Card-3:hover {
    opacity: 0.9;
  }
  .Card-4 {
    background: #1488cc; /* fallback for old browsers */
    height: 110px;
    cursor: pointer;
  }
  .Card-4:hover {
    opacity: 0.9;
  }
  .Card-5 {
    background: #cc2b5e; /* fallback for old browsers */
    height: 110px;
    cursor: pointer;
  }
  .Card-5:hover {
    opacity: 0.9;
  }
  .Card-6 {
    background: #02aab0; /* fallback for old browsers */
    height: 110px;
    cursor: pointer;
  }
  .Card-6:hover {
    opacity: 0.9;
  }

  .Card-7 {
    background: #606060ff; /* fallback for old browsers */
    height: 110px;
    cursor: pointer;
  }
  .Card-7:hover {
    opacity: 0.9;
  }
`;

export default UserWrapper;
