import React, { Fragment, useRef, useState, useEffect } from "react";
import { compose } from "redux";
import enhancer from "./validator";
import Button from "../button/Button";
import Toaster from "../common/Toaster";
import { Input } from "reactstrap";
import { WithContext as ReactTags } from "react-tag-input";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CONTENT_LIBRARY_TYPE, RD, SERVICE_TYPE } from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import CategoryServices from "../../api/CategoryServices";

const articleConfig = {
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "|",
      "bulletedList",
      "numberedList",
      "|",
      "insertTable",
      "|",
      // "imageUpload",
      "|",
      "undo",
      "redo"
    ]
  },
  image: {
    toolbar: ["imageStyle:full", "imageStyle:side", "|", "imageTextAlternative"]
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"]
  },
  language: "en"
};

const instructionConfig = {
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "|",
      "bulletedList",
      "numberedList",
      "|",
      "insertTable",
      "|",
      // 'imageUpload',
      "|",
      "undo",
      "redo"
    ]
  },
  image: {
    toolbar: ["imageStyle:full", "imageStyle:side", "|", "imageTextAlternative"]
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"]
  },
  language: "en"
};

const ingredientsConfig = {
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "|",
      "bulletedList",
      // 'numberedList',
      "|",
      // 'insertTable',
      "|",
      // 'imageUpload',
      "|",
      "undo",
      "redo"
    ]
  },
  // image: {
  //   toolbar: [
  //     'imageStyle:full',
  //     'imageStyle:side',
  //     '|',
  //     'imageTextAlternative'
  //   ]
  // },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"]
  },
  language: "en"
};

const acronymTags = ["dbt"];

const ContentLibraryForm = props => {
  const {
    handleChange,
    handleBlur,
    errors,
    setFieldTouched,
    setFieldValue,
    touched,
    submitCount,
    onSubmit,
    values,
    action,
    authorList,
    tagList
  } = props;

  const ROLE_SERVICE_ID = {
    4: 1,
    5: 2
  };

  const suggestions = tagList.map(tag => {
    return {
      id: tag.tag,
      text: tag.tag
    };
  });
  const toaster = useRef(null);
  const [tags, setTags] = useState([]);
  const [videoSrc, setVideoSrc] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [serviceList, setServiceList] = useState([]);
  const [typeList, setTypeList] = useState(
    Object.entries(CONTENT_LIBRARY_TYPE).map(value => {
      return {
        key: value[0],
        value: value[1]
      };
    })
  );
  const [filteredAuthors, setFilteredAuthors] = useState([...authorList]);

  useEffect(() => {
    (() => {
      let authors = [];
      if (
        props?.authData?.adminRoleId === 4 ||
        props?.authData?.adminRoleId === 5
      ) {
        authorList.forEach(ele => {
          if (
            ele?.rd_detail?.service_id ===
            ROLE_SERVICE_ID[props?.authData?.adminRoleId]
          ) {
            authors.push(ele);
          } else if (
            ele?.admin_role_id === 1 ||
            ele?.admin_role_id === 2 ||
            (ele?.admin_role_id === 6 &&
              ele?.practice_management?.service_id ===
                ROLE_SERVICE_ID[props?.authData?.adminRoleId])
          ) {
            authors.push(ele);
          } else if (ele?.admin_role_id === props?.authData?.adminRoleId) {
            authors.push(ele);
          }
        });
      } else if (props?.authData?.adminRoleId === 3) {
        authors = authorList.filter(el => el?.id === props?.authData?.adminId);
      } else if (
        props?.authData?.adminRoleId === 1 ||
        props?.authData?.adminRoleId === 2
      ) {
        authors = [...authorList];
      } else if (props?.authData?.adminRoleId === 6) {
        authors = authorList.filter(
          el =>
            el?.practice_id === props?.authData?.practice ||
            props?.authData?.adminRoleId === 1 ||
            props?.authData?.adminRoleId === 2 ||
            ROLE_SERVICE_ID[el?.admin_role_id] === props?.authData?.service_id
        );
      }

      setFilteredAuthors([...authors]);
    })();
  }, [authorList]);

  useEffect(() => {
    if (props?.authData?.adminRoleId === 3) {
      if (props?.authData?.service_id === SERVICE_TYPE.MENTAL_HEALTH) {
        setTypeList(typeList.slice(0, -1));
      }
    }
    if (props?.authData?.adminRoleId > 2) {
      setFieldValue("service", props?.authData?.service_id);
    }
  }, [props?.authData]);

  useEffect(() => {
    CategoryServices.serviceDropdown()
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setServiceList([...data.data]);
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      });
  }, []);

  useEffect(() => {
    setFieldValue("author_id", props.authData.adminId);
  }, [authorList]);

  useEffect(() => {
    const existing = values.content_library_tags.map(tag => {
      return {
        id: tag.content_tag.tag,
        text: tag.content_tag.tag
      };
    });

    setTags(existing);
    setFieldValue("tags", existing);
  }, [values.content_library_tags]);

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {field1 === "tags" &&
          Array.isArray(errors[field1]) &&
          errors[field1]?.length
            ? errors[field1]?.find(el => el?.id || el?.text)?.text
            : errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const handleSubmit = status => {
    let { values, isValid, handleSubmit, errors } = props;
    values.tags = tags;
    values.status = status;
    if (
      values.type === "video" &&
      values.video_url_status !== 2 &&
      values.status === "published"
    ) {
      if (toaster.current)
        toaster.current.error(
          "You cannot publish a video if the video upload failed."
        );
    } else {
      if (isValid || Object.keys(errors).length === 0) {
        setIsSubmit(true);
        onSubmit(values, action);
        setIsSubmit(false);
      }
      handleSubmit();
    }
  };

  const KeyCodes = {
    comma: 188,
    enter: 13
  };

  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  const handleDelete = i => {
    let data = tags.filter((tag, index) => index !== i);
    setFieldValue("tags", data);
    setFieldTouched("tags", true, true);
    setTags(data);
  };

  const handleAddition = tag => {
    let convertedTag = {
      id: tag?.id.charAt(0).toUpperCase() + tag?.id.slice(1),
      text: tag?.text.charAt(0).toUpperCase() + tag?.text.slice(1)
    };

    if (acronymTags.includes(convertedTag?.text?.toLowerCase())) {
      convertedTag = {
        id: convertedTag?.id?.toUpperCase(),
        text: convertedTag?.text.toUpperCase()
      };
    }

    let data = [...tags, convertedTag];
    setFieldValue("tags", data);
    setFieldTouched("tags", true, true);
    setTags(data);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setFieldValue("tags", newTags);
    setFieldTouched("tags", true, true);
    setTags(newTags);
  };

  const handleTagClick = index => {};

  const handleServiceChange = e => {
    setFieldValue("service", e.target.value);
    if (String(e.target.value) === "2") {
      setTypeList(typeList.slice(0, -1));
    } else {
      setTypeList(
        Object.entries(CONTENT_LIBRARY_TYPE).map(value => {
          return {
            key: value[0],
            value: value[1]
          };
        })
      );
    }
  };

  const handleTypeChanges = e => {
    setFieldValue("type", e.target.value);
    if (e.target.value === "recipe") {
      setFieldValue("service", 1);
    }
  };

  const handleVideo = (e, setValue) => {
    if (e.target.files[0]) {
      let fileObj = e.target.files[0];

      if (fileObj) {
        setFieldValue("video_url", fileObj);
        var src = URL.createObjectURL(fileObj);
        setVideoSrc(src);
      }
    }
  };

  return (
    <div>
      <form>
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <label className="fs-16 medium-text">
                Service<span className="asterisk">*</span>
              </label>
              <Input
                type="select"
                name="service"
                id="service"
                onChange={e => handleServiceChange(e)}
                value={values?.service}
                onBlur={handleBlur}
                disabled={props?.authData?.adminRoleId > 2}
              >
                <option value="">Select Service</option>
                {serviceList.map((service, i) => {
                  return (
                    <Fragment>
                      <option key={`option${i}`} value={service.id}>
                        {service.name}
                      </option>
                    </Fragment>
                  );
                })}
              </Input>
              <Error field="service" />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label className="fs-16 medium-text">
                Type<span className="asterisk">*</span>
              </label>
              <Input
                type="select"
                name="type"
                id="type"
                onChange={e => handleTypeChanges(e)}
                value={values.type}
                onBlur={handleBlur}
              >
                <option value="">Select Type</option>
                {typeList.map((item, i) => {
                  return (
                    <option key={`option${i}`} value={item.key}>
                      {item.value}
                    </option>
                  );
                })}
              </Input>
              <Error field="type" />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label className="fs-16 medium-text">
                Title<span className="asterisk">*</span>
              </label>
              <input
                type="hidden"
                className="form-control react-form-input"
                id="status"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.status}
                placeholder="Add Status"
              />
              <input
                type="text"
                className="form-control react-form-input"
                id="title"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.title}
                placeholder="Add Title"
              />
              <Error field="title" />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label className="fs-16 medium-text">
                Author<span className="asterisk">*</span>
              </label>
              <Input
                type="select"
                name="author_id"
                id="author_id"
                onChange={handleChange}
                value={values.author_id}
                onBlur={handleBlur}
              >
                <option value="">Select Author</option>
                {filteredAuthors.map((item, i) => {
                  return (
                    <option key={`option${i}`} value={item.id}>
                      {item.first_name} {item.last_name} ({item.admin_role.role}
                      )
                    </option>
                  );
                })}
              </Input>
              <Error field="author_id" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label className="fs-16 medium-text">Tag</label>
              <ReactTags
                tags={tags}
                suggestions={suggestions}
                delimiters={delimiters}
                handleDelete={handleDelete}
                handleAddition={handleAddition}
                handleDrag={handleDrag}
                handleTagClick={handleTagClick}
                inputFieldPosition="bottom"
                autocomplete={false}
                allowDeleteFromEmptyInput={false}
              />
              <Error field="tags" />
            </div>
          </div>
        </div>
        <div className="row pt-2">
          <div className="col-md-12">
            <div className="form-group">
              <label className="fs-16 medium-text">
                Description<span className="asterisk">*</span>
              </label>
              <textarea
                className="form-control react-form-input"
                rows="3"
                placeholder="Add Description"
                id="description"
                name="description"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
                maxLength={500}
              ></textarea>
              <Error field="description" />
            </div>
          </div>
        </div>

        {values.type === "article" && (
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label className="fs-16 medium-text">Body</label>
                <CKEditor
                  editor={ClassicEditor}
                  id="article_body"
                  onBeforeLoad={CKEDITOR => (CKEDITOR.disableAutoInline = true)}
                  config={articleConfig}
                  data={values.article_body}
                  onChange={(event, editor) => {
                    let data = editor.getData();
                    setFieldValue("article_body", data);
                  }}
                  onBlur={event => {
                    setFieldTouched("article_body", true, true);
                  }}
                />
                <Error field="article_body" />
              </div>
            </div>
          </div>
        )}

        {values.type === "recipe" && (
          <>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label className="fs-16 medium-text">Servings</label>
                  <input
                    type="number"
                    min={1}
                    className="form-control react-form-input"
                    id="recipe_serving"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.recipe_serving}
                    placeholder="Add Recipe Servings"
                  />
                  <Error field="recipe_serving" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label className="fs-16 medium-text">
                    Ingredients (include measurements)
                  </label>
                  <CKEditor
                    editor={ClassicEditor}
                    id="recipe_ingredient"
                    onBeforeLoad={CKEDITOR =>
                      (CKEDITOR.disableAutoInline = true)
                    }
                    config={ingredientsConfig}
                    data={values.recipe_ingredient}
                    onChange={(event, editor) => {
                      let data = editor.getData();
                      setFieldValue("recipe_ingredient", data);
                    }}
                    onBlur={event => {
                      setFieldTouched("recipe_ingredient", true, true);
                    }}
                  />
                  <Error field="recipe_ingredient" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label className="fs-16 medium-text">Instructions</label>
                  <CKEditor
                    editor={ClassicEditor}
                    id="recipe_instruction"
                    onBeforeLoad={CKEDITOR =>
                      (CKEDITOR.disableAutoInline = true)
                    }
                    config={instructionConfig}
                    data={values.recipe_instruction}
                    onChange={(event, editor) => {
                      let data = editor.getData();
                      setFieldValue("recipe_instruction", data);
                    }}
                    onBlur={event => {
                      setFieldTouched("recipe_instruction", true, true);
                    }}
                  />
                  <Error field="recipe_instruction" />
                </div>
              </div>
            </div>
          </>
        )}

        {values.type === "video" && (
          <>
            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label className="fs-16 medium-text">Video</label>

                  <br />
                  <div className="file-upload">
                    <label className="c-btn c-primary form-button fs-16 demi-bold-text mt-15">
                      Upload Video
                      <input
                        id="video_url"
                        className="file-upload__input"
                        name="file-upload"
                        type="file"
                        accept="video/*"
                        onChange={e => handleVideo(e)}
                        onBlur={handleBlur}
                      />
                    </label>
                  </div>
                  <Error field="video_url" />
                  <div>
                    {values.video_url && (
                      <video
                        src={videoSrc ? videoSrc : values.video_url}
                        controls="controls"
                        style={{
                          width: "258px",
                          borderRadius: "4%",
                          height: "200px",
                          background: "#404040"
                        }}
                        // onLoadedMetadata={handleLoadedMetadata}
                        // onError={() => setVideoError("You need to upload 960*360 resolution video only.")}
                      ></video>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div>
          <Button
            type="button"
            className="c-btn save-draft-color form-button fs-16 demi-bold-text"
            style={{ maxWidth: "125px" }}
            onClick={() => handleSubmit("save_draft")}
            disabled={
              ["pending_review", "published"].includes(values.status) ||
              isSubmit
                ? true
                : false
            }
          >
            Save Draft
          </Button>
          <Button
            type="button"
            className="c-btn submit-approval-color form-button fs-16 demi-bold-text"
            style={{ maxWidth: "150px", marginLeft: "10px" }}
            onClick={() => handleSubmit("pending_review")}
            disabled={
              ["pending_review", "published"].includes(values.status)
                ? true
                : false
            }
          >
            Submit for Approval
          </Button>
          {props.authData.adminRoleId !== RD && (
            <>
              <Button
                type="button"
                className="c-btn publish-color form-button fs-16 demi-bold-text"
                style={{ maxWidth: "125px", marginLeft: "10px" }}
                onClick={() => handleSubmit("published")}
                disabled={
                  ["published", "unpublished", "save_draft"].includes(
                    values.status
                  )
                    ? true
                    : false
                }
              >
                Publish
              </Button>
              <Button
                type="button"
                className="c-btn unpublish-color form-button fs-16 demi-bold-text"
                style={{ maxWidth: "125px", marginLeft: "10px" }}
                onClick={() => handleSubmit("unpublished")}
                disabled={
                  ["unpublished", "save_draft"].includes(values.status)
                    ? true
                    : false
                }
              >
                {values.status === "pending_review" ? "Decline" : "Unpublished"}
              </Button>
            </>
          )}
        </div>
      </form>
      <Toaster ref={toaster} />
    </div>
  );
};

export default compose(enhancer)(ContentLibraryForm);
