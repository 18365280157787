import { ANS_KEYS, FORMS_KEYS } from "helper/constant";
import React from "react";

const FormElement = ({ question, answer }) => {
  return (
    <div className="p-2 ">
      <div className="fs-16 demi-bold-text">{question}</div>
      <div>
        <i className="fas fa-arrow-right pr-1"></i>
        {answer ? answer : "N/A"}
      </div>
    </div>
  );
};

const PresentingForm = props => {
  return (
    <div>
      <div className="fs-18 bold-text pb-2 ">Presenting Problems</div>

      <FormElement
        question={FORMS_KEYS[1]["problem_you_are_seeking_help"]}
        answer={props?.data?.problem_you_are_seeking_help}
      />
      <FormElement
        question={FORMS_KEYS[1]["anxiety_attacks"]}
        answer={ANS_KEYS[props?.data?.anxiety_attacks]}
      />
      <FormElement
        question={FORMS_KEYS[1]["appetite_increased"]}
        answer={ANS_KEYS[props?.data?.appetite_increased]}
      />
      <FormElement
        question={FORMS_KEYS[1]["appetite_decreased"]}
        answer={ANS_KEYS[props?.data?.appetite_decreased]}
      />
      <FormElement
        question={FORMS_KEYS[1]["depressed_mood"]}
        answer={ANS_KEYS[props?.data?.depressed_mood]}
      />
      <FormElement
        question={FORMS_KEYS[1]["easily_distracted"]}
        answer={ANS_KEYS[props?.data?.easily_distracted]}
      />
      <FormElement
        question={FORMS_KEYS[1]["engage_in_risky_behaviors"]}
        answer={ANS_KEYS[props?.data?.engage_in_risky_behaviors]}
      />
      <FormElement
        question={FORMS_KEYS[1]["excessive_energy"]}
        answer={ANS_KEYS[props?.data?.excessive_energy]}
      />
      <FormElement
        question={FORMS_KEYS[1]["excessive_worry"]}
        answer={ANS_KEYS[props?.data?.excessive_worry]}
      />
      <FormElement
        question={FORMS_KEYS[1]["fatigue"]}
        answer={ANS_KEYS[props?.data?.fatigue]}
      />
      <FormElement
        question={FORMS_KEYS[1]["feeling_hopeless"]}
        answer={ANS_KEYS[props?.data?.feeling_hopeless]}
      />
      <FormElement
        question={FORMS_KEYS[1]["feelings_of_abandonment"]}
        answer={ANS_KEYS[props?.data?.feelings_of_abandonment]}
      />

      <FormElement
        question={FORMS_KEYS[1]["feelings_of_emptiness"]}
        answer={ANS_KEYS[props?.data?.feelings_of_emptiness]}
      />
      <FormElement
        question={FORMS_KEYS[1]["guilt"]}
        answer={ANS_KEYS[props?.data?.guilt]}
      />
      <FormElement
        question={FORMS_KEYS[1]["impulsive"]}
        answer={ANS_KEYS[props?.data?.impulsive]}
      />
      <FormElement
        question={FORMS_KEYS[1]["unable_to_feel_pleasure"]}
        answer={ANS_KEYS[props?.data?.unable_to_feel_pleasure]}
      />
      <FormElement
        question={FORMS_KEYS[1]["increased_activity"]}
        answer={ANS_KEYS[props?.data?.increased_activity]}
      />

      <FormElement
        question={FORMS_KEYS[1]["increase_need_for_sleep"]}
        answer={ANS_KEYS[props?.data?.increase_need_for_sleep]}
      />
      <FormElement
        question={FORMS_KEYS[1]["unable_to_fall_sleep"]}
        answer={ANS_KEYS[props?.data?.unable_to_fall_sleep]}
      />
      <FormElement
        question={FORMS_KEYS[1]["paranoia"]}
        answer={ANS_KEYS[props?.data?.paranoia]}
      />
      <FormElement
        question={FORMS_KEYS[1]["preoccupied_for_sex"]}
        answer={ANS_KEYS[props?.data?.preoccupied_for_sex]}
      />
      <FormElement
        question={FORMS_KEYS[1]["racing_thoughts"]}
        answer={ANS_KEYS[props?.data?.racing_thoughts]}
      />
      <FormElement
        question={FORMS_KEYS[1]["talkativeness"]}
        answer={ANS_KEYS[props?.data?.talkativeness]}
      />
      <FormElement
        question={FORMS_KEYS[1]["worthlessness"]}
        answer={ANS_KEYS[props?.data?.worthlessness]}
      />
      <FormElement
        question={
          props?.data?.other_symptoms
            ? FORMS_KEYS[1]["other_symptoms"] +
              " (" +
              props?.data?.other_symptoms +
              ")"
            : ""
        }
        answer={ANS_KEYS[props?.data?.other_symptoms_value]}
      />
      <FormElement
        question={FORMS_KEYS[1]["symptoms_start_from"]}
        answer={props?.data?.symptoms_start_from}
      />
      <FormElement
        question={FORMS_KEYS[1]["symptoms_start_type"]}
        answer={props?.data?.symptoms_start_type}
      />
      <FormElement
        question={FORMS_KEYS[1]["how_severe_symptoms"]}
        answer={ANS_KEYS[props?.data?.how_severe_symptoms]}
      />
      <FormElement
        question={FORMS_KEYS[1]["affect_activity_of_daily_living"]}
        answer={ANS_KEYS[props?.data?.affect_activity_of_daily_living]}
      />
      <FormElement
        question={FORMS_KEYS[1]["affect_finances"]}
        answer={ANS_KEYS[props?.data?.affect_finances]}
      />
      <FormElement
        question={FORMS_KEYS[1]["affect_housing"]}
        answer={ANS_KEYS[props?.data?.affect_housing]}
      />
      <FormElement
        question={FORMS_KEYS[1]["affect_legal_matters"]}
        answer={ANS_KEYS[props?.data?.affect_legal_matters]}
      />
      <FormElement
        question={FORMS_KEYS[1]["affect_recreational_activities"]}
        answer={ANS_KEYS[props?.data?.affect_recreational_activities]}
      />
      <FormElement
        question={FORMS_KEYS[1]["affect_relationships"]}
        answer={ANS_KEYS[props?.data?.affect_relationships]}
      />
      <FormElement
        question={FORMS_KEYS[1]["affect_school"]}
        answer={ANS_KEYS[props?.data?.affect_school]}
      />
      <FormElement
        question={FORMS_KEYS[1]["affect_self_esteem"]}
        answer={ANS_KEYS[props?.data?.affect_self_esteem]}
      />
      <FormElement
        question={FORMS_KEYS[1]["affect_sexual_activity"]}
        answer={ANS_KEYS[props?.data?.affect_sexual_activity]}
      />
      <FormElement
        question={FORMS_KEYS[1]["affect_work"]}
        answer={ANS_KEYS[props?.data?.affect_work]}
      />
      <FormElement
        question={FORMS_KEYS[1]["impact_symptoms_in_life"]}
        answer={ANS_KEYS[props?.data?.impact_symptoms_in_life]}
      />
    </div>
  );
};

export default PresentingForm;
