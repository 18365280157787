import React, { useState } from "react";
import { compose } from "redux";
import enhancer from "./validator";
import "./VisitNotesTemplateForm.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { config } from "../../util/editorconfig";
import Button from "../button/Button";

const VisitNoteTemplateForm = props => {
  const {
    handleChange,
    handleBlur,
    errors,
    setFieldTouched,
    setFieldValue,
    touched,
    submitCount,
    onSubmit,
    values,
    action,
    isLoading
  } = props;

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;

    if (isValid) {
      onSubmit(values, action);
    }
    handleSubmit();
  };

  return (
    <div>
      <form>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Title <span className="asterisk">*</span>
          </label>
          <input
            type="text"
            className="form-control react-form-input"
            id="title"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.title}
            placeholder="Add Content"
          />
          <Error field="title" />
        </div>

        <div className="form-group">
          <label className="fs-16 medium-text">
            Template
            <span className="asterisk">*</span>
          </label>
          <CKEditor
            editor={ClassicEditor}
            id="content"
            onBeforeLoad={CKEDITOR => (CKEDITOR.disableAutoInline = true)}
            config={config}
            data={values?.content}
            onChange={(event, editor) => {
              const data = editor.getData();
              setFieldValue("content", data);
            }}
            onBlur={event => {
              setFieldTouched("content", true, true);
            }}
          />

          <Error field="content" />
        </div>

        <div>
          <Button
            type="button"
            className="c-btn c-info form-button fs-16 demi-bold-text"
            style={{ maxWidth: "125px" }}
            onClick={() => handleSubmit()}
            disabled={isLoading}
            loading={isLoading}
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

export default compose(enhancer)(VisitNoteTemplateForm);
