import permissionsActions from "./actions";

const initState = { permissions: [] };

export default function permissionReducer(state = initState, action) {
  switch (action.type) {
    case permissionsActions.SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.permissions
      };
    default:
      return state;
  }
}
