const clientFilterActions = {
  SET_FILTERS: "SET_FILTERS",
  RESET_FILTERS: "RESET_FILTERS",

  setClientFilters: data => {
    return {
      type: clientFilterActions.SET_FILTERS,
      clientFilters: data
    };
  },
  resetClientFilters: data => {
    return {
      type: clientFilterActions.RESET_FILTERS
    };
  }
};

export default clientFilterActions;
