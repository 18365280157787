import axios from "axios";
import { baseUrl } from "../helper/constant";
import Header from "./Header";
import EncryptDecrypt from "./EncryptDecrypt";

export default class TierManagementServices {
  static listTier(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/tier/tier-list`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }
  static listTierModule(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/tier/list-tier-module`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static update(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/tier/update-tier-module`,
      EncryptDecrypt.encrypt(params)
    );
  }
}
