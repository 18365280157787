import React, { useRef, useEffect, useState } from "react";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import RdServices from "../../api/RdServices";
import Button from "../button/Button";
import Toaster from "../common/Toaster";
import RDWrapper from "./rd.style";

const InsuranceForm = props => {
  // const hasParam = props.match.params.id;

  const { onSubmit, action, insuranceData, insuranceList } = props;

  const toaster = useRef();

  const [selectedInput, setSelectedInput] = React.useState([]);

  useEffect(() => {
    setSelectedInput(insuranceData);
  }, [insuranceData]);

  const checkHandleChange = e => {
    const targtValue = parseInt(e.currentTarget.value);
    let newArray = [...selectedInput];
    const index = newArray.findIndex(el => el.insurance_id === targtValue);

    if (index !== -1) {
      newArray.splice(index, 1);
    } else {
      const insuranceDetail = insuranceList?.find(el => el.id === targtValue);
      newArray.push({
        insurance_id: insuranceDetail.id
        // effective_date: new Date(),
      });
    }

    setSelectedInput([...newArray]);
  };

  const handleSubmit = () => {
    let { values } = props;

    // onSubmit(values, action);
    const params = {
      admin_id: props.adminId,
      insurance: selectedInput
    };

    RdServices.editInsurance(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current) toaster.current.success(data.message);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });

    //handleSubmit();
  };

  return (
    <RDWrapper {...props}>
      <div className="row">
        <form>
          <div className="form-group col-md-12">
            {insuranceList &&
              insuranceList.map((type, i) => {
                return (
                  <div key={i} className="mt-2">
                    <input
                      type="checkbox"
                      name={`other_insurance_id${type.id}`}
                      onChange={checkHandleChange}
                      value={type.id}
                      checked={
                        selectedInput?.length &&
                        selectedInput.find(el => el.insurance_id === type.id)
                      }
                      className="checkbox"
                    />{" "}
                    {type?.name}({type?.is_active ? "Active" : "Inactive"}
                    )
                    <br />
                  </div>
                );
              })}
          </div>
          <div>
            <Button
              type="button"
              className="c-btn c-info form-button fs-16 demi-bold-text"
              style={{ maxWidth: "125px" }}
              onClick={() => handleSubmit()}
            >
              Submit
            </Button>
          </div>
        </form>
        <Toaster ref={toaster} />
      </div>
    </RDWrapper>
  );
};

export default InsuranceForm;
