import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    other_fax_number: Yup.string()
      .min(14, "Must be exactly 10 digits")
      .max(14, "Must be exactly 10 digits")
      .nullable(),
    other_phone_number: Yup.string()
      .min(14, "Must be exactly 10 digits")
      .max(14, "Must be exactly 10 digits")
      .nullable()
  }),
  mapPropsToValues: props => ({
    id: props.editedData ? props.editedData.id : "",
    first_name: props.editedData ? props.editedData.first_name : "",
    last_name: props.editedData ? props.editedData.last_name : "",
    provider_npi: props.editedData ? props.editedData.provider_npi : "",
    credential: props.editedData ? props.editedData.credential : "",
    taxonomy: props.editedData ? props.editedData.taxonomy : "",
    address: props.editedData ? props.editedData.address : "",
    city: props.editedData ? props.editedData.city : "",
    state: props.editedData ? props.editedData.state : "",
    zip: props.editedData ? props.editedData.zip : "",
    status: props.editedData ? props.editedData.status : "",
    phone_number: props.editedData ? props.editedData.phone_number : "",
    fax_number: props.editedData ? props.editedData.fax_number : "",
    other_phone_number: props.editedData
      ? props.editedData.other_phone_number
      : "",
    other_fax_number: props.editedData ? props.editedData.other_fax_number : ""
  }),

  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
