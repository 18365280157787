import axios from "axios";
import { baseUrl } from "../helper/constant";
import Header from "./Header";
import EncryptDecrypt from "./EncryptDecrypt";

export default class FoodTracking {
  static list(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/food-tracking/list`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static commentList(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/food-tracking/comment/list`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static addComment(params) {
    Header.setHeaders();

    return axios.post(
      `${baseUrl}/food-tracking/comment/add`,
      EncryptDecrypt.encrypt(params)
    );
  }
}
