import clientFilterActions from "./actions";

const initState = { clientFilters: null };

export default function permissionReducer(state = initState, action) {
  switch (action.type) {
    case clientFilterActions.SET_FILTERS:
      return {
        ...state,
        clientFilters: action.clientFilters
      };
    case clientFilterActions.RESET_FILTERS:
      return {
        ...state,
        clientFilters: null
      };
    default:
      return state;
  }
}
