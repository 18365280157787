const authActions = {
  PROGRESSAMD: "PROGRESSAMD",
  COMPLETEAMD: "COMPLETEAMD",
  FAILEDAMD: "FAILEDAMD",
  CLEARAMD: "CLEARAMD",

  progress: data => {
    return {
      type: authActions.PROGRESSAMD
    };
  },

  complete: data => {
    return {
      type: authActions.COMPLETEAMD
    };
  },

  failed: () => {
    return {
      type: authActions.FAILEDAMD
    };
  },

  clear: () => {
    return {
      type: authActions.CLEARAMD
    };
  }
};

export default authActions;
