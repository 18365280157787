import axios from "axios";
import { baseUrl } from "../helper/constant";
import Header from "./Header";
import EncryptDecrypt from "./EncryptDecrypt";
import FileDownload from "js-file-download";

export default class DiagnosesCodeServices {
  static list(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/diagnoses-code/list`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static add(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/diagnoses-code/add`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static edit(params) {
    Header.setHeaders();
    return axios.put(
      `${baseUrl}/diagnoses-code/edit`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static view(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/diagnoses-code/view`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static delete(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/diagnoses-code/delete`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static status(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/diagnoses-code/status`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static lookup(params) {
    Header.setHeaders();
    return axios
      .get(
        `${baseUrl}/advancemd/diagnoses-lookup`,
        EncryptDecrypt.encrypt(params)
      )
      .then(res => {
        FileDownload(res.data, "DiagnosesLookup.csv");
      });
  }
}
