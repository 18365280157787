import amdAction from "./actions";

const initState = {
  statusVisitAMD: null
};

export default function rootReducer(state = initState, action) {
  switch (action.type) {
    case amdAction.PROGRESSAMD:
      state.statusVisitAMD = "progress";
      return { ...state };

    case amdAction.COMPLETEAMD:
      state.statusVisitAMD = "success";
      return { ...state };

    case amdAction.FAILEDAMD:
      state.statusVisitAMD = "failed";
      return { ...state };

    case amdAction.CLEARAMD:
      state.statusVisitAMD = null;
      return { ...state };

    default:
      return state;
  }
}
