import styled from "styled-components";

const StateLicenceWrapper = styled.div`
  .h-500 {
    height: 500px;
  }
  h3 {
    color: #aece38;
    font-weight: bold;
    font-size: 2.2rem;
  }
  .profile-head {
    padding: 10px;
  }
  .checkbox {
    padding-left: 20px;
    height: 16px;
    width: 16px;
  }
  .box-height {
    height: 500px;
    overflow: scroll;
  }
`;
export default StateLicenceWrapper;
