import React, { Fragment } from "react";
import ProfileWrapper from "./profile.style";
import StepOneProfileForm from "components/rdprofile/StepOneProfileForm";
import CommonModel from "../../components/common/CommonModel";
import { connect } from "react-redux";
import StepTwoStateLicence from "components/rdprofile/StepTwoStateLicence";
import StepTheeInsurance from "components/rdprofile/StepTheeInsurance";
import StepFourCalendly from "components/rdprofile/StepFourCalendly";

const CompleteProfile = props => {
  return (
    <Fragment>
      <ProfileWrapper {...props}>
        {/* {props.auth.step == 2 && (
          <CommonModel
            modal={true}
            toggle={true}
            children={<StepOneProfileForm editedData={props.auth} />}
            size="xl"
          />
        )}
        {props.auth.step == 3 && (
          <CommonModel
            modal={true}
            toggle={true}
            children={<StepTwoStateLicence />}
            size="xl"
          />
        )}
        {props.auth.step == 4 && (
          <CommonModel
            modal={true}
            toggle={true}
            children={<StepTheeInsurance />}
            size="xl"
          />
        )} */}
        {/* {props.auth.step == 5 && ( */}
        <CommonModel
          modal={true}
          toggle={true}
          children={<StepFourCalendly />}
          size="lg"
          // style={{ height: "600px" }}
        />
        {/* )} */}
      </ProfileWrapper>
    </Fragment>
  );
};
const mapStateToProps = state => {
  return {
    ...state.permissionReducer,
    auth: state.auth
  };
};
export default connect(mapStateToProps, null)(CompleteProfile);
