import uploadAction from "./actions";

const initState = {
  videoStatus: []
};

export default function rootReducer(state = initState, action) {
  switch (action.type) {
    case uploadAction.PROGRESS:
      state.videoStatus.push({
        fileName: action.fileName,
        status: action.status
      });
      return { ...state };

    case uploadAction.COMPLETE:
      const i = state.videoStatus.findIndex(
        video => video.fileName === action.fileName
      );
      if (i !== -1) {
        state.videoStatus[i].status = 2;
      }
      return {
        ...state
      };

    case uploadAction.FAILED:
      state.videoStatus.forEach(element => {
        if (element.status === 1) {
          element.status = 3;
        }
      });

      return {
        ...state
      };

    case uploadAction.CLEAR:
      state.videoStatus = [];

      return {
        ...state
      };

    default:
      return state;
  }
}
