import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    primary_insurance_id: Yup.string().required("This field is required")
  }),
  mapPropsToValues: props => ({
    primary_insurance_id:
      props && props.editedData && props.editedData.primary_insurance_id
        ? props.editedData.primary_insurance_id
        : ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
