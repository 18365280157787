import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import ReportServices from "../../api/ReportServices";
import Loader from "../common/Loader";
import Toaster from "../common/Toaster";
import {
  PROVIDER_TYPES,
  RD_STATUS,
  SERVICE_NAME,
  TABLE
} from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import moment from "moment-timezone";
import Button from "components/button/Button";
import CommonModel from "../common/CommonModel";
import { DateRangePicker } from "materialui-daterange-picker";
import CategoryServices from "api/CategoryServices";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "reactstrap";
import CommonServices from "api/CommonServices";

const RdRetentionTable = props => {
  const [tblData, settblData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [field, setField] = useState("id");
  const [sortBy, setSortBy] = useState("DESC");
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null
  });
  const [modal, setModal] = useState(false);
  const [open, setOpen] = useState(false);

  const [serviceId, setServiceId] = useState(null);
  const [serviceName, setServiceName] = useState(null);
  const [serviceList, setServiceList] = useState([]);
  const [serviceDropdownOpen, setServiceDropdownOpen] = useState(false);

  const [practiceList, setPracticeList] = useState([]);
  const [practiceId, setPracticeId] = useState(null);
  const [practiceName, setPracticeName] = useState(null);
  const [practiceDropdownOpen, setPracticeDropdownOpen] = useState(false);

  const [typeName, setTypeName] = useState(null);
  const [typeValue, setTypeValue] = useState(null);
  const [typeDropdownOpen, setTypeDropdownOpen] = useState(false);
  const toaster = useRef();

  useEffect(() => {
    getList(field, sortBy, activePage + 1);
    setPages(10);
  }, [
    dateRange.startDate,
    dateRange.endDate,
    typeValue,
    serviceId,
    practiceId
  ]);

  useEffect(() => {
    CategoryServices.serviceDropdown()
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setServiceList([...data.data]);
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      });
  }, []);

  useEffect(() => {
    const params = {
      get_all: true,
      service_id:
        props?.admin_role_id > 3
          ? props?.service_id
          : serviceId
          ? serviceId
          : undefined
    };
    CommonServices.iboPracticeDropdownList(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setPracticeList([...data.data]);
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch Practice!");
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch Practice!");
        }
      });
  }, [props?.admin_role_id, serviceId]);

  const toggle = () => {
    setOpen(!open);
    setModal(!open);
  };
  const toggleType = () => setTypeDropdownOpen(prevState => !prevState);
  const toggleService = () => setServiceDropdownOpen(prevState => !prevState);
  const togglePractice = () => setPracticeDropdownOpen(prevState => !prevState);

  const columns = [
    {
      Header: "Provider ID",
      accessor: "id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 150
    },
    {
      Header: "Provider Name",
      accessor: "first_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      width: 200,
      Cell: props => (
        <div className="react-action-class">
          {props.original.first_name + " " + props.original.last_name}
        </div>
      )
    },
    {
      Header: "Provider Status",
      accessor: "status",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      width: 250,
      Cell: props => (
        <div className="react-action-class">
          {RD_STATUS[props.original.status]}
        </div>
      )
    },
    {
      Header: "Total Visits",
      accessor: "total_visit",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      width: 200
    },
    {
      Header: "Completed",
      accessor: "completed",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      width: 200
    },
    {
      Header: "Canceled",
      accessor: "canceled",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      width: 200
    },
    {
      Header: "Pending",
      accessor: "pending",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      width: 200
    },
    {
      Header: "No Show",
      accessor: "no_show",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      width: 200
    },
    {
      Header: "Late Cancel",
      accessor: "late_cancel",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      width: 200
    },
    {
      Header: "Completed Rate",
      accessor: "completed_rate",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      width: 200,
      Cell: props => (
        <div className="react-action-class">
          {props?.original?.completed_rate + " %"}
        </div>
      )
    },
    {
      Header: "Canceled Rate",
      accessor: "canceled_rate",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      width: 200,
      Cell: props => (
        <div className="react-action-class">
          {props?.original?.canceled_rate + " %"}
        </div>
      )
    },
    {
      Header: "Pending Rate",
      accessor: "pending_rate",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      width: 200,
      Cell: props => (
        <div className="react-action-class">
          {props?.original?.pending_rate + " %"}
        </div>
      )
    },
    {
      Header: "No Show rate",
      accessor: "no_show_rate",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      width: 200,
      Cell: props => (
        <div className="react-action-class">
          {props?.original?.no_show_rate + " %"}
        </div>
      )
    },
    {
      Header: "Late Cancel rate",
      accessor: "late_cancel_rate",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      width: 200,
      Cell: props => (
        <div className="react-action-class">
          {props?.original?.late_cancel_rate + " %"}
        </div>
      )
    },
    {
      Header: "Unique Patients Completed - Count",
      accessor: "unique_patients_completed",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      width: 280,
      Cell: props =>
        props?.original?.unique_patients_completed >= 5 ? (
          <div className="react-action-class">{"≥ " + 5}</div>
        ) : (
          <div className="react-action-class">
            {"≥ " + props?.original?.unique_patients_completed}
          </div>
        )
    },

    {
      Header: "Unique Patients Completed - Percent",
      accessor: "unique_patients_completed_rate",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      width: 300,
      Cell: props =>
        props?.original?.unique_patients_completed_rate >= 5 ? (
          <div className="react-action-class">{"≥ " + 5 + " %"}</div>
        ) : (
          <div className="react-action-class">
            {"≥ " + props?.original?.unique_patients_completed_rate + " %"}
          </div>
        )
    }
  ];

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  function getList(field = "name", sortBy = "DESC", page = "1") {
    const params = {
      sort_by: field,
      order: sortBy,
      page: page,
      start_date: dateRange.startDate
        ? moment(dateRange.startDate).format("YYYY-MM-DD")
        : null,
      end_date: dateRange.endDate
        ? moment(dateRange.endDate).format("YYYY-MM-DD")
        : null,
      service_id: props?.admin_role_id < 3 ? serviceId : props.service_id,
      rd_type: typeValue,
      practice_id:
        props?.admin_role_id === 6 && props?.practice
          ? props?.practice
          : practiceId
          ? practiceId
          : undefined
    };

    setLoader(true);
    ReportServices.rdRetentionReportList(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count.length / TABLE.LIMIT));
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
          setLoader(false);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
        setLoader(false);
      });
  }

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span>Provider Retention Report
            </div>
          </div>
          <div className="roe-card-header module-header">
            <div className="d-flex">
              <div className="fs-14 mr-10">
                <span className="fs-16">Select Date</span>
                <br></br>
                <Button
                  className="btn btn-secondary btn-md"
                  onClick={() => toggle()}
                >
                  {dateRange.startDate
                    ? `${moment(dateRange.startDate).format(
                        "MM/DD/YYYY"
                      )} - ${moment(dateRange.endDate).format("MM/DD/YYYY")}`
                    : "Select Date"}
                </Button>
                {dateRange && dateRange.startDate && (
                  <Button
                    className="btn btn-secondary btn-md ml-2"
                    onClick={() => {
                      setActivePage(0);
                      setDateRange({});
                    }}
                  >
                    Reset date
                  </Button>
                )}
              </div>
              {props.admin_role_id !== 3 && (
                <>
                  {props.admin_role_id < 3 && (
                    <div className="fs-14  mr-10">
                      <span className="fs-16">Service</span>
                      <Dropdown
                        isOpen={serviceDropdownOpen}
                        toggle={toggleService}
                      >
                        <DropdownToggle caret size="md">
                          {serviceName ? serviceName : "Select Service"}
                        </DropdownToggle>
                        <DropdownMenu
                          modifiers={{
                            setMaxHeight: {
                              enabled: true,
                              order: 890,
                              fn: data => {
                                return {
                                  ...data,
                                  styles: {
                                    ...data.styles,
                                    overflow: "auto",
                                    maxHeight: "300px"
                                  }
                                };
                              }
                            }
                          }}
                        >
                          <DropdownItem
                            key="-1"
                            onClick={() => {
                              setActivePage(0);
                              setPracticeList([]);
                              setPracticeId("");
                              setPracticeName("");
                              setServiceId("");
                              setServiceName("All");
                              setTypeName(null);
                              setTypeValue(null);
                            }}
                          >
                            All
                          </DropdownItem>
                          {serviceList.length > 0 &&
                            serviceList.map((result, i) => {
                              return (
                                <DropdownItem
                                  key={i}
                                  onClick={() => {
                                    setActivePage(0);
                                    setPracticeList([]);
                                    setPracticeId("");
                                    setPracticeName("");
                                    setServiceId(result.id);
                                    setServiceName(`${result.name}`);
                                    // setRDId("");
                                    // setRDName("");
                                    setTypeName(null);
                                    setTypeValue(null);
                                  }}
                                >
                                  {result.name}
                                </DropdownItem>
                              );
                            })}
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  )}
                  {props?.admin_role_id !== 6 && props?.admin_role_id !== 3 && (
                    <>
                      <div className="fs-14 mr-10">
                        <span className="fs-16">Type</span>
                        <Dropdown isOpen={typeDropdownOpen} toggle={toggleType}>
                          <DropdownToggle caret size="md">
                            {typeName ? typeName : "Type"}
                          </DropdownToggle>
                          <DropdownMenu
                            modifiers={{
                              setMaxHeight: {
                                enabled: true,
                                order: 890,
                                fn: data => {
                                  return {
                                    ...data,
                                    styles: {
                                      ...data.styles,
                                      overflow: "auto",
                                      maxHeight: "300px"
                                    }
                                  };
                                }
                              }
                            }}
                          >
                            <DropdownItem
                              key="-1"
                              onClick={() => {
                                setTypeName("All");
                                setTypeValue("");
                                setActivePage(0);
                              }}
                            >
                              All
                            </DropdownItem>
                            {PROVIDER_TYPES.map((result, i) => {
                              return (
                                <DropdownItem
                                  key={i}
                                  onClick={() => {
                                    setTypeName(`${result.name}`);
                                    setTypeValue(`${result.value}`);
                                    setActivePage(0);
                                    setPracticeName("");
                                    setPracticeId("");
                                  }}
                                >
                                  {result.name}
                                </DropdownItem>
                              );
                            })}
                          </DropdownMenu>
                        </Dropdown>
                      </div>

                      <div className="fs-14 mr-10">
                        <span className="fs-16">Practice</span>
                        <Dropdown
                          isOpen={practiceDropdownOpen}
                          toggle={togglePractice}
                          disabled={typeValue && typeValue !== "ibo practice"}
                        >
                          <DropdownToggle
                            caret
                            size="md"
                            className={
                              typeValue &&
                              typeValue !== "ibo practice" &&
                              "cursor-not-allowed"
                            }
                          >
                            {" "}
                            {practiceId ? practiceName : "Select Practice"}
                          </DropdownToggle>
                          <DropdownMenu
                            modifiers={{
                              setMaxHeight: {
                                enabled: true,
                                order: 890,
                                fn: data => {
                                  return {
                                    ...data,
                                    styles: {
                                      ...data.styles,
                                      overflow: "auto",
                                      maxHeight: "300px"
                                    }
                                  };
                                }
                              }
                            }}
                          >
                            <DropdownItem
                              key="-1"
                              onClick={() => {
                                setPracticeId("");
                                setPracticeName("All");
                                setActivePage(0);
                              }}
                            >
                              All
                            </DropdownItem>
                            {practiceList.length > 0 &&
                              practiceList.map((result, i) => {
                                return (
                                  <DropdownItem
                                    key={i}
                                    onClick={() => {
                                      setPracticeId(result.id);
                                      setPracticeName(
                                        `${result.practice_name}`
                                      );
                                      setActivePage(0);
                                    }}
                                  >
                                    {result.practice_name}
                                  </DropdownItem>
                                );
                              })}
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              LoadingComponent={Loader}
              onFetchData={onChangePageGetData}
              onPageChange={pageIndex => {
                setActivePage(pageIndex);
                getList(field, sortBy, pageIndex + 1);
              }}
              onSortedChange={sortProperties => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getList(sortProperties[0].id, sort, 1);
              }}
            />
          </div>
          <CommonModel
            modal={modal}
            toggle={toggle}
            size="lg"
            children={
              <DateRangePicker
                open={open}
                toggle={() => toggle()}
                onChange={range => {
                  setActivePage(0);
                  setDateRange(range);
                  toggle();
                }}
                closeOnClickOutside={true}
              />
            }
          />
        </div>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};

export default withRouter(RdRetentionTable);
