import React, { useState, useRef, Fragment, useEffect } from "react";
import { compose } from "redux";
import enhancer from "./state-license-validator";
import Button from "../button/Button";
import Toaster from "../common/Toaster";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import RdServices from "../../api/RdServices";
import { ImageResolution } from "../../helper/constant";
import { PLACEHOLDER } from "helper/constant";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import { Input } from "reactstrap";

const StateLicenseModel = props => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    setFieldValue,
    setFieldTouched,
    onSubmit,
    values,
    action
  } = props;

  const [imageObj, setImageObj] = useState(null);
  const [loading, setLoading] = useState(false);
  const [stateList, setStateList] = useState([]);

  useEffect(() => {
    RdServices.stateList().then(res => {
      let data = EncryptDecrypt.decrypt(res.data);
      setStateList(data.data);
    });
  }, []);

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const toaster = useRef();

  const hasParam = props.match.params.id;

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;
    if (isValid) {
      setLoading(true);
      if (action === "add") {
        const params = {
          rd_id: hasParam,
          valid_from: moment(values.valid_from).format("YYYY-MM-DD"),
          valid_to: moment(values.valid_to).format("YYYY-MM-DD"),
          state_id: values.state_id,
          license_number: values.license_number
        };

        const formdata = new FormData();
        const encryptData = EncryptDecrypt.encrypt(params);
        formdata.append("data", encryptData?.data);
        if (imageObj) formdata.append("data1", imageObj);

        RdServices.stateLicenseAdd(formdata)
          .then(res => {
            let data = EncryptDecrypt.decrypt(res.data);
            if (data.status) {
              if (props?.location?.state?.step === "4") {
                props.history.push(`${props.match.params.id}`, {
                  step: "5"
                });
              }
              if (toaster.current) toaster.current.success(data.message);
              props.pageRefresh();
              props.toggle();
              setLoading(false);
            } else {
              if (toaster.current) toaster.current.error(data.message);
              props.pageRefresh();
              props.toggle();
              setLoading(false);
            }
          })
          .catch(err => {
            if (toaster.current) toaster.current.error(err.response);
            props.pageRefresh();
            props.toggle();
            setLoading(false);
          });
      } else if (action === "edit") {
        const params = {
          id: values.id,
          rd_id: hasParam,
          valid_from: values.valid_from,
          valid_to: values.valid_to,
          state_id: values.state_id,
          license_number: values.license_number
        };

        const formdata = new FormData();
        const encryptData = EncryptDecrypt.encrypt(params);
        formdata.append("data", encryptData?.data);
        if (imageObj) formdata.append("data1", imageObj);

        RdServices.stateLicenseEdit(formdata)
          .then(res => {
            let data = EncryptDecrypt.decrypt(res.data);
            if (data.status) {
              if (toaster.current) toaster.current.success(data.message);
              props.pageRefresh();
              props.toggle();
              setLoading(false);
            } else {
              if (toaster.current) toaster.current.error(data.message);
              props.pageRefresh();
              props.toggle();
              setLoading(false);
            }
          })
          .catch(err => {
            if (toaster.current) toaster.current.error(err.response);
            props.pageRefresh();
            props.toggle();
            setLoading(false);
          });
      }
    }

    handleSubmit();
  };

  const handleImage = (e, setValue) => {
    if (e.target.files[0]) {
      let fileObj = e.target.files[0];

      if (fileObj.size > 10 * 1024 * 1024) {
        if (toaster.current)
          toaster.current.error("Please upload a file smaller than 10 MB");
        return false;
      }

      if (!fileObj.name.match(/\.(jpg|jpeg|png|gif|heif)$/)) {
        if (toaster.current)
          toaster.current.error(
            "Only .jpg, .jpeg, .gif, .png, .heif are allowed"
          );
        return false;
      }

      if (fileObj) {
        const img = new Image();
        img.src = window.URL.createObjectURL(fileObj);
        img.onload = function() {
          const width = img.naturalWidth,
            height = img.naturalHeight;
          window.URL.revokeObjectURL(img.src);
          setValue("width", width);
          setValue("height", height);
          setFieldTouched("image", true, true);
          if (
            width >= ImageResolution.WIDTH &&
            height >= ImageResolution.HEIGHT
          ) {
            setImageObj(fileObj);
            setValue("image", URL.createObjectURL(fileObj));
          }
        };
      } else {
        //No file was input or browser doesn't support client side reading
        // form.submit();
      }
    }
  };

  const handleExpireDate = e => {
    setFieldValue(
      "expire_date",
      new Date(
        moment(e)
          .add(1, "year")
          .subtract(1, "day")
      )
    );
    setFieldTouched("expire_date", true, true);
  };

  return (
    <form>
      <div className="form-group">
        <label className="fs-16 medium-text">
          State<span className="asterisk">*</span>
        </label>
        <br />
        <Input
          type="select"
          name=""
          id="state_id"
          onChange={handleChange}
          value={values.state_id}
          onBlur={handleBlur}
        >
          <option value="">Select State</option>
          {stateList &&
            stateList.map((type, i) => {
              return (
                <Fragment>
                  <option key={`option${i}`} value={type.id}>
                    {type.name}
                  </option>
                </Fragment>
              );
            })}
        </Input>
        <Error field="state_id" />
      </div>
      <div className="row">
        <div className="form-group col-md-6">
          <label className="fs-16 medium-text">
            Valid From<span className="asterisk">*</span>
          </label>
          <br />
          <DatePicker
            className="form-control"
            selected={values.valid_from}
            id="valid_from"
            onChange={e => {
              setFieldValue("valid_from", e);
              //handleExpireDate(e);
            }}
            dateFormat="MM/dd/yyyy"
            placeholder="Add From Date"
          />
          <br />
          <Error field="valid_from" />
        </div>
        <div className="form-group col-md-6">
          <label className="fs-16 medium-text">
            Valid To<span className="asterisk">*</span>
          </label>
          <br />
          <DatePicker
            className="form-control"
            selected={values.valid_to}
            id="valid_to"
            onChange={e => {
              setFieldValue("valid_to", e);
            }}
            dateFormat="MM/dd/yyyy"
            placeholder="Add To Date"
          />
          <br />
          <Error field="valid_to" />
        </div>
      </div>

      <div className="form-group">
        <label className="fs-16 medium-text">License Number</label>
        <input
          type="text"
          className="form-control react-form-input"
          id="license_number"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.license_number}
          placeholder="Add License Number"
        />
        <Error field="license_number" />
      </div>
      <div className="form-group">
        <label className="fs-16 medium-text c-btn c-primary demi-bold-text mt-15 wp-17">
          Choose Image
          <input
            id="image"
            className="file-upload__input"
            name="image"
            type="file"
            onChange={e => {
              handleImage(e, setFieldValue);
              setFieldTouched("image", true, true);
            }}
            onBlur={handleBlur}
            required={true}
            accept="image/png, image/jpeg, image/jpg"
            onClick={event => {
              event.target.value = "";
            }}
          />
        </label>
        <Error field="image" />
        <div>
          {values.image ? (
            <img
              src={
                imageObj
                  ? URL.createObjectURL(imageObj)
                  : values.image || PLACEHOLDER
              }
              alt=""
              className="mtb-15"
              style={{
                width: "200px",
                borderRadius: "4%",
                height: "150px",
                background: "#404040"
              }}
            />
          ) : (
            <img
              src={
                imageObj
                  ? URL.createObjectURL(imageObj)
                  : values.image || PLACEHOLDER
              }
              alt=""
              className="mtb-15"
              style={{
                width: "200px",
                borderRadius: "4%",
                height: "150px",
                background: "#404040"
              }}
            />
          )}
        </div>
      </div>
      {props?.history?.location?.state?.step === "4" ? (
        <div>
          <Button
            type="button"
            loading={loading}
            disabled={loading}
            className="c-btn c-info form-button fs-16 demi-bold-text mr-2"
            style={{ maxWidth: "180px" }}
            onClick={() => handleSubmit()}
            dataStyle="expand-right"
          >
            Save and Continue
          </Button>
          <Button
            type="button"
            className="c-btn c-info form-button fs-16 demi-bold-text "
            style={{ maxWidth: "125px" }}
            onClick={() =>
              props.history.push(
                `${props.match.params.id}`,
                {
                  step: "5"
                },
                props.toggle()
              )
            }
          >
            Skip
          </Button>
        </div>
      ) : (
        <div>
          <Button
            type="button"
            className="c-btn c-info form-button fs-16 demi-bold-text mr-10"
            style={{ maxWidth: "125px" }}
            loading={loading}
            onClick={() => handleSubmit()}
            disabled={loading}
            dataStyle="expand-right"
          >
            Submit
          </Button>
          <Button
            type="button"
            className="c-btn c-secondary form-button fs-16 demi-bold-text"
            style={{ maxWidth: "125px" }}
            onClick={props.toggle}
          >
            Close
          </Button>
        </div>
      )}
      <Toaster ref={toaster} />
    </form>
  );
};

export default compose(enhancer)(StateLicenseModel);
