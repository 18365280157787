import React from "react";

const FormViewDetails = props => {
  const formDetails = props.location.state;

  return (
    <div className="plr-15">
      <div className="roe-card-style mtb-15">
        <div className="roe-card-header module-header">
          <div className="flex-1 fs-16 demi-bold-text">
            <span className="hash"># </span> View Form
          </div>
        </div>
        <div className="roe-card-body">
          <div className="pt-2">
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>Name</div>
            <div>{formDetails?.name ? formDetails?.name : "-"}</div>
          </div>
          <div className="pt-2">
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>
              Service
            </div>
            <div>{formDetails?.service ? formDetails?.service?.name : "-"}</div>
          </div>
          <div className="pt-2">
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>Type</div>
            <div>{formDetails?.type ? formDetails?.type : "-"}</div>
          </div>
          <div className="pt-2">
            <div>
              <div style={{ fontWeight: "bolder", textAlign: "left" }}>
                {formDetails?.file_name ? (
                  <a href={formDetails?.file_name} target="_blank">
                    URL to the page
                  </a>
                ) : (
                  <div>-</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormViewDetails;
