import EncryptDecrypt from "api/EncryptDecrypt";
import TierManagementServices from "api/TierMangementServices";
import Loader from "components/common/Loader";
import Toaster from "components/common/Toaster";
import {
  MHTIERS,
  SERVICE_TYPE,
  MODULE_NAMES,
  PERMISSION_TYPE
} from "helper/constant";
import React, { useEffect, useRef, useState } from "react";
import ReactTable from "react-table";
import { Button, Pagination } from "reactstrap";
import { hasAccess } from "util/sidebar";

const defaultModules = ["More", "Dashboard", "Message", "Visits"];

const TierModalData = props => {
  const { permissions } = props;
  const [tblData, settblData] = useState([]);
  const [loader, setLoader] = useState(false);
  const toaster = useRef(null);
  const [permission, setPermissions] = useState([]);
  const [saveBtn, setSaveBtn] = useState(false);

  useEffect(() => {
    if (props?.selectedData?.service?.id === SERVICE_TYPE.MENTAL_HEALTH) {
      const tdata = [...MHTIERS];
      const index = tdata.findIndex(el => el?.id === 7);
      if (index !== -1) {
        tdata[index].status = props?.selectedData?.tier?.id === 3 ? 1 : 0;
      }
      settblData([...tdata]);
    } else getList();
  }, [props?.selectedData?.service?.id]);

  const handleChange = id => {
    const updated = [...permission];
    let index = updated.findIndex(per => per.id === id);
    if (index !== -1) {
      updated[index].status = updated[index].status ? 0 : 1;
    }
    setPermissions([...updated]);
  };

  const columns = [
    {
      Header: "Module Name",
      accessor: "name",
      headerClassName: "react-table-header-class",
      filterable: false,
      width: 230,
      sortable: true
    },
    {
      Header: "Status",
      accessor: "status",
      className: "text-center",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 210,
      Cell: ({ original, value }) => {
        return (
          <input
            type="checkbox"
            className="checkbox"
            checked={value}
            onChange={() => handleChange(original.id)}
            disabled={
              defaultModules.includes(original.name) ||
              (Number(props?.tierId) === 1 &&
                original?.backend_name === "points_reward_system") ||
              props?.selectedData?.service?.id === SERVICE_TYPE.MENTAL_HEALTH
            }
          />
        );
      }
    }
  ];

  function getList() {
    const params = {
      tier_id: props?.selectedData?.id
    };

    setLoader(true);
    TierManagementServices.listTierModule(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data);
          setPermissions(data.data);
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
          setLoader(false);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
        setLoader(false);
      });
  }

  const handleSave = () => {
    setSaveBtn(true);
    const payloadPErmissions = permission.map(el => {
      return {
        tier_module_id: el.id,
        status: el.status
      };
    });
    const params = {
      tier_id: props?.selectedData?.id,
      permissions: [...payloadPErmissions]
    };
    TierManagementServices.update(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);

        if (data.status) {
          if (toaster.current) toaster.current.success(data.message);
          setTimeout(() => {
            props.toggle();
          }, 1000);
        } else {
          if (toaster.current) toaster.current.error(data.message);
          setSaveBtn(false);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
        setSaveBtn(false);
      });
  };

  return (
    <div>
      <ReactTable
        style={{
          border: "none",
          boxShadow: "none"
        }}
        data={tblData}
        columns={columns}
        loading={loader}
        LoadingComponent={Loader}
        minRows={2}
        PaginationComponent={Pagination}
      />
      <div className="flex-y">
        {!loader &&
          props?.selectedData?.service?.id !== SERVICE_TYPE.MENTAL_HEALTH &&
          hasAccess(MODULE_NAMES.TIER, PERMISSION_TYPE.EDIT, permissions) && (
            <Button
              className="c-btn c-info fs-16 demi-bold-text align-self-center"
              onClick={handleSave}
            >
              {saveBtn ? "Saving..." : "Save"}
            </Button>
          )}
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};

export default TierModalData;
