import React, { useState, useEffect, useRef, useMemo } from "react";
import Sidebar from "components/sidebar/Sidebar";
import HorizontalSidebar from "components/horizontalsidebar/HorizontalSidebar";
import ThemeSetting from "components/themesetting/ThemeSetting";
import dashboardRoutes from "routes/dashboardRoutes";
import Header from "components/header/Header";
import Footer from "components/footer/Footer";
import themeActions from "redux/themeChanger/actions.js";
import settingactions from "redux/themeSettings/actions";
import AppLocale from "languageProvider";
// import { Button } from "reactstrap";
import {
  drawerWidth,
  miniDrawerWidth,
  themeSettingDrawerWidth
} from "helper/constant";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import { ProtectedRoute } from "./../routes/ProtectedRoute";
import GlobalWrapper from "./global.style";
import LayoutSettings from "components/layoutsetting/LayoutSettings";
import { STREAM_API_KEY, CMS_URL, AppName } from "../helper/constant";
import { StreamChat } from "stream-chat";
import Logo from "../assets/images/logo192.png";
import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";
import visitAMDStatus from "redux/amdStatus/actions";

const { clear } = visitAMDStatus;

const { changeTheme } = themeActions;
const { sidebarMini } = settingactions;

const firebaseConfig = {
  apiKey: "AIzaSyAXJdcy--YN5iOuJ0uZ7lOt4wWz-aPSuFA",
  authDomain: "charge-3cd9b.firebaseapp.com",
  projectId: "charge-3cd9b",
  storageBucket: "charge-3cd9b.appspot.com",
  messagingSenderId: "927808025019",
  appId: "1:927808025019:web:8cc540e3518f04b90fd49e",
  measurementId: "G-TL3B75N1K6"
};
const app = initializeApp(firebaseConfig);
const messaging = getMessaging();

onMessage(messaging, payload => {
  console.log("Message received. ", payload);
  const title = `New Notification from ` + AppName;
  const notification = new Notification(title, {
    body: payload.notification.body,
    icon: Logo
  });
  notification.onclick = function(event) {
    event.preventDefault();
    window.open(CMS_URL, "_blank");
  };
});

const DashboardLayout = props => {
  const [mini, setMini] = useState(false);
  const [themeDrawer, setThemeDrawer] = useState(true);
  const [layoutSettingDrawer, setLayoutSettingDrawer] = useState(true);
  const [statedrawerWidth] = useState(drawerWidth);
  const [stateminiDrawerWidth, setStateminiDrawerWidth] = useState(
    miniDrawerWidth
  );
  const mainPanel = useRef(null);
  const scrollbars = useRef(null);

  useEffect(() => {
    if (localStorage.getItem("isRefresh") === "true") {
      props.clear();
    }
  }, []);

  useEffect(() => {
    if (
      props?.statusVisitAMD === "success" ||
      props?.statusVisitAMD === "failed"
    ) {
      setTimeout(() => {
        props.clear();
      }, 5000);
    }
  }, [props?.statusVisitAMD]);

  useMemo(() => {
    if (scrollbars && scrollbars.current) {
      scrollbars.current.scrollToTop(0);
    }
  }, []);

  useEffect(() => {
    // Ask for notification permission and attach chat listeners after login only
    if (props.authData.isLogin && props.authData.token) {
      // Ask for permission to send notification if required
      if (
        !window.Notification ||
        (Notification.permission !== "granted" &&
          Notification.permission !== "denied")
      ) {
        askPermission();
      } else {
        addMessageListener();
      }
    }
  }, [props.authData.isLogin, props.authData.token]);

  let mainPanelWidth;

  const { layoutTheme, locale, themeSetting } = props;
  const currentAppLocale = AppLocale[locale];

  let routeArrowIcon = {
    backgroundColor: props.sidebarTheme.backgroundColor,
    color: props.sidebarTheme.textColor,
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    position: "fixed",
    zIndex: 1,
    fontSize: "18px",
    padding: "4px 5px"
  };

  if (themeSetting.layout === "vertical") {
    mainPanelWidth = {
      width: mini
        ? `calc(100% - ${miniDrawerWidth})`
        : `calc(100% - ${drawerWidth})`,
      backgroundColor: layoutTheme.backgroundColor
    };
  } else if (themeSetting.layout === "horizontal") {
    mainPanelWidth = {
      width: "100%",
      backgroundColor: layoutTheme.backgroundColor
    };
  }

  const drawerMiniMethod = () => {
    if (mini) {
      setMini(false);
      props.sidebarMini("off");
    } else {
      setMini(true);
      props.sidebarMini("on");
    }
  };

  const themeSettingDrawer = () => {
    if (themeDrawer) {
      setThemeDrawer(false);
    } else {
      setThemeDrawer(true);
    }
  };

  const toggleLayoutSettingDrawer = () => {
    setLayoutSettingDrawer(!layoutSettingDrawer);
  };

  const mouseEnter = () => {
    if (mini) {
      setStateminiDrawerWidth(drawerWidth);
    }
  };

  const mouseLeave = () => {
    if (mini) {
      setStateminiDrawerWidth(miniDrawerWidth);
    }
  };

  const closeDrawer = () => {
    setMini(true);
  };

  const askPermission = () => {
    if (
      Notification.permission !== "denied" ||
      Notification.permission === "default"
    ) {
      Notification.requestPermission()
        .then(result => {
          if (result === "granted") {
            addMessageListener();
          }
        })
        .catch(err => {
          console.error(err);
          alert("Failed to grant notification permission");
        });
    }
  };

  const addMessageListener = async () => {
    console.log("In addMessageListener method ", props.authData.id);
    try {
      if (
        props.authData.id &&
        props.authData.admin_role_id &&
        props.authData.stream_io_token
      ) {
        console.log("In listener ", props.authData.id);

        const user =
          props.authData.admin_role_id !== 3
            ? `AD_${props.authData.id.toString()}`
            : `RD_${props.authData.id.toString()}`;
        const userToConnect = { id: user, name: props.authData.full_name };
        const chatClient = StreamChat.getInstance(STREAM_API_KEY);
        chatClient.connectUser(userToConnect, props.authData.stream_io_token);

        const channels = await chatClient.queryChannels(
          { type: "messaging", members: { $in: [user] } },
          {
            last_message_at: -1,
            updated_at: -1,
            cid: 1
          },
          { watch: true }
        );

        channels.forEach(channel => {
          channel.disableSlowMode();
          channel.on(event => {
            // console.log(event.type, event)
            if (
              (event.type === "message.new" || event.type === "reaction.new") &&
              event.unread_count > 0 &&
              event.message.user.id !== user
            ) {
              let body = event.message.text;
              if (event.message.attachments.length > 0) {
                body = "Attachment";
              }
              const title =
                event.type === "message.new"
                  ? `${event.message.user.name} sent you a message`
                  : `${event.message.user.name} reacted to your message`;
              const notification = new Notification(title, {
                body: body,
                icon: Logo
              });
              notification.onclick = function(event) {
                event.preventDefault();
                window.open(CMS_URL + "/message", "_blank");
              };
            }

            // if (event.type === 'message.read' && !event.total_unread_count) {
            //   console.log('read message')
            // }
          });
        });
      }
    } catch (err) {
      console.error("Failed to execute listeners ", err);
    }
  };

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <GlobalWrapper {...props}>
        {themeSetting.layout === "vertical" && (
          <Sidebar
            mini={mini}
            drawerWidth={statedrawerWidth}
            miniDrawerWidth={stateminiDrawerWidth}
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
            closeDrawer={() => closeDrawer}
            {...props}
          />
        )}
        {themeSetting.layout === "horizontal" && (
          <div className="hor_mobile_sidebar">
            <Sidebar
              mini={mini}
              drawerWidth={statedrawerWidth}
              miniDrawerWidth={stateminiDrawerWidth}
              onMouseEnter={mouseEnter}
              onMouseLeave={mouseLeave}
              closeDrawer={() => closeDrawer}
              {...props}
            />
          </div>
        )}

        <ThemeSetting
          mini={themeDrawer}
          drawerWidth={themeSettingDrawerWidth}
          miniDrawerWidth={stateminiDrawerWidth}
          closeSettingDrawer={themeSettingDrawer}
          {...props}
        />

        <LayoutSettings
          mini={layoutSettingDrawer}
          drawerWidth={themeSettingDrawerWidth}
          miniDrawerWidth={stateminiDrawerWidth}
          closeSettingDrawer={toggleLayoutSettingDrawer}
          {...props}
        />

        <div
          id="main-panel"
          className="main-panel flex-y"
          ref={mainPanel}
          style={mainPanelWidth}
        >
          <div>
            {themeSetting.layout === "horizontal" && (
              <HorizontalSidebar
                {...props}
                drawerMiniMethod={drawerMiniMethod}
                layoutSettingDrawerToggle={toggleLayoutSettingDrawer}
              />
            )}
            {themeSetting.layout === "vertical" &&
              props.themeSetting.toolbarDisplayValue === "show" && (
                <Header
                  drawerMiniMethod={drawerMiniMethod}
                  mini={mini}
                  drawerWidth={statedrawerWidth}
                  miniDrawerWidth={stateminiDrawerWidth}
                  layoutSettingDrawerToggle={toggleLayoutSettingDrawer}
                  {...props}
                />
              )}
          </div>
          {/* <Button
            className="setting-button"
            onClick={themeSettingDrawer}
            style={{
              backgroundColor: "white",
              color: "rgba(0,0,0,.87)"
            }}
          >
            <i className="fas fa-cogs" />
          </Button> */}

          {themeSetting.toolbarDisplayValue !== "show" && (
            <button
              style={routeArrowIcon}
              className="c-btn mini-drawer-menu-icon-hide-topbar"
              onClick={drawerMiniMethod}
            >
              <i className="fas fa-bars" />
            </button>
          )}

          {/* Route Layout Start*/}
          <div
            className="route-height flex-1 overflow-auto scroll-color"
            style={
              themeSetting.toolbarDisplayValue === "show"
                ? {
                    background: layoutTheme.backgroundColor
                  }
                : {
                    background: layoutTheme.backgroundColor
                  }
            }
          >
            {props?.statusVisitAMD && props.authData.admin_role_id !== 3 && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  display: "flex",
                  height: 24,
                  right: 0,
                  left: 0,
                  justifyContent: "center"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    background:
                      props?.statusVisitAMD === "failed" ? "red" : "#fff",
                    color: props?.statusVisitAMD === "failed" ? "#fff" : "#000",
                    paddingInline: 6,
                    borderRadius: 4
                  }}
                >
                  {props?.statusVisitAMD === "progress" &&
                    "Adding visits in AMD....."}

                  {props?.statusVisitAMD === "success" &&
                    "Successfully added visits in AMD"}

                  {props?.statusVisitAMD === "failed" && (
                    <>
                      <div>Could not successfully add visits in AMD</div>
                      <div
                        style={{
                          paddingLeft: 4,
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer"
                        }}
                        onClick={() => props?.clear()}
                      >
                        x
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
            <Switch>
              <ProtectedRoute {...props}>
                {dashboardRoutes.map((prop, key) => {
                  if (
                    prop?.path === "/message" &&
                    props?.authData?.service_id === 2
                  ) {
                    return;
                  } else if (
                    props?.authData?.tier_id === 1 &&
                    props?.authData?.type !== "employee" &&
                    props?.authData?.admin_role_id === 3 &&
                    prop?.path === "/content-library"
                  ) {
                    return;
                  } else
                    return (
                      <Route
                        exact
                        path={prop.path}
                        component={prop.component}
                        key={key}
                      />
                    );
                })}
              </ProtectedRoute>
            </Switch>
          </div>
          {/* Route Layout Finish*/}
          <div>
            {themeSetting.footerDisplayValue === "show" && (
              <Footer
                {...props}
                mini={mini}
                drawerWidth={statedrawerWidth}
                miniDrawerWidth={stateminiDrawerWidth}
              />
            )}
          </div>
        </div>
      </GlobalWrapper>
    </IntlProvider>
  );
};

const mapStateToProps = state => {
  return {
    ...state.amdStatus,
    ...state.themeChanger,
    auth: { ...state.auth },
    LanguageSwitcher: state.LanguageSwitcher,
    locale: state.LanguageSwitcher.language.locale,

    authData: {
      token: state.auth.token,
      isLogin: state.auth.isLogin,
      profileImage: state.auth.profile_image,
      adminRoleId: state.auth.admin_role_id,
      adminRole: state.auth.admin_role,
      full_name: state.auth.first_name + " " + state.auth.last_name,
      step: state.auth.step,
      id: state.auth.id,
      stream_io_token: state.auth.stream_io_token,
      admin_role_id: state.auth.admin_role_id,
      service_id: state.auth.service_id,
      type: state.auth.adminType,
      tier_id: state.auth.tier_id,
      is_messaging_active: state.auth.is_messaging_active
    },
    ...state.permissionReducer,
    themeSetting: {
      toolbarAlignValue: state.themeSetting.toolbarAlignValue,
      footerAlignValue: state.themeSetting.footerAlignValue,
      sidebarDisplayValue: state.themeSetting.sidebarDisplayValue,
      toolbarDisplayValue: state.themeSetting.toolbarDisplayValue,
      footerDisplayValue: state.themeSetting.footerDisplayValue,
      sidebarTransParentValue: state.themeSetting.sidebarTransParentValue,
      transparentImage: state.themeSetting.transparentImage,
      activeLinkStyle: state.themeSetting.activeLinkStyle,
      sidebarMiniValue: state.themeSetting.sidebarMiniValue,
      layout: state.themeSetting.layout,
      sidebarTransParentActiveBack:
        state.themeSetting.sidebarTransParentActiveBack,
      sidebarTransParentActiveColor:
        state.themeSetting.sidebarTransParentActiveColor
    }
  };
};

export default connect(mapStateToProps, {
  changeTheme,
  sidebarMini,
  clear
})(DashboardLayout);
