import { withFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    first_name: Yup.string()
      .trim("Cannot include leading and trailing spaces")
      .strict(true)
      .matches(/^[A-Za-z0-9 ]+$/, "Cannot include special characters")
      .required("This field is required")
      .max(100, "First Name must be less than 100 characters"),
    last_name: Yup.string()
      .trim("Cannot include leading and trailing spaces")
      .strict(true)
      .matches(/^[A-Za-z0-9 ]+$/, "Cannot include special characters")
      .required("This field is required")
      .max(100, "Last Name must be less than 100 characters"),
    email: Yup.string()
      .trim()
      .required("This field is required")
      .email("Please enter valid email"),
    category_id: Yup.string()
      .trim()
      .required("This field is required"),
    group_id: Yup.string()
      .trim()
      .required("This field is required"),
    registration_code_id: Yup.string()
      .trim()
      .required("This field is required"),
    service_id: Yup.string()
      .trim()
      .required("This field is required"),
    rd_id: Yup.string()
      .trim()
      .required("This field is required"),
    insurance_id: Yup.string()
      .trim()
      .required("This field is required"),
    zipcode: Yup.string()
      .trim()
      .required("This field is required"),
    gender: Yup.string()
      .trim()
      .required("This field is required"),
    profile_photo: Yup.string()
      .trim()
      .notRequired(),
    dob: Yup.date()
      .nullable()
      .required("This field is required")
  }),
  mapPropsToValues: props => ({
    first_name: "",
    last_name: "",
    email: "",
    category_id: "",
    group_id: "",
    registration_code_id: "",
    service_id: props?.authData?.service_id ? props?.authData?.service_id : "",
    rd_id: "",
    insurance_id: "",
    zipcode: "",
    profile_photo: "",
    gender: "",
    dob: ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
