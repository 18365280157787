import React, { useRef, useState } from "react";
import { compose } from "redux";
import Button from "../button/Button";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import SubAdminServices from "../../api/SubAdminServices";
import Toaster from "../common/Toaster";
import { hasAccess } from "util/sidebar";
import { MODULE_NAMES, PERMISSION_TYPE } from "helper/constant";

const ImportUserForm = props => {
  const {
    handleBlur,
    errors,
    setFieldValue,
    touched,
    submitCount,
    onSubmit,
    action,
    permissions
  } = props;

  const toaster = useRef();
  const [imageObj, setImageObj] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const handleImage = (e, setValue) => {
    if (e.target.files[0]) {
      let fileObj = e.target.files[0];
      let reader = new FileReader();
      reader.readAsText(fileObj);
      reader.onloadend = () => {
        setImageObj(reader.result);
      };
    }
  };

  const handleSubmit = () => {
    setIsLoading(true);
    let { values, isValid, handleSubmit } = props;

    let param = {
      csvData: imageObj
    };
    SubAdminServices.importUser(param)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current) toaster.current.success(data.message);
          setTimeout(() => {
            onSubmit(values, action);
          }, 1000);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
        setIsLoading(false);
      })
      .catch(err => {
        if (err?.message !== "Network Error") {
          if (toaster.current) toaster.current.error(err?.message);
        } else {
          if (toaster.current)
            toaster.current.info(
              "Process is running in background. You'll receive an email once it's completed."
            );
        }
        setIsLoading(false);
      });

    // handleSubmit();
  };

  const exportCsv = () => {
    SubAdminServices.exportSampleUser();
  };

  return (
    <div>
      <div className="float-right">
        <Button className="c-btn c-primary ma-5" onClick={() => exportCsv()}>
          <i className="fas fa-download mr-10" />
          Export Sample File
        </Button>
      </div>

      <form>
        <div>
          <div className="form-group">
            <label className="fs-16 medium-text"> Upload CSV File</label>
            <br />
            <div className="">
              <input
                className=""
                name="file"
                type="file"
                accept=".csv"
                onChange={e => handleImage(e, setFieldValue)}
                onBlur={handleBlur}
              />
            </div>

            <br />
            {/* <Error field="image" /> */}
          </div>
        </div>
        <div>
          <Button
            type="button"
            className="c-btn c-info form-button fs-16 demi-bold-text"
            style={{ maxWidth: "125px" }}
            onClick={() => handleSubmit()}
            loading={isLoading}
            disabled={
              !imageObj ||
              isLoading ||
              !hasAccess(
                MODULE_NAMES.IMPORT_USER,
                PERMISSION_TYPE.ADD,
                permissions
              )
            }
            dataStyle={"expand-right"}
          >
            Submit
          </Button>
        </div>
      </form>
      <Toaster ref={toaster} />
    </div>
  );
};

export default compose()(ImportUserForm);
