import axios from "axios";
import { baseUrl } from "../helper/constant";
import Header from "./Header";
import EncryptDecrypt from "./EncryptDecrypt";
import FileDownload from "js-file-download";

export default class ExternalProviderServices {
  static list(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/external-rd/list`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static add(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/external-rd/add`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static search(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/external-rd/search`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static stateList(params) {
    Header.setHeaders();
    return axios.get(`${baseUrl}/external-rd/state-list`);
  }

  static edit(params) {
    Header.setHeaders();
    return axios.put(
      `${baseUrl}/external-rd/edit`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static delete(params) {
    Header.setHeaders();
    return axios.delete(
      `${baseUrl}/external-rd/delete/`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }
  static viewProvider(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/external-rd/view`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }
}
