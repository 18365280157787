import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import VisitInsuranceCarrier from "../../api/VisitInsuranceCarrier";
import ClientServices from "../../api/ClientServices";
import Loader from "../common/Loader";
import Toaster from "../common/Toaster";
import { MODULE_NAMES, PERMISSION_TYPE, TABLE } from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import CommonModel from "../common/CommonModel";
import AddInsurance from "components/insurancecarrier/AddInsurance";
import moment from "moment";
import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import CompleteBillingForm from "components/visittracking/CompleteBillingForm";
import ViewCompleteBill from "components/visittracking/ViewCompleteBill";
import { Label } from "reactstrap";
import { hasAccess } from "util/sidebar";

const InsuranceCarrier = props => {
  const { permissions } = props;
  const hasParam = props.match.params.id;

  const [tblData, settblData] = useState();

  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("created_at");
  const [sortBy, setSortBy] = useState("DESC");
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [editedData, setEditedData] = useState(undefined);

  const [dateRange, setDateRange] = useState({});
  const [open, setOpen] = useState(false);
  const [groupId, setGroupId] = useState(null);
  const [memberId, setMemberId] = useState(null);
  const [pageRefresh, setPageRefresh] = useState(false);

  const [active, setActive] = useState(false);
  const [selectedFood, setSelectedFood] = useState(null);
  // const [dateRange, setDateRange] = useState([null, null]);
  // const [startDate, endDate] = dateRange;

  useEffect(() => {
    if (
      hasAccess(
        MODULE_NAMES.CLIENT_INSURANCE,
        PERMISSION_TYPE.VIEW,
        permissions
      )
    ) {
      getList(field, sortBy, searchText, activePage + 1);
    }
  }, [dateRange.startDate, dateRange.endDate]);

  const toaster = useRef();

  useEffect(() => {
    if (
      hasAccess(
        MODULE_NAMES.CLIENT_INSURANCE,
        PERMISSION_TYPE.VIEW,
        permissions
      )
    ) {
      getList(field, sortBy, searchText, activePage + 1);
      setPages(10);
    }
    const params = {
      id: hasParam
    };
    ClientServices.view(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        setEditedData(data.data);
        setMemberId(data.data.user_unique_id);
        setGroupId(data.data.group);
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current) toaster.current.error(err?.message);
        }
      });
  }, []);
  // useEffect(() => {
  //   getList(field, sortBy, searchText, activePage + 1);
  //   setPages(10);
  //   const params = {
  //     id: hasParam
  //   };
  //   ClientServices.view(params).then(res => {
  //     let data = EncryptDecrypt.decrypt(res.data);
  //     setEditedData(data.data);
  //   });
  // }, [modal]);
  // useEffect(() => {
  //   getList(field, sortBy, searchText, activePage + 1);
  //   setPages(10);
  // }, [active]);
  function toggle() {
    modal && setEditedData({ user_unique_id: memberId, group: groupId });
    setModal(!modal);
  }

  function toggle1() {
    setModal2(!modal2);
    setOpen(!open);
  }
  function toggle2() {
    setModal1(!modal1);
  }
  function viewToggle() {
    setViewModal(!viewModal);
    setOpen(!open);
  }
  const columns = [
    {
      Header: "ID",
      accessor: "id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 100,
      sortable: false
    },
    {
      Header: "Insurance Name",
      accessor: "insurance.name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 250,
      sortable: false,
      Cell: props => props.original.insurance && props.original.insurance.name
    },
    {
      Header: "Member ID",
      accessor: "member_id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 200,
      sortable: false
    },
    {
      Header: "Group ID",
      accessor: "group_id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 200,
      sortable: false
    },
    {
      Header: "Primary Subscriber",
      accessor: "is_primary_subscriber",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 200,
      sortable: false
    },
    {
      Header: "Status",
      accessor: "is_active",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 150,
      sortable: false,
      Cell: props => (
        <div className="react-action-class">
          {props.original.is_active ? (
            <Label
              style={{ minWidth: "125px", pointerEvents: "none" }}
              className="c-btn c-info mr-10"
            >
              <div className="fs-14 medium-text">Active</div>
            </Label>
          ) : (
            <Button
              style={{ minWidth: "125px" }}
              className="c-btn c-warning mr-10 "
              onClick={() => {
                setActive(!active);
                handleStatusChange(props.original);
              }}
              disabled={
                !hasAccess(
                  MODULE_NAMES.CLIENT_INSURANCE,
                  PERMISSION_TYPE.STATUS,
                  permissions
                )
              }
            >
              <div className="fs-14 medium-text">Set Active</div>
            </Button>
          )}{" "}
        </div>
      )
    },
    {
      Header: "Action",
      accessor: "is_active",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 300,
      sortable: false,
      Cell: props =>
        hasAccess(
          MODULE_NAMES.CLIENT_INSURANCE,
          PERMISSION_TYPE.EDIT,
          permissions
        ) ? (
          <Button
            className="c-btn c-success mr-10"
            onClick={() => formAction("edit", props.original)}
          >
            <div className="fs-14 medium-text">
              <i className="fas fa-edit" />
            </div>
          </Button>
        ) : (
          ""
        )
    }
  ];
  const handleSubmit = () => {
    getList(field, sortBy, searchText, activePage + 1);
    setPages(10);
  };
  const handleStatusChange = data => {
    setLoader(true);
    let paramObj = {
      user_id: data.user_id,
      insurance_id: data.insurance_id,
      id: data.id
    };
    VisitInsuranceCarrier.changeStatus(paramObj)
      .then(result => {
        setLoader(false);
        getList(field, sortBy, searchText, activePage + 1);
        setPages(10);
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current) toaster.current.error(err?.message);
        }
        setLoader(false);
      });
  };
  const formAction = (action, data = null) => {
    // Here you can view the data and make forward action for edit data
    if (action === "edit") {
      setEditedData({ type: "edit", ...editedData, ...data });

      setModal(!modal);
    }
  };

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  function getList(
    field = "created_at",
    sortBy = "DESC",
    search = "",
    page = "1",
    client_id = null
  ) {
    const hasParam = props.match.params.id;
    const params = {
      sort_by: field,
      order: sortBy,
      search: search,
      page: page,
      user_id: hasParam,
      id: client_id,
      start_date: dateRange.startDate
        ? moment(dateRange.startDate).format("YYYY-MM-DD")
        : null,
      end_date: dateRange.endDate
        ? moment(dateRange.endDate).format("YYYY-MM-DD")
        : null
    };

    setLoader(true);
    VisitInsuranceCarrier.list(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count / TABLE.LIMIT));
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current) toaster.current.error(err?.message);
        }
      });
  }

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span>Insurance Carrier List
            </div>
            <div className="d-flex">
              <div>
                {hasAccess(
                  MODULE_NAMES.CLIENT_INSURANCE,
                  PERMISSION_TYPE.ADD,
                  permissions
                ) ? (
                  <Button
                    className="c-btn c-primary ma-5"
                    onClick={() => {
                      setSelectedFood(null);
                      setModal(!modal);
                      setEditedData({ type: "add", ...editedData });
                    }}
                  >
                    <i className="fas fa-plus mr-10" />
                    Add Insurance Carrier
                  </Button>
                ) : (
                  ""
                )}
              </div>
              {/* )} */}
            </div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;
                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              LoadingComponent={Loader}
              onFetchData={onChangePageGetData}
              onPageChange={pageIndex => {
                setActivePage(pageIndex);
                getList(field, sortBy, searchText, pageIndex + 1);
              }}
              onSortedChange={sortProperties => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getList(sortProperties[0].id, sort, searchText, 1);
                // alert("sort handler", sortProperties);
                // const orderByDirection = sortProperties[0].desc ? 1 : 0;

                // updateMapStateInfoOrderByColumn(sortProperties[0].id, orderByDirection);
              }}
            />
          </div>
        </div>
      </div>
      <Toaster ref={toaster} />
      {modal && (
        <CommonModel
          modal={modal}
          toggle={toggle}
          onSubmit={handleSubmit}
          size="lg"
          children={
            <AddInsurance
              onSubmit={handleSubmit}
              editedData={editedData}
              toggle={toggle}
              pageRefresh={() => {
                getList(field, sortBy, searchText, activePage + 1);
              }}
              {...props}
            />
          }
          // header={editedData ? "" : "Add Registration Code"}
        />
      )}
      {modal1 && (
        <CommonModel
          modal={modal1}
          toggle={toggle2}
          size="xl"
          children={
            <CompleteBillingForm
              // onSubmit={submitFormHandler}
              pageRefresh={() => {
                getList(field, sortBy, searchText, activePage + 1);
              }}
              editedData={selectedFood}
              toggle={toggle2}
              {...props}
            />
          }
          // header={editedData ? "" : "Add Registration Code"}
        />
      )}
      {open && (
        <CommonModel
          modal={modal2}
          toggle={toggle1}
          size="lg"
          children={
            <DateRangePicker
              open={open}
              toggle={() => toggle1()}
              onChange={range => {
                setDateRange(range);
                // setModal(!modal)
                toggle1();
              }}
              closeOnClickOutside={true}
            />
          }
          // header={editedData ? "" : "Add Registration Code"}
        />
      )}
      {viewModal && (
        <CommonModel
          modal={viewModal}
          toggle={viewToggle}
          size="lg"
          children={
            <ViewCompleteBill
              editedData={selectedFood}
              toggle={toggle2}
              {...props}
            />
          }
          // header={editedData ? "" : "Add Registration Code"}
        />
      )}
    </div>
  );
};

export default withRouter(InsuranceCarrier);
