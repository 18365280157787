import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import ReportServices from "../../api/ReportServices";
import Loader from "../common/Loader";
import Toaster from "../common/Toaster";
import { PROVIDER_TYPES, SERVICE_NAME, TABLE } from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import moment from "moment-timezone";
import Button from "components/button/Button";
import CommonModel from "../common/CommonModel";
import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
import GroupServices from "../../api/GroupServices";
import ClientServices from "../../api/ClientServices";
import CategoryServices from "api/CategoryServices";

const InsuranceBillTable = props => {
  const toaster = useRef(null);
  const [tblData, settblData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("id");
  const [sortBy, setSortBy] = useState("ASC");
  const [dateRange, setDateRange] = useState({});
  const [modal, setModal] = useState(undefined);
  const [open, setOpen] = useState(false);

  const [paymentMethodValue, setPaymentMethodValue] = useState("");
  const [parentDropdownOpenPayment, setParentDropdownOpenPayment] = useState(
    false
  );
  const [insuranceList, setInsuranceList] = useState([]);
  const [insuranceId, setInsuranceId] = useState(null);
  const [insuranceName, setInsuranceName] = useState(null);
  const [insuranceDropdownOpen, setInsuranceDropdownOpen] = useState(false);
  const [parentDropdownOpen, setParentDropdownOpen] = useState(false);

  const [createdDateRange, setCreatedDateRange] = useState({});
  const [modal1, setModal1] = useState(undefined);
  const [open1, setOpen1] = useState(false);

  const [updatedDateRange, setUpdatedDateRange] = useState({});
  const [modal2, setModal2] = useState(undefined);
  const [open2, setOpen2] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const [typeName, setTypeName] = useState(null);
  const [typeValue, setTypeValue] = useState(null);
  const [typeDropdownOpen, setTypeDropdownOpen] = useState(false);

  const [serviceId, setServiceId] = useState(null);
  const [serviceName, setServiceName] = useState(null);
  const [serviceList, setServiceList] = useState([]);
  const [serviceDropdownOpen, setServiceDropdownOpen] = useState(false);

  useEffect(() => {
    getList(field, sortBy, searchText, activePage + 1, paymentMethodValue);
    setPages(10);

    GroupServices.insuranceList().then(res => {
      let data = EncryptDecrypt.decrypt(res.data);
      setInsuranceList(data.data);
    });
  }, []);

  useEffect(() => {
    getList(field, sortBy, searchText, 1, paymentMethodValue);
    setPages(10);
  }, [
    insuranceId,
    dateRange.startDate,
    dateRange.endDate,
    createdDateRange.startDate,
    createdDateRange.endDate,
    updatedDateRange.startDate,
    updatedDateRange.endDate,
    typeValue,
    serviceId
  ]);

  useEffect(() => {
    CategoryServices.serviceDropdown()
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setServiceList([...data.data]);
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      });
  }, []);

  const toggleOnePayment = () =>
    setParentDropdownOpenPayment(prevState => !prevState);

  const toggleInsurance = () =>
    setInsuranceDropdownOpen(prevState => !prevState);

  const toggleOne = () => setParentDropdownOpen(prevState => !prevState);

  const toggle = () => {
    setOpen(!open);
    setModal(!open);
  };

  const toggle1 = () => {
    setOpen1(!open1);
    setModal1(!open1);
  };

  const toggle2 = () => {
    setOpen2(!open2);
    setModal2(!open2);
  };

  const toggleType = () => setTypeDropdownOpen(prevState => !prevState);

  const toggleService = () => setServiceDropdownOpen(prevState => !prevState);

  const columns = [
    {
      Header: "DOS",
      accessor: "service_date",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 150,
      Cell: props =>
        props.original.calendly_appointment.appointment_type === "Manual"
          ? moment
              .tz(
                moment(
                  props.original.calendly_appointment.appointment_date +
                    " " +
                    props.original.calendly_appointment.appointment_time
                ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                moment.tz.guess()
              )
              .format("MM/DD/YYYY")
          : moment
              .tz(
                props.original.calendly_appointment.event_detail.start_time,
                moment.tz.guess()
              )
              .format("MM/DD/YYYY")
    },
    {
      Header: "Created Date",
      accessor: "created_at",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 150,
      Cell: props =>
        props.original.created_at
          ? moment.utc(props.original.created_at).format("MM/DD/YYYY")
          : ""
    },
    {
      Header: "Updated Date",
      accessor: "updated_at",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 150,
      Cell: props =>
        props.original.updated_at
          ? moment.utc(props.original.updated_at).format("MM/DD/YYYY")
          : ""
    },
    {
      Header: "RD",
      accessor: "admin.first_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      Cell: props => (
        <div className="react-action-class">
          {props.original.admin
            ? props.original.admin.first_name +
              " " +
              props.original.admin.last_name
            : ""}
        </div>
      ),
      width: 200
    },
    {
      Header: "DOB",
      accessor: "user.dob",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 150,
      Cell: props =>
        props.original?.user?.dob
          ? moment(props.original.user.dob).format("MM/DD/YYYY")
          : ""
    },
    {
      Header: "First Name",
      accessor: "user.first_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200
    },
    {
      Header: "Last Name",
      accessor: "user.last_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200
    },
    {
      Header: "Insurance",
      accessor: "`user_insurance->insurance`.`name`",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200,
      Cell: props => (
        <div className="react-action-class">
          {props.original.user_insurance &&
          props.original.user_insurance.insurance
            ? props.original.user_insurance.insurance.name
            : ""}
        </div>
      )
    },
    {
      Header: "Primary Diagnosis Code",
      accessor: "primary_diagnosis.name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 230
    },
    {
      Header: "Diagnosis Code",
      accessor: "diagnoses_code",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      width: 200
    },
    {
      Header: "CPT Code",
      accessor: "cpt_code.name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200
    },
    {
      Header: "Units",
      accessor: "billable_unit",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 150
    },
    {
      Header: "Notes",
      accessor: "notes_for_medical_biller",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 250
    },
    {
      Header: "Place of Service",
      accessor: "client_pos",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200
    },
    {
      Header: "Location city",
      accessor: "service_location_detail.name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 220
    },
    {
      Header: "Location State",
      accessor: "state.name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200
    },
    {
      Header: "Bill to",
      accessor: "payment_method",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200
    },
    {
      Header: "Site",
      accessor: "group.name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200
    },
    {
      Header: "Member ID",
      accessor: "`user_insurance.member_id`",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 150,
      Cell: props => (
        <div className="react-action-class">
          {props.original.user_insurance
            ? props.original.user_insurance.member_id
            : ""}
        </div>
      )
    },
    {
      Header: "Group",
      accessor: "`user_insurance.group_id`",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 150,
      Cell: props => (
        <div className="react-action-class">
          {props.original.user_insurance
            ? props.original.user_insurance.group_id
            : ""}
        </div>
      )
    },
    {
      Header: "Modifier",
      accessor: "method_of_services",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200,
      Cell: props => (
        <div className="react-action-class">
          {props.original.method_of_services
            ? props.original.method_of_services === "Virtual"
              ? "Virtual (GT - 95 Modifier)"
              : "In Person"
            : ""}
        </div>
      )
    },
    {
      Header: "Client ID",
      accessor: "user.id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 150
    },
    {
      Header: "Subscriber",
      accessor: "`user_insurance.is_primary_subscriber`",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200,
      Cell: props => (
        <div className="react-action-class">
          {props.original.user_insurance
            ? props.original.user_insurance.is_primary_subscriber
            : ""}
        </div>
      )
    },
    {
      Header: "Subscriber First Name",
      accessor: "`user_insurance.primary_subscriber_first_name`",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 300,
      Cell: props => (
        <div className="react-action-class">
          {props.original.user_insurance
            ? props.original.user_insurance.primary_subscriber_first_name
            : ""}
        </div>
      )
    },
    {
      Header: "Subscriber Last Name",
      accessor: "`user_insurance.primary_subscriber_last_name`",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 300,
      Cell: props => (
        <div className="react-action-class">
          {props.original.user_insurance
            ? props.original.user_insurance.primary_subscriber_last_name
            : ""}
        </div>
      )
    },
    {
      Header: "Subscriber DOB",
      accessor: "`user_insurance.primary_subscriber_dob`",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 250,
      Cell: props => (
        <div className="react-action-class">
          {props.original.user_insurance &&
          props.original.user_insurance?.primary_subscriber_dob &&
          props.original.user_insurance?.primary_subscriber_dob !== "0000-00-00"
            ? moment(
                props.original.user_insurance.primary_subscriber_dob
              ).format("MM/DD/YYYY")
            : ""}
        </div>
      )
    }
  ];

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  function exportList(
    field = "name",
    sortBy = "DESC",
    search = "",
    page = "1"
  ) {
    setIsExporting(true);
    const params = {
      sort_by: field,
      order: sortBy,
      search: search,
      page: page,
      insurance_id: insuranceId,
      start_date: dateRange.startDate
        ? moment(dateRange.startDate).format("YYYY-MM-DD")
        : null,
      end_date: dateRange.endDate
        ? moment(dateRange.endDate).format("YYYY-MM-DD")
        : null,
      created_start_date: createdDateRange.startDate
        ? moment(createdDateRange.startDate).format("YYYY-MM-DD")
        : null,
      created_end_date: createdDateRange.endDate
        ? moment(createdDateRange.endDate).format("YYYY-MM-DD")
        : null,
      updated_start_date: updatedDateRange.startDate
        ? moment(updatedDateRange.startDate).format("YYYY-MM-DD")
        : null,
      updated_end_date: updatedDateRange.endDate
        ? moment(updatedDateRange.endDate).format("YYYY-MM-DD")
        : null,
      rd_type: typeValue,
      service_id: props?.admin_role_id < 3 ? serviceId : props.service_id,
      practice_id:
        props?.admin_role_id === 6 && props?.practice
          ? props?.practice
          : undefined
    };
    ReportServices.insuranceBillingTransactionListCsv(params)
      .then(res => {
        setIsExporting(false);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error("Something went wrong!");
        setIsExporting(false);
      });
  }

  const formAction = (action, data = null) => {
    // Here you can view the data and make forward action for edit data
    if (action === "export") {
      exportList(field, sortBy, searchText, activePage + 1, paymentMethodValue);
    }
  };

  function getList(
    field = "name",
    sortBy = "DESC",
    search = "",
    page = "1",
    bill_to
  ) {
    const params = {
      sort_by: field,
      order: sortBy,
      search: search,
      page: page,
      insurance_id: insuranceId,
      start_date: dateRange.startDate
        ? moment(dateRange.startDate).format("YYYY-MM-DD")
        : null,
      end_date: dateRange.endDate
        ? moment(dateRange.endDate).format("YYYY-MM-DD")
        : null,
      created_start_date: createdDateRange.startDate
        ? moment(createdDateRange.startDate).format("YYYY-MM-DD")
        : null,
      created_end_date: createdDateRange.endDate
        ? moment(createdDateRange.endDate).format("YYYY-MM-DD")
        : null,
      updated_start_date: updatedDateRange.startDate
        ? moment(updatedDateRange.startDate).format("YYYY-MM-DD")
        : null,
      updated_end_date: updatedDateRange.endDate
        ? moment(updatedDateRange.endDate).format("YYYY-MM-DD")
        : null,
      rd_type: typeValue,
      service_id: props?.admin_role_id < 3 ? serviceId : props.service_id,
      practice_id:
        props?.admin_role_id === 6 && props?.practice
          ? props?.practice
          : undefined
    };
    setLoader(true);
    ReportServices.insuranceBillList(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count.length / TABLE.LIMIT));
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
        setLoader(false);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.message);
        setLoader(false);
      });
  }

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Insurance Bill Report
            </div>
            <div>
              <Button
                className="c-btn c-primary ma-5"
                onClick={() => formAction("export")}
                disabled={isExporting || !tblData?.length}
              >
                <i className="fas fa-download mr-10" />
                {!isExporting ? "Export" : "Exporting"}
              </Button>
            </div>
          </div>
          <div className="roe-card-header module-header">
            <div className="d-flex">
              <div className="mr-10">
                <span className="fs-16">DOS</span>
                <br></br>
                <Button
                  className="btn btn-secondary btn-md"
                  onClick={() => toggle()}
                >
                  {dateRange.startDate
                    ? `${moment(dateRange.startDate).format(
                        "MM/DD/YYYY"
                      )} - ${moment(dateRange.endDate).format("MM/DD/YYYY")}`
                    : "Select Date"}
                </Button>
                {dateRange && dateRange.startDate && (
                  <Button
                    className="btn btn-secondary btn-md ml-2"
                    onClick={() => {
                      setActivePage(0);
                      setDateRange({});
                    }}
                  >
                    Reset date
                  </Button>
                )}
              </div>
            </div>
            <div className="d-flex">
              <div className="mr-10">
                <span className="fs-16">Created Date</span>
                <br></br>
                <Button
                  className="btn btn-secondary btn-md"
                  onClick={() => toggle1()}
                >
                  {createdDateRange.startDate
                    ? `${moment(createdDateRange.startDate).format(
                        "MM/DD/YYYY"
                      )} - ${moment(createdDateRange.endDate).format(
                        "MM/DD/YYYY"
                      )}`
                    : "Select Date"}
                </Button>
                {createdDateRange && createdDateRange.startDate && (
                  <Button
                    className="btn btn-secondary btn-md ml-2"
                    onClick={() => setCreatedDateRange({})}
                  >
                    Reset date
                  </Button>
                )}
              </div>
            </div>
            <div className="d-flex">
              <div className="mr-10">
                <span className="fs-16">Updated Date</span>
                <br></br>
                <Button
                  className="btn btn-secondary btn-md"
                  onClick={() => toggle2()}
                >
                  {updatedDateRange.startDate
                    ? `${moment(updatedDateRange.startDate).format(
                        "MM/DD/YYYY"
                      )} - ${moment(updatedDateRange.endDate).format(
                        "MM/DD/YYYY"
                      )}`
                    : "Select Date"}
                </Button>
                {updatedDateRange && updatedDateRange.startDate && (
                  <Button
                    className="btn btn-secondary btn-md ml-2"
                    onClick={() => setUpdatedDateRange({})}
                  >
                    Reset date
                  </Button>
                )}
              </div>
            </div>
            <div className="fs-14  mr-10">
              <span className="fs-16">Insurance</span>
              <Dropdown isOpen={insuranceDropdownOpen} toggle={toggleInsurance}>
                <DropdownToggle caret size="md">
                  {insuranceId ? insuranceName : "All"}
                </DropdownToggle>
                <DropdownMenu
                  modifiers={{
                    setMaxHeight: {
                      enabled: true,
                      order: 890,
                      fn: data => {
                        return {
                          ...data,
                          styles: {
                            ...data.styles,
                            overflow: "auto",
                            maxHeight: "300px"
                          }
                        };
                      }
                    }
                  }}
                >
                  <DropdownItem
                    key="-1"
                    onClick={() => {
                      setActivePage(0);
                      setInsuranceId("");
                      setInsuranceName("All");
                    }}
                  >
                    All
                  </DropdownItem>
                  {insuranceList.length > 0 &&
                    insuranceList.map((result, i) => {
                      return (
                        <DropdownItem
                          key={i}
                          onClick={() => {
                            setActivePage(0);
                            setInsuranceId(result.id);
                            setInsuranceName(`${result.name}`);
                          }}
                        >
                          {result.name}
                        </DropdownItem>
                      );
                    })}
                </DropdownMenu>
              </Dropdown>
            </div>
            {props.admin_role_id < 3 && (
              <div className="fs-14  mr-10">
                <span className="fs-16">Service</span>
                <Dropdown isOpen={serviceDropdownOpen} toggle={toggleService}>
                  <DropdownToggle caret size="md">
                    {serviceName ? serviceName : "Select Service"}
                  </DropdownToggle>
                  <DropdownMenu
                    modifiers={{
                      setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: data => {
                          return {
                            ...data,
                            styles: {
                              ...data.styles,
                              overflow: "auto",
                              maxHeight: "300px"
                            }
                          };
                        }
                      }
                    }}
                  >
                    <DropdownItem
                      key="-1"
                      onClick={() => {
                        setActivePage(0);
                        setServiceId("");
                        setServiceName("All");
                        // setRDId("");
                        // setRDName("");
                        setTypeName(null);
                        setTypeValue(null);
                      }}
                    >
                      All
                    </DropdownItem>
                    {serviceList.length > 0 &&
                      serviceList.map((result, i) => {
                        return (
                          <DropdownItem
                            key={i}
                            onClick={() => {
                              setActivePage(0);
                              setServiceId(result.id);
                              setServiceName(`${result.name}`);
                              setTypeName(null);
                              setTypeValue(null);
                            }}
                          >
                            {result.name}
                          </DropdownItem>
                        );
                      })}
                  </DropdownMenu>
                </Dropdown>
              </div>
            )}
            {props?.admin_role_id !== 6 && props?.admin_role_id !== 3 && (
              <div className="fs-14 mr-10">
                <span className="fs-16">Type</span>
                <Dropdown isOpen={typeDropdownOpen} toggle={toggleType}>
                  <DropdownToggle caret size="md">
                    {typeName ? typeName : "Type"}
                  </DropdownToggle>
                  <DropdownMenu
                    modifiers={{
                      setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: data => {
                          return {
                            ...data,
                            styles: {
                              ...data.styles,
                              overflow: "auto",
                              maxHeight: "300px"
                            }
                          };
                        }
                      }
                    }}
                  >
                    <DropdownItem
                      key="-1"
                      onClick={() => {
                        setTypeName("All");
                        setTypeValue("");
                        setActivePage(0);
                      }}
                    >
                      All
                    </DropdownItem>
                    {PROVIDER_TYPES.map((result, i) => {
                      return (
                        <DropdownItem
                          key={i}
                          onClick={() => {
                            setTypeName(`${result.name}`);
                            setTypeValue(`${result.value}`);
                            setActivePage(0);
                          }}
                        >
                          {result.name}
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
              </div>
            )}
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              LoadingComponent={Loader}
              onFetchData={onChangePageGetData}
              onPageChange={pageIndex => {
                setActivePage(pageIndex);
                getList(
                  field,
                  sortBy,
                  searchText,
                  pageIndex + 1,
                  paymentMethodValue
                );
              }}
              onSortedChange={sortProperties => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getList(
                  sortProperties[0].id,
                  sort,
                  searchText,
                  1,
                  paymentMethodValue
                );
                // alert("sort handler", sortProperties);
                // const orderByDirection = sortProperties[0].desc ? 1 : 0;

                // updateMapStateInfoOrderByColumn(sortProperties[0].id, orderByDirection);
              }}
            />
          </div>
          <CommonModel
            modal={modal}
            toggle={toggle}
            size="lg"
            children={
              <DateRangePicker
                open={open}
                toggle={() => toggle()}
                onChange={range => {
                  setActivePage(0);
                  setDateRange(range);
                  // setModal(!modal)
                  toggle();
                }}
                closeOnClickOutside={true}
              />
            }
            // header={editedData ? "" : "Add Registration Code"}
          />
          <CommonModel
            modal={modal1}
            toggle={toggle1}
            size="lg"
            children={
              <DateRangePicker
                open={open1}
                toggle={() => toggle1()}
                onChange={range => {
                  setCreatedDateRange(range);
                  // setModal(!modal)
                  toggle1();
                }}
                closeOnClickOutside={true}
              />
            }
          />
          <CommonModel
            modal={modal2}
            toggle={toggle2}
            size="lg"
            children={
              <DateRangePicker
                open={open2}
                toggle={() => toggle2()}
                onChange={range => {
                  setUpdatedDateRange(range);
                  // setModal(!modal)
                  toggle2();
                }}
                closeOnClickOutside={true}
              />
            }
          />
        </div>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};

export default withRouter(InsuranceBillTable);
