import React, { useRef, useState, useEffect } from "react";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import RdServices from "../../api/RdServices";
import {
  ACTIVE_ACCEPTING_CLIENT,
  ACTIVE_NOT_ACCEPTING_CLIENT,
  TEMPORARY_INACTIVE,
  PERMANENTALY_INACTIVE,
  PROVIDER_TYPE,
  MODULE_NAMES,
  PERMISSION_TYPE
} from "../../helper/constant";
import Button from "../button/Button";
import Toaster from "../common/Toaster";
import CommonModel from "components/common/CommonModel";
import RdEdit from "views/Rd/RdEdit";
import Loader from "components/common/Loader";
import { hasAccess } from "util/sidebar";

const RdSettings = props => {
  const { permissions } = props;
  const [editedData, setEditedData] = useState(null);
  const hasParam = props.match.params.id || props?.id;
  const toaster = useRef();
  const [refreshData, setRefreshData] = useState(0);
  const [modal, setModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const step = props.location?.state?.step;

  useEffect(() => {
    if (hasParam) {
      const params = {
        id: hasParam
      };
      setLoader(true);
      RdServices.view(params)
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          setEditedData(data.data);
          setLoader(false);
        })
        .catch(err => {
          setLoader(false);
        });
    }
  }, [refreshData]);

  function toggle() {
    setModal(false);
  }

  useEffect(() => {
    if (step === "2") {
      setModal(true);
    }
  }, []);

  return (
    <div style={{ position: "relative" }}>
      {loader ? (
        <Loader loading={loader} />
      ) : (
        <div>
          {hasAccess(
            MODULE_NAMES.PROVIDER_MANAGEMENT_SETTINGS,
            PERMISSION_TYPE.EDIT,
            permissions
          ) && (
            <div style={{ position: "absolute", top: 10, right: 40 }}>
              <Button
                className="c-btn c-success mr-10"
                onClick={() => {
                  setModal(true);
                }}
              >
                <div className="fs-14 medium-text-copy">
                  <i className="fas fa-edit" />
                </div>
              </Button>
            </div>
          )}
          <table style={{ width: "100%", lineHeight: "35px" }}>
            <tbody>
              <tr>
                <td
                  style={{
                    fontWeight: "bolder",
                    textAlign: "left",
                    width: "25%"
                  }}
                >
                  Service
                </td>
                <td>
                  {editedData &&
                    editedData.rd_detail &&
                    editedData.rd_detail.service.name}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontWeight: "bolder",
                    textAlign: "left",
                    width: "25%"
                  }}
                >
                  Type
                </td>
                <td>
                  {editedData && editedData?.rd_detail?.type
                    ? PROVIDER_TYPE[editedData?.rd_detail?.type]
                    : ""}
                </td>
              </tr>
              {!!editedData?.practice_management && (
                <tr>
                  <td
                    style={{
                      fontWeight: "bolder",
                      textAlign: "left",
                      width: "25%"
                    }}
                  >
                    Practice
                  </td>
                  <td>{editedData?.practice_management?.practice_name}</td>
                </tr>
              )}
              <tr>
                <td
                  style={{
                    fontWeight: "bolder",
                    textAlign: "left",
                    width: "25%"
                  }}
                >
                  Commission
                </td>
                <td>
                  {editedData &&
                    editedData?.rd_detail &&
                    editedData?.rd_detail?.commission}
                  <span>%</span>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontWeight: "bolder",
                    textAlign: "left",
                    width: "25%"
                  }}
                >
                  Tier
                </td>
                <td>
                  {editedData && editedData?.rd_detail?.tier?.name
                    ? editedData?.rd_detail?.tier?.name
                    : ""}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontWeight: "bolder",
                    textAlign: "left",
                    width: "25%"
                  }}
                >
                  Client Max
                </td>
                <td>
                  {editedData &&
                    editedData.rd_detail &&
                    editedData.rd_detail.max_client}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontWeight: "bolder",
                    textAlign: "left",
                    width: "25%"
                  }}
                >
                  Status
                </td>
                <td>
                  {editedData &&
                    (editedData.status === ACTIVE_ACCEPTING_CLIENT
                      ? "Active, accepting new clients"
                      : editedData.status === ACTIVE_NOT_ACCEPTING_CLIENT
                      ? "Active, not accepting new clients"
                      : editedData.status === TEMPORARY_INACTIVE
                      ? "Temporarily inactive"
                      : editedData.status === PERMANENTALY_INACTIVE
                      ? "Permanently inactive"
                      : "")}
                </td>
              </tr>

              {/* <tr>
                <td
                  style={{
                    fontWeight: "bolder",
                    textAlign: "left",
                    width: "25%"
                  }}
                >
                  Calendly Link
                </td>
                <td>
                  {editedData &&
                    editedData.rd_detail &&
                    editedData.rd_detail.calendly_link}
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    fontWeight: "bolder",
                    textAlign: "left",
                    width: "25%"
                  }}
                >
                  Calendly Link - Initial Visit
                </td>
                <td>
                  {editedData &&
                    editedData.rd_detail &&
                    editedData.rd_detail.initial_calendly_event_url}
                </td>
              </tr> */}
              <tr>
                <td
                  style={{
                    fontWeight: "bolder",
                    textAlign: "left",
                    width: "25%"
                  }}
                >
                  Service Location
                </td>
                <td>
                  <div className="flex">
                    {editedData?.rd_service_locations?.length > 0 &&
                      editedData.rd_service_locations.map((el, index) => (
                        <div>
                          {el?.service_location?.name}{" "}
                          {index !==
                          editedData?.rd_service_locations?.length - 1
                            ? ","
                            : ""}{" "}
                        </div>
                      ))}
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontWeight: "bolder",
                    textAlign: "left",
                    width: "25%"
                  }}
                >
                  AdvancedMD Provider ID
                </td>
                <td>
                  {editedData &&
                    editedData.rd_detail &&
                    editedData.rd_detail.advancemd_provider_id}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontWeight: "bolder",
                    textAlign: "left",
                    width: "25%"
                  }}
                >
                  Provider NPI
                </td>
                <td>
                  {editedData &&
                    editedData.rd_detail &&
                    editedData.rd_detail.provider_npi}
                </td>
              </tr>
              {editedData?.rd_detail?.service.id === 1 && (
                <tr>
                  <td
                    style={{
                      fontWeight: "bolder",
                      textAlign: "left",
                      width: "25%"
                    }}
                  >
                    Messaging
                  </td>
                  <td>
                    {editedData &&
                    editedData.rd_detail &&
                    editedData.rd_detail.is_messaging_active
                      ? "Active"
                      : "Inactive"}
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <CommonModel
            modal={modal}
            toggle={toggle}
            size="lg"
            children={
              <RdEdit
                toggle={toggle}
                pageView="SETTING"
                pageRefresh={() => {
                  setRefreshData(prev => prev + 1);
                }}
                {...props}
              />
            }
            header={"Edit Provider Settings"}
          />

          <Toaster ref={toaster} />
        </div>
      )}
    </div>
  );
};

export default RdSettings;
