import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    first_name: Yup.string()
      .trim()
      .required("This field is required")
      .max(100, "First Name must be less than 100 characters"),
    last_name: Yup.string()
      .trim()
      .required("This field is required")
      .max(100, "Last Name must be less than 100 characters"),
    email: Yup.string()
      .trim()
      .required("This field is required")
      .email("Please enter valid email"),
    role: Yup.string().required("This field is required"),
    practice: Yup.string().when("role", {
      is: "6",
      then: Yup.string()
        .trim()
        .required("This field is required")
    })
  }),
  mapPropsToValues: props => ({
    action: props.action,
    id: props.editedData
      ? props && props.editedData && props.editedData.id
      : "",
    first_name: props.editedData
      ? props && props.editedData && props.editedData.first_name
      : "",
    last_name: props.editedData
      ? props && props.editedData && props.editedData.last_name
      : "",
    email: props.editedData
      ? props && props.editedData && props.editedData.email
      : "",
    role: props.editedData
      ? typeof props.editedData.admin_role == "object"
        ? props.editedData.admin_role.id
        : props.editedData.admin_role
      : "",
    image: props.editedData
      ? props && props.editedData && props.editedData.profile_image
      : "",
    practice: props?.editedData?.practice_management?.id
      ? props?.editedData?.practice_management?.id
      : ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
