import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    notes: Yup.string()
      .required("This field is required")
      .nullable(),
    service: Yup.string()
      .trim()
      .required("This field is required")
      .nullable()
  }),
  mapPropsToValues: props => ({
    notes: props?.editedData ? props?.editedData?.notes : "",
    service: props?.editedData ? props?.editedData?.service?.id : ""
  }),

  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
