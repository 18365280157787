import React, { useState, useRef } from "react";
import { ForgotIcon, ROLE_SERVICE_ID } from "helper/constant";
import AdminServices from "../../../api/AdminServices";
import Toaster from "../../../components/common/Toaster";
import { withRouter } from "react-router-dom";
import Button from "../../../components/button/Button";
import EncryptDecrypt from "../../../api/EncryptDecrypt";
import AuthActions from "redux/auth/actions";
import { connect } from "react-redux";
import { compose } from "redux";
import enhancer from "./enhancer/MFAFormEnhancer";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { useEffect } from "react";

const { login } = AuthActions;

const firebaseConfig = {
  apiKey: "AIzaSyAXJdcy--YN5iOuJ0uZ7lOt4wWz-aPSuFA",
  authDomain: "charge-3cd9b.firebaseapp.com",
  projectId: "charge-3cd9b",
  storageBucket: "charge-3cd9b.appspot.com",
  messagingSenderId: "927808025019",
  appId: "1:927808025019:web:8cc540e3518f04b90fd49e",
  measurementId: "G-TL3B75N1K6"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging();

const getServiceForRoles = data => {
  const { admin_role_id, rd_detail, practice_management } = data;
  let service = null;
  if (admin_role_id === 3) {
    service = rd_detail?.service_id;
  } else if (admin_role_id === 6) {
    service = practice_management?.service_id;
  } else if (admin_role_id === 4 || admin_role_id === 5) {
    service = ROLE_SERVICE_ID[admin_role_id];
  }
  return service;
};

const MFA = props => {
  const loginDetails = props.location.state;
  const loginContainer = {
    //backgroundImage: `url(${loginBack})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0,
    backgroundColor: "#AECE38"
  };

  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount
  } = props;

  const toaster = useRef();
  const [loading, setloading] = useState(false);
  const [deviceToken, setDeviceToken] = useState("");
  useEffect(() => {
    getToken(messaging, {
      vapidKey:
        "BH2TjRHvVKDbj0p2oCVV-QulifNJvJg_lPrvLe1MjbHshv2fxPkyr786Pjr0qJGH8mV6NIZb53sGxTOYTnNZ_ng"
    })
      .then(currentToken => {
        if (currentToken) {
          setDeviceToken(currentToken);
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
        }
      })
      .catch(err => {
        console.log("An error occurred while retrieving token. ", err);
      });
  }, []);

  const setupMFA = () => {
    const params = {
      admin_id: loginDetails.id,
      email: loginDetails.email
    };
    AdminServices.setupMfa(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current) toaster.current.success(data.message);
        } else {
          if (toaster.current) toaster.current.error(data.message);
          setloading(false);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
        setloading(false);
      });
  };

  function submitHandler(e) {
    e.preventDefault();
    let { values, handleSubmit } = props;
    if (values.code !== "") {
      const params = {
        code: values.code,
        admin_id: loginDetails.id,
        email: loginDetails.email,
        device_token: deviceToken
      };
      setloading(true);
      AdminServices.mfa(params)
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          if (data.status) {
            let loginData = {
              token: data.data.token,
              first_name: data.data.first_name,
              last_name: data.data.last_name,
              email: data.data.email,
              id: data.data.id,
              profile_image: data.data.profile_image,
              admin_role_id: data.data.admin_role_id,
              admin_role: data.data.admin_role.role,
              isLogin: true,
              service_id: getServiceForRoles(data?.data) || null,
              step: data.data.rd_detail ? data.data.rd_detail.step : "",
              stream_io_token:
                data.data.stream_io_token && data.data.stream_io_token,
              calendly_link: data.data.rd_detail
                ? data.data.rd_detail.calendly_link
                : null,
              adminType: data.data.rd_detail ? data.data.rd_detail.type : null,
              tier_id: data.data.rd_detail ? data.data.rd_detail.tier_id : null,
              practice: data?.data?.practice_management?.id
                ? data?.data?.practice_management?.id
                : null,
              is_messaging_active: data?.data?.rd_detail?.is_messaging_active
            };
            props.login(loginData);
            setloading(false);
            data.data.rd_detail && !data.data.rd_detail?.step?.includes(6)
              ? props.history.push("/complete-rd-profile")
              : props.history.push("/dashboard");
          } else {
            if (toaster.current) toaster.current.error(data.message);
            setloading(false);
          }
        })
        .catch(err => {
          if (toaster.current) toaster.current.error(err.message);
          setloading(false);
        });
    }
    handleSubmit();
  }

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <div className="container-fluid" style={loginContainer}>
      <div className="form-container">
        <div className="login-icon">
          <img src={ForgotIcon} alt="icon" height="100px" />
        </div>
        <div className="login-title">Enter Code</div>
        <div className="text-center form-info-text plr-24 mt-16">
          Enter the 6-digit code from Google Authenticator to log in.
        </div>
        <form className="pa-24" onSubmit={e => submitHandler(e)}>
          <div className="form-group">
            <input
              type="text"
              onBlur={handleBlur}
              onChange={handleChange}
              className="form-control react-form-input"
              id="code"
              aria-describedby="emailHelp"
              value={values.code}
              placeholder="Enter code"
            />
            <Error field="code" />
          </div>
          <Button
            type="submit"
            loading={loading}
            className="btn form-button"
            dataStyle="expand-right"
            style={{ position: "relative" }}
          >
            Log In
          </Button>
          <div className="text-center link-label" onClick={setupMFA}>
            Don't have Google Authenicator set up yet? Set up now.
          </div>
        </form>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};

export default compose(withRouter, enhancer, connect(null, { login }))(MFA);
