import axios from "axios";
import { baseUrl } from "../helper/constant";
import Header from "./Header";
import EncryptDecrypt from "./EncryptDecrypt";

export default class FormServices {
  static list(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/form/list`, EncryptDecrypt.encrypt(params));
  }

  static add(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/form/add`, params);
  }

  static edit(params) {
    Header.setHeaders();
    return axios.put(`${baseUrl}/form/edit`, params);
  }

  static view(params) {
    Header.setHeaders();
    return axios.get(`${baseUrl}/form/view`, EncryptDecrypt.encrypt(params, 1));
  }

  static delete(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/form/delete?form_id=${params.form_id}`);
  }
}
