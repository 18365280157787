import styled from "styled-components";

const RDWrapper = styled.div`
  .checkbox {
    padding-left: 20px;
    height: 16px;
    width: 16px;
  }
`;
export default RDWrapper;
