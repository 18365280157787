import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    goal_type: Yup.string().required("This field is required"),
    goal_category: Yup.string().required("This field is required"),
    goal_sentence: Yup.string().required("This field is required")
  }),
  mapPropsToValues: props => ({
    goal_type: props.valuess ? props.valuess.goal_type : "",
    goal_category_id: props.valuess ? props.valuess.goal_category.id : "",
    goal_sentence: "I want "
  }),

  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true,

  isInitialValid: props => (props.editedData ? true : false)
});

export default formikEnhancer;
