import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import Loader from "../common/Loader";
import Toaster from "../common/Toaster";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import CommonModel from "components/common/CommonModel";
import {
  MODULE_NAMES,
  PERMISSION_TYPE,
  PLACEHOLDER,
  TABLE
} from "helper/constant";
import RewardsManagementServices from "api/RewardsMangementServices";
import DeleteModal from "components/common/DeleteModal";
import AddProductModal from "./AddProductModal";
import ViewProductModal from "./ViewProductModal";
import EditProductModal from "./EditProductModal";
import { hasAccess } from "util/sidebar";

const ProductManagementTable = props => {
  const { permissions } = props;
  const toaster = useRef();
  const [tblData, settblData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [modal, setModal] = useState(false);
  const [productModal, setProductModal] = useState(false);
  const [viewProductModal, setViewProductModal] = useState(false);
  const [editProductModal, setEditProductModal] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [id, setId] = useState("");

  useEffect(() => {
    getList(field, sortBy, searchText, activePage + 1);
    setPages(10);
  }, [props]);

  function productToggle() {
    setProductModal(!productModal);
  }
  function viewProductToggle() {
    setViewProductModal(!viewProductModal);
  }
  function editProductToggle() {
    setEditProductModal(!editProductModal);
  }

  const deleteClick = data => {
    setId(data.id);
    setModal(true);
  };

  function deleteHandler() {
    setLoader(true);
    const params = {
      product_id: id
    };

    // Add form delete API code here
    RewardsManagementServices.delete(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current) toaster.current.success(data.message);
          setLoader(false);
          setModal(false);
          setTimeout(() => {
            getList(field, sortBy, searchText, activePage + 1, 1);
          }, 1000);
        } else {
          if (toaster.current) toaster.current.error(data.message);
          setLoader(false);
          setModal(false);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.message);
        setLoader(false);
        setModal(false);
      });
  }

  function toggle() {
    setModal(!modal);
  }

  const activeInactiveStatusHandler = oData => {
    const params = {
      product_id: oData.id,
      is_active: !oData.is_active
    };
    setLoader(true);
    RewardsManagementServices.status(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current)
            toaster.current.success(
              oData.is_active === true
                ? "Product has been deactivated successfully."
                : "Product has been activated successfully."
            );
          getList(field, sortBy, searchText, activePage + 1);
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
          setLoader(false);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
        setLoader(false);
      });
  };

  const columns = [
    {
      Header: "ID",
      accessor: "id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 70,
      sortable: true
    },
    {
      Header: "Name",
      accessor: "product_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 250,
      sortable: true
    },
    {
      Header: "Image",
      accessor: "image",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 100,
      sortable: true,
      Cell: props => (
        <div>
          <img
            src={
              props.original?.reward_product_variations[0]?.image || PLACEHOLDER
            }
            alt="error"
            style={{ height: "35px", width: "35px", borderRadius: "5%" }}
          />
        </div>
      )
    },
    {
      Header: "SKU Number",
      accessor: "sku_no",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 140,
      sortable: true
    },
    {
      Header: "Points",
      accessor: "point",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 100,
      sortable: true
    },
    {
      Header: "Price",
      accessor: "price",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 100,
      sortable: true
    },
    {
      Header: "Rewards Tier",
      accessor: "rewards_tier",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 120,
      sortable: true,
      Cell: props => props?.original?.reward_tier?.name
    },
    {
      Header: "iRedeem Status",
      accessor: "iredeem_product_exist",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 140,
      sortable: true,
      Cell: props =>
        props?.original?.iredeem_product_exist ? (
          <div>Active</div>
        ) : (
          <div>Inactive</div>
        )
    },
    {
      Header: "Status",
      accessor: "is_active",
      className: "",
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      filterable: false,
      width: 200,
      Cell: props => (
        <div className="react-action-class">
          {props.original.is_active ? (
            <div id={`active-${props.original?.id}`}>
              <Button
                style={{ minWidth: "125px" }}
                className="c-btn c-info mr-10"
                onClick={() => activeInactiveStatusHandler(props.original)}
                disabled={
                  !hasAccess(
                    MODULE_NAMES.REWARDS,
                    PERMISSION_TYPE.STATUS,
                    permissions
                  )
                }
              >
                <div className="fs-14 medium-text">
                  <i className="fas fa-toggle-off mr-6" /> Active
                </div>
              </Button>
            </div>
          ) : (
            <div id={`deactive-${props.original.id}`}>
              <Button
                style={{ minWidth: "125px" }}
                className="c-btn c-warning mr-10"
                onClick={() => activeInactiveStatusHandler(props.original)}
                disabled={
                  !hasAccess(
                    MODULE_NAMES.REWARDS,
                    PERMISSION_TYPE.STATUS,
                    permissions
                  )
                }
              >
                <div className="fs-14 medium-text">
                  <i className="fas fa-toggle-on mr-6" /> Inactive
                </div>
              </Button>
            </div>
          )}
        </div>
      ) // Custom cell components!
    },
    {
      Header: "Action",
      accessor: "action",
      className: "",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 300,
      Cell: props => (
        <div className="react-action-class">
          <Button
            className="c-btn c-info mr-10"
            onClick={() => {
              setViewProductModal(!viewProductModal);
              setEditedData({ ...props.original });
            }}
          >
            <div className="fs-14 medium-text" title="View">
              <i className="fas fa-eye" />
            </div>
          </Button>
          {hasAccess(
            MODULE_NAMES.REWARDS,
            PERMISSION_TYPE.EDIT,
            permissions
          ) ? (
            <Button
              className="c-btn c-success mr-10"
              onClick={() => {
                setEditProductModal(!editProductModal);
                setEditedData({ ...props.original });
              }}
            >
              <div className="fs-14 medium-text" title="Edit">
                <i className="fas fa-edit" />
              </div>
            </Button>
          ) : null}
          {hasAccess(
            MODULE_NAMES.REWARDS,
            PERMISSION_TYPE.DELETE,
            permissions
          ) ? (
            <Button
              className="c-btn c-danger"
              onClick={() => deleteClick(props.original)}
            >
              <div className="fs-14 medium-text" title="Delete">
                <i className="fas fa-trash" />
              </div>
            </Button>
          ) : null}
        </div>
      ) // Custom cell components!
    }
  ];

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  const handleSubmit = () => {
    getList(field, sortBy, searchText, activePage + 1);
    setPages(10);
  };

  function getList(
    field = "created_at",
    sort_by = "DESC",
    search = "",
    page = "1"
  ) {
    const params = {
      order: sort_by,
      sort_by: field,
      search: search,
      page: page
    };
    setLoader(true);
    RewardsManagementServices.list(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count / TABLE.LIMIT));
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });
  }
  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Product List
            </div>
            <div className="mr-10">
              <input
                value={searchText}
                onChange={e => {
                  setSearchText(e.target.value);
                  const search = e.target.value;
                  setLoader(true);
                  setActivePage(0);
                  setTimeout(() => {
                    getList(field, sortBy, search, 1);
                  }, 500);
                }}
                type="text"
                placeholder="Search..."
                className="fs-14 medium-text plr-10 form-control react-form-input"
              />
            </div>
            <div>
              {hasAccess(
                MODULE_NAMES.REWARDS,
                PERMISSION_TYPE.ADD,
                permissions
              ) ? (
                <Button
                  className="c-btn c-primary ma-5"
                  onClick={() => {
                    setProductModal(!productModal);
                    setEditedData({ type: "add" });
                  }}
                >
                  <i className="fas fa-plus mr-10" />
                  Add Product
                </Button>
              ) : null}
            </div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              LoadingComponent={Loader}
              onFetchData={onChangePageGetData}
              onPageChange={pageIndex => {
                setActivePage(pageIndex);
                getList(field, sortBy, searchText, pageIndex + 1);
              }}
              onSortedChange={sortProperties => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getList(sortProperties[0].id, sort, searchText, 1);
              }}
            />
          </div>
        </div>
      </div>
      <CommonModel
        modal={modal}
        toggle={toggle}
        children={
          <DeleteModal
            text={"Are you sure you want to delete this Product?"}
            deleteHandler={deleteHandler}
            toggle={toggle}
          />
        }
      />

      <CommonModel
        modal={productModal}
        toggle={productToggle}
        onSubmit={handleSubmit}
        size="lg"
        children={
          <AddProductModal
            onSubmit={handleSubmit}
            toggle={productToggle}
            pageRefresh={() => {
              getList(field, sortBy, searchText, activePage + 1);
            }}
            {...props}
          />
        }
        header={"Add Product"}
      />
      <CommonModel
        modal={editProductModal}
        toggle={editProductToggle}
        onSubmit={handleSubmit}
        size="lg"
        children={
          <EditProductModal
            onSubmit={handleSubmit}
            editedData={editedData}
            toggle={editProductToggle}
            pageRefresh={() => {
              getList(field, sortBy, searchText, activePage + 1);
            }}
            {...props}
          />
        }
        header={"Edit Product"}
      />
      <CommonModel
        size="md"
        modal={viewProductModal}
        toggle={viewProductToggle}
        children={
          <ViewProductModal
            editedData={editedData}
            toggle={viewProductToggle}
          />
        }
        header={"Product Details"}
      />
      <Toaster ref={toaster} />
    </div>
  );
};

export default withRouter(ProductManagementTable);
