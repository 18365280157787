import React, { Fragment, useEffect, useRef, useState } from "react";
import { compose } from "redux";
import enhancer from "./validator";
import Button from "../button/Button";
import { ImageResolution } from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import CategoryServices from "../../api/CategoryServices";
import Toaster from "../../components/common/Toaster";
import { PLACEHOLDER } from "helper/constant";
import { Input } from "reactstrap";

const CategoryForm = props => {
  const {
    handleChange,
    handleBlur,
    errors,
    setFieldTouched,
    setFieldValue,
    touched,
    submitCount,
    onSubmit,
    values,
    action
  } = props;

  const toaster = useRef(null);
  const [serviceList, setServiceList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [imageObj, setImageObj] = useState(null);
  const [uploadImage, setUploadImage] = useState(null);

  useEffect(() => {
    CategoryServices.serviceDropdown()
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setServiceList([...data.data]);
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      });
  }, []);

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const handleImage = (e, setValue) => {
    if (e.target.files[0]) {
      let fileObj = e.target.files[0];

      if (fileObj.size > 10 * 1024 * 1024) {
        if (toaster.current)
          toaster.current.error("Please upload a file smaller than 10 MB");
        return false;
      }

      if (!fileObj.name.match(/\.(jpg|jpeg|png|gif|heif)$/)) {
        if (toaster.current)
          toaster.current.error(
            "Only .jpg, .jpeg, .gif, .png, .heif are allowed"
          );
        return false;
      }

      if (fileObj) {
        const img = new Image();

        img.src = window.URL.createObjectURL(fileObj);

        img.onload = function() {
          const width = img.naturalWidth,
            height = img.naturalHeight;

          window.URL.revokeObjectURL(img.src);
          setValue("width", width);
          setValue("height", height);
          setFieldTouched("image", true, true);
          if (
            width >= ImageResolution.WIDTH &&
            height >= ImageResolution.HEIGHT
          ) {
            setImageObj(fileObj);
            setValue("image", URL.createObjectURL(fileObj));
            setUploadImage(URL.createObjectURL(fileObj));
          }
        };
      } else {
        //No file was input or browser doesn't support client side reading
        // form.submit();
      }
    } else {
      setUploadImage();
    }
  };

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;

    if (isValid) {
      // onSubmit(values, action);
      setIsLoading(true);
      if (action === "add") {
        const params = {
          name: values.name,
          description: values.description,
          service_id: values.service
        };

        const formdata = new FormData();
        const encryptData = EncryptDecrypt.encrypt(params);
        formdata.append("data", encryptData?.data);
        if (imageObj) formdata.append("data1", imageObj);

        CategoryServices.add(formdata)
          .then(res => {
            let data = EncryptDecrypt.decrypt(res.data);
            if (data.status) {
              if (toaster.current) toaster.current.success(data.message);
              onSubmit(values, action);
            } else {
              if (toaster.current) toaster.current.error(data.message);
            }
            setIsLoading(false);
          })
          .catch(err => {
            if (toaster.current) toaster.current.error(err.response);
            setIsLoading(false);
          });
      } else if (action === "edit") {
        const params = {
          id: values.id,
          name: values.name,
          description: values.description,
          service_id: values.service
        };

        const formdata = new FormData();
        const encryptData = EncryptDecrypt.encrypt(params);
        formdata.append("data", encryptData?.data);
        if (imageObj) formdata.append("data1", imageObj);

        CategoryServices.edit(formdata)
          .then(res => {
            let data = EncryptDecrypt.decrypt(res.data);
            if (data.status) {
              if (toaster.current) toaster.current.success(data.message);
              onSubmit(values, action);
            } else {
              if (toaster.current) toaster.current.error(data.message);
            }
            setIsLoading(false);
          })
          .catch(err => {
            if (toaster.current) toaster.current.error(err.response);
            setIsLoading(false);
          });
      }
    }

    handleSubmit();
  };

  return (
    <div>
      <form>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Name <span className="asterisk">*</span>
          </label>
          <input
            type="text"
            className="form-control react-form-input"
            id="name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            placeholder="Add Category Name"
          />
          <Error field="name" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Service<span className="asterisk">*</span>
          </label>
          <Input
            type="select"
            name="service"
            id="service"
            onChange={handleChange}
            value={values.service}
            onBlur={handleBlur}
          >
            <option value="">Select Service</option>
            {serviceList.map((service, i) => {
              return (
                <Fragment>
                  <option key={`option${i}`} value={service.id}>
                    {service.name}
                  </option>
                </Fragment>
              );
            })}
          </Input>
          <Error field="service" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Description <span className="asterisk">*</span>
          </label>
          <input
            type="text"
            className="form-control react-form-input"
            id="description"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.description}
            placeholder="Add Category Description"
          />
          <Error field="description" />
        </div>
        <div>
          <div className="form-group">
            <label className="fs-16 medium-text">Image</label>
            <br />
            <div className="file-upload">
              <label className="c-btn c-primary form-button fs-16 demi-bold-text mt-15">
                Upload image
                <input
                  id="image"
                  className="file-upload__input"
                  name="file-upload"
                  type="file"
                  accept="image/jpg,image/png,image/jpeg"
                  onChange={e => handleImage(e, setFieldValue)}
                  onBlur={handleBlur}
                  onClick={event => {
                    event.target.value = "";
                  }}
                />
              </label>
            </div>
            <br />
            {
              <img
                src={uploadImage ? uploadImage : values.image || PLACEHOLDER}
                alt=""
                className="mtb-15"
                style={{
                  width: "200px",
                  borderRadius: "4%",
                  height: "150px",
                  background: "#404040"
                }}
              />
            }
            <br />
            <Error field="image" />
          </div>
        </div>
        <div>
          <Button
            type="button"
            className="c-btn c-info form-button fs-16 demi-bold-text"
            style={{ maxWidth: "125px" }}
            onClick={() => handleSubmit()}
            loading={isLoading}
            ddisabled={isLoading}
            dataStyle={"expand-right"}
          >
            Submit
          </Button>
        </div>
      </form>
      <Toaster ref={toaster} />
    </div>
  );
};

export default compose(enhancer)(CategoryForm);
