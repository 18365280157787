import React, { useEffect, useMemo, useRef, useState } from "react";
import UserWrapper from "./user.style";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CommonServices from "../../api/CommonServices";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import { Pie, Bar } from "react-chartjs-2";
import Gauge from "react-svg-gauge";
import {
  FOODIMAGE,
  CLIENTIMAGE,
  GOALIMAGE,
  CALENDLY_CLIENT_ID,
  CALENDLY_REDIRECT_URL,
  DOC_TYPE,
  SERVICE_NAME
} from "helper/constant";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
import { CMS_URL } from "../../helper/constant";
import Button from "components/button/Button";
import DatePicker from "react-datepicker";
import moment from "moment";
import { getCalendlyStatusAndTime } from "util/calendly";
import Toaster from "components/common/Toaster";
import CategoryServices from "api/CategoryServices";
import {
  startOfWeek,
  addDays,
  lastDayOfWeek,
  getWeek,
  addWeeks,
  subWeeks,
  subDays,
  isWithinInterval
} from "date-fns";
import { PencilIcon } from "components/message/MessagingChannelHeader/EditPencil";
import visitAMDStatus from "redux/amdStatus/actions";
import { compose } from "redux";
import permissionsActions from "redux/permissions/actions";

const { progress, complete, failed } = visitAMDStatus;

const { setPermissions } = permissionsActions;

const Dashboard = props => {
  const toaster = useRef();
  const [dashboardData, setDashboardData] = useState(null);
  const [rdData, setRdData] = useState([]);
  const [activityData, setActivityData] = useState([]);
  const [activeClientData, setActiveClientData] = useState([]);
  const [appointmentDateData, setAppointmentDateData] = useState([]);
  const [appointmentCountData, setAppointmentCountData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [refreshData, setRefreshData] = useState(0);
  const [isReconnecting, setIsReconnectiong] = useState(null);
  const [serviceId, setServiceId] = useState(1);
  const [serviceName, setServiceName] = useState("Nutrition");
  const [serviceDropdownOpen, setServiceDropdownOpen] = useState(false);
  const [serviceList, setServiceList] = useState([]);
  const toggleService = () => setServiceDropdownOpen(prevState => !prevState);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));
  const [startEndWeek, setStartEndWeek] = useState({
    start: null,
    end: null,
    today: new Date()
  });
  const [isLoading, setIsLoading] = useState(false);

  let pieData = {
    labels: ["Active Clients %", "Inactive Clients %"],
    datasets: [
      {
        label: "",
        data: activeClientData,
        backgroundColor: ["#AECE38", "#9b9b9c"],
        borderColor: ["#AECE38", "#9b9b9c"],
        borderWidth: 1
      }
    ]
  };

  let barData = {
    labels: appointmentDateData,
    datasets: [
      {
        label: "Visits By Day",
        data: appointmentCountData,
        backgroundColor: [
          "#9b9b9c",
          "#9b9b9c",
          "#9b9b9c",
          "#9b9b9c",
          "#9b9b9c",
          "#9b9b9c"
        ],
        borderColor: [
          "#9b9b9c",
          "#9b9b9c",
          "#9b9b9c",
          "#9b9b9c",
          "#9b9b9c",
          "#9b9b9c"
        ],
        borderWidth: 1
      }
    ]
  };

  const changeWeekHandle = btnType => {
    let currentM = "";
    if (btnType === "prev") {
      if (startEndWeek.start === getWeek(currentMonth)) return;
      currentM = subWeeks(currentMonth, 1);
      setCurrentMonth(subWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));
      setIsLoading(true);
    }
    if (btnType === "next") {
      if (startEndWeek.end === getWeek(currentMonth)) return;
      currentM = addWeeks(currentMonth, 1);
      setCurrentMonth(addWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
      setStartEndWeek({
        today: null
      });
      setIsLoading(true);
    }
  };

  useEffect(() => {
    let startDayOfWeek = startOfWeek(currentMonth, { weekStartsOn: 1 });
    const endDayOfWeek = subDays(
      lastDayOfWeek(currentMonth, { weekStartsOn: 1 }),
      0
    );
    setStartEndWeek({
      start: startDayOfWeek,
      end: endDayOfWeek
    });
  }, [currentWeek]);

  useEffect(() => {
    if (props.admin_role_id > 3) {
      setServiceId(props?.service_id);
      setServiceName(SERVICE_NAME[props?.service_id]);
    }
  }, []);

  useEffect(() => {
    if (props.admin_role_id === 3) {
      CommonServices.rdDashboardCount({
        date: moment(startDate).format("YYYY-MM-DD")
      }).then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        setDashboardData(data.data);
        setActiveClientData([
          data.data.monthly_metrics.active_client,
          data.data.monthly_metrics.inactive_client
        ]);
        setAppointmentDateData(
          data.data.monthly_metrics.visits_by_day.map(
            data => data.appointment_date
          )
        );
        setAppointmentCountData(
          data.data.monthly_metrics.visits_by_day.map(data => data.total_visits)
        );
        setActivityData(data.data.activity);
      });
    } else {
      const params = {
        practice_id:
          props?.admin_role_id === 6 && props?.practice
            ? props?.practice
            : undefined
      };
      if (serviceId === 2) {
        params.service_id = serviceId;
        params.start_date = startEndWeek.start;
        params.end_date = startEndWeek.end;
      }
      CommonServices.dashboardCount(params).then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        setIsLoading(false);
        setDashboardData(data.data);
        setRdData(Object.values(data.data.rd_list));
        setAppointmentDateData(
          data.data.metrics.visits_by_day.map(data => data.appointment_date)
        );
        setAppointmentCountData(
          data.data.metrics.visits_by_day.map(data => data.total_visits)
        );
      });
    }
  }, [startDate, refreshData, serviceId, startEndWeek]);

  useEffect(() => {
    CategoryServices.serviceDropdown()
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setServiceList([...data.data]);
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      });
  }, []);

  useEffect(() => {
    CommonServices.getAccessPermissions().then(res => {
      let data = EncryptDecrypt.decrypt(res.data);
      props.setPermissions(data.data);
    });
  }, []);

  const isTodayInDesiredWeek = useMemo(() => {
    let temp = true;
    if (startEndWeek?.start && startEndWeek?.end) {
      temp = isWithinInterval(new Date(), {
        start: startEndWeek?.start,
        end: startEndWeek?.end
      });
    }
    return temp;
  }, [startEndWeek]);

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          }
        }
      ]
    }
  };

  const handleEditMessageSetting = () => {
    props.history.push("/message", { settings: true });
  };

  const handleVisit = () => {
    if (toaster.current)
      toaster.current.info("Adding visits in AMD in backgroud....");
    CommonServices.addVisitInAdvanceMd()
      .then(res => {
        props.complete();
      })
      .catch(err => {
        if (err?.message !== "Network Error") {
          props.failed();
        }
      });
  };

  const handleInitialVisit = () => {
    CommonServices.addInitialVisitInRd()
      .then(res => {})
      .catch(err => {});
  };

  const openCalendly = () => {
    window.open(
      "https://auth.calendly.com/oauth/authorize?client_id=" +
        CALENDLY_CLIENT_ID +
        "&response_type=code&redirect_uri=" +
        CALENDLY_REDIRECT_URL,
      "_self"
    );
  };

  const reconnectRDCalendly = async info => {
    setIsReconnectiong(info?.admin_id);

    const isAdminAllowedReconnect = getCalendlyStatusAndTime(info)
      .adminReconnect;
    let isRDCalendlyReconnectAllowed = false;

    if (props.admin_role_id === 3) {
      isRDCalendlyReconnectAllowed = getCalendlyStatusAndTime(info)
        .rdCalendlyReConnect;
    }

    if (!isAdminAllowedReconnect && props.admin_role_id <= 2) {
      if (toaster.current)
        toaster.current.info(
          "Provider needs to reconnect Calendly to allow access permissions."
        );
    }

    if (props.admin_role_id === 3 && isRDCalendlyReconnectAllowed) {
      openCalendly();
      return;
    }
    if (
      isAdminAllowedReconnect ||
      (props.admin_role_id === 3 && !isRDCalendlyReconnectAllowed)
    ) {
      const params = {
        rd_id: info?.admin_id
      };
      try {
        const res = await CommonServices.reconnectRDCalendly(params);
        let data = EncryptDecrypt.decrypt(res.data);
        setRefreshData(prev => prev + 1);
        if (data.status) {
          if (toaster.current) toaster.current.success(data.message);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
        setIsReconnectiong(null);
      } catch (err) {
        setIsReconnectiong(null);
        if (toaster.current) toaster.current.error(err.message);
      }
    }
    setIsReconnectiong(null);
  };

  const handleServiceFilter = (result, i) => {
    setServiceId(result.id);
    setServiceName(`${result.name}`);
  };

  return (
    <UserWrapper {...props}>
      <PageTitle title="sidebar.dashboard" className="plr-15" />
      {props.admin_role_id !== 3 && (
        <>
          <div className="fs-14 mlr-5 ">
            <Dropdown isOpen={serviceDropdownOpen} toggle={toggleService}>
              <DropdownToggle
                caret
                size="md"
                disabled={props.admin_role_id > 3}
              >
                {serviceId ? serviceName : "Service"}
              </DropdownToggle>
              <DropdownMenu
                modifiers={{
                  setMaxHeight: {
                    enabled: true,
                    order: 890,
                    fn: data => {
                      return {
                        ...data,
                        styles: {
                          ...data.styles,
                          overflow: "auto",
                          maxHeight: "300px"
                        }
                      };
                    }
                  }
                }}
              >
                {serviceList.length > 0 &&
                  serviceList.map((result, i) => {
                    return (
                      <DropdownItem
                        key={i}
                        onClick={() => handleServiceFilter(result, i)}
                      >
                        {result.name}
                      </DropdownItem>
                    );
                  })}
              </DropdownMenu>
            </Dropdown>
          </div>
          <div>
            <Button
              className="c-btn c-primary ma-5"
              onClick={() => handleVisit()}
            >
              <i className="fas fa-plus mr-10" />
              Add Visit in AdvanceMd
            </Button>
            <Button
              className="c-btn c-primary ma-5"
              onClick={() => handleInitialVisit()}
            >
              <i className="fas fa-plus mr-10" />
              Add Calendly Event Detail
            </Button>
          </div>
          <div className="row">
            <div className="col-md-6">
              <table className="w-100 border">
                <tbody>
                  <tr>
                    <th
                      colSpan="2"
                      className="text-center text-white p-2 fs-16"
                      style={{ backgroundColor: "#b0d135" }}
                    >
                      Appointments
                    </th>
                  </tr>
                  <tr>
                    <td className="p-3">
                      <table className="w-100">
                        <tr>
                          <td className="border p-1">Upcoming</td>
                          <td className="border p-1">
                            {dashboardData &&
                              dashboardData.appointments &&
                              dashboardData.appointments.upcoming}
                          </td>
                        </tr>
                        <tr>
                          <td className="border p-1">Pending</td>
                          <td className="border p-1">
                            {dashboardData &&
                              dashboardData.appointments &&
                              dashboardData.appointments.pending}
                          </td>
                        </tr>
                        <tr>
                          <td className="border p-1">Completed</td>
                          <td className="border p-1">
                            {dashboardData &&
                              dashboardData.appointments &&
                              dashboardData.appointments.completed}
                          </td>
                        </tr>
                        <tr>
                          <td className="border p-1">Cancelled</td>
                          <td className="border p-1">
                            {dashboardData &&
                              dashboardData.appointments &&
                              dashboardData.appointments.canceled}
                          </td>
                        </tr>
                        <tr>
                          <td className="border p-1">No Show</td>
                          <td className="border p-1">
                            {dashboardData &&
                              dashboardData.appointments &&
                              dashboardData.appointments.no_show}
                          </td>
                        </tr>
                        <tr>
                          <td className="border p-1">Late Cancel</td>
                          <td className="border p-1">
                            {dashboardData &&
                              dashboardData.appointments &&
                              dashboardData.appointments?.late_cancel}
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              {serviceId === 1 && (
                <>
                  <div
                    className="flex flex-1 flex-column mt-12"
                    style={{ border: "1px solid #e5e7eb" }}
                  >
                    <div className="pa-10 flex flex-1 justify-content-center align-items-center bg-green color-white">
                      Messaging Status
                    </div>
                    <div className="flex flex-1 flex-column plr-10 ptb-12">
                      <div className="flex flex-1 flex-row font-bold">
                        <div className="flex" style={{ width: "32%" }}>
                          Provider
                        </div>
                        <div
                          className="flex justify-content-center"
                          style={{ width: "16%" }}
                        >
                          Status
                        </div>
                        <div className="flex " style={{ width: "26%" }}>
                          First Date
                        </div>
                        <div className="flex " style={{ width: "26%" }}>
                          Last Date
                        </div>
                      </div>
                      {!!dashboardData &&
                        !!dashboardData?.rd_list_with_away_setting?.length &&
                        dashboardData?.rd_list_with_away_setting.map(el => (
                          <div
                            className="flex flex-1 flex-row"
                            style={{ paddingTop: 6 }}
                          >
                            <div
                              className="flex align-items-center"
                              style={{ width: "32%" }}
                            >
                              {el?.first_name} {el?.last_name}
                            </div>
                            <div
                              className="flex align-items-center justify-content-center"
                              style={{ width: "16%" }}
                            >
                              <div
                                style={{
                                  height: 16,
                                  width: 16,
                                  backgroundColor: !el?.rd_away_setting?.is_away
                                    ? "#AECE38"
                                    : "#df5151",
                                  borderRadius: "50%",
                                  marginRight: 4
                                }}
                              />
                            </div>
                            <div
                              className="flex align-items-center"
                              style={{ width: "26%" }}
                            >
                              {el?.rd_away_setting?.start_date &&
                              el?.rd_away_setting?.is_away
                                ? moment(
                                    el?.rd_away_setting?.start_date
                                  ).format("YYYY-MM-DD")
                                : "-"}
                            </div>
                            <div
                              className="flex align-items-center"
                              style={{ width: "26%" }}
                            >
                              {el?.rd_away_setting?.end_date &&
                              el?.rd_away_setting?.is_away
                                ? moment(el?.rd_away_setting?.end_date).format(
                                    "YYYY-MM-DD"
                                  )
                                : "-"}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </>
              )}
              {serviceId !== 2 && (
                <table className="w-100 border mt-3">
                  <tbody>
                    <tr>
                      <th
                        colSpan="2"
                        className="text-center text-white p-2 fs-16"
                        style={{ backgroundColor: "#b0d135" }}
                      >
                        Provider Ranking
                      </th>
                    </tr>
                    <tr>
                      <td className="p-3">
                        <table className="w-100">
                          <tr>
                            <th className="border p-1">Provider</th>
                            <th className="border p-1">Units</th>
                          </tr>
                          {rdData.length > 0 &&
                            rdData.map(e => {
                              return (
                                <tr>
                                  <td className="border p-1">
                                    {e.admin && e.admin.first_name}{" "}
                                    {e.admin && e.admin.last_name}
                                  </td>
                                  <td className="border p-1">{e.unit}</td>
                                </tr>
                              );
                            })}
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
            <div className="col-md-6">
              <table className="w-100 border">
                <tbody>
                  <tr>
                    <th
                      colSpan="2"
                      className="text-center text-white p-2 fs-16"
                      style={{ backgroundColor: "#b0d135" }}
                    >
                      Monthly Metrics
                    </th>
                  </tr>
                  <tr className="text-center">
                    <td className="col-md-12">
                      <div className="p-2" style={{ display: "inline-grid" }}>
                        <lable
                          style={{
                            backgroundColor: "#9b9b9c",
                            color: "white",
                            padding: "10px"
                          }}
                        >
                          Total Clients
                        </lable>
                        <lable
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px solid #e0e0e0"
                          }}
                        >
                          {dashboardData &&
                            dashboardData.metrics &&
                            dashboardData.metrics.total_clients}
                        </lable>
                      </div>
                      <div className="p-2" style={{ display: "inline-grid" }}>
                        <lable
                          style={{
                            backgroundColor: "#9b9b9c",
                            color: "white",
                            padding: "10px"
                          }}
                        >
                          New Clients
                        </lable>
                        <lable
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px solid #e0e0e0"
                          }}
                        >
                          {dashboardData &&
                            dashboardData.metrics &&
                            dashboardData.metrics.new_clients}
                        </lable>
                      </div>
                      <div className="p-2" style={{ display: "inline-grid" }}>
                        <lable
                          style={{
                            backgroundColor: "#9b9b9c",
                            color: "white",
                            padding: "10px"
                          }}
                        >
                          Active Clients
                        </lable>
                        <lable
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px solid #e0e0e0"
                          }}
                        >
                          {dashboardData &&
                            dashboardData.metrics &&
                            dashboardData.metrics.active_client}
                        </lable>
                      </div>
                      <div className="p-2" style={{ display: "inline-grid" }}>
                        <lable
                          style={{
                            backgroundColor: "#9b9b9c",
                            color: "white",
                            padding: "10px"
                          }}
                        >
                          Units
                        </lable>
                        <lable
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px solid #e0e0e0"
                          }}
                        >
                          {dashboardData &&
                          dashboardData.metrics &&
                          dashboardData.metrics?.units[0]?.unit
                            ? dashboardData.metrics?.units[0]?.unit
                            : 0}
                        </lable>
                      </div>
                    </td>
                  </tr>
                  {dashboardData &&
                  dashboardData.metrics &&
                  dashboardData.metrics.visits_by_day ? (
                    <tr className="text-center">
                      <td>
                        <div
                          className="p-2 w-100"
                          style={{ display: "inline-grid" }}
                        >
                          <lable
                            style={{
                              backgroundColor: "#9b9b9c",
                              color: "white",
                              padding: "10px",
                              display: "flex",
                              justifyContent: "center"
                            }}
                          >
                            {serviceId === 2 && (
                              <div
                                className={
                                  isLoading ? "mr-100" : "c-btn mr-100"
                                }
                                onClick={() => {
                                  if (!isLoading) {
                                    changeWeekHandle("prev");
                                  }
                                }}
                              >
                                {"<"}
                              </div>
                            )}
                            Visits by Day
                            {serviceId === 2 && (
                              <div
                                className={
                                  isTodayInDesiredWeek || isLoading
                                    ? "ml-100"
                                    : "c-btn ml-100"
                                }
                                // style={{ maxWidth: "125px" }}

                                onClick={() => {
                                  if (!isTodayInDesiredWeek || isLoading) {
                                    changeWeekHandle("next");
                                  }
                                }}
                              >
                                {">"}
                              </div>
                            )}
                          </lable>
                          <lable
                            style={{
                              padding: "10px",
                              textAlign: "center",
                              border: "1px solid #e0e0e0"
                            }}
                          >
                            {isLoading ? "Loading.." : ""}
                            <Bar data={barData} options={options} />
                            {/* findWeekStartEndDate(); */}
                          </lable>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                </tbody>
              </table>
              <div
                className="flex flex-1 flex-column mt-12"
                style={{ border: "1px solid #e5e7eb" }}
              >
                <div className="pa-10 flex flex-1 justify-content-center align-items-center bg-green color-white">
                  Calendly Webhooks Deactivated
                </div>
                <div className="flex flex-1 flex-column plr-10 ptb-12">
                  <div className="flex flex-1 flex-row font-bold">
                    <div className="flex" style={{ width: "27%" }}>
                      Provider
                    </div>
                    <div className="flex " style={{ width: "26%" }}>
                      Status
                    </div>
                    <div className="flex " style={{ width: "31%" }}>
                      Last Updated
                    </div>
                    <div className="flex " style={{ width: "16%" }}></div>
                  </div>
                  {!!dashboardData &&
                    !!dashboardData?.rd_calendly_status_list?.length &&
                    dashboardData?.rd_calendly_status_list.map(el => (
                      <div
                        className="flex flex-1 flex-row"
                        style={{ paddingTop: 6 }}
                      >
                        <div
                          className="flex align-items-center"
                          style={{ width: "27%" }}
                        >
                          {el?.admin?.first_name} {el?.admin?.last_name}
                        </div>
                        <div
                          className="flex align-items-center"
                          style={{ width: "26%" }}
                        >
                          <div
                            style={{
                              height: 16,
                              width: 16,
                              backgroundColor: "#df5151",
                              borderRadius: "50%",
                              marginRight: 4
                            }}
                          />
                          {getCalendlyStatusAndTime(el)?.status}
                        </div>
                        <div
                          className="flex align-items-center"
                          style={{ width: "31%" }}
                        >
                          {getCalendlyStatusAndTime(el)?.time}
                        </div>
                        <div
                          className="flex align-items-center"
                          style={{ width: "16%" }}
                        >
                          <div
                            role="button"
                            onClick={() => {
                              if (isReconnecting === el?.admin_id) return;
                              reconnectRDCalendly(el);
                            }}
                            className="c-primary border-radius-6 plr-8 ptb-4 cursor-pointer"
                          >
                            {isReconnecting === el?.admin_id
                              ? "Loading.."
                              : "Reconnect"}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {props.admin_role_id === 3 && (
        <div className="row">
          <div className="col-md-6">
            <table className="w-100 border">
              <tbody>
                <tr>
                  <th
                    colSpan="2"
                    className="text-center text-white p-2 fs-16"
                    style={{ backgroundColor: "#b0d135" }}
                  >
                    Appointment Summary
                  </th>
                </tr>
                <tr>
                  <td className="p-2 text-center">
                    Date:{" "}
                    <DatePicker
                      selected={startDate}
                      onChange={date => setStartDate(date)}
                      className="custom-datepicker form-control react-form-input"
                      calendarClassName="custom-calender-class"
                      selectsStart
                    />
                  </td>
                </tr>
                <tr>
                  <td className="p-3">
                    <table className="w-100">
                      <tr>
                        <td className="border p-1">Upcoming</td>
                        <td className="border p-1">
                          {dashboardData &&
                            dashboardData.today_appointments &&
                            dashboardData.today_appointments.upcoming}
                        </td>
                      </tr>
                      <tr>
                        <td className="border p-1">Pending</td>
                        <td className="border p-1">
                          {dashboardData &&
                            dashboardData.today_appointments &&
                            dashboardData.today_appointments.pending}
                        </td>
                      </tr>
                      <tr>
                        <td className="border p-1">Completed</td>
                        <td className="border p-1">
                          {dashboardData &&
                            dashboardData.today_appointments &&
                            dashboardData.today_appointments.completed}
                        </td>
                      </tr>
                      <tr>
                        <td className="border p-1">Cancelled</td>
                        <td className="border p-1">
                          {dashboardData &&
                            dashboardData.today_appointments &&
                            dashboardData.today_appointments.canceled}
                        </td>
                      </tr>
                      <tr>
                        <td className="border p-1">No Show</td>
                        <td className="border p-1">
                          {dashboardData &&
                            dashboardData.today_appointments &&
                            dashboardData.today_appointments.no_show}
                        </td>
                      </tr>
                      <tr>
                        <td className="border p-1">Late Cancel</td>
                        <td className="border p-1">
                          {dashboardData &&
                            dashboardData.today_appointments &&
                            dashboardData.today_appointments?.late_cancel}
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              className="flex flex-1 flex-column mt-12"
              style={{ border: "1px solid #e5e7eb" }}
            >
              <div className="pa-10 flex flex-1 justify-content-center align-items-center bg-green color-white">
                Calendly Status
              </div>
              {!!dashboardData && (
                <>
                  <div className="flex flex-1 flex-column plr-10 ptb-12">
                    <div className="flex flex-row fill-width ptb-10">
                      <div className="wa-30 flex font-bold">Status</div>
                      <div className="fill-width">
                        <div className="flex align-items-center">
                          <div
                            style={{
                              height: 16,
                              width: 16,
                              backgroundColor:
                                getCalendlyStatusAndTime(
                                  dashboardData?.rd_calendly_status
                                ).status === "Not Connected"
                                  ? "#df5151"
                                  : "#AECE38",
                              borderRadius: "50%",
                              marginRight: 4
                            }}
                          />
                          {
                            getCalendlyStatusAndTime(
                              dashboardData?.rd_calendly_status
                            ).status
                          }
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row fill-width ptb-10">
                      <div className="wa-30 flex font-bold">Last Updated</div>
                      <div className="fill-width">
                        {
                          getCalendlyStatusAndTime(
                            dashboardData?.rd_calendly_status
                          ).time
                        }
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-1 flex-column plr-10 ptb-12 wa-30">
                    <Button
                      className="c-btn c-primary cursor-pointer"
                      onClick={() =>
                        reconnectRDCalendly(dashboardData?.rd_calendly_status)
                      }
                      disabled={
                        getCalendlyStatusAndTime(
                          dashboardData?.rd_calendly_status
                        ).status !== "Not Connected" ||
                        isReconnecting ===
                          dashboardData?.rd_calendly_status?.admin_id
                      }
                    >
                      {isReconnecting ===
                      dashboardData?.rd_calendly_status?.admin_id
                        ? "Reconnecting.."
                        : "Reconnect"}
                    </Button>
                  </div>
                </>
              )}
            </div>
            {props.admin_role_id === 3 && props.service_id !== 2 && (
              <div
                className="flex flex-1 flex-column mt-12"
                style={{ border: "1px solid #e5e7eb" }}
              >
                <div className="pa-10 flex flex-1 justify-content-center align-items-center bg-green color-white">
                  Messaging Status
                </div>
                {!!dashboardData && (
                  <>
                    <div className="flex flex-1 flex-column plr-10 ptb-12">
                      <div className="flex flex-row fill-width ptb-10">
                        <div className="wa-30 flex font-bold">Status</div>
                        <div className="fill-width">
                          <div className="flex align-items-center">
                            <div
                              style={{
                                height: 16,
                                width: 16,
                                backgroundColor: !dashboardData?.rd_away_setting
                                  ?.rd_away_setting?.is_away
                                  ? "#AECE38"
                                  : "#df5151",
                                borderRadius: "50%",
                                marginRight: 6
                              }}
                            />
                            <div
                              className="cursor-pointer flex align-items-center"
                              onClick={handleEditMessageSetting}
                            >
                              <PencilIcon height={16} width={16} />
                              <div
                                style={{ marginBottom: 1, paddingInline: 2 }}
                              >
                                Edit Status
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {dashboardData?.rd_away_setting?.rd_away_setting
                        ?.is_away && (
                        <>
                          <div className="flex flex-row fill-width ptb-10">
                            <div className="wa-30 flex font-bold">
                              First Date
                            </div>
                            <div className="fill-width">
                              {dashboardData?.rd_away_setting?.rd_away_setting
                                ?.start_date
                                ? moment(
                                    dashboardData?.rd_away_setting
                                      ?.rd_away_setting?.start_date
                                  ).format("YYYY-MM-DD")
                                : "-"}
                            </div>
                          </div>
                          <div className="flex flex-row fill-width ptb-10">
                            <div className="wa-30 flex font-bold">
                              Last Date
                            </div>
                            <div className="fill-width">
                              {dashboardData?.rd_away_setting?.rd_away_setting
                                ?.end_date
                                ? moment(
                                    dashboardData?.rd_away_setting
                                      ?.rd_away_setting?.end_date
                                  ).format("YYYY-MM-DD")
                                : "-"}
                            </div>
                          </div>
                          <div className="flex flex-y fill-width ptb-10">
                            <div className="wa-30 flex font-bold">Message</div>
                            <div className="fill-width">
                              {dashboardData?.rd_away_setting?.rd_away_setting
                                ?.message
                                ? dashboardData?.rd_away_setting
                                    ?.rd_away_setting?.message
                                : "-"}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            )}
            {/* {activityData.length > 0 ? ( */}
            <table className="w-100 border mt-3">
              <tbody>
                <tr>
                  <th
                    colSpan="2"
                    className="text-center text-white p-2 fs-16"
                    style={{ backgroundColor: "#b0d135" }}
                  >
                    Activity
                  </th>
                </tr>
                {!activityData.length && (
                  <div className="flex justify-content-center align-items-center pt-2">
                    No Activities
                  </div>
                )}
                <tr>
                  <td className="p-3">
                    <table className="w-100">
                      {activityData.length > 0 &&
                        activityData.map(e => {
                          if (e.activity_type === 1) {
                            return (
                              <tr>
                                <td>
                                  <img src={GOALIMAGE} width="30"></img>
                                </td>
                                <td className="p-2">
                                  {e.user.first_name + " " + e.user.last_name}{" "}
                                  has completed{" "}
                                  {e.isCurrentClient ? (
                                    <a
                                      href={
                                        CMS_URL +
                                        "/client/view/" +
                                        e.user_id +
                                        `${
                                          props?.service_id &&
                                          props?.service_id === 2
                                            ? "?feelings"
                                            : "?goal"
                                        }`
                                      }
                                    >
                                      goal
                                    </a>
                                  ) : (
                                    <span>goal</span>
                                  )}
                                </td>
                              </tr>
                            );
                          } else if (e.activity_type === 3) {
                            return (
                              <tr>
                                <td>
                                  <img src={FOODIMAGE} width="30"></img>
                                </td>
                                <td className="p-2">
                                  {e.user.first_name + " " + e.user.last_name}{" "}
                                  tracked a{" "}
                                  {e.isCurrentClient ? (
                                    <a
                                      href={
                                        CMS_URL +
                                        "/client/view/" +
                                        e.user_id +
                                        "?meal"
                                      }
                                    >
                                      new meal
                                    </a>
                                  ) : (
                                    <span>new meal</span>
                                  )}
                                </td>
                              </tr>
                            );
                          } else if (e.activity_type === 4) {
                            return (
                              <tr>
                                <td>
                                  <img src={GOALIMAGE} width="30"></img>
                                </td>
                                <td className="p-2">
                                  {e.user.first_name + " " + e.user.last_name}{" "}
                                  created a{" "}
                                  {e.isCurrentClient ? (
                                    <a
                                      href={
                                        CMS_URL +
                                        "/client/view/" +
                                        e.user_id +
                                        "?goal"
                                      }
                                    >
                                      new goal
                                    </a>
                                  ) : (
                                    <span>new goal</span>
                                  )}
                                </td>
                              </tr>
                            );
                          } else if (e.activity_type === 5) {
                            return (
                              <tr>
                                <td>
                                  <img src={CLIENTIMAGE} width="30"></img>
                                </td>
                                <td className="p-2">
                                  You were assigned a new client,{" "}
                                  {e.isCurrentClient ? (
                                    <a
                                      href={
                                        CMS_URL + "/client/view/" + e.user_id
                                      }
                                    >
                                      {e.user.first_name +
                                        " " +
                                        e.user.last_name}
                                    </a>
                                  ) : (
                                    <span>
                                      {e.user.first_name +
                                        " " +
                                        e.user.last_name}
                                    </span>
                                  )}
                                  {e.user_referral_id &&
                                    `, referred by ${e?.user_referral?.admin
                                      ?.first_name +
                                      " " +
                                      e?.user_referral?.admin?.last_name}`}
                                </td>
                              </tr>
                            );
                          } else if (e.activity_type === 6) {
                            return (
                              <tr>
                                <td>
                                  <i className="far fa-lightbulb fa-2x"></i>
                                </td>
                                <td className="p-2">
                                  {e.user.first_name + " " + e.user.last_name}{" "}
                                  created a{" "}
                                  {e.isCurrentClient ? (
                                    <a
                                      href={
                                        CMS_URL +
                                        "/client/view/" +
                                        e.user_id +
                                        "?thoughts"
                                      }
                                    >
                                      new thought
                                    </a>
                                  ) : (
                                    <span>new thought</span>
                                  )}
                                </td>
                              </tr>
                            );
                          } else if (e.activity_type === 7) {
                            return (
                              <tr>
                                <td>
                                  <i className="far fa-lightbulb fa-2x"></i>
                                </td>
                                <td className="p-2">
                                  {e.user.first_name + " " + e.user.last_name}{" "}
                                  shared a{" "}
                                  {e.isCurrentClient ? (
                                    <a
                                      href={
                                        CMS_URL +
                                        "/client/view/" +
                                        e.user_id +
                                        "?thoughts"
                                      }
                                    >
                                      thought
                                    </a>
                                  ) : (
                                    <span>thought</span>
                                  )}
                                </td>
                              </tr>
                            );
                          } else if (e.activity_type === 8) {
                            return (
                              <tr>
                                <td>
                                  <i className="fas fa-smile fa-2x"></i>
                                </td>
                                <td className="p-2">
                                  {e.user.first_name + " " + e.user.last_name}{" "}
                                  created a{" "}
                                  {e.isCurrentClient ? (
                                    <a
                                      href={
                                        CMS_URL +
                                        "/client/view/" +
                                        e.user_id +
                                        "?feelings"
                                      }
                                    >
                                      new feeling
                                    </a>
                                  ) : (
                                    <span>new feeling</span>
                                  )}
                                </td>
                              </tr>
                            );
                          } else if (e.activity_type === 9) {
                            return (
                              <tr>
                                <td>
                                  <i class="fas fa-file-alt" width="30"></i>
                                </td>
                                <td className="p-2">
                                  New {DOC_TYPE[e?.user_document?.service_id]}{" "}
                                  document uploaded for,{" "}
                                  {e.isCurrentClient ? (
                                    <a
                                      href={
                                        CMS_URL +
                                        "/client/view/" +
                                        e.user_id +
                                        "?document"
                                      }
                                    >
                                      {e.user.first_name +
                                        " " +
                                        e.user.last_name}
                                    </a>
                                  ) : (
                                    <span>
                                      {e.user.first_name +
                                        " " +
                                        e.user.last_name}
                                    </span>
                                  )}
                                </td>
                              </tr>
                            );
                          }
                        })}
                    </table>
                    {activityData?.length > 0 && (
                      <div className="text-right">
                        <a href={"activity"}>View All</a>
                      </div>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
            {/*) : (
              ""
            )} */}
          </div>
          <div className="col-md-6">
            <table className="w-100 border">
              <tbody>
                <tr>
                  <th
                    colSpan="2"
                    className="text-center text-white p-2 fs-16"
                    style={{ backgroundColor: "#b0d135" }}
                  >
                    Monthly Metrics
                  </th>
                </tr>
                <tr className="text-center">
                  <td>
                    <div
                      className="p-2 w-100"
                      style={{ display: "inline-grid" }}
                    >
                      <lable
                        style={{
                          backgroundColor: "#9b9b9c",
                          color: "white",
                          padding: "10px"
                        }}
                      >
                        Client Metrics
                      </lable>
                      <lable
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          border: "1px solid #e0e0e0"
                        }}
                      >
                        <div className="p-2" style={{ display: "inline-grid" }}>
                          <lable
                            style={{
                              backgroundColor: "#9b9b9c",
                              color: "white",
                              padding: "10px"
                            }}
                          >
                            Total Clients
                          </lable>
                          <lable
                            style={{
                              padding: "10px",
                              textAlign: "center",
                              border: "1px solid #e0e0e0"
                            }}
                          >
                            {dashboardData &&
                              dashboardData.monthly_metrics &&
                              dashboardData.monthly_metrics.total_client}
                          </lable>
                        </div>
                        <Pie data={pieData} />
                      </lable>
                    </div>
                  </td>
                </tr>
                {dashboardData &&
                dashboardData.monthly_metrics &&
                dashboardData.monthly_metrics.units_to_goal ? (
                  <tr className="text-center">
                    <td>
                      <div
                        className="p-2 w-100"
                        style={{ display: "inline-grid" }}
                      >
                        <lable
                          style={{
                            backgroundColor: "#9b9b9c",
                            color: "white",
                            padding: "10px"
                          }}
                        >
                          Unit - Goal
                        </lable>
                        <lable
                          style={{
                            border: "1px solid #e0e0e0"
                          }}
                        >
                          <Gauge
                            value={
                              dashboardData.monthly_metrics.units_to_goal.unit
                            }
                            max={
                              dashboardData.monthly_metrics.units_to_goal.goal
                            }
                            width={200}
                            height={170}
                            color={`#AECE38`}
                            label={
                              dashboardData.monthly_metrics.units_to_goal.unit +
                              " / " +
                              dashboardData.monthly_metrics.units_to_goal.goal
                            }
                          />
                        </lable>
                      </div>
                    </td>
                  </tr>
                ) : (
                  ""
                )}

                {dashboardData &&
                dashboardData.monthly_metrics &&
                dashboardData.monthly_metrics.visits_by_day ? (
                  <tr className="text-center">
                    <td>
                      <div
                        className="p-2 w-100"
                        style={{ display: "inline-grid" }}
                      >
                        <lable
                          style={{
                            backgroundColor: "#9b9b9c",
                            color: "white",
                            padding: "10px"
                          }}
                        >
                          Visits by Day
                        </lable>
                        <lable
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px solid #e0e0e0"
                          }}
                        >
                          <Bar data={barData} options={options} />
                        </lable>
                      </div>
                    </td>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
      <Toaster ref={toaster} />
    </UserWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    ...state.auth,
    ...state.permissionReducer
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    progress,
    complete,
    failed,
    setPermissions
  })
)(Dashboard);
