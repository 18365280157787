import React from "react";
import PageWrapper from "./ExternalProvider.style";
import { connect } from "react-redux";
import PageTitle from "components/common/PageTitle";
import ExternalProviderViewDetails from "../../components/ExternalProvider/ExternalProviderViewDetails";

const StaticPageView = props => {
  const hasParam = props.match.params.id;
  const data = props.location.state;
  return (
    <PageWrapper {...props}>
      <div className="pos-relative">
        <PageTitle
          props={props}
          title="sidebar.external_provider"
          className="plr-15"
          breadCrumb={[
            {
              name: "sidebar.dashboard",
              url: "/dashboard"
            },
            {
              name: "sidebar.external_provider",
              url: "/external-provider"
            },
            {
              name: "action.view"
            }
          ]}
        />
        <div
          className="back-icon fs-15 demi-bold-text cursor-pointer"
          onClick={() => props.history.push("/external-provider")}
        >
          <i className="fas fa-step-backward"></i> Back
        </div>
      </div>
      <div className="user-tbl">
        <ExternalProviderViewDetails
          {...props}
          hasParam={hasParam}
          editedData={data}
        />
      </div>
    </PageWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(StaticPageView);
