import React from "react";
import UserWrapper from "./uploadFinancialReport.style";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";
import UploadFinancialReportForm from "../../components/uploadFinancialReport/UploadFinancialReportForm";

const UploadFinancialReport = props => {
  const submitFormHandler = () => {
    setTimeout(() => {
      props.history.push("/financial-report");
    }, 2000);
  };

  return (
    <UserWrapper {...props}>
      <div className="pos-relative">
        <PageTitle
          props={props}
          title="sidebar.upload_financial_report"
          className="plr-15"
          breadCrumb={[
            {
              name: "sidebar.dashboard",
              url: "/dashboard"
            },
            {
              name: "sidebar.upload_financial_report"
            }
          ]}
        />
      </div>

      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Upload Financial Report
            </div>
          </div>
          <div className="roe-card-body">
            <UploadFinancialReportForm
              onSubmit={submitFormHandler}
              action={"add"}
              {...props}
            />
          </div>
        </div>
      </div>
    </UserWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.permissionReducer,
    ...state.themeChanger,
    ...state.auth
  };
};

export default connect(mapStateToProps, null)(UploadFinancialReport);
