import React, { memo, useContext } from "react";
import {
  Avatar,
  ChatContext,
  useChannelStateContext,
  useChatContext
} from "stream-chat-react";

import "./MessagingChannelPreview.css";

const getTimeStamp = channel => {
  let today = new Date();
  let todayDate = today?.getDate();
  let todayMonth = today?.getMonth() + 1;
  let todayYear = today?.getFullYear();

  let lastDate = channel.state.last_message_at?.getDate();
  let lastMonth = channel.state.last_message_at?.getMonth() + 1;
  let lastYear = channel.state.last_message_at?.getFullYear();
  let lastHours = channel.state.last_message_at?.getHours();
  let lastMinutes = channel.state.last_message_at?.getMinutes();
  let half = "AM";

  if (lastHours === undefined || lastMinutes === undefined) {
    return "";
  }

  if (lastHours > 12) {
    lastHours = lastHours - 12;
    half = "PM";
  }

  if (lastHours === 0) lastHours = 12;
  if (lastHours === 12) half = "PM";

  if (lastMinutes.toString().length === 1) {
    lastMinutes = `0${lastMinutes}`;
  }

  if (
    todayDate == lastDate &&
    todayMonth == lastMonth &&
    todayYear == lastYear
  ) {
    return `Today ${lastHours}:${lastMinutes} ${half}`;
  } else {
    return `${lastMonth}/${lastDate}/${lastYear} ${lastHours}:${lastMinutes} ${half}`;
  }
};

const getChannelName = members => {
  const defaultName = "Johnny Blaze";

  if (!members.length || members.length === 1) {
    return members[0]?.user.name || defaultName;
  }

  return `${members[0]?.user.name || defaultName}, ${members[1]?.user.name ||
    defaultName}`;
};

const MessagingChannelPreview = memo(props => {
  const { channel, latestMessage, setActiveChannel, setIsCreating } = props;
  // const { channel: activeChannel, client } = useContext(ChatContext);

  // const { channel: activeChannel } = useChatContext(); //useContext(ChannelContext);

  const { client, channel: activeChannel } = useChatContext();

  const members = Object.values(channel.state.members).filter(
    ({ user }) => user.id !== client.userID
  );

  return (
    <div
      id={channel?.id}
      className={
        channel?.id === activeChannel?.id
          ? "channel-preview__container selected"
          : "channel-preview__container"
      }
      onClick={() => {
        setIsCreating(false);
        setActiveChannel(channel);
      }}
    >
      {/* {getAvatarGroup(members)} */}
      {
        <Avatar
          image={members[0] && members[0].user && members[0].user.image}
          name={channel.data.name}
          shape="circle"
          size={40}
        />
      }
      <div className="channel-preview__content-wrapper">
        <div className="channel-preview__content-top">
          <p className="channel-preview__content-name">
            {getChannelName(members) || channel.data.name}
          </p>

          <p className="channel-preview__content-time">
            {getTimeStamp(channel)}
          </p>
        </div>
        <div className="channel-preview__content-top">
          <p className="channel-preview__content-message">
            {latestMessage || "Send a message"}
          </p>
          {channel.state && channel.state.unreadCount > 0 && (
            <p className="channel-preview__notification_cnt">
              {channel.state.unreadCount}
            </p>
          )}
        </div>
      </div>
    </div>
  );
});

export default MessagingChannelPreview;
