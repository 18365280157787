import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import Loader from "../common/Loader";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import moment from "moment";
import FeelingsTrackingService from "../../api/FeelingsTracking";

const FeelingsTracking = props => {
  const [tblData, settblData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [field, setField] = useState("created_at");
  const [sortBy, setSortBy] = useState("DESC");
  const [searchText, setsearchText] = useState("");

  const hasParam = props.match.params?.id;

  const toaster = useRef();

  useEffect(() => {
    getList(field, sortBy, searchText, activePage + 1);
    setPages(10);
  }, []);

  const columns = [
    {
      Header: "Date",
      accessor: "goal_date",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      Cell: ({ original }) => (
        <>
          {original?.goal_date
            ? moment(original?.goal_date).format("MM/DD/YYYY")
            : "-"}
        </>
      ),
      width: 150,
      sortable: true
    },
    {
      Header: "I Feel",
      accessor: "feeling",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      width: 160,
      Cell: ({ value }) => (
        <div className="d-flex flex-wrap">
          {value?.feelings_option.name !== "Other"
            ? String(value?.feelings_option?.name)
            : `${String(value?.feelings_option?.name)} - ${String(
                value?.other_value
              )}`}
        </div>
      )
    },
    {
      Header: "I Need",
      accessor: "need",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      width: 400,
      Cell: ({ value }) =>
        value && value.length
          ? value.map((el, i) => (
              <div className="d-flex flex-wrap">
                {el.feelings_option.name !== "Other"
                  ? String(el?.feelings_option?.name)
                  : `${String(el?.feelings_option?.name)} - ${String(
                      el?.other_value
                    )}`}
                {i !== value.length - 1 && ","}
              </div>
            ))
          : "-"
    },
    {
      Header: "I Can",
      accessor: "i_can",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      width: 520,
      Cell: ({ value }) =>
        value && value.length
          ? value.map((el, i) => (
              <div className="d-flex flex-wrap">
                {el.feelings_option.name !== "Other"
                  ? String(el?.feelings_option?.name)
                  : `${String(el?.feelings_option?.name)} - ${String(
                      el?.other_value
                    )}`}
                {i !== value.length - 1 && ","}
              </div>
            ))
          : "-"
    },
    {
      Header: "Status",
      accessor: "is_completed",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      width: 100,
      Cell: ({ value }) => (
        <div>
          <i
            className={
              !value
                ? "far fa-check-circle tick-no-bg-color"
                : "far fa-check-circle tick-bg-color"
            }
          ></i>
        </div>
      )
    }
  ];

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  function getList(
    field = "created_at",
    sortBy = "DESC",
    search = "",
    page = "1"
  ) {
    const params = {
      sort_by: field === "goal_date" ? "created_at" : field,
      order: sortBy,
      search: search,
      page: page,
      user_id: hasParam
    };
    setLoader(true);
    FeelingsTrackingService.list(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count / 10));
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
          setLoader(false);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
        setLoader(false);
      });
  }

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Feelings List
            </div>
          </div>

          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData} //{tblData}
              columns={columns}
              defaultPageSize={10}
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              LoadingComponent={Loader}
              onFetchData={onChangePageGetData}
              onPageChange={pageIndex => {
                setActivePage(pageIndex);
                getList(field, sortBy, searchText, pageIndex + 1);
              }}
              onSortedChange={sortProperties => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getList(sortProperties[0].id, sort, searchText, 1);
                // alert("sort handler", sortProperties);
                // const orderByDirection = sortProperties[0].desc ? 1 : 0;

                // updateMapStateInfoOrderByColumn(sortProperties[0].id, orderByDirection);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default withRouter(FeelingsTracking);
