import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    first_name: Yup.string()
      .min(2, "Enter at least 2 characters.")
      .nullable(),
    last_name: Yup.string()
      .min(2, "Enter at least 2 characters.")
      .nullable(),
    provider_npi: Yup.number().min(10, "NPI must be upto 10 digits")
  }),
  mapPropsToValues: props => ({
    first_name: "",
    last_name: "",
    provider_npi: "",
    state: ""
  }),

  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
