import React, { useRef, useState } from "react";
import { compose } from "redux";
import enhancer from "./validator";
import Button from "../button/Button";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import PushNotificationServices from "../../api/PushNotificationServices";
import Toaster from "../common/Toaster";

const PushNotificationForm = props => {
  const [loader, setLoader] = useState(false);
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    onSubmit,
    values,
    action
  } = props;

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };
  const toaster = useRef();

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;
    setLoader(true);
    if (isValid) {
      onSubmit(values, action);
      if (action === "add") {
        const params = {
          notification_text: values.notification_text,
          user_type: values.user_type
        };
        PushNotificationServices.add(params)
          .then(res => {
            let data = EncryptDecrypt.decrypt(res.data);
            if (data.status) {
              if (toaster.current) toaster.current.success(data.message);
              setLoader(false);
            } else {
              if (toaster.current) toaster.current.error(data.message);
              setLoader(false);
            }
            setLoader(false);
          })
          .catch(err => {
            if (toaster.current) toaster.current.error(err.response);
            setLoader(false);
          });
      }
    }

    handleSubmit();
  };

  return (
    <div>
      <form>
        <div className="form-group">
          <label className="fs-16 medium-text">
            User Type <span className="asterisk">*</span>
          </label>
          <div className="col-sm-10">
            <div className="form-check form-check-inline mt-7">
              <input
                className="form-check-input"
                type="radio"
                name="user_type"
                id="user"
                value="user"
                onChange={handleChange}
                checked={values.user_type === "user" ? "checked" : false}
              />
              <label className="form-check-label">Clients</label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="user_type"
                id="rd"
                value="rd"
                onChange={handleChange}
                checked={values.user_type === "rd" ? "checked" : false}
              />
              <label className="form-check-label">Providers</label>
            </div>
          </div>
          <Error field="user_type" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Name <span className="asterisk">*</span>
          </label>
          <input
            type="text"
            className="form-control react-form-input"
            id="notification_text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.notification_text}
            placeholder="Add Notification"
          />
          <Error field="notification_text" />
        </div>
        <div>
          <Button
            type="button"
            className="c-btn c-info form-button fs-16 demi-bold-text"
            style={{ maxWidth: "125px" }}
            onClick={() => handleSubmit()}
            loading={loader}
            disabled={loader}
            dataStyle={"expand-right"}
          >
            Submit
          </Button>
        </div>
      </form>
      <Toaster ref={toaster} />
    </div>
  );
};

export default compose(enhancer)(PushNotificationForm);
