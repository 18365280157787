import axios from "axios";
import { baseUrl } from "../helper/constant";
import Header from "./Header";
import EncryptDecrypt from "./EncryptDecrypt";

export default class ThoughtsTrackingService {
  static list(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/thoughts-tracking/list`,
      EncryptDecrypt.encrypt(params)
    );
  }
}
