import React from "react";
import PageWrapper from "./DiagnosesCode.style";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";
import DiagnosesCodeTable from "../../components/diagnosesCode/diagnosesCodeTable";

const DiagnosesCode = props => {
  return (
    <PageWrapper {...props}>
      <PageTitle
        props={props}
        title="sidebar.diagnoses_code"
        className="plr-15"
        breadCrumb={[
          {
            name: "sidebar.dashboard",
            url: "/dashboard"
          },
          {
            name: "sidebar.diagnoses_code"
          }
        ]}
      />
      <div className="user-tbl">
        <DiagnosesCodeTable {...props} />
      </div>
    </PageWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    ...state.permissionReducer,
    authData: {
      token: state.auth.token,
      isLogin: state.auth.isLogin,
      profileImage: state.auth.profile_image,
      adminRoleId: state.auth.admin_role_id,
      adminRole: state.auth.admin_role,
      full_name: state.auth.first_name + " " + state.auth.last_name,
      service: state.auth.service_id,
      practice: state.auth.practice
    }
  };
};

export default connect(mapStateToProps, null)(DiagnosesCode);
