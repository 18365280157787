import axios from "axios";
import { baseUrl } from "../helper/constant";
import Header from "./Header";
import EncryptDecrypt from "./EncryptDecrypt";
import FileDownload from "js-file-download";
export default class ReportServices {
  static visitDetailList(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/report/list-visit-details`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static billingTransactionList(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/report/list-billing-transaction`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static unbillableClientList(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/report/unbillable-client-report`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static insuranceBillList(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/report/list-insurance-billing-transaction`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static clientDetailList(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/report/list-client-details`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static rdUnitGoalList(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/report/list-rd-unit-goal`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static rdInsuranceList(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/report/list-rd-insurance`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static rdStateList(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/report/list-rd-state`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static scaleRedemtionList(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/report/list-scale-redemption`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static activationDetailListNutrition(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/report/list-activation`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static activationDetailListMentalHealth(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/report/list-activation-mental-health`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static visitDetailListCsv(params) {
    Header.setHeaders();
    return axios
      .post(
        `${baseUrl}/report/csv-visit-details`,
        EncryptDecrypt.encrypt(params)
      )
      .then(res => {
        FileDownload(res.data, "VisitDetail.csv");
      });
  }

  static billingTransactionListCsv(params) {
    Header.setHeaders();
    return axios
      .post(
        `${baseUrl}/report/csv-billing-transaction`,
        EncryptDecrypt.encrypt(params)
      )
      .then(res => {
        FileDownload(res.data, "BillingTransactionList.csv");
      });
  }

  static unbillableClientListCsv(params) {
    Header.setHeaders();
    return axios
      .post(
        `${baseUrl}/report/csv-un-billable-client-report`,
        EncryptDecrypt.encrypt(params)
      )
      .then(res => {
        FileDownload(res.data, "UnbillableClientList.csv");
      });
  }

  static insuranceBillingTransactionListCsv(params) {
    Header.setHeaders();
    return axios
      .post(
        `${baseUrl}/report/csv-insurance-billing-transaction`,
        EncryptDecrypt.encrypt(params)
      )
      .then(res => {
        FileDownload(res.data, "InsuranceBillReport.csv");
      });
  }

  static clientDetailListCsv(params) {
    Header.setHeaders();
    return axios
      .post(
        `${baseUrl}/report/csv-client-details`,
        EncryptDecrypt.encrypt(params)
      )
      .then(res => {
        FileDownload(res.data, "ClientDetail.csv");
      });
  }

  static rdUnitGoalListCsv(params) {
    Header.setHeaders();
    return axios
      .post(
        `${baseUrl}/report/csv-rd-unit-goal`,
        EncryptDecrypt.encrypt(params)
      )
      .then(res => {
        FileDownload(res.data, "RDUnitGoalDetail.csv");
      });
  }

  static rdInsuranceListCsv(params) {
    Header.setHeaders();
    return axios
      .post(
        `${baseUrl}/report/csv-rd-insurance`,
        EncryptDecrypt.encrypt(params)
      )
      .then(res => {
        FileDownload(res.data, "RDInsuranceDetail.csv");
      });
  }

  static rdStateListCsv(params) {
    Header.setHeaders();
    return axios
      .post(`${baseUrl}/report/csv-rd-state`, EncryptDecrypt.encrypt(params))
      .then(res => {
        FileDownload(res.data, "RDStateDetail.csv");
      });
  }

  static scaleRedemtionListCsv(params) {
    Header.setHeaders();
    return axios
      .post(
        `${baseUrl}/report/csv-scale-redemption`,
        EncryptDecrypt.encrypt(params)
      )
      .then(res => {
        FileDownload(res.data, "ScaleRedemptionList.csv");
      });
  }

  static activationDetailListCsvNutrition(params) {
    Header.setHeaders();
    return axios
      .post(`${baseUrl}/report/csv-activation`, EncryptDecrypt.encrypt(params))
      .then(res => {
        FileDownload(res.data, "ActivationReport.csv");
      });
  }

  static activationDetailListCsvMentalHealth(params) {
    Header.setHeaders();
    return axios
      .post(
        `${baseUrl}/report/csv-activation-mental-health`,
        EncryptDecrypt.encrypt(params)
      )
      .then(res => {
        FileDownload(res.data, "ActivationReport.csv");
      });
  }

  static mealDeliveryList(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/report/list-meal-delivery`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static mealDeliveryListCsv(params) {
    Header.setHeaders();
    return axios
      .post(
        `${baseUrl}/report/csv-meal-delivery`,
        EncryptDecrypt.encrypt(params)
      )
      .then(res => {
        FileDownload(res.data, "MealDeliveryList.csv");
      });
  }

  static dailyBillingFailureList(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/report/list-amd-billing-failure-report`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static dailyBillingFailureCsv(params) {
    Header.setHeaders();
    return axios
      .post(
        `${baseUrl}/report/csv-amd-billing-failure-report`,
        EncryptDecrypt.encrypt(params)
      )
      .then(res => {
        FileDownload(res.data, "DailyBillingFailureList.csv");
      });
  }

  static rdCaqhList(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/report/list-rd-caqh`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static rdCaqhCsv(params) {
    Header.setHeaders();
    return axios
      .post(`${baseUrl}/report/csv-rd-caqh`, EncryptDecrypt.encrypt(params))
      .then(res => {
        FileDownload(res.data, "RDCaqhDetail.csv");
      });
  }

  static rdLiabilityInsuranceList(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/report/list-rd-liability-insurance`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static rdLiabilityInsuranceCsv(params) {
    Header.setHeaders();
    return axios
      .post(
        `${baseUrl}/report/csv-rd-liability-insurance`,
        EncryptDecrypt.encrypt(params)
      )
      .then(res => {
        FileDownload(res.data, "RDLiabilityInsuranceDetail.csv");
      });
  }

  static rdCdrList(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/report/list-rd-cdr`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static rdCdrCsv(params) {
    Header.setHeaders();
    return axios
      .post(`${baseUrl}/report/csv-rd-cdr`, EncryptDecrypt.encrypt(params))
      .then(res => {
        FileDownload(res.data, "RDCdrDetail.csv");
      });
  }

  static rdRetentionReportList(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/report/rd-retention-report`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static iboPracticeReportList(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/report/list-ibo-practice-report`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static lateCancelNoShowReportList(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/report/list-late-cancel-report`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static lateCancelNoShowListCsv(params) {
    Header.setHeaders();
    return axios
      .post(
        `${baseUrl}/report/csv-late-cancel-report`,
        EncryptDecrypt.encrypt(params)
      )
      .then(res => {
        FileDownload(res.data, "LateCancel/NoShow.csv");
      });
  }
}
