import React from "react";
import Logo from "../../assets/images/logo.png";
import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View
} from "@react-pdf/renderer";
import moment from "moment-timezone";
import { diffMinutes } from "components/roeeditor/utils";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    paddingHorizontal: 48,
    color: "#5A6A80",
    paddingBottom: 86
  },
  footer: {
    fontSize: "9pt",
    paddingHorizontal: 48
  },
  header: {
    paddingTop: 24,
    paddingBottom: 24,
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center"
  },
  pointContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  billPoints: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 2,
    alignItems: "center"
  },
  visitPoints: {
    display: "flex",
    flexDirection: "row",
    marginTop: 4,
    alignItems: "flex-start"
  }
});

export const DownloadPdf = ({ data }) => {
  return (
    <Document>
      <Page wrap size="A4" style={styles.page}>
        <View fixed style={styles.header}>
          <Text style={{ fontSize: 24 }}>
            Patient Export - [{data?.userDetails?.id}]
          </Text>
          <Image src={Logo} style={{ height: 46, width: 150 }} />
        </View>
        <View style={{ marginBottom: 6 }}>
          <Text
            style={{
              fontSize: 18,
              marginBottom: 6,
              fontWeight: 700,
              marginBottom: 12
            }}
          >
            Patient Demographics
          </Text>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}
          >
            <Text style={{ marginHorizontal: 6, fontSize: "16px" }}>•</Text>
            <Text style={{ fontSize: 12 }}>
              ID: {data?.userDetails?.id ? data?.userDetails?.id : "-"}
            </Text>
          </View>
          <View style={styles.pointContainer}>
            <Text style={{ marginHorizontal: 6, fontSize: "16px" }}>•</Text>
            <Text style={{ fontSize: 12 }}>
              First Name:{" "}
              {data?.userDetails?.first_name
                ? data?.userDetails?.first_name
                : "-"}
            </Text>
          </View>
          <View style={styles.pointContainer}>
            <Text style={{ marginHorizontal: 6, fontSize: "16px" }}>•</Text>
            <Text style={{ fontSize: 12 }}>
              Last Name:{" "}
              {data?.userDetails?.last_name
                ? data?.userDetails?.last_name
                : "-"}
            </Text>
          </View>
          <View style={styles.pointContainer}>
            <Text style={{ marginHorizontal: 6, fontSize: "16px" }}>•</Text>
            <Text style={{ fontSize: 12 }}>
              Email: {data?.userDetails?.email ? data?.userDetails?.email : "-"}
            </Text>
          </View>
          <View style={styles.pointContainer}>
            <Text style={{ marginHorizontal: 6, fontSize: "16px" }}>•</Text>
            <Text style={{ fontSize: 12 }}>
              Date of birth:{" "}
              {data?.userDetails?.dob
                ? moment(data?.userDetails?.dob).format("MM/DD/YYYY")
                : "-"}
            </Text>
          </View>
          <View style={styles.pointContainer}>
            <Text style={{ marginHorizontal: 6, fontSize: "16px" }}>•</Text>
            <Text style={{ fontSize: 12 }}>
              Category:{" "}
              {data?.userDetails?.category?.name
                ? data?.userDetails?.category?.name
                : "-"}
            </Text>
          </View>
          <View style={styles.pointContainer}>
            <Text style={{ marginHorizontal: 6, fontSize: "16px" }}>•</Text>
            <Text style={{ fontSize: 12 }}>
              Street Address:{" "}
              {data?.userDetails?.address ? data?.userDetails?.address : "-"}
            </Text>
          </View>
          <View style={styles.pointContainer}>
            <Text style={{ marginHorizontal: 6, fontSize: "16px" }}>•</Text>
            <Text style={{ fontSize: 12 }}>
              City: {data?.userDetails?.city ? data?.userDetails?.city : "-"}
            </Text>
          </View>
          <View style={styles.pointContainer}>
            <Text style={{ marginHorizontal: 6, fontSize: "16px" }}>•</Text>
            <Text style={{ fontSize: 12 }}>
              State:{" "}
              {data?.userDetails?.state?.name
                ? data?.userDetails?.state?.name
                : "-"}
            </Text>
          </View>
          <View style={styles.pointContainer}>
            <Text style={{ marginHorizontal: 6, fontSize: "16px" }}>•</Text>
            <Text style={{ fontSize: 12 }}>
              Zip Code:{" "}
              {data?.userDetails?.zipcode ? data?.userDetails?.zipcode : "-"}
            </Text>
          </View>
          <View style={styles.pointContainer}>
            <Text style={{ marginHorizontal: 6, fontSize: "16px" }}>•</Text>
            <Text style={{ fontSize: 12 }}>
              Current Provider:{" "}
              {data?.userDetails?.admin
                ? data?.userDetails?.admin?.first_name +
                  " " +
                  data?.userDetails?.admin?.last_name
                : "-"}
            </Text>
          </View>
          <View style={styles.pointContainer}>
            <Text style={{ marginHorizontal: 6, fontSize: "16px" }}>•</Text>
            <Text style={{ fontSize: 12 }}>
              Group:{" "}
              {data?.userDetails?.group?.name
                ? data?.userDetails?.group?.name
                : "-"}
            </Text>
          </View>
        </View>
        <Text style={{ fontSize: 18, marginTop: 8 }}>Visit Details</Text>
        {!!data?.appointmentDetails?.length ? (
          data?.appointmentDetails.map(visit => (
            <View style={{ fontSize: 16 }}>
              <Text style={{ fontSize: 14, marginVertical: 16 }}>
                Visit {visit?.id} -{" "}
                {moment(visit?.appointment_date).format("MM/DD/YYYY")}
              </Text>
              <Text style={{ fontSize: 14, marginBottom: 6 }}>
                Billing Form
              </Text>
              <View style={styles.billPoints}>
                <Text style={{ marginHorizontal: 6, fontSize: "16px" }}>•</Text>
                <Text style={{ fontSize: 12 }}>
                  Insurance Carrier:{" "}
                  {visit?.visit_bill?.user_insurance?.insurance?.name
                    ? visit?.visit_bill?.user_insurance?.insurance?.name
                    : "-"}
                </Text>
              </View>
              <View style={styles.billPoints}>
                <Text style={{ marginHorizontal: 6, fontSize: "16px" }}>•</Text>
                <Text style={{ fontSize: 12 }}>
                  Member ID:{" "}
                  {visit?.visit_bill?.user_insurance?.member_id
                    ? visit?.visit_bill?.user_insurance?.member_id
                    : "-"}
                </Text>
              </View>
              <View style={styles.billPoints}>
                <Text style={{ marginHorizontal: 6, fontSize: "16px" }}>•</Text>
                <Text style={{ fontSize: 12 }}>
                  Group ID:{" "}
                  {visit?.visit_bill?.user_insurance?.group_id
                    ? visit?.visit_bill?.user_insurance?.group_id
                    : "-"}
                </Text>
              </View>
              <View style={styles.billPoints}>
                <Text style={{ marginHorizontal: 6, fontSize: "16px" }}>•</Text>
                <Text style={{ fontSize: 12 }}>
                  Service date:{" "}
                  {visit?.appointment_date
                    ? moment(visit?.appointment_date).format("MM/DD/YYYY")
                    : "-"}
                </Text>
              </View>
              <View style={styles.billPoints}>
                <Text style={{ marginHorizontal: 6, fontSize: "16px" }}>•</Text>
                <Text style={{ fontSize: 12 }}>
                  Service time:{" "}
                  {visit?.appointment_time
                    ? moment
                        .tz(
                          moment(
                            visit?.appointment_date +
                              " " +
                              visit?.appointment_time
                          ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                          moment.tz.guess()
                        )
                        .format("h:mm A")
                    : "-"}
                </Text>
              </View>
              <View style={styles.billPoints}>
                <Text style={{ marginHorizontal: 6, fontSize: "16px" }}>•</Text>
                <Text style={{ fontSize: 12 }}>
                  Created date:{" "}
                  {visit?.visit_bill?.created_at
                    ? visit?.visit_bill?.created_at
                    : "-"}
                </Text>
              </View>
              <View style={styles.billPoints}>
                <Text style={{ marginHorizontal: 6, fontSize: "16px" }}>•</Text>
                <Text style={{ fontSize: 12 }}>
                  Service start time:{" "}
                  {visit?.visit_bill?.service_start_time
                    ? moment
                        .tz(
                          moment(
                            visit?.appointment_date +
                              " " +
                              visit?.visit_bill?.service_start_time
                          ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                          moment.tz.guess()
                        )
                        .format("h:mm A")
                    : "-"}
                </Text>
              </View>
              <View style={styles.billPoints}>
                <Text style={{ marginHorizontal: 6, fontSize: "16px" }}>•</Text>
                <Text style={{ fontSize: 12 }}>
                  Service end time:{" "}
                  {visit?.visit_bill?.service_end_time
                    ? moment
                        .tz(
                          moment(
                            visit?.appointment_date +
                              " " +
                              visit?.visit_bill?.service_end_time
                          ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                          moment.tz.guess()
                        )
                        .format("h:mm A")
                    : "-"}
                </Text>
              </View>
              <View style={styles.billPoints}>
                <Text style={{ marginHorizontal: 6, fontSize: "16px" }}>•</Text>
                <Text style={{ fontSize: 12 }}>
                  Service Duration:{" "}
                  {visit?.visit_bill
                    ? diffMinutes(
                        moment(
                          visit?.appointment_date +
                            " " +
                            visit?.visit_bill?.service_start_time
                        ).format("HH:mm"),
                        moment(
                          visit?.appointment_date +
                            " " +
                            visit?.visit_bill?.service_end_time
                        ).format("HH:mm")
                      ) + " minutes"
                    : "-"}
                </Text>
              </View>
              <View style={styles.billPoints}>
                <Text style={{ marginHorizontal: 6, fontSize: "16px" }}>•</Text>
                <Text style={{ fontSize: 12 }}>
                  This unit is not billable:{" "}
                  {visit?.visit_bill?.billable_unit !== 0 ? "No" : "Yes"}
                </Text>
              </View>
              <View style={styles.billPoints}>
                <Text style={{ marginHorizontal: 6, fontSize: "16px" }}>•</Text>
                <Text style={{ fontSize: 12 }}>
                  Method of service:{" "}
                  {visit?.visit_bill?.method_of_services
                    ? visit?.visit_bill?.method_of_services
                    : "-"}
                </Text>
              </View>
              <View style={styles.billPoints}>
                <Text style={{ marginHorizontal: 6, fontSize: "16px" }}>•</Text>
                <Text style={{ fontSize: 12 }}>
                  Payment Method:{" "}
                  {visit?.visit_bill?.payment_method
                    ? visit?.visit_bill?.payment_method
                    : "-"}
                </Text>
              </View>
              <View style={styles.billPoints}>
                <Text style={{ marginHorizontal: 6, fontSize: "16px" }}>•</Text>
                <Text style={{ fontSize: 12 }}>
                  Billable units:{" "}
                  {visit?.visit_bill?.billable_unit
                    ? visit?.visit_bill?.billable_unit + " units"
                    : "0 units"}
                </Text>
              </View>
              <View style={styles.billPoints}>
                <Text style={{ marginHorizontal: 6, fontSize: "16px" }}>•</Text>
                <Text style={{ fontSize: 12 }}>
                  Service location:{" "}
                  {visit?.visit_bill?.service_location_detail?.name
                    ? visit?.visit_bill?.service_location_detail?.name
                    : "-"}
                </Text>
              </View>
              <View style={styles.billPoints}>
                <Text style={{ marginHorizontal: 6, fontSize: "16px" }}>•</Text>
                <Text style={{ fontSize: 12 }}>
                  Client state:{" "}
                  {visit?.visit_bill?.state?.name
                    ? visit?.visit_bill?.state?.name
                    : "-"}
                </Text>
              </View>
              <View style={styles.billPoints}>
                <Text style={{ marginHorizontal: 6, fontSize: "16px" }}>•</Text>
                <Text style={{ fontSize: 12 }}>
                  Notes for medical billers:{" "}
                  {visit?.visit_bill?.notes_for_medical_biller
                    ? visit?.visit_bill?.notes_for_medical_biller
                    : "-"}
                </Text>
              </View>
              {visit?.visit_bill?.payment_method &&
                visit?.visit_bill?.payment_method === "Insurance Funded" && (
                  <View>
                    <View style={styles.billPoints}>
                      <Text style={{ marginHorizontal: 6, fontSize: "16px" }}>
                        •
                      </Text>
                      <Text style={{ fontSize: 12 }}>
                        Type of Service:{" "}
                        {visit?.visit_bill?.type_of_service
                          ? visit?.visit_bill?.type_of_service
                          : "-"}
                      </Text>
                    </View>
                    <View style={styles.billPoints}>
                      <Text style={{ marginHorizontal: 6, fontSize: "16px" }}>
                        •
                      </Text>
                      <Text style={{ fontSize: 12 }}>
                        Primary diagnosis code:{" "}
                        {visit?.visit_bill?.primary_diagnosis &&
                        visit?.visit_bill?.primary_diagnosis?.name
                          ? visit?.visit_bill?.primary_diagnosis?.name
                          : "-"}
                      </Text>
                    </View>
                    <View style={styles.billPoints}>
                      <Text style={{ marginHorizontal: 6, fontSize: "16px" }}>
                        •
                      </Text>
                      <Text style={{ fontSize: 12 }}>
                        Other diagnosis code:{" "}
                        <Text
                          style={{
                            display: "flex",
                            flexDirection: "column"
                          }}
                        >
                          {visit?.visit_bill?.visit_bills_diagnoses.length
                            ? visit?.visit_bill?.visit_bills_diagnoses.map(
                                (dc, i) => (
                                  <View
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      marginBottom: 4,
                                      paddingLeft: 8
                                    }}
                                  >
                                    {i > 0 &&
                                      i <
                                        visit?.visit_bill?.visit_bills_diagnoses
                                          .length && (
                                        <Text
                                          style={{
                                            marginHorizontal: 8,
                                            fontSize: "12pt"
                                          }}
                                        >
                                          ,
                                        </Text>
                                      )}

                                    <Text style={{ fontSize: 12 }}>
                                      {dc?.diagnoses_code?.name}
                                    </Text>
                                  </View>
                                )
                              )
                            : "-"}
                        </Text>
                      </Text>
                    </View>
                    <View style={styles.billPoints}>
                      <Text style={{ marginHorizontal: 6, fontSize: "16px" }}>
                        •
                      </Text>
                      <Text style={{ fontSize: 12 }}>
                        CPT code:{" "}
                        {visit?.visit_bill?.cpt_code?.name
                          ? visit?.visit_bill?.cpt_code?.name
                          : "-"}
                      </Text>
                    </View>
                    <View style={styles.billPoints}>
                      <Text style={{ marginHorizontal: 6, fontSize: "16px" }}>
                        •
                      </Text>
                      <Text style={{ fontSize: 12 }}>
                        Where is client located:{" "}
                        {visit?.visit_bill?.client_pos
                          ? visit?.visit_bill?.client_pos
                          : "-"}
                      </Text>
                    </View>
                  </View>
                )}
              <Text style={{ fontSize: 14, marginVertical: 8 }}>
                Visit Notes
              </Text>
              {visit?.visit_notes?.length ? (
                visit?.visit_notes?.map(notes => (
                  <View style={styles.visitPoints}>
                    <Text
                      style={{
                        marginHorizontal: 6,
                        fontSize: "16px"
                      }}
                    >
                      •
                    </Text>
                    <Text style={{ fontSize: 12, marginTop: 2 }}>
                      {notes?.created_at + " - " + notes?.visit_note}
                    </Text>
                  </View>
                ))
              ) : (
                <Text style={{ fontSize: 12, marginBottom: 10 }}>
                  No Visit Notes
                </Text>
              )}
            </View>
          ))
        ) : (
          <Text style={{ fontSize: 14, marginVertical: 8 }}>No Visits</Text>
        )}
        <View
          fixed
          style={{
            position: "absolute",
            bottom: 10,
            left: 0,
            right: 0
          }}
        >
          <Text style={styles.footer}>
            Generated from HUSK EHR System on {moment().format("MM/DD/YYYY")}{" "}
            {moment().format("LT")} by {data?.adminDetails?.first_name}{" "}
            {data?.adminDetails?.last_name}. This document is intended for the
            sole use of the individual or entity to whom it is addressed. If you
            are not the intended recipient, you are notified that any use,
            dissemination, distribution, printing or copying of this document is
            strictly prohibited. This document may contain information that is
            proprietary, privileged, confidential and/or exempt from disclosure
            under applicable law.
          </Text>
        </View>
      </Page>
    </Document>
  );
};
