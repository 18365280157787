import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  // validationSchema: Yup.object().shape({
  //     insurance: Yup.array().of()
  // }),
  mapPropsToValues: props => ({
    insurance: []
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
