import React, { useRef, useEffect, useState, Fragment } from "react";
import { compose } from "redux";
import enhancer from "./addEditAppointmentvalidator";
import Button from "../button/Button";
import Toaster from "../common/Toaster";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import VisitService from "../../api/VisitService";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import {
  APPOINTMENT_STATUS,
  APPOINTMENT_STATUS_LABEL,
  SERVICE_TYPE
} from "../../helper/constant";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Input
} from "reactstrap";
import CategoryServices from "api/CategoryServices";
import { convertToFixedOffset } from "components/roeeditor/utils";

const AddVisitNote = props => {
  const hasParam = props.match.params.id;

  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    submitCount,
    isValid,
    handleSubmit
  } = props;
  const toaster = useRef(null);

  const [loading, setloading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [endTime, setEndTime] = useState(
    new Date().setHours(new Date().getHours() + 1)
  );
  const [statusOption, setStatusOption] = useState([]);
  const [statusLabel, setStatusLabel] = useState(null);
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [serviceList, setServiceList] = useState([]);
  const toggle = () => setStatusDropdownOpen(prevState => !prevState);

  useEffect(() => {
    if (props?.auth?.admin_role_id <= 2) {
      CategoryServices.serviceDropdown()
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          if (data.status) {
            setServiceList([...data.data]);
          } else {
            if (toaster.current)
              toaster.current.error("Could not fetch services!");
          }
        })
        .catch(err => {
          if (err?.response?.status === 403) {
            if (toaster.current) {
              toaster.current.error("You cannot access this user info!");
              setTimeout(() => {
                props.history.push("/client");
              }, 3000);
            }
          } else {
            if (toaster.current)
              toaster.current.error("Could not fetch services!");
          }
        });
    }
  }, [props?.auth]);

  useEffect(() => {
    if (props.editedData) {
      setStartDate(
        new Date(
          convertToFixedOffset(
            moment(
              props.editedData.appointment_date +
                " " +
                props.editedData.appointment_time
            ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            moment.tz.guess(),
            "YYYY/MM/DD HH:mm:ss"
          )
        )
      );
      setStartTime(
        new Date(
          convertToFixedOffset(
            moment(
              props.editedData.appointment_date +
                " " +
                props.editedData.appointment_time
            ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            moment.tz.guess(),
            "YYYY/MM/DD HH:mm:ss"
          )
        )
      );

      setEndDate(
        new Date(
          convertToFixedOffset(
            moment(
              props.editedData.appointment_end_date +
                " " +
                props.editedData.appointment_end_time
            ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            moment.tz.guess(),
            "YYYY/MM/DD HH:mm:ss"
          )
        )
      );
      setEndTime(
        new Date(
          convertToFixedOffset(
            moment(
              props.editedData.appointment_end_date +
                " " +
                props.editedData.appointment_end_time
            ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            moment.tz.guess(),
            "YYYY/MM/DD HH:mm:ss"
          )
        )
      );

      setStatusLabel(props.editedData.status);

      checkDateValidation(
        props?.editedData?.appointment_date,
        props?.editedData?.appointment_time,
        props?.editedData?.appointment_end_date,
        props?.editedData?.appointment_end_time
      );

      let status = [];

      switch (props.editedData.status) {
        case APPOINTMENT_STATUS.SCHEDULE:
          status = [
            {
              value: APPOINTMENT_STATUS.CANCELLED,
              label: "Cancelled"
            },
            {
              value: APPOINTMENT_STATUS.SCHEDULE,
              label: "Schedule"
            }
          ];
          break;
        case APPOINTMENT_STATUS.PENDING:
          status = [
            {
              value: APPOINTMENT_STATUS.CANCELLED,
              label: "Cancelled"
            },
            {
              value: APPOINTMENT_STATUS.LATE_CANCELLED,
              label: "Late Cancel"
            },
            {
              value: APPOINTMENT_STATUS.NO_SHOW,
              label: "No Show"
            },
            {
              value: APPOINTMENT_STATUS.PENDING,
              label: "Pending"
            }
          ];
          break;
        case APPOINTMENT_STATUS.CANCELLED:
          status = [
            {
              value: APPOINTMENT_STATUS.CANCELLED,
              label: "Cancelled"
            },
            {
              value: APPOINTMENT_STATUS.LATE_CANCELLED,
              label: "Late Cancel"
            },
            {
              value: APPOINTMENT_STATUS.NO_SHOW,
              label: "No Show"
            },
            {
              value: APPOINTMENT_STATUS.PENDING,
              label: "Pending"
            }
          ];
          break;
        case APPOINTMENT_STATUS.NO_SHOW:
          status = [
            {
              value: APPOINTMENT_STATUS.CANCELLED,
              label: "Cancelled"
            },
            {
              value: APPOINTMENT_STATUS.LATE_CANCELLED,
              label: "Late Cancel"
            },
            {
              value: APPOINTMENT_STATUS.NO_SHOW,
              label: "No Show"
            },
            {
              value: APPOINTMENT_STATUS.PENDING,
              label: "Pending"
            }
          ];
          break;
        case APPOINTMENT_STATUS.LATE_CANCELLED:
          status = [
            {
              value: APPOINTMENT_STATUS.CANCELLED,
              label: "Cancelled"
            },
            {
              value: APPOINTMENT_STATUS.LATE_CANCELLED,
              label: "Late Cancel"
            },
            {
              value: APPOINTMENT_STATUS.NO_SHOW,
              label: "No Show"
            },
            {
              value: APPOINTMENT_STATUS.PENDING,
              label: "Pending"
            }
          ];
          break;

        case APPOINTMENT_STATUS.COMPLETED:
          status = [
            {
              value: APPOINTMENT_STATUS.COMPLETED,
              label: "Completed"
            }
          ];
          break;

        default:
          break;
      }

      if (
        props.editedData.service_id === SERVICE_TYPE.MENTAL_HEALTH &&
        props.editedData.status === APPOINTMENT_STATUS.PENDING
      ) {
        status.push({
          value: APPOINTMENT_STATUS.COMPLETED,
          label: "Completed"
        });
      }
      setStatusOption([...status]);
    } else {
      props.setFieldValue("appointment_date", startDate);
      props.setFieldValue("appointment_time", startTime);
      props.setFieldValue("appointment_end_date", endDate);
      props.setFieldValue("appointment_end_time", endTime);
      props.setFieldValue("isAppointmentValid", true);
    }
  }, []);

  const checkDateValidation = (sDate, sTime, eDate, eTime) => {
    if (
      moment(
        moment(sDate).format("YYYY-MM-DD") +
          " " +
          moment(sTime).format("HH:mm:ss")
      ).utc() >=
      moment(
        moment(eDate).format("YYYY-MM-DD") +
          " " +
          moment(eTime).format("HH:mm:ss")
      ).utc()
    ) {
      props.setFieldValue("isAppointmentValid", false);
    } else {
      props.setFieldValue("isAppointmentValid", true);
    }
  };

  const handleNote = () => {
    if (!props?.values?.service && props?.auth?.admin_role_id <= 2) return;
    if (isValid && !!props?.values?.isAppointmentValid) {
      if (props.editedData) {
        setloading(true);
        let editNote = {
          appointment_date: moment(
            moment(startDate).format("YYYY-MM-DD") +
              " " +
              moment(startTime).format("HH:mm:ss")
          )
            .utc()
            .format("YYYY-MM-DD"),
          appointment_time: moment(
            moment(startDate).format("YYYY-MM-DD") +
              " " +
              moment(startTime).format("HH:mm:ss")
          )
            .utc()
            .format("HH:mm:ss"),
          appointment_end_date: moment(
            moment(endDate).format("YYYY-MM-DD") +
              " " +
              moment(endTime).format("HH:mm:ss")
          )
            .utc()
            .format("YYYY-MM-DD"),
          appointment_end_time: moment(
            moment(endDate).format("YYYY-MM-DD") +
              " " +
              moment(endTime).format("HH:mm:ss")
          )
            .utc()
            .format("HH:mm:ss"),
          appointment_id: props.editedData.id,
          timezone: moment.tz.guess(),
          appointment_status: statusLabel,
          service_id:
            props?.auth?.admin_role_id <= 2
              ? values?.service
              : props?.auth?.service_id
        };

        VisitService.editVisit(editNote)
          .then(result => {
            const data = EncryptDecrypt.decrypt(result.data);

            if (data.status) {
              props.pageRefresh();
              props.toggle();
            } else {
              if (toaster.current) toaster.current.error(data.message);
            }
            setloading(false);
          })
          .catch(error => {
            if (toaster.current) toaster.current.error(error.message);
            setloading(false);
          });
      } else {
        setloading(true);
        let editNote = {
          appointment_date: moment(
            moment(startDate).format("YYYY-MM-DD") +
              " " +
              moment(startTime).format("HH:mm:ss")
          )
            .utc()
            .format("YYYY-MM-DD"),
          appointment_time: moment(
            moment(startDate).format("YYYY-MM-DD") +
              " " +
              moment(startTime).format("HH:mm:ss")
          )
            .utc()
            .format("HH:mm:ss"),
          appointment_end_date: moment(
            moment(endDate).format("YYYY-MM-DD") +
              " " +
              moment(endTime).format("HH:mm:ss")
          )
            .utc()
            .format("YYYY-MM-DD"),
          appointment_end_time: moment(
            moment(endDate).format("YYYY-MM-DD") +
              " " +
              moment(endTime).format("HH:mm:ss")
          )
            .utc()
            .format("HH:mm:ss"),

          // visit_notes: values.visit_notes,
          user_id: hasParam,
          timezone: moment.tz.guess(),
          service_id:
            props?.auth?.admin_role_id <= 2
              ? values?.service
              : props?.auth?.service_id
        };

        VisitService.addVisit(editNote)

          .then(result => {
            const data = EncryptDecrypt.decrypt(result.data);

            if (data.status) {
              props.pageRefresh();
              props.toggle();
            } else {
              if (toaster.current) toaster.current.error(data.message);
            }
            setloading(false);
          })
          .catch(err => {
            if (err?.response?.status === 403) {
              if (toaster.current) {
                toaster.current.error("You cannot access this user info!");
                setTimeout(() => {
                  props.history.push("/client");
                }, 3000);
              }
            } else {
              if (toaster.current) toaster.current.error(err?.message);
            }
            setloading(false);
          });
      }
    }
    handleSubmit();
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };
  return (
    <div>
      <form className="pa-24">
        <div className="flex flex-x">
          <div className="flex flex-1 flex-y">
            <label className="font-bold">
              Appointment Start Date and Time:
            </label>
            <div className="form-group">
              <label>Date:</label>
              <div className="roe-card-body">
                <DatePicker
                  selected={startDate}
                  onChange={date => {
                    setStartDate(date);
                    setFieldValue("appointment_date", date);
                    checkDateValidation(date, startTime, endDate, endTime);
                  }}
                  name="appointment_date"
                  className="custom-datepicker form-control react-form-input"
                  calendarClassName="custom-calender-class"
                  selectsStart
                />
              </div>
              <Error field="appointment_date" />
            </div>

            <div className="form-group">
              <label>Time:</label>
              <div className="roe-card-body">
                <DatePicker
                  showTime={{ user12hours: true }}
                  selected={startTime}
                  onChange={date => {
                    setStartTime(date);
                    setFieldValue("appointment_time", date);
                    checkDateValidation(startDate, date, endDate, endTime);
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  dateFormat="hh:mm a"
                  timeFormat="hh:mm a"
                  timeCaption="Time"
                  className="custom-datepicker form-control react-form-input"
                  calendarClassName="custom-calender-class"
                  name="appointment_time"
                />
              </div>
              <Error field="appointment_time" />
            </div>
          </div>
          <div className="flex flex-1 flex-y">
            <label className="font-bold">Appointment End Date and Time:</label>
            <div className="form-group">
              <label>Date:</label>
              <div className="roe-card-body">
                <DatePicker
                  selected={endDate}
                  onChange={date => {
                    setEndDate(date);
                    setFieldValue("appointment_end_date", date);
                    checkDateValidation(startDate, startTime, date, endTime);
                  }}
                  className="custom-datepicker form-control react-form-input"
                  calendarClassName="custom-calender-class"
                  selectsStart
                  name="appointment_end_date"
                />
              </div>
              <Error field="appointment_end_date" />
            </div>

            <div className="form-group">
              <label>Time:</label>
              <div className="roe-card-body">
                <DatePicker
                  showTime={{ user12hours: true }}
                  selected={endTime}
                  onChange={date => {
                    setEndTime(date);
                    setFieldValue("appointment_end_time", date);
                    checkDateValidation(startDate, startTime, endDate, date);
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  dateFormat="hh:mm a"
                  timeFormat="hh:mm a"
                  timeCaption="Time"
                  className="custom-datepicker form-control react-form-input"
                  calendarClassName="custom-calender-class"
                  name="appointment_end_time"
                />
              </div>
              <Error field="appointment_end_time" />
            </div>
          </div>
        </div>

        {props.editedData && (
          <div className="form-group ">
            <label>Appointment Status:</label>
            <Dropdown isOpen={statusDropdownOpen} toggle={toggle}>
              <DropdownToggle caret size="md">
                {statusLabel
                  ? APPOINTMENT_STATUS_LABEL[statusLabel]
                  : "Select Status"}
              </DropdownToggle>
              <DropdownMenu>
                {statusOption.map((result, i) => {
                  return (
                    <DropdownItem
                      key={`payment_${i}`}
                      onClick={() => {
                        setStatusLabel(result.value);
                      }}
                    >
                      {result.label}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
            <Error field="appointment_status" />
          </div>
        )}
        {props?.auth?.admin_role_id <= 2 && (
          <div className="form-group wa-20">
            <label className="fs-16 medium-text">
              Service <span className="asterisk"> *</span>
            </label>
            <Input
              type="select"
              name="service"
              id="service"
              onChange={handleChange}
              value={values.service}
              onBlur={handleBlur}
            >
              <option value="">Select Service</option>
              {serviceList.map((service, i) => {
                return (
                  <Fragment>
                    <option key={`option${i}`} value={service.id}>
                      {service.name}
                    </option>
                  </Fragment>
                );
              })}
            </Input>
          </div>
        )}

        {!props.editedData &&
          !props?.values?.service &&
          props?.auth?.admin_role_id <= 2 && (
            <div className="error-msg mb-10">Service is required.</div>
          )}
        {!props?.values?.isAppointmentValid && (
          <div className="error-msg mtb-10">
            Appointment End Time should be greater than Appointment Start Time
          </div>
        )}

        <div>
          <Button
            type="button"
            className="c-btn c-info form-button fs-16 demi-bold-text mr-10"
            style={{ maxWidth: "125px" }}
            onClick={() => handleNote()}
            disabled={loading}
            // disabled={
            //   !props?.values?.isAppointmentValid ||
            //   (!props?.values?.service && props?.auth?.admin_role_id <= 2) ||
            //   false
            // }
          >
            Submit
          </Button>
          <Button
            type="button"
            className="c-btn c-secondary form-button fs-16 demi-bold-text"
            style={{ maxWidth: "125px" }}
            onClick={props.toggle}
            disabled={loading}
          >
            Close
          </Button>
        </div>
      </form>
      <Toaster ref={toaster} />
    </div>
  );
};

export default compose(enhancer)(AddVisitNote);
