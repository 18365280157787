import React, { useRef, useState } from "react";
import { compose } from "redux";
import enhancer from "./validator";
import ExternalProviderServices from "api/ExternalProviderServices";
import Toaster from "components/common/Toaster";
import EncryptDecrypt from "api/EncryptDecrypt";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Button from "components/button/Button";

const EditExternalProvider = props => {
  const [isLoading, setIsLoading] = useState(false);
  const toaster = useRef();
  const history = useHistory();
  const {
    handleChange,
    handleBlur,
    submitCount,
    errors,
    values,
    touched,
    setFieldValue
  } = props;

  const changePhoneFormat = e => {
    var x = e.target.value
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    let no = "(" + x[1] + ") " + x[2] + "-" + x[3];
    setFieldValue("other_phone_number", no);
  };

  const changeFaxFormat = e => {
    var x = e.target.value
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    let no = "(" + x[1] + ") " + x[2] + "-" + x[3];
    setFieldValue("other_fax_number", no);
  };

  const handleEdit = () => {
    let { values, isValid, handleSubmit } = props;

    if (isValid) {
      setIsLoading(true);
      let editObj = {
        rd_id: values.id,
        other_fax_number: values.other_fax_number,
        other_phone_number: values.other_phone_number
      };

      ExternalProviderServices.edit(editObj)
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          if (data.status) {
            if (toaster.current) toaster.current.success(data.message);
            setTimeout(() => {
              history.push(`/external-provider`);
            }, 500);
          } else {
            if (toaster.current) toaster.current.error(data.message);
          }
          setIsLoading(false);
        })
        .catch(err => {
          if (toaster.current) toaster.current.error(err.response);
        });
    }

    handleSubmit();
  };
  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <div className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </div>
      );
    } else {
      return <span />;
    }
  };

  return (
    <div>
      <form className="pa-24 row">
        <div className="plr-15">
          <div className="roe-card-style mtb-15">
            <div className="roe-card-header module-header">
              <div className="flex-1 fs-16 demi-bold-text">
                <span className="hash"># </span>
                Edit External Provider Details
              </div>
            </div>

            <div className="roe-card-body">
              <form>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label className="fs-16 medium-text">ID</label>
                    <input
                      type="text"
                      className="form-control react-form-input"
                      id="id"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.id}
                      disabled
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="fs-16 medium-text">NPI</label>
                    <input
                      type="text"
                      className="form-control react-form-input"
                      id="provider_npi"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.provider_npi}
                      disabled
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="fs-16 medium-text">First Name</label>
                    <input
                      type="text"
                      className="form-control react-form-input"
                      id="first_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.first_name}
                      disabled
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="fs-16 medium-text">Last Name</label>
                    <input
                      type="text"
                      className="form-control react-form-input"
                      id="last_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.last_name}
                      disabled
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="fs-16 medium-text">Credential</label>
                    <input
                      type="text"
                      className="form-control react-form-input"
                      id="taxonomy"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.credential}
                      disabled
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="fs-16 medium-text">Address</label>
                    <input
                      type="text"
                      className="form-control react-form-input"
                      id="address"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address}
                      disabled
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="fs-16 medium-text">City</label>
                    <input
                      type="text"
                      className="form-control react-form-input"
                      id="city"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.city}
                      disabled
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="fs-16 medium-text">State</label>
                    <input
                      type="text"
                      className="form-control react-form-input"
                      id="State"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.state}
                      disabled
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="fs-16 medium-text">Zip</label>
                    <input
                      type="text"
                      className="form-control react-form-input"
                      id="Zip"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.zip}
                      disabled
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="fs-16 medium-text">Category</label>
                    <input
                      type="text"
                      className="form-control react-form-input"
                      id="taxonomy"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.taxonomy}
                      disabled
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="fs-16 medium-text">Status</label>
                    <input
                      type="text"
                      className="form-control react-form-input"
                      id="Status"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.status}
                      disabled
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="fs-16 medium-text">Phone number</label>
                    <input
                      type="text"
                      className="form-control react-form-input"
                      id="phone_number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone_number}
                      disabled
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="fs-16 medium-text">Fax Number</label>
                    <input
                      type="text"
                      className="form-control react-form-input"
                      id="fax_number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.fax_number}
                      disabled
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="fs-16 medium-text">
                      Other Phone Number
                    </label>
                    <input
                      type="text"
                      className="form-control react-form-input"
                      id="other_phone_number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={
                        values.other_phone_number
                          ? values.other_phone_number
                          : ""
                      }
                      onKeyUp={value => changePhoneFormat(value)}
                    />
                    <Error field="other_phone_number" />
                  </div>

                  <div className="form-group col-md-6">
                    <label className="fs-16 medium-text">
                      Other Fax Number
                    </label>
                    <input
                      type="text"
                      className="form-control react-form-input"
                      id="other_fax_number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={
                        values.other_fax_number ? values.other_fax_number : ""
                      }
                      onKeyUp={value => changeFaxFormat(value)}
                    />
                    <Error field="other_fax_number" />
                  </div>
                </div>
                <Button
                  type="button"
                  className="c-btn c-info form-button fs-16 demi-bold-text mr-10"
                  style={{ maxWidth: "200px" }}
                  onClick={() => {
                    handleEdit();
                  }}
                  loading={isLoading}
                  disabled={isLoading}
                  dataStyle={"expand-right"}
                >
                  Submit
                </Button>
              </form>
            </div>
          </div>
        </div>
        <Toaster ref={toaster} />
      </form>
    </div>
  );
};
export default compose(enhancer)(EditExternalProvider);
