import axios from "axios";
import { baseUrl } from "../helper/constant";
import Header from "./Header";
import EncryptDecrypt from "./EncryptDecrypt";

export default class EfaxManagementServices {
  static list(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/fax-inbox/list`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static edit(params) {
    Header.setHeaders();
    return axios.put(
      `${baseUrl}/fax-inbox/edit`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static updateStatus(params) {
    Header.setHeaders();
    return axios.put(
      `${baseUrl}/fax-inbox/update-status`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static download(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/fax-inbox/download-attachment`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static view(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/fax-inbox/view-fax`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static clientList(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/fax-inbox/client-list`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static attachDocument(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/fax-inbox/assign-attachment`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static outboxList(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/fax-outbox/list`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static editOutboxFax(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/fax-outbox/add-edit-notes`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static externalProviderList(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/fax-outbox/external-rd-list`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static addExternalProvider(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/fax-outbox/add`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static sendFax(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/fax-outbox/send`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static viewFaxNote(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/fax-outbox/view`,
      EncryptDecrypt.encrypt(params)
    );
  }
}
