import React, { useRef, useState } from "react";
import { compose } from "redux";
import enhancer from "./noteValidator";
import Button from "../button/Button";
import EncryptDecrypt from "api/EncryptDecrypt";
import Toaster from "components/common/Toaster";
import ClientServices from "api/ClientServices";

const ArchievedNoteModal = props => {
  const toaster = useRef(null);
  const [loading, setLoading] = useState(false);

  const {
    handleChange,
    handleBlur,
    submitCount,
    errors,
    values,
    touched,
    editedData
  } = props;

  const handleEdit = () => {
    let { values, isValid, handleSubmit } = props;
    if (isValid) {
      setLoading(true);
      let params = {
        user_id: editedData.id,
        note: values.notes
      };
      ClientServices.editDiscoveryQueueNote(params)
        .then(result => {
          const res = EncryptDecrypt.decrypt(result.data);
          if (res.status) {
            props.toggle();
            props.pageRefresh();
          } else {
            if (toaster.current) toaster.current.error(res.message);
          }
          setLoading(false);
        })
        .catch(err => {
          if (toaster.current) toaster.current.error(err.message);
          setLoading(false);
        });
    }

    handleSubmit();
  };
  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <div className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </div>
      );
    } else {
      return <span />;
    }
  };

  return (
    <div>
      <form className="pa-24 row">
        <div className="form-group col-md-12">
          <label className="fs-16 medium-text">Note:</label>
          <textarea
            className="form-control react-form-input"
            rows="3"
            placeholder="Add Note"
            id="notes"
            name="notes"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.notes}
          ></textarea>
          <Error field="notes" />
        </div>
        <div className="col-12 text-center">
          <Button
            type="button"
            className="c-btn c-secondary form-button fs-16 demi-bold-text mr-10 max-w-200"
            onClick={props.toggle}
          >
            Cancel
          </Button>
          <Button
            type="button"
            loading={loading}
            disabled={loading}
            className="c-btn c-info form-button fs-16 demi-bold-text max-w-200"
            onClick={handleEdit}
            dataStyle="expand-right"
          >
            Save
          </Button>
        </div>
      </form>
      <Toaster ref={toaster} />
    </div>
  );
};
export default compose(enhancer)(ArchievedNoteModal);
