import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import SubAdminServices from "../../api/SubAdminServices";
import Loader from "../common/Loader";
import CommonModel from "../common/CommonModel";
import DeleteModal from "../common/DeleteModal";
import CustomModal from "../common/CustomModal";
import Toaster from "../common/Toaster";
import { MODULE_NAMES, PERMISSION_TYPE, TABLE } from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import { PLACEHOLDER } from "helper/constant";
import moment from "moment";
import { hasAccess } from "util/sidebar";

const SubAdminTable = props => {
  const toaster = useRef(null);
  const { permissions } = props;
  const [tblData, settblData] = useState();
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("id");
  const [sortBy, setSortBy] = useState("ASC");
  const [id, setId] = useState(undefined);
  const [modal, setModal] = useState(undefined);
  const [status, setStatus] = useState(undefined);
  const [customModal, setCustomModal] = useState(undefined);
  const [blockStatus, setBlockStatus] = useState(undefined);
  const [blockCustomModal, setBlockCustomModal] = useState(undefined);

  useEffect(() => {
    getList(field, sortBy, searchText, activePage + 1);
    setPages(10);
  }, []);

  const deleteClick = data => {
    setId(data.id);
    setModal(true);
  };

  function deleteHandler() {
    setLoader(true);
    const params = {
      id: id
    };
    SubAdminServices.delete(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current) toaster.current.success(data.message);

          setModal(false);
          setTimeout(() => {
            getList(field, sortBy, searchText, activePage + 1, 1);
          }, 1000);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
        setLoader(false);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
        setLoader(false);
      });
  }

  function toggle() {
    setModal(false);
  }

  const activeDeactive = data => {
    setId(data.id);
    setStatus(data.status);
    setCustomModal(true);
  };

  function activeToggle() {
    setCustomModal(false);
  }

  const activeInactiveStatusHandler = oData => {
    let statusData = status;
    const params = {
      is_active: statusData === 1 ? 4 : 1,
      id: id
    };
    setLoader(true);
    SubAdminServices.status(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current)
            toaster.current.success(
              statusData === 1
                ? "Admin has been deactivated successfully."
                : "Admin has been activated successfully."
            );
          setCustomModal(false);
          getList(field, sortBy, searchText, activePage + 1);
        } else {
          setCustomModal(false);
          if (toaster.current) toaster.current.error(data.message);
        }
        setLoader(false);
      })
      .catch(err => {
        setCustomModal(false);
        if (toaster.current) toaster.current.error(err.response);
        setLoader(false);
      });
  };

  const blockUnblock = data => {
    setId(data.id);
    setBlockStatus(data.is_block);
    setBlockCustomModal(true);
  };

  function blockToggle() {
    setBlockCustomModal(false);
  }

  const blockUnblockStatusHandler = oData => {
    let statusData = blockStatus;
    const params = {
      is_block: !statusData,
      id: id
    };
    setLoader(true);
    SubAdminServices.blockStatus(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current)
            toaster.current.success(
              statusData
                ? "Admin has been unblocked successfully."
                : "Admin has been blocked successfully."
            );
          setBlockCustomModal(false);
          getList(field, sortBy, searchText, activePage + 1);
          setLoader(false);
        } else {
          setBlockCustomModal(false);
          if (toaster.current) toaster.current.error(data.message);
          setLoader(false);
        }
      })
      .catch(err => {
        setBlockCustomModal(false);
        if (toaster.current) toaster.current.error(err.response);
        setLoader(false);
      });
  };

  const columns = [
    {
      Header: "ID",
      accessor: "id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 100,
      sortable: true
    },
    {
      Header: "Image",
      accessor: "profile_image",
      className: "",
      filterable: false,
      sortable: false,
      width: 100,
      headerClassName: "react-table-header-class",
      Cell: props => (
        <div>
          <img
            src={props.original.profile_image || PLACEHOLDER}
            alt="error"
            style={{ height: "35px", width: "35px", borderRadius: "50%" }}
          />
        </div>
      )
    },
    {
      Header: "First Name",
      accessor: "first_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 200,
      sortable: true
    },
    {
      Header: "Last Name",
      accessor: "last_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 200,
      sortable: true
    },
    {
      Header: "Email",
      accessor: "email",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 250,
      sortable: true
    },
    {
      Header: "Role",
      accessor: "admin_role.role",
      className: "",
      filterable: false,
      width: 200,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true
    },
    {
      Header: "Date Last Accessed",
      accessor: "last_accessed",
      className: "",
      filterable: false,
      width: 200,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      Cell: props =>
        props.original.last_accessed
          ? moment(props.original.last_accessed).format("MM/DD/YYYY")
          : ""
    },
    {
      Header: "Status",
      accessor: "status",
      className: "",
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      filterable: false,
      width: 200,
      Cell: props => (
        <div className="react-action-class">
          {props.original.status === 1 ? (
            <div id={`active-${props.original.state_id}`}>
              <Button
                style={{ minWidth: "125px" }}
                className="c-btn c-info mr-10"
                onClick={() => activeDeactive(props.original)}
                disabled={
                  !hasAccess(
                    MODULE_NAMES.ADMIN,
                    PERMISSION_TYPE.STATUS,
                    permissions
                  )
                }
              >
                <div className="fs-14 medium-text">
                  <i className="fas fa-toggle-off mr-6" /> Active
                </div>
              </Button>
            </div>
          ) : (
            <div id={`deactive-${props.original.state_id}`}>
              <Button
                style={{ minWidth: "125px" }}
                className="c-btn c-warning mr-10"
                onClick={() => activeDeactive(props.original)}
                disabled={
                  !hasAccess(
                    MODULE_NAMES.ADMIN,
                    PERMISSION_TYPE.STATUS,
                    permissions
                  )
                }
              >
                <div className="fs-14 medium-text">
                  <i className="fas fa-toggle-on mr-6" /> Inactive
                </div>
              </Button>
            </div>
          )}
        </div>
      ) // Custom cell components!
    },
    {
      Header: "Block/Unblock",
      accessor: "is_block",
      className: "",
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      filterable: false,
      width: 200,
      Cell: props => (
        <div className="react-action-class">
          {props.original.is_block ? (
            <div id={`active-${props.original.state_id}`}>
              <Button
                style={{ minWidth: "125px" }}
                className="c-btn c-warning mr-10"
                onClick={() => blockUnblock(props.original)}
                disabled={
                  !hasAccess(
                    MODULE_NAMES.ADMIN,
                    PERMISSION_TYPE.STATUS,
                    permissions
                  )
                }
              >
                <div className="fs-14 medium-text">
                  <i className="fas fa-toggle-off mr-6" /> Blocked
                </div>
              </Button>
            </div>
          ) : (
            <div id={`deactive-${props.original.state_id}`}>
              <Button
                style={{ minWidth: "125px" }}
                className="c-btn c-info mr-10"
                onClick={() => blockUnblock(props.original)}
                disabled={
                  !hasAccess(
                    MODULE_NAMES.ADMIN,
                    PERMISSION_TYPE.STATUS,
                    permissions
                  )
                }
              >
                <div className="fs-14 medium-text">
                  <i className="fas fa-toggle-on mr-6" /> Unblocked
                </div>
              </Button>
            </div>
          )}
        </div>
      ) // Custom cell components!
    },
    {
      Header: "Action",
      accessor: "action",
      className: "",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 180,
      Cell: props => (
        <div className="react-action-class">
          {hasAccess(MODULE_NAMES.ADMIN, PERMISSION_TYPE.VIEW, permissions) ? (
            <Button
              className="c-btn c-info mr-10"
              onClick={() => formAction("view", props.original)}
            >
              <div className="fs-14 medium-text" title="View">
                <i className="fas fa-eye" />
              </div>
            </Button>
          ) : null}
          {hasAccess(MODULE_NAMES.ADMIN, PERMISSION_TYPE.EDIT, permissions) ? (
            <Button
              className="c-btn c-success mr-10"
              onClick={() => formAction("edit", props.original)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-edit" />
              </div>
            </Button>
          ) : null}
          {hasAccess(
            MODULE_NAMES.ADMIN,
            PERMISSION_TYPE.DELETE,
            permissions
          ) ? (
            <Button
              className="c-btn c-danger"
              onClick={() => deleteClick(props.original)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-trash" />
              </div>
            </Button>
          ) : null}
        </div>
      ) // Custom cell components!
    }
  ];

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  const formAction = (action, data = null) => {
    // Here you can view the data and make forward action for edit data
    if (action === "add") {
      props.history.push("/sub-admin/add");
    } else if (action === "edit") {
      props.history.push(`/sub-admin/edit/${data.id}`);
    } else if (action === "view") {
      props.history.push(`/sub-admin/view/${data.id}`, { ...data });
    }
  };

  function getList(
    field = "created_at",
    sortBy = "DESC",
    search = "",
    page = "1"
  ) {
    const params = {
      sort_by: field,
      order: sortBy,
      search: search,
      page: page
    };
    setLoader(true);
    SubAdminServices.list(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count / TABLE.LIMIT));
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });
  }

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Admin List
            </div>
            <div className="mr-10">
              <input
                value={searchText}
                onChange={e => {
                  setSearchText(e.target.value);
                  const search = e.target.value;
                  setLoader(true);
                  setActivePage(0);
                  setTimeout(() => {
                    getList(field, sortBy, search, 1);
                  }, 500);
                }}
                type="text"
                placeholder="Search..."
                className="fs-14 medium-text plr-10 form-control react-form-input"
              />
            </div>
            {hasAccess(MODULE_NAMES.ADMIN, PERMISSION_TYPE.ADD, permissions) ? (
              <div>
                <Button
                  className="c-btn c-primary ma-5"
                  onClick={() => formAction("add")}
                >
                  <i className="fas fa-plus mr-10" />
                  Add Admin
                </Button>
              </div>
            ) : null}
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              LoadingComponent={Loader}
              onFetchData={onChangePageGetData}
              onPageChange={pageIndex => {
                setActivePage(pageIndex);
                getList(field, sortBy, searchText, pageIndex + 1);
              }}
              onSortedChange={sortProperties => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getList(sortProperties[0].id, sort, searchText, 1);
              }}
            />
          </div>
          <CommonModel
            modal={modal}
            toggle={toggle}
            children={
              <DeleteModal
                text={"Are you sure you want to delete this admin?"}
                deleteHandler={deleteHandler}
                toggle={toggle}
              />
            }
          />
          <CommonModel
            modal={customModal}
            toggle={activeToggle}
            children={
              <CustomModal
                text={`${
                  status !== 1
                    ? "Are you sure you want to activate admin?"
                    : "Are you sure you want to deactivate admin?"
                }`}
                activeInactiveStatusHandler={activeInactiveStatusHandler}
                activeToggle={activeToggle}
              />
            }
          />
          <CommonModel
            modal={blockCustomModal}
            toggle={blockToggle}
            children={
              <CustomModal
                text={`${
                  blockStatus
                    ? "Are you sure you want to unblock admin?"
                    : "Are you sure you want to block admin?"
                }`}
                activeInactiveStatusHandler={blockUnblockStatusHandler}
                activeToggle={blockToggle}
              />
            }
          />
        </div>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};

export default withRouter(SubAdminTable);
