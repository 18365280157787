import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { routerMiddleware } from "react-router-redux";
import { createBrowserHistory } from "history";
import thunk from "redux-thunk";
import createReducer from "./reducers";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import reducers from "../redux/reducers";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["scrumboard", "themeSetting", "LanguageSwitcher", "themeChanger"]
};
const history = createBrowserHistory({
  basename: process.env.REACT_APP_BASE_NAME
});
const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk, routeMiddleware];
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducer = combineReducers({
  ...reducers
});

const rootReducer = (state, action) => {
  // if (action.type === LOGOUT) {
  // 	localStorage.removeItem("persist:root");
  // 	return reducer(undefined, action);
  // }
  return reducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

let store = createStore(persistedReducer);

const persistor = persistStore(store);
store.subscribe(() => {
  sessionStorage.setItem("token", store.getState().auth.token);
  sessionStorage.setItem("isLogin", store.getState().auth.isLogin);
  // localStorage.setItem("name", store.getState().auth.name);
  // localStorage.setItem("email", store.getState().auth.email);
});
export { store, history, persistor };
