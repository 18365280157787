import Demo from "views/Demo";

import DashBoard from "../views/DashBoard/DashBoard";

import StaticPage from "../views/StaticPageManagement/StaticPage";
import StaticPageEdit from "../views/StaticPageManagement/StaticPageEdit";
import StaticPageView from "../views/StaticPageManagement/StaticPageView";

import Editprofile from "../views/Editprofile/Editprofile";
import ChangePassword from "../views/ChangePassword/ChangePassword";

import State from "../views/State/State";
import StateAdd from "../views/State/StateAdd";

import Category from "../views/Category/Category";
import CategoryEdit from "../views/Category/CategoryEdit";
import CategoryView from "../views/Category/CategoryView";

import SubAdmin from "../views/SubAdmin/SubAdmin";
import SubAdminEdit from "../views/SubAdmin/SubAdminEdit";
import SubAdminView from "../views/SubAdmin/SubAdminView";

import Insurance from "../views/Insurance/Insurance";
import InsuranceEdit from "../views/Insurance/InsuranceEdit";

import Group from "../views/Group/Group";
import GroupEdit from "../views/Group/GroupEdit";
import GroupView from "../views/Group/GroupView";

import RegisteredDietitian from "../views/RegisteredDietitian/Profile";
import RDCompleteProfile from "../views/RegisteredDietitian/CompleteProfile";

import Rd from "../views/Rd/Rd";
import RdEdit from "../views/Rd/RdEdit";
import RdAdd from "../views/Rd/RdAdd";
import RdView from "../views/Rd/RdView";

import ViewClient from "../views/ViewClient/ViewClient";
import ViewClientDetail from "../views/ViewClient/ViewClientDetails";
import ClientEdit from "../views/ViewClient/ClientEdit";
import ClientAdd from "../views/ViewClient/ClientAdd";

import Messaging from "../views/Messaging/Messgeing";

import AppointmentSchedule from "../views/AppointmentSchedule/AppointmentSchedule";
import AppointmentViews from "../views/AppointmentSchedule/AppointmentViews";

import VisitProgram from "../views/VisitProgram/VisitProgram";
import VisitProgramEdit from "../views/VisitProgram/VisitProgramEdit";

import ScaleProgram from "../views/ScaleProgram/ScaleProgram";
import ScaleProgramEdit from "../views/ScaleProgram/ScaleProgramEdit";

import DiagnosesCode from "../views/DiagnosesCode/DiagnosesCode";
import DiagnosesCodeEdit from "../views/DiagnosesCode/DiagnosesCodeEdit";

import CptCode from "../views/CptCode/CptCode";
import CptCodeEdit from "../views/CptCode/CptCodeEdit";
import CptCodeView from "../views/CptCode/CptCodeView";

import VisitDetailReport from "../views/Report/VisitDetailReport";
import BillTransactionReport from "../views/Report/BillingTransactionReport";
import UnbillableClientReport from "../views/Report/UnbillableClientReport";
import ClientDetailReport from "../views/Report/ClientDetailReport";
import RdUnitGoalReport from "../views/Report/RdUnitGoalReport";
import ScaleRedemptionReport from "../views/Report/ScaleRedemptionReport";
import NutritionActivationDetailReport from "../views/Report/NutritionActivationDetailReport";
import MentalHealthActivationReport from "../views/Report/MentalHealthActivationReport";
import RdInsuranceDetailReport from "../views/Report/RdInsuranceReport";
import RdStateDetailReport from "../views/Report/RdStateReport";
import InsuranceBillReport from "../views/Report/InsuranceBillReport";
import MealDeliveryReport from "../views/Report/MealDeliveryReport";
import DailyBillingFailureReport from "../views/Report/DailyBillingFailureReport";
import RdCaqhReport from "../views/Report/RdCaqhReport";
import RdLiabilityInsuranceReport from "../views/Report/RdLiabilityInsuranceReport";
import RdCdrReport from "../views/Report/RdCdrReport";

import PushNotification from "../views/PushNotification/PushNotification";
import PushNotificationEdit from "../views/PushNotification/PushNotificationEdit";

import RdPerformanceMetric from "../views/RdPerformanceMetric/RdPerformanceMetric";
import Activity from "../views/activity/Activity";

import ImportUser from "../views/ImportUser/ImportUser";

import ServiceLocation from "../views/ServiceLocation/ServiceLocation";
import ServiceLocationEdit from "../views/ServiceLocation/ServiceLocationEdit";

import ContentLibrary from "../views/ContentLibrary/ContentLibrary";
import ContentLibraryEdit from "../views/ContentLibrary/ContentLibraryEdit";
import ContentLibraryView from "../views/ContentLibrary/ContentLibraryView";
import ContentLibraryShare from "../views/ContentLibrary/ContentLibraryShare";

import Service from "views/ServiceManagement/Service";
import ServiceEdit from "views/ServiceManagement/ServiceEdit";
import Tier from "views/TierManagement/Tier";
import FormManagement from "views/Form/FormManagement";
import FormEdit from "views/Form/FormEdit";
import FormView from "views/Form/FormView";
import Fax from "views/FaxManagement/Fax";
import FaxEdit from "views/FaxManagement/FaxEdit";
import FaxView from "views/FaxManagement/FaxView";
import ExternalProvider from "views/ExternalProviderMangement/ExternalProvider";
import ExternalProviderView from "views/ExternalProviderMangement/ExternalProviderView";
import ExternalProviderEdit from "views/ExternalProviderMangement/ExternalProviderEdit";

import Efax from "views/EFaxInbox/Efax";
import Rewards from "views/Rewards/Rewards";
import PendingBilling from "views/PendingBilling/PendingBilling";
import RdRetentionReport from "views/Report/RdRetentionReport";
import FinancialReport from "views/Report/FinancialReport";
import UploadFinancialReport from "views/UploadFinancialReport/UploadFinancialReport";
import DiscoveryQueue from "views/DiscoveryQueue/DiscoveryQueue";
import RoleAndPermission from "views/RoleAndPermissionManagement/RoleAndPermission";
import Practice from "views/PracticeManagement/Practice";
import PracticeEdit from "views/PracticeManagement/PracticeEdit";
import PracticeView from "views/PracticeManagement/PracticeView";
import VisitNotesTemplateView from "views/VisitNotesTemplate/VisitNotesTemplateView";
import VisitNotesTemplateEdit from "views/VisitNotesTemplate/VisitNotesTemplateEdit";
import IboPracticeReport from "views/Report/IboPracticeReport";
import OutstandingClaimReport from "views/Report/OutstandingClaimReport";
import LateCancelNoShowReport from "views/Report/LateCancelNoShowReport";
import FinancialReportView from "views/Report/FinancialReportView";

const dashboardRoutes = [
  { path: "/dashboard", component: DashBoard },
  { path: "/demo", component: Demo },

  { path: "/editprofile", component: Editprofile },
  { path: "/changepassword", component: ChangePassword },

  { path: "/static-page", component: StaticPage },
  { path: "/static-page/add", component: StaticPageEdit },
  { path: "/static-page/edit/:id", component: StaticPageEdit },
  { path: "/static-page/view/:id", component: StaticPageView },

  { path: "/state", component: State },
  { path: "/state/add", component: StateAdd },

  { path: "/category", component: Category },
  { path: "/category/add", component: CategoryEdit },
  { path: "/category/edit/:id", component: CategoryEdit },
  { path: "/category/view/:id", component: CategoryView },

  { path: "/form", component: FormManagement },
  { path: "/form/add", component: FormEdit },
  { path: "/form/edit/:id", component: FormEdit },
  { path: "/form/view/:id", component: FormView },

  { path: "/sub-admin", component: SubAdmin },
  { path: "/sub-admin/add", component: SubAdminEdit },
  { path: "/sub-admin/edit/:id", component: SubAdminEdit },
  { path: "/sub-admin/view/:id", component: SubAdminView },

  { path: "/insurance", component: Insurance },
  { path: "/insurance/add", component: InsuranceEdit },
  { path: "/insurance/edit/:id", component: InsuranceEdit },

  { path: "/group", component: Group },
  { path: "/group/add", component: GroupEdit },
  { path: "/group/edit/:id", component: GroupEdit },
  { path: "/group/view/:id", component: GroupView },

  { path: "/rd-edit-profile", component: RegisteredDietitian },
  { path: "/rd-edit-profile/edit", component: RdEdit },
  { path: "/complete-rd-profile", component: RDCompleteProfile },

  { path: "/rd", component: Rd },
  { path: "/rd/add", component: RdAdd },
  { path: "/rd/edit/:id", component: RdEdit },
  { path: "/rd/view/:id", component: RdView },

  { path: "/external-provider", component: ExternalProvider },
  // { path: "/external-provider/add", component: RdAdd },
  { path: "/external-provider/edit/:id", component: ExternalProviderEdit },
  { path: "/external-provider/view/:id", component: ExternalProviderView },

  { path: "/message", component: Messaging },

  { path: "/appointment", component: AppointmentSchedule },
  { path: "/appointment/view/:id", component: AppointmentViews },

  { path: "/client", component: ViewClient },
  { path: "/client/view/:id", component: ViewClientDetail },
  { path: "/client/edit/:id", component: ClientEdit },
  { path: "/client/add", component: ClientAdd },

  { path: "/visit-program", component: VisitProgram },
  { path: "/visit-program/add", component: VisitProgramEdit },
  { path: "/visit-program/edit/:id", component: VisitProgramEdit },

  { path: "/scale-program", component: ScaleProgram },
  { path: "/scale-program/add", component: ScaleProgramEdit },
  { path: "/scale-program/edit/:id", component: ScaleProgramEdit },

  { path: "/diagnoses-code", component: DiagnosesCode },
  { path: "/diagnoses-code/add", component: DiagnosesCodeEdit },
  { path: "/diagnoses-code/edit/:id", component: DiagnosesCodeEdit },

  { path: "/cpt-code", component: CptCode },
  { path: "/cpt-code/add", component: CptCodeEdit },
  { path: "/cpt-code/edit/:id", component: CptCodeEdit },
  { path: "/cpt-code/view/:id", component: CptCodeView },

  { path: "/visit-details-report", component: VisitDetailReport },
  { path: "/bill-transaction-report", component: BillTransactionReport },
  { path: "/unbillable-client-report", component: UnbillableClientReport },
  { path: "/client-details-report", component: ClientDetailReport },
  { path: "/rd-unit-goal-report", component: RdUnitGoalReport },
  { path: "/scale-redemption-report", component: ScaleRedemptionReport },
  {
    path: "/activation-report-nutrition",
    component: NutritionActivationDetailReport
  },
  {
    path: "/activation-report-mentalHealth",
    component: MentalHealthActivationReport
  },
  { path: "/rd-insurance-report", component: RdInsuranceDetailReport },
  { path: "/rd-state-report", component: RdStateDetailReport },
  { path: "/insurance-bill-report", component: InsuranceBillReport },
  { path: "/meal-delivery-report", component: MealDeliveryReport },
  {
    path: "/daily-billing-failure-report",
    component: DailyBillingFailureReport
  },
  { path: "/rd-caqh-report", component: RdCaqhReport },
  {
    path: "/rd-liability-insurance-report",
    component: RdLiabilityInsuranceReport
  },
  { path: "/rd-cdr-report", component: RdCdrReport },

  { path: "/push-notification", component: PushNotification },
  { path: "/push-notification/add", component: PushNotificationEdit },

  { path: "/performance-metric", component: RdPerformanceMetric },
  { path: "/activity", component: Activity },

  { path: "/import-user", component: ImportUser },

  { path: "/service-location", component: ServiceLocation },
  { path: "/service-location/add", component: ServiceLocationEdit },
  { path: "/service-location/edit/:id", component: ServiceLocationEdit },

  { path: "/content-library", component: ContentLibrary },
  { path: "/content-library/add", component: ContentLibraryEdit },
  { path: "/content-library/edit/:id", component: ContentLibraryEdit },
  { path: "/content-library/view/:id", component: ContentLibraryView },
  { path: "/content-library/share/:id", component: ContentLibraryShare },

  { path: "/service", component: Service },
  { path: "/service/add", component: ServiceEdit },
  { path: "/service/edit/:id", component: ServiceEdit },

  { path: "/tier", component: Tier },

  { path: "/fax", component: Fax },
  { path: "/fax/add", component: FaxEdit },
  { path: "/fax/edit/:id", component: FaxEdit },
  { path: "/fax/view/:id", component: FaxView },

  { path: "/efax", component: Efax },

  { path: "/rewards", component: Rewards },

  { path: "/pending-billing", component: PendingBilling },

  { path: "/rd-retention-report", component: RdRetentionReport },

  { path: "/discovery-queue", component: DiscoveryQueue },
  { path: "/financial-report", component: FinancialReportView },
  { path: "/financial-report/view/:id", component: FinancialReport },

  { path: "/upload-financial-report", component: UploadFinancialReport },

  { path: "/discovery-queue", component: DiscoveryQueue },
  { path: "/roles-and-permissions", component: RoleAndPermission },

  { path: "/practice", component: Practice },
  { path: "/practice/add", component: PracticeEdit },
  { path: "/practice/edit/:id", component: PracticeEdit },
  { path: "/practice/view/:id", component: PracticeView },

  { path: "/visit-note-template/add", component: VisitNotesTemplateEdit },
  { path: "/visit-note-template/edit/:id", component: VisitNotesTemplateEdit },
  { path: "/visit-note-template/get/:id", component: VisitNotesTemplateView },
  { path: "/ibo-practice-report", component: IboPracticeReport },

  { path: "/outstanding-claim-report", component: OutstandingClaimReport },
  { path: "/late-cancel-no-show-report", component: LateCancelNoShowReport }
];

export default dashboardRoutes;
