import { withFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    start_date: Yup.date()
      .required("This field is required")
      .nullable(),
    end_date: Yup.date()
      .required("This field is required")
      .nullable()
      .min(Yup.ref("start_date"), "End date can't be before start date")
  }),
  mapPropsToValues: props => ({
    id: props.editedData
      ? props && props.editedData && props.editedData.id
      : "",
    start_date: props.editedData
      ? props &&
        props.editedData &&
        new Date(moment(props.editedData.start_date))
      : new Date(),
    end_date: props.editedData
      ? props && props.editedData && new Date(moment(props.editedData.end_date))
      : ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
