import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import Loader from "../common/Loader";
import Toaster from "../common/Toaster";
import {
  FORM_TYPE,
  MODULE_NAMES,
  PERMISSION_TYPE,
  STATIC_PAGE_IDS,
  TABLE
} from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import CommonModel from "../common/CommonModel";
import moment from "moment";
import ReassignForm from "./ReassignForm";
import ClientManagementForm from "api/ClientMangementForm";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
import FormData from "./FormData";
import DeleteModal from "components/common/DeleteModal";
import { hasAccess } from "util/sidebar";

const UserForms = props => {
  const { permissions } = props;
  const [tblData, settblData] = useState();
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [parentDropdownOpen, setParentDropdownOpen] = useState(false);
  const [pendingText, setPendingText] = useState("");
  const [searchType, setSearchType] = useState("");
  const [formId, setFormId] = useState(null);
  const [details, setDetails] = useState(null);
  const [id, setId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const toaster = useRef();
  const toggleOne = () => setParentDropdownOpen(prevState => !prevState);

  useEffect(() => {
    if (
      hasAccess(MODULE_NAMES.CLIENT_FORMS, PERMISSION_TYPE.VIEW, permissions)
    ) {
      getList(activePage + 1);
      setPages(10);
    }
  }, [searchType]);

  function toggle() {
    setModal(!modal);
  }
  function toggle2() {
    setModal1(!modal1);
  }
  function deleteToggle() {
    setDeleteModal(false);
  }

  const deleteClick = data => {
    setId(data.id);
    setDeleteModal(true);
  };

  function deleteHandler() {
    setLoader(true);
    const params = {
      form_id: id
    };
    ClientManagementForm.deleteForm(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current) toaster.current.success(data.message);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
        setLoader(false);
        setDeleteModal(false);
        setTimeout(() => {
          getList(activePage + 1);
        }, 1000);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });
  }

  const formAction = data => {
    setFormId(data.id);
    setDetails({
      formName: data?.form?.name,
      staticPageId: STATIC_PAGE_IDS[data?.form?.name],
      userName:
        props?.location?.state?.first_name +
        " " +
        props?.location?.state?.last_name,
      submittedDate: data?.submitted_date
    });
    setModal1(!modal1);
  };

  const columns = [
    {
      Header: "Form",
      accessor: "name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: true,
      width: 280,
      Cell: props => props?.original?.form?.name
    },
    {
      Header: "Service",
      accessor: "service.name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 150,
      sortable: false
    },
    {
      Header: "Due Date",
      accessor: "due_date",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",
      Cell: props =>
        props?.original?.due_date
          ? moment(props?.original?.due_date).format("MM/DD/YYYY")
          : "-",
      width: 180,
      sortable: false
    },
    {
      Header: "Completed Date",
      accessor: "submitted_date",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",

      Cell: props =>
        props?.original?.submitted_date
          ? moment(props?.original?.submitted_date).format("MM/DD/YYYY HH:mm")
          : "-",
      width: 180,
      sortable: false
    },
    {
      Header: "Status",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",
      show: searchType !== "pending",
      Cell: props =>
        props?.original?.submitted_date ? "Completed" : "Pending",
      width: 180,
      sortable: false
    },
    {
      Header: "Action",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",
      Cell: prop => (
        <div>
          {prop?.original?.form.type !== FORM_TYPE.STANDARD &&
            prop?.original?.submitted_date && (
              <div>
                <Button
                  className="c-btn c-info mr-10"
                  onClick={() => formAction(prop?.original)}
                >
                  <div className="fs-14 medium-text">View</div>
                </Button>
              </div>
            )}
          {hasAccess(
            MODULE_NAMES.CLIENT_FORMS,
            PERMISSION_TYPE.DELETE,
            permissions
          ) &&
            !prop?.original?.submitted_date && (
              <Button
                className="c-btn c-danger"
                onClick={() => deleteClick(prop.original)}
              >
                <div className="fs-14 medium-text">
                  <i className="fas fa-trash" />
                </div>
              </Button>
            )}
        </div>
      ),
      width: 180,
      sortable: false
    }
  ];

  const handleSubmit = () => {
    getList(activePage + 1);
    setPages(10);
  };

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  const handleDropDown = ({ ...props }) => {
    if (searchType === props.value) return;
    setSearchType(props.text);
    setPendingText(props.value);
    setLoader(true);
    setActivePage(0);
  };

  function getList(page = "1") {
    const hasParam = props.match.params.id;
    const params = {
      page: page,
      limit: TABLE.LIMIT,
      search_type: searchType,
      user_id: hasParam
    };

    setLoader(true);
    ClientManagementForm.list(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data?.data?.rows);
          setPages(Math.ceil(data.data.count / TABLE.LIMIT));
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
          setLoader(false);
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current) toaster.current.error(err?.message);
        }
        setLoader(false);
      });
  }

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span>Form List
            </div>
            <div className="d-flex">
              <Dropdown isOpen={parentDropdownOpen} toggle={toggleOne}>
                <DropdownToggle caret size="md">
                  {pendingText ? pendingText : "All"}
                </DropdownToggle>
                <DropdownMenu
                  modifiers={{
                    setMaxHeight: {
                      enabled: true,
                      order: 890,
                      fn: data => {
                        return {
                          ...data,
                          styles: {
                            ...data.styles,
                            overflow: "auto",
                            maxHeight: "300px"
                          }
                        };
                      }
                    }
                  }}
                >
                  <DropdownItem
                    key="0"
                    onClick={() => handleDropDown({ value: "All", text: "" })}
                  >
                    All
                  </DropdownItem>
                  <DropdownItem
                    key="1"
                    onClick={() =>
                      handleDropDown({ value: "Pending", text: "pending" })
                    }
                  >
                    Pending
                  </DropdownItem>
                  <DropdownItem
                    key="2"
                    onClick={() =>
                      handleDropDown({ value: "History", text: "history" })
                    }
                  >
                    History
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>

              {hasAccess(
                MODULE_NAMES.CLIENT_FORMS,
                PERMISSION_TYPE.ADD,
                permissions
              ) ? (
                <Button
                  className="c-btn c-primary ma-5"
                  onClick={() => {
                    setModal(!modal);
                  }}
                >
                  <i className="fas fa-plus mr-10" />
                  Reassign Form
                </Button>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;
                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class ReactTable1"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              LoadingComponent={Loader}
              onFetchData={onChangePageGetData}
              onPageChange={pageIndex => {
                setActivePage(pageIndex);
                getList(pageIndex + 1);
              }}
            />
          </div>
        </div>
      </div>
      <Toaster ref={toaster} />

      <CommonModel
        modal={deleteModal}
        toggle={deleteToggle}
        children={
          <DeleteModal
            text={"Are you sure you want to delete this form?"}
            deleteHandler={deleteHandler}
            toggle={deleteToggle}
          />
        }
      />

      {modal && (
        <CommonModel
          modal={modal}
          toggle={toggle}
          onSubmit={handleSubmit}
          size="lg"
          children={
            <ReassignForm
              toggle={toggle}
              onSubmit={handleSubmit}
              pageRefresh={() => {
                getList(activePage + 1);
              }}
              {...props}
            />
          }
        />
      )}
      {modal1 && (
        <CommonModel
          modal={modal1}
          toggle={toggle2}
          size="xl"
          children={
            <FormData
              toggle={toggle2}
              details={details}
              {...props}
              formId={formId}
            />
          }
        />
      )}
    </div>
  );
};

export default withRouter(UserForms);
