import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    first_name: Yup.string()
      .trim()
      .required("This field is required")
      .max(100, "First Name must be less than 100 characters"),
    last_name: Yup.string()
      .trim()
      .required("This field is required")
      .max(100, "Last Name must be less than 100 characters"),
    email: Yup.string()
      .trim()
      .required("This field is required")
      .email("Please enter valid email"),
    advancemd_provider_id: Yup.string()
      .required("This field is required")
      .matches(/^[a-z0-9]+$/i, "Must contain numbers and letters only")
      .max(10, "Must be 10 characters or less"),
    provider_npi: Yup.string()
      .matches(/^[0-9]+$/i, "Must contain numbers only")
      .min(10, "Must be exactly 10 digits")
      .max(10, "Must be exactly 10 digits"),
    zip: Yup.string()
      .matches(/^[0-9]+$/i, "Must contain numbers only")
      .min(5, "Must be exactly 5 digits")
      .max(5, "Must be exactly 5 digits"),
    phone_number: Yup.string()
      .min(14, "Must be exactly 10 digits")
      .max(14, "Must be exactly 10 digits"),
    timezone_id: Yup.string()
      .trim()
      .required("This field is required"),
    language_id: Yup.string()
      .trim()
      .required("This field is required"),
    type: Yup.string().required("This field is required"),
    service: Yup.string().required("This field is required"),
    practice: Yup.string().when("type", {
      is: "ibo practice",
      then: Yup.string()
        .trim()
        .required("This field is required")
    })
  }),
  mapPropsToValues: props => ({
    first_name: "",
    last_name: "",
    email: "",
    service_location_id: null,
    advancemd_provider_id: "",
    provider_npi: "",
    phone_number: "",
    address: "",
    city: "",
    state_id: "",
    zip: "",
    language_id: "",
    timezone_id: "",
    type: "",
    service: "",
    practice: ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
