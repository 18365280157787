export const calculateAgeFromDOB = dob => {
  // dob should be a Date object
  const currentDate = new Date();
  const birthDate = new Date(dob);

  let age = currentDate.getFullYear() - birthDate.getFullYear();

  // Adjust age based on the birth month and day
  if (
    currentDate.getMonth() < birthDate.getMonth() ||
    (currentDate.getMonth() === birthDate.getMonth() &&
      currentDate.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
};
