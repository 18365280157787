import React, { useRef, useState } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import VisitService from "../../api/VisitService";
import Loader from "../common/Loader";
import moment from "moment-timezone";
import Toaster from "../common/Toaster";
import { RD } from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import CommonModel from "components/common/CommonModel";
import ExternalProviderModal from "./ExternalProviderModal";

const ViewVisitNote = props => {
  const [externalProviderModal, setExternalProviderModal] = useState(false);
  const [noteData, setNoteData] = useState({});
  const {
    data,
    toggle,
    pageRefresh,
    tblData,
    loader,
    editedData,
    setEditedData,
    getList,
    defaultNote,
    pages,
    setPages,
    setActivePage,
    activePage,
    displayOnFormText,
    field,
    setField,
    id,
    setId,
    modal,
    setModal,
    sortBy,
    setSortBy,
    searchText
  } = props;

  const toaster = useRef();
  function externalProviderToggle() {
    setExternalProviderModal(false);
  }

  const showEditVisit = () => {
    let value = true;
    if (
      props?.auth?.admin_role_id !== RD ||
      (props?.auth?.admin_role_id === RD && props?.auth?.adminType === "ibo")
    ) {
      value = true;
    } else {
      if (props?.data?.appointment_type === "Manual") {
        value =
          moment
            .tz(
              moment(
                props?.data?.appointment_date +
                  " " +
                  props?.data?.appointment_time
              ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
              moment.tz.guess()
              // props?.data?.timezone
            )
            .format("MM/DD/YYYY") >=
          moment()
            .utc()
            .local()
            .format("MM/DD/YYYY");
      } else {
        value =
          moment
            .tz(props?.data?.event_detail?.start_time, moment.tz.guess())
            .format("MM/DD/YYYY") >=
          moment()
            .utc()
            .local()
            .format("MM/DD/YYYY");
      }
    }
    return value;
  };

  const columns = [
    {
      Header: "ID",
      accessor: "id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 60,
      sortable: true
    },
    {
      Header: "Note",
      accessor: "visit_note",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 350,
      sortable: true,
      Cell: props => (
        <div className="react-action-class">
          <textarea
            value={props?.original?.visit_note}
            readOnly={true}
            rows={6}
            cols={50}
            style={{ maxWidth: "100%", width: 448 }}
          >
            {props?.original?.visit_note}
          </textarea>
        </div>
      )
    },
    {
      Header: "Created Date",
      accessor: "created_at",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 180,
      sortable: true,
      Cell: props =>
        moment
          .tz(props?.original?.created_at, moment.tz.guess())
          .format("MM/DD/YYYY h:mm A")
    },
    {
      Header: "Signed Date",
      accessor: "signed_date",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 180,
      sortable: true,
      Cell: props =>
        props?.original?.signed_date &&
        moment
          .tz(props?.original?.signed_date, moment.tz.guess())
          .format("MM/DD/YYYY h:mm A")
    },
    {
      Header: "Copy To Next Visit",
      accessor: "copy_to_next_visit",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 150,
      sortable: false,
      Cell: ({ value }) => (
        <div>
          <i
            className={
              value
                ? "far fa-check-circle tick-bg-color"
                : "fas fa-times-circle cross-bg-color"
            }
          ></i>
        </div>
      )
    },
    {
      Header: "Action",
      accessor: "action",
      className: "",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 150,
      Cell: colProps => (
        <div className="react-action-class">
          {showEditVisit() ? (
            <Button
              className="c-btn c-success mr-10"
              onClick={() => formAction("edit", colProps.original)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-edit" />
              </div>
            </Button>
          ) : (
            "-"
          )}
          <Button
            className="c-btn c-success mr-10"
            onClick={() => {
              setExternalProviderModal(true);
              setNoteData({ ...colProps.original });
            }}
            disabled={
              data.status !== "completed" || !colProps.original.signed_date
            }
          >
            <div className="fs-14 medium-text"> Fax Notes</div>
          </Button>
        </div>
      ) // Custom cell components!
    }
  ];

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  const formAction = (action, data = null) => {
    // Here you can view the data and make forward action for edit data
    if (action === "edit") {
      setId(data.id);
      setModal(true);
      const params = {
        id: data.id
      };

      VisitService.visitNoteView(params).then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        setEditedData(data.data);
      });
    } else if (action === "add") {
      setId(data);
      setModal(true);
      setEditedData(null);
    }
    // props.toggle();
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <div className="plr-15">
          <div className="roe-card-style mtb-15">
            <div className="roe-card-header module-header">
              <div className="flex-1 fs-16 demi-bold-text">Visit Note List</div>
              <div>
                <Button
                  className="c-btn c-primary ma-5"
                  onClick={() => formAction("add")}
                >
                  <i className="fas fa-plus mr-10" />
                  Add Visit Note
                </Button>
              </div>
            </div>
            <div className="roe-card-body">
              <ReactTable
                style={{
                  border: "none",
                  boxShadow: "none"
                }}
                data={tblData}
                columns={columns}
                defaultPageSize={10}
                minRows={2}
                manual
                defaultFilterMethod={(filter, row) => {
                  const id = filter.pivotId || filter.id;

                  return row[id] !== undefined
                    ? String(row[id].toLowerCase()).includes(
                        filter.value.toLowerCase()
                      )
                    : true;
                }}
                className="-striped -highlight custom-react-table-theme-class"
                pages={pages}
                page={activePage}
                PaginationComponent={Pagination}
                loading={loader}
                LoadingComponent={Loader}
                onFetchData={onChangePageGetData}
                onPageChange={pageIndex => {
                  setActivePage(pageIndex);
                  getList(
                    field,
                    sortBy,
                    searchText,
                    pageIndex + 1,
                    displayOnFormText
                  );
                }}
                onSortedChange={sortProperties => {
                  const sort = sortProperties[0].desc ? "DESC" : "ASC";
                  setSortBy(sort);
                  setActivePage(0);
                  setField(sortProperties[0].id);
                  getList(
                    sortProperties[0].id,
                    sort,
                    searchText,
                    1,
                    displayOnFormText
                  );
                  // alert("sort handler", sortProperties);
                  // const orderByDirection = sortProperties[0].desc ? 1 : 0;

                  // updateMapStateInfoOrderByColumn(sortProperties[0].id, orderByDirection);
                }}
              />
            </div>
          </div>
        </div>
        <CommonModel
          modal={externalProviderModal}
          toggle={externalProviderToggle}
          size="md"
          children={
            <ExternalProviderModal
              {...props}
              adminId={props?.auth?.id}
              noteData={noteData}
              toggle={externalProviderToggle}
            />
          }
        />
        <Toaster ref={toaster} />
      </div>
    </>
  );
};

export default withRouter(ViewVisitNote);
