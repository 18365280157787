import React, { useEffect, useState, useRef } from "react";
import RegisteredDietitian from "../../api/RegisteredDietitian";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import enhancer from "./insurance";
import { connect } from "react-redux";
import { compose } from "redux";
import Button from "components/button/Button";
import Toaster from "../common/Toaster";
import StateLicenceWrapper from "./statelicence.style";
import AuthActions from "redux/auth/actions";
import { withRouter } from "react-router-dom";

const { login } = AuthActions;

const StepThreeStateLicence = props => {
  const {
    setFieldValue,
    onSubmit,
    values,
    errors,
    action,
    touched,
    submitCount,
    handleChange,
    isValid
  } = props;
  useEffect(() => {
    return getStateList();
  }, []);
  const toaster = useRef();

  const [stateList, setStateList] = useState([]);
  const [selectedInput, setSelectedInput] = React.useState([]);
  const getStateList = () => {
    RegisteredDietitian.insuranceList()
      .then(result => {
        let data = EncryptDecrypt.decrypt(result.data);

        setStateList(data);
      })
      .catch(error => {
        console.log("getStateList -> error", error);
      });
  };
  const Error = props => {
    const field1 = props.field;

    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };
  const checkHandleChange = e => {
    const targtValue = parseInt(e.currentTarget.value);
    setSelectedInput(prev =>
      prev.some(data => data === targtValue)
        ? prev.filter(data => data !== targtValue)
        : [...prev, targtValue]
    );

    let objIndex = values.insurance.findIndex(obj => obj === targtValue);
    if (objIndex > -1) {
      values.insurance.splice(objIndex, 1);
      setFieldValue("state", values.insurance);
    } else {
      values.insurance.push(targtValue);
    }
    handleChange(e);
  };

  const handleSubmit = () => {
    if (isValid) {
      const params = {
        insurance: values.insurance
      };

      RegisteredDietitian.completeInsurance(params)
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          if (data.status) {
            if (toaster.current)
              toaster.current.success(
                "Your account is in pending please wait until admin will approve."
              );
            setTimeout(resullt => {
              let loginData = {
                ...props.auth,
                step: 5
              };
              props.login(loginData);
            }, 3000);
          } else {
            if (toaster.current) toaster.current.error("Something went wrong");
          }
        })
        .catch(err => {
          console.log("handleSubmit -> err", err);
          // if (toaster.current) toaster.current.error(err.response);
        });
    }
    if (values.insurance.length <= 0) {
      toaster.current.error("Please select insurance");
    }
  };

  return (
    <StateLicenceWrapper>
      <div className="profile-head">
        <h3>Complete Insurance</h3>
      </div>
      <div className="profile-head">
        Select the healthplans in which you are an in-network provider.
      </div>
      <form>
        <div className="form-group col-md-12 h-500 overflow-auto">
          <br />
          {stateList.data &&
            stateList.data.map((type, i) => {
              return (
                <div key={i} className="mt-2">
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td style={{ width: "25%" }}>
                          <input
                            type="checkbox"
                            id={type.id}
                            name={`state_id`}
                            onChange={checkHandleChange}
                            value={type.id}
                            checked={
                              selectedInput && selectedInput.includes(type.id)
                                ? "checked"
                                : false
                            }
                            className="checkbox"
                          />
                          {" " + type.name}
                        </td>
                        <td
                          className={
                            selectedInput && selectedInput.includes(type.id)
                              ? ""
                              : "d-none"
                          }
                        ></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              );
            })}
        </div>
        <div>
          <Button
            type="button"
            className="c-btn c-info form-button fs-16 demi-bold-text"
            style={{ maxWidth: "150px" }}
            onClick={() => handleSubmit()}
          >
            Complete Profile
          </Button>
        </div>
      </form>
      <Toaster ref={toaster} />
    </StateLicenceWrapper>
  );
};

// export default compose(enhancer)(StepThreeStateLicence)
const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};
export default compose(
  withRouter,
  enhancer,
  connect(mapStateToProps, { login })
)(StepThreeStateLicence);
