import authAction from "./actions";

const initState = {
  isLogin: localStorage.getItem("isLogin")
    ? localStorage.getItem("isLogin") === "true"
    : false,
  token: localStorage.getItem("token"),
  admin: localStorage.getItem("admin"),
  profile_image: null,
  email: null,
  id: null,
  full_name: null,
  admin_role_id: null,
  admin_role: null,
  step: null,
  stream_io_token: null,
  calendly_link: null,
  admintype: null,
  tier_id: null,
  practice: null,
  is_messaging_active: true
};

export default function rootReducer(state = initState, action) {
  switch (action.type) {
    case authAction.LOGIN:
      return {
        ...state,
        isLogin: action.isLogin,
        token: action.token,
        first_name: action.first_name,
        last_name: action.last_name,
        email: action.email,
        id: action.id,
        profile_image: action.profile_image,
        admin_role_id: action.admin_role_id,
        admin_role: action.admin_role,
        step: action.step,
        stream_io_token: action.stream_io_token,
        calendly_link: action.calendly_link,
        service_id: action.service_id,
        adminType: action.adminType,
        tier_id: action.tier_id,
        practice: action.practice,
        is_messaging_active: action.is_messaging_active
      };
    case authAction.LOGOUT:
      return {
        ...state,
        isLogin: action.isLogin,
        token: null,
        admin: null,
        stream_io_token: null,
        calendly_link: null
      };
    case authAction.ADMIN:
      return {
        ...state,
        first_name: action.first_name,
        last_name: action.last_name,
        profile_image: action.profile_image
      };
    default:
      return state;
  }
}
