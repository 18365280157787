import React, { useEffect, useRef, useState } from "react";
import { compose } from "redux";
import enhancer from "./edit-provider-validator";
import Button from "../button/Button";
import Toaster from "../common/Toaster";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
import CareTeamServices from "api/CareTeamServices";
import ClientServices from "api/ClientServices";
import { SERVICE_NAME } from "helper/constant";
import Loader from "components/common/Loader";

const EditProviderModal = props => {
  const [providerList, setProviderList] = useState();
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [clientList, setClientList] = useState();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [provider, setProvider] = useState(null);
  const [notify, setNotify] = useState(0);
  const hasParam = props.match.params.id || props?.userId;
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const toaster = useRef();
  const { errors, touched, submitCount, setFieldValue } = props;

  useEffect(() => {
    if (hasParam) {
      const params = {
        id: hasParam
      };
      ClientServices.view(params)
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          setClientList(data.data);
        })
        .catch(err => {
          if (err?.response?.status === 403) {
            if (toaster.current) {
              toaster.current.error("You cannot access this user info!");
              setTimeout(() => {
                props.history.push("/client");
              }, 3000);
            }
          } else {
            if (toaster.current)
              toaster.current.error("Could not fetch client details!");
          }
        });
    }
    return () => {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    };
  }, []);

  useEffect(() => {
    const params = {
      user_id: hasParam,
      service_id: props.service_id,
      practice_id:
        props?.auth?.admin_role_id === 6 ? props?.auth?.practice : undefined
    };
    CareTeamServices.listProvider(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setProviderList(data.data);
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch provider list!");
        }
      })
      .catch(err => {
        if (toaster.current)
          toaster.current.error("Could not fetch provider list!");
      });
  }, []);

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };
  const handleAssignRd = () => {
    let { isValid, handleSubmit } = props;
    if (isValid) {
      setIsLoading(true);
      let params = {
        user_id: hasParam,
        rd_id: provider.id,
        is_notify: notify
      };

      CareTeamServices.editProvider(params)
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          if (data.status) {
            if (toaster.current) toaster.current.success(data.message);
            setIsLoading(false);
            props.toggle();
            props.pageRefresh();
          } else {
            if (toaster.current) toaster.current.error(data.message);
            props.toggle();
          }
        })
        .catch(err => {
          if (err?.response?.status === 403) {
            if (toaster.current) {
              toaster.current.error("You cannot access this user info!");
              setTimeout(() => {
                props.history.push("/client");
              }, 3000);
            }
          } else {
            if (toaster.current) toaster.current.error(err?.message);
          }
          setTimeout(() => {
            props.toggle();
          }, 500);
        });
    }
    handleSubmit();
  };

  return (
    <form className="pa-20">
      {loading && !clientList ? (
        <>
          <Loader loading={loading} />
        </>
      ) : (
        <>
          <div className="form-group ">
            <div>
              <span className="fs-20 demi-bold-text">
                {props?.heading ? props?.heading : "Edit"} Provider
              </span>

              <p className="ptb-6 fs-18 regular-text ">
                {clientList?.first_name + " " + clientList?.last_name} is in{" "}
                {clientList?.state?.name} with{" "}
                {clientList?.user_insurance?.insurance?.name} and is associated
                with {clientList?.group?.name}. Choose a{" "}
                {SERVICE_NAME[props.service_id]} provider below to assign to
                this client:
              </p>

              <div className="flex pt-2 pb-100">
                <div>
                  <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                    <DropdownToggle caret style={{ width: "420px" }}>
                      {provider
                        ? provider.first_name + " " + provider.last_name
                        : "Select Provider"}
                    </DropdownToggle>
                    <DropdownMenu
                      style={{
                        width: "100%",
                        height: "20vh",
                        overflow: "scroll"
                      }}
                    >
                      <DropdownItem
                        header
                        style={{
                          fontSize: "18px",
                          color: "#334155",
                          fontWeight: 700
                        }}
                      >
                        State and Network Matches
                      </DropdownItem>
                      {providerList?.matched_rd?.length ? (
                        providerList?.matched_rd?.map((result, i) => {
                          return (
                            <DropdownItem
                              key={i}
                              onClick={() => {
                                setProvider(result);
                                setFieldValue("rd_id", result.id);
                              }}
                            >
                              {result.first_name + " " + result.last_name}
                            </DropdownItem>
                          );
                        })
                      ) : (
                        <div className="flex justify-content-center">-</div>
                      )}
                      <DropdownItem
                        header
                        style={{
                          fontSize: "18px",
                          color: "#334155",
                          fontWeight: 700
                        }}
                      >
                        Other Providers
                      </DropdownItem>
                      {providerList?.other_rd?.length ? (
                        providerList?.other_rd?.map((result, i) => {
                          return (
                            <DropdownItem
                              key={i}
                              onClick={() => {
                                setProvider(result);
                                setFieldValue("rd_id", result.id);
                              }}
                            >
                              {result.first_name + " " + result.last_name}
                            </DropdownItem>
                          );
                        })
                      ) : (
                        <div className="flex justify-content-center">-</div>
                      )}
                    </DropdownMenu>
                  </Dropdown>
                  <Error field="rd_id" />
                </div>

                <div className="flex-x align-center fs-18 regular-text pl-4">
                  Notify Client:
                  <span className="pl-1 flex-x center">
                    <input
                      type="checkbox"
                      value="1"
                      onChange={() => {
                        setNotify(1);
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="flex-x center">
            <Button
              type="button"
              className="c-btn c-secondary form-button fs-16 demi-bold-text mr-10"
              style={{ maxWidth: "125px" }}
              onClick={props.toggle}
            >
              Cancel
            </Button>
            <Button
              type="button"
              className="c-btn c-info form-button fs-16 demi-bold-text"
              style={{ maxWidth: "125px" }}
              onClick={() => handleAssignRd()}
              loading={isLoading}
              disabled={isLoading}
              dataStyle={"expand-right"}
            >
              {isLoading ? <p>Saving...</p> : <p>Save</p>}
            </Button>
          </div>

          <Toaster ref={toaster} />
        </>
      )}
    </form>
  );
};

export default compose(enhancer)(EditProviderModal);
