import React, { useEffect, useState } from "react";
import Button from "../../components/button/Button";

const IdleTimeoutModal = ({ showModal, handleContinue, handleLogout }) => {
  const [counter, setCounter] = useState(60);

  useEffect(() => {
    const timerInterval =
      counter > 0 &&
      showModal &&
      setInterval(() => setCounter(counter => counter - 1), 1000);

    if (showModal && !counter) {
      handleLogout("AUTO");
    }

    return () => clearInterval(timerInterval);
  }, [counter, showModal]);

  return (
    <div className="text-center" style={{ color: "#58585a" }}>
      <div className="fs-24 font-bold" style={{ color: "#58585a" }}>
        Your Session is about to expire
      </div>
      <div style={{ paddingBlock: 16, color: "#58585a" }}>
        You have been inactive for a while. For your security, we'll
        automatically sign you out in {counter} seconds
      </div>
      <div>
        <Button
          className="c-btn c-info form-button fs-16 demi-bold-text mr-10"
          style={{ maxWidth: "125px" }}
          type="button"
          onClick={handleLogout}
        >
          Logout Now
        </Button>
        <Button
          className="c-btn c-primary ma-5"
          style={{ maxWidth: "125px" }}
          onClick={handleContinue}
        >
          Continue Session
        </Button>
      </div>
    </div>
  );
};

export default IdleTimeoutModal;
