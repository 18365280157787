import React, { Fragment, useRef, useEffect, useState } from "react";
import { compose } from "redux";
import enhancer from "./add-validator";
import Button from "../button/Button";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import ClientServices from "../../api/ClientServices";
import Toaster from "../common/Toaster";
import { Input } from "reactstrap";
import {
  ImageResolution,
  DEFAULT_GROUP,
  PROVIDER_TYPE
} from "../../helper/constant";
import { PLACEHOLDER } from "helper/constant";
import { RD } from "../../helper/constant";
import CategoryServices from "api/CategoryServices";
import RdServices from "api/RdServices";

const genderList = [
  {
    key: "M",
    value: "Male"
  },
  {
    key: "F",
    value: "Female"
  },
  {
    key: "U",
    value: "Unknown"
  }
];

const ClientEditForm = props => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    setFieldTouched,
    setFieldValue,
    onSubmit,
    values,
    action,
    groupList,
    insuranceList,
    onServiceIdChange
  } = props;

  const toaster = useRef(null);
  const [category, setCategory] = useState([]);
  const [isIbo, setIsIbo] = useState(false);
  const [imageObj, setImageObj] = useState(null);
  const [uploadImage, setUploadImage] = useState(null);
  const [registrationCodeList, setRegistrationCodeList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [rdList, setRdList] = useState([]);
  const [regLoading, setRegLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const params = {};

    if (values.service_id) {
      params.service_id = Number(values?.service_id);
      rdListChangeHandle({}, values.service_id);
    }

    RdServices.listCategory(params).then(res => {
      let data = EncryptDecrypt.decrypt(res.data);
      setCategory(data.data);
    });
  }, [values?.service_id]);

  useEffect(() => {
    if (props.authData.adminRoleId === RD) {
      setFieldValue("rd_id", props.authData.adminId);
    }
    if (
      props.authData.adminRoleId === RD &&
      (props?.authData?.adminType === "ibo" ||
        props?.authData?.adminType === "ibo practice")
    ) {
      setFieldValue(
        "group_id",
        values.service_id == 1
          ? DEFAULT_GROUP.nutrition
          : DEFAULT_GROUP.mentalHealth
      );
      setIsIbo(true);
    }
  }, [props?.authData]);

  useEffect(() => {
    if (values?.rd_id && props.authData.adminRoleId !== RD) {
      const index = rdList.findIndex(
        el =>
          el.id === Number(values?.rd_id) &&
          (el?.rd_detail?.type === "ibo" ||
            el?.rd_detail?.type === "ibo practice")
      );
      if (index !== -1) {
        setFieldValue(
          "group_id",
          values.service_id == 1
            ? DEFAULT_GROUP.nutrition
            : DEFAULT_GROUP.mentalHealth
        );
        setIsIbo(true);
      } else {
        setFieldValue("group_id", "");
        setIsIbo(false);
      }
    }
  }, [values?.rd_id]);

  useEffect(() => {
    if (
      isIbo ||
      props?.authData?.adminType === "ibo" ||
      props?.authData?.adminType === "ibo practice"
    ) {
      regCodeByRD(values?.rd_id);
    } else {
      if (values?.group_id) changeHandleChange(values?.group_id);
    }
  }, [isIbo, values?.rd_id]);

  useEffect(() => {
    CategoryServices.serviceDropdown()
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setServiceList([...data.data]);
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      });
  }, []);

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const handleImage = (e, setValue) => {
    if (e.target.files[0]) {
      let fileObj = e.target.files[0];

      if (fileObj.size > 10 * 1024 * 1024) {
        if (toaster.current)
          toaster.current.error("Please upload a file smaller than 10 MB");
        return false;
      }

      if (!fileObj.name.match(/\.(jpg|jpeg|png|gif|heif)$/)) {
        if (toaster.current)
          toaster.current.error(
            "Only .jpg, .jpeg, .gif, .png, .heif are allowed"
          );
        return false;
      }

      if (fileObj) {
        const img = new Image();

        img.src = window.URL.createObjectURL(fileObj);

        img.onload = function() {
          const width = img.naturalWidth,
            height = img.naturalHeight;

          window.URL.revokeObjectURL(img.src);
          setValue("width", width);
          setValue("height", height);
          setFieldTouched("profile_photo", true, true);
          if (
            width >= ImageResolution.WIDTH &&
            height >= ImageResolution.HEIGHT
          ) {
            setImageObj(fileObj);
            setValue("profile_photo", URL.createObjectURL(fileObj));
            setUploadImage(URL.createObjectURL(fileObj));
          }
        };
      } else {
        //No file was input or browser doesn't support client side reading
        // form.submit();
      }
    } else {
      setUploadImage();
    }
  };

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;
    if (isValid) {
      setIsLoading(true);
      const params = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        category_id: values.category_id,
        group_id: values.group_id,
        service_id: values.service_id,
        registration_code_id: values.registration_code_id,
        rd_id: values.rd_id,
        insurance_id: values.insurance_id,
        zipcode: values.zipcode,
        gender: values.gender,
        practice_id:
          props.authData &&
          props.authData?.adminRoleId === 6 &&
          props.authData?.practice
            ? props.authData?.practice
            : undefined,
        dob: values.dob
      };

      const formdata = new FormData();
      const encryptData = EncryptDecrypt.encrypt(params);
      formdata.append("data", encryptData?.data);
      if (imageObj) formdata.append("data1", imageObj);

      ClientServices.add(formdata)
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          if (data.status) {
            if (toaster.current) toaster.current.success(data.message);
            onSubmit(values, action);
            setIsLoading(false);
          } else {
            if (toaster.current) toaster.current.error(data.message);
            setIsLoading(false);
          }
        })
        .catch(err => {
          if (toaster.current) toaster.current.error(err.response);
          setIsLoading(false);
        });
    }
    handleSubmit();
  };

  const changeHandleChange = e => {
    setRegLoading(true);
    const param = {
      group_id: e?.currentTarget?.value || e
    };

    ClientServices.listRegistrationCodeByGroup(param)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setRegistrationCodeList(data.data);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
        setRegLoading(false);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
        setRegLoading(false);
      });
  };

  const regCodeByRD = value => {
    setRegLoading(true);
    const param = {
      rd_id: value
    };
    ClientServices.listRegistrationCodeByRD(param)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setRegistrationCodeList(data.data);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
        setRegLoading(false);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });
  };

  const rdListChangeHandle = (e, service) => {
    setRdList([]);
    const param = {
      id: e?.target?.value || service,
      practice_id:
        props.authData &&
        props.authData?.adminRoleId === 6 &&
        props.authData?.practice
          ? props.authData?.practice
          : undefined
    };

    ClientServices.RDList(param)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);

        if (data.status) {
          setRdList(data.data);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });
  };
  const handleServiceChange = e => {
    setFieldValue("service_id", e.target.value);
    handleChange(e);
    onServiceIdChange(e.target.value); // Call the handler passed from parent

    setCategory([]);
    setFieldValue("category_id", "");
    rdListChangeHandle(e);
  };

  return (
    <div>
      <form>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">
              First Name <span className="asterisk">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="first_name"
              onChange={e => {
                setFieldValue("first_name", e.target.value);
                setFieldTouched("first_name", true, true);
              }}
              onBlur={handleBlur}
              value={values.first_name}
              placeholder="Add First Name"
            />
            <Error field="first_name" />
          </div>
          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">
              Last Name <span className="asterisk">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="last_name"
              onChange={e => {
                setFieldValue("last_name", e.target.value);
                setFieldTouched("last_name", true, true);
              }}
              onBlur={handleBlur}
              value={values.last_name}
              placeholder="Add Last Name"
            />
            <Error field="last_name" />
          </div>
          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">
              Email <span className="asterisk">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              placeholder="Add Email"
            />
            <Error field="email" />
          </div>
          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">
              Service<span className="asterisk">*</span>
            </label>
            <Input
              type="select"
              name="service_id"
              id="service_id"
              onChange={handleServiceChange}
              // onChange={e => {
              //   setCategory([]);
              //   setFieldValue("category_id", "");
              //   rdListChangeHandle(e);
              //   handleChange(e);
              // }}
              value={values?.service_id}
              onBlur={handleBlur}
              disabled={props?.authData?.service_id}
            >
              <option value="">Select Service</option>
              {serviceList.map((service, i) => {
                return (
                  <Fragment>
                    <option key={`option${i}`} value={service.id}>
                      {service.name}
                    </option>
                  </Fragment>
                );
              })}
            </Input>
            <Error field="service_id" />
          </div>
          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">
              Category<span className="asterisk"> *</span>
            </label>
            <Input
              type="select"
              name="category_id"
              id="category_id"
              onChange={handleChange}
              value={values.category_id}
              onBlur={handleBlur}
              disabled={!values?.service_id}
            >
              <option value="">Select Category</option>
              {category.map((item, i) => {
                return (
                  <option key={`option${i}`} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </Input>
            <Error field="category_id" />
          </div>
          {props.authData.adminRoleId !== RD ? (
            <div className="form-group col-md-6">
              <label className="fs-16 medium-text">
                Provider<span className="asterisk"> *</span>
              </label>
              <Input
                type="select"
                name="rd_id"
                id="rd_id"
                onChange={e => {
                  setIsIbo(false);
                  setFieldValue("registration_code_id", "");
                  setRegistrationCodeList([]);
                  setFieldValue("group_id", "");
                  handleChange(e);
                }}
                value={values.rd_id}
                onBlur={handleBlur}
                disabled={!values.service_id}
              >
                <option value="">Select Provider</option>
                {rdList.map((item, i) => {
                  return (
                    <option key={`option${i}`} value={item.id}>
                      {item.first_name} {item.last_name} (
                      {item?.rd_detail?.type
                        ? PROVIDER_TYPE[item?.rd_detail?.type]
                        : "-"}
                      )
                    </option>
                  );
                })}
              </Input>
              <Error field="rd_id" />
            </div>
          ) : (
            ""
          )}
          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">
              Group<span className="asterisk"> *</span>
            </label>
            <Input
              type="select"
              name="group_id"
              id="group_id"
              onChange={e => {
                setRegistrationCodeList([]);
                setFieldValue("registration_code_id", "");
                handleChange(e);
                changeHandleChange(e);
              }}
              value={values.group_id}
              onBlur={handleBlur}
              disabled={isIbo}
            >
              <option value="">Select Group</option>
              {groupList.map((item, i) => {
                return (
                  <option key={`option${i}`} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </Input>
            <Error field="group_id" />
          </div>
          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">
              Registration Code<span className="asterisk"> *</span>
            </label>
            <Input
              type="select"
              name="registration_code_id"
              id="registration_code_id"
              onChange={handleChange}
              value={values.registration_code_id}
              onBlur={handleBlur}
            >
              <option value="">
                {regLoading ? "Loading Codes.." : "Select Registration Code"}
              </option>
              {registrationCodeList.map((item, i) => {
                return (
                  <option key={`option${i}`} value={item.id}>
                    {item.code}
                  </option>
                );
              })}
            </Input>
            <Error field="registration_code_id" />
          </div>

          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">
              Insurance<span className="asterisk"> *</span>
            </label>
            <Input
              type="select"
              name="insurance_id"
              id="insurance_id"
              onChange={handleChange}
              value={values.insurance_id}
              onBlur={handleBlur}
            >
              <option value="">Select Insurance</option>
              {insuranceList.map((item, i) => {
                return (
                  <option key={`option${i}`} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </Input>
            <Error field="insurance_id" />
          </div>
          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">
              Date of Birth <span className="asterisk">*</span>
            </label>
            <input
              type="date"
              className="form-control react-form-input"
              id="dob"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.dob}
              placeholder="Add DOB"
            />
            <Error field="dob" />
          </div>
          <div className="form-group col-md-6">
            <div>
              <label className="fs-16 medium-text">
                Gender<span className="asterisk"> *</span>
              </label>
              <Input
                type="select"
                name="gender"
                id="gender"
                onChange={handleChange}
                value={values.gender}
                onBlur={handleBlur}
              >
                <option value="">Select Gender</option>
                {genderList.map((item, i) => {
                  return (
                    <option key={`option${i}`} value={item.key}>
                      {item.value}
                    </option>
                  );
                })}
              </Input>
              <Error field="gender" />
            </div>
            <div>
              <label className="fs-16 medium-text">Profile Photo</label>
              <br />
              <div className="file-upload">
                <label className="c-btn c-primary form-button fs-16 demi-bold-text mt-15">
                  Upload image
                  <input
                    id="profile_photo"
                    className="file-upload__input"
                    name="file-upload"
                    type="file"
                    accept="image/jpg,image/png,image/jpeg"
                    onChange={e => handleImage(e, setFieldValue)}
                    onBlur={handleBlur}
                    onClick={event => {
                      event.target.value = "";
                    }}
                  />
                </label>
              </div>
              <br />
              {
                <img
                  src={
                    uploadImage
                      ? uploadImage
                      : values.profile_photo || PLACEHOLDER
                  }
                  alt=""
                  className="mtb-15"
                  style={{
                    width: "200px",
                    borderRadius: "4%",
                    height: "150px",
                    background: "#404040"
                  }}
                />
              }
              <br />
              <Error field="profile_photo" />
            </div>
          </div>
          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">
              Zipcode <span className="asterisk">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="zipcode"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.zipcode}
              placeholder="Add Zipcode"
            />
            <Error field="zipcode" />
          </div>
        </div>
        <div>
          <Button
            type="button"
            className="c-btn c-info form-button fs-16 demi-bold-text"
            style={{ maxWidth: "125px" }}
            onClick={handleSubmit}
            loading={isLoading}
            disabled={isLoading}
          >
            Submit
          </Button>
        </div>
      </form>
      <Toaster ref={toaster} />
    </div>
  );
};

export default compose(enhancer)(ClientEditForm);
