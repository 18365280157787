import React, { useRef, useState } from "react";
import { compose } from "redux";
import enhancer from "../Rewards/rewardsActivityValidator";
import EncryptDecrypt from "api/EncryptDecrypt";
import Button from "components/button/Button";
import RewardsManagementServices from "api/RewardsMangementServices";
import Toaster from "components/common/Toaster";

const exceptThisSymbols = ["e", "E", "+", "-", "."];

const EditRewardsActivityModal = props => {
  const toaster = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleChange,
    handleBlur,
    submitCount,
    errors,
    values,
    touched,
    handleSubmit
  } = props;

  const handleFormSubmit = () => {
    let { values, isValid } = props;
    if (isValid) {
      const params = {
        reward_activity_id: values?.id,
        unit_point: values.unit_point,
        max_point: values.max_point,
        display_name: values.name,
        note: values.note
      };
      setIsLoading(true);
      RewardsManagementServices.editRewardsAcivity(params)
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          if (data.status) {
            if (toaster.current) toaster.current.success(data.message);
            setTimeout(() => {
              props.toggle();
              props.pageRefresh();
              setIsLoading(false);
            }, 1000);
          } else {
            if (toaster.current) toaster.current.error(data.message);
            setIsLoading(false);
          }
        })
        .catch(err => {
          if (toaster.current) toaster.current.error(err.response);
          setIsLoading(false);
        });
    }
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <div className={props.class ? props.class : "error-msg mt-2"}>
          {errors[field1]}
        </div>
      );
    } else {
      return <span />;
    }
  };

  return (
    <div className="p-2">
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">
              Name<span className="asterisk">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
            />
            <Error field="name" />
          </div>
          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">
              Unit Points <span className="asterisk">*</span>
            </label>
            <input
              type="number"
              className="form-control react-form-input"
              id="unit_point"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.unit_point}
              placeholder="Add point"
              onKeyDown={e =>
                exceptThisSymbols.includes(e.key) && e.preventDefault()
              }
            />
            <Error field="unit_point" />
          </div>
          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">
              Note:<span className="asterisk">*</span>
            </label>
            <textarea
              className="form-control react-form-input"
              rows="3"
              placeholder="Add Note"
              id="note"
              name="note"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.note}
            ></textarea>
            <Error field="note" />
          </div>
          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">
              Max Points <span className="asterisk">*</span>
            </label>
            <input
              type="number"
              className="form-control react-form-input"
              id="max_point"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.max_point}
              placeholder="Add point"
              onKeyDown={e =>
                exceptThisSymbols.includes(e.key) && e.preventDefault()
              }
            />
            <Error field="max_point" />
          </div>
        </div>
        <div>
          <Button
            type="submit"
            className="c-btn c-info form-button fs-16 demi-bold-text"
            style={{ maxWidth: "125px" }}
            onClick={handleFormSubmit}
            loading={isLoading}
            disabled={isLoading}
            dataStyle={"expand-right"}
          >
            Submit
          </Button>
        </div>
      </form>
      <Toaster ref={toaster} />
    </div>
  );
};
export default compose(enhancer)(EditRewardsActivityModal);
