import React, { useEffect, useRef, useState } from "react";
import UserWrapper from "./viewClient.style";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";
import ClientServices from "../../api/ClientServices";
import RdServices from "../../api/RdServices";
import ClientEditForm from "../../components/viewclient/ClientEditForm";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import Toaster from "components/common/Toaster";

const ClientEdit = props => {
  const toaster = useRef(null);
  const [editedData, setEditedData] = useState(undefined);
  const [category, setCategory] = useState([]);
  const [state, setState] = useState([]);

  const hasParam = props.match.params.id;
  useEffect(() => {
    if (hasParam) {
      const params = {
        id: hasParam
      };
      ClientServices.view(params)
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          setEditedData(data.data);
        })
        .catch(err => {
          if (err?.response?.status === 403) {
            if (toaster.current) {
              toaster.current.error("You cannot access this user info!");
              setTimeout(() => {
                props.history.push("/client");
              }, 3000);
            }
          } else {
            if (toaster.current) toaster.current.error(err?.message);
          }
        });
    }
    RdServices.listCategory().then(res => {
      let data = EncryptDecrypt.decrypt(res.data);
      setCategory(data.data);
    });
    RdServices.stateList().then(res => {
      let data = EncryptDecrypt.decrypt(res.data);
      setState(data.data);
    });
  }, []);

  const submitFormHandler = (data, action) => {
    setTimeout(() => {
      props.history.push("/client");
    }, 2000);
  };

  return (
    <UserWrapper {...props}>
      <div className="pos-relative">
        <PageTitle
          props={props}
          title="sidebar.client_management"
          className="plr-15"
          breadCrumb={[
            {
              name: "sidebar.dashboard",
              url: "/dashboard"
            },
            {
              name: "sidebar.client_management",
              url: "/client"
            },
            {
              name: hasParam ? "action.edit" : "action.add"
            }
          ]}
        />
        <div
          className="back-icon fs-15 demi-bold-text cursor-pointer"
          onClick={() => props.history.push("/client")}
        >
          <i className="fas fa-step-backward"></i> Back
        </div>
      </div>

      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> {hasParam ? "Edit" : "Add"}{" "}
              Client
            </div>
          </div>
          <div className="roe-card-body">
            <ClientEditForm
              onSubmit={submitFormHandler}
              editedData={editedData}
              category={category || []}
              state={state || []}
              action={hasParam ? "edit" : "add"}
              auth={props?.auth}
            />
          </div>
        </div>
      </div>
      <Toaster ref={toaster} />
    </UserWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    ...state
  };
};

export default connect(mapStateToProps, null)(ClientEdit);
