import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .trim()
      .required("This field is required")
      .max(100, "Name must be less than 100 characters"),
    payor_id: Yup.string()
      .trim("Cannot include leading and trailing spaces")
      .strict(true)
  }),
  mapPropsToValues: props => ({
    id: props.editedData
      ? props && props.editedData && props.editedData.id
      : "",
    name: props.editedData
      ? props && props.editedData && props.editedData.name
      : "",
    payor_id: props.editedData
      ? props && props.editedData && props.editedData.payor_id
      : "",
    is_display_registration: props.editedData
      ? props &&
        props.editedData &&
        props.editedData.is_display_registration.toString()
      : "1"
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
