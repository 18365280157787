import React from "react";
import Button from "../button/Button";
import { PLACEHOLDER } from "helper/constant";

const ViewProductModal = props => {
  return (
    <form>
      <div className="roe-card-body flex space-between pa-28">
        <div className="pr-4">
          <div className="pt-2">
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>ID</div>
            <div>{props?.editedData?.id}</div>
          </div>
          <div className="pt-2">
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>Name</div>
            <div>{props?.editedData?.product_name}</div>
          </div>
          <div className="pt-2">
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>
              Product image
            </div>
            <div>
              {props?.editedData?.reward_product_variations.map(value => (
                <img
                  className="top-header-profile-class"
                  style={{
                    height: "150px",
                    width: "150px",
                    marginBottom: "15px",
                    borderRadius: "10px",
                    marginTop: "10px",
                    marginRight: "20px"
                  }}
                  src={value.image || PLACEHOLDER}
                  alt="Product"
                />
              ))}
            </div>
          </div>
          <div className="pt-2">
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>
              Description
            </div>
            <div>
              <div
                className="text-left"
                dangerouslySetInnerHTML={{
                  __html: props?.editedData?.description
                }}
              />
            </div>
          </div>
          <div className="pt-2">
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>
              SKU Number
            </div>
            <div>
              <div>{props?.editedData?.sku_no}</div>
            </div>
          </div>
          <div className="pt-2">
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>
              Rewards Tier
            </div>
            <div>
              <div>{props?.editedData?.reward_tier?.name}</div>
            </div>
          </div>
          <div className="pt-2">
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>
              Status
            </div>
            <div>
              <div>{props?.editedData?.is_active ? "Active" : "Inactive"}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex-x center">
        <Button
          type="button"
          className="c-btn c-secondary form-button fs-16 demi-bold-text"
          style={{ maxWidth: "125px" }}
          onClick={props.toggle}
        >
          Close
        </Button>
      </div>
    </form>
  );
};

export default ViewProductModal;
