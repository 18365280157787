import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    service_id: Yup.string()
      .trim()
      .required("This field is required"),
    rd_id: Yup.string()
      .trim()
      .required("This field is required"),
    type: Yup.string()
      .trim()
      .required("This field is required"),
    upload_file: Yup.mixed()
      .required("File is required")
      .test("type", "Only .csv file format is accepted", value => {
        return value && value.type === "text/csv";
      })
  }),
  mapPropsToValues: props => ({
    service_id: "",
    rd_id: "",
    upload_file: "",
    type: ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
