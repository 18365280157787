import React, { useRef, useState } from "react";
import { iconDemo } from "helper/constant";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import AuthActions from "redux/auth/actions";
import enhancer from "./enhancer/LoginFormEnhancer";
import AdminServices from "../../../api/AdminServices";
import Toaster from "../../../components/common/Toaster";
import Button from "../../../components/button/Button";
import EncryptDecrypt from "../../../api/EncryptDecrypt";
import showPwdImg from "../../../assets/images/show-password.svg";
import hidePwdImg from "../../../assets/images/hide-password.svg";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { useEffect } from "react";
import moment from "moment-timezone";

const firebaseConfig = {
  apiKey: "AIzaSyAXJdcy--YN5iOuJ0uZ7lOt4wWz-aPSuFA",
  authDomain: "charge-3cd9b.firebaseapp.com",
  projectId: "charge-3cd9b",
  storageBucket: "charge-3cd9b.appspot.com",
  messagingSenderId: "927808025019",
  appId: "1:927808025019:web:8cc540e3518f04b90fd49e",
  measurementId: "G-TL3B75N1K6"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging();

const { login } = AuthActions;

const Login = props => {
  const toaster = useRef();
  const [deviceToken, setDeviceToken] = useState("");
  const [loading, setloading] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);

  useEffect(() => {
    getToken(messaging, {
      vapidKey:
        "BH2TjRHvVKDbj0p2oCVV-QulifNJvJg_lPrvLe1MjbHshv2fxPkyr786Pjr0qJGH8mV6NIZb53sGxTOYTnNZ_ng"
    })
      .then(currentToken => {
        if (currentToken) {
          setDeviceToken(currentToken);
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
        }
      })
      .catch(err => {
        console.log("An error occurred while retrieving token. ", err);
      });
  }, []);

  const handleLogin = e => {
    e.preventDefault();
    let { values, handleSubmit } = props;

    if (values.email !== "" && values.password !== "") {
      const params = {
        email: values.email,
        password: values.password,
        device_token: deviceToken,
        timezone: moment.tz.guess()
      };
      setloading(true);
      AdminServices.login(params)
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          if (data.status) {
            if (data.data.token === undefined) {
              let loginData = {
                token: "",
                full_name: data.data.full_name,
                email: data.data.email,
                id: data.data.id,
                profile_image: data.data.profile_image,
                isLogin: false
              };
              props.login(loginData);
              setloading(false);
              props.history.push("/oauth", loginData);
            } else {
              let loginData = {
                token: data.data.token,
                first_name: data.data.first_name,
                last_name: data.data.last_name,
                email: data.data.email,
                id: data.data.id,
                profile_image: data.data.profile_image,
                admin_role_id: data.data.admin_role_id,
                admin_role: data.data.admin_role.role,
                isLogin: true,
                stream_io_token:
                  data.data.stream_io_token && data.data.stream_io_token,
                calendly_link: data.data.rd_detail
                  ? data.data.rd_detail.calendly_link
                  : null
              };
              props.login(loginData);
              setloading(false);
              props.history.push("/dashboard");
            }
          } else {
            setloading(false);
            if (toaster.current) toaster.current.error(data.message);
          }
        })
        .catch(err => {
          setloading(false);
          if (toaster.current)
            toaster.current.error("Something went wrong. Please try later.");
        });
      //   const data = {
      //     token: "DEMOJWTTOKEN"
      //   };
      // using this method you can store token in redux

      //   props.history.push("/dashboard");
    }
    handleSubmit();
  };

  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount
  } = props;

  const loginContainer = {
    //backgroundImage: `url(${loginBack})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0,
    backgroundColor: "#AECE38"
  };
  const loginIcon = {
    backgroundColor: "#fff"
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <div className="container-fluid" style={loginContainer}>
      <div className="form-container">
        <div className="login-icon" style={loginIcon}>
          <img src={iconDemo} alt="icon" height="50px" />
        </div>
        <div className="login-title pt-4">Sign in to your account</div>
        <form className="pa-24" onSubmit={handleLogin}>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              className="form-control react-form-input"
              id="email"
              onChange={handleChange}
              value={values.email}
              onBlur={handleBlur}
              placeholder="Email"
            />
            <Error field="email" />
          </div>

          <div className="form-group">
            <label>Password </label>
            <div style={{ position: "relative" }}>
              <input
                type={isRevealPwd ? "text" : "password"}
                className="form-control react-form-input"
                id="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Password"
              />
              <img
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  width: "20px",
                  right: "8px",
                  top: "8px"
                }}
                title={isRevealPwd ? "Hide password" : "Show password"}
                src={isRevealPwd ? hidePwdImg : showPwdImg}
                onClick={() => setIsRevealPwd(prevState => !prevState)}
              />
            </div>
            <Error field="password" />
          </div>

          <Button
            type="submit"
            loading={loading}
            className="btn form-button"
            dataStyle="expand-right"
            style={{ position: "relative" }}
          >
            Login
          </Button>
          <div
            className="text-center link-label"
            onClick={() => props.history.push("/forgotPassword")}
          >
            Forgot Password ?
          </div>
        </form>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};

export default compose(withRouter, enhancer, connect(null, { login }))(Login);
