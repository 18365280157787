import React, { useRef, useEffect, useState, Fragment } from "react";
import { compose } from "redux";
import enhancer from "../insurancecarrier/validator";
import Button from "../button/Button";
import Toaster from "../common/Toaster";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import VisitInsuranceCarrier from "../../api/VisitInsuranceCarrier";
import DatePicker from "react-datepicker";
import moment from "moment";
import AllDatePickers from "components/forms/alldatepickers/AllDatePicker";
import { ENCRYPTKEY, ImageResolution } from "../../helper/constant";
import { Input } from "reactstrap";
import { PLACEHOLDER } from "helper/constant";

import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
let primarySub = ["Self", "Spouse", "Dependent"];
const genderList = [
  {
    key: "M",
    value: "Male"
  },
  {
    key: "F",
    value: "Female"
  },
  {
    key: "U",
    value: "Unknown"
  }
];

const AddInsurance = props => {
  const toaster = useRef();

  const hasParam = props.match.params.id;
  const [startDate, setStartDate] = useState(new Date());
  const {
    handleChange,
    handleBlur,
    setFieldTouched,
    setFieldValue,
    submitCount,
    errors,
    values,
    touched,
    isValid,
    handleSubmit
  } = props;

  const [loading, setLoading] = useState(false);
  const [insuranceOpen, setInsuranceOpen] = useState(false);
  const [insuranceArr, setInsuranceArr] = useState([]);
  const [primaryOpen, setPrimaryOpen] = useState(false);
  const [imageObj, setImageObj] = useState(null);
  const [imageObjOne, setImageObjOne] = useState(null);

  const [filterIndex, setFilterIndex] = useState(null);
  const [primaryIndex, setPrimaryIndex] = useState(null);

  useEffect(() => {
    VisitInsuranceCarrier.insuranceDropDown()
      .then(result => {
        let data = EncryptDecrypt.decrypt(result.data);

        setInsuranceArr(data.data);
      })
      .catch(error => {});
  }, []);
  const toggleInsurance = () => setInsuranceOpen(!insuranceOpen);
  const togglePrimary = () => setPrimaryOpen(!primaryOpen);
  const handleNote = () => {
    let { values, isValid, handleSubmit } = props;
    if (isValid) {
      setLoading(true);
      if (props.editedData.type === "add") {
        let insuranceObj = {
          insurance_id: values.insurance_id?.id,

          member_id: values.member_id,
          group_id: values.group_id,
          user_id: hasParam,
          is_primary_subscriber: primaryIndex,
          primary_subscriber_first_name:
            values.subscriber_data &&
            values.subscriber_data.primary_subscriber_first_name,
          primary_subscriber_last_name:
            values.subscriber_data &&
            values.subscriber_data.primary_subscriber_last_name,
          primary_subscriber_dob:
            values.subscriber_data &&
            values.subscriber_data.primary_subscriber_dob,
          primary_subscriber_gender:
            values.subscriber_data &&
            values.subscriber_data.primary_subscriber_gender
        };

        let data1 = EncryptDecrypt.encrypt(insuranceObj);
        const formdata = new FormData();
        formdata.append("data", data1?.data);
        if (imageObj) formdata.append("data1", imageObj);
        if (imageObjOne) formdata.append("data2", imageObjOne);

        VisitInsuranceCarrier.add(formdata).then(result => {
          setLoading(false);
          props.toggle();
          props.pageRefresh();
        });
      }
      if (props.editedData.type === "edit") {
        let insuranceObj = {
          id: props.editedData?.id,
          member_id: values.member_id,
          group_id: values.group_id,
          insurance_id: values.insurance_id?.id,
          is_primary_subscriber: values.subscriber,
          primary_subscriber_first_name:
            values.subscriber_data &&
            values.subscriber_data.primary_subscriber_first_name,
          primary_subscriber_last_name:
            values.subscriber_data &&
            values.subscriber_data.primary_subscriber_last_name,
          primary_subscriber_dob:
            values.subscriber_data &&
            values.subscriber_data.primary_subscriber_dob,
          primary_subscriber_gender:
            values.subscriber_data &&
            values.subscriber_data.primary_subscriber_gender
        };

        let data1 = EncryptDecrypt.encrypt(insuranceObj);
        const formdata = new FormData();
        formdata.append("data", data1?.data);
        if (imageObj) formdata.append("data1", imageObj);
        if (imageObjOne) formdata.append("data2", imageObjOne);

        VisitInsuranceCarrier.edit(formdata).then(result => {
          setLoading(false);
          props.toggle();
          props.pageRefresh();
        });
      }
    }

    handleSubmit();
  };
  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <div className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </div>
      );
    } else {
      return <span />;
    }
  };

  const Error2 = props => {
    const field1 = props.field1;
    if ("subscriber_data") {
      if (errors["subscriber_data"] && touched["subscriber_data"]) {
        if (
          (errors["subscriber_data"][field1] &&
            touched["subscriber_data"][field1]) ||
          submitCount > 0
        ) {
          return (
            <div className="error-msg">{errors["subscriber_data"][field1]}</div>
          );
        } else {
          return <div />;
        }
      } else {
        return <div />;
      }
    } else {
      return <div />;
    }
  };
  const handleFrontImage = (e, setValue) => {
    if (e.target.files[0]) {
      let fileObj = e.target.files[0];

      if (fileObj.size > 10 * 1024 * 1024) {
        if (toaster.current)
          toaster.current.error("Please upload a file smaller than 10 MB");
        return false;
      }

      if (!fileObj.name.match(/\.(jpg|jpeg|png|gif|heif)$/)) {
        if (toaster.current)
          toaster.current.error(
            "Only .jpg, .jpeg, .gif, .png, .heif are allowed"
          );
        return false;
      }
      if (fileObj) {
        const img = new Image();
        img.src = window.URL.createObjectURL(fileObj);
        img.onload = function() {
          const width = img.naturalWidth,
            height = img.naturalHeight;
          window.URL.revokeObjectURL(img.src);
          setValue("width", width);
          setValue("height", height);
          setFieldTouched("front_insurance_image", true, true);
          if (
            width >= ImageResolution.WIDTH &&
            height >= ImageResolution.HEIGHT
          ) {
            setImageObj(fileObj);
            setValue("front_insurance_image", URL.createObjectURL(fileObj));
          }
        };
      } else {
        //No file was input or browser doesn't support client side reading
        // form.submit();
      }
    }
  };
  const handleBackImage = (e, setValue) => {
    if (e.target.files[0]) {
      let fileObj = e.target.files[0];
      if (fileObj.size > 10 * 1024 * 1024) {
        if (toaster.current)
          toaster.current.error("Please upload a file smaller than 10 MB");
        return false;
      }

      if (!fileObj.name.match(/\.(jpg|jpeg|png|gif|heif)$/)) {
        if (toaster.current)
          toaster.current.error(
            "Only .jpg, .jpeg, .gif, .png, .heif are allowed"
          );
        return false;
      }

      if (fileObj) {
        const img = new Image();
        img.src = window.URL.createObjectURL(fileObj);
        img.onload = function() {
          const width = img.naturalWidth,
            height = img.naturalHeight;
          window.URL.revokeObjectURL(img.src);
          setValue("width", width);
          setValue("height", height);
          setFieldTouched("back_insurance_image", true, true);
          if (
            width >= ImageResolution.WIDTH &&
            height >= ImageResolution.HEIGHT
          ) {
            setImageObjOne(fileObj);
            setValue("back_insurance_image", URL.createObjectURL(fileObj));
          }
        };
        handleChange();
      } else {
        //No file was input or browser doesn't support client side reading
        // form.submit();
      }
    }
  };

  return (
    <div>
      <form className="pa-24 row">
        <div className="form-group col-md-6">
          <label className="fs-16 medium-text">Select Insurance:</label>
          <Dropdown isOpen={insuranceOpen} toggle={toggleInsurance}>
            <DropdownToggle caret size="md">
              {values.insurance_id && values.insurance_id.name
                ? values.insurance_id.name
                : "Select Insurance"}
            </DropdownToggle>
            <DropdownMenu
              style={{ width: "100%", height: "50vh", overflow: "scroll" }}
            >
              {insuranceArr.map((result, i) => {
                return (
                  <DropdownItem
                    key={`insurance_${i}`}
                    onClick={() => {
                      setFilterIndex(result);
                      setFieldTouched("insurance_id", true, true);
                      setFieldValue("insurance_id", result);
                    }}
                  >
                    {result.name}
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </Dropdown>
          <Error field="insurance_id" />
        </div>
        <div className="form-group col-md-6">
          <label className="fs-16 medium-text">Member ID:</label>
          <input
            type="text"
            className="form-control react-form-input"
            id="member_id"
            onChange={e => {
              setFieldValue("member_id", e.target.value);
            }}
            value={values.member_id}
            onBlur={handleBlur}
            placeholder="Member ID"
          />
          <Error field="member_id" />
        </div>
        <div className="form-group col-md-6">
          <label className="fs-16 medium-text">Group ID:</label>
          <input
            type="text"
            className="form-control react-form-input"
            id="group_id"
            onChange={e => {
              setFieldValue("group_id", e.target.value);
            }}
            value={values.group_id}
            onBlur={handleBlur}
            placeholder="Group ID"
          />
          <Error field="group_id" />
        </div>
        <div className="form-group col-md-6">
          <label className="fs-16 medium-text">Primary Subscriber:</label>
          <Dropdown isOpen={primaryOpen} toggle={togglePrimary}>
            <DropdownToggle caret size="md">
              {values.subscriber && values.subscriber
                ? values.subscriber
                : "Primary subscriber"}
            </DropdownToggle>
            <DropdownMenu>
              {primarySub.map((result, i) => {
                return (
                  <DropdownItem
                    key={`insurance_${i}`}
                    onClick={() => {
                      setPrimaryIndex(result);
                      setFieldTouched("subscriber", true, true);
                      setFieldValue("subscriber", result);
                      if (result === "Self") {
                        setFieldValue("is_dependent", false);
                        setFieldValue("subscriber_data", null);
                      } else {
                        setFieldValue("is_dependent", true);
                      }
                    }}
                  >
                    {result}
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </Dropdown>
          <Error field="subscriber" />
        </div>
        {values && values.subscriber !== "Self" && (
          <Fragment>
            <div className="form-group col-md-6">
              <label className="fs-16 medium-text">
                Primary Subscriber First Name:
              </label>
              <input
                type="text"
                className="form-control react-form-input"
                id="primary_subscriber_first_name"
                onChange={e => {
                  let obj = values.subscriber_data
                    ? { ...values.subscriber_data }
                    : {};
                  obj.primary_subscriber_first_name = e.target.value;
                  setFieldTouched("subscriber_data", true, true);
                  setFieldValue("subscriber_data", obj);
                  setFieldValue(
                    "primary_subscriber_first_name_new",
                    e.target.value
                  );
                }}
                value={
                  values.subscriber_data
                    ? values.subscriber_data.primary_subscriber_first_name || ""
                    : ""
                }
                onBlur={handleBlur}
                placeholder="First name"
              />
              {props?.errors?.subscriber_data
                ?.primary_subscriber_first_name && (
                <Error2 field1={"primary_subscriber_first_name"} />
              )}
              {props?.errors?.primary_subscriber_first_name_new && (
                <Error field={"primary_subscriber_first_name_new"} />
              )}
            </div>
            <div className="form-group col-md-6">
              <label className="fs-16 medium-text">
                Primary Subscriber Last Name:
              </label>
              <input
                type="text"
                className="form-control react-form-input"
                id="primary_subscriber_last_name"
                onChange={e => {
                  let obj = values.subscriber_data
                    ? { ...values.subscriber_data }
                    : {};
                  obj.primary_subscriber_last_name = e.target.value;
                  setFieldTouched("subscriber_data", true, true);
                  setFieldValue("subscriber_data", obj);
                  setFieldValue(
                    "primary_subscriber_last_name_new",
                    e.target.value
                  );
                }}
                value={
                  values.subscriber_data
                    ? values.subscriber_data.primary_subscriber_last_name || ""
                    : ""
                }
                onBlur={handleBlur}
                placeholder="Last name"
              />
              {props?.errors?.subscriber_data?.primary_subscriber_last_name && (
                <Error2 field1={"primary_subscriber_last_name"} />
              )}
              {props?.errors?.primary_subscriber_last_name_new && (
                <Error field={"primary_subscriber_last_name_new"} />
              )}
            </div>
            <div className="form-group col-md-6">
              <label className="fs-16 medium-text">
                Primary Subscriber DOB:
              </label>

              <DatePicker
                selected={startDate}
                onChange={e => {
                  let obj = values.subscriber_data
                    ? { ...values.subscriber_data }
                    : {};
                  obj.primary_subscriber_dob = e;
                  setFieldTouched("subscriber_data", true, true);
                  setFieldValue("subscriber_data", obj);
                  setStartDate(e);
                }}
                value={
                  values.subscriber_data
                    ? values.subscriber_data.primary_subscriber_dob || ""
                    : ""
                }
                className="form-control react-form-input custom-datepicker"
                calendarClassName="custom-calender-class"
              />
              <Error2 field1="primary_subscriber_dob" />
            </div>
            <div className="form-group col-md-6">
              <label className="fs-16 medium-text">
                Primary Subscriber Gender:
              </label>
              <Input
                type="select"
                name="gender"
                id="gender"
                // onChange={handleChange}
                onChange={e => {
                  let obj = values.subscriber_data
                    ? { ...values.subscriber_data }
                    : {};
                  obj.primary_subscriber_gender = e.target.value;
                  setFieldTouched("subscriber_data", true, true);
                  setFieldValue("subscriber_data", obj);
                }}
                value={
                  values.subscriber_data
                    ? values.subscriber_data.primary_subscriber_gender || ""
                    : ""
                }
                onBlur={handleBlur}
              >
                <option value="">Select Gender</option>
                {genderList.map((item, i) => {
                  return (
                    <option key={`option${i}`} value={item.key}>
                      {item.value}
                    </option>
                  );
                })}
              </Input>
              <Error2 field1="primary_subscriber_gender" />
            </div>
          </Fragment>
        )}
        <div className="form-group col-md-6">
          <label className="fs-16 medium-text">Front image:</label>
          <br />

          <label className="fs-16 medium-text c-btn c-primary demi-bold-text mt-15 wp-17">
            Choose Image
            <input
              id="front_insurance_image"
              className="file-upload__input"
              name="front_insurance_image"
              type="file"
              onChange={e => {
                handleFrontImage(e, setFieldValue);
                setFieldTouched("front_insurance_image", true, true);
              }}
              onBlur={handleBlur}
              required={true}
              accept="image/png, image/jpeg, image/jpg, image/gif, image/heif"
            />
          </label>
          <Error field="front_insurance_image" />
          <div>
            <img
              src={values.front_insurance_image || PLACEHOLDER}
              alt=""
              className="mtb-15"
              style={{
                width: "200px",
                borderRadius: "4%",
                height: "150px",
                background: "#404040"
              }}
            />
          </div>
        </div>
        <div className="form-group col-md-6">
          <label className="fs-16 medium-text">Back image:</label> <br />
          <div className="form-group">
            <label className="fs-16 medium-text c-btn c-primary demi-bold-text mt-15 wp-17">
              Choose Image
              <input
                id="back_insurance_image"
                className="file-upload__input"
                name="back_insurance_image"
                type="file"
                onChange={e => {
                  handleBackImage(e, setFieldValue);
                  setFieldTouched("back_insurance_image", true, true);
                }}
                onBlur={handleBlur}
                required={true}
                accept="image/png, image/jpeg, image/jpg"
              />
            </label>
            <Error field="back_insurance_image" />
            <div>
              <img
                src={values.back_insurance_image || PLACEHOLDER}
                alt=""
                className="mtb-15"
                style={{
                  width: "200px",
                  borderRadius: "4%",
                  height: "150px",
                  background: "#404040"
                }}
              />
            </div>
            {/* {imageObjOne ? (
              <img
                src={imageObjOne}
                alt=""
                className="mtb-15"
                style={{
                  width: "200px",
                  borderRadius: "4%",
                  height: "150px",
                  background: "#404040"
                  // display: displaya,
                }}
              // onChange={handleImage}
              />
            ) : ""}
            {props.editedData && props.editedData.back_insurance_image ? (
              <img
                src={props.editedData.back_insurance_image}
                alt=""
                className="mtb-15"
                style={{
                  width: "200px",
                  borderRadius: "4%",
                  height: "150px",
                  background: "#404040"
                  // display: displaya,
                }}
              // onChange={handleImage}
              />
            ) : ""} */}
          </div>
        </div>

        <div className="col-12 text-center">
          <Button
            type="button"
            // loading={loading}
            className="c-btn c-info form-button fs-16 demi-bold-text mr-10"
            style={{ maxWidth: "200px" }}
            onClick={() => {
              handleNote();
            }}
            loading={loading}
            disabled={loading}
            dataStyle={"expand-right"}
          >
            {props.editedData && props.editedData.type == "edit"
              ? "Edit insurance"
              : "Add insurance"}
          </Button>
          <Button
            type="button"
            className="c-btn c-secondary form-button fs-16 demi-bold-text"
            style={{ maxWidth: "200px" }}
            onClick={props.toggle}
          >
            Close
          </Button>
        </div>
      </form>
      <Toaster ref={toaster} />
    </div>
  );
};
export default compose(enhancer)(AddInsurance);
