import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .trim()
      .required("This field is required")
      .max(100, "Name must be less than 100 characters"),
    code: Yup.string()
      .trim()
      .required("This field is required")
      .max(10, "Code must be less than 10 characters")
  }),
  mapPropsToValues: props => ({
    name: "",
    code: ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
