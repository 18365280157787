import EncryptDecrypt from "api/EncryptDecrypt";
import InsuranceServices from "api/InsuranceServices";
import Button from "components/button/Button";
import Loader from "components/common/Loader";
import Pagination from "components/common/Pagination";
import Toaster from "components/common/Toaster";
import { PROVIDER_TYPE, TABLE } from "helper/constant";
import React, { useEffect, useRef, useState } from "react";
import ReactTable from "react-table";
import CommonLookUpWrapper from "./CommonLookUpWrapper";
import CommonServices from "api/CommonServices";

const SEARCH_BY = {
  NAME: "name",
  CPID: "cpid",
  CODE: "code"
};

const HEADING = {
  PROVIDER: "Provider",
  SERVICE_LOC: "Service Location",
  CPT: "CPT Codes",
  DIAGNOSIS: "Diagnosis"
};

export const CommonLookUpSelectionModal = ({ onClose, type, authData }) => {
  const toaster = useRef(null);
  const [amdKey, setAmdKey] = useState(null);
  const [isNextStep, setIsNextStep] = useState(false);
  const [searchBy, setSearchBy] = useState(SEARCH_BY.NAME);
  const [searchText, setSearchText] = useState("");
  const [tblData, setTblData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [field, setField] = useState("id");
  const [sortBy, setSortBy] = useState("ASC");
  const [isSearched, setIsSearched] = useState(false);
  const [amdKeyList, setAmdKeyList] = useState([]);
  const [amdLoading, setAmdLoading] = useState(true);
  const [isExporting, setIsExporting] = useState(false);

  useEffect(() => {
    InsuranceServices.getAMDSettings({
      practice_id: authData?.adminRoleId === 6 ? authData?.practice : undefined,
      service_id:
        authData?.adminRoleId > 2 && authData?.adminRoleId < 6
          ? authData?.service
          : undefined
    })
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setAmdKeyList(data.data);
          setAmdLoading(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
          setAmdLoading(false);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error("Something went wrong!");
        setAmdLoading(false);
      });
  }, []);

  useEffect(() => {
    if (searchText === "" && isSearched) handleSearch("1", searchText, false);
  }, [searchText, isSearched]);

  const handleNext = () => {
    setIsNextStep(true);
    handleSearch("1", searchText, false);
  };

  const handleBack = () => {
    setIsNextStep(false);
    setIsSearched(false);
    setTblData([]);
    setSearchText("");
    setActivePage(0);
    setSearchBy(SEARCH_BY.NAME);
  };

  const handleSearch = (pageNo = "1", search = searchText, isSearch = true) => {
    if (isSearch) {
      setIsSearched(isSearch);
    }
    setLoader(true);

    const params = {
      advancemd_setting_id: amdKey.id,
      search_type: searchBy,
      search_value: search,
      page: pageNo,
      limit: TABLE.LIMIT
    };

    CommonServices.commonLookupList(params, type)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);

        if (data.status) {
          setTblData(data?.data?.rows);
          setPages(Math.ceil(data?.data?.count / TABLE.LIMIT));
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
          setLoader(false);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error("Something went wrong!");
        setLoader(false);
      });
  };

  const handleExport = () => {
    setIsExporting(true);

    const params = {
      advancemd_setting_id: amdKey.id,
      search_type: searchBy,
      search_value: searchText
    };

    CommonServices.commonLookup(params, `${type}_EX`)
      .then(res => {
        setIsExporting(false);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error("Something went wrong!");
        setIsExporting(false);
      });
  };

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  const handleKeyDown = event => {
    if (event.key === "Enter" && searchText) {
      setActivePage(0);
      handleSearch();
    }
  };

  const columns = [
    {
      Header: "ID",
      accessor: "@id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 200,
      sortable: false
    },
    {
      Header: "Code",
      accessor: "@code",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      width: type === "SERVICE_LOC" || type === "CPT" ? 200 : 360
    },
    {
      Header: "Name",
      accessor: "@name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      width: type === "SERVICE_LOC" || type === "CPT" ? 300 : 500
    }
  ];

  if (type === "SERVICE_LOC") {
    columns.push({
      Header: "POS",
      accessor: "@pos",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      width: 150
    });
  }

  if (type === "CPT") {
    const cptCols = [
      {
        Header: "Units",
        accessor: "@units",
        className: "",
        filterable: false,
        headerClassName: "react-table-header-class",
        width: 150,
        sortable: false
      },
      {
        Header: "NDC Code",
        accessor: "@ndccode",
        className: "",
        filterable: false,
        headerClassName: "react-table-header-class",
        sortable: false,
        width: 150
      },
      {
        Header: "NDC Quantity",
        accessor: "@ndcquantity",
        className: "",
        filterable: false,
        headerClassName: "react-table-header-class",
        sortable: false,
        width: 200
      },
      {
        Header: "NDC Price",
        accessor: "@ndcprice",
        className: "",
        filterable: false,
        headerClassName: "react-table-header-class",
        sortable: false,
        width: 150
      },
      {
        Header: "POS",
        accessor: "@pos",
        className: "",
        filterable: false,
        headerClassName: "react-table-header-class",
        sortable: false,
        width: 150
      },
      {
        Header: "TOS",
        accessor: "@tos",
        className: "",
        filterable: false,
        headerClassName: "react-table-header-class",
        sortable: false,
        width: 150
      },
      {
        Header: "REV Code",
        accessor: "@revcode",
        className: "",
        filterable: false,
        headerClassName: "react-table-header-class",
        sortable: false,
        width: 150
      },
      {
        Header: "REV Description",
        accessor: "@revdesc",
        className: "",
        filterable: false,
        headerClassName: "react-table-header-class",
        sortable: false,
        width: 180
      },
      {
        Header: "Default Global Days",
        accessor: "@defaultglobaldays",
        className: "",
        filterable: false,
        headerClassName: "react-table-header-class",
        sortable: false,
        width: 250
      },
      {
        Header: "Macro",
        accessor: "@macro",
        className: "",
        filterable: false,
        headerClassName: "react-table-header-class",
        sortable: false,
        width: 150
      }
    ];
    cptCols.forEach(el => columns.push(el));
  }

  return (
    <CommonLookUpWrapper>
      <div className="flex flex-y pa-10">
        <div className="flex flex-x align-items-center justify-content-between">
          <div className="font-bold fs-22">{HEADING[type]} Lookup</div>
          {isNextStep && (
            <div
              className="fs-15 demi-bold-text cursor-pointer"
              onClick={handleBack}
            >
              <i className="fas fa-step-backward"></i> Back
            </div>
          )}
        </div>
        {!isNextStep ? (
          <>
            <div className="ptb-10 fs-18">
              Which key do you want to look up?
            </div>
            {amdLoading && <div>Loading....</div>}
            {!amdLoading && !amdKeyList.length && <div>No Keys Fetched</div>}
            {amdKeyList.length > 0 &&
              !amdLoading &&
              amdKeyList.map(keys => (
                <div className="flex flex-y ">
                  <div
                    className="flex flex-x align-items-center"
                    style={{ paddingBottom: 4, cursor: "pointer" }}
                    onClick={() => setAmdKey(keys)}
                    role="button"
                  >
                    <div
                      style={{
                        height: 14,
                        width: 14,
                        borderRadius: "50%",
                        background:
                          amdKey?.id === keys?.id ? "#475569" : "#e2e8f0",
                        border: "1px solid #94a3b8",
                        marginRight: 6,
                        cursor: "pointer"
                      }}
                    />
                    <div className="fs-18">
                      {keys?.service?.name || keys?.practice_name} -{" "}
                      {PROVIDER_TYPE[keys?.type]} -{keys?.advancemd_office_code}
                    </div>
                  </div>
                </div>
              ))}
            <div>
              <div
                className="flex flex-x justify-content-start ptb-10"
                style={{ marginTop: 8 }}
              >
                <Button
                  type="button"
                  className="c-btn c-info form-button fs-16 demi-bold-text"
                  style={{ maxWidth: "125px" }}
                  onClick={handleNext}
                  disabled={!amdKey}
                >
                  Next
                </Button>
                <Button
                  type="button"
                  className="c-primary c-btn form-button fs-16 demi-bold-text"
                  style={{ maxWidth: "125px", marginLeft: 24 }}
                  onClick={onClose}
                >
                  Close
                </Button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="fs-18">
              Key: {amdKey?.service?.name} - {PROVIDER_TYPE[amdKey?.type]} -
              {amdKey?.advancemd_office_code}
            </div>
            <div className="flex flex-x align-items-center ptb-10">
              <div
                className="flex flex-x align-items-center justify-content-center cursor-pointer"
                role="button"
                onClick={() => {
                  setSearchText("");
                  setSearchBy(SEARCH_BY.NAME);
                }}
              >
                <div
                  style={{
                    height: 14,
                    width: 14,
                    borderRadius: "50%",
                    background:
                      searchBy === SEARCH_BY.NAME ? "#475569" : "#e2e8f0",
                    border: "1px solid #94a3b8",
                    marginRight: 4,
                    cursor: "pointer"
                  }}
                />
                <div className="fs-18">Name</div>
              </div>
              <div
                className="flex flex-x align-items-center justify-content-center cursor-pointer"
                style={{ marginLeft: 20 }}
                role="button"
                onClick={() => {
                  setSearchText("");
                  setSearchBy(SEARCH_BY.CODE);
                }}
              >
                <div
                  style={{
                    height: 14,
                    width: 14,
                    borderRadius: "50%",
                    background:
                      searchBy === SEARCH_BY.CODE ? "#475569" : "#e2e8f0",
                    border: "1px solid #94a3b8",
                    marginRight: 4,
                    cursor: "pointer"
                  }}
                />
                <div className="fs-18">Code</div>
              </div>
            </div>
            <div className="pb-10 flex align-items-center justify-content-between">
              <input
                value={searchText}
                onChange={e => {
                  setSearchText(e.target.value);
                }}
                type="text"
                placeholder="Search..."
                className="fs-14 medium-text plr-10 form-control react-form-input"
                onKeyDown={handleKeyDown}
              />
              <div
                className="flex flex-x justify-content-start align-itens-center ptb-10"
                style={{ width: 362, paddingLeft: 20 }}
              >
                {(isSearched || searchText) && (
                  <Button
                    type="button"
                    className="c-primary c-btn form-button fs-16 demi-bold-text"
                    style={{ maxWidth: "50px" }}
                    onClick={() => {
                      setSearchText("");
                      if (isSearched) {
                        setActivePage(0);
                        handleSearch(1, "", false);
                      }
                    }}
                    title="Undo/Clear"
                  >
                    <i class="fas fa-undo"></i>
                  </Button>
                )}
                <Button
                  type="button"
                  className="c-btn c-info form-button fs-16 demi-bold-text"
                  style={{ maxWidth: "125px", marginLeft: 12 }}
                  onClick={() => {
                    setActivePage(0);
                    handleSearch();
                  }}
                  disabled={!amdKey || !searchText}
                >
                  Search
                </Button>
                <Button
                  type="button"
                  className="c-primary c-btn form-button fs-16 demi-bold-text"
                  style={{ maxWidth: "125px", marginLeft: 12 }}
                  onClick={handleExport}
                  disabled={!amdKey || isExporting || !tblData?.length}
                >
                  {isExporting ? "Exporting.." : "Export"}
                </Button>
              </div>
            </div>

            <div className="roe-card-body">
              <ReactTable
                style={{
                  border: "none",
                  boxShadow: "none"
                }}
                data={tblData}
                columns={columns}
                defaultPageSize={10}
                minRows={2}
                manual
                defaultFilterMethod={(filter, row) => {
                  const id = filter.pivotId || filter.id;

                  return row[id] !== undefined
                    ? String(row[id].toLowerCase()).includes(
                        filter.value.toLowerCase()
                      )
                    : true;
                }}
                className="-striped -highlight custom-react-table-theme-class"
                pages={pages}
                page={activePage}
                PaginationComponent={Pagination}
                loading={loader}
                LoadingComponent={Loader}
                onFetchData={onChangePageGetData}
                onPageChange={pageIndex => {
                  setActivePage(pageIndex);
                  handleSearch(pageIndex + 1);
                }}
              />
            </div>
          </>
        )}
      </div>
      <Toaster ref={toaster} />
    </CommonLookUpWrapper>
  );
};
