import classNames from "classnames";
import React, { useState, useRef, useEffect } from "react";

const ResizableDraggableDiv = ({ ...props }) => {
  const containerRef = useRef(null);
  const innerContainerRef = useRef(null);

  const [isResizing, setIsResizing] = useState(false);
  const [width, setWidth] = useState(window.innerWidth / 2);
  const [height, setHeight] = useState(window.innerHeight / 1.22);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({
    x: window.innerWidth / 4,
    y: window.innerHeight / 9
  });
  const [initialPosition, setInitialPosition] = useState({
    x: 0,
    y: 0
  });

  useEffect(() => {
    const handleMouseMove = event => {
      const { clientX, clientY } = event;
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;

      if (
        clientX < 0 ||
        clientY < 0 ||
        clientX > screenWidth - 2 ||
        clientY > screenHeight - 2
      ) {
        setIsResizing(false);
      }
    };

    document.addEventListener("mouseup", handleMouseMove);

    return () => {
      document.removeEventListener("mouseup", handleMouseMove);
    };
  }, []);

  useEffect(() => {
    setWidth(containerRef.current.offsetWidth);
    setHeight(containerRef.current.offsetHeight);
  }, []);

  useEffect(() => {
    if (isFullScreen && isResizing) setIsFullScreen(false);
  }, [isFullScreen, isResizing]);

  const handleDragStart = event => {
    setInitialPosition({ x: event.clientX, y: event.clientY });
    setIsDragging(true);
  };

  const handleMouseDownResize = e => {
    e.preventDefault();
    setIsResizing(true);
    setInitialPosition({ x: e.clientX, y: e.clientY });
  };

  const handleMouseUp = () => {
    setIsResizing(false);
    setIsDragging(false);
  };

  const handleMouseMove = e => {
    if (
      e.target !== containerRef.current ||
      e.target !== innerContainerRef.current
    ) {
      setIsDragging(false);
    }

    if (isResizing) {
      const newWidth = e.clientX - containerRef.current.offsetLeft;
      const newHeight = e.clientY - containerRef.current.offsetTop;
      setWidth(newWidth > 0 ? newWidth : 0);
      setHeight(newHeight > 0 ? newHeight : 0);
    }

    if (isDragging) {
      const offsetX = e.clientX - initialPosition.x;
      const offsetY = e.clientY - initialPosition.y;

      const newLeft = position.x + offsetX;
      const newTop = position.y + offsetY;

      setPosition({
        x:
          newLeft < 0
            ? 0
            : position.x + offsetX + width >= window.innerWidth
            ? window.innerWidth - width
            : newLeft,
        y:
          newTop < 0
            ? 0
            : position.y + offsetY + height >= window.innerHeight
            ? window.innerHeight - height
            : newTop
      });

      setInitialPosition({ x: e.clientX, y: e.clientY });
    }
  };

  const handleFullScreen = () => {
    setIsFullScreen(prev => !prev);
    if (!isFullScreen) {
      setPosition({
        x: 0,
        y: 0
      });
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    } else {
      setPosition({
        x: window.innerWidth / 4,
        y: window.innerHeight / 9
      });
      setHeight(window.innerHeight / 1.22);
      setWidth(window.innerWidth / 2);
    }
  };

  return (
    <div className="visit-note-fixed-main">
      <div className="visit-note-drag-main">
        <div className="visit-note-drag-rel" onMouseMove={handleMouseMove}>
          <div
            id="dd"
            ref={containerRef}
            style={{
              border: "1px solid #ccc",
              overflow: "hidden",
              position: "absolute",
              left: `${position.x}px`,
              top: `${position.y}px`,
              width: `${width}px`,
              height: `${height}px`,
              zIndex: 99999,
              background: "white"
            }}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            // draggable
            // onDragStart={handleDragStart}
          >
            <div
              className={classNames("flex justify-content-between pa-24", {
                "cursor-drag visit-note-header": !isFullScreen
              })}
              draggable
              onDragStart={handleDragStart}
            >
              <div className="fs-18">{props?.header}</div>
              <div onClick={handleFullScreen}>
                <div className="fs-14 medium-text cursor-pointer" title="View">
                  {!isFullScreen ? (
                    <i class="fas fa-expand"></i>
                  ) : (
                    <i class="fas fa-compress"></i>
                  )}
                </div>
              </div>
            </div>
            <div
              style={{
                height: !isFullScreen ? `${height - 74}px` : `${height - 164}px`
              }}
            >
              {props?.children}
            </div>
            <div
              className="visit-note-resize"
              ref={innerContainerRef}
              onMouseDown={handleMouseDownResize}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResizableDraggableDiv;
