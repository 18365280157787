import React from "react";

const CategoryViewDetails = props => {
  const categoryDetails = props.location.state;

  return (
    <div className="plr-15">
      <div className="roe-card-style mtb-15">
        <div className="roe-card-header module-header">
          <div className="flex-1 fs-16 demi-bold-text">
            <span className="hash"># </span> View Category
          </div>
        </div>
        <div className="roe-card-body">
          <div className="pt-2">
            <div>
              <div style={{ fontWeight: "bolder", textAlign: "left" }}>
                Image
              </div>
              {categoryDetails?.image ? (
                <img
                  className="top-header-profile-class"
                  style={{
                    height: "125px",
                    width: "125px",
                    marginBottom: "15px",
                    borderRadius: "10px",
                    marginTop: "10px"
                  }}
                  src={categoryDetails?.image}
                  alt="category image"
                />
              ) : (
                <div>-</div>
              )}
            </div>
          </div>
          <div className="pt-2">
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>Name</div>
            <div>{categoryDetails?.name ? categoryDetails?.name : "-"}</div>
          </div>
          <div className="pt-2">
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>
              Service
            </div>
            <div>
              {categoryDetails?.service ? categoryDetails?.service?.name : "-"}
            </div>
          </div>
          <div className="pt-2">
            <div>
              <div style={{ fontWeight: "bolder", textAlign: "left" }}>
                Description
              </div>
              <div>
                {categoryDetails.description
                  ? categoryDetails.description
                  : "-"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryViewDetails;
