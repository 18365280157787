import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    code: Yup.string()
      .required("Please enter MFA code")
      .max(100, "Code must be less than 100 characters")
  }),
  mapPropsToValues: props => ({
    code: ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
