import emojiRegexText from "emoji-regex";
import emojis from "./emoji.json";
import moment from "moment-timezone";

const XRegExp = require("xregexp");
const emojiRegex = emojiRegexText();

function parse(input) {
  const lines = input.trim().split("\n");

  return lines.map(line => {
    const match = line.trim().match(/^-\s*(.*)/);

    if (match !== null) {
      return {
        type: "bullet",
        children: parseInline(match[1])
      };
    }

    return {
      type: "paragraph",
      children: parseInline(line)
    };
  });
}

function parseInline(input) {
  // empty string
  if (input === "") return [];
  const regex = XRegExp(
    `
      ^
      (?<prefix>.*?\\s)??
      (?: 
        (?<bold>\\*.*?\\*)            | 
        (?<italic>_.*?_)              |
        (?<mention>@[a-zA-Z0-9-.]+)   |
        (?<link>https?:\\/\\/[^\\s]*)   
      )
      (?<suffix>\\s.*)?
      $
    `,
    "x"
  );

  const match = XRegExp.exec(input, regex);

  // simple text
  if (match === null) return [input];

  // const prefix = input.slice(0, match.index);
  // const suffix = input.slice(match.index + match[0].length);

  let node;

  // bold
  if (match.bold) {
    node = {
      type: "bold",
      children: parseInline(match.bold.slice(1, -1))
    };
  }
  // italic
  else if (match.italic) {
    node = {
      type: "italic",
      children: parseInline(match.italic.slice(1, -1))
    };
  }
  // mention
  else if (match.mention) {
    node = {
      type: "mention",
      username: match.mention.slice(1)
    };
  }
  // link
  else if (match.link) {
    node = {
      type: "link",
      href: match.link
    };
  }

  const nodes = [node];

  if (match.prefix !== "") nodes.unshift(match.prefix);
  if (match.suffix !== "") nodes.push(...parseInline(match.suffix));

  return nodes;
}

function render(nodes) {
  return nodes.map(renderNode).join("");
}

function renderNode(node) {
  if (typeof node === "string") {
    // NSAttributedString(string: node)
    return node;
  }

  // block

  if (node && node.type === "paragraph") {
    return `<p>${render(node.children)}</p>`;
  }

  if (node && node.type === "bullet") {
    return `<p class="bullet"><span>${render(node.children)}</span></p>`;
  }

  // inline

  if (node && node.type === "bold") {
    // bold
    return `<strong>${render(node.children)}</strong>`;
  }

  if (node && node.type === "italic") {
    return `<em>${render(node.children)}</em>`;
  }

  if (node && node.type === "mention") {
    // return `<a href="mention://${node.username}">@${node.username}</a>`;
    return `<span data-id=${node.username} class="mention">${node.username}</span>`;
  }

  if (node && node.type === "link") {
    // return `${node.href}`;
    return `<a target="_blank" href="${node.href}">${node.href}</a>`;
  }
}

export function emojiShortCodeParsing(input) {
  return input.replace(/:.*?:/g, match => {
    const shortcode = match.slice(1, -1);
    return emojis[shortcode]
      ? `<span class="editor-emoji">${emojis[shortcode]}</span>`
      : match;
  });
}

export function emojiParsing(input) {
  return input.replace(emojiRegex, `<span class="editor-emoji">$&</span>`);
}

export function compile(input) {
  if (input) {
    return render(
      parse(
        emojiShortCodeParsing(
          emojiParsing(input.replace("<", "&lt;").replace("</", "&lt;/"))
        )
      )
    );
  }
  return `<span></span>`;
}

export const diffMinutes = (time1, time2) => {
  const [hours1, minutes1] = time1.split(":").map(Number);
  const [hours2, minutes2] = time2.split(":").map(Number);

  // Convert times into milliseconds
  const date1 = new Date(0, 0, 0, hours1, minutes1, 0);
  const date2 = new Date(0, 0, 0, hours2, minutes2, 0);

  // Calculate the difference in milliseconds
  let difference = date2.getTime() - date1.getTime();

  // Convert milliseconds to minutes
  difference = Math.floor(difference / (1000 * 60));

  return difference;
};

export const convertToFixedOffset = (time, timezone, format = "h:mm A") => {
  // Get the current time in the given timezone
  const momentTime = moment.tz(time, timezone);

  // Fetch the standard UTC offset for the timezone (ignoring DST)
  const fixedOffset = moment.tz(timezone).utcOffset();

  // Apply the fixed UTC offset without considering DST
  const fixedOffsetTime = momentTime.utcOffset(fixedOffset);

  return fixedOffsetTime.format(format); // Return the time in the required format
};
