import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: props => {
    return Yup.object().shape({
      category: Yup.string().required("This field is required"),
      name: Yup.string().required("This field is required"),
      document: Yup.mixed()
        .required("Please select a file.")
        .test("FILE_FORMAT", "Unsupported file format. ", value => {
          if (props?.editedData?.type === "edit") {
            return true;
          } else if (
            value &&
            (value?.type === "application/pdf" ||
              value?.type === "image/png" ||
              value?.type === "image/jpeg" ||
              value?.type === "application/msword" ||
              value?.type ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
          ) {
            return true;
          } else {
            return false;
          }
        })
      // .test("fileSize", "The file is too large", (value) => {
      //   console.log("Value",value[0].size)
      //   return value && value[0].size <= 1024 * 1024 * 10; // 10 MB
      //   })
    });
  },

  mapPropsToValues: props => ({
    document:
      props.editedData && props.editedData.type == "edit"
        ? props.editedData.document
        : "",
    category:
      props.editedData && props.editedData.type == "edit"
        ? props.editedData.service.id
        : "",
    name:
      props.editedData && props.editedData.type == "edit"
        ? props.editedData.name
        : ""
  }),

  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true,
  isInitialValid: props => (props.editedData.type === "edit" ? true : false)
});

export default formikEnhancer;
