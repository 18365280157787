import React, { useState, useRef } from "react";
import { compose } from "redux";
import enhancer from "./liability-insurance-validator";
import Button from "../button/Button";
import Toaster from "../common/Toaster";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import RdServices from "../../api/RdServices";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";

const BusinessServiceAgreementModal = props => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    setFieldValue,
    setFieldTouched,
    onSubmit,
    values,
    action
  } = props;

  const [imageObj, setImageObj] = useState(null);
  const [loading, setLoading] = useState(false);
  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const toaster = useRef();

  const hasParam = props.match.params.id;

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;

    if (isValid) {
      setLoading(true);
      if (action === "add") {
        const params = {
          rd_id: hasParam,
          effective_date: moment(values?.effective_date).format("YYYY-MM-DD"),
          expire_date: moment(values?.expire_date).format("YYYY-MM-DD"),
          file_name: values?.image
        };

        const formdata = new FormData();
        const encryptData = EncryptDecrypt.encrypt(params);
        formdata.append("data", encryptData?.data);
        if (imageObj) formdata.append("data1", imageObj);

        RdServices.businessAgreementAdd(formdata)
          .then(res => {
            let data = EncryptDecrypt.decrypt(res.data);
            if (data.status) {
              if (toaster.current) toaster.current.success(data.message);
              props.pageRefresh();
              props.toggle();
              setLoading(false);
            } else {
              if (toaster.current) toaster.current.error(data.message);
              props.pageRefresh();
              props.toggle();
              setLoading(false);
            }
          })
          .catch(err => {
            if (toaster.current) toaster.current.error(err.response);
            props.pageRefresh();
            props.toggle();
            setLoading(false);
          });
      } else if (action === "edit") {
        const params = {
          id: values?.id,
          effective_date: moment(values?.effective_date).format("YYYY-MM-DD"),
          expire_date: moment(values?.expire_date).format("YYYY-MM-DD"),
          file_name: values?.image
        };

        const formdata = new FormData();
        const encryptData = EncryptDecrypt.encrypt(params);
        formdata.append("data", encryptData?.data);
        if (imageObj) formdata.append("data1", imageObj);

        RdServices.businessAgreementEdit(formdata)
          .then(res => {
            let data = EncryptDecrypt.decrypt(res.data);
            if (data.status) {
              if (toaster.current) toaster.current.success(data.message);
              props.pageRefresh();
              props.toggle();
              setLoading(false);
            } else {
              if (toaster.current) toaster.current.error(data.message);
              props.pageRefresh();
              props.toggle();
              setLoading(false);
            }
          })
          .catch(err => {
            if (toaster.current) toaster.current.error(err.response);
            props.pageRefresh();
            props.toggle();
            setLoading(false);
          });
      }
    }

    handleSubmit();
  };

  const handleImage = (e, setValue) => {
    let fileObj = e.target.files[0];

    if (fileObj.size > 10 * 1024 * 1024) {
      if (toaster.current)
        toaster.current.error("Please upload a file smaller than 10 MB");
      return false;
    }

    if (fileObj) {
      setImageObj(fileObj);
      setValue("image", fileObj.name);
    }
  };

  return (
    <form>
      <div className="form-group">
        <label className="fs-16 medium-text c-btn c-primary demi-bold-text mt-15 wp-17">
          Choose File
          <input
            id="image"
            className="file-upload__input"
            name="image"
            type="file"
            onChange={e => {
              handleImage(e, setFieldValue);
              setFieldTouched("image", true, true);
            }}
            onBlur={handleBlur}
            required={true}
            accept="image/png, image/jpeg, image/jpg,.doc, .docx,.txt,.pdf"
            onClick={event => {
              event.target.value = "";
            }}
          />
        </label>
        <Error field="image" />
        {values?.image && <div>{values?.image}</div>}
      </div>
      <div className="form-group">
        <label className="fs-16 medium-text">
          Effective Date <span className="asterisk">*</span>
        </label>
        <br />
        <DatePicker
          className="form-control"
          selected={values.effective_date}
          id="effective_date"
          onChange={e => {
            setFieldValue("effective_date", e);
          }}
          dateFormat="MM/dd/yyyy"
          placeholder="Add Effective Date"
        />
        <br />
        <Error field="effective_date" />
      </div>
      <div className="form-group">
        <label className="fs-16 medium-text">
          Expire Date <span className="asterisk">*</span>
        </label>
        <br />
        <DatePicker
          className="form-control"
          selected={values.expire_date}
          id="expire_date"
          onChange={e => {
            setFieldValue("expire_date", e);
          }}
          dateFormat="MM/dd/yyyy"
          placeholder="Add Expire Date"
          minDate={new Date(values?.effective_date)}
          disabled={!values.effective_date}
        />
        <br />
        <Error field="expire_date" />
      </div>
      <div>
        <Button
          type="button"
          className="c-btn c-info form-button fs-16 demi-bold-text mr-10"
          style={{ maxWidth: "125px" }}
          loading={loading}
          onClick={() => handleSubmit()}
          disabled={loading}
        >
          Submit
        </Button>
        <Button
          type="button"
          className="c-btn c-secondary form-button fs-16 demi-bold-text"
          style={{ maxWidth: "125px" }}
          onClick={props.toggle}
        >
          Close
        </Button>
      </div>
      <Toaster ref={toaster} />
    </form>
  );
};

export default compose(enhancer)(BusinessServiceAgreementModal);
