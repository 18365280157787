import { withFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    image: Yup.string().required("This field is required"),
    effective_date: Yup.date()
      .required("This field is required")
      .nullable(),
    expire_date: Yup.date()
      .required("This field is required")
      .nullable()
      .min(
        Yup.ref("effective_date"),
        "Expire Date can't be before Effective Date"
      )
  }),
  mapPropsToValues: props => ({
    id: props.editedData
      ? props && props.editedData && props.editedData.id
      : "",
    image: props.editedData
      ? props && props.editedData && props.editedData.image
      : "",
    effective_date: props.editedData
      ? props &&
        props.editedData &&
        new Date(moment(props.editedData.effective_date))
      : "",
    expire_date: props.editedData
      ? props &&
        props.editedData &&
        new Date(moment(props.editedData.expire_date))
      : ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
