import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import Loader from "../common/Loader";
import Toaster from "../common/Toaster";
import {
  MODULE_NAMES,
  OUTBOX_STATUS,
  PERMISSION_TYPE,
  TABLE
} from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import EfaxManagementServices from "api/EfaxMangementServices";
import moment from "moment";
import CommonModel from "components/common/CommonModel";
import EditOutboxModal from "./EditOutboxModal";
import ViewOutboxFaxModal from "./ViewOutboxFaxModal";
import { hasAccess } from "util/sidebar";

const OutboxTable = props => {
  const { permissions } = props;
  const [tblData, settblData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [field, setField] = useState("id");
  const [sortBy, setSortBy] = useState("DESC");
  const [editModal, setEditModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [viewData, setViewData] = useState({});
  const toaster = useRef();

  useEffect(() => {
    if (
      hasAccess(MODULE_NAMES.EFAX_OUTBOX, PERMISSION_TYPE.VIEW, permissions)
    ) {
      getList(field, sortBy, activePage + 1);
      setPages(10);
    }
  }, [props]);

  function editToggle() {
    setEditModal(!editModal);
  }
  function viewToggle() {
    setViewModal(!viewModal);
  }

  function sendFax(props) {
    setLoader(true);
    const params = {
      fax_outbox_id: props.original.id
    };
    EfaxManagementServices.sendFax(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current) toaster.current.success(data.message);
          getList(field, sortBy, activePage + 1);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
        setLoader(false);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
        setLoader(false);
      });
  }

  function viewPdf(pdf) {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = "file.pdf";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  function viewAction(props) {
    const params = {
      fax_outbox_id: props.original.id
    };
    EfaxManagementServices.viewFaxNote(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current) toaster.current.success(data.message);
          if (data?.data?.status === "sent") {
            viewPdf(data?.data?.data);
          } else {
            setViewModal(!viewModal);
            setViewData(data?.data?.data);
          }
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
        setLoader(false);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
        setLoader(false);
      });
  }

  const columns = [
    {
      Header: "ID",
      accessor: "id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 60,
      sortable: true
    },
    {
      Header: "To Number",
      accessor: "to_number",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 130,
      sortable: true,
      Cell: props => {
        const formattedNumber = props?.original?.to_number
          ?.toString()
          ?.replace(/\D+/g, "")
          ?.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
        return <div>{formattedNumber}</div>;
      }
    },
    {
      Header: "From Number",
      accessor: "from_number",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 140,
      sortable: true,
      Cell: props => {
        const formattedNumber = props?.original?.from_number
          ?.toString()
          ?.replace(/\D+/g, "")
          ?.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
        return <div>{formattedNumber}</div>;
      }
    },
    {
      Header: "Date Sent",
      accessor: "date_sent",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 150,
      sortable: true,
      Cell: props =>
        props.original.date_sent
          ? moment(props?.original?.date_sent).format("MM/DD/YYYY HH:mm")
          : ""
    },

    {
      Header: "Status",
      accessor: "status",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 150,
      sortable: true,
      Cell: props =>
        props?.original?.status && OUTBOX_STATUS[`${props?.original?.status}`]
    },
    {
      Header: "Pages",
      accessor: "pages",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 90,
      sortable: true
    },

    {
      Header: "Notes",
      accessor: "notes",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 100,
      sortable: true
    },

    {
      Header: "Action",
      accessor: "action",
      className: "",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 200,
      // show: props.auth.admin_role_id <= 2,
      Cell: props => (
        <div className="react-action-class">
          {hasAccess(
            MODULE_NAMES.EFAX_OUTBOX,
            PERMISSION_TYPE.EDIT,
            permissions
          ) && (
            <Button
              className="c-btn c-success mr-10"
              onClick={() => {
                setEditModal(!editModal);
                setEditedData({ ...props.original });
              }}
            >
              <div className="fs-14 medium-text" title="Edit Note">
                <i className="fas fa-edit" />
              </div>
            </Button>
          )}
          {hasAccess(
            MODULE_NAMES.EFAX_OUTBOX,
            PERMISSION_TYPE.VIEW,
            permissions
          ) && (
            <Button
              className="c-btn c-info mr-10"
              onClick={() => viewAction(props)}
            >
              <div className="fs-14 medium-text" title="View">
                <i className="fas fa-eye" />
              </div>
            </Button>
          )}
          {hasAccess(
            MODULE_NAMES.EFAX_OUTBOX,
            PERMISSION_TYPE.ADD,
            permissions
          ) &&
            (props.original.status !== "queued" ? (
              <Button
                className="c-btn c-info mr-10"
                onClick={() => sendFax(props)}
              >
                <div className="fs-14 medium-text" title="Send">
                  Send
                </div>
              </Button>
            ) : (
              <Button className="c-btn c-info mr-10" disabled="true">
                <div className="fs-14 medium-text" title="Queued">
                  Queued
                </div>
              </Button>
            ))}
        </div>
      )
    }
  ];

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
    }
  };

  function getList(field = "created_at", sortBy = "DESC", page = "1") {
    const params = {
      order: sortBy,
      sort_by: field,
      page: page,
      service_id:
        props?.auth?.admin_role_id > 2 ? props?.auth?.service_id : undefined
    };
    setLoader(true);
    EfaxManagementServices.outboxList(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count / TABLE.LIMIT));
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
          setLoader(false);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
        setLoader(false);
      });
  }

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> eFax Outbox List
            </div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              LoadingComponent={Loader}
              onFetchData={onChangePageGetData}
              onPageChange={pageIndex => {
                setActivePage(pageIndex);
                getList(field, sortBy, pageIndex + 1);
              }}
              onSortedChange={sortProperties => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getList(sortProperties[0].id, sort, 1);
              }}
            />
          </div>
        </div>
      </div>
      <CommonModel
        modal={editModal}
        toggle={editToggle}
        children={
          <EditOutboxModal
            editedData={editedData}
            toggle={editToggle}
            pageRefresh={() => {
              getList(field, sortBy, activePage + 1);
            }}
          />
        }
      />
      <CommonModel
        modal={viewModal}
        toggle={viewToggle}
        size="md"
        children={
          <ViewOutboxFaxModal viewData={viewData} toggle={viewToggle} />
        }
      />
      <Toaster ref={toaster} />
    </div>
  );
};

export default withRouter(OutboxTable);
