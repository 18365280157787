import axios from "axios";
import { baseUrl } from "../helper/constant";
import Header from "./Header";
import EncryptDecrypt from "./EncryptDecrypt";

export default class CareTeamServices {
  static listCareTeam(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/client/list-care-team`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static listProvider(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/client/list-rd-for-client`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static editProvider(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/client/assign-rd`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static statusCareTeam(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/client/status-care-team`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static listReferral(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/client/list-referral`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static addReferral(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/client/add-referral`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static listExternalCareTeam(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/external-care-team/list`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static addExternalCareTeam(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/external-care-team/add`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static editExternalCareTeam(params) {
    Header.setHeaders();
    return axios.put(
      `${baseUrl}/external-care-team/edit`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static searchExternalCareTeam(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/external-care-team/search-external-rd`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static deleteExternalCareTeam(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/external-care-team/delete`,
      EncryptDecrypt.encrypt(params)
    );
  }
}
