import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    first_name: Yup.string()
      .trim()
      .required("This field is required")
      .max(100, "First Name must be less than 100 characters"),
    last_name: Yup.string()
      .trim()
      .required("This field is required ")
      .max(100, "Last Name must be less than 100 characters"),
    profile_text: Yup.string()
      .trim()
      .required("This field is required "),
    email: Yup.string()
      .trim()
      .required("This field is required ")
      .email("Please enter valid email"),
    // primary_category_id: Yup.string()
    //   .trim()
    //   .required("This field is required "),
    // secondary_category_id: Yup.string()
    //   .trim()
    //   .required("This field is required "),
    profile_image: Yup.string()
      .trim()
      .required("This field is required "),
    zip: Yup.string()
      .matches(/^[0-9]+$/i, "Must contain numbers only")
      .min(5, "Must be exactly 5 digits")
      .max(5, "Must be exactly 5 digits"),
    phone_number: Yup.string()
      .min(14, "Must be exactly 10 digits")
      .max(14, "Must be exactly 10 digits")
      .required("This field is required"),
    timezone_id: Yup.string()
      .trim()
      .required("This field is required "),
    language_id: Yup.string()
      .trim()
      .required("This field is required ")
  }),
  mapPropsToValues: props => ({
    id:
      props && props.editedData && props.editedData.id
        ? props.editedData.id
        : "",
    first_name:
      props && props.editedData && props.editedData.first_name
        ? props.editedData.first_name
        : "",
    last_name:
      props && props.editedData && props.editedData.last_name
        ? props.editedData.last_name
        : "",
    email:
      props && props.editedData && props.editedData.email
        ? props.editedData.email
        : "",

    profile_image:
      props && props.editedData && props.editedData.profile_image
        ? props.editedData.profile_image
        : "",
    profile_text:
      props &&
      props.editedData &&
      props.editedData.rd_detail &&
      props.editedData.rd_detail.profile_text
        ? props.editedData.rd_detail.profile_text
        : "",
    primary_category_id:
      props.editedData &&
      props.editedData.rd_detail &&
      props.editedData.rd_detail.primary_category_id
        ? props.editedData.rd_detail.primary_category_id
        : "",
    secondary_category_id:
      props &&
      props.editedData &&
      props.editedData.rd_detail &&
      props.editedData.rd_detail.secondary_category_id
        ? props.editedData.rd_detail.secondary_category_id
        : "",
    phone_number:
      props &&
      props.editedData &&
      props.editedData.rd_detail &&
      props.editedData.rd_detail.phone_number
        ? props.editedData.rd_detail.phone_number
            .toString()
            .replace(/\D+/g, "")
            .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
        : "",
    address:
      props &&
      props.editedData &&
      props.editedData.rd_detail &&
      props.editedData.rd_detail.address
        ? props.editedData.rd_detail.address
        : "",
    city:
      props &&
      props.editedData &&
      props.editedData.rd_detail &&
      props.editedData.rd_detail.city
        ? props.editedData.rd_detail.city
        : "",
    state_id:
      props &&
      props.editedData &&
      props.editedData.rd_detail &&
      props.editedData.rd_detail.state_id
        ? props.editedData.rd_detail.state_id
        : "",
    zip:
      props &&
      props.editedData &&
      props.editedData.rd_detail &&
      props.editedData.rd_detail.zip
        ? props.editedData.rd_detail.zip
        : "",
    language_id:
      props && props.editedData && props.editedData.language_id
        ? props.editedData.language_id
        : [],
    timezone_id:
      props &&
      props.editedData &&
      props.editedData.rd_detail &&
      props.editedData.rd_detail.timezone_id
        ? props.editedData.rd_detail.timezone_id
        : ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
