import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .trim()
      .required("This field is required")
      .max(100, "Name must be less than 100 characters"),
    description: Yup.string()
      .trim()
      .required("This field is required"),
    image: Yup.string()
      .trim()
      .nullable()
      .notRequired(),
    service: Yup.string().required("This field is required")
  }),
  mapPropsToValues: props => ({
    id: props.editedData
      ? props && props.editedData && props.editedData.id
      : "",
    name: props.editedData
      ? props && props.editedData && props.editedData.name
      : "",
    description: props.editedData
      ? props && props.editedData && props.editedData.description
      : "",
    image: props.editedData
      ? props && props.editedData && props.editedData.image
      : "",
    service: props.editedData
      ? typeof props.editedData.service == "object"
        ? props.editedData.service.id
        : props.editedData.service
      : ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
