import React, { useRef, useState } from "react";
import { compose } from "redux";
import enhancer from "../rd/performance-validator";
import Button from "../button/Button";
import Toaster from "../common/Toaster";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import RdServices from "../../api/RdServices";
import MonthYearPicker from "react-month-year-picker";
import "./performance.css";

const PerformanceModal = props => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    submitCount,
    onSubmit,
    values,
    action
  } = props;

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };
  // const [goalMonth, setGoalMonth] = useState(parseInt(moment().format("MM")));
  // const [goalYear, setGoalYear] = useState(parseInt(moment().format("YYYY")));

  const toaster = useRef();

  const hasParam = props.match.params.id;

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;

    if (isValid) {
      onSubmit(values, action);
      if (action === "add") {
        const params = {
          rd_id: hasParam,
          goal: values.goal,
          month_year:
            values.year +
            "-" +
            (values.month < 10 ? "0" + values.month : values.month) +
            "-" +
            "01"
        };
        RdServices.goalAdd(params)
          .then(res => {
            let data = EncryptDecrypt.decrypt(res.data);
            if (data.status) {
              if (toaster.current) toaster.current.success(data.message);
            } else {
              if (toaster.current) toaster.current.error(data.message);
            }
          })
          .catch(err => {
            if (toaster.current) toaster.current.error(err.response);
          });
      } else if (action === "edit") {
        const params = {
          id: values.id,
          rd_id: hasParam,
          goal: values.goal,
          month_year:
            values.year +
            "-" +
            (values.month < 10 ? "0" + values.month : values.month) +
            "-" +
            "01"
        };
        RdServices.goalEdit(params)
          .then(res => {
            let data = EncryptDecrypt.decrypt(res.data);
            if (data.status) {
              if (toaster.current) toaster.current.success(data.message);
            } else {
              if (toaster.current) toaster.current.error(data.message);
            }
          })
          .catch(err => {
            if (toaster.current) toaster.current.error(err.response);
          });
      }
    }

    handleSubmit();
  };

  return (
    <form>
      <div className="form-group">
        <MonthYearPicker
          selectedMonth={values.month}
          selectedYear={values.year}
          // minYear={2001}
          // maxYear={new Date().getFullYear()}
          onChangeYear={year => setFieldValue("year", year)}
          onChangeMonth={month => setFieldValue("month", month)}
        />
        <br />
        <Error field="start_date" />
      </div>
      <div className="form-group">
        <label className="fs-16 medium-text">
          Goal <span className="asterisk">*</span>
        </label>
        <br />
        <input
          type="number"
          min="0"
          className="form-control react-form-input"
          id="goal"
          name="goal"
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Add Goal Unit"
          value={values.goal}
        />
        <Error field="goal" />
      </div>
      <div>
        <Button
          type="button"
          className="c-btn c-info form-button fs-16 demi-bold-text mr-10"
          style={{ maxWidth: "125px" }}
          onClick={() => handleSubmit()}
        >
          Submit
        </Button>
        <Button
          type="button"
          className="c-btn c-secondary form-button fs-16 demi-bold-text"
          style={{ maxWidth: "125px" }}
          onClick={props.toggle}
        >
          Close
        </Button>
      </div>
      <Toaster ref={toaster} />
    </form>
  );
};

export default compose(enhancer)(PerformanceModal);
