import axios from "axios";
import { baseUrl } from "../helper/constant";
import Header from "./Header";
import EncryptDecrypt from "./EncryptDecrypt";
import FileDownload from "js-file-download";

export default class InsuranceServices {
  static list(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/insurance/list`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static lookupList(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/advancemd/insurance-lookup`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static add(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/insurance/add`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static edit(params) {
    Header.setHeaders();
    return axios.put(
      `${baseUrl}/insurance/edit`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static view(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/insurance/view`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static delete(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/insurance/delete`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static status(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/insurance/status`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static getAMDSettings(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/advancemd/advancemd-setting`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static lookup(params) {
    Header.setHeaders();
    return axios
      .post(
        `${baseUrl}/advancemd/insurance-lookup-csv`,
        EncryptDecrypt.encrypt(params)
      )
      .then(res => {
        FileDownload(res.data, "InsuranceLookup.csv");
      });
  }
}
