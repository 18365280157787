import React from "react";
import PageWrapper from "./CptCode.style";
import { connect } from "react-redux";
import CptCodeViewDetails from "../../components/cptCode/cptCodeViewDetails";
import PageTitle from "components/common/PageTitle";

const CptCodeView = props => {
  return (
    <PageWrapper {...props}>
      <div className="pos-relative">
        <PageTitle
          props={props}
          title="sidebar.admin"
          className="plr-15"
          breadCrumb={[
            {
              name: "sidebar.dashboard",
              url: "/dashboard"
            },
            {
              name: "sidebar.cpt_code",
              url: "/cpt-code"
            },
            {
              name: "action.view"
            }
          ]}
        />
        <div
          className="back-icon fs-15 demi-bold-text cursor-pointer"
          onClick={() => props.history.push("/cpt-code")}
        >
          <i className="fas fa-step-backward"></i> Back
        </div>
      </div>
      <div className="user-tbl">
        <CptCodeViewDetails {...props} />
      </div>
    </PageWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(CptCodeView);
