import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import GoalTrackingService from "../../api/GoalTrackingService";
import CommonModel from "../common/CommonModel";
import GoalCategory from "./GoalCategory";
import { Line } from "react-chartjs-2";

//import Moment from "react-moment";
import Toaster from "../common/Toaster";
import {
  TABLE,
  SYSTEMADMIN,
  MODULE_NAMES,
  PERMISSION_TYPE
} from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import PageWrapper from "./foodtracking.style";
import Goal from "./Goal";
import { hasAccess } from "util/sidebar";
const data = {
  labels: [],
  datasets: [
    {
      label: "# of Votes",
      data: [],
      fill: false,
      backgroundColor: "rgb(255, 99, 132)",
      borderColor: "rgba(255, 99, 132, 0.2)"
    }
  ]
};

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true
        }
      }
    ]
  }
};

const GoalTracking = props => {
  const { permissions } = props;
  const hasParam = props.match.params.id;
  const [tblData, settblData] = useState();

  const initialValues = {
    title: ""
  };

  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [displayOnFormText, setDisplayOnFormText] = useState("");
  const [field, setField] = useState("id");
  const [sortBy, setSortBy] = useState("ASC");
  const [id, setId] = useState(undefined);
  const [modal, setModal] = useState(false);

  const [parentDropdownOpen, setParentDropdownOpen] = useState(false);

  const [modalOne, setModalOne] = useState(false);

  const toaster = useRef();

  const toggleOne = () => setParentDropdownOpen(prevState => !prevState);

  useEffect(() => {
    getList(field, sortBy, searchText, activePage + 1, displayOnFormText);
    setPages(10);
  }, []);

  function toggle1() {
    setModal(false);
    getList(field, sortBy, searchText, activePage + 1, displayOnFormText);
    setPages(10);
  }

  function getList(
    field = "created_at",
    sortBy = "DESC",
    search = "",
    page = "1",
    is_display_registration = ""
  ) {
    const params = {
      sort_by: field,
      order: sortBy,
      search: search,
      page: page,
      user_id: hasParam,
      is_display_registration
    };
    setLoader(true);
    GoalTrackingService.list(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data);
          setPages(Math.ceil(data.data.count / TABLE.LIMIT));
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current) toaster.current.error(err?.message);
        }
      });
  }

  return (
    <PageWrapper>
      <div className="row col-md-12">
        <div className="row col-md-6 ml-0 float-right">
          {hasAccess(
            MODULE_NAMES.CLIENT_TRACKING,
            PERMISSION_TYPE.ADD,
            permissions
          ) && (
            <div
              className=" text-center text-white p-2 fs-16 w-50"
              style={{ backgroundColor: "#b0d135", cursor: "pointer" }}
              onClick={() => setModal(true)}
            >
              Add New Goal
            </div>
          )}
        </div>
      </div>
      <div className="row col-md-12 mt-3">
        <div className="col-md-6">
          <div className="w-100 border">
            <div>
              <div
                colSpan="2"
                className="text-center text-white p-2 fs-16 font-weight-bold"
                style={{ backgroundColor: "#b0d135" }}
              >
                Short Term Goal
              </div>

              {tblData &&
                tblData.short_term &&
                tblData.short_term.map((result, index) => {
                  return (
                    <Goal
                      key={index}
                      result={result}
                      type="short"
                      pageRefresh={() => {
                        setModalOne(false);
                        toggle1();
                        getList(
                          field,
                          sortBy,
                          searchText,
                          activePage + 1,
                          displayOnFormText
                        );
                      }}
                      {...props}
                    />
                  );
                })}

              {/* <tr>
                <td className="w-50 p-3"></td>
              </tr> */}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <table className="w-100 border">
            <tbody>
              <tr>
                <th
                  colSpan="2"
                  className="text-center text-white p-2 fs-16"
                  style={{ backgroundColor: "#b0d135" }}
                >
                  Long Term Goal
                </th>
              </tr>
              {tblData &&
                tblData.long_term &&
                tblData.long_term.map(result => {
                  return (
                    <Goal
                      result={result}
                      type="long"
                      pageRefresh={() => {
                        setModalOne(false);
                        toggle1();
                        getList(
                          field,
                          sortBy,
                          searchText,
                          activePage + 1,
                          displayOnFormText
                        );
                      }}
                      {...props}
                    />
                  );
                })}
              {/* {data.labels.length && <Line data={data} options={options} />} */}

              <tr>
                <td className="w-50 p-3"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <Toaster ref={toaster} />
      {modal && (
        <CommonModel
          modal={modal}
          toggle1={toggle1}
          size="lg"
          children={
            <GoalCategory
              // modalOne={modalOne}
              toggle1={toggle1}
              {...props}
            />
          }
          // header={"Add Goal"}
        />
      )}
    </PageWrapper>
  );
};

export default withRouter(GoalTracking);
