import { withFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    caqh_id: Yup.string().required("This field is required"),
    username: Yup.string().required("This field is required"),
    password: Yup.string().required("This field is required"),
    attested_date: Yup.date()
      .required("This field is required")
      .nullable(),
    reattested_date: Yup.date()
      .required("This field is required")
      .nullable()
      .min(
        Yup.ref("attested_date"),
        "Reattested date can't be before Attested date"
      )
  }),
  mapPropsToValues: props => ({
    id: props.editedData
      ? props && props.editedData && props.editedData.id
      : "",
    caqh_id: props.editedData
      ? props && props.editedData && props.editedData.caqh_id
      : "",
    username: props.editedData
      ? props && props.editedData && props.editedData.username
      : "",
    password: props.editedData
      ? props && props.editedData && props.editedData.password
      : "",
    attested_date: props.editedData
      ? props &&
        props.editedData &&
        new Date(moment(props.editedData.attested_date))
      : "",
    reattested_date: props.editedData
      ? props &&
        props.editedData &&
        new Date(moment(props.editedData.reattested_date))
      : ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
