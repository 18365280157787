import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import GroupServices from "../../api/GroupServices";
import Loader from "../common/Loader";
import CommonModel from "../common/CommonModel";
import ScaleProgramModal from "./ScaleProgramModal";
import Toaster from "../common/Toaster";
import {
  TABLE,
  RD,
  MODULE_NAMES,
  PERMISSION_TYPE
} from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import { hasAccess } from "util/sidebar";

const ScaleProgramTable = props => {
  const { permissions } = props;
  const [tblData, settblData] = useState();
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("id");
  const [sortBy, setSortBy] = useState("DESC");
  const [id, setId] = useState(undefined);
  const [modal, setModal] = useState(undefined);
  const [editedData, setEditedData] = useState(undefined);
  const [scaleProgramList, setScaleProgramList] = useState(undefined);

  const toaster = useRef();

  const hasParam = props.match.params.id;

  function toggle() {
    setModal(false);
  }

  const formAction = (action, data = null) => {
    // Here you can view the data and make forward action for edit data
    GroupServices.listScaleProgram().then(res => {
      let data = EncryptDecrypt.decrypt(res.data);
      setScaleProgramList(data.data);
    });
    if (action === "edit") {
      setId(data.id);
      setModal(true);
      const params = {
        id: data.id
      };
      GroupServices.scaleProgramView(params).then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        setEditedData(data.data);
      });
    } else if (action === "add") {
      setId(data);
      setModal(true);
      setEditedData(null);
    }
  };

  useEffect(() => {
    if (
      hasAccess(MODULE_NAMES.GROUP_SCALE, PERMISSION_TYPE.VIEW, permissions)
    ) {
      const params = {
        id: hasParam
      };
      getList(field, sortBy, searchText, activePage + 1);
      setPages(10);
    }
  }, []);

  const submitFormHandler = () => {
    setTimeout(() => {
      setModal(false);
      getList(field, sortBy, searchText, activePage + 1);
    }, 2000);
  };

  const activeInactiveStatusHandler = oData => {
    const params = {
      is_active: !oData.is_active,
      id: oData.id,
      group_id: hasParam
    };
    setLoader(true);
    GroupServices.scaleProgramActiveInactiveStatus(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current)
            toaster.current.success(
              oData.is_active === true
                ? "Scale Program has been deactivated successfully."
                : "Scale Program has been activated successfully."
            );
          getList(field, sortBy, searchText, activePage + 1, 1);
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
          setLoader(false);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
        setLoader(false);
      });
  };

  const columns = [
    {
      Header: "Name",
      accessor: "scale_program.name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200
    },
    {
      Header: "Description",
      accessor: "scale_program.description",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 300,
      sortable: true
    },
    {
      Header: "Visits",
      accessor: "scale_program.visits",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 100,
      sortable: true
    },
    {
      Header: "Status",
      accessor: "is_active",
      className: "",
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      filterable: false,
      width: 150,
      Cell: cellProps => (
        <div className="react-action-class">
          {props.authData.adminRoleId !== RD ? (
            cellProps.original.is_active ? (
              <div id={`active-${cellProps.original.state_id}`}>
                <Button
                  style={{ minWidth: "80px" }}
                  className="c-btn c-info mr-10"
                  onClick={() =>
                    activeInactiveStatusHandler(cellProps.original)
                  }
                  disabled={
                    !hasAccess(
                      MODULE_NAMES.GROUP_SCALE,
                      PERMISSION_TYPE.STATUS,
                      permissions
                    )
                  }
                >
                  <div className="fs-14 medium-text">
                    <i className="fas fa-toggle-off mr-6" /> Enabled
                  </div>
                </Button>
              </div>
            ) : (
              <div id={`deactive-${cellProps.original.state_id}`}>
                <Button
                  style={{ minWidth: "80px" }}
                  className="c-btn c-warning mr-10"
                  onClick={() =>
                    activeInactiveStatusHandler(cellProps.original)
                  }
                  disabled={
                    !hasAccess(
                      MODULE_NAMES.GROUP_SCALE,
                      PERMISSION_TYPE.STATUS,
                      permissions
                    )
                  }
                >
                  <div className="fs-14 medium-text">
                    <i className="fas fa-toggle-on mr-6" /> Disabled
                  </div>
                </Button>
              </div>
            )
          ) : cellProps.original.is_active ? (
            "Enabled"
          ) : (
            "Disabled"
          )}
        </div>
      ) // Custom cell components!
    }
  ];

  if (hasAccess(MODULE_NAMES.GROUP_SCALE, PERMISSION_TYPE.EDIT, permissions)) {
    columns.push({
      Header: "Action",
      accessor: "action",
      className: "",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      Cell: colProps => (
        <div className="react-action-class">
          <Button
            className="c-btn c-success mr-10"
            onClick={() => formAction("edit", colProps.original)}
          >
            <div className="fs-14 medium-text">
              <i className="fas fa-edit" />
            </div>
          </Button>
        </div>
      ) // Custom cell components!
    });
  }
  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  function getList(
    field = "created_at",
    sortBy = "DESC",
    search = "",
    page = "1"
  ) {
    const params = {
      sort_by: field,
      order: sortBy,
      search: search,
      page: page,
      group_id: hasParam
    };
    setLoader(true);
    GroupServices.listGroupScaleProgram(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count / TABLE.LIMIT));
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });
  }

  return (
    <div>
      <div className="module-header">
        <div className="flex-1 fs-16 demi-bold-text"></div>
        <div>
          {hasAccess(
            MODULE_NAMES.GROUP_SCALE,
            PERMISSION_TYPE.ADD,
            permissions
          ) ? (
            <Button
              className="c-btn c-primary ma-5"
              onClick={() => formAction("add")}
            >
              <i className="fas fa-plus mr-10" />
              Add Scale Program
            </Button>
          ) : (
            ""
          )}
        </div>
      </div>
      <ReactTable
        style={{
          border: "none",
          boxShadow: "none"
        }}
        data={tblData}
        columns={columns}
        defaultPageSize={10}
        minRows={2}
        manual
        defaultFilterMethod={(filter, row) => {
          const id = filter.pivotId || filter.id;

          return row[id] !== undefined
            ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
            : true;
        }}
        className="-striped -highlight custom-react-table-theme-class"
        pages={pages}
        page={activePage}
        PaginationComponent={Pagination}
        loading={loader}
        LoadingComponent={Loader}
        onFetchData={onChangePageGetData}
        onPageChange={pageIndex => {
          setActivePage(pageIndex);
          getList(field, sortBy, searchText, pageIndex + 1);
        }}
        onSortedChange={sortProperties => {
          const sort = sortProperties[0].desc ? "DESC" : "ASC";
          setSortBy(sort);
          setActivePage(0);
          setField(sortProperties[0].id);
          getList(sortProperties[0].id, sort, searchText, 1);
          // alert("sort handler", sortProperties);
          // const orderByDirection = sortProperties[0].desc ? 1 : 0;

          // updateMapStateInfoOrderByColumn(sortProperties[0].id, orderByDirection);
        }}
      />
      <Toaster ref={toaster} />
      <CommonModel
        modal={modal}
        toggle={toggle}
        children={
          <ScaleProgramModal
            scaleProgramList={scaleProgramList}
            onSubmit={submitFormHandler}
            editedData={editedData}
            action={editedData ? "edit" : "add"}
            toggle={toggle}
            {...props}
          />
        }
        header={editedData ? "Edit Scale Program" : "Add Scale Program"}
      />
    </div>
  );
};
export default withRouter(ScaleProgramTable);
