import React, { useRef, useEffect } from "react";
import { compose } from "redux";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import GroupServices from "../../api/GroupServices";
import enhancer from "./insurance-validator";
import { Input } from "reactstrap";
import Button from "../button/Button";
import Toaster from "../common/Toaster";
import { MODULE_NAMES, PERMISSION_TYPE, RD } from "helper/constant";
import { hasAccess } from "util/sidebar";

const InsuranceTab = props => {
  const hasParam = props.match.params.id;

  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    onSubmit,
    values,
    action,
    insuranceData,
    insuranceList,
    permissions
  } = props;

  const toaster = useRef();

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const [selectedInput, setSelectedInput] = React.useState([]);

  useEffect(() => {
    setSelectedInput(insuranceData);
  }, [insuranceData]);

  const checkHandleChange = e => {
    const targtValue = parseInt(e.currentTarget.value);
    setSelectedInput(prev =>
      prev.some(data => data === targtValue)
        ? prev.filter(data => data !== targtValue)
        : [...prev, targtValue]
    );
  };

  const handleSubmit = () => {
    let { values, handleSubmit } = props;
    if (values.primary_insurance_id !== "") {
      onSubmit(values, action);
      const params = {
        group_id: hasParam,
        primary_insurance_id: values.primary_insurance_id,
        other_insurance_id: selectedInput
      };
      GroupServices.addGroupInsurance(params)
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          if (data.status) {
            if (toaster.current) toaster.current.success(data.message);
          } else {
            if (toaster.current) toaster.current.error(data.message);
          }
        })
        .catch(err => {
          if (toaster.current) toaster.current.error(err.response);
        });
    }
    handleSubmit();
  };

  return (
    <div className="row">
      <form>
        <div className="form-group col-md-12">
          <label className="fs-16 medium-text">
            Primary Insurance <span className="asterisk">*</span>
          </label>
          <Input
            type="select"
            name="primary_insurance_id"
            id="primary_insurance_id"
            onChange={handleChange}
            value={values.primary_insurance_id}
            onBlur={handleBlur}
            disabled={props.authData.adminRoleId === RD ? true : false}
          >
            <option value="">Select Primary Insurance</option>
            {insuranceList &&
              insuranceList.map((type, i) => {
                return (
                  <option key={`option${i}`} value={type.id}>
                    {type.name} ({type.is_active ? "Active" : "Inactive"})
                  </option>
                );
              })}
          </Input>
          <Error field="primary_insurance_id" />
        </div>
        <div className="form-group col-md-12">
          <label className="fs-16 medium-text">Other Insurance</label>
          <br />
          {insuranceList &&
            insuranceList.map((type, i) => {
              return (
                <div key={i}>
                  <input
                    type="checkbox"
                    name={`other_insurance_id${type.id}`}
                    onChange={checkHandleChange}
                    value={type.id}
                    disabled={props.authData.adminRoleId === RD ? true : false}
                    checked={
                      selectedInput && selectedInput.includes(type.id)
                        ? "checked"
                        : false
                    }
                  />{" "}
                  {type?.name} ({type?.is_active ? "Active" : "Inactive"})
                  <br />
                </div>
              );
            })}
        </div>
        <div>
          {hasAccess(
            MODULE_NAMES.GROUP_INSURANCE,
            PERMISSION_TYPE.ADD,
            permissions
          ) ? (
            <Button
              type="button"
              className="c-btn c-info form-button fs-16 demi-bold-text"
              style={{ maxWidth: "125px" }}
              onClick={() => handleSubmit()}
            >
              Submit
            </Button>
          ) : (
            ""
          )}
        </div>
      </form>
      <Toaster ref={toaster} />
    </div>
  );
};

export default compose(enhancer)(InsuranceTab);
