import EncryptDecrypt from "api/EncryptDecrypt";
import FaxManagementServices from "api/FaxMangementServices";
import Loader from "components/common/Loader";
import React, { useEffect, useState } from "react";

const FaxViewDetails = props => {
  const [adminList, setAdminList] = useState([]);
  const pageDetails = props.location.state;
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const params = {
      id: pageDetails.id
    };
    setLoader(true);
    FaxManagementServices.view(params).then(res => {
      let data = EncryptDecrypt.decrypt(res.data);
      setAdminList(data.data);
      setLoader(false);
    });
  }, []);

  return (
    <div className="plr-15">
      <div className="roe-card-style mtb-15">
        <div className="roe-card-header module-header">
          <div className="flex-1 fs-16 demi-bold-text">
            <span className="hash"># </span> Fax details
          </div>
        </div>
        {loader ? (
          <Loader loading={loader} />
        ) : (
          <div className="roe-card-body">
            <div className="pt-2">
              <div style={{ fontWeight: "bolder", textAlign: "left" }}>
                Fax Number
              </div>
              <div>{pageDetails.number}</div>
            </div>
            <div className="pt-2">
              <div style={{ fontWeight: "bolder", textAlign: "left" }}>
                Description
              </div>
              <div>{pageDetails.description}</div>
            </div>
            <div className="pt-2">
              <div style={{ fontWeight: "bolder", textAlign: "left" }}>
                Access
              </div>
              <div>
                {adminList?.admin_fax_numbers
                  ?.map(
                    data =>
                      data?.admin?.first_name + " " + data?.admin?.last_name
                  )
                  .join(", ")}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FaxViewDetails;
