import React, { useRef, useEffect, useState, Fragment } from "react";
import { compose } from "redux";
import enhancer from "./editvalidator";
import Button from "../button/Button";
import Toaster from "../common/Toaster";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import VisitService from "../../api/VisitService";
import moment from "moment-timezone";
import { useDebounce } from "helper/useDebounce";
import { RD } from "helper/constant";
import BmiCalculator from "./BmiCalculator";
import { Input } from "reactstrap";
import AdminServices from "api/StaticpageServices";

const EditVisitNote = props => {
  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    setFieldValue,
    setEditedData
  } = props;

  const toaster = useRef(null);
  const noteRef = useRef(null);

  const [loading, setloading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [isAdding, setIsAdding] = useState(false);
  const [updatedTime, setUpdatedTime] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [esignModal, setEsignModal] = useState(false);
  const [signName, setSignName] = useState("");
  const [isSigning, setIsSigning] = useState(false);
  const [isSignError, setIsSignError] = useState(false);
  const [showBmiCalculator, setShowBmiCalculator] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const debouncedNoteText = useDebounce(values?.visit_notes, 1000);

  useEffect(() => {
    if (!isAdding && !!values?.visit_notes) {
      noteRef.current.focus();
    }
  }, [isAdding, values.visit_notes]);

  useEffect(() => {
    if (props.editedData) {
      setStartDate(new Date(props.editedData.appointment_date));
      setStartTime(new Date(props.editedData.appointment_time));

      setSignName(props?.editedData?.signatory);
      setIsSignError(!props?.editedData?.signatory);
    }
  }, [props.editedData]);

  useEffect(() => {
    handleNote("AUTO");
  }, [debouncedNoteText, values.addToNextVisist]);

  useEffect(() => {
    VisitService.visitNoteTemplateDropdownList()
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        setTemplateList(data.data);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err?.message);
      });
  }, []);

  const handleEsignModal = () => {
    setEsignModal(!esignModal);
  };

  const handleSignNameChange = e => {
    setSignName(e.target.value);
    setIsSignError(!e.target.value);
  };

  const handleTemplateChange = e => {
    const params = { id: e.target.value };
    AdminServices.viewVisitNoteTemplate(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        let content = data?.data?.content
          ?.replaceAll("<p>", "")
          .replaceAll("</p>", "\n")
          .replaceAll("<br>", "\n")
          .replaceAll("&nbsp;", " ");
        setFieldValue("visit_notes", content);
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this Template!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current) toaster.current.error(err?.message);
        }
      });
  };

  const handleNote = (type = "BUTTON") => {
    let { values, isValid } = props;

    if (!isAdding && (isValid || (isChanged && values?.visit_notes))) {
      setloading(true);
      if (props.editedData) {
        let editNote = {
          id: values.id,
          visit_notes: values.visit_notes,
          copy_to_next_visit: !!values?.addToNextVisist
        };
        VisitService.editNote(editNote)
          .then(result => {
            const data = EncryptDecrypt.decrypt(result?.data);
            if (data.status) {
              setloading(false);
              props.pageRefresh();
              setUpdatedTime(moment());
              if (type === "BUTTON") props.toggle();
            } else {
              setloading(false);
              if (toaster.current) toaster.current.error(data.message);
            }
          })
          .catch(error => {
            if (toaster.current) toaster.current.error(error.message);
            setloading(false);
          });
      } else {
        setIsAdding(true);
        let addNote = {
          visit_notes: values.visit_notes,
          calendly_appointment_id: props.calendlyId,
          copy_to_next_visit: !!values?.addToNextVisist
        };
        VisitService.addNote(addNote)
          .then(result => {
            const data = EncryptDecrypt.decrypt(result.data);
            if (data?.status) {
              props.pageRefresh();
              setEditedData({
                ...data?.data,
                visit_note: data?.data?.visit_notes
              });
              setloading(false);
              setUpdatedTime(moment());
              if (type === "BUTTON") props.toggle();
              setTimeout(() => {
                setIsAdding(false);
              }, 1000);
            } else {
              if (toaster.current) toaster.current.error(data.message);
              setloading(false);
              setIsAdding(false);
            }
          })
          .catch(error => {
            if (toaster.current) toaster.current.error(error.message);
            setloading(false);
            setIsAdding(false);
          });
      }
    }
  };

  const handleEsignSubmit = () => {
    if (isSignError) return;
    setIsSigning(true);
    let params = {
      signatory: signName.trim(),
      id: props?.editedData?.id,
      visit_notes: values?.visit_notes,
      copy_to_next_visit: !!values?.addToNextVisist
    };

    VisitService.eSignNote(params)
      .then(result => {
        const data = EncryptDecrypt.decrypt(result?.data);
        if (data.status) {
          setEsignModal(false);
          setIsSigning(false);
          props.toggle();
          props.pageRefresh();
        } else {
          if (toaster.current) toaster.current.error(data.message);
          setIsSigning(false);
        }
      })
      .catch(error => {
        if (toaster.current) toaster.current.error(error.message);
        setIsSigning(false);
      });
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <div className="flex flex-column fill-height pos-relative">
      {esignModal && (
        <div className="pos-absolute fill-height fill-width">
          <div className="roe-card-header flex flex-y fill-height fill-width bg-slate">
            <div className="heading font-bold pb-10">E-sign Note</div>
            <div className="pb-10">
              Please complete your e-signature by typing your full name followed
              by your credentials, and click Sign.
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control react-form-input"
                id="sign"
                name="sign"
                onChange={handleSignNameChange}
                placeholder="Add E-Sign"
                value={signName}
              />
              {isSignError && (
                <div className="error-msg">This field is required.</div>
              )}
            </div>

            <div>
              <Button
                type="button"
                className="c-btn c-info form-button fs-16 demi-bold-text mr-10 max-w-125 max-h-36"
                onClick={handleEsignSubmit}
                loading={loading}
                disabled={loading}
              >
                {!isSigning ? "Sign" : "Signing.."}
              </Button>
              <Button
                type="button"
                className="c-btn c-secondary form-button fs-16 demi-bold-text max-w-125 max-h-36"
                onClick={handleEsignModal}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      )}
      <form className="pa-24 pt-0 flex flex-column fill-height overflow-auto">
        {!props.id && (
          <div className="pbottom-20 max-w-300">
            <Input
              type="select"
              name="template"
              id="template"
              onChange={e => handleTemplateChange(e)}
              onBlur={handleBlur}
            >
              <option value="">Select Visit Note Template</option>
              {templateList.map((template, i) => {
                return (
                  <Fragment>
                    <option key={`option${i}`} value={template.id}>
                      {template.title}
                    </option>
                  </Fragment>
                );
              })}
            </Input>
          </div>
        )}
        {(props.id || values?.visit_notes) && (
          <div className="flex flex-row space-between">
            <div>
              <span className="fs-16">Calculate BMI</span>{" "}
              <input
                type="checkbox"
                checked={showBmiCalculator}
                onChange={() => setShowBmiCalculator(!showBmiCalculator)}
              />
              {showBmiCalculator && <BmiCalculator />}
            </div>
            <div>
              <input
                type="checkbox"
                checked={values?.addToNextVisist}
                disabled={props?.adminRole !== RD}
                onChange={() =>
                  setFieldValue("addToNextVisist", !values?.addToNextVisist)
                }
              />
              <span className="plr-1">Copy to Next Visit</span>
            </div>
          </div>
        )}
        <div className="flex flex-column fill-width justify-content-center align-items-end">
          {(props.editedData || updatedTime) && (
            <div>
              <span className="font-bold">Last Updated At:</span>{" "}
              {updatedTime
                ? moment(updatedTime).format("MM/DD/YYYY h:mm a")
                : props?.editedData?.updated_at
                ? moment(props?.editedData?.updated_at).format(
                    "MM/DD/YYYY h:mm a"
                  )
                : "-"}
            </div>
          )}
        </div>

        {(props.id || values?.visit_notes) && (
          <div className="flex flex-column form-group flex-2">
            <label>Visit Note:</label>
            <textarea
              ref={noteRef}
              className="form-control react-form-input fill-height resize-none"
              onChange={e => {
                setIsChanged(true);
                handleChange(e);
              }}
              value={values.visit_notes}
              onBlur={handleBlur}
              placeholder="Visit note"
              id="visit_notes"
              disabled={isAdding}
            ></textarea>

            <Error field="visit_notes" />
          </div>
        )}
        {loading && <div className="ptb-2">Saving Note....</div>}
        {!esignModal && (
          <div className="flex ptop-6">
            {(props.id || values?.visit_notes) && (
              <Button
                type="button"
                className="c-btn c-info form-button fs-16 demi-bold-text mr-10 max-w-125 max-h-36"
                onClick={() => {
                  if (props?.adminRole === RD) {
                    handleEsignModal();
                  } else {
                    handleNote();
                  }
                }}
                disabled={
                  isAdding ||
                  loading ||
                  (props?.adminRole === RD && !props?.editedData?.id)
                }
              >
                {isAdding || loading ? "Loading.." : "Submit"}
              </Button>
            )}
            <Button
              type="button"
              className="c-btn c-secondary form-button fs-16 demi-bold-text max-w-125 max-h-36"
              onClick={props.toggle}
            >
              Close
            </Button>
          </div>
        )}
      </form>
      <Toaster ref={toaster} />
    </div>
  );
};
export default compose(enhancer)(EditVisitNote);
