import { ANS_KEYS, FORMS_KEYS } from "helper/constant";
import React from "react";

const FormElement = ({ question, answer }) => {
  return (
    <div className="p-2 ">
      <div className="fs-16 demi-bold-text">{question}</div>
      <div>
        <i className="fas fa-arrow-right pr-1"></i>
        {answer ? answer : "N/A"}
      </div>
    </div>
  );
};

const GadForm = props => {
  return (
    <div>
      <div className="fs-18 bold-text pb-2">GAD-7</div>
      <FormElement
        question={FORMS_KEYS[2]["feeling_nervous"]}
        answer={ANS_KEYS[props?.data?.feeling_nervous]}
      />
      <FormElement
        question={FORMS_KEYS[2]["control_worrying"]}
        answer={ANS_KEYS[props?.data?.control_worrying]}
      />
      <FormElement
        question={FORMS_KEYS[2]["worrying_too_much"]}
        answer={ANS_KEYS[props?.data?.worrying_too_much]}
      />
      <FormElement
        question={FORMS_KEYS[2]["trouble_relaxing"]}
        answer={ANS_KEYS[props?.data?.trouble_relaxing]}
      />
      <FormElement
        question={FORMS_KEYS[2]["being_so_restless"]}
        answer={ANS_KEYS[props?.data?.being_so_restless]}
      />
      <FormElement
        question={FORMS_KEYS[2]["annoyed_or_irritable"]}
        answer={ANS_KEYS[props?.data?.annoyed_or_irritable]}
      />
      <FormElement
        question={FORMS_KEYS[2]["feeling_afraid"]}
        answer={ANS_KEYS[props?.data?.feeling_afraid]}
      />
      <FormElement
        question={FORMS_KEYS[2]["how_difficult_those_problems"]}
        answer={props?.data?.how_difficult_those_problems.map((data, i) => {
          return (
            <span>
              {ANS_KEYS[data]}
              {i !== props?.data?.how_difficult_those_problems?.length - 1 &&
                ", "}
            </span>
          );
        })}
      />
    </div>
  );
};

export default GadForm;
