import React, { useState, useEffect, useRef } from "react";
import { compose } from "redux";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import BioMetricsTrackingService from "../../api/BioMetricsTracking";
import enhancer from "../viewclient/reassign-rd";
import PageWrapper from "./activitymetrictracking.style";
import { Line } from "react-chartjs-2";
import CommonModel from "../common/CommonModel";
import EditBioMetricTrackingModal from "./EditActivityMetricTrackingModal";
import { Label } from "reactstrap";
import Toaster from "../common/Toaster";

const BioMetricsTrackingDetails = props => {
  const [todayData, setTodayData] = useState([]);

  const [stepMonthData, setStepMonthData] = useState([]);
  const [stepCountData, setStepCountData] = useState([]);
  const [stepIndex, setStepIndex] = useState(-1);

  const [sleepMonthData, setSleepMonthData] = useState([]);
  const [sleepCountData, setSleepCountData] = useState([]);
  const [sleepIndex, setSleepIndex] = useState(-1);

  const [activeMinutesMonthData, setActiveMinutesMonthData] = useState([]);
  const [activeMinutesCountData, setActiveMinutesCountData] = useState([]);
  const [activeMinutesIndex, setActiveMinutesIndex] = useState(-1);

  const [caloriesMonthData, setCaloriesMonthData] = useState([]);
  const [caloriesCountData, setCaloriesCountData] = useState([]);
  const [caloriesIndex, setCaloriesIndex] = useState(-1);

  const [distanceMonthData, setDistanceMonthData] = useState([]);
  const [distanceCountData, setDistanceCountData] = useState([]);
  const [distanceIndex, setDistanceIndex] = useState(-1);

  const [waterIntakeMonthData, setWaterIntakeMonthData] = useState([]);
  const [waterIntakeCountData, setWaterIntakeCountData] = useState([]);
  const [waterIntakeIndex, setWaterIntakeIndex] = useState(-1);

  const [mindfulMinutesMonthData, setMindfulMinutesMonthData] = useState([]);
  const [mindfulMinutesCountData, setMindfulMinutesCountData] = useState([]);
  const [mindfulMinutesIndex, setMindfulMinutesIndex] = useState(-1);

  const hasParam = props.match.params.id;
  const toaster = useRef();
  const [modal, setModal] = useState(undefined);
  const [activityType, setActivityType] = useState(undefined);

  useEffect(() => {
    if (hasParam) {
      getList();
    }
  }, []);

  function getList() {
    const params = {
      user_id: hasParam,
      track_type: "Activity Tracking"
    };

    BioMetricsTrackingService.list(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);

        if (data.data.today_data) {
          let details = data.data.today_data.tracking_detail;
          details.find((item, i) => {
            if (item.activity_type === "step") {
              setStepIndex(i);
            } else if (item.activity_type === "sleep") {
              setSleepIndex(i);
            } else if (item.activity_type === "active_minutes") {
              setActiveMinutesIndex(i);
            } else if (item.activity_type === "calories") {
              setCaloriesIndex(i);
            } else if (item.activity_type === "distance") {
              setDistanceIndex(i);
            } else if (item.activity_type === "water_intake") {
              setWaterIntakeIndex(i);
            } else if (item.activity_type === "mindful_minutes") {
              setMindfulMinutesIndex(i);
            }
          });
          setTodayData(data.data.today_data.tracking_detail);
        }

        setStepMonthData(data.data.monthly_data.step.map(data => data.month));
        setStepCountData(data.data.monthly_data.step.map(data => data.values));
        setSleepMonthData(data.data.monthly_data.sleep.map(data => data.month));
        setSleepCountData(
          data.data.monthly_data.sleep.map(data => data.values)
        );
        setActiveMinutesMonthData(
          data.data.monthly_data.active_minutes.map(data => data.month)
        );
        setActiveMinutesCountData(
          data.data.monthly_data.active_minutes.map(data => data.values)
        );
        setCaloriesMonthData(
          data.data.monthly_data.calories.map(data => data.month)
        );
        setCaloriesCountData(
          data.data.monthly_data.calories.map(data => data.values)
        );
        setDistanceMonthData(
          data.data.monthly_data.distance.map(data => data.month)
        );
        setDistanceCountData(
          data.data.monthly_data.distance.map(data => data.values)
        );
        setWaterIntakeMonthData(
          data.data.monthly_data.water_intake.map(data => data.month)
        );
        setWaterIntakeCountData(
          data.data.monthly_data.water_intake.map(data => data.values)
        );
        setMindfulMinutesMonthData(
          data.data.monthly_data.mindful_minutes.map(data => data.month)
        );
        setMindfulMinutesCountData(
          data.data.monthly_data.mindful_minutes.map(data => data.values)
        );
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current) toaster.current.error(err?.message);
        }
      });
  }

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          }
          // scaleLabel: {
          //   display: true,
          //   labelString: 'Visceral Fat (%)'
          // }
        }
      ]
      // xAxes: [
      //   {
      //     scaleLabel: {
      //       display: true,
      //       labelString: 'Month'
      //     }
      //   },
      // ],
    }
  };

  const stepData = {
    labels: stepMonthData,
    datasets: [
      {
        label: "Step",
        data: stepCountData,
        fill: true,
        backgroundColor: "#AECE38",
        borderColor: "#AECE38"
      }
    ]
  };

  const sleepData = {
    labels: sleepMonthData,
    datasets: [
      {
        label: "Sleep (hr)",
        data: sleepCountData,
        fill: true,
        backgroundColor: "#AECE38",
        borderColor: "#AECE38"
      }
    ]
  };

  const activeMinutesData = {
    labels: activeMinutesMonthData,
    datasets: [
      {
        label: "Active Minutes (min)",
        data: activeMinutesCountData,
        fill: true,
        backgroundColor: "#AECE38",
        borderColor: "#AECE38"
      }
    ]
  };

  const caloriesData = {
    labels: caloriesMonthData,
    datasets: [
      {
        label: "Calories (kcal)",
        data: caloriesCountData,
        fill: true,
        backgroundColor: "#AECE38",
        borderColor: "#AECE38"
      }
    ]
  };

  const distanceData = {
    labels: distanceMonthData,
    datasets: [
      {
        label: "Distance (miles)",
        data: distanceCountData,
        fill: true,
        backgroundColor: "#AECE38",
        borderColor: "#AECE38"
      }
    ]
  };

  const waterIntakeData = {
    labels: waterIntakeMonthData,
    datasets: [
      {
        label: "Water Intake (fl oz)",
        data: waterIntakeCountData,
        fill: true,
        backgroundColor: "#AECE38",
        borderColor: "#AECE38"
      }
    ]
  };

  const mindfulMinutesData = {
    labels: mindfulMinutesMonthData,
    datasets: [
      {
        label: "Mindful Minutes (min)",
        data: mindfulMinutesCountData,
        fill: true,
        backgroundColor: "#AECE38",
        borderColor: "#AECE38"
      }
    ]
  };

  function toggle1() {
    setModal(false);
  }

  const formAction = activity_type => {
    // Here you can view the data and make forward action for edit data
    setModal(true);
    setActivityType(todayData[activity_type]);
  };

  const submitFormHandler1 = () => {
    setModal(false);
    getList();
  };

  return (
    <PageWrapper>
      <div className="">
        <h4 className="text-center pb-2">Activity Tracking</h4>
        <div className="row">
          <div className="col-md-6">
            <table className="w-100 border">
              <tbody>
                <tr className="text-center">
                  <td>
                    <div
                      className="p-2 w-100"
                      style={{ display: "inline-grid" }}
                    >
                      <Label
                        style={{
                          backgroundColor: "#9b9b9c",
                          color: "white",
                          padding: "10px"
                        }}
                      >
                        Steps
                        {stepIndex !== -1 ? (
                          <span
                            className="float-right"
                            onClick={() => formAction(stepIndex)}
                          >
                            <i className="fas fa-edit"></i>
                          </span>
                        ) : (
                          ""
                        )}
                      </Label>
                      <Line data={stepData} options={options} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-6">
            <table className="w-100 border">
              <tbody>
                <tr className="text-center">
                  <td>
                    <div
                      className="p-2 w-100"
                      style={{ display: "inline-grid" }}
                    >
                      <Label
                        style={{
                          backgroundColor: "#9b9b9c",
                          color: "white",
                          padding: "10px"
                        }}
                      >
                        Distance
                        {distanceIndex !== -1 ? (
                          <span
                            className="float-right"
                            onClick={() => formAction(distanceIndex)}
                          >
                            <i className="fas fa-edit"></i>
                          </span>
                        ) : (
                          ""
                        )}
                      </Label>
                      <Line data={distanceData} options={options} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-6 mt-4">
            <table className="w-100 border">
              <tbody>
                <tr className="text-center">
                  <td>
                    <div
                      className="p-2 w-100"
                      style={{ display: "inline-grid" }}
                    >
                      <Label
                        style={{
                          backgroundColor: "#9b9b9c",
                          color: "white",
                          padding: "10px"
                        }}
                      >
                        Active Minutes
                        {activeMinutesIndex !== -1 ? (
                          <span
                            className="float-right"
                            onClick={() => formAction(activeMinutesIndex)}
                          >
                            <i className="fas fa-edit"></i>
                          </span>
                        ) : (
                          ""
                        )}
                      </Label>
                      <Line data={activeMinutesData} options={options} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-6 mt-4">
            <table className="w-100 border">
              <tbody>
                <tr className="text-center">
                  <td>
                    <div
                      className="p-2 w-100"
                      style={{ display: "inline-grid" }}
                    >
                      <Label
                        style={{
                          backgroundColor: "#9b9b9c",
                          color: "white",
                          padding: "10px"
                        }}
                      >
                        Calories Burned
                        {caloriesIndex !== -1 ? (
                          <span
                            className="float-right"
                            onClick={() => formAction(caloriesIndex)}
                          >
                            <i className="fas fa-edit"></i>
                          </span>
                        ) : (
                          ""
                        )}
                      </Label>
                      <Line data={caloriesData} options={options} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-6 mt-4">
            <table className="w-100 border">
              <tbody>
                <tr className="text-center">
                  <td>
                    <div
                      className="p-2 w-100"
                      style={{ display: "inline-grid" }}
                    >
                      <Label
                        style={{
                          backgroundColor: "#9b9b9c",
                          color: "white",
                          padding: "10px"
                        }}
                      >
                        Water Intake
                        {waterIntakeIndex !== -1 ? (
                          <span
                            className="float-right"
                            onClick={() => formAction(waterIntakeIndex)}
                          >
                            <i className="fas fa-edit"></i>
                          </span>
                        ) : (
                          ""
                        )}
                      </Label>
                      <Line data={waterIntakeData} options={options} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-6 mt-4">
            <table className="w-100 border">
              <tbody>
                <tr className="text-center">
                  <td>
                    <div
                      className="p-2 w-100"
                      style={{ display: "inline-grid" }}
                    >
                      <Label
                        style={{
                          backgroundColor: "#9b9b9c",
                          color: "white",
                          padding: "10px"
                        }}
                      >
                        Sleep
                        {sleepIndex !== -1 ? (
                          <span
                            className="float-right"
                            onClick={() => formAction(sleepIndex)}
                          >
                            <i className="fas fa-edit"></i>
                          </span>
                        ) : (
                          ""
                        )}
                      </Label>
                      <Line data={sleepData} options={options} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-6 mt-4">
            <table className="w-100 border">
              <tbody>
                <tr className="text-center">
                  <td>
                    <div
                      className="p-2 w-100"
                      style={{ display: "inline-grid" }}
                    >
                      <Label
                        style={{
                          backgroundColor: "#9b9b9c",
                          color: "white",
                          padding: "10px"
                        }}
                      >
                        Mindful Minutes
                        {mindfulMinutesIndex !== -1 ? (
                          <span
                            className="float-right"
                            onClick={() => formAction(mindfulMinutesIndex)}
                          >
                            <i className="fas fa-edit"></i>
                          </span>
                        ) : (
                          ""
                        )}
                      </Label>
                      <Line data={mindfulMinutesData} options={options} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <CommonModel
        modal={modal}
        toggle1={toggle1}
        children={
          <EditBioMetricTrackingModal
            onSubmit1={submitFormHandler1}
            todayData={todayData}
            activityType={activityType}
            toggle1={toggle1}
            {...props}
          />
        }
        header={"Edit Tracking Data"}
      />
      <Toaster ref={toaster} />
    </PageWrapper>
  );
};

export default compose(enhancer)(BioMetricsTrackingDetails);
