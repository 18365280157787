import axios from "axios";
import { baseUrl } from "../helper/constant";
import Header from "./Header";
import EncryptDecrypt from "./EncryptDecrypt";

export default class PracticeMangementServices {
  static list(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/practice-management/list-all-practice`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static add(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/practice-management/create`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static edit(params) {
    Header.setHeaders();
    return axios.patch(
      `${baseUrl}/practice-management/update-practice`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static status(params) {
    Header.setHeaders();
    return axios.patch(
      `${baseUrl}/practice-management/update-status`,
      EncryptDecrypt.encrypt(params)
    );
  }
}
