import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    activity_value: Yup.string()
      .trim()
      .required("This field is required"),
    activity_value_in: Yup.string()
      .trim()
      .required("This field is required"),
    selected_date: Yup.string()
      .trim()
      .required("This field is required")
  }),
  mapPropsToValues: props => ({
    activity_value:
      props && props.activityType
        ? props.activityType.activity_type === "height"
          ? props.activityType.values.toString().split(".")[0]
          : props.activityType.values
        : "",
    activity_value_in:
      props &&
      props.activityType &&
      props.activityType.activity_type === "height"
        ? props.activityType.values.toString().split(".")[1]
        : ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
