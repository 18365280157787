import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import ReportServices from "../../api/ReportServices";
import Loader from "../common/Loader";
import Toaster from "../common/Toaster";
import {
  APPOINTMENT_STATUS,
  APPOINTMENT_STATUS_LABEL,
  DEFAULT_RD_DROPDOWN,
  PROVIDER_TYPE,
  PROVIDER_TYPES,
  TABLE
} from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import moment from "moment";
import Button from "components/button/Button";
import CommonModel from "../common/CommonModel";
import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import ClientServices from "../../api/ClientServices";
import CategoryServices from "api/CategoryServices";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
import Select from "react-select";
import CommonServices from "api/CommonServices";

const VisitDetailTable = props => {
  const toaster = useRef(null);
  const [tblData, settblData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("id");
  const [sortBy, setSortBy] = useState("DESC");
  const [dateRange, setDateRange] = useState({});
  const [modal, setModal] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [RDId, setRDId] = useState(null);
  const [RDName, setRDName] = useState(null);
  const [RDList, setRDList] = useState([]);
  const [groupId, setGroupId] = useState(null);
  const [groupName, setGroupName] = useState(null);
  const [groupList, setGroupList] = useState([]);
  const [visitStatus, setVisitStatus] = useState([]);
  const [RDDropdownOpen, setRDDropdownOpen] = useState(false);
  const [groupDropdownOpen, setGroupDropdownOpen] = useState(false);
  const [visitStatusDropdownOpen, setVisitStatusDropdownOpen] = useState(false);
  const [serviceDropdownOpen, setServiceDropdownOpen] = useState(false);
  const [serviceId, setServiceId] = useState(null);
  const [serviceName, setServiceName] = useState(null);
  const [serviceList, setServiceList] = useState([]);
  const [typeName, setTypeName] = useState(null);
  const [typeValue, setTypeValue] = useState(null);
  const [typeDropdownOpen, setTypeDropdownOpen] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const [practiceList, setPracticeList] = useState([]);
  const [practiceId, setPracticeId] = useState(null);
  const [practiceName, setPracticeName] = useState(null);
  const [practiceDropdownOpen, setPracticeDropdownOpen] = useState(false);

  const [filteredRDList, setFilteredRDList] = useState([]);

  const togglePractice = () => setPracticeDropdownOpen(prevState => !prevState);

  useEffect(() => {
    // getList(field, sortBy, searchText, activePage + 1);
    // setPages(10);
    const params1 = {
      type: "",
      practice_id:
        props?.admin_role_id === 6 && props?.practice
          ? props?.practice
          : practiceId
          ? practiceId
          : undefined,
      id: props?.admin_role_id > 2 ? props?.service_id : undefined
    };
    ClientServices.RDList(params1).then(result => {
      let data = EncryptDecrypt.decrypt(result.data);
      setRDList(data.data);
    });
    const groupParams = {
      service_id: props.admin_role_id < 3 ? serviceId : props?.service_id
    };
    ClientServices.groupList(groupParams).then(result => {
      let data = EncryptDecrypt.decrypt(result.data);
      setGroupList(data.data);
    });
  }, [practiceId, serviceId]);

  useEffect(() => {
    let filtered = [...RDList];

    if (serviceId) {
      filtered = filtered.filter(rd => {
        return (
          rd?.rd_detail?.service_id === serviceId ||
          rd?.id === Number(DEFAULT_RD_DROPDOWN)
        );
      });
    }

    if (typeValue) {
      filtered = filtered.filter(rd => {
        return rd?.rd_detail?.type === typeValue;
      });
    }

    // if (!serviceId && !typeValue) filtered = [];

    setFilteredRDList([...filtered]);
  }, [serviceId, typeValue, RDList]);

  useEffect(() => {
    CategoryServices.serviceDropdown()
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setServiceList([...data.data]);
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      });
  }, []);

  useEffect(() => {
    const params = {
      get_all: true,
      service_id:
        props.admin_role_id > 3
          ? props.service_id
          : serviceId
          ? serviceId
          : undefined
    };
    CommonServices.iboPracticeDropdownList(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setPracticeList([...data.data]);
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch Practice!");
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch Practice!");
        }
      });
  }, [props?.admin_role_id, serviceId]);

  useEffect(() => {
    getList(field, sortBy, searchText, 1);
    setPages(10);
  }, [
    RDId,
    groupId,
    visitStatus,
    dateRange.startDate,
    dateRange.endDate,
    serviceId,
    typeValue,
    practiceId
  ]);

  const toggleRD = () => setRDDropdownOpen(prevState => !prevState);
  const toggleGroup = () => setGroupDropdownOpen(prevState => !prevState);
  const toggleService = () => setServiceDropdownOpen(prevState => !prevState);
  const toggleVisitStatus = () =>
    setVisitStatusDropdownOpen(prevState => !prevState);
  const toggleType = () => setTypeDropdownOpen(prevState => !prevState);

  const columns = [
    {
      Header: "Client ID",
      accessor: "user.id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 150
    },
    {
      Header: "Client First Name",
      accessor: "user.first_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200
    },
    {
      Header: "Client Last Name",
      accessor: "user.last_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200
    },
    {
      Header: "Client Email",
      accessor: "user.email",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 300
    },
    {
      Header: "Service Date",
      accessor: "appointment_date",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 150,
      Cell: props =>
        moment(props.original.appointment_date).format("MM/DD/YYYY")
    },
    {
      Header: "Service",
      accessor: "service.name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      width: 130
    },
    {
      Header: "Provider",
      accessor: "admin.first_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      Cell: props => (
        <div className="react-action-class">
          {props.original.admin
            ? props.original.admin.first_name +
              " " +
              props.original.admin.last_name
            : ""}
        </div>
      ),
      width: 220
    },
    {
      Header: "Appointment Type",
      accessor: "appointment_type",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 200,
      sortable: true
    },
    {
      Header: "Status",
      accessor: "status",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 150,
      Cell: props => APPOINTMENT_STATUS_LABEL[props.original.status]
    },
    {
      Header: "Group",
      accessor: "`user->user_rd_service->group`.`name`",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 200,
      sortable: true,
      Cell: props => (
        <div className="react-action-class">
          {props.original.user &&
          props.original.user.user_rd_service &&
          props.original.user.user_rd_service.group
            ? props.original.user.user_rd_service.group.name
            : ""}
        </div>
      )
    },
    {
      Header: "State",
      accessor: "`user->state`.`name`",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 200,
      sortable: true,
      Cell: props => (
        <div className="react-action-class">
          {props.original.user && props.original.user.state
            ? props.original.user.state.name
            : ""}
        </div>
      )
    },
    {
      Header: "Zipcode",
      accessor: "user.zipcode",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200
    },
    {
      Header: "Insurance Carrier",
      accessor: "`visit_bill->user_insurance->insurance`.`name`",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 200,
      sortable: true,
      Cell: props => (
        <div className="react-action-class">
          {props.original.visit_bill &&
          props.original.visit_bill.user_insurance &&
          props.original.visit_bill.user_insurance.insurance
            ? props.original.visit_bill.user_insurance.insurance.name
            : ""}
        </div>
      )
    },
    {
      Header: "Event Name",
      accessor: "",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 200,
      sortable: false,
      Cell: props => (
        <div className="react-action-class">
          {props.original.event_detail ? props.original.event_detail.name : ""}
        </div>
      )
    },
    {
      Header: "Registration Date",
      accessor: "user.created_at",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200,
      Cell: props =>
        props.original.user
          ? moment(props.original.user.created_at).format("MM/DD/YYYY")
          : ""
    }
  ];

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  function exportList(
    field = "name",
    sortBy = "DESC",
    search = "",
    page = "1"
  ) {
    const params = {
      rd_id: RDId,
      group_id: groupId,
      visit_status: visitStatus ? visitStatus.map(status => status.value) : [],
      start_date: dateRange.startDate
        ? moment(dateRange.startDate).format("YYYY-MM-DD")
        : null,
      end_date: dateRange.endDate
        ? moment(dateRange.endDate).format("YYYY-MM-DD")
        : null,
      service_id: props?.admin_role_id < 3 ? serviceId : props?.service_id,
      rd_type: typeValue,
      practice_id:
        props?.admin_role_id === 6 && props?.practice
          ? props?.practice
          : practiceId
          ? practiceId
          : undefined
    };
    setIsExporting(true);
    ReportServices.visitDetailListCsv(params)
      .then(res => {
        setIsExporting(false);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error("Something went wrong!");
        setIsExporting(false);
      });
  }

  const formAction = (action, data = null) => {
    // Here you can view the data and make forward action for edit data
    if (action === "export") {
      exportList(field, sortBy, searchText, activePage + 1);
    }
  };

  function getList(field = "name", sortBy = "DESC", search = "", page = "1") {
    const params = {
      sort_by: field,
      order: sortBy,
      search: search,
      page: page,
      rd_id: RDId,
      service_id: props?.admin_role_id < 3 ? serviceId : props?.service_id,
      group_id: groupId,
      visit_status: visitStatus ? visitStatus.map(status => status.value) : [],
      start_date: dateRange.startDate
        ? moment(dateRange.startDate).format("YYYY-MM-DD")
        : null,
      end_date: dateRange.endDate
        ? moment(dateRange.endDate).format("YYYY-MM-DD")
        : null,
      rd_type: typeValue,
      practice_id:
        props?.admin_role_id === 6 && props?.practice
          ? props?.practice
          : practiceId
          ? practiceId
          : undefined
    };
    setLoader(true);
    ReportServices.visitDetailList(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count / TABLE.LIMIT));
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
        setLoader(false);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.message);
        setLoader(false);
      });
  }

  const toggle = () => {
    setOpen(!open);
    setModal(!open);
  };

  const options = [
    {
      value: APPOINTMENT_STATUS.SCHEDULE,
      label: APPOINTMENT_STATUS_LABEL.active
    },
    {
      value: APPOINTMENT_STATUS.PENDING,
      label: APPOINTMENT_STATUS_LABEL.pending
    },
    {
      value: APPOINTMENT_STATUS.COMPLETED,
      label: APPOINTMENT_STATUS_LABEL.completed
    },
    {
      value: APPOINTMENT_STATUS.CANCELLED,
      label: APPOINTMENT_STATUS_LABEL.canceled
    },
    {
      value: APPOINTMENT_STATUS.NO_SHOW,
      label: APPOINTMENT_STATUS_LABEL.noshow
    }
  ];

  const statusChange = e => {
    setActivePage(0);
    setVisitStatus(e);
  };

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Visit Details Report
            </div>
            <div>
              <Button
                className="c-btn c-primary ma-5"
                onClick={() => formAction("export")}
                disabled={isExporting || !tblData?.length}
              >
                <i className="fas fa-download mr-10" />
                {!isExporting ? "Export" : "Exporting..."}
              </Button>
            </div>
          </div>
          <div className="roe-card-header module-header flex-wrap rgap">
            <div className="d-flex">
              <div className="mr-10">
                <Button
                  className="btn btn-secondary btn-md"
                  onClick={() => toggle()}
                >
                  {dateRange.startDate
                    ? `${moment(dateRange.startDate).format(
                        "MM/DD/YYYY"
                      )} - ${moment(dateRange.endDate).format("MM/DD/YYYY")}`
                    : "Select Date"}
                </Button>
                {dateRange && dateRange.startDate && (
                  <Button
                    className="btn btn-secondary btn-md ml-2"
                    onClick={() => {
                      setActivePage(0);
                      setDateRange({});
                    }}
                  >
                    Reset date
                  </Button>
                )}
              </div>
            </div>
            {props.admin_role_id !== 3 && (
              <>
                {props.admin_role_id < 3 && (
                  <div className="fs-14  mr-10">
                    <Dropdown
                      isOpen={serviceDropdownOpen}
                      toggle={toggleService}
                    >
                      <DropdownToggle caret size="md">
                        {serviceId ? serviceName : "Service"}
                      </DropdownToggle>
                      <DropdownMenu
                        modifiers={{
                          setMaxHeight: {
                            enabled: true,
                            order: 890,
                            fn: data => {
                              return {
                                ...data,
                                styles: {
                                  ...data.styles,
                                  overflow: "auto",
                                  maxHeight: "300px"
                                }
                              };
                            }
                          }
                        }}
                      >
                        <DropdownItem
                          key="-1"
                          onClick={() => {
                            setActivePage(0);
                            setPracticeList([]);
                            setPracticeId("");
                            setPracticeName("");
                            setServiceId("");
                            setServiceName("All");
                          }}
                        >
                          All
                        </DropdownItem>
                        {serviceList.length > 0 &&
                          serviceList.map((result, i) => {
                            return (
                              <DropdownItem
                                key={i}
                                onClick={() => {
                                  setActivePage(0);
                                  setPracticeList([]);
                                  setPracticeId("");
                                  setPracticeName("");
                                  setServiceId(result.id);
                                  setServiceName(`${result.name}`);
                                  setRDId("");
                                  setRDName("");
                                }}
                              >
                                {result.name}
                              </DropdownItem>
                            );
                          })}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                )}
                {props?.admin_role_id !== 6 && props?.admin_role_id !== 3 && (
                  <>
                    <div className="fs-14 mr-10">
                      <Dropdown isOpen={typeDropdownOpen} toggle={toggleType}>
                        <DropdownToggle caret size="md">
                          {typeName ? typeName : "Type"}
                        </DropdownToggle>
                        <DropdownMenu
                          modifiers={{
                            setMaxHeight: {
                              enabled: true,
                              order: 890,
                              fn: data => {
                                return {
                                  ...data,
                                  styles: {
                                    ...data.styles,
                                    overflow: "auto",
                                    maxHeight: "300px"
                                  }
                                };
                              }
                            }
                          }}
                        >
                          <DropdownItem
                            key="-1"
                            onClick={() => {
                              setRDId(null);
                              setRDName(null);
                              setTypeName("All");
                              setTypeValue("");
                              setActivePage(0);
                            }}
                          >
                            All
                          </DropdownItem>
                          {PROVIDER_TYPES.map((result, i) => {
                            return (
                              <DropdownItem
                                key={i}
                                onClick={() => {
                                  setRDId(null);
                                  setRDName(null);
                                  setTypeName(`${result.name}`);
                                  setTypeValue(`${result.value}`);
                                  setActivePage(0);
                                  setPracticeName("");
                                  setPracticeId("");
                                }}
                              >
                                {result.name}
                              </DropdownItem>
                            );
                          })}
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                    <div className="fs-14 mr-10">
                      <Dropdown
                        isOpen={practiceDropdownOpen}
                        toggle={togglePractice}
                        disabled={typeValue && typeValue !== "ibo practice"}
                      >
                        <DropdownToggle
                          caret
                          size="md"
                          className={
                            typeValue &&
                            typeValue !== "ibo practice" &&
                            "cursor-not-allowed"
                          }
                        >
                          {practiceId ? practiceName : "Select Practice"}
                        </DropdownToggle>
                        <DropdownMenu
                          modifiers={{
                            setMaxHeight: {
                              enabled: true,
                              order: 890,
                              fn: data => {
                                return {
                                  ...data,
                                  styles: {
                                    ...data.styles,
                                    overflow: "auto",
                                    maxHeight: "300px"
                                  }
                                };
                              }
                            }
                          }}
                        >
                          <DropdownItem
                            key="-1"
                            onClick={() => {
                              setRDId(null);
                              setRDName(null);
                              setPracticeId("");
                              setPracticeName("All");
                              setActivePage(0);
                            }}
                          >
                            All
                          </DropdownItem>
                          {practiceList.length > 0 &&
                            practiceList.map((result, i) => {
                              return (
                                <DropdownItem
                                  key={i}
                                  onClick={() => {
                                    setRDId(null);
                                    setRDName(null);
                                    setPracticeId(result.id);
                                    setPracticeName(`${result.practice_name}`);
                                    setActivePage(0);
                                  }}
                                >
                                  {result.practice_name}
                                </DropdownItem>
                              );
                            })}
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </>
                )}
                {props.admin_role_id !== 3 && (
                  <div className="fs-14  mr-10">
                    <Dropdown isOpen={RDDropdownOpen} toggle={toggleRD}>
                      <DropdownToggle caret size="md">
                        {RDName ? RDName : "Providers"}
                      </DropdownToggle>
                      <DropdownMenu
                        modifiers={{
                          setMaxHeight: {
                            enabled: true,
                            order: 890,
                            fn: data => {
                              return {
                                ...data,
                                styles: {
                                  ...data.styles,
                                  overflow: "auto",
                                  maxHeight: "300px"
                                }
                              };
                            }
                          }
                        }}
                      >
                        <DropdownItem
                          key="-1"
                          onClick={() => {
                            setActivePage(0);
                            setRDId("");
                            setRDName("All");
                          }}
                        >
                          All
                        </DropdownItem>

                        {filteredRDList.length > 0 &&
                          filteredRDList.map((result, i) => {
                            return (
                              <DropdownItem
                                key={i + 1}
                                onClick={() => {
                                  setActivePage(0);
                                  setRDId(result.id);
                                  setRDName(
                                    `${result.first_name} ${result.last_name}`
                                  );
                                }}
                              >
                                {result.first_name} {result.last_name}{" "}
                                {result?.rd_detail?.type
                                  ? `(${
                                      PROVIDER_TYPE[result?.rd_detail?.type]
                                    })`
                                  : "(-)"}
                              </DropdownItem>
                            );
                          })}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                )}
              </>
            )}
            <div className="fs-14 mr-10">
              <Dropdown isOpen={groupDropdownOpen} toggle={toggleGroup}>
                <DropdownToggle caret size="md">
                  {groupName ? groupName : "Select Group"}
                </DropdownToggle>
                <DropdownMenu
                  modifiers={{
                    setMaxHeight: {
                      enabled: true,
                      order: 890,
                      fn: data => {
                        return {
                          ...data,
                          styles: {
                            ...data.styles,
                            overflow: "auto",
                            maxHeight: "300px"
                          }
                        };
                      }
                    }
                  }}
                >
                  <DropdownItem
                    key="0"
                    onClick={() => {
                      setActivePage(0);
                      setGroupId("");
                      setGroupName("All");
                    }}
                  >
                    All
                  </DropdownItem>
                  {groupList.length > 0 &&
                    groupList.map((result, i) => {
                      return (
                        <DropdownItem
                          key={i}
                          onClick={() => {
                            setActivePage(0);
                            setGroupId(result.id);
                            setGroupName(result.name);
                          }}
                        >
                          {result.name}
                        </DropdownItem>
                      );
                    })}
                </DropdownMenu>
              </Dropdown>
            </div>
            <div
              className="fs-14 mr-10"
              style={{ width: "25%", zIndex: "100" }}
            >
              <Select
                options={options}
                isMulti
                onChange={statusChange}
                placeholder={<div>Visit Status</div>}
              />
            </div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              LoadingComponent={Loader}
              onFetchData={onChangePageGetData}
              onPageChange={pageIndex => {
                setActivePage(pageIndex);
                getList(field, sortBy, searchText, pageIndex + 1);
              }}
              onSortedChange={sortProperties => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getList(sortProperties[0].id, sort, searchText, 1);
                // alert("sort handler", sortProperties);
                // const orderByDirection = sortProperties[0].desc ? 1 : 0;

                // updateMapStateInfoOrderByColumn(sortProperties[0].id, orderByDirection);
              }}
            />
          </div>
          <CommonModel
            modal={modal}
            toggle={toggle}
            size="lg"
            children={
              <DateRangePicker
                open={open}
                toggle={() => toggle()}
                onChange={range => {
                  setActivePage(0);
                  setDateRange(range);
                  // setModal(!modal)
                  toggle();
                }}
                closeOnClickOutside={true}
              />
            }
            // header={editedData ? "" : "Add Registration Code"}
          />
        </div>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};

export default withRouter(VisitDetailTable);
