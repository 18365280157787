import React, { useRef, useEffect, useState } from "react";
import { compose } from "redux";
import enhancer from "../group/registation-code-validator";
import Button from "../button/Button";
import Toaster from "../common/Toaster";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import FoodTrackingService from "../../api/FoodTracking";
import PageWrapper from "./foodtracking.style";
import moment from "moment";
import { PLACEHOLDER } from "helper/constant";
import Logo from "../../assets/images/logo192.png";
import Shimmer from "components/common/Shimmer";

const FoodModel = props => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    setFieldValue,
    onSubmit,
    values,
    action
  } = props;
  let [commentList, setCommentList] = useState([]);
  let [comment, setComment] = useState(null);
  let [isComment, setIsComment] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(true);

  useEffect(() => {
    FoodTrackingService.commentList({ id: props.editedData.id })
      .then(result => {
        let data = EncryptDecrypt.decrypt(result.data);

        setCommentList(data.data);
      })
      .catch(error => {
        console.log("error", error);
      });
  }, []);
  useEffect(() => {
    FoodTrackingService.commentList({ id: props.editedData.id })
      .then(result => {
        let data = EncryptDecrypt.decrypt(result.data);
        setCommentList(data.data);
      })
      .catch(error => {
        console.log("error", error);
      });
  }, [isComment]);

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };
  const toaster = useRef();

  const handleImageLoad = () => {
    setIsImageLoading(false); // Image has loaded
  };

  const handleImageError = () => {
    setIsImageLoading(false); // Stop loader even if there's an error
  };

  const handleSubmit = e => {
    let { values, isValid, handleSubmit } = props;
    if (comment) {
      // e.preventdefault()
      const params = {
        food_tracking_id: props.editedData.id,
        comment: comment
      };

      FoodTrackingService.addComment(params)
        .then(result => {
          setIsComment(!isComment);
          setComment("");
        })
        .catch(error => {
          console.log("handleSubmit -> error", error);
        });
    }
  };

  const handleKeypress = e => {
    if (e.key == "Enter") {
      handleSubmit();
    }
  };

  return (
    <PageWrapper>
      <div className="close-arw" onClick={props.toggle1}>
        <i
          class="fa fa-times-circle-o fa-lg"
          onClick={props.toggle1}
          aria-hidden="true"
        ></i>
      </div>
      <div className="row d-flex justify-content-center">
        <div className="col-sm-6 food-box-border">
          <h3 className="text-center">{props.editedData.description}</h3>
          <div className="m-2">
            <h6>
              Meal Date:{" "}
              {moment(props.editedData.meal_date).format("MM/DD/YYYY")}
            </h6>

            <h6>
              Meal Type:{" "}
              {props.editedData.meal_type === "Brunch"
                ? "Snack"
                : props.editedData.meal_type}
            </h6>
            <h6>
              Meal Goals:{" "}
              {props.editedData.user_food_items
                .map(item => item.food_item.food_name)
                .join(", ")}
            </h6>
          </div>
          {isImageLoading && (
            <Shimmer width="100%" height="450px" borderRadius="5px" />
          )}
          <img
            src={props.editedData.meal_image || PLACEHOLDER}
            className={`w-100 food-img ${isImageLoading ? "d-none" : ""}`}
            onLoad={handleImageLoad}
            onError={handleImageError}
            alt="Meal"
          />
        </div>

        <div className="col-sm-5 d-flex flex-column cmnt-box-border justify-content-between pb-8 ">
          <div className="comment-list">
            {commentList.length
              ? commentList.map(result => {
                  return (
                    <div
                      className={
                        result.admin
                          ? "d-flex justify-content-end mt-2 mr-3"
                          : "d-flex  mt-2"
                      }
                    >
                      {!result.admin &&
                        (result?.user?.profile_photo ? (
                          <img
                            src={result.user.profile_photo}
                            className="profile-box"
                          />
                        ) : (
                          <img src={PLACEHOLDER} className="profile-box" />
                        ))}
                      <div
                        className={`ml-2 align-items-center ${
                          result.admin ? "comment-admin-box" : "comment-box"
                        }`}
                      >
                        {result.comment}
                      </div>
                      {result.admin &&
                        (props?.auth?.profile_image ? (
                          <img
                            src={props.auth.profile_image}
                            className="profile-box"
                          />
                        ) : (
                          <img src={Logo} className="profile-box" />
                        ))}
                    </div>
                  );
                })
              : "No comments"}
          </div>

          <div className="send-btn d-flex">
            <div className="w-100">
              <input
                type="text"
                className=" form-control react-form-inputs w-100"
                id="comment"
                onChange={e => setComment(e.target.value)}
                value={comment}
                onBlur={handleBlur}
                placeholder="Add Comment"
                onKeyPress={e => handleKeypress(e)}
              />
              <Error field="comment" />
            </div>
            <Button
              type="button"
              loading={false}
              className="btn w-5 btn-info"
              dataStyle="expand-right"
              style={{ position: "relative", fontSize: "12px" }}
              onClick={handleSubmit}
            >
              {/* Add Comment */}
              <i class="fa fa-paper-plane" aria-hidden="true"></i>
            </Button>
          </div>
        </div>
      </div>

      <Toaster ref={toaster} />
    </PageWrapper>
  );
};

export default compose(enhancer)(FoodModel);
