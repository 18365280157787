export const AppName = "HUSK Nutrition";
export const drawerWidth = "300px";
export const miniDrawerWidth = "80px";
export const themeSettingDrawerWidth = "300px";
export const chatDrawerWidth = "290px";
export const chatMiniDrawerWidth = "0px";
export const baseUrl = process.env.REACT_APP_BASE_URL;
export const googleApiKey = "AIzaSyATWjRZ-Oz07UBNdE7e6OaL87eWsi6Ig2I";
export const brownShoe = require("assets/images/brown-shoe.jpeg");
export const speaker = require("assets/images/speaker.jpeg");
export const loginBack = require("assets/images/loginback.jpg");
export const iconDemo = require("assets/images/logo.png");
export const iconDemo1 = require("assets/images/husk_white_logo.png");
export const roelogo2 = require("assets/images/roelogo2.png");
export const ForgotIcon = require("assets/images/forgotpassword.svg");
export const locakscreenBack = require("assets/images/lockscreen.jpg");
export const ProfileLockScreen = require("assets/images/defaultProfile.png");
export const PLACEHOLDER = require("assets/images/placeholder.jpg");
export const FOODIMAGE = require("assets/images/food.png");
export const CLIENTIMAGE = require("assets/images/client.png");
export const MESSAGEIMAGE = require("assets/images/message.png");
export const GOALIMAGE = require("assets/images/goal.png");
export const errorback = require("assets/images/errorback.jpg");
export const feed1 = require("assets/images/feed1.jpg");
export const feed2 = require("assets/images/feed2.jpg");
export const friend1 = require("assets/images/friend1.jpg");
export const friend2 = require("assets/images/friend2.jpg");
export const friend3 = require("assets/images/friend3.jpg");
export const friend4 = require("assets/images/friend4.jpg");
export const friend5 = require("assets/images/friend5.jpg");
export const friend6 = require("assets/images/friend6.jpg");
export const media1 = require("assets/images/media1.jpeg");
export const media2 = require("assets/images/media2.jpeg");
export const media3 = require("assets/images/media3.jpeg");
export const media4 = require("assets/images/media4.jpeg");
export const media5 = require("assets/images/media5.jpeg");
export const media6 = require("assets/images/media6.jpeg");
export const media7 = require("assets/images/media7.jpeg");
export const media8 = require("assets/images/media8.jpeg");
export const media9 = require("assets/images/media9.jpeg");
export const sidebar1 = require("assets/images/sidebar1.jpg");
export const sidebar2 = require("assets/images/sidebar2.jpg");
export const sidebar3 = require("assets/images/sidebar3.jpg");
export const sidebar4 = require("assets/images/sidebar4.jpg");
export const sidebar5 = require("assets/images/sidebar5.jpg");
export const sidebar6 = require("assets/images/sidebar6.jpg");
export const sidebar7 = require("assets/images/sidebar7.jpg");
export const sidebar8 = require("assets/images/sidebar8.jpg");
export const Img6464 = require("assets/images/6464Img.svg");
export const boardBanner = require("assets/images/boardbanner.jpeg");

export const people1 = require("assets/images/boardImages/people1.jpeg");
export const people2 = require("assets/images/boardImages/people2.jpeg");
export const people3 = require("assets/images/boardImages/people3.jpeg");
export const people4 = require("assets/images/boardImages/people4.jpeg");
export const people5 = require("assets/images/boardImages/people5.jpeg");
export const people6 = require("assets/images/boardImages/people6.jpeg");
export const people7 = require("assets/images/boardImages/people7.jpeg");
export const people8 = require("assets/images/boardImages/people8.jpeg");
export const people9 = require("assets/images/boardImages/people9.jpeg");
export const people10 = require("assets/images/boardImages/people10.jpeg");
export const people11 = require("assets/images/boardImages/people11.jpeg");
export const people12 = require("assets/images/boardImages/people12.jpeg");
export const people13 = require("assets/images/boardImages/people13.jpeg");
export const people14 = require("assets/images/boardImages/people14.jpeg");
export const people15 = require("assets/images/boardImages/people15.jpeg");

export const cover1 = require("assets/images/boardImages/cover1.jpeg");
export const cover2 = require("assets/images/boardImages/cover2.jpeg");
export const cover3 = require("assets/images/boardImages/cover3.jpeg");
export const cover4 = require("assets/images/boardImages/cover4.jpg");
export const cover5 = require("assets/images/boardImages/cover5.jpeg");
export const cover6 = require("assets/images/boardImages/cover6.jpeg");
export const cover7 = require("assets/images/boardImages/cover7.jpeg");
export const cover8 = require("assets/images/boardImages/cover8.jpeg");
export const cover9 = require("assets/images/boardImages/cover9.jpeg");
export const cover10 = require("assets/images/boardImages/cover10.jpeg");

// File Manager

export const folder = require("assets/images/filemanager/folder.jpg");
export const file1 = require("assets/images/filemanager/file1.jpg");
export const file2 = require("assets/images/filemanager/file2.jpg");
export const file3 = require("assets/images/filemanager/file3.jpg");
export const file4 = require("assets/images/filemanager/file4.jpg");
export const file5 = require("assets/images/filemanager/file5.jpg");
export const file6 = require("assets/images/filemanager/file6.jpg");
export const file7 = require("assets/images/filemanager/file7.jpg");
export const file8 = require("assets/images/filemanager/file8.jpg");
export const file9 = require("assets/images/filemanager/file9.jpg");
export const file10 = require("assets/images/filemanager/file10.jpg");
export const file11 = require("assets/images/filemanager/file11.jpg");
export const file12 = require("assets/images/filemanager/file12.jpg");
export const file13 = require("assets/images/filemanager/file13.jpg");
export const file14 = require("assets/images/filemanager/file14.jpg");
export const file15 = require("assets/images/filemanager/file15.jpg");
export const file16 = require("assets/images/filemanager/file16.jpg");
export const file17 = require("assets/images/filemanager/file17.jpg");
export const file18 = require("assets/images/filemanager/file18.jpg");

// Demos

export const demo1 = require("assets/images/themes/demo1.PNG");
export const demo2 = require("assets/images/themes/demo2.png");
export const demo3 = require("assets/images/themes/demo3.png");
export const demo4 = require("assets/images/themes/demo4.png");
export const demo5 = require("assets/images/themes/demo5.png");

export const USERTYPE = {
  BUYER: "buyer",
  MERCHANT: "merchant"
};

export const SORT = {
  ASC: "ASC",
  DESC: "DESC"
};

export const TABLE = {
  LIMIT: 10
};

export const DEFAULT = {
  FIELD: "createdAt",
  SEARCH: "",
  PAGE: 1,
  SORT: SORT.DESC,
  USERTYPE: USERTYPE.BUYER,
  ADDRESS_ID: 0
};

export const ImageResolution = {
  HEIGHT: 100,
  WIDTH: 100
};

export const WEEKDAYS = [
  {
    id: 1,
    name: "Monday"
  },
  {
    id: 2,
    name: "Tuesday"
  },
  {
    id: 3,
    name: "Wednesday"
  },
  {
    id: 4,
    name: "Thursday"
  },
  {
    id: 5,
    name: "Friday"
  },
  {
    id: 6,
    name: "Saturday"
  },
  {
    id: 7,
    name: "Sunday"
  }
];
export const DAYS = {
  MONDAY: WEEKDAYS[0],
  TUESDAY: WEEKDAYS[1],
  WEDNESDAY: WEEKDAYS[2],
  THURSDAY: WEEKDAYS[3],
  FRIDAY: WEEKDAYS[4],
  SATURDAY: WEEKDAYS[5],
  SUNDAY: WEEKDAYS[6]
};

export const getDays = day_number => {
  switch (day_number) {
    case DAYS.MONDAY.id:
      return DAYS.MONDAY.name;
    case DAYS.TUESDAY.id:
      return DAYS.TUESDAY.name;
    case DAYS.WEDNESDAY.id:
      return DAYS.WEDNESDAY.name;
    case DAYS.THURSDAY.id:
      return DAYS.THURSDAY.name;
    case DAYS.FRIDAY.id:
      return DAYS.FRIDAY.name;
    case DAYS.SATURDAY.id:
      return DAYS.SATURDAY.name;
    case DAYS.SUNDAY.id:
      return DAYS.SUNDAY.name;
    default:
      return "";
  }
};

export const substractArrays = (oldArray, newArray) => {
  let array = [];

  oldArray &&
    oldArray.forEach(oldElement => {
      let flag = true;
      newArray &&
        newArray.forEach(newElement => {
          if (oldElement === newElement) {
            flag = false;
          }
        });
      if (flag) array.push(oldElement);
    });
  return array;
};

// export const format24to12 = time => {
//   const value = time.split("")[0];
//   const hh = value[0];
//   const mm = value[1];
//   const ss = value[2];

//   if (hh <= 12) {
//     if (hh === 12) {
//       if (mm > 0) {
//         return;
//       }
//     }
//   } else {
//     return `${hh}:${mm} PM`;
//   }
// };

export const ENCRYPTKEY = "itstopsecretrequest";
export const SYSTEMADMIN = 1;
export const RD = 3;
export const ACTIVE_ACCEPTING_CLIENT = 1;
export const ACTIVE_NOT_ACCEPTING_CLIENT = 2;
export const TEMPORARY_INACTIVE = 3;
export const PERMANENTALY_INACTIVE = 4;
export const USER_WEB_URL = process.env.REACT_APP_USER_WEB_URL;
export const STREAM_API_KEY = process.env.REACT_APP_STREAM_API_KEY;
export const CMS_URL = process.env.REACT_APP_CMS_URL;
export const CALENDLY_CLIENT_ID = process.env.REACT_APP_CALENDLY_CLIENT_ID;
export const CALENDLY_REDIRECT_URL =
  process.env.REACT_APP_CALENDLY_REDIRECT_URL;
export const APPOINTMENT_STATUS = {
  SCHEDULE: "active",
  PENDING: "pending",
  CANCELLED: "canceled",
  NO_SHOW: "noshow",
  COMPLETED: "completed",
  LATE_CANCELLED: "latecancel"
};
export const APPOINTMENT_STATUS_LABEL = {
  active: "Scheduled",
  pending: "Pending",
  canceled: "Cancelled",
  noshow: "No Show",
  completed: "Completed",
  latecancel: "Late Cancel"
};

export const CONTENT_LIBRARY_TYPE = {
  article: "Article",
  video: "Video",
  recipe: "Recipe"
};

export const CONTENT_LIBRARY_STATUS = {
  save_draft: "Draft",
  published: "Published",
  unpublished: "Unpublished",
  pending_review: "Pending Review"
};

export const GENDER = {
  U: "Unknown",
  M: "Male",
  F: "Female"
};

export const SERVICE_TYPE = {
  NUTRITION: 1,
  MENTAL_HEALTH: 2,
  GENERAL: 3
};

export const SERVICE_NAME = {
  1: "Nutrition",
  2: "Mental health"
};

export const PROVIDER_TYPE = {
  employee: "Employee",
  IBO: "IBO",
  ibo: "IBO",
  "ibo practice": "IBO Practice"
};

export const PROVIDER = {
  internal: "Internal",
  external: "External"
};

export const FORMS_KEYS = {
  1: {
    problem_you_are_seeking_help:
      "What are the problem(s) for which you are seeking help?",
    anxiety_attacks: "Anxiety Attacks",
    appetite_increased: "Appetite, Increased",
    appetite_decreased: "Appetite, Decreased",
    depressed_mood: "Depressed Mood",
    easily_distracted: "Easily Distracted",
    engage_in_risky_behaviors: "Engage in Risky Behaviors",
    excessive_energy: "Excessive Energy",
    excessive_worry: "Excessive Worry",
    fatigue: "Fatique",
    feeling_hopeless: "Feeling hopeless",
    feelings_of_abandonment: "Feelings of Abandonment",
    feelings_of_emptiness: "Feelings of Emptiness",
    guilt: "Guilt",
    impulsive: "Impulsive",
    unable_to_feel_pleasure: "Unable to feel pleasure",
    increased_activity: "Increased activity",
    increase_need_for_sleep: "Increased need for sleep",
    unable_to_fall_sleep: "Unable to fall asleep",
    paranoia: "Paranoia",
    preoccupied_for_sex: "Preoccupied with sex",
    racing_thoughts: "Racing thoughts",
    talkativeness: "Talkativeness",
    worthlessness: "Worthlessness",
    other_symptoms: "Other Symptoms",
    symptoms_start_from: "How often Other Symptoms Occur During the day",
    symptoms_start_type: "When did the symptom(s) start(onset)",
    how_severe_symptoms:
      "Use the scale to indicate the overall severity of symptoms",
    affect_activity_of_daily_living: "Activities of Daily Living",
    affect_finances: " Finances",
    affect_housing: "Housing",
    affect_legal_matters: "Legal Matters",
    affect_recreational_activities: "Recreational Activities",
    affect_relationships: "Relationships",
    affect_school: "School",
    affect_self_esteem: "Self Esteem",
    affect_sexual_activity: "Sexual Activity",
    affect_work: "Work",
    impact_symptoms_in_life:
      "What is the impact of these symptoms on the areas of your life selected from the previous question?"
  },

  2: {
    feeling_nervous: "Feeling nervous, anxious, or an edge",
    control_worrying: "Not being able to stop or control worrying",
    worrying_too_much: "Worrying too much about different things",
    trouble_relaxing: "Trouble relaxing",
    being_so_restless: "Being so restless that it is hard to sit still",
    annoyed_or_irritable: "Becoming easily annoyed or irritable",
    feeling_afraid: "Feeling afraid as something awful might happen",
    how_difficult_those_problems:
      "If you checked of any problems,how difficult have these problems made it for you to do your work,take care of things at home or get along with people?"
  },

  3: {
    little_interest: "Little interest or pleasure in doing things.",
    feeling_down: "Feeling down, depressed, or hopeless.",
    trouble_falling_asleep:
      "Trouble falling asleep or staying asleep, or sleeping too much",
    feeling_tired: "Feeling tired or having little energy.",
    poor_appetite: "Poor appetite or overeating.",
    feeling_bad_about_yourself:
      "Feeling bad about yourself or that you are a failure or have let yourself or your family",
    trouble_concentrating:
      "Trouble concentrating on things, such as reading the newspaper or watching",
    moving_or_speaking:
      "Moving or speaking so slowly that other people could have noticed. Or the opposite, being so fidgety or restless that you have been moving around a lot more than usual.",
    better_off_dead: "Thoughts that you would be better off",
    how_difficult_those_problems:
      "How difficult have those problems made it for you to do work , take care of things at home, or get along with other people? Select all that apply."
  },

  4: {
    problems_goals:
      "What brings you in today? Please provide a brief description of current or ongoing problems and goals you would like to address:",
    family_history:
      "Please describe any significant family history in your immediate family (cancer, heart disease, etc).",
    height: "Height",
    weight: "Weight",
    years_at_weight: "Years at current weight",
    do_you_follow_plan: "Do you currently follow a special dietary plan?",
    cook: "Who usually cooks?",
    eat_often: "How often do you eat out?",
    resturant_type: "What types of restaurants do you frequent?",
    reason_eat: "Please check all of the reasons that cause you to eat:",
    suffer_from: "Do you suffer from any of the following?",
    smoke: "Do you smoke?",
    smoke_count: "If Yes, how many times per day?",
    alcohol: "Do you consume alcohol?",
    drink_per_week: "If Yes, how many drinks do you typically have per week?",
    physical_activity_limit:
      "Do you currently have any physical activity limitations?",
    excercise: "Do you exercise regularly?",
    excercise_routine:
      "If Yes, what is your exercise routine (activity, frequency, duration)?",
    medications: "Are you taking any medications?",
    list_medications: "If Yes, please list your medications:",
    vit_supp: "Are you taking any vitamins or supplements?",
    list_vit_supp: "If yes, please list the vitamins or supplements you take:",
    allergies:
      "Do you have any allergies or sensitivities to medications or food?",
    other: "Is there anything else you would like to share?"
  }
};

export const ANS_KEYS = {
  several_days: "Several Days",
  not_at_all: "Not at All",
  more_than_half_the_Days: "More Than Half The Days",
  nearly_every_day: "Nearly Every Day",
  frequent: "Frequent",
  sometimes: "Sometimes",
  never: "Never",
  constant: "Constant",
  no: "No",
  yes: "Yes",
  moderate: "Moderate",
  severe: "Severe",
  none: "None",
  mild: "Mild",
  no_impact: "No impact",
  very_severe: "Very Severe",
  not_difficult_at_all: "Not Difficuly At all",
  somewhat_difficult: "Somewhat Difficult",
  very_difficult: "Very Difficult",
  extremely_difficult: "Extremely Difficult"
};

export const SERVICE_NAME_HEADER = {
  1: "Nutrition",
  2: "Mental Health"
};

export const CALENDLY_TYPE = {
  main_url: "Main URL",
  "initial-virtual": "Initial-Virtual",
  "initial-in-person": "Initial-InPerson"
};

export const DOC_TYPE = {
  1: "Nutrition",
  2: "Mental Health",
  3: "General"
};
export const PRIMARY_CARE_PHYSICIAN = {
  pcp: "Primary Care Physician"
};

export const STATIC_PAGE_IDS = {
  "HIPAA Consent": 3,
  "Terms & Conditions": 1,
  "Privacy Policy": 2,
  "Husk Payment Policy": 6
};

export const OTHER_PHYSICIAN = {
  other: "Other"
};

export const FAX_TYPE = {
  unread: "Unread",
  pending: "Pending",
  archived: "Archived"
};

export const FAX_STATUS = {
  UNREAD: "unread",
  PENDING: "pending",
  ARCHIVED: "archived"
};

export const ORDER_STATUS = {
  pending: "Pending",
  processing: "Processing",
  "on-hold": "On Hold",
  completed: "Completed",
  cancelled: "Canceled",
  refunded: "Refunded",
  failed: "Failed"
};
export const DEFAULT_RD_DROPDOWN = process.env.REACT_APP_DEFAULT_RD;

export const PREFER_LANG = {
  en: "English",
  es: "Español"
};

export const FORM_TYPE = {
  STANDARD: "standard"
};

export const PROVIDER_TYPES = [
  {
    name: "Employee",
    value: "employee"
  },
  {
    name: "IBO",
    value: "ibo"
  },
  {
    name: "IBO Practice Provider",
    value: "ibo practice"
  }
];

export const RD_STATUS = {
  1: "Active Accepting Client",
  2: "Active - Not Accepting Client",
  3: "Temporary Inactive",
  4: "Permanently Inactive"
};

export const USER_WEB_MH_URL = process.env.REACT_APP_USER_WEB_MH_URL;

export const ACTIVITY_TYPE = {
  SLEEP: "SLEEP"
};

export const PAID_STATUS_TYPE = {
  true: "Yes",
  false: "No"
};

export const PAYMENT_METHOD = {
  "Consumer Model": "Consumer Model",
  "Client Funded": "Client Funded",
  "Group Funded": "Group Funded",
  "Insurance Funded": "Insurance Funded",
  "Other - Free Consultation": "Other - Free Consultation"
};

export const VISIT_STATUS = {
  completed: "completed"
};

export const ADULT_LIFESTYLE_FORM_ID = process.env.REACT_APP_LIFESTYLE_FORM;

export const MHTIERS = [
  {
    id: 1,
    name: "More",
    backend_name: "more",
    status: 1
  },
  {
    id: 2,
    name: "Dashboard",
    backend_name: "dashboard",
    status: 1
  },
  {
    id: 3,
    name: "Sessions",
    backend_name: "sessions",
    status: 1
  },
  {
    id: 4,
    name: "My Feelings",
    backend_name: "my_feelings",
    status: 1
  },
  {
    id: 5,
    name: "My Thoughts",
    backend_name: "my_thougts",
    status: 1
  },
  {
    id: 6,
    name: "Resources",
    backend_name: "resources",
    status: 1
  },
  {
    id: 7,
    name: "New Patient Flow",
    backend_name: "resources",
    status: 0
  }
];

export const OUTBOX_STATUS = {
  "pending review": "Pending review",
  sent: "Sent",
  failed: "Failed",
  queued: "Queued"
};

export const MODULE_NAMES = {
  ADMIN: "Admin Management",
  STATIC_PAGE: "Static Page Management",
  INSURANCE: "Insurance Management",
  GROUP: "Group Management",
  GROUP_REG_CODES: "Group Management Registration Codes",
  GROUP_BENEFIT: "Group Management Benefit Periods",
  GROUP_VISIT_PROGRAM: "Group Management Visit Program",
  GROUP_SCALE: "Group Management Scale Program",
  GROUP_INSURANCE: "Group Management Insurance Carriers",
  CLIENT: "Client Management",
  CLIENT_TRACKING: "Client Management Tracking",
  CLIENT_VISIT_HISTORY: "Client Management Visit History",
  CLIENT_PROGRAM_DETAILS: "Client Management Program Details",
  CLIENT_INSURANCE: "Client Management Insurance Carrier",
  CLIENT_NOTES: "Client Management Notes",
  CLIENT_FORMS: "Client Management Forms",
  CLIENT_DOCUMENT: "Client Management Documents",
  MESSAGE: "Message",
  APPOINTMENT: "Appointment Management",
  VISIT_PROGRAM: "Visit Program",
  SCALE_PROGRAM: "Scale Program ",
  DIAGNOSIS: "Diagnosis Codes",
  CPT: "CPT Codes",
  SERVICE_LOC: "Service Location",
  SERVICE: "Service Management",
  CONTENT: "Content Management",
  PUSH_NOTIFICATION: "Push Notification",
  NOTIFICATION: "Notification",
  VISIT_REPORT: "Visit Details Report",
  EXTERNAL_PROVIDER: "External Provider Management",
  FAX: "Fax Management",
  FORM: "Form Management",
  TIER: "Tier Management",
  REWARDS: "Rewards Management",
  CATEGORY: "Category Management",
  PROVIDER: "Provider Management",
  PROVIDER_MANAGEMENT_SETTINGS: "Provider Management Settings",
  PROVIDER_MANAGEMENT_STATE_LICENCES: "Provider Management State Licenses",
  PROVIDER_MANAGEMENT_INSURANCE_CARRIERS:
    "Provider Management Insurance Carriers",
  PROVIDER_MANAGEMENT_PERFORMANCE_METRICS:
    "Provider Management Performance Metrics",
  PROVIDER_MANAGEMENT_CDR: "Provider Management CDR",
  PROVIDER_MANAGEMENT_CAQH: "Provider Management CAQH",
  PROVIDER_MANAGEMENT_LIABILITY_INSURANCE:
    "Provider Management Liability Insurance",
  PROVIDER_MANAGEMENT_CATEGORIES: "Provider Management Categories",
  PROVIDER_MANAGEMENT_EDIT_BUSINESS_SERVICES_AGREEMENT:
    "Provider Management Edit Business Services agreement",
  DISCOVERY_QUEUE: "Discovery Queue",
  UPLOAD_FINANCIAL: "Upload Financial",
  EFAX_INBOX: "eFax Inbox",
  EFAX_OUTBOX: "eFax Outbox",
  EFAX_ARCHIVE: "eFax Archive",
  IMPORT_USER: "Import User",
  PROVIDER_REGISTRATION_CODE: "Provider Registration Code",
  PRACTICE_MANAGEMENT: "Practice Management",
  VISIT_NOTE_TEMPLATE: "Visit Note Template",
  OUTSTANDING_CLAIM_REPORT: "Outstanding Claim Report",
  LATE_CANCEL_REPORT: "Late Cancel/No Show Report"
};

export const PERMISSION_TYPE = {
  ADD: "add",
  EDIT: "edit",
  DELETE: "delete",
  STATUS: "status",
  LOOKUP: "lookup",
  VIEW: "view"
};

export const modulePaths = {
  "Admin Management": {
    view: "/sub-admin",
    add: "/sub-admin/add",
    edit: "/sub-admin/edit/:id"
  },

  "Service Management": {
    view: "/service",
    add: "/service/add",
    edit: "/service/edit/:id"
  },

  "Static Page Management": {
    view: "/static-page",
    add: "/static-page/add",
    edit: "/static-page/edit/:id"
  },

  "State Management": {
    view: "/state",
    add: "/state/add",
    edit: "/state/edit/:id"
  },

  "Category Management": {
    view: "/category",
    add: "/category/add",
    edit: "/category/edit/:id"
  },

  "Insurance Management": {
    view: "/insurance",
    add: "/insurance/add",
    edit: "/insurance/edit/:id"
  },

  "Group Management": {
    view: "/group",
    add: "/group/add",
    edit: "/group/edit/:id"
  },

  "Client Management": {
    view: "/client",
    add: "/client/add",
    edit: "/client/edit/:id"
  },

  "Provider Management": {
    view: "/rd",
    add: "/rd/add",
    edit: "/rd/edit/:id"
  },

  Message: {
    view: "/message",
    add: "/message",
    edit: "/message"
  },

  "Appointment Management": {
    view: "/appointment",
    add: "/appointment/add",
    edit: "/appointment/edit/:id"
  },

  "Visit Program": {
    view: "/visit-program",
    add: "/visit-program/add",
    edit: "/visit-program/edit/:id"
  },

  "Scale Program ": {
    view: "/scale-program",
    add: "/scale-program/add",
    edit: "/scale-program/edit/:id"
  },

  "Diagnosis Codes": {
    view: "/diagnoses-code",
    add: "/diagnoses-code/add",
    edit: "/diagnoses-code/edit/:id"
  },

  "CPT Codes": {
    view: "/cpt-code",
    add: "/cpt-code/add",
    edit: "/cpt-code/edit/:id"
  },

  "Service Location": {
    view: "/service-location",
    add: "/service-location/add",
    edit: "/service-location/edit/:id"
  },

  "Content Management": {
    view: "/content-library",
    add: "/content-library/add",
    edit: "/content-library/edit/:id"
  },

  "Push Notification": {
    view: "/push-notification",
    add: "/push-notification/add",
    edit: "/push-notification/edit/:id"
  },

  "Roles and Permissions": {
    view: "/roles-and-permissions",
    add: null,
    edit: null
  },

  "Import User": {
    view: "/import-user",
    add: null,
    edit: null
  },
  "Visit Details Report": {
    view: "/visit-details-report",
    add: null,
    edit: null
  },
  "Bill Transaction Report": {
    view: "/bill-transaction-report",
    add: null,
    edit: null
  },
  "Client Details Report": {
    view: "/client-details-report",
    add: null,
    edit: null
  },
  "Unbillable Insurance Client Report": {
    view: "/unbillable-client-report",
    add: null,
    edit: null
  },
  "Scale Redemption Report": {
    view: "/scale-redemption-report",
    add: null,
    edit: null
  },
  "Activation Report - Nutrition": {
    view: "/activation-report-nutrition",
    add: null,
    edit: null
  },
  "Activation Report - Mental Health": {
    view: "/activation-report-mentalHealth",
    add: null,
    edit: null
  },
  "Insurance Billing Report": {
    view: "/insurance-bill-report",
    add: null,
    edit: null
  },
  "Meal Delivery Report": {
    view: "/meal-delivery-report",
    add: null,
    edit: null
  },
  "Daily Billing Failures Report": {
    view: "/daily-billing-failure-report",
    add: null,
    edit: null
  },
  "Tier Management": {
    view: "/tier",
    add: null,
    edit: null
  },
  "Pending Billing": {
    view: "/pending-billing",
    add: null,
    edit: null
  },
  "eFax Inbox": {
    view: "/efax",
    add: null,
    edit: null
  },
  "External Provider Management": {
    view: "/external-provider",
    add: null,
    edit: null
  },
  "Fax Management": {
    view: "/fax",
    add: null,
    edit: null
  },
  "Form Management": {
    view: "/form",
    add: null,
    edit: null
  },
  "Rewards Management": {
    view: "/rewards",
    add: null,
    edit: null
  },
  "Provider Unit to Goal Report": {
    view: "/rd-unit-goal-report",
    add: null,
    edit: null
  },
  "Provider Insurance Report": {
    view: "/rd-insurance-report",
    add: null,
    edit: null
  },
  "Provider Retention Report": {
    view: "/rd-retention-report",
    add: null,
    edit: null
  },
  "Provider State Report": {
    view: "/rd-state-report",
    add: null,
    edit: null
  },
  "Provider CAQH Report": {
    view: "/rd-caqh-report",
    add: null,
    edit: null
  },
  "Provider Liability Insurance Report": {
    view: "/rd-liability-insurance-report",
    add: null,
    edit: null
  },
  "Provider CDR Report": {
    view: "/rd-cdr-report",
    add: null,
    edit: null
  },
  "Discovery Queue": {
    view: "/discovery-queue",
    add: null,
    edit: null
  },
  "Financial Report": {
    view: "/financial-report",
    add: null,
    edit: null
  },
  "Upload Financial": {
    view: "/upload-financial-report",
    add: null,
    edit: null
  },
  Notification: {
    view: "/activity",
    add: null,
    edit: null
  },
  "Practice Management": {
    view: "/practice",
    add: "/practice/add",
    edit: "/practice/edit/:id"
  },
  "IBO Practice Report": {
    view: "/ibo-practice-report",
    add: null,
    edit: null
  },
  "Outstanding Claim Report": {
    view: "/outstanding-claim-report",
    add: null,
    edit: null
  },
  "Late Cancel/No Show Report": {
    view: "/late-cancel-no-show-report",
    add: null,
    edit: null
  }
};

export const PRACTICE_STATUS = {
  active: "Active",
  inactive: "Inactive",
  pending: "Pending"
};

export const ROLE_SERVICE_ID = {
  4: 1,
  5: 2
};

export const DEFAULT_GROUP = {
  nutrition: process.env.REACT_APP_NUTRITION_DEFAULT_GROUP_ID,
  mentalHealth: process.env.REACT_APP_MENTAL_HEALTH_DEFAULT_GROUP_ID
};

export const PRACTICE_TYPE = {
  Large: "large",
  Small: "small"
};

export const PRACTICE_TYPE_CAPITAL_CASE = {
  large: "Large",
  small: "Small"
};
