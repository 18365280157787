import Layout from "../layouts/DashboardLayout";
import {
  Login,
  Register,
  ForgotPassword,
  SetPassword
} from "views/pages/index";
import Otp from "../views/pages/authentication/Otp";
import MFA from "../views/pages/authentication/Mfa";

const indexRoutes = [
  { path: "/login", component: Login },
  { path: "/register", component: Register },
  { path: "/otp", component: Otp },
  { path: "/forgotPassword", component: ForgotPassword },
  { path: "/set-password/:data", component: SetPassword },
  { path: "/oauth", component: MFA },
  { path: "/", component: Layout }
];

export default indexRoutes;
