import React, { useEffect, useState } from "react";

const BmiCalculator = () => {
  const [heightFeet, setHeightFeet] = useState("");
  const [heightInches, setHeightInches] = useState("");
  const [weight, setWeight] = useState("");
  const [bmi, setBmi] = useState(null);

  useEffect(() => {
    if ((heightFeet || heightFeet === "0") && weight) {
      calculateBmi();
    } else {
      setBmi(null);
    }
  }, [heightFeet, heightInches, weight]);

  const handleChange = (e, setter, isInches = false, max = Infinity) => {
    const value = e.target.value;

    const regex = /^\d*$/;

    if (value === "" || regex.test(value)) {
      const numValue = parseInt(value, 10);

      if (
        value === "" ||
        (!isNaN(numValue) &&
          isFinite(numValue) &&
          (isInches ? numValue >= 0 : numValue > 0) &&
          numValue <= max)
      ) {
        setter(value);
      }
    }
  };

  const calculateBmi = () => {
    const feet = parseInt(heightFeet, 10) || 0;
    const inches = parseInt(heightInches, 10) || 0;
    const weightLbs = parseInt(weight, 10) || 0;

    if (feet > 0 && weightLbs > 0) {
      const heightInMeters = (feet * 12 + inches) * 0.0254;
      const weightInKg = weightLbs * 0.453592;
      const bmiValue = (weightInKg / (heightInMeters * heightInMeters)).toFixed(
        2
      );
      setBmi(bmiValue);
    } else {
      setBmi(null);
    }
  };

  return (
    <div className="pleft-16 fs-14">
      <div className="flex align-items-baseline max-w-350 ptop-10">
        <span className="pright-5">Height:</span>
        <input
          type="text"
          className="form-control react-form-input mleft-8 max-h-26"
          value={heightFeet}
          onChange={e => handleChange(e, setHeightFeet, false, 8)}
          pattern="\d*"
          inputMode="numeric"
        />
        <span className="pleft-5 pright-5">Feet</span>
        <input
          type="text"
          className="form-control react-form-input mright-7 max-h-26"
          value={heightInches}
          onChange={e => handleChange(e, setHeightInches, true, 11)}
          pattern="\d*"
          inputMode="numeric"
        />
        <span>Inches</span>
      </div>
      <div className="flex align-items-baseline ptop-6 max-w-333">
        <span className="pright-1">Weight:</span>
        <input
          type="text"
          className="form-control react-form-input mleft-7 mright-7 max-h-26"
          value={weight}
          onChange={e => handleChange(e, setWeight, false, 1000)}
          pattern="\d*"
          inputMode="numeric"
        />
        <span>Lbs.</span>
      </div>
      <div className="ptop-5 fs-14 medium-text">
        <span className="pright-30">BMI:</span>
        <span className="fs-16 demi-bold-text pleft-3">{bmi}</span>
      </div>
    </div>
  );
};

export default BmiCalculator;
