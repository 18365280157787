import React, { useEffect, useState } from "react";
import UserWrapper from "./viewClient.style";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";
import ClientServices from "../../api/ClientServices";
import GroupServices from "../../api/GroupServices";
import RdServices from "../../api/RdServices";
import ClientAddForm from "../../components/viewclient/ClientAddForm";
import EncryptDecrypt from "../../api/EncryptDecrypt";

const ClientAdd = props => {
  const [category, setCategory] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [insuranceList, setInsuranceList] = useState([]);
  const [rdList, setRdList] = useState([]);
  const [serviceId, setServiceId] = useState(null);

  const hasParam = props.match.params.id;

  useEffect(() => {
    const params = {
      service_id: serviceId
    };
    RdServices.listCategory().then(res => {
      let data = EncryptDecrypt.decrypt(res.data);
      setCategory(data.data);
    });
    ClientServices.listGroup(params).then(res => {
      let data = EncryptDecrypt.decrypt(res.data);
      setGroupList(data.data);
    });
    GroupServices.insuranceList().then(res => {
      let data = EncryptDecrypt.decrypt(res.data);
      setInsuranceList(data.data);
    });
    const params1 = {
      type: "assign"
    };
    ClientServices.RDList(params1).then(result => {
      let data = EncryptDecrypt.decrypt(result.data);
      setRdList(data.data);
    });
  }, [serviceId]);

  const submitFormHandler = (data, action) => {
    setTimeout(() => {
      props.history.push("/client");
    }, 2000);
  };

  const handleServiceIdChange = id => {
    setServiceId(id);
  };

  return (
    <UserWrapper {...props}>
      <div className="pos-relative">
        <PageTitle
          props={props}
          title="sidebar.client_management"
          className="plr-15"
          breadCrumb={[
            {
              name: "sidebar.dashboard",
              url: "/dashboard"
            },
            {
              name: "sidebar.client_management",
              url: "/client"
            },
            {
              name: hasParam ? "action.edit" : "action.add"
            }
          ]}
        />
        <div
          className="back-icon fs-15 demi-bold-text cursor-pointer"
          onClick={() => props.history.push("/client")}
        >
          <i className="fas fa-step-backward"></i> Back
        </div>
      </div>

      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> {hasParam ? "Edit" : "Add"}{" "}
              Client
            </div>
          </div>
          <div className="roe-card-body">
            <ClientAddForm
              onSubmit={submitFormHandler}
              category={category || []}
              groupList={groupList || []}
              insuranceList={insuranceList || []}
              rdList={rdList || []}
              action={hasParam ? "edit" : "add"}
              onServiceIdChange={handleServiceIdChange}
              {...props}
            />
          </div>
        </div>
      </div>
    </UserWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    authData: {
      token: state.auth.token,
      isLogin: state.auth.isLogin,
      profileImage: state.auth.profile_image,
      adminRoleId: state.auth.admin_role_id,
      adminRole: state.auth.admin_role,
      full_name: state.auth.first_name + " " + state.auth.last_name,
      adminId: state.auth.id,
      service_id: state.auth.service_id,
      adminType: state.auth.adminType,
      practice: state.auth.practice
    }
  };
};

export default connect(mapStateToProps, null)(ClientAdd);
