import React, { useRef, useEffect } from "react";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import RdServices from "../../api/RdServices";
import Button from "../button/Button";
import Toaster from "../common/Toaster";
import enhancer from "./state-validator";
import DatePicker from "react-datepicker";
import RDWrapper from "./rd.style";
import { compose } from "redux";

const StateForm = props => {
  // const hasParam = props.match.params.id;

  const {
    setFieldValue,
    onSubmit,
    values,
    action,
    stateData,
    stateList
  } = props;

  const toaster = useRef();

  const [selectedInput, setSelectedInput] = React.useState([]);

  useEffect(() => {
    if (stateData) {
      let stateData1 = stateData.map(value => value.state_id);
      setSelectedInput(stateData1);
    }
    setFieldValue("state", stateData);
  }, [stateData]);

  const checkHandleChange = async e => {
    const targtValue = parseInt(e.currentTarget.value);
    setSelectedInput(prev =>
      prev.some(data => data === targtValue)
        ? prev.filter(data => data !== targtValue)
        : [...prev, targtValue]
    );

    let objIndex = values.state.findIndex(obj => obj.state_id === targtValue);
    if (objIndex > -1) {
      values.state.splice(objIndex, 1);
      setFieldValue("state", values.state);
    } else {
      values.state.push({
        state_id: targtValue,
        valid_from: new Date(),
        valid_to: new Date()
      });
    }
  };

  const handlerValidFromDate = (e, id) => {
    let objIndex = values.state.findIndex(obj => obj.state_id === id);
    values.state[objIndex].valid_from = new Date(e);
    setFieldValue("state", values.state);
  };

  const handlerValidToDate = (e, id) => {
    let objIndex = values.state.findIndex(obj => obj.state_id === id);
    values.state[objIndex].valid_to = new Date(e);
    setFieldValue("state", values.state);
  };

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;
    if (isValid) {
      // onSubmit(values, action);
      const params = {
        admin_id: props.adminId,
        state: values.state
      };
      RdServices.editState(params)
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          if (data.status) {
            if (toaster.current) toaster.current.success(data.message);
          } else {
            if (toaster.current) toaster.current.error(data.message);
          }
        })
        .catch(err => {
          if (toaster.current) toaster.current.error(err.response);
        });
    }
    if (toaster.current && props.errors.state !== undefined) {
      toaster.current.error("Please enter valid date");
    }
    handleSubmit();
  };

  return (
    <RDWrapper {...props}>
      <form>
        <div className="box-height form-group col-md-12">
          {stateList &&
            stateList.map((type, i) => {
              return (
                <div key={i} className="mt-2">
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td style={{ width: "25%" }}>
                          <input
                            type="checkbox"
                            name={`state_id${type.id}`}
                            onChange={checkHandleChange}
                            value={type.id}
                            checked={
                              selectedInput && selectedInput.includes(type.id)
                                ? "checked"
                                : false
                            }
                            className="checkbox"
                          />

                          {" " + type.name}
                        </td>
                        <td
                          className={
                            selectedInput && selectedInput.includes(type.id)
                              ? ""
                              : "d-none"
                          }
                        >
                          <label className="fs-16 medium-text pr-2">
                            Valid From
                          </label>
                          <DatePicker
                            className="form-control"
                            selected={
                              values.state &&
                              values.state[
                                values.state.findIndex(
                                  obj => obj.state_id === type.id
                                )
                              ] &&
                              values.state[
                                values.state.findIndex(
                                  obj => obj.state_id === type.id
                                )
                              ].valid_from &&
                              new Date(
                                values.state[
                                  values.state.findIndex(
                                    obj => obj.state_id === type.id
                                  )
                                ].valid_from
                              )
                            }
                            id={`valid_from${type.id}`}
                            data-id={type.id}
                            onChange={e => {
                              handlerValidFromDate(e, type.id);
                            }}
                            dateFormat="MM/dd/yyyy"
                          />
                        </td>
                        <td
                          className={
                            selectedInput && selectedInput.includes(type.id)
                              ? ""
                              : "d-none"
                          }
                        >
                          <label className="fs-16 medium-text pr-2">
                            Valid To
                          </label>
                          <DatePicker
                            className="form-control"
                            selected={
                              values.state &&
                              values.state[
                                values.state.findIndex(
                                  obj => obj.state_id === type.id
                                )
                              ] &&
                              values.state[
                                values.state.findIndex(
                                  obj => obj.state_id === type.id
                                )
                              ].valid_to &&
                              new Date(
                                values.state[
                                  values.state.findIndex(
                                    obj => obj.state_id === type.id
                                  )
                                ].valid_to
                              )
                            }
                            id={`valid_to${type.id}`}
                            onChange={e => {
                              handlerValidToDate(e, type.id);
                            }}
                            dateFormat="MM/dd/yyyy"
                            placeholder="Valid To Date"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              );
            })}
        </div>
        <div>
          <Button
            type="button"
            className="c-btn c-info form-button fs-16 demi-bold-text"
            style={{ maxWidth: "125px" }}
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </div>
      </form>
      <Toaster ref={toaster} />
    </RDWrapper>
  );
};

export default compose(enhancer)(StateForm);
