import axios from "axios";
import { baseUrl } from "../helper/constant";
import Header from "./Header";
import EncryptDecrypt from "./EncryptDecrypt";

export default class RegisteredDietitian {
  static completeProfile(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/rd/complete-profile`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static stateList() {
    Header.setHeaders();
    return axios.get(`${baseUrl}/rd/list-state`);
  }
  static completeStateLicence(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/rd/complete-state-license`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static insuranceList(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/group/list-insurance`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static completeInsurance(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/rd/complete-insurance`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static viewRDProfile(params) {
    Header.setHeaders();
    return axios.get(`${baseUrl}/rd/view/?id=${params}`);
  }
  static editProfile(params) {
    Header.setHeaders();
    return axios.put(`${baseUrl}/rd/edit`, EncryptDecrypt.encrypt(params));
  }
  static groupInsuranceList(params) {
    Header.setHeaders();
    return axios.get(`${baseUrl}/rd/list-rd-insurance?id=${params}`);
  }
  static rdStateList(params) {
    Header.setHeaders();
    return axios.get(`${baseUrl}/rd/list-rd-state?id=${params}`);
  }

  static timezoneList(params) {
    Header.setHeaders();
    return axios.get(`${baseUrl}/rd/list-timezone`);
  }

  static languageList(params) {
    Header.setHeaders();
    return axios.get(`${baseUrl}/rd/list-language`);
  }
}
