import React from "react";
import moment from "moment-timezone";
import { APPOINTMENT_STATUS_LABEL } from "helper/constant";

const AppointmentScheduleDetail = props => {
  const appointmentDetails = props.location.state;

  return (
    <div className="plr-15">
      <div className="roe-card-style mtb-15">
        <div className="roe-card-header module-header">
          <div className="flex-1 fs-16 demi-bold-text">
            <span className="hash"># </span> View Appointment
          </div>
        </div>
        <div className="roe-card-body">
          <div className="pt-2">
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>
              Client Name
            </div>
            <div>
              {appointmentDetails.user.first_name}{" "}
              {appointmentDetails.user.last_name}
            </div>
          </div>
          <div className="pt-2">
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>
              Client Email
            </div>
            <div>{appointmentDetails.user.email}</div>
          </div>
          <div className="pt-2">
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>
              Provider
            </div>
            <div>
              {appointmentDetails.admin.first_name +
                " " +
                appointmentDetails.admin.last_name}
            </div>
          </div>
          <div className="pt-2">
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>
              Service
            </div>
            <div>
              {appointmentDetails?.service?.name
                ? appointmentDetails?.service?.name
                : ""}
            </div>
          </div>
          <div className="pt-2">
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>
              Meeting
            </div>
            <div>
              {appointmentDetails.event_detail &&
                appointmentDetails.event_detail.name}
            </div>
          </div>
          <div className="pt-2">
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>
              Appointment Date
            </div>
            <div>
              {appointmentDetails.appointment_type === "Manual"
                ? moment
                    .tz(
                      moment(
                        appointmentDetails.appointment_date +
                          " " +
                          appointmentDetails.appointment_time
                      ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                      moment.tz.guess()
                    )
                    .format("MM/DD/YYYY")
                : moment
                    .tz(
                      appointmentDetails.event_detail.start_time,
                      moment.tz.guess()
                    )
                    .format("MM/DD/YYYY")}
            </div>
          </div>
          <div className="pt-2">
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>
              Appointment Time
            </div>
            <div>
              {appointmentDetails.appointment_type === "Manual"
                ? moment
                    .tz(
                      moment(
                        appointmentDetails.appointment_date +
                          " " +
                          appointmentDetails.appointment_time
                      ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                      moment.tz.guess()
                    )
                    .format("h:mm A")
                : moment
                    .tz(
                      appointmentDetails.event_detail.start_time,
                      moment.tz.guess()
                    )
                    .format("h:mm A")}
            </div>
          </div>
          <div className="pt-2">
            <div>
              <div style={{ fontWeight: "bolder", textAlign: "left" }}>
                Status
              </div>
              <div>{APPOINTMENT_STATUS_LABEL[appointmentDetails.status]}</div>
            </div>
          </div>
          <div className="pt-2">
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>
              Join URL
            </div>
            <div>
              {appointmentDetails.location &&
              appointmentDetails.location.join_url ? (
                <a href={appointmentDetails.location.join_url}>
                  {appointmentDetails.location.join_url}
                </a>
              ) : (
                "-"
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentScheduleDetail;
