import auth from "./auth/reducer";
import themeChanger from "./themeChanger/reducer";
import LanguageSwitcher from "./languageSwitcher/reducer";
import themeSetting from "./themeSettings/reducer";
import scrumboard from "./scrumboard/reducer";
import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import fileUpload from "./fileUpload/reducer";
import amdStatus from "./amdStatus/reducer";
import permissionReducer from "./permissions/reducer";
import clientFiltersReducer from "./clientFilters/reducer";

// const createReducer = asyncReducers =>
//   combineReducers({
//     auth,
//     themeChanger,
//     LanguageSwitcher,
//     themeSetting,
//     scrumboard,
//     fileUpload,
//     router: routerReducer,
//     ...asyncReducers
//   });

export default {
  auth,
  themeChanger,
  LanguageSwitcher,
  themeSetting,
  scrumboard,
  fileUpload,
  amdStatus,
  permissionReducer,
  clientFiltersReducer
};
