import React, { useEffect, useRef, useState } from "react";
import { compose } from "redux";
import enhancer from "./add-referral-validator";
import Button from "../button/Button";
import Toaster from "../common/Toaster";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  InputGroup,
  Input
} from "reactstrap";
import CategoryServices from "api/CategoryServices";
import CareTeamServices from "api/CareTeamServices";
import ReferralsServices from "api/ReferralsService";

const AddReferralModal = props => {
  const [dropdownOpenType, setDropdownOpenType] = useState(false);
  const [dropdownOpenProvider, setDropdownOpenProvider] = useState(false);
  const [
    dropdownOpenInternalProvider,
    setDropdownOpenInternalProvider
  ] = useState(false);
  const [dropdownOpenService, setDropdownOpenService] = useState(false);
  const [rdList, setRdList] = useState([]);
  const [internalRdList, setInternalRdList] = useState([]);
  const [RD, setRD] = useState();
  const [internalRD, setInternalRD] = useState(null);
  const [service, setService] = useState(null);
  const [serviceList, setServiceList] = useState([]);
  const [typeText, setTypeText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputRdValue, setInputRdValue] = useState("");
  const toggleType = () => setDropdownOpenType(prevState => !prevState);

  const toggleProvider = () => setDropdownOpenProvider(prevState => !prevState);
  const toggleInternalProvider = () =>
    setDropdownOpenInternalProvider(prevState => !prevState);

  const toggleService = () => setDropdownOpenService(prevState => !prevState);
  const toaster = useRef();
  const hasParam = props.match.params.id;
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    setFieldValue,
    values
  } = props;

  useEffect(() => {
    setRD(null);
    setFieldValue("rd", null);
  }, [typeText]);

  useEffect(() => {
    setInternalRD(null);
    setFieldValue("internal_rd", null);
  }, [service]);

  useEffect(() => {
    const params = {
      type: values?.type
    };
    ReferralsServices.listExternalInternalProvider(params).then(result => {
      let data = EncryptDecrypt.decrypt(result.data);
      setRdList(data?.data);
    });
  }, [values?.type]);

  useEffect(() => {
    const params = {
      type: "internal",
      service_id: values?.service
    };

    ReferralsServices.listExternalInternalProvider(params).then(result => {
      let data = EncryptDecrypt.decrypt(result.data);
      setInternalRdList(data.data);
    });
  }, [values?.service]);

  useEffect(() => {
    if (props.auth.admin_role_id === 3) {
      const result = {
        id: props.auth.id,
        last_name: props.auth.last_name,
        first_name: props.auth.first_name
      };
      setRD(result);
      setFieldValue("rd", result);
    }
  }, [values?.type]);

  useEffect(() => {
    const params = {
      user_id: hasParam,
      referring_service: props.auth.admin_role_id === 1 ? false : true
    };
    CategoryServices.serviceDropdown(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setServiceList([...data.data]);
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      });
  }, []);

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const handleAddReferral = () => {
    let { isValid, handleSubmit, values } = props;
    if (isValid) {
      setIsLoading(true);
      let params = {
        user_id: hasParam,
        type: values?.type,
        admin_id: values?.rd?.id,
        service_id: values?.service,
        referral_note: values?.referral_note,
        referred_to: values?.internal_rd
      };

      CareTeamServices.addReferral(params)
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);

          if (data.status) {
            if (toaster.current) toaster.current.success(data.message);
            setIsLoading(false);
            setTimeout(() => {
              props.toggle();
            }, 1000);
            props.pageRefresh();
          } else {
            if (toaster.current) toaster.current.error(data.message);
            setIsLoading(false);
            setTimeout(() => {
              props.toggle();
            }, 1000);
          }
        })
        .catch(err => {
          if (toaster.current) toaster.current.error(err.response);
          props.toggle();
        });
    }
    handleSubmit();
  };
  return (
    <form className="pa-20">
      <div className="form-group flex-x ">
        <div className="pr-4">
          <div>
            <span className="fs-18">Type:</span>
            <span className="asterisk">*</span>
            <Dropdown isOpen={dropdownOpenType} toggle={toggleType}>
              <DropdownToggle caret size="md" style={{ width: "200px" }}>
                {typeText ? typeText : "Select type"}
              </DropdownToggle>
              <DropdownMenu style={{ width: "100%", overflow: "scroll" }}>
                <DropdownItem
                  key="Internal"
                  onClick={() => {
                    setTypeText("Internal");
                    setFieldValue("type", "internal");
                  }}
                >
                  Internal
                </DropdownItem>
                <DropdownItem
                  key="External"
                  onClick={() => {
                    setTypeText("External");
                    setFieldValue("type", "external");
                  }}
                >
                  External
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <Error field="type" />
          </div>
          <div className="pt-2">
            <span className="fs-18">Referred By:</span>
            <span className="asterisk">*</span>

            <Dropdown
              isOpen={dropdownOpenProvider}
              toggle={toggleProvider}
              style={{ width: "200px" }}
              disabled={!typeText}
            >
              <DropdownToggle
                disabled={props.auth.admin_role_id === 2}
                caret
                size="md"
                style={{ width: "200px" }}
              >
                {RD ? RD.first_name + " " + RD.last_name : "Select Provider"}
              </DropdownToggle>

              <DropdownMenu
                style={{ width: "100%", height: "20vh", overflow: "scroll" }}
              >
                <div style={{ position: "static", overflow: "hidden" }}>
                  <InputGroup style={{ padding: "10px" }}>
                    <Input
                      placeholder="Type to search..."
                      value={inputValue}
                      onChange={e => setInputValue(e.target.value)}
                    />
                  </InputGroup>
                </div>

                {rdList
                  .filter(item =>
                    (item.first_name + " " + item.last_name)
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  )
                  .map((result, i) => {
                    return (
                      <DropdownItem
                        key={i}
                        onClick={() => {
                          setRD(result);
                          setFieldValue("rd", result);
                        }}
                      >
                        {result?.first_name + " " + result?.last_name}
                      </DropdownItem>
                    );
                  })}
              </DropdownMenu>
            </Dropdown>

            <Error field="rd" />
          </div>
          <div className="pt-2">
            <span className="fs-18">Referred To Service:</span>
            <span className="asterisk">*</span>
            <Dropdown isOpen={dropdownOpenService} toggle={toggleService}>
              <DropdownToggle caret size="md" style={{ width: "200px" }}>
                {service ? service.name : "Select Service"}
              </DropdownToggle>
              <DropdownMenu style={{ width: "100%", overflow: "scroll" }}>
                {serviceList.map((result, i) => {
                  return (
                    <DropdownItem
                      key={i}
                      onClick={() => {
                        setService(result);
                        setFieldValue("service", result.id);
                      }}
                    >
                      {result?.name}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
            <Error field="service" />
          </div>
          <div className="pt-2">
            <span className="fs-18">Referred To Provider:</span>

            <Dropdown
              isOpen={dropdownOpenInternalProvider}
              toggle={toggleInternalProvider}
              style={{ width: "200px" }}
              disabled={!service}
            >
              <DropdownToggle
                disabled={props.auth.admin_role_id === 2}
                caret
                size="md"
                style={{ width: "200px" }}
              >
                {internalRD
                  ? internalRD.first_name + " " + internalRD.last_name
                  : "Select Provider"}
              </DropdownToggle>

              <DropdownMenu
                style={{ width: "100%", height: "20vh", overflow: "scroll" }}
              >
                <div style={{ position: "static", overflow: "hidden" }}>
                  <InputGroup style={{ padding: "10px" }}>
                    <Input
                      placeholder="Type to search..."
                      value={inputRdValue}
                      onChange={e => setInputRdValue(e.target.value)}
                    />
                  </InputGroup>
                </div>
                {internalRdList
                  ?.filter(item =>
                    (item.first_name + " " + item.last_name)
                      .toLowerCase()
                      .includes(inputRdValue.toLowerCase())
                  )
                  .map((result, i) => {
                    return (
                      <DropdownItem
                        key={i}
                        onClick={() => {
                          setInternalRD(result);
                          setFieldValue("internal_rd", result.id);
                        }}
                      >
                        {result.first_name + " " + result.last_name}
                      </DropdownItem>
                    );
                  })}
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
        <div>
          <label className="fs-18">Referral Notes:</label>
          <textarea
            className="form-control react-form-input"
            rows="11"
            onChange={handleChange}
            value={values?.referral_note}
            onBlur={handleBlur}
            placeholder="Referral note"
            id="referral_note"
            name="referral_note"
          ></textarea>
          <Error field="referral_note" />
        </div>
      </div>

      <div className="flex-x center pt-4">
        <Button
          type="button"
          className="c-btn c-secondary form-button fs-16 demi-bold-text mr-10"
          style={{ maxWidth: "125px" }}
          onClick={props.toggle}
        >
          Cancel
        </Button>
        <Button
          type="button"
          className="c-btn c-info form-button fs-16 demi-bold-text"
          style={{ maxWidth: "125px" }}
          onClick={() => handleAddReferral()}
          loading={isLoading}
          disabled={isLoading}
          dataStyle={"expand-right"}
        >
          {isLoading ? <p>Saving...</p> : <p>Save</p>}
        </Button>
      </div>
      <Toaster ref={toaster} />
    </form>
  );
};

export default compose(enhancer)(AddReferralModal);
