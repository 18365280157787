import React from "react";
import { compose } from "redux";
import enhancer from "../userNote/validator";
import { Button } from "reactstrap";
import ClientServices from "api/ClientServices";

const AddNote = props => {
  const hasParam = props.match.params.id;
  const {
    handleChange,
    handleBlur,
    submitCount,
    errors,
    values,
    touched,
    editedData
  } = props;

  const handleNote = () => {
    let { values, isValid, handleSubmit } = props;
    if (isValid) {
      if (props.editedData.type == "add") {
        let noteObj = {
          user_id: hasParam,
          note: values.note
        };
        ClientServices.addNote(noteObj).then(result => {
          props.toggle();
          props.pageRefresh();
        });
      } else {
        let noteObj = {
          id: values.id,
          user_id: hasParam,
          note: values.note
        };
        ClientServices.editNote(noteObj).then(result => {
          props.toggle();
          props.pageRefresh();
        });
      }
    }

    handleSubmit();
  };
  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <div className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </div>
      );
    } else {
      return <span />;
    }
  };

  return (
    <div>
      <form className="pa-24 row">
        <div className="form-group col-md-12">
          <label className="fs-16 medium-text">Note:</label>
          <textarea
            className="form-control react-form-input"
            rows="3"
            placeholder="Add Note"
            id="note"
            name="note"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.note}
          ></textarea>
          <Error field="note" />
        </div>
        <div className="col-12 text-center">
          <Button
            type="button"
            // loading={loading}
            className="c-btn c-info form-button fs-16 demi-bold-text mr-10"
            style={{ maxWidth: "200px" }}
            onClick={() => {
              handleNote();
            }}
          >
            {props.editedData.type == "edit" ? "Edit" : "Add"} Note
          </Button>
          <Button
            type="button"
            className="c-btn c-secondary form-button fs-16 demi-bold-text"
            style={{ maxWidth: "200px" }}
            onClick={props.toggle}
          >
            Close
          </Button>
        </div>
      </form>
    </div>
  );
};
export default compose(enhancer)(AddNote);
