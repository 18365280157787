const permissionsActions = {
  SET_PERMISSIONS: "SET_PERMISSIONS",

  setPermissions: data => {
    return {
      type: permissionsActions.SET_PERMISSIONS,
      permissions: data
    };
  }
};

export default permissionsActions;
