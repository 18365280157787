import axios from "axios";
import { baseUrl } from "../helper/constant";
import Header from "./Header";
import EncryptDecrypt from "./EncryptDecrypt";

export default class UserServices {
  static visitList(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/visit/list`, EncryptDecrypt.encrypt(params));
  }
  static editNote(params) {
    Header.setHeaders();
    return axios.put(`${baseUrl}/visit/edit`, EncryptDecrypt.encrypt(params));
  }
  static addNote(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/visit/add-note`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static addVisit(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/visit/add`, EncryptDecrypt.encrypt(params));
  }
  static editVisit(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/visit/edit`, EncryptDecrypt.encrypt(params));
  }
  static visitNoteList(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/visit/note-list`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static visitNoteView(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/visit/note-view`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static visitNoteDefaultNote() {
    Header.setHeaders();
    return axios.get(`${baseUrl}/visit/default-note`);
  }
  static exportPatientReport(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/visit/export-patient-details`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static eSignNote(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/visit/sign-note`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static updateStatus(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/visit/paid`, EncryptDecrypt.encrypt(params));
  }

  static visitNoteTemplateDropdownList() {
    Header.setHeaders();
    return axios.get(`${baseUrl}/visit-note-template/titles`);
  }

  static getFinancialStatus(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/visit/get-claim-detail`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static getUpdatedFinancialStatus(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/visit/generate-claim-details`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }
}
