import React, { useEffect, useRef, useState } from "react";
import { compose } from "redux";
import enhancer from "./validator";
import Button from "../button/Button";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
import CategoryServices from "api/CategoryServices";
import EncryptDecrypt from "api/EncryptDecrypt";
import EfaxManagementServices from "api/EfaxMangementServices";

const EditEfaxModal = props => {
  const [serviceDropdownOpen, setServiceDropdownOpen] = useState(false);
  const [serviceList, setServiceList] = useState([]);
  const [serviceName, setServiceName] = useState("Nutrition");
  const [loading, setLoading] = useState(false);
  const toggleService = () => setServiceDropdownOpen(prevState => !prevState);
  const toaster = useRef();

  const {
    handleChange,
    handleBlur,
    submitCount,
    errors,
    values,
    touched,
    editedData,
    setFieldValue
  } = props;

  useEffect(() => {
    setFieldValue(true, true);
  }, []);

  useEffect(() => {
    CategoryServices.serviceDropdown()
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setServiceList([...data.data]);
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      });
    setServiceName(props?.editedData?.service?.name);
  }, []);

  const handleEdit = () => {
    let { values, isValid, handleSubmit } = props;
    if (isValid) {
      setLoading(true);
      let params = {
        fax_id: editedData.id,
        service_id: values.service,
        notes: values.notes
      };
      EfaxManagementServices.edit(params)
        .then(result => {
          const res = EncryptDecrypt.decrypt(result.data);
          if (res.status) {
            props.callUpdateStatusAPiHandler();

            setLoading(false);
            props.toggle();
            props.pageRefresh();
          } else {
            setLoading(false);
          }
        })
        .catch(err => {
          setLoading(false);
        });
    }

    handleSubmit();
  };
  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <div className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </div>
      );
    } else {
      return <span />;
    }
  };

  const handleServiceFilter = (result, i) => {
    setServiceName(`${result.name}`);
  };

  return (
    <div>
      <form className="pa-24 row">
        <div className="form-group col-md-12">
          <label className="fs-16 medium-text">Category:</label>
          <div className="fs-14 mlr-5 ">
            <Dropdown isOpen={serviceDropdownOpen} toggle={toggleService}>
              <DropdownToggle caret size="md">
                {serviceName ? serviceName : "Select Service"}
              </DropdownToggle>
              <DropdownMenu
                modifiers={{
                  setMaxHeight: {
                    enabled: true,
                    order: 890,
                    fn: data => {
                      return {
                        ...data,
                        styles: {
                          ...data.styles,
                          overflow: "auto",
                          maxHeight: "300px"
                        }
                      };
                    }
                  }
                }}
              >
                {serviceList.length > 0 &&
                  serviceList.map((result, i) => {
                    return (
                      <DropdownItem
                        key={i}
                        onClick={() => {
                          handleServiceFilter(result, i);
                          setFieldValue("service", result.id);
                        }}
                      >
                        {result?.name}
                      </DropdownItem>
                    );
                  })}
              </DropdownMenu>
            </Dropdown>
            <Error field="service" />
          </div>
        </div>
        <div className="form-group col-md-12">
          <label className="fs-16 medium-text">Note:</label>
          <textarea
            className="form-control react-form-input"
            rows="3"
            placeholder="Add Note"
            id="notes"
            name="notes"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.notes}
          ></textarea>
          <Error field="notes" />
        </div>
        <div className="col-12 text-center">
          <Button
            type="button"
            className="c-btn c-secondary form-button fs-16 demi-bold-text mr-10"
            style={{ maxWidth: "200px" }}
            onClick={props.toggle}
          >
            Cancel
          </Button>
          <Button
            type="button"
            loading={loading}
            disabled={loading}
            className="c-btn c-info form-button fs-16 demi-bold-text "
            style={{ maxWidth: "200px" }}
            onClick={() => {
              handleEdit();
            }}
            dataStyle="expand-right"
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};
export default compose(enhancer)(EditEfaxModal);
