import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import RdServices from "../../api/RdServices";
import Loader from "../common/Loader";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import {
  FOODIMAGE,
  CLIENTIMAGE,
  MESSAGEIMAGE,
  GOALIMAGE,
  DOC_TYPE
} from "helper/constant";
import moment from "moment";
import { CMS_URL } from "../../helper/constant";

const ActivityTable = ({ service_id = null, ...props }) => {
  const [tblData, settblData] = useState();
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("created_at");
  const [sortBy, setSortBy] = useState("DESC");

  const toaster = useRef();

  useEffect(() => {
    getList(field, sortBy, searchText, activePage + 1);
    setPages(10);
  }, []);

  const columns = [
    {
      Header: "Notification",
      accessor: "",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      Cell: props => {
        if (props.original.activity_type === 1) {
          return (
            <>
              <img src={GOALIMAGE} width="30"></img>
              <span className="pl-2">
                {props.original.user.first_name +
                  " " +
                  props.original.user.last_name +
                  " has completed "}{" "}
                {props.original.isCurrentClient ? (
                  <a
                    href={
                      CMS_URL +
                      "/client/view/" +
                      props.original.user_id +
                      `${
                        service_id && service_id === 2 ? "?feelings" : "?goal"
                      }`
                    }
                  >
                    goal
                  </a>
                ) : (
                  <span>goal</span>
                )}
              </span>
            </>
          );
        } else if (props.original.activity_type === 3) {
          return (
            <>
              <img src={FOODIMAGE} width="30"></img>
              <span className="pl-2">
                {props.original.user.first_name +
                  " " +
                  props.original.user.last_name +
                  " tracked a "}{" "}
                {props.original.isCurrentClient ? (
                  <a
                    href={
                      CMS_URL +
                      "/client/view/" +
                      props.original.user_id +
                      "?meal"
                    }
                  >
                    new meal
                  </a>
                ) : (
                  <span>new meal</span>
                )}
              </span>
            </>
          );
        } else if (props.original.activity_type === 4) {
          return (
            <>
              <img src={GOALIMAGE} width="30"></img>
              <span className="pl-2">
                {props.original.user.first_name +
                  " " +
                  props.original.user.last_name +
                  " created a "}{" "}
                {props.original.isCurrentClient ? (
                  <a
                    href={
                      CMS_URL +
                      "/client/view/" +
                      props.original.user_id +
                      "?goal"
                    }
                  >
                    new goal
                  </a>
                ) : (
                  <span>new goal</span>
                )}
              </span>
            </>
          );
        } else if (props.original.activity_type === 5) {
          return (
            <>
              <img src={CLIENTIMAGE} width="30"></img>
              <span className="pl-2">
                {"You were assigned a new client, "}
                {props.original.isCurrentClient ? (
                  <a href={CMS_URL + "/client/view/" + props.original.user_id}>
                    {props.original.user.first_name}{" "}
                    {props.original.user.last_name}
                  </a>
                ) : (
                  <span>
                    {props.original.user.first_name}{" "}
                    {props.original.user.last_name}
                  </span>
                )}
              </span>
            </>
          );
        } else if (props.original.activity_type === 9) {
          return (
            <tr>
              <td>
                <i class="fas fa-file-alt" width="30"></i>
              </td>
              <td className="p-2">
                New {DOC_TYPE[props.original?.user_document?.service_id]}{" "}
                document uploaded for,{" "}
                {props.original.isCurrentClient ? (
                  <a
                    href={
                      CMS_URL +
                      "/client/view/" +
                      props.original.user_id +
                      "?document"
                    }
                  >
                    {props.original.user.first_name +
                      " " +
                      props.original.user.last_name}
                  </a>
                ) : (
                  <span>
                    {props.original.user.first_name +
                      " " +
                      props.original.user.last_name}
                  </span>
                )}
              </td>
            </tr>
          );
        } else if (props.original.activity_type === 6) {
          return (
            <>
              <i className="far fa-lightbulb fa-2x"></i>
              <span className="pl-2">
                {" "}
                {props.original.user.first_name +
                  " " +
                  props.original.user.last_name}{" "}
                created a{" "}
                {props.original.isCurrentClient ? (
                  <a
                    href={
                      CMS_URL +
                      "/client/view/" +
                      props.original.user_id +
                      "?thoughts"
                    }
                  >
                    new thought
                  </a>
                ) : (
                  <span>new thought</span>
                )}
              </span>
            </>
          );
        } else if (props.original.activity_type === 7) {
          return (
            <>
              <i className="far fa-lightbulb fa-2x"></i>
              <span className="pl-2">
                {props.original.user.first_name +
                  " " +
                  props.original.user.last_name}{" "}
                shared a{" "}
                {props.original.isCurrentClient ? (
                  <a
                    href={
                      CMS_URL +
                      "/client/view/" +
                      props.original.user_id +
                      "?thoughts"
                    }
                  >
                    thought
                  </a>
                ) : (
                  <span>thought</span>
                )}
              </span>
            </>
          );
        } else if (props.original.activity_type === 8) {
          return (
            <>
              <i className="fas fa-smile fa-2x"></i>
              <span className="pl-2">
                {" "}
                {props.original.user.first_name +
                  " " +
                  props.original.user.last_name}{" "}
                created a{" "}
                {props.original.isCurrentClient ? (
                  <a
                    href={
                      CMS_URL +
                      "/client/view/" +
                      props.original.user_id +
                      "?feelings"
                    }
                  >
                    new feeling
                  </a>
                ) : (
                  <span> new feeling</span>
                )}
              </span>
            </>
          );
        } else {
          return null;
        }
      }
    },
    {
      Header: "Date Created",
      accessor: "created_at",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      Cell: props =>
        moment(props.original.created_at).format("MM/DD/YYYY HH:mm"),
      width: 150,
      sortable: true
    }
  ];

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  function getList(
    field = "created_at",
    sortBy = "DESC",
    search = "",
    page = "1"
  ) {
    const params = {
      sort_by: field,
      order: sortBy,
      search: search,
      page: page
    };
    setLoader(true);

    RdServices.acitivityList(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);

        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count / 10));
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });
  }

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Notification List
            </div>
          </div>

          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              LoadingComponent={Loader}
              onFetchData={onChangePageGetData}
              onPageChange={pageIndex => {
                setActivePage(pageIndex);
                getList(field, sortBy, searchText, pageIndex + 1);
              }}
              onSortedChange={sortProperties => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getList(sortProperties[0].id, sort, searchText, 1);
                // alert("sort handler", sortProperties);
                // const orderByDirection = sortProperties[0].desc ? 1 : 0;

                // updateMapStateInfoOrderByColumn(sortProperties[0].id, orderByDirection);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default withRouter(ActivityTable);
