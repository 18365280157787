import React, { useEffect, useRef, useState } from "react";
import { compose } from "redux";
import enhancer from "./external-provider-validator";
import Button from "../button/Button";
import Toaster from "../common/Toaster";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
import Loader from "components/common/Loader";
import EfaxManagementServices from "api/EfaxMangementServices";
import { CMS_URL } from "helper/constant";

const ExternalProviderModal = props => {
  const [externalProviderList, setExternalProviderList] = useState();
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [faxDropdownOpen, setFaxDropdownOpen] = useState(false);
  const [provider, setProvider] = useState(null);
  const [faxNumber, setFaxnumber] = useState(null);
  const hasParam = props.match.params.id;
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const faxToggle = () => setFaxDropdownOpen(prevState => !prevState);
  const toaster = useRef();
  const { errors, touched, submitCount, setFieldValue, noteData } = props;

  useEffect(() => {
    if (hasParam) {
      const params = {
        user_id: hasParam
      };
      EfaxManagementServices.externalProviderList(params).then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        setExternalProviderList(data.data);
      });
    }
    return () => {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    };
  }, []);

  useEffect(() => {
    setFaxnumber(null);
    setFieldValue("fax_number", null);
  }, [provider]);

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const handleAssignRd = () => {
    let { isValid, handleSubmit } = props;
    if (isValid) {
      setIsLoading(true);
      let params = {
        rd_id: props?.adminId,
        external_rd_id: provider?.id,
        visit_note_id: noteData?.id,
        fax_number: faxNumber
      };

      EfaxManagementServices.addExternalProvider(params)
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          if (data.status) {
            if (toaster.current) toaster.current.success(data.message);
            setIsLoading(false);
            setTimeout(() => {
              props.toggle();
            }, 800);
          } else {
            if (toaster.current) toaster.current.error(data.message);
            setTimeout(() => {
              props.toggle();
            }, 1000);
          }
        })
        .catch(err => {
          if (toaster.current) toaster.current.error(err.message);
          setTimeout(() => {
            props.toggle();
          }, 800);
        });
    }
    handleSubmit();
  };

  return (
    <form className="pa-20">
      {loading && !externalProviderList ? (
        <>
          <Loader loading={loading} />
        </>
      ) : (
        <>
          <div className="form-group ">
            {externalProviderList.length ? (
              <>
                <div>
                  <span className="fs-20 demi-bold-text">
                    Select External Provider
                  </span>
                  <div className="flex pt-2 pb-10">
                    <div>
                      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                        <DropdownToggle caret style={{ width: "420px" }}>
                          {provider
                            ? provider?.first_name + " " + provider?.last_name
                            : "Select Provider"}
                        </DropdownToggle>
                        <DropdownMenu
                          style={{
                            width: "100%",
                            height: "20vh",
                            overflow: "scroll"
                          }}
                        >
                          {externalProviderList?.map((result, i) => {
                            return (
                              <DropdownItem
                                key={i}
                                onClick={() => {
                                  setProvider(result.external_rd);
                                  setFieldValue(
                                    "external_rd_id",
                                    result.external_rd.id
                                  );
                                }}
                              >
                                {result.external_rd.first_name +
                                  " " +
                                  result.external_rd.last_name}
                              </DropdownItem>
                            );
                          })}
                        </DropdownMenu>
                      </Dropdown>
                      <Error field="external_rd_id" />
                    </div>
                  </div>

                  <span className="fs-20 demi-bold-text">
                    Select Fax Number
                  </span>
                  <div className="flex pt-2 pb-100">
                    <div>
                      <Dropdown
                        isOpen={faxDropdownOpen}
                        toggle={faxToggle}
                        disabled={
                          !provider?.fax_number && !provider?.other_fax_number
                        }
                        title={`${
                          !provider?.fax_number && !provider?.other_fax_number
                            ? "Please select Provider first or contact Admin to add fax number!"
                            : ""
                        }`}
                      >
                        <DropdownToggle
                          caret
                          style={{ width: "420px" }}
                          className={
                            !provider?.fax_number &&
                            !provider?.other_fax_number &&
                            "cursor-not-allowed"
                          }
                        >
                          {faxNumber ? faxNumber : "Select Fax Number"}
                        </DropdownToggle>
                        <DropdownMenu
                          style={{
                            width: "100%",
                            height: "12vh",
                            overflow: "scroll"
                          }}
                        >
                          <DropdownItem
                            onClick={() => {
                              setFaxnumber(provider?.fax_number);
                              setFieldValue("fax_number", provider?.fax_number);
                            }}
                          >
                            {provider?.fax_number}
                          </DropdownItem>
                          {provider?.other_fax_number && (
                            <DropdownItem
                              onClick={() => {
                                setFaxnumber(provider?.other_fax_number);
                                setFieldValue(
                                  "fax_number",
                                  provider?.other_fax_number
                                );
                              }}
                            >
                              {provider?.other_fax_number}
                            </DropdownItem>
                          )}
                        </DropdownMenu>
                      </Dropdown>
                      <Error field="fax_number" />
                    </div>
                  </div>
                </div>
                <div className="flex-x center">
                  <Button
                    type="button"
                    className="c-btn c-secondary form-button fs-16 demi-bold-text mr-10"
                    style={{ maxWidth: "125px" }}
                    onClick={props.toggle}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    className="c-btn c-info form-button fs-16 demi-bold-text"
                    style={{ maxWidth: "125px" }}
                    onClick={() => handleAssignRd()}
                    loading={isLoading}
                    disabled={isLoading}
                    dataStyle={"expand-right"}
                  >
                    {isLoading ? <p>Saving...</p> : <p>Save</p>}
                  </Button>
                </div>
              </>
            ) : (
              <div className="flex flex-y center">
                <div className="pb-2">
                  Please assign a External Provider to this client from External
                  Care Team{" "}
                </div>
                <Button
                  type="button"
                  className="c-btn c-info form-button fs-16 demi-bold-text"
                  style={{ maxWidth: "125px" }}
                  onClick={() => {
                    window.location.href =
                      CMS_URL + "/client/view/" + hasParam + "?care-team";
                  }}
                >
                  Add
                </Button>
              </div>
            )}
          </div>
        </>
      )}
      <Toaster ref={toaster} />
    </form>
  );
};

export default compose(enhancer)(ExternalProviderModal);
