import React from "react";
import PageWrapper from "./rdperformancemetric.style";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";
import RdPerformanceMetricTable from "../../components/rdPerformanceMetric/RdPerformanceMetricTable";

const RdPerformanceMetric = props => {
  return (
    <PageWrapper {...props}>
      <div className="user-tbl">
        <RdPerformanceMetricTable {...props} />
      </div>
    </PageWrapper>
  );
};

const mapStateToProps = state => {
  return {
    rd_id: state.auth.id,
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(RdPerformanceMetric);
