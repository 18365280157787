import { withFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .trim()
      .required("This field is required")
      .max(100, "Name must be less than 100 characters"),
    launch_date: Yup.date()
      .required("This field is required")
      .nullable(),
    service: Yup.string()
      .trim()
      .required("This field is required")
  }),
  mapPropsToValues: props => ({
    id: props.editedData
      ? props && props.editedData && props.editedData.id
      : "",
    name: props.editedData
      ? props && props.editedData && props.editedData.name
      : "",
    type: props.editedData
      ? props && props.editedData && props.editedData.type
      : "Employer",
    payment_method: props.editedData
      ? props && props.editedData && props.editedData.payment_method
      : "Insurance Funded",
    launch_date: props.editedData
      ? props &&
        props.editedData &&
        new Date(moment(props.editedData.launch_date))
      : new Date(),
    universal_login: props.editedData
      ? props && props.editedData && props.editedData.universal_login
      : false,
    service: props.editedData ? props.editedData?.service?.id : ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
