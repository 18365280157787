import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import enhancer from "../userForms/validator";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Button
} from "reactstrap";
import ClientManagementForm from "api/ClientMangementForm";
import EncryptDecrypt from "api/EncryptDecrypt";
import { compose } from "redux";
import Toaster from "components/common/Toaster";

const ReassignForm = props => {
  const toaster = useRef(null);
  const hasParam = props.match.params.id;
  const { errors, touched, setFieldValue, submitCount } = props;
  const [startDate, setStartDate] = useState(null);
  const [statusLabel, setStatusLabel] = useState("Select Form");
  const [formList, setFormList] = useState([]);
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [formId, setFormId] = useState(null);
  const [loading, setLoading] = useState(false);

  const toggle = () => setStatusDropdownOpen(prevState => !prevState);

  useEffect(() => {
    const params = {
      user_id: hasParam
    };
    ClientManagementForm.form_list(params).then(result => {
      let data = EncryptDecrypt.decrypt(result.data);
      if (data.data) setFormList(data.data);
    });
  }, []);

  const handleReassign = () => {
    let { isValid, handleSubmit } = props;

    if (isValid) {
      setLoading(true);
      let reassignObj = {
        user_id: hasParam,
        form_id: formId.id,
        due_date: moment(startDate).format("YYYY-MM-DD"),
        reassign_form: statusLabel
      };
      ClientManagementForm.reassign_form(reassignObj)
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          if (data.status) {
            props.toggle();
            props.pageRefresh();
          } else {
            if (toaster.current) toaster.current.error("Something went wrong!");
          }
          setLoading(false);
        })
        .catch(err => {
          if (err?.response?.status === 403) {
            if (toaster.current) {
              toaster.current.error("You cannot access this user info!");
              setTimeout(() => {
                props.history.push("/client");
              }, 3000);
            }
          } else {
            if (toaster.current) toaster.current.error(err?.message);
          }
          setLoading(false);
        });
    }
    handleSubmit();
  };
  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <div>
      <form className="pa-24">
        <div className="form-group">
          <div className="roe-card-body">
            <div className="form-group ">
              <label>Form:</label>
              <Dropdown isOpen={statusDropdownOpen} toggle={toggle}>
                <DropdownToggle caret size="md">
                  {formId ? formId?.name : "Select Form"}
                </DropdownToggle>
                <DropdownMenu>
                  {formList.length > 0 &&
                    formList?.map(data => (
                      <DropdownItem
                        key={data.id}
                        onClick={() => {
                          setFormId(data);
                          setFieldValue("form_id", data);
                        }}
                      >
                        {data.name}
                      </DropdownItem>
                    ))}
                </DropdownMenu>
              </Dropdown>
              <Error field="form_id" />
            </div>
          </div>
        </div>
        <div className="form-group">
          <label>Due Date:</label>
          <div className="roe-card-body">
            <DatePicker
              selected={startDate}
              onChange={date => {
                setStartDate(date);
                setFieldValue("due_date", date);
              }}
              className="custom-datepicker form-control react-form-input"
              calendarClassName="custom-calender-class"
              minDate={moment().toDate()}
              selectsStart
            />
          </div>
          <Error field="due_date" />
        </div>
        <div>
          <Button
            type="button"
            className="c-btn c-info form-button fs-16 demi-bold-text mr-10"
            style={{ maxWidth: "125px" }}
            onClick={() => {
              handleReassign();
            }}
            loading={loading}
            disabled={loading}
            dataStyle="expand-right"
          >
            Submit
          </Button>
          <Button
            type="button"
            className="c-btn c-secondary form-button fs-16 demi-bold-text"
            style={{ maxWidth: "125px" }}
            onClick={props.toggle}
          >
            Close
          </Button>
        </div>
      </form>
      <Toaster ref={toaster} />
    </div>
  );
};

export default compose(enhancer)(ReassignForm);
