import React, { useEffect } from "react";
import moment from "moment-timezone";
import { PRACTICE_STATUS } from "helper/constant";

const PracticeDetail = props => {
  const details = props.location.state;

  useEffect(() => {
    if (!details) props.history.push("/practice");
  }, [details]);

  return (
    <div className="plr-15">
      <div className="roe-card-style mtb-15">
        <div className="roe-card-header module-header">
          <div className="flex-1 fs-16 demi-bold-text">
            <span className="hash"># </span> View Practice
          </div>
        </div>
        <div className="roe-card-body">
          <div className="pt-2">
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>
              IBO Practice Name
            </div>
            <div>{details?.practice_name ? details?.practice_name : "-"}</div>
          </div>
          <div className="pt-2">
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>
              Service
            </div>
            <div>{details?.service?.name ? details?.service?.name : "-"}</div>
          </div>
          <div className="pt-2">
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>
              IBO Practice TIN
            </div>
            <div>{details?.practice_tin ? details?.practice_tin : "-"}</div>
          </div>
          <div className="pt-2">
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>
              IBO Practice NPI
            </div>
            <div>{details?.practice_npi ? details?.practice_npi : "-"}</div>
          </div>
          <div className="pt-2">
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>
              IBO Office Key
            </div>
            <div>
              {details?.advancemd_setting?.advancemd_office_code
                ? details?.advancemd_setting?.advancemd_office_code
                : "-"}
            </div>
          </div>

          <div className="pt-2">
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>
              Created At
            </div>
            <div>
              {details?.created_at
                ? moment(details?.created_at).format("MM/DD/YYYY HH:mm")
                : "-"}
            </div>
          </div>
          <div className="pt-2">
            <div>
              <div style={{ fontWeight: "bolder", textAlign: "left" }}>
                Status
              </div>
              <div>
                {details?.status ? PRACTICE_STATUS[details?.status] : "-"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PracticeDetail;
