import React, { useRef, useState } from "react";
import UserWrapper from "./user.style";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";
import ChangePasswordForm from "../../components/changepassword/ChangePasswordForm";
import AdminServices from "../../api/AdminServices";
import Toaster from "../../components/common/Toaster";
import EncryptDecrypt from "../../api/EncryptDecrypt";

const ChangePassword = props => {
  const toaster = useRef();
  const [loading, setLoading] = useState(false);
  const submitFormHandler = data => {
    const params = {
      old_password: data.currentPassword,
      new_password: data.newPassword
    };
    AdminServices.changepassword(params)
      .then(response => {
        let data = EncryptDecrypt.decrypt(response.data);
        if (data.status) {
          if (toaster.current) toaster.current.success(data.message);
          setLoading(false);
          setTimeout(() => {
            AdminServices.logout();
            props.history.push("./login");
          }, 500);
        } else {
          if (toaster.current) toaster.current.error(data.message);
          setLoading(false);
        }
      })
      .catch(error => {
        setLoading(false);
        if (toaster.current) toaster.current.error(data.message);
      });
  };

  return (
    <UserWrapper {...props}>
      <div className="pos-relative">
        <PageTitle title="sidebar.changepassword" className="plr-15" />
        <div
          className="back-icon fs-15 demi-bold-text cursor-pointer"
          onClick={() => props.history.push("/dashboard")}
        >
          <i className="fas fa-step-backward"></i> Back
        </div>
      </div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header"></div>
          <div className="roe-card-body">
            <ChangePasswordForm
              loading={loading}
              onSubmit={submitFormHandler}
            />
          </div>
        </div>
      </div>
      <Toaster ref={toaster} />
    </UserWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(ChangePassword);
