import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import RDServices from "../../api/RdServices";
import Loader from "../common/Loader";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
import Toaster from "../common/Toaster";
import {
  APPOINTMENT_STATUS_LABEL,
  DEFAULT_RD_DROPDOWN,
  PROVIDER_TYPE,
  PROVIDER_TYPES,
  SERVICE_NAME,
  TABLE
} from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import moment from "moment-timezone";
import ClientServices from "../../api/ClientServices";
import CategoryServices from "api/CategoryServices";
import Button from "components/button/Button";
import RdServices from "../../api/RdServices";

const PendingBillingTable = props => {
  const toaster = useRef(null);

  const [tblData, settblData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [field, setField] = useState("created_at");
  const [sortBy, setSortBy] = useState("DESC");
  const [RDDropdownOpen, setRDDropdownOpen] = useState(false);
  const [RDId, setRDId] = useState(null);
  const [RDName, setRDName] = useState(null);
  const [RDList, setRDList] = useState([]);
  const [serviceId, setServiceId] = useState(null);
  const [serviceName, setServiceName] = useState(null);
  const [serviceList, setServiceList] = useState([]);
  const [serviceDropdownOpen, setServiceDropdownOpen] = useState(false);
  const [filteredRDList, setFilteredRDList] = useState([]);
  const [typeName, setTypeName] = useState(null);
  const [typeValue, setTypeValue] = useState(null);
  const [typeDropdownOpen, setTypeDropdownOpen] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  useEffect(() => {
    CategoryServices.serviceDropdown()
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setServiceList([...data.data]);
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      });
  }, []);

  useEffect(() => {
    if (props.auth && props.auth.admin_role_id !== 3) {
      const params1 = {
        type: "",
        practice_id:
          props.auth && props.auth?.admin_role_id === 6 && props.auth?.practice
            ? props.auth?.practice
            : undefined
      };
      ClientServices.RDList(params1).then(result => {
        let data = EncryptDecrypt.decrypt(result.data);
        setRDList(data.data);
      });
    }
    setRDId(
      props.auth && props.auth.admin_role_id !== 3 ? null : props.auth.id
    );
  }, []);

  useEffect(() => {
    let filtered = [...RDList];

    if (serviceId) {
      filtered = filtered.filter(rd => {
        return (
          rd?.rd_detail?.service_id === serviceId ||
          rd?.id === Number(DEFAULT_RD_DROPDOWN)
        );
      });
    }

    if (typeValue) {
      filtered = filtered.filter(rd => {
        return rd?.rd_detail?.type === typeValue;
      });
    }
    // if (!serviceId && !typeValue) filtered = [];

    setFilteredRDList([...filtered]);
  }, [serviceId, typeValue, RDList]);

  useEffect(() => {
    if (serviceId || props.auth.admin_role_id <= 3) {
      getList(field, sortBy, activePage + 1);
      setPages(10);
    }
  }, [RDId, typeValue, serviceId]);

  useEffect(() => {
    if (props?.auth?.admin_role_id > 2) {
      setServiceId(props?.auth?.service_id);
      setServiceName(SERVICE_NAME[props?.auth?.service_id]);
    }
  }, []);

  const toggleRD = () => setRDDropdownOpen(prevState => !prevState);

  const toggleType = () => setTypeDropdownOpen(prevState => !prevState);

  const toggleService = () => setServiceDropdownOpen(prevState => !prevState);

  const columns = [
    {
      Header: "Client ID",
      accessor: "user_id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 120
    },
    {
      Header: "First Name",
      accessor: "user.first_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 180
    },
    {
      Header: "Last Name",
      accessor: "user.last_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 180
    },
    {
      Header: "Appointment ID",
      accessor: "id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 170
    },
    {
      Header: "Appointment Type",
      accessor: "appointment_type",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 300,
      Cell: props => {
        return props.original.appointment_type === "Manual"
          ? "Manual"
          : props.original?.event_detail?.name
          ? `${props.original?.event_detail?.name}(Calendly)`
          : "";
      }
    },
    {
      Header: "Appointment Date",
      accessor: "appointment_date",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 180,
      Cell: props =>
        props.original.appointment_type === "Manual"
          ? moment
              .tz(
                moment(
                  props.original.appointment_date +
                    " " +
                    props.original.appointment_time
                ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                moment.tz.guess()
              )
              .format("MM/DD/YYYY")
          : moment
              .tz(props.original.event_detail.start_time, moment.tz.guess())
              .format("MM/DD/YYYY")
    },
    {
      Header: "Appointment Time",
      accessor: "appointment_time",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 180,
      Cell: props =>
        props.original.appointment_type === "Manual"
          ? moment
              .tz(
                moment(
                  props.original.appointment_date +
                    " " +
                    props.original.appointment_time
                ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                moment.tz.guess()
              )
              .format("h:mm A")
          : moment
              .tz(props.original.event_detail.start_time, moment.tz.guess())
              .format("h:mm A")
    },
    {
      Header: "Status",
      accessor: "status",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",
      sortable: false,
      width: 150,
      Cell: props => APPOINTMENT_STATUS_LABEL[props.original.status]
    },
    {
      Header: "Service",
      accessor: "user",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      width: 120,
      Cell: props =>
        props?.original?.admin?.rd_detail?.service?.name
          ? props?.original?.admin?.rd_detail?.service?.name
          : ""
    }
  ];

  if (props.auth && props.auth.admin_role_id !== 3) {
    let element = {
      Header: "Provider",
      accessor: "admin",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      width: 200,
      Cell: props =>
        props.original?.admin
          ? props.original?.admin?.first_name +
            " " +
            props.original?.admin?.last_name
          : ""
    };

    columns.splice(4, 0, element);
  }

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  function getList(field = "title", sortBy = "DESC", page = 1) {
    const params = {
      sort_by: field,
      order: sortBy,
      page: page,
      rd_id:
        props.auth && props.auth.admin_role_id !== 3 ? RDId : props.auth.id,
      service_id:
        props.auth && props.auth.admin_role_id !== 3
          ? serviceId
          : props.auth.service_id,
      rd_type: typeValue,
      practice_id:
        props.auth && props.auth?.admin_role_id === 6 && props.auth?.practice
          ? props.auth?.practice
          : undefined
    };

    setLoader(true);
    RDServices.pendingBillingList(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count?.length / TABLE.LIMIT));
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
          setLoader(false);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.message);
        setLoader(false);
      });
  }

  function exportList() {
    setIsExporting(true);
    const params = {
      sort_by: field,
      order: sortBy,
      rd_id:
        props.auth && props.auth.admin_role_id !== 3 ? RDId : props.auth.id,
      service_id:
        props.auth && props.auth.admin_role_id !== 3
          ? serviceId
          : props.auth.service_id,
      rd_type: typeValue,
      practice_id:
        props.auth && props.auth?.admin_role_id === 6 && props.auth?.practice
          ? props.auth?.practice
          : undefined
    };
    RdServices.pendingBillingCsv(params)
      .then(res => {
        setIsExporting(false);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error("Something went wrong!");
        setIsExporting(false);
      });
  }

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Pending Billing List
            </div>

            {props.auth && props.auth.admin_role_id <= 2 && (
              <div className="fs-14  mr-10">
                <Dropdown isOpen={serviceDropdownOpen} toggle={toggleService}>
                  <DropdownToggle caret size="md">
                    {serviceName ? serviceName : "Select Service"}
                  </DropdownToggle>
                  <DropdownMenu
                    modifiers={{
                      setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: data => {
                          return {
                            ...data,
                            styles: {
                              ...data.styles,
                              overflow: "auto",
                              maxHeight: "300px"
                            }
                          };
                        }
                      }
                    }}
                  >
                    <DropdownItem
                      key="-1"
                      onClick={() => {
                        setServiceId("");
                        setServiceName("All");
                        setRDId("");
                        setRDName("");
                        setTypeName(null);
                        setTypeValue(null);
                        setActivePage(0);
                      }}
                    >
                      All
                    </DropdownItem>
                    {serviceList.length > 0 &&
                      serviceList.map((result, i) => {
                        return (
                          <DropdownItem
                            key={i}
                            onClick={() => {
                              setServiceId(result.id);
                              setServiceName(`${result.name}`);
                              setRDId("");
                              setRDName("");
                              setTypeName(null);
                              setTypeValue(null);
                              setActivePage(0);
                            }}
                          >
                            {result.name}
                          </DropdownItem>
                        );
                      })}
                  </DropdownMenu>
                </Dropdown>
              </div>
            )}
            {props.auth &&
              props.auth.admin_role_id !== 3 &&
              props.auth.admin_role_id !== 6 && (
                <div className="fs-14 mr-10">
                  <Dropdown isOpen={typeDropdownOpen} toggle={toggleType}>
                    <DropdownToggle caret size="md">
                      {typeName ? typeName : "Type"}
                    </DropdownToggle>
                    <DropdownMenu
                      modifiers={{
                        setMaxHeight: {
                          enabled: true,
                          order: 890,
                          fn: data => {
                            return {
                              ...data,
                              styles: {
                                ...data.styles,
                                overflow: "auto",
                                maxHeight: "300px"
                              }
                            };
                          }
                        }
                      }}
                    >
                      <DropdownItem
                        key="-1"
                        onClick={() => {
                          setRDId(null);
                          setRDName(null);
                          setTypeName("All");
                          setTypeValue("");
                          setActivePage(0);
                        }}
                      >
                        All
                      </DropdownItem>
                      {PROVIDER_TYPES.map((result, i) => {
                        return (
                          <DropdownItem
                            key={i}
                            onClick={() => {
                              setRDId(null);
                              setRDName(null);
                              setTypeName(`${result.name}`);
                              setTypeValue(`${result.value}`);
                              setActivePage(0);
                            }}
                          >
                            {result.name}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </Dropdown>
                </div>
              )}
            {props.auth && props.auth.admin_role_id !== 3 && (
              <div className="fs-14  mr-10">
                <Dropdown
                  isOpen={RDDropdownOpen}
                  toggle={toggleRD}
                  disabled={!filteredRDList.length}
                  title="Please select service first!"
                >
                  <DropdownToggle
                    caret
                    size="md"
                    className={!filteredRDList.length && "cursor-not-allowed"}
                  >
                    {RDName ? RDName : "Providers"}
                  </DropdownToggle>
                  <DropdownMenu
                    modifiers={{
                      setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: data => {
                          return {
                            ...data,
                            styles: {
                              ...data.styles,
                              overflow: "auto",
                              maxHeight: "300px"
                            }
                          };
                        }
                      }
                    }}
                  >
                    <DropdownItem
                      key="-1"
                      onClick={() => {
                        setRDId("");
                        setRDName("All");
                        setActivePage(0);
                      }}
                    >
                      All
                    </DropdownItem>
                    {filteredRDList.length > 0 && (
                      <DropdownItem
                        key="0"
                        onClick={() => {
                          setRDId("null");
                          setRDName("Not Assigned");
                          setActivePage(0);
                        }}
                      >
                        Not Assigned
                      </DropdownItem>
                    )}
                    {filteredRDList.length > 0 &&
                      filteredRDList.map((result, i) => {
                        return (
                          <DropdownItem
                            key={i + 1}
                            onClick={() => {
                              setRDId(result.id);
                              setRDName(
                                `${result.first_name} ${result.last_name}`
                              );
                              setActivePage(0);
                            }}
                          >
                            {result.first_name} {result.last_name}{" "}
                            {result?.rd_detail?.type
                              ? `(${PROVIDER_TYPE[result?.rd_detail?.type]})`
                              : "(-)"}
                          </DropdownItem>
                        );
                      })}
                  </DropdownMenu>
                </Dropdown>
              </div>
            )}

            <div>
              <Button
                className="c-btn c-primary ma-5"
                onClick={exportList}
                disabled={!tblData?.length || isExporting}
              >
                <i className="fas fa-download mr-10" />
                {!isExporting ? "Export" : "Exporting..."}
              </Button>
            </div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              LoadingComponent={Loader}
              onFetchData={onChangePageGetData}
              onPageChange={pageIndex => {
                setActivePage(pageIndex);
                getList(field, sortBy, pageIndex + 1);
              }}
              onSortedChange={sortProperties => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getList(sortProperties[0].id, sort, 1);
                // alert("sort handler", sortProperties);
                // const orderByDirection = sortProperties[0].desc ? 1 : 0;

                // updateMapStateInfoOrderByColumn(sortProperties[0].id, orderByDirection);
              }}
            />
          </div>
        </div>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};

export default withRouter(PendingBillingTable);
