import React, { Fragment, useRef } from "react";
import { compose } from "redux";
import enhancer from "./scale-program-validator";
import Button from "../button/Button";
import Toaster from "../common/Toaster";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import GroupServices from "../../api/GroupServices";
import { Input } from "reactstrap";

const ScaleProgramModal = props => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    setFieldValue,
    onSubmit,
    values,
    action,
    scaleProgramList
  } = props;

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const toaster = useRef();

  const hasParam = props.match.params.id;

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;

    if (isValid) {
      onSubmit(values, action);
      if (action === "add") {
        const params = {
          group_id: hasParam,
          scale_program_id: values.scale_program_id
        };
        GroupServices.addScaleProgram(params)
          .then(res => {
            let data = EncryptDecrypt.decrypt(res.data);
            if (data.status) {
              if (toaster.current) toaster.current.success(data.message);
            } else {
              if (toaster.current) toaster.current.error(data.message);
            }
          })
          .catch(err => {
            if (toaster.current) toaster.current.error(err.response);
          });
      } else if (action === "edit") {
        const params = {
          id: values.id,
          group_id: hasParam,
          scale_program_id: values.scale_program_id
        };
        GroupServices.editScaleProgram(params)
          .then(res => {
            let data = EncryptDecrypt.decrypt(res.data);
            if (data.status) {
              if (toaster.current) toaster.current.success(data.message);
            } else {
              if (toaster.current) toaster.current.error(data.message);
            }
          })
          .catch(err => {
            if (toaster.current) toaster.current.error(err.response);
          });
      }
    }

    handleSubmit();
  };

  return (
    <form>
      <div className="form-group">
        <label className="fs-16 medium-text">
          Scale Program <span className="asterisk">*</span>
        </label>
        <br />
        <Input
          type="select"
          name=""
          id="scale_program_id"
          onChange={handleChange}
          value={values.scale_program_id}
          onBlur={handleBlur}
        >
          <option value="">Select Scale Program</option>
          {scaleProgramList &&
            scaleProgramList.map((type, i) => {
              return (
                <Fragment>
                  <option key={`option${i}`} value={type.id}>
                    {type.name}
                  </option>
                </Fragment>
              );
            })}
        </Input>
        <Error field="scale_program_id" />
      </div>

      <div>
        <Button
          type="button"
          className="c-btn c-info form-button fs-16 demi-bold-text mr-10"
          style={{ maxWidth: "125px" }}
          onClick={() => handleSubmit()}
        >
          Submit
        </Button>
        <Button
          type="button"
          className="c-btn c-secondary form-button fs-16 demi-bold-text"
          style={{ maxWidth: "125px" }}
          onClick={props.toggle}
        >
          Close
        </Button>
      </div>
      <Toaster ref={toaster} />
    </form>
  );
};

export default compose(enhancer)(ScaleProgramModal);
