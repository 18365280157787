import axios from "axios";
import { baseUrl } from "../helper/constant";
import Header from "./Header";
import EncryptDecrypt from "./EncryptDecrypt";

export default class CategoryServices {
  static list(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/category/list`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static add(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/category/add`, params);
  }

  static edit(params) {
    Header.setHeaders();
    return axios.put(`${baseUrl}/category/edit`, params);
  }

  static view(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/category/view`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static delete(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/category/delete`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static status(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/category/status`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static serviceDropdown(params = {}) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/category/services-list`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static listCategory(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/rd/list-category`,
      params && EncryptDecrypt.encrypt(params, 1)
    );
  }
}
