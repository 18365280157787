import { modulePaths } from "helper/constant";
import { sidebarData } from "./data/sidebar";

export const getSidebarData = permissions => {
  let newSb = [
    {
      name: "sidebar.dashboard",
      iconClass: "fas fa-chalkboard",
      routepath: "/dashboard"
      // subAdmin: true,
      // isRD: true,
      // order: 1,
    }
  ];
  let reports = {
    name: "sidebar.report",
    iconClass: "fa fa-clone",
    // isRD: true,
    // subAdmin: true,
    child: []
    // order: 20,
  };
  if (permissions) {
    permissions.forEach(module => {
      if (module.view && !module.name.split(" ").includes("Report")) {
        const sidebarEl = sidebarData.find(
          s =>
            !s?.child?.length && s.routepath === modulePaths[module.name]?.view
        );
        if (sidebarEl) {
          newSb.push(sidebarEl);
        }
      }

      if (module.view && !!module.name.split(" ").includes("Report")) {
        const sidebarEl = sidebarData.find(s => !!s?.child?.length);
        if (sidebarEl) {
          const child = sidebarEl.child.find(
            s => s.routepath === modulePaths[module.name]?.view
          );
          if (child) reports.child.push(child);
        }
      }
    });
  }
  if (reports.child.length) {
    newSb.push(reports);
  }
  newSb.sort((a, b) => a.order - b.order);
  return newSb;
};

export const hasAccess = (moduleName = "", permission = "", permissions) => {
  const moduleInfo =
    permissions.length && permissions.find(el => el.name === moduleName);
  return moduleInfo ? !!moduleInfo[permission] : false;
};
