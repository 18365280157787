import React, { useEffect, useState } from "react";
import UserWrapper from "./Rd.style";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";
import RdServices from "../../api/RdServices";
import RdForm from "../../components/rd/RdForm";
import EncryptDecrypt from "../../api/EncryptDecrypt";

const RdEdit = props => {
  const [editedData, setEditedData] = useState(undefined);
  const [category, setCategory] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [serviceLocationList, setServiceLocationList] = useState([]);
  const [timezoneList, setTimezoneList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const hasParam =
    props?.match?.params.id || props?.id || props?.location?.state?.rdID;

  const breadCrums = !props?.location?.state?.rdID
    ? [
        {
          name: "sidebar.dashboard",
          url: "/dashboard"
        },
        {
          name: "sidebar.provider",
          url: "/rd"
        },
        {
          name: hasParam ? "action.edit" : "action.add"
        }
      ]
    : [
        {
          name: "sidebar.profile",
          url: "/rd-edit-profile"
        },
        {
          name: hasParam ? "action.edit" : "action.add"
        }
      ];

  useEffect(() => {
    if (hasParam) {
      const params = {
        id: hasParam
      };
      RdServices.view(params).then(res => {
        let data = EncryptDecrypt.decrypt(res.data);

        const languageArr = [];

        data.data.languages.map(res => {
          return languageArr.push(res.id.toString());
        });

        data.data.language_id = languageArr;
        setEditedData(data.data);
      });
    }
    RdServices.stateList().then(res => {
      let data = EncryptDecrypt.decrypt(res.data);
      setStateList(data.data);
    });
    RdServices.serviceLocationList().then(res => {
      let data = EncryptDecrypt.decrypt(res.data);
      setServiceLocationList(data.data);
    });
    RdServices.timezoneList().then(res => {
      let data = EncryptDecrypt.decrypt(res.data);
      setTimezoneList(data.data);
    });
    RdServices.languageList().then(res => {
      let data = EncryptDecrypt.decrypt(res.data);
      setLanguageList(data.data);
    });
  }, []);

  useEffect(() => {
    RdServices.listCategory({
      service_id: editedData?.rd_detail?.service_id
    }).then(res => {
      let data = EncryptDecrypt.decrypt(res.data);
      setCategory(data.data);
    });
  }, [editedData]);

  const submitFormHandler = (data, action) => {
    setTimeout(() => {
      if (props.pageView === "SETTING") {
        props.toggle();
        props.pageRefresh();
      } else if (props?.location?.state?.rdID) {
        props.history.push("/rd-edit-profile");
      } else {
        props.history.push("/rd");
      }
    }, 1000);
  };

  return (
    <UserWrapper {...props}>
      {props?.pageView !== "SETTING" && (
        <div className="pos-relative">
          <PageTitle
            props={props}
            title="sidebar.provider"
            className="plr-15"
            breadCrumb={breadCrums}
          />
          <div
            className="back-icon fs-15 demi-bold-text cursor-pointer"
            onClick={() =>
              props?.location?.state?.isRD
                ? props.history.push("/rd-edit-profile")
                : props.history.push("/rd")
            }
          >
            <i className="fas fa-step-backward"></i> Back
          </div>
        </div>
      )}

      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          {props?.pageView !== "SETTING" && !props?.location?.state?.isRD && (
            <div className="roe-card-header module-header">
              <div className="flex-1 fs-16 demi-bold-text">
                <span className="hash"># </span> {hasParam ? "Edit" : "Add"}{" "}
                Provider Profile
              </div>
            </div>
          )}
          {props?.pageView !== "SETTING" && props?.location?.state?.isRD && (
            <div className="roe-card-header module-header">
              <div className="flex-1 fs-16 demi-bold-text">
                <span className="hash"># </span>
                Edit Profile
              </div>
            </div>
          )}

          <div className="roe-card-body">
            <RdForm
              onSubmit={submitFormHandler}
              editedData={editedData}
              category={category || []}
              actionFlow={props?.location?.state?.actionFlow}
              providerId={props?.location?.state?.providerId}
              action={hasParam ? "edit" : "add"}
              stateList={stateList || []}
              serviceLocationList={serviceLocationList || []}
              setServiceLocationList={setServiceLocationList}
              languageList={languageList || []}
              timezoneList={timezoneList || []}
              pageView={props?.pageView}
              isRD={props?.admin_role_id === 3}
            />
          </div>
        </div>
      </div>
    </UserWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    ...state.auth
  };
};

export default connect(mapStateToProps, null)(RdEdit);
