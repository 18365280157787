import React, { Fragment, useState, useEffect } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col
} from "reactstrap";
import ProfileWrapper from "./profile.style";
import ProfileForm from "components/rdprofile/ProfileForm";
import RegisteredDietitian from "../../api/RegisteredDietitian";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import { connect } from "react-redux";
import InsuranceForm from "components/rdprofile/InsuranceForm";
import StateForm from "components/rdprofile/StateForm";
import RdPerformanceMetricTable from "../../views/RdPerformanceMetric/RdPerformanceMetric";
import classnames from "classnames";
import RdServices from "api/RdServices";
import CategoryTab from "components/rd/CategoryTab";
import ReferralCodeTable from "components/rd/ReferralCodeTable";
import RdBasicViewDetails from "components/rd/RdBasicViewDetails";
import RdSettings from "components/rd/RdSettings";
import { hasAccess } from "util/sidebar";
import { MODULE_NAMES, PERMISSION_TYPE } from "helper/constant";

const Profile = props => {
  const { permissions } = props;
  const [activeTab, setActiveTab] = useState(1);
  const [profileData, setProfileData] = useState([]);
  const [insuranceData, setInsuranceData] = useState(undefined);

  const [insuranceList, setInsuranceList] = useState(undefined);
  const [stateData, setStateData] = useState(undefined);
  const [stateList, setStateList] = useState([]);
  const [timezoneList, setTimezoneList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [categoryData, setCategoryData] = useState([]);

  useEffect(() => {
    if (activeTab === 1) {
      RegisteredDietitian.viewRDProfile(props.id).then(result => {
        let data = EncryptDecrypt.decrypt(result.data);
        const languageArr = [];

        data.data.languages.map(res => {
          return languageArr.push(res.id.toString());
        });

        data.data.language_id = languageArr;
        setProfileData(data.data);
      });
      RdServices.listCategory({
        service_id: props?.service_id
      }).then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        setCategoryList(data.data);
      });
      RegisteredDietitian.stateList().then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        setStateList(data.data);
      });
      RegisteredDietitian.timezoneList().then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        setTimezoneList(data.data);
      });
      RegisteredDietitian.languageList().then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        setLanguageList(data.data);
      });
      RdServices.rdCategoryList({
        id: props.id
      }).then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        setCategoryData(data.data);
      });
    }
    if (activeTab === 2) {
      RegisteredDietitian.rdStateList(props.id).then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        setStateData(data.data);
      });

      RegisteredDietitian.stateList().then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        setStateList(data.data);
      });
    }
    if (activeTab === 3) {
      RegisteredDietitian.groupInsuranceList(props.id).then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        setInsuranceData(data.data);
      });
      RegisteredDietitian.insuranceList().then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        setInsuranceList(data.data);
      });
    }
  }, [activeTab]);

  return (
    <Fragment>
      <ProfileWrapper {...props}>
        <div className="profile-head">
          <h3>
            {profileData.first_name} {profileData.last_name}
          </h3>
        </div>
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames(
                      { active: activeTab === 1 },
                      "doc-title"
                    )}
                    onClick={() => {
                      setActiveTab(1);
                    }}
                  >
                    Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames(
                      { active: activeTab === 7 },
                      "doc-title"
                    )}
                    onClick={() => {
                      setActiveTab(7);
                    }}
                    disabled={
                      !hasAccess(
                        MODULE_NAMES.PROVIDER_MANAGEMENT_SETTINGS,
                        PERMISSION_TYPE.VIEW,
                        permissions
                      )
                    }
                  >
                    Settings
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames(
                      { active: activeTab === 2 },
                      "doc-title"
                    )}
                    onClick={() => {
                      setActiveTab(2);
                    }}
                    disabled={
                      !hasAccess(
                        MODULE_NAMES.PROVIDER_MANAGEMENT_STATE_LICENCES,
                        PERMISSION_TYPE.VIEW,
                        permissions
                      )
                    }
                  >
                    State Licenses
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames(
                      { active: activeTab === 3 },
                      "doc-title"
                    )}
                    onClick={() => {
                      setActiveTab(3);
                    }}
                    disabled={
                      !hasAccess(
                        MODULE_NAMES.PROVIDER_MANAGEMENT_INSURANCE_CARRIERS,
                        PERMISSION_TYPE.VIEW,
                        permissions
                      )
                    }
                  >
                    Insurance Carriers
                  </NavLink>
                </NavItem>
                {props?.service_id !== 2 && (
                  <NavItem>
                    <NavLink
                      className={classnames(
                        { active: activeTab === 4 },
                        "doc-title"
                      )}
                      onClick={() => {
                        setActiveTab(4);
                      }}
                      disabled={
                        !hasAccess(
                          MODULE_NAMES.PROVIDER_MANAGEMENT_PERFORMANCE_METRICS,
                          PERMISSION_TYPE.VIEW,
                          permissions
                        )
                      }
                    >
                      Performance Metrics
                    </NavLink>
                  </NavItem>
                )}
                <NavItem>
                  <NavLink
                    className={classnames(
                      { active: activeTab === 5 },
                      "doc-title"
                    )}
                    onClick={() => {
                      setActiveTab(5);
                    }}
                    disabled={
                      !hasAccess(
                        MODULE_NAMES.PROVIDER_MANAGEMENT_CATEGORIES,
                        PERMISSION_TYPE.VIEW,
                        permissions
                      )
                    }
                  >
                    Category
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames(
                      { active: activeTab === 6 },
                      "doc-title"
                    )}
                    onClick={() => {
                      setActiveTab(6);
                    }}
                    disabled={
                      !hasAccess(
                        MODULE_NAMES.PROVIDER_REGISTRATION_CODE,
                        PERMISSION_TYPE.VIEW,
                        permissions
                      )
                    }
                  >
                    Registration Codes
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>
          <div className="roe-card-body">
            <TabContent activeTab={activeTab}>
              <TabPane tabId={1}>
                <RdBasicViewDetails {...props} isRD={true} />
              </TabPane>
              <TabPane tabId={7}>
                <RdSettings {...props} />
              </TabPane>
              {/* <TabPane tabId={1}>
                <Row>
                  <Col sm="12">
                    <ProfileForm
                      editedData={profileData}
                      stateList={stateList}
                      timezoneList={timezoneList}
                      languageList={languageList}
                    />
                  </Col>
                </Row>
              </TabPane> */}
              <TabPane tabId={2}>
                <Row>
                  <Col sm="12">
                    <StateForm
                      stateData={stateData}
                      stateList={stateList}
                      adminId={props.id}
                    />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId={3}>
                <Row>
                  <Col sm="12">
                    <InsuranceForm
                      insuranceData={insuranceData}
                      insuranceList={insuranceList}
                      adminId={props.id}
                    />
                  </Col>
                </Row>
              </TabPane>
              {props?.service_id !== 2 && (
                <TabPane tabId={4}>
                  <Row>
                    <Col sm="12">
                      <RdPerformanceMetricTable adminId={props.id} />
                    </Col>
                  </Row>
                </TabPane>
              )}
              <TabPane tabId={5}>
                <CategoryTab
                  onSubmit={() => {}}
                  editedData={null}
                  insuranceData={categoryData}
                  insuranceList={categoryList}
                  {...props}
                />
              </TabPane>
              <TabPane tabId={6}>
                <ReferralCodeTable service={props?.service_id} {...props} />
              </TabPane>
            </TabContent>
          </div>
        </div>
      </ProfileWrapper>
    </Fragment>
  );
};
const mapStateToProps = state => {
  return { ...state.auth, ...state.permissionReducer };
};
export default connect(mapStateToProps, null)(Profile);
