import React from "react";
import PageWrapper from "./common.style";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";
import DailyBillingFailureTable from "../../components/report/DailyBillingFailureTable";

const DailyBillingFailureReport = props => {
  return (
    <PageWrapper {...props}>
      <PageTitle
        props={props}
        title="sidebar.report"
        className="plr-15"
        breadCrumb={[
          {
            name: "sidebar.dashboard",
            url: "/dashboard"
          },
          {
            name: "sidebar.daily_billing_failures_report"
          }
        ]}
      />
      <div className="user-tbl">
        <DailyBillingFailureTable {...props} />
      </div>
    </PageWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    ...state.auth
  };
};

export default connect(mapStateToProps, null)(DailyBillingFailureReport);
