import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    appointment_date: Yup.date()
      .nullable()
      .required("This field is required"),
    appointment_time: Yup.string()
      .nullable()
      .required("This field is required"),
    appointment_end_date: Yup.date()
      .nullable()
      .required("This field is required"),
    appointment_end_time: Yup.string()
      .nullable()
      .required("This field is required"),
    isAppointmentValid: Yup.boolean(),
    service: Yup.string()
  }),
  mapPropsToValues: props => {
    return {
      id: props.editedData ? props.editedData.id : "",
      appointment_date:
        props.editedData && props.editedData.appointment_date
          ? props.editedData.appointment_date
          : "",
      appointment_time:
        props.editedData && props.editedData.appointment_time
          ? props.editedData.appointment_time
          : "",
      appointment_end_date:
        props.editedData && props.editedData.appointment_end_date
          ? props.editedData.appointment_end_date
          : "",
      appointment_end_time:
        props.editedData && props.editedData.appointment_end_time
          ? props.editedData.appointment_end_time
          : "",
      service:
        props.editedData && props.editedData.service_id
          ? props.editedData.service_id
          : ""
    };
  },
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true,
  isInitialValid: props =>
    props.editedData && props.editedData.action === "edit" ? true : false
});

export default formikEnhancer;
