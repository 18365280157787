import React, { useEffect, useState } from "react";
import moment from "moment";
import CommonModel from "components/common/CommonModel";
import IdleTimeoutModal from "./idleTimeoutModal";
import { connect } from "react-redux";
import { STREAM_API_KEY } from "helper/constant";
import { StreamChat } from "stream-chat";
import authActions from "redux/auth/actions";
import Button from "components/button/Button";
const { logout } = authActions;

const IdleTimeOutHandler = props => {
  const [showModal, setShowModal] = useState(false);
  const [isLogout, setLogout] = useState(false);
  const [viewLogoutInfo, setViewLogoutInfo] = useState(false);

  let timer = undefined;
  const events = ["click", "load", "keydown"];

  const eventHandler = eventType => {
    if (!isLogout) {
      localStorage.setItem("lastInteractionTime", moment());
      if (timer) {
        startTimer();
      }
    }
  };

  useEffect(() => {
    if (props?.isLogin) {
      addEvents();

      return () => {
        removeEvents();
        clearTimeout(timer);
      };
    }
  }, [showModal, props?.isLogin]);

  const startTimer = () => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(
      () => {
        let lastInteractionTime = localStorage.getItem("lastInteractionTime");
        const diff = moment.duration(
          moment().diff(moment(lastInteractionTime))
        );
        let timeOutInterval = props?.timeOutInterval
          ? props?.timeOutInterval
          : 7140000;
        if (isLogout) {
          clearTimeout(timer);
        } else {
          if (diff._milliseconds < timeOutInterval) {
            startTimer();
          } else {
            setShowModal(true);
          }
        }
      },
      props?.timeOutInterval ? props?.timeOutInterval : 7140000
    );
  };

  const addEvents = () => {
    events.forEach(eventName => {
      window.addEventListener(eventName, eventHandler);
    });

    startTimer();
  };

  const removeEvents = () => {
    events.forEach(eventName => {
      window.removeEventListener(eventName, eventHandler);
    });
  };

  const handleContinueSession = () => {
    setShowModal(false);
    setLogout(false);
  };

  const handleLogout = (type = null) => {
    removeEvents();
    clearTimeout(timer);
    setLogout(true);
    setShowModal(false);
    const apiKey = STREAM_API_KEY;
    const chatClient = StreamChat.getInstance(apiKey);
    chatClient.disconnect();
    chatClient.disconnectUser();
    props.logout();
    if (type === "AUTO") setViewLogoutInfo(true);
  };

  return (
    <div>
      <CommonModel
        modal={showModal}
        children={
          <IdleTimeoutModal
            showModal={showModal}
            handleContinue={handleContinueSession}
            handleLogout={handleLogout}
          />
        }
      />
      <CommonModel
        modal={viewLogoutInfo}
        toggle={() => setViewLogoutInfo(prev => !prev)}
        children={
          <div className="text-center" style={{ color: "#58585a" }}>
            <div className="fs-24 font-bold" style={{ color: "#58585a" }}>
              Your Session is expired
            </div>
            <div style={{ paddingBlock: 16, color: "#58585a" }}>
              You have been inactive for a while. For your security, you were
              automatically signed out.
            </div>
            <div>
              <Button
                className="c-btn c-info form-button fs-16 demi-bold-text mr-10"
                style={{ maxWidth: "125px" }}
                type="button"
                onClick={() => setViewLogoutInfo(false)}
              >
                Ok
              </Button>
            </div>
          </div>
        }
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state.auth
  };
};

export default connect(mapStateToProps, { logout })(IdleTimeOutHandler);
