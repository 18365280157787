import EncryptDecrypt from "api/EncryptDecrypt";
import ExternalProviderServices from "api/ExternalProviderServices";
import Loader from "components/common/Loader";
import React, { useEffect, useState } from "react";

const ExternalProviderViewDetails = props => {
  const { hasParam } = props;
  const [providerData, setProviderData] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    ExternalProviderServices.viewProvider({ rd_id: hasParam }).then(res => {
      let data = EncryptDecrypt.decrypt(res.data);
      setProviderData(data.data);
      setLoader(false);
    });
  }, []);

  return (
    <div className="plr-15">
      <div className="roe-card-style mtb-15">
        <div className="roe-card-header module-header">
          <div className="flex-1 fs-16 demi-bold-text">
            <span className="hash"># </span> View Page
          </div>
        </div>
        <div className="roe-card-body">
          {loader ? (
            <Loader loading={loader} />
          ) : (
            <table style={{ width: "100%", lineHeight: "35px" }}>
              <tbody>
                <tr>
                  <td
                    style={{
                      fontWeight: "bolder",
                      textAlign: "left",
                      width: "25%"
                    }}
                  >
                    ID
                  </td>
                  <td>{providerData && providerData?.id}</td>
                </tr>

                <tr>
                  <td
                    style={{
                      fontWeight: "bolder",
                      textAlign: "left",
                      width: "25%"
                    }}
                  >
                    NPI
                  </td>
                  <td>{providerData && providerData?.provider_npi}</td>
                </tr>

                <tr>
                  <td
                    style={{
                      fontWeight: "bolder",
                      textAlign: "left",
                      width: "25%"
                    }}
                  >
                    First Name
                  </td>
                  <td>{providerData && providerData?.first_name}</td>
                </tr>
                <tr>
                  <td
                    style={{
                      fontWeight: "bolder",
                      textAlign: "left",
                      width: "25%"
                    }}
                  >
                    Last Name
                  </td>
                  <td>{providerData && providerData?.last_name}</td>
                </tr>

                <tr>
                  <td
                    style={{
                      fontWeight: "bolder",
                      textAlign: "left",
                      width: "25%"
                    }}
                  >
                    Credential
                  </td>
                  <td>{providerData && providerData?.credential}</td>
                </tr>
                <tr>
                  <td
                    style={{
                      fontWeight: "bolder",
                      textAlign: "left",
                      width: "25%"
                    }}
                  >
                    Address
                  </td>
                  <td>{providerData && providerData?.address}</td>
                </tr>

                <tr>
                  <td
                    style={{
                      fontWeight: "bolder",
                      textAlign: "left",
                      width: "25%"
                    }}
                  >
                    City
                  </td>
                  <td>{providerData && providerData?.city}</td>
                </tr>
                <tr>
                  <td
                    style={{
                      fontWeight: "bolder",
                      textAlign: "left",
                      width: "25%"
                    }}
                  >
                    ZIP
                  </td>
                  <td>{providerData && providerData?.zip}</td>
                </tr>
                <tr>
                  <td
                    style={{
                      fontWeight: "bolder",
                      textAlign: "left",
                      width: "25%"
                    }}
                  >
                    Category
                  </td>
                  <td>{providerData && providerData?.taxonomy}</td>
                </tr>

                <tr>
                  <td
                    style={{
                      fontWeight: "bolder",
                      textAlign: "left",
                      width: "25%"
                    }}
                  >
                    Status
                  </td>
                  <td>{providerData && providerData?.status}</td>
                </tr>

                <tr>
                  <td
                    style={{
                      fontWeight: "bolder",
                      textAlign: "left",
                      width: "25%"
                    }}
                  >
                    Phone number
                  </td>
                  <td>
                    {providerData &&
                      providerData?.phone_number
                        ?.toString()
                        .replace(/\D+/g, "")
                        .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")}
                  </td>
                </tr>

                <tr>
                  <td
                    style={{
                      fontWeight: "bolder",
                      textAlign: "left",
                      width: "25%"
                    }}
                  >
                    Fax number
                  </td>

                  <td>
                    {providerData &&
                      providerData?.fax_number
                        ?.toString()
                        ?.replace(/\D+/g, "")
                        .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")}
                  </td>
                </tr>

                <tr>
                  <td
                    style={{
                      fontWeight: "bolder",
                      textAlign: "left",
                      width: "25%"
                    }}
                  >
                    Other Phone number
                  </td>
                  <td>
                    {providerData &&
                      providerData?.other_phone_number
                        ?.toString()
                        .replace(/\D+/g, "")
                        .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")}
                  </td>
                </tr>

                <tr>
                  <td
                    style={{
                      fontWeight: "bolder",
                      textAlign: "left",
                      width: "25%"
                    }}
                  >
                    Other Fax number
                  </td>
                  <td>
                    {providerData &&
                      providerData?.other_fax_number
                        ?.toString()
                        .replace(/\D+/g, "")
                        .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")}
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExternalProviderViewDetails;
