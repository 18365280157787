import React, { useRef, useEffect, useState } from "react";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import RdServices from "../../api/RdServices";
import Button from "../button/Button";
import Toaster from "../common/Toaster";
import DatePicker from "react-datepicker";
import moment from "moment";
import enhancer from "./insurance-validator";
import { compose } from "redux";
import { hasAccess } from "util/sidebar";
import { MODULE_NAMES, PERMISSION_TYPE } from "helper/constant";

const InsuranceTab = props => {
  const hasParam = props.match.params.id;

  const {
    setFieldValue,
    values,
    onSubmit,
    action,
    insuranceData,
    insuranceList,
    permissions
  } = props;

  const toaster = useRef();
  const [selectedInput, setSelectedInput] = React.useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSelectedInput(insuranceData);
    if (insuranceData) {
      let insuranceData1 = insuranceData.map(value => value.insurance_id);
      setSelectedInput(insuranceData1);
    }
    setFieldValue("insurance", insuranceData);
  }, [insuranceData]);

  const checkHandleChange = async e => {
    const targtValue = parseInt(e.currentTarget.value);
    setSelectedInput(prev =>
      prev.some(data => data === targtValue)
        ? prev.filter(data => data !== targtValue)
        : [...prev, targtValue]
    );

    let objIndex = values.insurance.findIndex(
      obj => obj.insurance_id === targtValue
    );
    if (objIndex > -1) {
      values.insurance.splice(objIndex, 1);
      setFieldValue("insurance", values.insurance);
    } else {
      values.insurance.push({
        insurance_id: targtValue,
        effective_date: new Date()
      });
    }
  };

  const handlerEffectiveDate = (e, id) => {
    let objIndex = values.insurance.findIndex(obj => obj.insurance_id === id);
    values.insurance[objIndex].effective_date = new Date(e);
    setFieldValue("insurance", values.insurance);
  };

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;
    // if (isValid) {
    if (selectedInput.length !== 0) {
      const params = {
        admin_id: hasParam,
        insurance: values.insurance
      };
      setLoading(true);
      RdServices.editInsurance(params)
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          if (data.status) {
            if (toaster.current) toaster.current.success(data.message);
            onSubmit(values, action, "5");
            props.history.push(`${props.match.params.id}`);
          } else {
            if (toaster.current) toaster.current.error(data.message);
          }
          setLoading(false);
        })
        .catch(err => {
          if (toaster.current) toaster.current.error(err.response);
          setLoading(false);
        });
      // }
      if (toaster.current && props.errors.insurance !== undefined) {
        toaster.current.error("Please enter valid date");
      }
    }
    handleSubmit();
  };

  return (
    <div>
      <form>
        <div className="form-group col-md-12">
          <br />
          {insuranceList &&
            insuranceList.map((type, i) => {
              return (
                <div key={i}>
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td style={{ width: "25%" }}>
                          <input
                            type="checkbox"
                            name={`other_insurance_id${type.id}`}
                            onChange={checkHandleChange}
                            value={type.id}
                            checked={
                              selectedInput && selectedInput.includes(type.id)
                                ? "checked"
                                : false
                            }
                          />{" "}
                          {type?.name}({type?.is_active ? "Active" : "Inactive"}
                          )
                        </td>
                        <td
                          className={
                            selectedInput && selectedInput.includes(type.id)
                              ? ""
                              : "d-none"
                          }
                        >
                          <label className="fs-16 medium-text pr-2">
                            Effective Date
                          </label>
                          <DatePicker
                            className="form-control"
                            selected={
                              values.insurance &&
                              values.insurance[
                                values.insurance.findIndex(
                                  obj => obj.insurance_id === type.id
                                )
                              ] &&
                              values.insurance[
                                values.insurance.findIndex(
                                  obj => obj.insurance_id === type.id
                                )
                              ].effective_date &&
                              new Date(
                                moment(
                                  values.insurance[
                                    values.insurance.findIndex(
                                      obj => obj.insurance_id === type.id
                                    )
                                  ].effective_date
                                )
                              )
                            }
                            id={`effective_date${type.id}`}
                            data-id={type.id}
                            onChange={e => {
                              handlerEffectiveDate(e, type.id);
                            }}
                            dateFormat="MM/dd/yyyy"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              );
            })}
        </div>
        {props?.location?.state?.step === "5" ? (
          <div>
            <Button
              type="button"
              loading={loading}
              disabled={loading}
              className="c-btn c-info form-button fs-16 demi-bold-text mr-2 max-w-180"
              onClick={handleSubmit}
              dataStyle="expand-right"
            >
              Save and Continue
            </Button>
            <Button
              type="button"
              className="c-btn c-info form-button fs-16 demi-bold-text max-w-125"
              onClick={() => {
                props.history.push(`${props.match.params.id}`);
              }}
            >
              Skip
            </Button>
          </div>
        ) : (
          <div>
            {hasAccess(
              MODULE_NAMES.PROVIDER_MANAGEMENT_INSURANCE_CARRIERS,
              PERMISSION_TYPE.ADD,
              permissions
            ) && (
              <Button
                type="button"
                loading={loading}
                disabled={loading}
                className="c-btn c-info form-button fs-16 demi-bold-text"
                style={{ maxWidth: "125px" }}
                onClick={() => handleSubmit()}
                dataStyle="expand-right"
              >
                Submit
              </Button>
            )}
          </div>
        )}
      </form>
      <Toaster ref={toaster} />
    </div>
  );
};

export default compose(enhancer)(InsuranceTab);
