import React, { useRef, useEffect, useState } from "react";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import RdServices from "../../api/RdServices";
import Button from "../button/Button";
import Toaster from "../common/Toaster";
import DatePicker from "react-datepicker";
import moment from "moment";
import enhancer from "./insurance-validator";
import { compose } from "redux";
import { hasAccess } from "util/sidebar";
import { MODULE_NAMES, PERMISSION_TYPE } from "helper/constant";

const CategoryTab = props => {
  const toaster = useRef();
  const [selectedInput, setSelectedInput] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const hasParam = props.match.params.id || props?.id;
  const {
    setFieldValue,
    values,
    onSubmit,
    action,
    insuranceData,
    insuranceList,
    permissions,
    editedData
  } = props;

  useEffect(() => {
    if (insuranceData) {
      let insuranceData1 = insuranceData.map(value => value.category_id);
      setSelectedInput(insuranceData1);
    }
    setFieldValue("insurance", insuranceData);
  }, [insuranceData]);

  useEffect(() => {
    if (
      insuranceList?.length &&
      editedData?.rd_detail?.type === "employee" &&
      props.location?.state?.step == 3 &&
      editedData?.rd_detail?.service_id === 1
    ) {
      let insuranceData2 = [];
      insuranceList.forEach(value => insuranceData2.push(value?.id));
      setSelectedInput(insuranceData2);
      setFieldValue("insurance", insuranceData2);
    }
  }, [insuranceList, editedData?.rd_detail?.type, props.location?.state?.step]);

  const checkHandleChange = async e => {
    const targtValue = parseInt(e.currentTarget.value);

    setSelectedInput(prev =>
      prev.some(data => data === targtValue)
        ? prev.filter(data => data !== targtValue)
        : [...prev, targtValue]
    );

    const categories = [...values.insurance];
    let objIndex = categories.findIndex(obj => obj.category_id === targtValue);

    if (objIndex !== -1) {
      categories.splice(objIndex, 1);
    } else {
      categories.push({
        category_id: targtValue
      });
    }
    setFieldValue("insurance", categories);
  };

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;
    // if (selectedInput.length !== 0) {
    const params = {
      admin_id: hasParam,
      categories: selectedInput
    };
    setLoading(true);
    RdServices.addCategory(params)
      .then(res => {
        setLoading(false);
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (props?.location?.state?.step === "3") {
            props.history.push(`${props?.match?.params?.id}`, {
              step: "4"
            });
          } else {
            if (toaster.current) toaster.current.success(data.message);
            setTimeout(() => {
              onSubmit(values, action, "4");
            }, 500);
          }
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });
    // }
    handleSubmit();
  };

  return (
    <div>
      <form>
        <div className="form-group col-md-12">
          <br />
          {!insuranceList && <div>Loading...</div>}
          {insuranceList &&
            insuranceList.length > 0 &&
            insuranceList.map((type, i) => {
              return (
                <div key={i}>
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td style={{ width: "25%" }}>
                          <input
                            type="checkbox"
                            name={`other_insurance_id${type.id}`}
                            onChange={checkHandleChange}
                            value={type.id}
                            checked={
                              selectedInput && selectedInput.includes(type.id)
                                ? "checked"
                                : false
                            }
                          />{" "}
                          {type.name}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              );
            })}
        </div>
        {props?.location?.state?.step === "3" ? (
          <div>
            <Button
              loading={loading}
              disabled={loading}
              type="button"
              className="c-btn c-info form-button fs-16 demi-bold-text mr-2"
              style={{ maxWidth: "180px" }}
              onClick={() => handleSubmit()}
              dataStyle="expand-right"
            >
              Save and Continue
            </Button>
            <Button
              type="button"
              className="c-btn c-info form-button fs-16 demi-bold-text "
              style={{ maxWidth: "125px" }}
              onClick={() =>
                props.history.push(`${props?.match?.params?.id}`, {
                  step: "4"
                })
              }
            >
              Skip
            </Button>
          </div>
        ) : (
          <div>
            {hasAccess(
              MODULE_NAMES.PROVIDER_MANAGEMENT_CATEGORIES,
              PERMISSION_TYPE.ADD,
              permissions
            ) ? (
              <Button
                type="button"
                loading={loading}
                disabled={loading}
                className="c-btn c-info form-button fs-16 demi-bold-text"
                style={{ maxWidth: "125px" }}
                onClick={() => handleSubmit()}
                dataStyle="expand-right"
              >
                Submit
              </Button>
            ) : (
              ""
            )}
          </div>
        )}
      </form>
      <Toaster ref={toaster} />
    </div>
  );
};

export default compose(enhancer)(CategoryTab);
