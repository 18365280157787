import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: props => {
    return Yup.object().shape({
      eventName:
        props?.editedData?.type !== "main_url" &&
        Yup.string()
          .required("This field is required")
          .nullable(),
      location:
        props?.editedData?.type !== "main_url" &&
        Yup.string()
          .required("This field is required")
          .nullable(),
      url:
        props?.editedData?.type === "main_url" &&
        Yup.string()
          .url("Enter valid URL")
          .required("This field is required")
          .max(255, "Calendly Link must be less than 255 characters")
          .nullable()
    });
  },
  mapPropsToValues: props => ({
    id: props.editedData
      ? props && props.editedData && props.editedData.id
      : "",
    eventName: props.editedData ? props?.editedData?.event_name : "",
    location: props.editedData ? props?.editedData?.location : "",
    url: props.editedData ? props?.editedData?.url : ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
