import styled from "styled-components";

const PageWrapper = styled.div`
  .ReactTable .rt-thead .rt-tr {
    text-align: left;
  }

  .ReactTable .rt-thead .rt-tr .rt-th {
    padding: 15px 5px !important;
  }

  .module-header {
    display: flex;
    align-items: center;
  }

  .custom-react-table-theme-class {
    .rt-tbody {
      .rt-td {
        font-family: "muli-medium";
        color: #757575;
      }
    }
  }

  .Table__itemCount {
    font-size: 14px;
  }

  .Table__pagination {
    display: flex;
    justify-content: flex-end;
    padding: 20px 10px 0px;
  }

  .Table__pageButton {
    font-size: 18px;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: #757575 !important;
    margin: 0 5px;
  }

  .Table__pageButton:disabled {
    cursor: not-allowed;
    color: gray;
  }

  .Table__pageButton--active {
    font-weight: bold;
    background: #58585a !important;
    color: #fff !important;
    min-width: 30px;
    height: 30px;
    border-radius: 6px;
  }

  .tabl-search {
    &:focus {
      outline: 0;
    }
  }

  .back-icon {
    position: absolute;
    right: 30px;
    bottom: 27px;
    color: rgba(255, 255, 255, 0.85) !important;
  }

  .-sort-desc {
    box-shadow: none !important;
    &:before {
      content: "▼";
      float: right;
      margin-right: 14px;
      color: #58585a !important;
    }
  }

  .-sort-asc {
    box-shadow: none !important;
    &:before {
      content: "▲";
      float: right;
      margin-right: 14px;
      color: #58585a !important;
    }
  }
  .react-action-class {
    button {
      height: auto !important;
      width: auto !important;
    }
  }

  .tbl-loader {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    .lds-ring {
      div {
        width: 8px !important;
        height: 45px !important;
        margin: 0px !important;
        border-color: #5c258d transparent transparent transparent !important;
      }
    }
  }

  .ReactTags__tags {
    position: relative;
  }

  /* Styles for the input */
  .ReactTags__tagInput {
    width: 100%;
    border-radius: 2px;
    display: inline-block;
    margin-top: 6px;
  }
  .ReactTags__tagInput input.ReactTags__tagInputField,
  .ReactTags__tagInput input.ReactTags__tagInputField:focus {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  /* Styles for selected tags */
  .ReactTags__selected span.ReactTags__tag {
    border: 1px solid #ddd;
    background: #aece38;
    color: white;
    font-size: 12px;
    display: inline-block;
    padding: 5px;
    margin: 0 5px;
    border-radius: 10px;
  }
  .ReactTags__selected a.ReactTags__remove {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
  }

  /* Styles for suggestions */
  .ReactTags__suggestions {
    position: absolute;
    z-index: 100;
  }
  .ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    background: white;
    width: 100%;
  }
  .ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
  }
  .ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
  }
  .ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
  }

  .ReactTags__remove {
    border: none;
    cursor: pointer;
    background: none;
    color: white;
  }

  .save-draft-color {
    color: #fff !important;
    background-color: #f0af42 !important;
    border-color: #f0af42 !important;
  }

  .submit-approval-color {
    color: #fff !important;
    background-color: #209092 !important;
    border-color: #209092 !important;
  }

  .publish-color {
    color: #fff !important;
    background-color: #afd135 !important;
    border-color: #afd135 !important;
  }

  .unpublish-color {
    color: #fff !important;
    background-color: #e06226 !important;
    border-color: #e06226 !important;
  }

  .c-btn.disabled,
  .c-btn:disabled {
    color: #fff !important;
    background-color: #58585a !important;
    border-color: #58585a !important;
  }
`;

export default PageWrapper;
