import React, { useRef, useState, useEffect, Fragment } from "react";
import { compose } from "redux";
import enhancer from "./validator";
import Button from "../button/Button";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import GroupServices from "../../api/GroupServices";
import Toaster from "../common/Toaster";
import DatePicker from "react-datepicker";
import { date } from "yup";
import { Input } from "reactstrap";
import CategoryServices from "api/CategoryServices";

const GroupForm = props => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    setFieldValue,
    onSubmit,
    values,
    action
  } = props;
  const [serviceList, setServiceList] = useState([]);

  useEffect(() => {
    CategoryServices.serviceDropdown({ is_general: true })
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setServiceList([...data.data]);
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      });
  }, []);

  useEffect(() => {
    if (props?.authData?.adminRoleId > 2) {
      setFieldValue("service", props?.authData?.service_id);
    }
  }, [props?.authData]);

  const handleServiceChange = e => {
    setFieldValue("service", e.target.value);
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };
  const toaster = useRef();

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;

    if (isValid) {
      onSubmit(values, action);
      if (action === "add") {
        const params = {
          name: values.name,
          type: values.type,
          payment_method: values.payment_method,
          launch_date: values.launch_date,
          auto_renew: values.auto_renew,
          universal_login: values.universal_login,
          service_id: values?.service
        };
        GroupServices.add(params)
          .then(res => {
            let data = EncryptDecrypt.decrypt(res.data);
            if (data.status) {
              if (toaster.current) toaster.current.success(data.message);
            } else {
              if (toaster.current) toaster.current.error(data.message);
            }
          })
          .catch(err => {
            if (toaster.current) toaster.current.error(err.response);
          });
      } else if (action === "edit") {
        const params = {
          id: values.id,
          name: values.name,
          type: values.type,
          payment_method: values.payment_method,
          launch_date: values.launch_date,
          auto_renew: values.auto_renew,
          universal_login: values.universal_login,
          service_id: values?.service
        };
        GroupServices.edit(params)
          .then(res => {
            let data = EncryptDecrypt.decrypt(res.data);
            if (data.status) {
              if (toaster.current) toaster.current.success(data.message);
            } else {
              if (toaster.current) toaster.current.error(data.message);
            }
          })
          .catch(err => {
            if (toaster.current) toaster.current.error(err.response);
          });
      }
    }

    handleSubmit();
  };

  return (
    <div>
      <form>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Name <span className="asterisk">*</span>
          </label>
          <input
            type="text"
            className="form-control react-form-input"
            id="name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            placeholder="Add Group Name"
          />
          <Error field="name" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Service<span className="asterisk">*</span>
          </label>
          <Input
            type="select"
            name="service"
            id="service"
            onChange={e => handleServiceChange(e)}
            value={values?.service}
            onBlur={handleBlur}
            disabled={props?.authData?.adminRoleId > 2}
          >
            <option value="">Select Service</option>
            {serviceList.map((service, i) => {
              return (
                <Fragment>
                  <option key={`option${i}`} value={service.id}>
                    {service.name}
                  </option>
                </Fragment>
              );
            })}
          </Input>
          <Error field="service" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Type <span className="asterisk">*</span>
          </label>
          <div className="col-sm-10">
            <div className="form-check form-check-inline mt-7">
              <input
                className="form-check-input"
                type="radio"
                name="type"
                id="employer-type"
                value="Employer"
                onChange={handleChange}
                checked={values.type === "Employer" ? "checked" : false}
              />
              <label className="form-check-label">Employer</label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="type"
                id="individual-type"
                value="Individual"
                onChange={handleChange}
                checked={values.type === "Individual" ? "checked" : false}
              />
              <label className="form-check-label">Individual</label>
            </div>
          </div>
          <Error field="type" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Payment Method <span className="asterisk">*</span>
          </label>
          <div className="col-sm-10">
            <div className="form-check form-check-inline mt-7">
              <input
                className="form-check-input"
                type="radio"
                name="payment_method"
                id="insurance-funded"
                value="Insurance Funded"
                onChange={handleChange}
                checked={
                  values.payment_method === "Insurance Funded"
                    ? "checked"
                    : false
                }
              />
              <label className="form-check-label">Insurance Funded</label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="payment_method"
                id="group-funded"
                value="Group Funded"
                onChange={handleChange}
                checked={
                  values.payment_method === "Group Funded" ? "checked" : false
                }
              />
              <label className="form-check-label">Group Funded</label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="payment_method"
                id="client-funded"
                value="Client Funded"
                onChange={handleChange}
                checked={
                  values.payment_method === "Client Funded" ? "checked" : false
                }
              />
              <label className="form-check-label">Client Funded</label>
            </div>
          </div>
          <Error field="payment_method" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Launch Date</label>
          <div>
            <DatePicker
              className="form-control"
              selected={values.launch_date}
              id="launch_date"
              onChange={e => {
                setFieldValue("launch_date", e);
              }}
              dateFormat="MM/dd/yyyy"
              placeholder="Add Launch Date"
            />
          </div>
          <Error field="launch_date" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Universal Login <span className="asterisk">*</span>
          </label>
          <div className="col-sm-10">
            <div className="form-check form-check-inline mt-7">
              <input
                className="form-check-input"
                type="radio"
                name="universal_login"
                id="universal_login_yes"
                value="1"
                onChange={handleChange}
                checked={+values.universal_login === 1 ? "checked" : false}
              />
              <label className="form-check-label">Enable</label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="universal_login"
                id="universal_login_no"
                value="0"
                onChange={handleChange}
                checked={+values.universal_login === 0 ? "checked" : false}
              />
              <label className="form-check-label">Disable</label>
            </div>
          </div>
          <Error field="universal_login" />
        </div>
        <div>
          <Button
            type="button"
            className="c-btn c-info form-button fs-16 demi-bold-text"
            style={{ maxWidth: "125px" }}
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </div>
      </form>
      <Toaster ref={toaster} />
    </div>
  );
};

export default compose(enhancer)(GroupForm);
