import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .trim()
      .required("This field is required")
      .max(100, "Name must be less than 100 characters"),
    tin: Yup.string()
      .matches(/^[0-9]+$/i, "Must contain numbers only")
      .nullable(),
    npi: Yup.string()
      .matches(/^[0-9]+$/i, "Must contain numbers only")
      .nullable()
      .min(10, "Must be exactly 10 digits")
      .max(10, "Must be exactly 10 digits"),
    officeKey: Yup.string()
      .matches(/^[0-9]+$/i, "Must contain numbers only")
      .nullable(),
    service: Yup.string().required("This field is required"),
    type: Yup.string()
      .nullable()
      .required("This field is required")
  }),
  mapPropsToValues: props => ({
    action: props.action,
    id: props.editedData
      ? props && props.editedData && props.editedData.id
      : "",
    name: props.editedData ? props?.editedData?.practice_name : "",
    tin: props.editedData ? props?.editedData?.practice_tin : "",
    npi: props.editedData ? props?.editedData?.practice_npi : "",
    officeKey: props.editedData
      ? props?.editedData?.advancemd_setting?.advancemd_office_code
      : "",
    status: props.editedData && props?.editedData?.status,
    id: props.editedData && props?.editedData?.id,
    service: props.editedData ? props?.editedData?.service?.id : "",
    type: props.editedData && props?.editedData?.type
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
