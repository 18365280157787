import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import ServiceMangementServices from "api/ServiceManagementServices";
import Loader from "../common/Loader";
import Toaster from "../common/Toaster";
import { MODULE_NAMES, PERMISSION_TYPE, TABLE } from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import { hasAccess } from "util/sidebar";

const ServiceTable = props => {
  const { permissions } = props;
  const [tblData, settblData] = useState();
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("id");
  const [sortBy, setSortBy] = useState("ASC");
  const toaster = useRef();

  useEffect(() => {
    getList(field, sortBy, searchText, activePage + 1);
    setPages(10);
  }, []);

  const activeInactiveStatusHandler = oData => {
    const params = {
      is_active: !oData.is_active,
      service_id: oData.id
    };
    setLoader(true);
    ServiceMangementServices.status(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current)
            toaster.current.success(
              oData.is_active === true
                ? "Service has been deactivated successfully."
                : "Service has been activated successfully."
            );
          getList(field, sortBy, searchText, activePage + 1);
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
          setLoader(false);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
        setLoader(false);
      });
  };

  const columns = [
    {
      Header: "ID",
      accessor: "id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 100,
      sortable: true
    },
    {
      Header: "Name",
      accessor: "name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 400,
      sortable: true
    },

    {
      Header: "Status",
      accessor: "is_active",
      className: "",
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      filterable: false,
      width: 200,
      Cell: props => (
        <div className="react-action-class">
          {props.original.is_active ? (
            <div id={`active-${props.original.id}`}>
              <Button
                style={{ minWidth: "125px" }}
                className="c-btn c-info mr-10"
                onClick={() => activeInactiveStatusHandler(props.original)}
                disabled={
                  !hasAccess(
                    MODULE_NAMES.SERVICE,
                    PERMISSION_TYPE.STATUS,
                    permissions
                  )
                }
              >
                <div className="fs-14 medium-text">
                  <i className="fas fa-toggle-off mr-6" /> Active
                </div>
              </Button>
            </div>
          ) : (
            <div id={`deactive-${props.original.id}`}>
              <Button
                style={{ minWidth: "125px" }}
                className="c-btn c-warning mr-10"
                onClick={() => activeInactiveStatusHandler(props.original)}
                disabled={
                  !hasAccess(
                    MODULE_NAMES.SERVICE,
                    PERMISSION_TYPE.STATUS,
                    permissions
                  )
                }
              >
                <div className="fs-14 medium-text">
                  <i className="fas fa-toggle-on mr-6" /> Inactive
                </div>
              </Button>
            </div>
          )}
        </div>
      ) // Custom cell components!
    },

    {
      Header: "Action",
      accessor: "action",
      className: "",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 100,
      Cell: props => (
        <div className="react-action-class">
          {hasAccess(
            MODULE_NAMES.SERVICE,
            PERMISSION_TYPE.EDIT,
            permissions
          ) ? (
            <Button
              className="c-btn c-success mr-10"
              onClick={() => formAction("edit", props.original)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-edit" />
              </div>
            </Button>
          ) : null}
        </div>
      ) // Custom cell components!
    }
  ];

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  const formAction = (action, data = null) => {
    // Here you can view the data and make forward action for edit data
    if (action === "add") {
      props.history.push("/service/add");
    } else if (action === "edit") {
      props.history.push(`/service/edit/${data.id}`, { ...data });
    } else if (action === "view") {
      props.history.push(`/service/view/${data.id}`, { ...data });
    }
  };

  function getList(
    field = "created_at",
    sortBy = "DESC",
    search = "",
    page = "1"
  ) {
    const params = {
      sort_by: field,
      order: sortBy,
      search: search,
      page: page
    };
    setLoader(true);

    ServiceMangementServices.list(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count / TABLE.LIMIT));
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });
  }

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Service List
            </div>
            {hasAccess(
              MODULE_NAMES.SERVICE,
              PERMISSION_TYPE.ADD,
              permissions
            ) ? (
              <div>
                <Button
                  className="c-btn c-primary ma-5"
                  onClick={() => formAction("add")}
                >
                  <i className="fas fa-plus mr-10" />
                  Add Service
                </Button>
              </div>
            ) : null}
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              LoadingComponent={Loader}
              onFetchData={onChangePageGetData}
              onPageChange={pageIndex => {
                setActivePage(pageIndex);
                getList(field, sortBy, searchText, pageIndex + 1);
              }}
              onSortedChange={sortProperties => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getList(sortProperties[0].id, sort, searchText, 1);
              }}
            />
          </div>
        </div>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};

export default withRouter(ServiceTable);
