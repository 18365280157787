import React from "react";
import PageWrapper from "./ScaleProgram.style";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";
import ScaleProgramTable from "../../components/scaleProgram/scaleProgramTable";

const ScaleProgram = props => {
  return (
    <PageWrapper {...props}>
      <PageTitle
        props={props}
        title="sidebar.scale_program"
        className="plr-15"
        breadCrumb={[
          {
            name: "sidebar.dashboard",
            url: "/dashboard"
          },
          {
            name: "sidebar.scale_program"
          }
        ]}
      />
      <div className="user-tbl">
        <ScaleProgramTable {...props} />
      </div>
    </PageWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    ...state.permissionReducer,
    authData: {
      token: state.auth.token,
      isLogin: state.auth.isLogin,
      profileImage: state.auth.profile_image,
      adminRoleId: state.auth.admin_role_id,
      adminRole: state.auth.admin_role,
      full_name: state.auth.first_name + " " + state.auth.last_name
    }
  };
};

export default connect(mapStateToProps, null)(ScaleProgram);
