import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { compose } from "redux";
import enhancer from "./add-validator";
import Button from "../button/Button";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import RdServices from "../../api/RdServices";
import Toaster from "../common/Toaster";
import { Input } from "reactstrap";
import CategoryServices from "api/CategoryServices";
import Select from "react-select";
import PracticeMangementServices from "api/PracticeManagementServices";

const PROVIDER_TYPES = [
  {
    name: "Employee",
    value: "employee"
  },
  {
    name: "IBO",
    value: "ibo"
  },
  {
    name: "IBO Practice Provider",
    value: "ibo practice"
  }
];

const RdForm = props => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    onSubmit,
    values,
    action,
    stateList,
    serviceLocationList,
    languageList,
    timezoneList,
    setFieldValue,
    setServiceLocationList
  } = props;
  const toaster = useRef(null);
  const [serviceList, setServiceList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [practiceList, setPracticeList] = useState([]);
  const [practiceDisabled, setPracticeDisabled] = useState(false);

  useEffect(() => {
    if (props?.auth?.admin_role_id > 2) {
      setFieldValue("service", props?.auth?.service_id);
    }
  }, [props?.auth]);

  useEffect(() => {
    if (props?.auth?.admin_role_id === 6) {
      setFieldValue("practice", props?.auth?.practice);
    } else if (
      values?.type === "ibo practice" &&
      props?.auth?.admin_role_id !== 6
    ) {
      setPracticeDisabled(true);
      PracticeMangementServices.list({
        get_all: true,
        service_id: values?.service ? values?.service : undefined
      })
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          if (data?.status) {
            setPracticeList([...data?.data]);
          } else {
            if (toaster.current)
              toaster.current.error("Could not fetch practices!");
          }
          setPracticeDisabled(false);
        })
        .catch(err => {
          if (toaster.current)
            toaster.current.error("Could not fetch practices!");
          setPracticeDisabled(false);
        });
    } else {
      setFieldValue("practice", "");
    }
  }, [values?.type, props?.auth?.admin_role_id, values?.service]);

  useEffect(() => {
    CategoryServices.serviceDropdown()
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setServiceList([...data.data]);
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      });
  }, []);

  useEffect(() => {
    if (values?.type || values?.service) {
      const params = {
        type: values?.type ? values?.type : undefined,
        service_id: values?.service ? values?.service : undefined
      };
      setFieldValue("service_location_id", null);
      RdServices.serviceLocationList(params).then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        setServiceLocationList(data.data);
        setFieldValue("service_location_id", null);
      });
    }
  }, [values?.type, values?.service]);

  useEffect(() => {
    if (props?.auth?.admin_role_id === 6) {
      setFieldValue("type", "ibo practice");
    }
  }, [props?.auth?.admin_role_id]);

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const changeFormat = e => {
    var x = e.target.value
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    let no = "(" + x[1] + ") " + x[2] + "-" + x[3];
    setFieldValue("phone_number", no);
  };

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;
    const serviceLocationId = [];
    if (
      values &&
      values?.service_location_id &&
      values?.service_location_id?.length
    ) {
      values.service_location_id.forEach(el => {
        serviceLocationId.push(Number(el.value));
      });
    }

    if (isValid) {
      setLoading(true);
      const params = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        service_location: serviceLocationId.length ? serviceLocationId : [],
        advancemd_provider_id: values.advancemd_provider_id,
        provider_npi: values.provider_npi,
        phone_number: values.phone_number.replace(/[()-\s]/g, ""),
        address: values.address,
        city: values.city,
        state_id: values.state_id,
        zip: values.zip,
        timezone_id: values.timezone_id,
        language_id: values.language_id,
        type: values.type,
        service_id: Number(values.service),
        practice_id:
          props?.auth?.admin_role_id === 6 && props?.auth?.practice
            ? props?.auth?.practice
            : !!values?.practice
            ? values?.practice
            : undefined
      };
      RdServices.add(params)
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          if (data.status) {
            if (toaster.current) toaster.current.success(data.message);
            onSubmit(values, { actionFlow: "add" }, data.data);
            setLoading(false);
          } else {
            if (toaster.current) toaster.current.error(data.message);
            setLoading(false);
          }
        })
        .catch(err => {
          if (toaster.current) toaster.current.error(err.message);
          setLoading(false);
        });
    }
    handleSubmit();
  };

  const serviceLocationOptions = useMemo(() => {
    const updatedServiceLocation = [];
    if (serviceLocationList.length) {
      serviceLocationList.forEach(el =>
        updatedServiceLocation.push({
          label: el?.name,
          value: el?.id
        })
      );
    }
    return updatedServiceLocation;
  }, [serviceLocationList]);

  return (
    <div>
      <form>
        <div className="row">
          <div className="col-md-6 form-group">
            <label className="fs-16 medium-text">
              First Name <span className="asterisk">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="first_name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              placeholder="Add First Name"
            />
            <Error field="first_name" />
          </div>
          <div className="col-md-6 form-group">
            <label className="fs-16 medium-text">
              Last Name <span className="asterisk">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="last_name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              placeholder="Add Last Name"
            />
            <Error field="last_name" />
          </div>
          <div className="col-md-6 form-group">
            <label className="fs-16 medium-text">
              Email <span className="asterisk">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              placeholder="Add Email"
            />
            <Error field="email" />
          </div>
          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">
              Service <span className="asterisk"> *</span>
            </label>
            <Input
              type="select"
              name="service"
              id="service"
              onChange={handleChange}
              value={values.service}
              onBlur={handleBlur}
              disabled={props?.auth?.admin_role_id > 2}
            >
              <option value="">Select Service</option>
              {serviceList.map((service, i) => {
                return (
                  <Fragment>
                    <option key={`option${i}`} value={service.id}>
                      {service.name}
                    </option>
                  </Fragment>
                );
              })}
            </Input>
            <Error field="service" />
          </div>
          <div className="col-md-6 form-group">
            <label className="fs-16 medium-text">
              Type<span className="asterisk">*</span>
            </label>
            <Input
              type="select"
              name=""
              id="type"
              onChange={handleChange}
              value={values.type}
              onBlur={handleBlur}
              disabled={props?.auth?.admin_role_id === 6}
            >
              <option value="">Select Type</option>
              {PROVIDER_TYPES.map((type, i) => {
                return (
                  <Fragment>
                    <option key={`option${i}`} value={type.value}>
                      {type.name}
                    </option>
                  </Fragment>
                );
              })}
            </Input>
            <Error field="type" />
          </div>
          {values?.type === "ibo practice" && props?.auth?.admin_role_id !== 6 && (
            <div className="form-group col-md-6">
              <label className="fs-16 medium-text">
                Practice<span className="asterisk">*</span>
              </label>
              <Input
                type="select"
                name=""
                id="practice"
                onChange={handleChange}
                value={values.practice}
                onBlur={handleBlur}
                disabled={!!practiceDisabled}
              >
                <option value="">Select Practice</option>
                {practiceList.map((type, i) => {
                  return (
                    <Fragment>
                      <option key={`option${i}`} value={type.id}>
                        {type.practice_name}
                      </option>
                    </Fragment>
                  );
                })}
              </Input>
              <Error field="practice" />
            </div>
          )}
          <div className="col-md-6 form-group">
            <label className="fs-16 medium-text">Service Location</label>
            <Select
              options={serviceLocationOptions}
              isMulti
              onChange={val => setFieldValue("service_location_id", val)}
              placeholder={"Select Service Location"}
              value={values?.service_location_id}
              isClearable
              onBlur={handleBlur}
            />
            <Error field="service_location_id" />
          </div>
          <div className="col-md-6 form-group">
            <label className="fs-16 medium-text">
              AdvancedMD Provider ID <span className="asterisk">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="advancemd_provider_id"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.advancemd_provider_id}
              placeholder="Add AdvanceMD Provider ID"
            />
            <Error field="advancemd_provider_id" />
          </div>
          <div className="col-md-6 form-group">
            <label className="fs-16 medium-text">Provider NPI</label>
            <input
              type="text"
              className="form-control react-form-input"
              id="provider_npi"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.provider_npi}
              placeholder="Add Provider NPI"
            />
            <Error field="provider_npi" />
          </div>
          <div className="col-md-6 form-group">
            <label className="fs-16 medium-text">Phone Number</label>
            <input
              type="text"
              className="form-control react-form-input"
              id="phone_number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phone_number}
              placeholder="Add Phone Number"
              onKeyUp={value => changeFormat(value)}
            />
            <Error field="phone_number" />
          </div>

          <div className="col-md-12">
            <label className="fs-18 medium-text">Home Address</label>
            <div className="row">
              <div className="col-md-6 form-group">
                <label className="fs-16 medium-text">Address</label>
                <input
                  type="text"
                  className="form-control react-form-input"
                  id="address"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address}
                  placeholder="Add Address"
                />
                <Error field="address" />
              </div>
              <div className="col-md-6 form-group">
                <label className="fs-16 medium-text">City</label>
                <input
                  type="text"
                  className="form-control react-form-input"
                  id="city"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.city}
                  placeholder="Add City"
                />
                <Error field="city" />
              </div>
              <div className="col-md-6 form-group">
                <label className="fs-16 medium-text">State</label>
                <Input
                  type="select"
                  name=""
                  id="state_id"
                  onChange={handleChange}
                  value={values.state_id}
                  onBlur={handleBlur}
                >
                  <option value="">Select State</option>
                  {stateList.map((type, i) => {
                    return (
                      <Fragment>
                        <option key={`option${i}`} value={type.id}>
                          {type.name}
                        </option>
                      </Fragment>
                    );
                  })}
                </Input>
                <Error field="state_id" />
              </div>
              <div className="col-md-6 form-group">
                <label className="fs-16 medium-text">Zip</label>
                <input
                  type="text"
                  className="form-control react-form-input"
                  id="zip"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.zip}
                  placeholder="Add Zip"
                />
                <Error field="zip" />
              </div>
            </div>
          </div>
          <div className="col-md-6 form-group">
            <label className="fs-16 medium-text">
              Language<span className="asterisk">*</span>
            </label>
            <Input
              type="select"
              name=""
              id="language_id"
              onChange={event => {
                let opts = [],
                  opt;
                for (
                  let i = 0, len = event.target.options.length;
                  i < len;
                  i++
                ) {
                  opt = event.target.options[i];
                  if (opt.selected) {
                    opts.push(opt.value);
                  }
                }
                setFieldValue("language_id", opts);
              }}
              value={values.language_id}
              onBlur={handleBlur}
              multiple
            >
              {languageList.map((type, i) => {
                return (
                  <Fragment>
                    <option key={`option${i}`} value={type.id}>
                      {type.name}
                    </option>
                  </Fragment>
                );
              })}
            </Input>
            <Error field="language_id" />
          </div>
          <div className="col-md-6 form-group">
            <label className="fs-16 medium-text">
              Timezone<span className="asterisk">*</span>
            </label>
            <Input
              type="select"
              name=""
              id="timezone_id"
              onChange={handleChange}
              value={values.timezone_id}
              onBlur={handleBlur}
            >
              <option value="">Select Timezone</option>
              {timezoneList.map((type, i) => {
                return (
                  <Fragment>
                    <option key={`option${i}`} value={type.id}>
                      {type.name}
                    </option>
                  </Fragment>
                );
              })}
            </Input>
            <Error field="timezone_id" />
          </div>
        </div>
        <div>
          <Button
            type="button"
            loading={loading}
            disabled={loading}
            className="c-btn c-info form-button fs-16 demi-bold-text"
            style={{ maxWidth: "125px" }}
            onClick={() => {
              handleSubmit();
            }}
            dataStyle="expand-right"
          >
            Submit
          </Button>
        </div>
      </form>
      <Toaster ref={toaster} />
    </div>
  );
};

export default compose(enhancer)(RdForm);
