import React from "react";
import PageWrapper from "./Service.style";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";
import ServiceTable from "components/service/ServiceTable";

const Service = props => {
  return (
    <PageWrapper {...props}>
      <PageTitle
        props={props}
        title="sidebar.service_management"
        className="plr-15"
        breadCrumb={[
          {
            name: "sidebar.dashboard",
            url: "/dashboard"
          },
          {
            name: "sidebar.service_management"
          }
        ]}
      />
      <div className="user-tbl">
        <ServiceTable {...props} />
      </div>
    </PageWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.permissionReducer,
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(Service);
