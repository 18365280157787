import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    form_id: Yup.string().required("This field is required"),
    due_date: Yup.date().required("Due date is required")
  }),
  mapPropsToValues: props => ({
    form_id: "",
    due_date: ""
  }),

  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
