import React from "react";
import Button from "../../components/button/Button";

const DeleteModal = ({ type = "DEFAULT", ...props }) => {
  return (
    <div className="text-center">
      <div>
        {props.text ? props.text : "Are you sure you want to delete this?"}
      </div>
      <div>
        <Button
          className={`c-btn  mr-10 mt-10 ${
            type === "SWITCH" ? "c-info" : "c-danger"
          }`}
          style={{ maxWidth: "125px" }}
          type="button"
          onClick={props.deleteHandler}
        >
          Delete
        </Button>
        <Button
          className={`c-btn  mr-10 mt-10 ${
            type === "SWITCH" ? "c-danger" : "c-info"
          }`}
          style={{ maxWidth: "125px" }}
          onClick={props.toggle}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default DeleteModal;
