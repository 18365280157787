import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .trim()
      .required("This field is required")
      .max(150, "Name must be less than 150 characters"),
    visits: Yup.number().required("This field is required"),
    service: Yup.string()
      .trim()
      .required("This field is required")
      .max(100, "Type must be less than 100 characters"),
    time_count: Yup.number().required("This field is required"),
    time_interval: Yup.string().required("This field is required"),
    bmi_threshold: Yup.string().when("is_bmi", {
      is: "1",
      then: Yup.string().required("This field is required"),
      otherwise: Yup.string().notRequired()
    })
  }),
  mapPropsToValues: props => {
    return {
      id:
        props && props.editedData && props.editedData.id
          ? props.editedData.id
          : "",
      name:
        props && props.editedData && props.editedData.name
          ? props.editedData.name
          : "",
      description:
        props && props.editedData && props.editedData.description
          ? props.editedData.description
          : "",
      service: props.editedData
        ? props && props.editedData && props.editedData?.service.id
        : "",
      visits:
        props && props.editedData && props.editedData.visits
          ? props.editedData.visits
          : "",
      time_count:
        props && props.editedData && props.editedData.time_count
          ? props.editedData.time_count
          : "",
      time_interval:
        props && props.editedData && props.editedData.time_interval
          ? props.editedData.time_interval
          : "",
      insurance_id:
        props && props.editedData && props.editedData.insurance_id
          ? props.editedData.insurance_id
          : "",
      is_bmi:
        props && props.editedData && props.editedData.is_bmi
          ? props.editedData.is_bmi.toString()
          : "0",
      bmi_threshold:
        props && props.editedData && props.editedData.bmi_threshold
          ? props.editedData.bmi_threshold
          : ""
    };
  },
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
