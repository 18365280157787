import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import "react-table/react-table.css";
import { withRouter } from "react-router";
// import SubAdminServices from "../../api/SubAdminServices";
import Loader from "../common/Loader";
import CommonModel from "../common/CommonModel";
import Toaster from "../common/Toaster";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import RoleModalData from "./RoleModalData";
import SubAdminServices from "api/SubAdminServices";

const RoleTable = props => {
  const [tblData, settblData] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [modal, setModal] = useState(undefined);
  const toaster = useRef();

  useEffect(() => {
    setLoader(true);
    const params = {
      type: "all"
    };
    SubAdminServices.adminTypes(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(data.data.rows.length);
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
          setLoader(false);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });
  }, []);

  function toggle() {
    setModal(false);
  }

  const columns = [
    {
      Header: "Roles",
      accessor: "role",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      // headerClassName: "react-table-header-class sort-sign",
      // width: 250,
      sortable: false
    },
    {
      Header: "Permissions",
      accessor: "id",
      className: "text-center",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 300,
      Cell: props => (
        <div className="react-action-class flex plr-1">
          <Button
            className="c-btn c-info mr-10"
            onClick={() => {
              setSelectedRole(props.original);
              setModal(true);
            }}
          >
            {props.original.id === 1 ? (
              <div className="fs-14 medium-text" title="Permission">
                View
              </div>
            ) : (
              <div className="fs-14 medium-text" title="Permission">
                View and Update
              </div>
            )}
          </Button>
        </div>
      ) // Custom cell components!
    }
  ];

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="mr-10"></div>
            <div></div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              showPagination={false}
              defaultPageSize={10}
              minRows={2}
              loading={loader}
              LoadingComponent={Loader}
            />
          </div>
          <CommonModel
            modal={modal}
            toggle={toggle}
            size="xl"
            style={{ minHeight: "100vh" }}
            children={<RoleModalData onClose={toggle} data={selectedRole} />}
          />
        </div>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};

export default withRouter(RoleTable);
