import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import ReportServices from "../../api/ReportServices";
import Loader from "../common/Loader";
import Toaster from "../common/Toaster";
import { TABLE } from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import moment from "moment-timezone";
import Button from "components/button/Button";
import CommonModel from "../common/CommonModel";
import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
import completeImg from "../../assets/images/green-check-mark.png";
import warningImg from "../../assets/images/warning.png";
import ViewError from "./ViewErrorModal";
import CategoryServices from "api/CategoryServices";
import CommonServices from "api/CommonServices";
const PROVIDER_TYPES = [
  {
    name: "Employee",
    value: "employee"
  },
  {
    name: "IBO",
    value: "ibo"
  },
  {
    name: "IBO Practice Provider",
    value: "ibo practice"
  }
];

const BillingTransactionTable = props => {
  const toaster = useRef(null);
  const [tblData, settblData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("id");
  const [sortBy, setSortBy] = useState("DESC");
  const [dateRange, setDateRange] = useState({
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD")
  });
  const [modal, setModal] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [parentDropdownOpenPayment, setParentDropdownOpenPayment] = useState(
    false
  );
  const [selectedData, setSelectedData] = useState(null);
  const [viewWarning, setViewWarning] = useState(false);
  const [serviceList, setServiceList] = useState([]);
  const [serviceId, setServiceId] = useState(null);
  const [serviceName, setServiceName] = useState(null);
  const [serviceDropdownOpen, setServiceDropdownOpen] = useState(false);
  const [typeName, setTypeName] = useState(null);
  const [typeValue, setTypeValue] = useState(null);
  const [typeDropdownOpen, setTypeDropdownOpen] = useState(false);
  const [parentDropdownPayment, setParentDropdownPayment] = useState(false);
  const [paymentMethodValue, setPaymentMethodValue] = useState("");
  const [isExporting, setIsExporting] = useState(false);
  const [practiceList, setPracticeList] = useState([]);
  const [practiceId, setPracticeId] = useState(null);
  const [practiceName, setPracticeName] = useState(null);
  const [practiceDropdownOpen, setPracticeDropdownOpen] = useState(false);

  // useEffect(() => {
  //   getList(field, sortBy, searchText, activePage + 1, filterValue);
  //   setPages(10);
  // }, []);

  useEffect(() => {
    CategoryServices.serviceDropdown()
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setServiceList([...data.data]);
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      });
  }, []);

  useEffect(() => {
    const params = {
      get_all: true,
      service_id:
        props.admin_role_id > 3
          ? props.service_id
          : serviceId
          ? serviceId
          : undefined
    };
    CommonServices.iboPracticeDropdownList(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setPracticeList([...data.data]);
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch Practice!");
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch Practice!");
        }
      });
  }, [serviceId, props?.admin_role_id]);

  useEffect(() => {
    getList(field, sortBy, searchText, activePage + 1, filterValue);
    setPages(10);
  }, [
    dateRange.startDate,
    dateRange.endDate,
    serviceId,
    typeValue,
    paymentMethodValue,
    practiceId
  ]);

  const toggleService = () => setServiceDropdownOpen(prevState => !prevState);
  const toggleType = () => setTypeDropdownOpen(prevState => !prevState);
  const toggleOnePayment = () =>
    setParentDropdownOpenPayment(prevState => !prevState);
  const togglePayment = () => setParentDropdownPayment(prevState => !prevState);

  function toggleWarning() {
    setViewWarning(!viewWarning);
  }

  const toggle = () => {
    setOpen(!open);
    setModal(!open);
  };
  const togglePractice = () => setPracticeDropdownOpen(prevState => !prevState);

  const columns = [
    {
      Header: "Error Date",
      accessor: "advancemd_added_date",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 150,
      Cell: props =>
        props.original.advancemd_added_date
          ? moment(props.original.advancemd_added_date).format("MM/DD/YYYY")
          : ""
    },
    {
      Header: "Visit ID (Database)",
      accessor: "id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200
    },
    {
      Header: "Visit ID",
      accessor: "calendly_appointment_id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200
    },
    {
      Header: "Patient ID",
      accessor: "user_id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200
    },
    {
      Header: "Payment Method",
      accessor: "payment_method",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      width: 200
    },
    {
      Header: "Date of Serivce",
      accessor: "service_date",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200,
      Cell: props =>
        props.original?.service_date
          ? moment(props.original?.service_date).format("MM/DD/YYYY")
          : ""
    },
    {
      Header: "AMD Error",
      accessor: "",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      width: 350,
      Cell: props => {
        return props.original && props.original?.advancemd_step != 11
          ? props.original.advancemd_logs.length > 0
            ? props.original.advancemd_logs[0].custom_message
            : ""
          : "-";
      }
    },
    {
      Header: "#",
      accessor: "",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 100,
      sortable: false,
      Cell: props => {
        return props.original && props.original.advancemd_step != 0 ? (
          props.original.advancemd_step == 11 ? (
            <img
              style={{
                width: "20px"
              }}
              title="Visit Status"
              src={completeImg}
            />
          ) : (
            <img
              style={{
                cursor: "pointer",
                width: "20px"
              }}
              title="Visit Status"
              src={warningImg}
              onClick={() => {
                setSelectedData({ ...props.original });
                setViewWarning(!viewWarning);
              }}
            />
          )
        ) : (
          "-"
        );
      }
    }
  ];

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  function exportList(filter) {
    const params = {
      start_date: dateRange.startDate
        ? moment(dateRange.startDate).format("YYYY-MM-DD")
        : null,
      end_date: dateRange.endDate
        ? moment(dateRange.endDate).format("YYYY-MM-DD")
        : null,
      filter: filter,
      service_id:
        props?.admin_role_id && props?.admin_role_id < 3
          ? serviceId
          : props?.service_id,
      rd_type: typeValue,
      payment_method: paymentMethodValue,
      practice_id:
        props?.admin_role_id === 6 && props?.practice
          ? props?.practice
          : practiceId
          ? practiceId
          : undefined
    };
    setIsExporting(true);
    ReportServices.dailyBillingFailureCsv(params)
      .then(res => {
        setIsExporting(false);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error("Something went wrong!");
        setIsExporting(false);
      });
  }

  const formAction = (action, data = null) => {
    // Here you can view the data and make forward action for edit data
    if (action === "export") {
      exportList(filterValue);
    }
  };

  function getList(
    field = "name",
    sortBy = "DESC",
    search = "",
    page = "1",
    filter = ""
  ) {
    const params = {
      sort_by: field,
      order: sortBy,
      search: search,
      page: page,
      start_date: dateRange.startDate
        ? moment(dateRange.startDate).format("YYYY-MM-DD")
        : null,
      end_date: dateRange.endDate
        ? moment(dateRange.endDate).format("YYYY-MM-DD")
        : null,
      filter: filter,
      service_id:
        props?.admin_role_id && props?.admin_role_id < 3
          ? serviceId
          : props?.service_id,
      rd_type: typeValue,
      payment_method: paymentMethodValue,
      practice_id:
        props?.admin_role_id === 6 && props?.practice
          ? props?.practice
          : practiceId
          ? practiceId
          : undefined
    };
    setLoader(true);
    ReportServices.dailyBillingFailureList(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count.length / TABLE.LIMIT));
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
          setLoader(false);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.message);
        setLoader(false);
      });
  }

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Daily Billing Failure Report
            </div>
            <div>
              <Button
                className="c-btn c-primary ma-5"
                onClick={() => formAction("export")}
                disabled={!tblData?.length || isExporting}
              >
                <i className="fas fa-download mr-10" />
                {isExporting ? "Exporting.." : "Export"}
              </Button>
            </div>
          </div>
          <div className="roe-card-header module-header">
            <div className="d-flex">
              <div className="mr-10">
                <span className="fs-16">Error Date</span>
                <br></br>
                <Button
                  className="btn btn-secondary btn-md"
                  onClick={() => toggle()}
                >
                  {dateRange.startDate
                    ? `${moment(dateRange.startDate).format(
                        "MM/DD/YYYY"
                      )} - ${moment(dateRange.endDate).format("MM/DD/YYYY")}`
                    : "Select Date"}
                </Button>
                {dateRange && dateRange.startDate && (
                  <Button
                    className="btn btn-secondary btn-md ml-2"
                    onClick={() => {
                      setActivePage(0);
                      setDateRange({});
                    }}
                  >
                    Reset date
                  </Button>
                )}
              </div>
            </div>
            <div className="fs-16 mr-10">
              <span className="fs-16">Filter</span>
              <Dropdown
                isOpen={parentDropdownOpenPayment}
                toggle={toggleOnePayment}
              >
                <DropdownToggle caret size="md" className="w-100 float-left">
                  {filterValue ? filterValue : "All"}
                </DropdownToggle>
                <DropdownMenu
                  modifiers={{
                    setMaxHeight: {
                      enabled: true,
                      order: 890,
                      fn: data => {
                        return {
                          ...data,
                          styles: {
                            ...data.styles,
                            overflow: "auto",
                            maxHeight: "300px"
                          }
                        };
                      }
                    }
                  }}
                >
                  <DropdownItem
                    key="0"
                    onClick={() => {
                      setFilterValue("");
                      setLoader(true);
                      setActivePage(0);
                      setTimeout(() => {
                        getList(field, sortBy, searchText, 1, "");
                      }, 500);
                    }}
                  >
                    All
                  </DropdownItem>
                  <DropdownItem
                    key="Success"
                    onClick={() => {
                      setFilterValue("Success");
                      setLoader(true);
                      setActivePage(0);
                      setTimeout(() => {
                        getList(field, sortBy, searchText, 1, "Success");
                      }, 500);
                    }}
                  >
                    Success
                  </DropdownItem>
                  <DropdownItem
                    key="Failed"
                    onClick={() => {
                      setFilterValue("Failed");
                      setLoader(true);
                      setActivePage(0);
                      setTimeout(() => {
                        getList(field, sortBy, searchText, 1, "Failed");
                      }, 500);
                    }}
                  >
                    Failed
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            {props?.admin_role_id < 3 && (
              <div className="fs-14 mr-10">
                <span className="fs-16">Service</span>
                <Dropdown isOpen={serviceDropdownOpen} toggle={toggleService}>
                  <DropdownToggle caret size="md">
                    {serviceId ? serviceName : "All"}
                  </DropdownToggle>
                  <DropdownMenu
                    modifiers={{
                      setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: data => {
                          return {
                            ...data,
                            styles: {
                              ...data.styles,
                              overflow: "auto",
                              maxHeight: "300px"
                            }
                          };
                        }
                      }
                    }}
                  >
                    <DropdownItem
                      key="-1"
                      onClick={() => {
                        setPracticeList([]);
                        setPracticeId("");
                        setPracticeName("");
                        setServiceId("");
                        setServiceName("All");
                        setActivePage(0);
                      }}
                    >
                      All
                    </DropdownItem>
                    {serviceList.length > 0 &&
                      serviceList.map((result, i) => {
                        return (
                          <DropdownItem
                            key={i}
                            onClick={() => {
                              setPracticeList([]);
                              setPracticeId("");
                              setPracticeName("");
                              setServiceId(result.id);
                              setServiceName(`${result.name}`);
                              setActivePage(0);
                            }}
                          >
                            {result.name}
                          </DropdownItem>
                        );
                      })}
                  </DropdownMenu>
                </Dropdown>
              </div>
            )}
            {props?.admin_role_id !== 6 && props?.admin_role_id !== 3 && (
              <div className="fs-14 mr-10">
                <span className="fs-16">Type</span>
                <Dropdown isOpen={typeDropdownOpen} toggle={toggleType}>
                  <DropdownToggle caret size="md">
                    {typeName ? typeName : "All"}
                  </DropdownToggle>
                  <DropdownMenu
                    modifiers={{
                      setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: data => {
                          return {
                            ...data,
                            styles: {
                              ...data.styles,
                              overflow: "auto",
                              maxHeight: "300px"
                            }
                          };
                        }
                      }
                    }}
                  >
                    <DropdownItem
                      key="-1"
                      onClick={() => {
                        setTypeName("");
                        setTypeValue("");
                        setActivePage(0);
                      }}
                    >
                      All
                    </DropdownItem>
                    {PROVIDER_TYPES.map((result, i) => {
                      return (
                        <DropdownItem
                          key={i}
                          onClick={() => {
                            setTypeName(`${result.name}`);
                            setTypeValue(`${result.value}`);
                            setActivePage(0);
                            setPracticeId("");
                            setPracticeName("");
                          }}
                        >
                          {result.name}
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
              </div>
            )}
            {props.admin_role_id !== 3 && props.admin_role_id !== 6 && (
              <div className="fs-14 mr-10">
                <span className="fs-16">Practice</span>
                <Dropdown
                  isOpen={practiceDropdownOpen}
                  toggle={togglePractice}
                  disabled={typeValue && typeValue !== "ibo practice"}
                >
                  <DropdownToggle
                    caret
                    size="md"
                    className={
                      typeValue &&
                      typeValue !== "ibo practice" &&
                      "cursor-not-allowed"
                    }
                  >
                    {practiceId ? practiceName : "Select Practice"}
                  </DropdownToggle>
                  <DropdownMenu
                    modifiers={{
                      setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: data => {
                          return {
                            ...data,
                            styles: {
                              ...data.styles,
                              overflow: "auto",
                              maxHeight: "300px"
                            }
                          };
                        }
                      }
                    }}
                  >
                    <DropdownItem
                      key="-1"
                      onClick={() => {
                        setPracticeId("");
                        setPracticeName("All");
                        setActivePage(0);
                      }}
                    >
                      All
                    </DropdownItem>
                    {practiceList.length > 0 &&
                      practiceList.map((result, i) => {
                        return (
                          <DropdownItem
                            key={i}
                            onClick={() => {
                              setPracticeId(result.id);
                              setPracticeName(`${result.practice_name}`);
                              setActivePage(0);
                            }}
                          >
                            {result.practice_name}
                          </DropdownItem>
                        );
                      })}
                  </DropdownMenu>
                </Dropdown>
              </div>
            )}
            <div className="fs-16 mr-10">
              <span className="fs-16">Payment Method</span>
              <Dropdown isOpen={parentDropdownPayment} toggle={togglePayment}>
                <DropdownToggle caret size="md" className="w-100 float-left">
                  {paymentMethodValue ? paymentMethodValue : "All"}
                </DropdownToggle>
                <DropdownMenu
                  modifiers={{
                    setMaxHeight: {
                      enabled: true,
                      order: 890,
                      fn: data => {
                        return {
                          ...data,
                          styles: {
                            ...data.styles,
                            overflow: "auto",
                            maxHeight: "300px"
                          }
                        };
                      }
                    }
                  }}
                >
                  <DropdownItem
                    key="0"
                    onClick={() => {
                      setPaymentMethodValue("");
                      setActivePage(0);
                    }}
                  >
                    All
                  </DropdownItem>
                  <DropdownItem
                    key="Insurance Funded"
                    onClick={() => {
                      setPaymentMethodValue("Insurance Funded");
                      setActivePage(0);
                    }}
                  >
                    Insurance Funded
                  </DropdownItem>
                  <DropdownItem
                    key="Client Funded"
                    onClick={() => {
                      setPaymentMethodValue("Client Funded");
                      setActivePage(0);
                    }}
                  >
                    Client Funded
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              LoadingComponent={Loader}
              onFetchData={onChangePageGetData}
              onPageChange={pageIndex => {
                setActivePage(pageIndex);
                getList(field, sortBy, searchText, pageIndex + 1, filterValue);
              }}
              onSortedChange={sortProperties => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getList(sortProperties[0].id, sort, searchText, 1, filterValue);
                // alert("sort handler", sortProperties);
                // const orderByDirection = sortProperties[0].desc ? 1 : 0;

                // updateMapStateInfoOrderByColumn(sortProperties[0].id, orderByDirection);
              }}
            />
          </div>
          <CommonModel
            modal={modal}
            toggle={toggle}
            size="lg"
            children={
              <DateRangePicker
                open={open}
                toggle={() => toggle()}
                onChange={range => {
                  setActivePage(0);
                  setDateRange(range);
                  // setModal(!modal)
                  toggle();
                }}
                closeOnClickOutside={true}
              />
            }
            // header={editedData ? "" : "Add Registration Code"}
          />
        </div>
      </div>
      <Toaster ref={toaster} />
      {viewWarning && (
        <CommonModel
          modal={viewWarning}
          toggle={toggleWarning}
          size="xl"
          children={
            <ViewError
              editedData={selectedData}
              toggle={toggleWarning}
              {...props}
            />
          }
        />
      )}
    </div>
  );
};

export default withRouter(BillingTransactionTable);
