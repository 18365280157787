import React, { useRef } from "react";
import UserWrapper from "./state.style";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";
import StateServices from "../../api/StateServices";
import Toaster from "../../components/common/Toaster";
import StateForm from "../../components/state/StateForm";
import EncryptDecrypt from "../../api/EncryptDecrypt";

const StateAdd = props => {
  const toaster = useRef();

  const submitFormHandler = (data, action) => {
    const params = {
      name: data.name,
      code: data.code
    };
    StateServices.add(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current) {
            toaster.current.success(data.message);
          }
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
        setTimeout(() => {
          props.history.push("/state");
        }, 1000);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });
  };

  return (
    <UserWrapper {...props}>
      <div className="pos-relative">
        <PageTitle
          props={props}
          title="sidebar.state"
          className="plr-15"
          breadCrumb={[
            {
              name: "sidebar.dashboard",
              url: "/dashboard"
            },
            {
              name: "sidebar.state",
              url: "/state"
            },
            {
              name: "action.add"
            }
          ]}
        />
        <div
          className="back-icon fs-15 demi-bold-text cursor-pointer"
          onClick={() => props.history.push("/state")}
        >
          <i className="fas fa-step-backward"></i> Back
        </div>
      </div>

      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Add State
            </div>
          </div>
          <div className="roe-card-body">
            <StateForm onSubmit={submitFormHandler} />
          </div>
        </div>
      </div>
      <Toaster ref={toaster} />
    </UserWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(StateAdd);
