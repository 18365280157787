import React from "react";

const VisitNotesTemplateViewDetails = props => {
  const pageDetails = props.location.state;

  return (
    <div className="plr-15">
      <div className="roe-card-style mtb-15">
        <div className="roe-card-header module-header">
          <div className="flex-1 fs-16 demi-bold-text">
            <span className="hash"># </span> View Template
          </div>
        </div>
        <div className="roe-card-body">
          <div className="pt-2">
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>Title</div>
            <div>{pageDetails.title}</div>
          </div>
          <div className="pt-2">
            <div>
              <div style={{ fontWeight: "bolder", textAlign: "left" }}>
                Template
              </div>
              <div
                className="text-left"
                dangerouslySetInnerHTML={{ __html: pageDetails.content }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisitNotesTemplateViewDetails;
