import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import ContentLibraryServices from "../../api/ContentLibraryServices";
import Loader from "../common/Loader";
import Toaster from "../common/Toaster";
import { TABLE, RD } from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import SharedUserDetail from "./SharedUserDetail";

const ContentLibraryShare = props => {
  const [tblData, settblData] = useState();
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("created_at");
  const [sortBy, setSortBy] = useState("DESC");
  const [clientId, setClientId] = useState("");
  const [checkedId, setCheckedId] = useState([]);

  const [RDId, setRDId] = useState(null);
  const toaster = useRef();

  useEffect(() => {
    getList(field, sortBy, searchText, activePage + 1, clientId);
    setPages(10);
  }, []);

  const handleChange = event => {
    let index = checkedId.findIndex(x => x.id === +event.target.value);
    if (event.target.checked) {
      if (index === -1) {
        let userData = tblData.find(data => data.id === +event.target.value);
        setCheckedId(checkedId => [...checkedId, userData]);
      }
      console.log("✅ Checkbox is checked");
    } else {
      checkedId.splice(index, 1);
      setCheckedId([...checkedId]);
      console.log("⛔️ Checkbox is NOT checked");
    }
  };

  const columns = [
    {
      Header: "#",
      accessor: "id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 100,
      sortable: false,
      Cell: props => (
        <div className="react-action-class">
          <input
            type="checkbox"
            onChange={handleChange}
            defaultChecked={
              checkedId.findIndex(x => x.id == props.original.id) == -1
                ? false
                : true
            }
            value={props.original.id}
          ></input>
        </div>
      )
    },
    {
      Header: "ID",
      accessor: "id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 100,
      sortable: true
    },
    {
      Header: "First Name",
      accessor: "first_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 200,
      sortable: true
    },
    {
      Header: "Last Name",
      accessor: "last_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 200,
      sortable: true
    },
    {
      Header: "Email",
      accessor: "email",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 250,
      sortable: true
    },
    {
      Header: "Group name",
      accessor: "group.name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 200,
      sortable: false
    },
    {
      Header: "Provider",
      accessor: "admin.first_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 200,
      sortable: false,
      Cell: props => (
        <div className="react-action-class">
          {props.original.admin
            ? props.original.admin.first_name +
              " " +
              props.original.admin.last_name
            : ""}
        </div>
      )
    },
    {
      Header: "Active Client",
      accessor: "is_appointment_booked",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 150,
      sortable: true,
      Cell: props => (
        <div className="react-action-class">
          {props.original.is_appointment_booked ? "Active" : "InActive"}
        </div>
      )
    }
  ];
  useEffect(() => {
    setRDId(props.auth && props.auth.admin_role_id <= 2 ? null : props.auth.id);
  }, []);

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  function getList(
    field = "created_at",
    sortBy = "DESC",
    search = "",
    page = "1",
    client_id = null
  ) {
    const params = {
      field: field,
      sort_by: sortBy,
      order: sortBy,
      search: search,
      page: page,
      rd_id: props.auth && props.auth.admin_role_id <= 2 ? RDId : props.auth.id,
      id: client_id,
      service_id: props?.location?.state?.service_id
    };

    setLoader(true);
    ContentLibraryServices.userList(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count / TABLE.LIMIT));
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });
  }

  const handleSubmit = () => {
    const params = {
      id: props.location.state.id,
      user_data: checkedId
    };
    ContentLibraryServices.share(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current) toaster.current.success(data.message);
          setTimeout(res => {
            props.history.push("/content-library");
          }, 1000);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });
  };

  const removeFromList = id => {
    let index = checkedId.findIndex(x => x.id === +id);
    checkedId.splice(index, 1);
    setCheckedId([...checkedId]);
  };

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header d-block">
            <div className="fs-16 demi-bold-text">
              Share {props.history.location.state.title} with:
            </div>
            <div
              className="fs-16"
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap"
              }}
            >
              {checkedId.map(user => (
                <SharedUserDetail onSubmit={removeFromList} user={user} />
              ))}
            </div>
          </div>
          <div className="roe-card-header module-header">
            <div className="d-flex">
              <div className="fs-14 mr-10">
                <input
                  value={searchText}
                  onChange={e => {
                    setSearchText(e.target.value);
                    const search = e.target.value;
                    setLoader(true);
                    setActivePage(0);
                    setTimeout(() => {
                      getList(field, sortBy, search, activePage + 1, clientId);
                    }, 500);
                  }}
                  type="text"
                  placeholder="Search..."
                  className="fs-14 medium-text plr-10 form-control react-form-input  mr-10"
                />
              </div>
              <div className="fs-14 mr-10">
                <input
                  value={clientId}
                  onChange={e => {
                    setClientId(e.target.value);
                    const clientId = e.target.value;
                    setLoader(true);
                    setActivePage(0);
                    setTimeout(() => {
                      getList(
                        field,
                        sortBy,
                        searchText,
                        activePage + 1,
                        clientId
                      );
                    }, 500);
                  }}
                  type="number"
                  placeholder="Search by ID"
                  className="fs-14 medium-text plr-10 form-control react-form-input  mr-10"
                />
              </div>
            </div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              LoadingComponent={Loader}
              onFetchData={onChangePageGetData}
              onPageChange={pageIndex => {
                setActivePage(pageIndex);
                getList(field, sortBy, searchText, pageIndex + 1, clientId);
              }}
              onSortedChange={sortProperties => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";

                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getList(sortProperties[0].id, sort, searchText, 1, clientId);
              }}
            />

            <div className="text-right mt-3">
              <Button
                type="button"
                className="c-btn c-primary form-button fs-16 demi-bold-text "
                style={{ maxWidth: "125px" }}
                onClick={() => handleSubmit()}
                disabled={checkedId.length > 0 ? false : true}
              >
                Share
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};

export default withRouter(ContentLibraryShare);
