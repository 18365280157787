import React from "react";

export const PencilIcon = props => {
  return (
    <svg viewBox="0 0 25 25" {...props}>
      <g id="pencil">
        <path
          class="cls-1"
          d="M21.84,5,17.53,1.38a1.17,1.17,0,0,0-.87-.27,1.15,1.15,0,0,0-.8.42L3.1,16.65a.54.54,0,0,0-.11.26l-.84,6.44a.5.5,0,0,0,.49.56l.15,0L9,22a.47.47,0,0,0,.24-.15L22,6.69A1.19,1.19,0,0,0,21.84,5Zm-.62,1-12.66,15L3.24,22.7,4,17.19l12.66-15a.21.21,0,0,1,.27,0L21.2,5.78A.19.19,0,0,1,21.22,6.05Z"
        />
        <path
          class="cls-1"
          d="M15,5.75a.51.51,0,0,0-.71.06.49.49,0,0,0,.06.7l3.05,2.61a.49.49,0,0,0,.32.12.52.52,0,0,0,.38-.17A.51.51,0,0,0,18,8.36Z"
        />
      </g>
    </svg>
  );
};
