import React, { useEffect, useState } from "react";
import UserWrapper from "./subAdmin.style";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";
import SubAdminServices from "../../api/SubAdminServices";
import SubAdminForm from "../../components/subAdmin/SubAdminForm";
import EncryptDecrypt from "../../api/EncryptDecrypt";

const SubAdminEdit = props => {
  const [editedData, setEditedData] = useState(undefined);
  const [types, setTypes] = useState([]);
  const hasParam = props.match.params.id;
  useEffect(() => {
    if (hasParam) {
      const params = {
        id: hasParam
      };
      SubAdminServices.view(params).then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        setEditedData(data.data);
      });
    }
    SubAdminServices.adminTypes().then(res => {
      let data = EncryptDecrypt.decrypt(res.data);
      setTypes(data.data.rows);
    });
  }, []);

  const submitFormHandler = (data, action) => {
    setTimeout(() => {
      props.history.push("/sub-admin");
    }, 2000);
  };

  return (
    <UserWrapper {...props}>
      <div className="pos-relative">
        <PageTitle
          props={props}
          title="sidebar.admin"
          className="plr-15"
          breadCrumb={[
            {
              name: "sidebar.dashboard",
              url: "/dashboard"
            },
            {
              name: "sidebar.admin",
              url: "/sub-admin"
            },
            {
              name: hasParam ? "action.edit" : "action.add"
            }
          ]}
        />
        <div
          className="back-icon fs-15 demi-bold-text cursor-pointer"
          onClick={() => props.history.push("/sub-admin")}
        >
          <i className="fas fa-step-backward"></i> Back
        </div>
      </div>

      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> {hasParam ? "Edit" : "Add"} Admin
            </div>
          </div>
          <div className="roe-card-body">
            <SubAdminForm
              onSubmit={submitFormHandler}
              editedData={editedData}
              types={types || []}
              action={hasParam ? "edit" : "add"}
            />
          </div>
        </div>
      </div>
    </UserWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(SubAdminEdit);
