import React, { useRef, useEffect, useState } from "react";
import UserWrapper from "./content_library.style";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";
import ContentLibraryServices from "../../api/ContentLibraryServices";
import ContentLibraryForm from "../../components/contentLibrary/ContentLibraryForm";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import moment from "moment";
import { Uploader } from "../../util/upload";
import UploadActions from "../../redux/fileUpload/actions";
import Toaster from "../../components/common/Toaster";

const generateRandomString = (length = 8) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const { progress, complete } = UploadActions;

const ContentLibraryEdit = props => {
  const toaster = useRef(null);
  const [editedData, setEditedData] = useState(undefined);
  const [authorList, setAuthorList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [uploader, setUploader] = useState(undefined);
  const [hasParam, setHasParam] = useState(props.match.params.id);

  useEffect(() => {
    if (hasParam) {
      const params = {
        id: hasParam
      };
      ContentLibraryServices.view(params).then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        setEditedData(data.data);
      });
    }

    ContentLibraryServices.authorList()
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setAuthorList(data.data);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });

    ContentLibraryServices.tagList()
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setTagList(data.data);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });
  }, []);

  const submitFormHandler = (data, action) => {
    if (action === "add") {
      const params = {
        type: data.type,
        title: data.title,
        tags: data.tags,
        service_id: data.service,
        description: data.description,
        author_id: data.author_id,
        status: data.status,
        recipe_serving: data.recipe_serving,
        recipe_ingredient: data.recipe_ingredient,
        recipe_instruction: data.recipe_instruction.replaceAll(
          "<table>",
          "<table border=1>"
        ),
        article_body: data.article_body.replaceAll(
          "<table>",
          "<table border=1>"
        ),
        video_url: "",
        video_url_status: ""
      };

      if (data.video_url) {
        let ext = data.video_url.name.split(".").pop();
        let videoFileName =
          generateRandomString(8) + "_" + moment().format("x");
        params.video_url = videoFileName + "." + ext;

        // video upload
        const videoUploaderOptions = {
          fileName: videoFileName,
          file: data.video_url
        };
        const uploader = new Uploader(videoUploaderOptions, props);
        setUploader(uploader);
        let percentage = undefined;
        uploader
          .onProgress(({ percentage: newPercentage, fileName: fileName }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {
              percentage = newPercentage;
              console.log(`${percentage}%`, `${fileName}`);
            }
          })
          .onError(error => {
            // setFile(undefined)
            console.error(error);
          });

        props.progress({ fileName: videoFileName, status: 1 });

        uploader.start();
        // end video upload
      }

      ContentLibraryServices.add(params)
        .then(res => {
          let resData = EncryptDecrypt.decrypt(res.data);
          if (resData.status) {
            setEditedData(resData.data);
            setHasParam(resData.data.id);
            if (toaster.current) toaster.current.success(resData.message);

            if (data.status !== "save_draft") {
              setTimeout(res => {
                props.history.push("/content-library");
              }, 1000);
            }
          } else {
            if (toaster.current) toaster.current.error(resData.message);
          }
        })
        .catch(err => {
          if (toaster.current) toaster.current.error(err.response);
        });
    } else if (action === "edit") {
      const params = {
        id: data.id,
        type: data.type,
        title: data.title,
        tags: data.tags,
        service_id: data.service,
        description: data.description,
        author_id: data.author_id,
        status: data.status,
        recipe_serving: data.recipe_serving,
        recipe_ingredient: data.recipe_ingredient,
        recipe_instruction: data.recipe_instruction.replaceAll(
          "<table>",
          "<table border=1>"
        ),
        article_body: data.article_body.replaceAll(
          "<table>",
          "<table border=1>"
        ),
        video_url: data.video_original_url
      };

      if (typeof data.video_url == "object") {
        let ext = data.video_url.name.split(".").pop();
        let videoFileName =
          generateRandomString(8) + "_" + moment().format("x");
        params.video_url = videoFileName + "." + ext;

        // video upload
        const videoUploaderOptions = {
          fileName: videoFileName,
          file: data.video_url
        };
        const uploader = new Uploader(videoUploaderOptions, props);
        setUploader(uploader);
        let percentage = undefined;
        uploader
          .onProgress(({ percentage: newPercentage, fileName: fileName }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {
              percentage = newPercentage;
              console.log(`${percentage}%`, `${fileName}`);
            }
          })
          .onError(error => {
            // setFile(undefined)
            console.error(error);
          });

        props.progress({ fileName: videoFileName, status: 1 });

        uploader.start();
        // end video upload
      }

      ContentLibraryServices.edit(params)
        .then(res => {
          let resData = EncryptDecrypt.decrypt(res.data);
          if (resData.status) {
            if (toaster.current) toaster.current.success(resData.message);
            if (data.status !== "save_draft") {
              setTimeout(res => {
                props.history.push("/content-library");
              }, 1000);
            }
          } else {
            if (toaster.current) toaster.current.error(resData.message);
          }
        })
        .catch(err => {
          if (toaster.current) toaster.current.error(err.response);
        });
    }
  };

  // const submitFormHandler = (data, action) => {
  //   setTimeout(() => {
  //     props.history.push("/content-library");
  //   }, 2000);
  // };

  return (
    <UserWrapper {...props}>
      <div className="pos-relative">
        <PageTitle
          props={props}
          title="sidebar.content_library"
          className="plr-15"
          breadCrumb={[
            {
              name: "sidebar.dashboard",
              url: "/dashboard"
            },
            {
              name: "sidebar.content_library",
              url: "/content-library"
            },
            {
              name: hasParam ? "action.edit" : "action.add"
            }
          ]}
        />
        <div
          className="back-icon fs-15 demi-bold-text cursor-pointer"
          onClick={() => props.history.push("/content-library")}
        >
          <i className="fas fa-step-backward"></i> Back
        </div>
      </div>

      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> {hasParam ? "Edit" : "Add"}{" "}
              Content Library
            </div>
          </div>
          <div className="roe-card-body">
            <ContentLibraryForm
              onSubmit={submitFormHandler}
              editedData={editedData}
              authorList={authorList}
              tagList={tagList}
              action={hasParam ? "edit" : "add"}
              {...props}
            />
          </div>
        </div>
      </div>
      <Toaster ref={toaster} />
    </UserWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    authData: {
      token: state.auth.token,
      isLogin: state.auth.isLogin,
      profileImage: state.auth.profile_image,
      adminRoleId: state.auth.admin_role_id,
      adminRole: state.auth.admin_role,
      full_name: state.auth.first_name + " " + state.auth.last_name,
      adminId: state.auth.id,
      service_id: state.auth.service_id,
      practice: state.auth.practice
    }
  };
};

export default connect(mapStateToProps, { progress, complete })(
  ContentLibraryEdit
);
