import axios from "axios";
import { baseUrl } from "../helper/constant";
import Header from "./Header";
import EncryptDecrypt from "./EncryptDecrypt";

export default class VisitProgramServices {
  static list(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/insurance-carrier/list`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static changeStatus(params) {
    Header.setHeaders();
    return axios.put(
      `${baseUrl}/insurance-carrier/change-status`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static insuranceDropDown(params) {
    Header.setHeaders();
    return axios.get(`${baseUrl}/insurance-carrier/dropdown-insurance/list`);
  }
  static add(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/insurance-carrier/add`, params);
  }
  static edit(params) {
    Header.setHeaders();
    return axios.put(`${baseUrl}/insurance-carrier/edit`, params);
  }
}
