import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import RdServices from "../../api/RdServices";
import Loader from "../common/Loader";
import Toaster from "../common/Toaster";
import { MODULE_NAMES, PERMISSION_TYPE, TABLE } from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
import CommonModel from "components/common/CommonModel";
import AddProviderModal from "./AddProviderModal";
import DeleteModal from "components/common/DeleteModal";
import ExternalProviderServices from "api/ExternalProviderServices";
import { useDebounce } from "helper/useDebounce";
import { hasAccess } from "util/sidebar";

const RdTable = props => {
  const { permissions } = props;
  const toaster = useRef();
  const [tblData, settblData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [stateList, setStateList] = useState([]);
  const [stateId, setStateId] = useState(null);
  const [stateName, setStateName] = useState(null);
  const [stateCode, setStateCode] = useState(null);
  const [stateDropdownOpen, setStateDropdownOpen] = useState(false);
  const [id, setId] = useState(undefined);
  const [deleteModal, setDeleteModal] = useState(undefined);
  const [modal, setModal] = useState(false);
  const [field, setField] = useState("created_at");
  const [sortBy, setSortBy] = useState("ASC");
  const debouncedSearchTerm = useDebounce(searchText, 1000);

  useEffect(() => {
    RdServices.stateList().then(res => {
      let data = EncryptDecrypt.decrypt(res.data);
      setStateList(data.data);
    });
  }, []);

  useEffect(() => {
    getList(activePage + 1, field);
    setPages(10);
  }, [stateName, debouncedSearchTerm, stateCode]);

  const toggleState = () => setStateDropdownOpen(prevState => !prevState);

  function toggle() {
    setModal(false);
  }
  function deleteToggle() {
    setDeleteModal(false);
  }

  const deleteClick = data => {
    setId(data.id);
    setDeleteModal(true);
  };

  function deleteHandler() {
    setLoader(true);
    const params = {
      rd_id: id
    };
    ExternalProviderServices.delete(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current) toaster.current.success(data.message);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
        setLoader(false);
        setDeleteModal(false);
        setTimeout(() => {
          getList(activePage + 1);
        }, 1000);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
        setLoader(false);
      });
  }

  const columns = [
    {
      Header: "ID",
      accessor: "id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",
      width: 100,
      sortable: false
    },
    {
      Header: "NPI",
      accessor: "provider_npi",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",
      width: 150,
      sortable: false
    },
    {
      Header: "First Name",
      accessor: "first_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 250,
      sortable: true
    },
    {
      Header: "Last Name",
      accessor: "last_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 250,
      sortable: true
    },
    {
      Header: "Credential",
      accessor: "credential",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",
      width: 150,
      sortable: false
    },
    {
      Header: "Address",
      accessor: "address",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",
      width: 250,
      sortable: false
    },
    {
      Header: "City",
      accessor: "city",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 150,
      sortable: false
    },
    {
      Header: "State",
      accessor: "state",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",
      width: 150,
      sortable: false
    },
    {
      Header: "Postal Code",
      accessor: "zip",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",
      width: 150,
      sortable: false
    },
    {
      Header: "Category",
      accessor: "taxonomy",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",
      width: 350,
      sortable: false
    },
    {
      Header: "Status",
      accessor: "status",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",
      width: 100,
      sortable: false
    },
    {
      Header: "Action",
      accessor: "action",
      className: "",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 150,
      Cell: colProps => (
        <div className="react-action-class">
          {hasAccess(
            MODULE_NAMES.EXTERNAL_PROVIDER,
            PERMISSION_TYPE.VIEW,
            permissions
          ) ? (
            <Button
              className="c-btn c-info mr-10"
              onClick={() => formAction("view", colProps.original)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-eye" />
              </div>
            </Button>
          ) : null}
          {hasAccess(
            MODULE_NAMES.EXTERNAL_PROVIDER,
            PERMISSION_TYPE.EDIT,
            permissions
          ) ? (
            <Button
              className="c-btn c-success mr-10"
              onClick={() => formAction("edit", colProps.original)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-edit" />
              </div>
            </Button>
          ) : (
            ""
          )}

          {hasAccess(
            MODULE_NAMES.EXTERNAL_PROVIDER,
            PERMISSION_TYPE.DELETE,
            permissions
          ) ? (
            <Button
              className="c-btn c-danger"
              onClick={() => deleteClick(colProps.original)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-trash" />
              </div>
            </Button>
          ) : null}
        </div>
      ) // Custom cell components!
    }
  ];

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  const formAction = (action, data = null) => {
    if (action === "edit") {
      props.history.push(`/external-provider/edit/${data.id}`, { ...data });
    } else if (action === "view") {
      props.history.push(`/external-provider/view/${data.id}`, { ...data });
    }
  };

  function getList(page = "1", field = "created_at", sortBy = "ASC") {
    const params = {
      search: searchText,
      page: page,
      state: stateCode,
      field: field,
      order: sortBy
    };
    setLoader(true);
    ExternalProviderServices.list(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count / TABLE.LIMIT));
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });
  }

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> External Provider List
            </div>
            <div className="mr-10">
              <input
                value={searchText}
                onChange={e => {
                  setSearchText(e.target.value);
                  setLoader(true);
                  setActivePage(0);
                }}
                type="text"
                placeholder="Search..."
                className="fs-14 medium-text plr-10 form-control react-form-input"
              />
            </div>
            <div>
              {hasAccess(
                MODULE_NAMES.EXTERNAL_PROVIDER,
                PERMISSION_TYPE.ADD,
                permissions
              ) ? (
                <Button
                  className="c-btn c-primary ma-5"
                  onClick={() => setModal(true)}
                >
                  <i className="fas fa-plus mr-10" />
                  Add Provider
                </Button>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="roe-card-header module-header">
            <div className="fs-14 mr-10">
              <span className="fs-16">State</span>
              <Dropdown isOpen={stateDropdownOpen} toggle={toggleState}>
                <DropdownToggle caret size="md">
                  {stateId ? stateName : "All"}
                </DropdownToggle>
                <DropdownMenu
                  modifiers={{
                    setMaxHeight: {
                      enabled: true,
                      order: 890,
                      fn: data => {
                        return {
                          ...data,
                          styles: {
                            ...data.styles,
                            overflow: "auto",
                            maxHeight: "300px"
                          }
                        };
                      }
                    }
                  }}
                >
                  <DropdownItem
                    key="-1"
                    onClick={() => {
                      setStateId("");
                      setStateCode("");
                      setActivePage(0);
                    }}
                  >
                    All
                  </DropdownItem>
                  {stateList.length > 0 &&
                    stateList.map((result, i) => {
                      return (
                        <DropdownItem
                          key={i}
                          onClick={() => {
                            setStateId(result.id);
                            setStateCode(`${result.code}`);
                            setStateName(`${result.name}`);
                            setActivePage(0);
                          }}
                        >
                          {result.name}
                        </DropdownItem>
                      );
                    })}
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              LoadingComponent={Loader}
              onFetchData={onChangePageGetData}
              onPageChange={pageIndex => {
                setActivePage(pageIndex);
                getList(pageIndex + 1, field, sortBy);
              }}
              onSortedChange={sortProperties => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getList("1", sortProperties[0].id, sort);
              }}
            />
          </div>
        </div>
      </div>
      <Toaster ref={toaster} />
      <CommonModel
        modal={modal}
        toggle={toggle}
        size="xl"
        children={
          <AddProviderModal
            {...props}
            toggle={toggle}
            pageRefresh={() => {
              getList(activePage + 1);
            }}
          />
        }
      />
      <CommonModel
        modal={deleteModal}
        toggle={deleteToggle}
        children={
          <DeleteModal
            text={"Are you sure you want to delete this provider?"}
            deleteHandler={deleteHandler}
            toggle={deleteToggle}
          />
        }
      />
    </div>
  );
};

export default withRouter(RdTable);
