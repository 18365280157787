import axios from "axios";
import { baseUrl } from "../helper/constant";
import Header from "./Header";
import EncryptDecrypt from "./EncryptDecrypt";
import fileDownload from "js-file-download";

export default class FinancialReportServices {
  static upload(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/admin/upload-financial-report`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static list(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/report/list-rd-financial-report`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static view(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/report/view-rd-financial-report`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static download(params) {
    Header.setHeaders();
    return axios
      .post(
        `${baseUrl}/report/view-rd-financial-report-csv`,
        EncryptDecrypt.encrypt(params)
      )
      .then(res => {
        fileDownload(res.data, "financial-report.csv");
      });
  }
}
