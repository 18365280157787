import styled from "styled-components";

const PageWrapper = styled.div`
  .react-form-inputs {
    border-radius: 1px !important;
    font-size: 14px !important;
    border: 1px solid red !important;
  }
  .box-height {
    height: 200px;
    overflow: scroll;
    background-color: #80808014;
    border-radius: 10px;
  }
  label {
    font-weight: 600;
  }
`;

export default PageWrapper;
