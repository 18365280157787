import React, { useState, useEffect, useRef } from "react";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import ClientServices from "../../api/ClientServices";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import moment from "moment";
import Button from "components/button/Button";
import CommonModel from "../common/CommonModel";
import ProgramDetailsModal from "../viewclient/ProgramDetailsModal";
import { Label } from "reactstrap";
import { hasAccess } from "util/sidebar";
import { MODULE_NAMES, PERMISSION_TYPE } from "helper/constant";

const VisitProgramTable = props => {
  const { permissions } = props;
  const [programDetail, setProgramDetail] = useState(null);
  const [groupList, setGroupList] = useState([]);
  const [editedData, setEditedData] = useState(undefined);
  const [modal, setModal] = useState(undefined);

  const toaster = useRef();

  const hasParam = props.match.params.id;

  function toggle() {
    setModal(false);
  }

  useEffect(() => {
    if (
      hasAccess(
        MODULE_NAMES.CLIENT_PROGRAM_DETAILS,
        PERMISSION_TYPE.VIEW,
        permissions
      )
    ) {
      getList();
      getGroupList();
    }
  }, []);

  function getList() {
    const params = {
      user_id: hasParam
    };
    ClientServices.programDetails(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setProgramDetail(data.data);
          setEditedData(data.data);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current) toaster.current.error(err?.message);
        }
      });
  }

  function getGroupList() {
    const params = {
      service_id: ""
    };
    ClientServices.listGroup(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setGroupList(data.data);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });
  }

  const formAction = (action, data = null) => {
    setModal(true);
  };

  const submitFormHandler1 = (data, action) => {
    setTimeout(() => {
      setModal(false);
      getList();
    }, 2000);
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-6">
          <table className="w-100 border">
            <tbody>
              <tr>
                <th
                  colSpan="2"
                  className="text-center text-white p-2 fs-16"
                  style={{ backgroundColor: "#b0d135" }}
                >
                  Program Details
                </th>
              </tr>
              <tr>
                <td className="w-50 p-3">Group ID</td>
                <td className="w-50 p-3">
                  {programDetail &&
                    programDetail.program_detail &&
                    programDetail.program_detail.group_id}
                </td>
              </tr>
              <tr>
                <td className="w-50 p-3">Group Name</td>
                <td className="w-50 p-3">
                  {programDetail &&
                    programDetail.program_detail &&
                    programDetail.program_detail.group &&
                    programDetail.program_detail.group.name}
                </td>
              </tr>
              <tr>
                <td className="w-50 p-3">Benefit Start</td>
                <td className="w-50 p-3">
                  {programDetail &&
                    programDetail.program_detail &&
                    programDetail.program_detail &&
                    programDetail.program_detail.benefit_start_date &&
                    moment(
                      programDetail.program_detail.benefit_start_date
                    ).format("MM/DD/YYYY")}
                </td>
              </tr>
              <tr>
                <td className="w-50 p-3">Benefit End</td>
                <td className="w-50 p-3">
                  {programDetail &&
                    programDetail.program_detail &&
                    programDetail.program_detail &&
                    programDetail.program_detail.benefit_end_date &&
                    moment(
                      programDetail.program_detail.benefit_end_date
                    ).format("MM/DD/YYYY")}
                </td>
              </tr>
              <tr>
                <td className="w-50 p-3">Program</td>
                <td className="w-50 p-3">
                  {programDetail &&
                    programDetail.program_detail &&
                    programDetail.program_detail.group_visit_program &&
                    programDetail.program_detail.group_visit_program
                      .visit_program &&
                    programDetail.program_detail.group_visit_program
                      .visit_program.name}
                </td>
              </tr>
            </tbody>
          </table>
          <div className="float-right">
            <Button
              className="c-btn ma-5 pl-4 pr-4 text-white"
              style={{ backgroundColor: "#b0d135" }}
              onClick={() => formAction("add")}
              disabled={
                !hasAccess(
                  MODULE_NAMES.CLIENT_PROGRAM_DETAILS,
                  PERMISSION_TYPE.EDIT,
                  permissions
                )
              }
            >
              <strong>Edit Program</strong>
            </Button>
          </div>
        </div>
        <div className="col-md-6">
          <table className="w-100 border">
            <tbody>
              <tr>
                <th
                  colSpan="2"
                  className="text-center text-white p-2 fs-16"
                  style={{ backgroundColor: "#b0d135" }}
                >
                  Program Summary
                </th>
              </tr>
              <tr className="text-center">
                <td className="col-md-12">
                  <div
                    className="p-2"
                    style={{ display: "inline-grid", width: "30%" }}
                  >
                    <Label
                      style={{
                        backgroundColor: "#9b9b9c",
                        color: "white",
                        padding: "10px",
                        marginBottom: "0"
                      }}
                    >
                      Visit
                    </Label>
                    <Label
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px solid #e0e0e0"
                      }}
                    >
                      {programDetail &&
                        programDetail.program_detail &&
                        programDetail.program_detail.group_visit_program &&
                        programDetail.program_detail.group_visit_program
                          .visit_program &&
                        programDetail.program_detail.group_visit_program
                          .visit_program.visits}
                    </Label>
                  </div>
                  <div className="p-2" style={{ display: "inline-grid" }}>
                    <Label
                      style={{
                        backgroundColor: "#9b9b9c",
                        color: "white",
                        padding: "10px",
                        marginBottom: "0"
                      }}
                    >
                      Time (Count)
                    </Label>
                    <Label
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px solid #e0e0e0"
                      }}
                    >
                      {programDetail &&
                        programDetail.program_detail &&
                        programDetail.program_detail.group_visit_program &&
                        programDetail.program_detail.group_visit_program
                          .visit_program &&
                        programDetail.program_detail.group_visit_program
                          .visit_program.time_count}
                    </Label>
                  </div>
                  <div className="p-2" style={{ display: "inline-grid" }}>
                    <Label
                      style={{
                        backgroundColor: "#9b9b9c",
                        color: "white",
                        padding: "10px",
                        marginBottom: "0"
                      }}
                    >
                      Time (Interval)
                    </Label>
                    <Label
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px solid #e0e0e0"
                      }}
                    >
                      {programDetail &&
                        programDetail.program_detail &&
                        programDetail.program_detail.group_visit_program &&
                        programDetail.program_detail.group_visit_program
                          .visit_program &&
                        programDetail.program_detail.group_visit_program
                          .visit_program.time_interval}
                    </Label>
                  </div>
                </td>
              </tr>
              <tr className="text-center">
                <td className="col-md-12">
                  <div
                    className="p-2"
                    style={{ display: "inline-grid", width: "30%" }}
                  >
                    <Label
                      style={{
                        backgroundColor: "#9b9b9c",
                        color: "white",
                        padding: "10px",
                        marginBottom: "0"
                      }}
                    >
                      BMI
                    </Label>
                    <Label
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px solid #e0e0e0"
                      }}
                    >
                      {programDetail &&
                      programDetail.program_detail &&
                      programDetail.program_detail.group_visit_program &&
                      programDetail.program_detail.group_visit_program
                        .visit_program &&
                      programDetail.program_detail.group_visit_program
                        .visit_program.is_bmi === true
                        ? "Yes"
                        : "No"}
                    </Label>
                  </div>
                  <div className="p-2" style={{ display: "inline-grid" }}>
                    <Label
                      style={{
                        backgroundColor: "#9b9b9c",
                        color: "white",
                        padding: "10px",
                        marginBottom: "0"
                      }}
                    >
                      BMI Threshold
                    </Label>
                    <Label
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px solid #e0e0e0"
                      }}
                    >
                      {programDetail &&
                      programDetail.program_detail &&
                      programDetail.program_detail.group_visit_program &&
                      programDetail.program_detail.group_visit_program
                        .visit_program &&
                      programDetail.program_detail.group_visit_program
                        .visit_program.bmi_threshold === null
                        ? "N/A"
                        : programDetail &&
                          programDetail.program_detail &&
                          programDetail.program_detail.group_visit_program
                            .visit_program.bmi_threshold}
                    </Label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <table className="w-100 border mt-3">
            <tbody>
              <tr>
                <th
                  colSpan="2"
                  className="text-center text-white p-2 fs-16"
                  style={{ backgroundColor: "#b0d135" }}
                >
                  Program Utilization
                </th>
              </tr>
              <tr className="text-center">
                <td className="col-md-12">
                  <div
                    className="p-2"
                    style={{ display: "inline-grid", width: "30%" }}
                  >
                    <Label
                      style={{
                        backgroundColor: "#9b9b9c",
                        color: "white",
                        padding: "10px",
                        marginBottom: "0"
                      }}
                    >
                      Visit Used
                    </Label>
                    <Label
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px solid #e0e0e0"
                      }}
                    >
                      {programDetail && programDetail.completed_visit}
                    </Label>
                  </div>
                  <div className="p-2" style={{ display: "inline-grid" }}>
                    <Label
                      style={{
                        backgroundColor: "#9b9b9c",
                        color: "white",
                        padding: "10px",
                        marginBottom: "0"
                      }}
                    >
                      Visit Available
                    </Label>
                    <Label
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        border: "1px solid #e0e0e0"
                      }}
                    >
                      {programDetail &&
                        programDetail.program_detail &&
                        programDetail.program_detail.group_visit_program &&
                        programDetail.program_detail.group_visit_program
                          .visit_program &&
                        programDetail.program_detail.group_visit_program
                          .visit_program.visits - programDetail.completed_visit}
                    </Label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <CommonModel
        modal={modal}
        toggle={toggle}
        children={
          <ProgramDetailsModal
            onSubmit1={submitFormHandler1}
            editedData={editedData}
            groupList={groupList}
            action={editedData ? "edit" : "add"}
            toggle={toggle}
            {...props}
          />
        }
        header={editedData ? "Edit Program" : "Add Program"}
      />
    </div>
  );
};
export default withRouter(VisitProgramTable);
