import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import RdServices from "../../api/RdServices";
import Loader from "../common/Loader";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
import moment from "moment";

const RdPerformanceMetricTable = props => {
  const [tblData, settblData] = useState();
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("month_year");
  const [sortBy, setSortBy] = useState("ASC");
  const [parentDropdownOpen, setParentDropdownOpen] = useState(false);
  const [summaryData, setSummaryData] = useState("");
  const [year, setYear] = useState(moment().format("YYYY"));

  const toaster = useRef();
  const toggleOne = () => setParentDropdownOpen(prevState => !prevState);

  const hasParam = props.adminId;

  const rangeOfYears = (start, end) =>
    Array(end - start + 1)
      .fill(start)
      .map((year, index) => year + index);

  let years = rangeOfYears(1900, new Date().getFullYear() + 50);

  useEffect(() => {
    getList(field, sortBy, searchText, activePage + 1, year);
    setPages(10);
  }, [year]);

  useEffect(() => {
    getList(field, sortBy, searchText, activePage + 1, year);
    setPages(10);

    const params = {
      rd_id: hasParam
    };
    RdServices.summary(params).then(res => {
      let data = EncryptDecrypt.decrypt(res.data);
      setSummaryData(data.data);
    });
  }, []);

  const columns = [
    {
      Header: "Month",
      accessor: "month_year",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true
    },
    {
      Header: "Units",
      accessor: "unit",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true
    },
    {
      Header: "Goal",
      accessor: "goal",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true
    }
  ];

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  function getList(
    field = "month_year",
    sortBy = "ASC",
    search = "",
    page = "1",
    year = year
  ) {
    const params = {
      sort_by: field,
      order: sortBy,
      search: search,
      page: page,
      rd_id: hasParam,
      year: year
    };
    setLoader(true);
    RdServices.goalList(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count / 12));
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });
  }

  return (
    <div>
      <div className="fs-16 demi-bold-text mb-3">Monthly Summary</div>
      <div className="module-header p-2 mb-3">
        <div style={{ display: "inline-grid", paddingRight: "3rem" }}>
          <lable
            style={{
              backgroundColor: "#9b9b9c",
              color: "white",
              padding: "10px"
            }}
          >
            Total Client
          </lable>
          <lable
            style={{
              padding: "10px",
              textAlign: "center",
              border: "1px solid #e0e0e0"
            }}
          >
            {summaryData && summaryData.total_client}
          </lable>
        </div>

        <div style={{ display: "inline-grid" }}>
          <lable
            style={{
              backgroundColor: "#9b9b9c",
              color: "white",
              padding: "10px"
            }}
          >
            Active Client
          </lable>
          <lable
            style={{
              padding: "10px",
              textAlign: "center",
              border: "1px solid #e0e0e0"
            }}
          >
            {summaryData && summaryData.active_client}
          </lable>
        </div>
      </div>
      <div className="module-header">
        <div className="flex-1 fs-16 demi-bold-text">Performance vs. Goal </div>
        <div className="fs-14 mr-10">
          <Dropdown isOpen={parentDropdownOpen} toggle={toggleOne}>
            <DropdownToggle caret size="md">
              {year ? year : "Year"}
            </DropdownToggle>
            <DropdownMenu
              modifiers={{
                setMaxHeight: {
                  enabled: true,
                  order: 890,
                  fn: data => {
                    return {
                      ...data,
                      styles: {
                        ...data.styles,
                        overflow: "auto",
                        maxHeight: "300px"
                      }
                    };
                  }
                }
              }}
            >
              {years.length > 0 &&
                years.map((result, i) => {
                  return (
                    <DropdownItem key={i} onClick={() => setYear(result)}>
                      {result}
                    </DropdownItem>
                  );
                })}
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
      <ReactTable
        style={{
          border: "none",
          boxShadow: "none"
        }}
        data={tblData}
        columns={columns}
        defaultPageSize={10}
        minRows={2}
        manual
        defaultFilterMethod={(filter, row) => {
          const id = filter.pivotId || filter.id;

          return row[id] !== undefined
            ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
            : true;
        }}
        className="-striped -highlight custom-react-table-theme-class"
        pages={pages}
        page={activePage}
        PaginationComponent={Pagination}
        loading={loader}
        LoadingComponent={Loader}
        onFetchData={onChangePageGetData}
        onPageChange={pageIndex => {
          setActivePage(pageIndex);
          getList(field, sortBy, searchText, pageIndex + 1, year);
        }}
        onSortedChange={sortProperties => {
          const sort = sortProperties[0].desc ? "DESC" : "ASC";
          setSortBy(sort);
          setActivePage(0);
          setField(sortProperties[0].id);
          getList(sortProperties[0].id, sort, searchText, 1, year);
          // alert("sort handler", sortProperties);
          // const orderByDirection = sortProperties[0].desc ? 1 : 0;

          // updateMapStateInfoOrderByColumn(sortProperties[0].id, orderByDirection);
        }}
      />
    </div>
  );
};
export default withRouter(RdPerformanceMetricTable);
