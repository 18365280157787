import React, { Fragment, useState } from "react";
// import {
//   Dropdown,
//   DropdownToggle,
//   DropdownItem,
//   DropdownMenu
// } from "reactstrap";
import ProfileWrapper from "./profile.style";
import enhancer from "./validator";
import { compose } from "redux";
// import Button from "components/button/Button";
// import { ImageResolution } from "../../helper/constant";
import RegisteredDietitian from "../../api/RegisteredDietitian";
import AuthActions from "redux/auth/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  CALENDLY_CLIENT_ID,
  CALENDLY_REDIRECT_URL
} from "../../helper/constant";
const { login } = AuthActions;
const StepFourCalendly = props => {
  const {
    // handleChange,
    // handleBlur,
    // setFieldTouched,
    // setFieldValue,
    submitCount,
    errors,
    // values,
    touched
  } = props;
  // let categoryList = [
  //   'Weight Management',
  //   'Disease Management',
  //   'Healthy Lifestyle',
  // ]
  const [parentDropdownOpen, setParentDropdownOpen] = useState(false);
  const [secondDropdownOpen, setSecondDropdownOpen] = useState(false);
  const [parentCategoryId, setParentCategoryId] = useState(null);
  const [secondCategoryId, setSecondCategoryId] = useState(null);

  const [preview, setPreview] = useState(null);

  const toggleOne = () => setParentDropdownOpen(prevState => !prevState);
  const toggleTwo = () => setSecondDropdownOpen(prevState => !prevState);

  const [imageObj, setImageObj] = useState(null);
  const [uploadImage, setUploadImage] = useState(null);
  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;
    if (isValid) {
      values.primary_category_id = parentCategoryId;
      values.secondary_category_id = secondCategoryId;
      values.image = imageObj;
      RegisteredDietitian.completeProfile(values)
        .then(result => {
          let loginData = {
            ...props.auth,
            step: 3
          };
          props.login(loginData);
        })
        .catch(error => {
          console.log("handleSubmit -> error", error);
        });
    }
    handleSubmit();
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const openCalendly = () => {
    window.open(
      "https://auth.calendly.com/oauth/authorize?client_id=" +
        CALENDLY_CLIENT_ID +
        "&response_type=code&redirect_uri=" +
        CALENDLY_REDIRECT_URL,
      "_target"
    );
    // props.history.push("/login")
    setTimeout(() => {
      let data = {
        ...props.auth,
        isLogin: false
      };
      props.login(data);
      // props.history.push("/login")
    }, 1500);
  };

  return (
    <Fragment>
      <ProfileWrapper {...props}>
        <div className="profile-head d-flex justify-content-center ">
          <b>
            Note:
            <br />
            1. By connecting your calendly account, you are giving the HUSK
            portal access to your scheduled meetings.
            <br />
            2. The email account used for Calendly must match the email account
            in the HUSK portal.
          </b>
        </div>
        {!props.isModal && (
          <form onClick={() => openCalendly()}>
            <div class="row  d-flex justify-content-center mt-50 mb-50">
              <div class="col-md-6 calendly-btn  d-flex justify-content-center align-items-center">
                <h4>Connect with Calendly</h4>
              </div>
            </div>
          </form>
        )}
      </ProfileWrapper>
    </Fragment>
  );
};
const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};
export default compose(
  withRouter,
  enhancer,
  connect(mapStateToProps, { login })
)(StepFourCalendly);
