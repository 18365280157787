import React, { useEffect, useRef, useState } from "react";
import { compose } from "redux";
import enhancer from "../Rewards/productValidator";
import EncryptDecrypt from "api/EncryptDecrypt";
import { PLACEHOLDER } from "helper/constant";
import Button from "components/button/Button";
import RewardsManagementServices from "api/RewardsMangementServices";
import Toaster from "components/common/Toaster";

const exceptThisSymbols = ["e", "E", "+", "-", "."];

const EditProductModal = props => {
  const toaster = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleChange,
    handleBlur,
    submitCount,
    errors,
    values,
    touched,
    editedData,
    handleSubmit
  } = props;

  const handleFormSubmit = () => {
    let { values, isValid } = props;
    if (isValid) {
      const params = {
        product_id: editedData?.id,
        point: values.point
      };
      setIsLoading(true);
      RewardsManagementServices.edit(params)
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          if (data.status) {
            if (toaster.current) toaster.current.success(data.message);
            setTimeout(() => {
              props.toggle();
              props.pageRefresh();
              setIsLoading(false);
            }, 1000);
          } else {
            if (toaster.current) toaster.current.error(data.message);
            setIsLoading(false);
          }
        })
        .catch(err => {
          if (toaster.current) toaster.current.error(err.response);
          setIsLoading(false);
        });
    }
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <div className={props.class ? props.class : "error-msg mt-2"}>
          {errors[field1]}
        </div>
      );
    } else {
      return <span />;
    }
  };

  return (
    <div className="p-2">
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">Name</label>
            <input
              type="text"
              className="form-control react-form-input"
              id="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              disabled={true}
            />
          </div>
          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">SKU Number</label>
            <input
              type="text"
              className="form-control react-form-input"
              id="skuNumber"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.skuNumber}
              disabled={true}
            />
          </div>

          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">Price</label>
            <input
              type="number"
              className="form-control react-form-input"
              id="point"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.price}
              disabled={true}
            />
          </div>

          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">
              Points <span className="asterisk">*</span>
            </label>
            <input
              type="number"
              className="form-control react-form-input"
              id="point"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.point}
              placeholder="Add point"
              onKeyDown={e =>
                exceptThisSymbols.includes(e.key) && e.preventDefault()
              }
            />
            <Error field="point" />
          </div>

          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">Image</label>
            <div>
              {
                <img
                  src={values.image || PLACEHOLDER}
                  alt=""
                  className="mtb-15"
                  style={{
                    width: "200px",
                    borderRadius: "4%",
                    height: "150px",
                    background: "#404040"
                  }}
                />
              }
            </div>
          </div>
        </div>
        <div>
          <Button
            type="submit"
            className="c-btn c-info form-button fs-16 demi-bold-text"
            style={{ maxWidth: "125px" }}
            onClick={handleFormSubmit}
            loading={isLoading}
            disabled={isLoading}
            dataStyle={"expand-right"}
          >
            Submit
          </Button>
        </div>
      </form>
      <Toaster ref={toaster} />
    </div>
  );
};
export default compose(enhancer)(EditProductModal);
