import React, { useRef } from "react";
import { compose } from "redux";
import enhancer from "./validator";
import Button from "../button/Button";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import CptCodeServices from "../../api/CptCodeServices";
import Toaster from "../common/Toaster";

const CptCodeForm = props => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    setFieldValue,
    onSubmit,
    values,
    action,
    insuranceList
  } = props;

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };
  const toaster = useRef();

  const checkHandleChange = e => {
    const targtValue = parseInt(e.currentTarget.value);

    let objIndex = values.insurance.findIndex(obj => obj === targtValue);
    if (objIndex > -1) {
      values.insurance.splice(objIndex, 1);
      setFieldValue("insurance", values.insurance);
    } else {
      values.insurance.push(targtValue);
      setFieldValue("insurance", values.insurance);
    }
  };

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;
    if (values.name !== "" && values.insurance.length > 0) {
      isValid = true;
    }
    if (isValid) {
      onSubmit(values, action);
      if (action === "add") {
        const params = {
          name: values.name,
          code: values.code,
          insurances: values.insurance
        };
        CptCodeServices.add(params)
          .then(res => {
            let data = EncryptDecrypt.decrypt(res.data);
            if (data.status) {
              if (toaster.current) toaster.current.success(data.message);
            } else {
              if (toaster.current) toaster.current.error(data.message);
            }
          })
          .catch(err => {
            if (toaster.current) toaster.current.error(err.response);
          });
      } else if (action === "edit") {
        const params = {
          id: values.id,
          name: values.name,
          code: values.code,
          insurances: values.insurance
        };
        CptCodeServices.edit(params)
          .then(res => {
            let data = EncryptDecrypt.decrypt(res.data);
            if (data.status) {
              if (toaster.current) toaster.current.success(data.message);
            } else {
              if (toaster.current) toaster.current.error(data.message);
            }
          })
          .catch(err => {
            if (toaster.current) toaster.current.error(err.response);
          });
      }
    }

    handleSubmit();
  };

  return (
    <div>
      <form>
        <div className="row">
          <div className="col-md-6 form-group">
            <label className="fs-16 medium-text">
              Name <span className="asterisk">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              placeholder="Add Cpt Code"
            />
            <Error field="name" />
          </div>
          <div className="col-md-6 form-group">
            <label className="fs-16 medium-text">Code</label>
            <input
              type="text"
              className="form-control react-form-input"
              id="code"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.code}
              placeholder="Add Cpt Code"
            />
            <Error field="code" />
          </div>
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Insurance <span className="asterisk">*</span>
          </label>
          <br />
          {insuranceList &&
            insuranceList.map((type, i) => {
              return (
                <div key={i}>
                  <input
                    type="checkbox"
                    name={`other_insurance_id${type.id}`}
                    onChange={checkHandleChange}
                    value={type.id}
                    checked={
                      values.insurance && values.insurance.includes(type.id)
                        ? "checked"
                        : false
                    }
                  />{" "}
                  {type.name}
                  <br />
                </div>
              );
            })}
          <Error field="insurance" />
        </div>
        <div>
          <Button
            type="button"
            className="c-btn c-info form-button fs-16 demi-bold-text"
            style={{ maxWidth: "125px" }}
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </div>
      </form>
      <Toaster ref={toaster} />
    </div>
  );
};

export default compose(enhancer)(CptCodeForm);
