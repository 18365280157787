import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    visit_notes: Yup.string()
      .trim()
      .required("This field is required"),
    addToNextVisist: Yup.boolean()
  }),
  mapPropsToValues: props => {
    return {
      id: props.editedData ? props.editedData.id : "",
      visit_notes: props.editedData ? props.editedData.visit_note : "",
      addToNextVisist: props?.editedData?.copy_to_next_visit
        ? props?.editedData?.copy_to_next_visit
        : false
    };
  },
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true,
  isInitialValid: props =>
    props.editedData && props.editedData.action === "edit" ? true : false
});

export default formikEnhancer;
