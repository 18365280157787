import React from "react";
import "./Shimmer.css";

const Shimmer = ({
  width = "100%",
  height = "16px",
  borderRadius = "4px",
  className = ""
}) => {
  return (
    <div
      className={`shimmer-wrapper ${className}`}
      style={{
        width,
        height,
        borderRadius
      }}
    >
      <div className="shimmer"></div>
    </div>
  );
};

export default Shimmer;
