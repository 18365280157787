import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    goal_id: Yup.string().required("This field is required"),
    goal_sentence: Yup.string().required("This field is required")
  }),
  mapPropsToValues: props => ({
    goal_id: props.goalData ? props.goalData.id : "",
    goal_sentence: props.goalData ? props.goalData.goal_sentence : ""
  }),

  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true,

  isInitialValid: props => (props.editedData ? true : false)
});

export default formikEnhancer;
