import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    notification_text: Yup.string()
      .trim()
      .required("This field is required")
      .max(500, "Name must be less than 500 characters"),
    user_type: Yup.string()
      .trim()
      .required("This field is required")
  }),
  mapPropsToValues: props => ({
    id: props.editedData
      ? props && props.editedData && props.editedData.id
      : "",
    name: props.editedData
      ? props && props.editedData && props.editedData.name
      : "",
    is_display_registration: props.editedData
      ? props &&
        props.editedData &&
        props.editedData.is_display_registration.toString()
      : "1"
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
