import React, { useEffect, useRef, useState } from "react";
import { compose } from "redux";
import enhancer from "./edit-validator";
import Button from "../button/Button";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import ClientServices from "../../api/ClientServices";
import Toaster from "../common/Toaster";
import { Input } from "reactstrap";
import { ImageResolution } from "../../helper/constant";
import { PLACEHOLDER } from "helper/constant";
import DatePicker from "react-datepicker";

const genderList = [
  {
    key: "M",
    value: "Male"
  },
  {
    key: "F",
    value: "Female"
  },
  {
    key: "U",
    value: "Unknown"
  }
];

const ClientEditForm = props => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    setFieldTouched,
    setFieldValue,
    onSubmit,
    values,
    action,
    category,
    state
  } = props;

  const toaster = useRef();
  const [imageObj, setImageObj] = useState(null);
  const [uploadImage, setUploadImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const handleImage = (e, setValue) => {
    if (e.target.files[0]) {
      let fileObj = e.target.files[0];

      if (fileObj.size > 10 * 1024 * 1024) {
        if (toaster.current)
          toaster.current.error("Please upload a file smaller than 10 MB");
        return false;
      }

      if (!fileObj.name.match(/\.(jpg|jpeg|png|gif|heif)$/)) {
        if (toaster.current)
          toaster.current.error(
            "Only .jpg, .jpeg, .gif, .png, .heif are allowed"
          );
        return false;
      }

      if (fileObj) {
        const img = new Image();

        img.src = window.URL.createObjectURL(fileObj);

        img.onload = function() {
          const width = img.naturalWidth,
            height = img.naturalHeight;

          window.URL.revokeObjectURL(img.src);
          setValue("width", width);
          setValue("height", height);
          setFieldTouched("profile_photo", true, true);
          if (
            width >= ImageResolution.WIDTH &&
            height >= ImageResolution.HEIGHT
          ) {
            setImageObj(fileObj);
            setValue("profile_photo", URL.createObjectURL(fileObj));
            setUploadImage(URL.createObjectURL(fileObj));
          }
        };
      } else {
        //No file was input or browser doesn't support client side reading
        // form.submit();
      }
    } else {
      setUploadImage();
    }
  };

  const changeFormat = (e, name) => {
    const invalidStrings = ["() -", "()", "() ", ""];
    if (invalidStrings.includes(e.target.value)) {
      setFieldValue(name, "");
    } else {
      var x = e.target.value
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      let no = "(" + x[1] + ") " + x[2] + "-" + x[3];
      setFieldValue(name, no);
    }
  };

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;
    if (isValid) {
      setIsLoading(true);
      const params = {
        id: values.id,
        first_name: values.first_name,
        email: values.email,
        last_name: values.last_name,
        // category_id: values.category_id,
        zipcode: values.zipcode,
        address: values.address,
        city: values.city,
        state_id: values.state_id,
        dob: values.dob,
        phone_number: values.phone_number.replace(/[()-\s]/g, ""),
        gender: values.gender,
        emergency_name: values.emergency_name,
        emergency_phone_number: values.emergency_phone_number,
        practice_id:
          props.auth && props.auth?.adminRoleId === 6 && props.auth?.practice
            ? props.auth?.practice
            : undefined
      };

      const formdata = new FormData();
      const encryptData = EncryptDecrypt.encrypt(params);
      formdata.append("data", encryptData?.data);
      if (imageObj) formdata.append("data1", imageObj);

      ClientServices.edit(formdata)
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          if (data.status) {
            if (toaster.current) toaster.current.success(data.message);
            onSubmit(values, action);
            setIsLoading(false);
          } else {
            if (toaster.current) toaster.current.error(data.message);
            setIsLoading(false);
          }
        })
        .catch(err => {
          if (toaster.current) toaster.current.error(err.response);
          setIsLoading(false);
        });
    }
    handleSubmit();
  };

  return (
    <div>
      <form>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">
              First Name <span className="asterisk">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="first_name"
              onChange={e => {
                setFieldValue("first_name", e.target.value);
                setFieldTouched("first_name", true, true);
              }}
              onBlur={handleBlur}
              value={values.first_name}
              placeholder="Add First Name"
            />
            <Error field="first_name" />
          </div>
          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">
              Last Name <span className="asterisk">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="last_name"
              onChange={e => {
                setFieldValue("last_name", e.target.value);
                setFieldTouched("last_name", true, true);
              }}
              onBlur={handleBlur}
              value={values.last_name}
              placeholder="Add Last Name"
            />
            <Error field="last_name" />
          </div>
          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">
              Email <span className="asterisk">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              placeholder="Add Email"
              //readOnly
            />
            <Error field="email" />
          </div>
          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">Phone number</label>
            <input
              type="text"
              className="form-control react-form-input"
              id="phone_number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phone_number}
              placeholder="Add Phone Number"
              onKeyUp={value => changeFormat(value, "phone_number")}
            />
            <Error field="phone_number" />
          </div>
          {/* <div className="form-group col-md-6">
            <label className="fs-16 medium-text">
              Category<span className="asterisk"> *</span>
            </label>
            <Input
              type="select"
              name="category_id"
              id="category_id"
              onChange={handleChange}
              value={values.category_id}
              onBlur={handleBlur}
            >
              <option value="">Select Category</option>
              {category.map((item, i) => {
                return (
                  <option key={`option${i}`} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </Input>
            <Error field="category_id" />
          </div> */}
          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">
              Address <span className="asterisk">*</span>
            </label>
            <textarea
              className="form-control react-form-input"
              rows="3"
              placeholder="Add Address"
              id="address"
              name="address"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.address}
            ></textarea>
            <Error field="address" />
          </div>
          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">
              City <span className="asterisk">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="city"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.city}
              placeholder="Add City"
            />
            <Error field="city" />
          </div>
          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">
              State<span className="asterisk"> *</span>
            </label>
            <Input
              type="select"
              name="state_id"
              id="state_id"
              onChange={handleChange}
              value={values.state_id}
              onBlur={handleBlur}
            >
              <option value="">Select State</option>
              {state.map((item, i) => {
                return (
                  <option key={`option${i}`} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </Input>
            <Error field="state_id" />
          </div>
          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">
              Zipcode <span className="asterisk">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="zipcode"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.zipcode}
              placeholder="Add Zipcode"
            />
            <Error field="zipcode" />
          </div>
          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">
              Date of Birth <span className="asterisk">*</span>
            </label>
            <div>
              <input
                type="date"
                className="form-control react-form-input"
                id="dob"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.dob}
                placeholder="Add DOB"
              />
              {/* <DatePicker
                className="form-control"
                selected={values.dob}
                id="dob"
                onChange={e => {
                  setFieldValue("dob", e);
                }}
                dateFormat="MM/dd/yyyy"
                placeholder="Add Date of Birth"
              /> */}
            </div>
            <Error field="dob" />
          </div>
          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">
              Gender<span className="asterisk"> *</span>
            </label>
            <Input
              type="select"
              name="gender"
              id="gender"
              onChange={handleChange}
              value={values.gender}
              onBlur={handleBlur}
            >
              <option value="">Select Gender</option>
              {genderList.map((item, i) => {
                return (
                  <option key={`option${i}`} value={item.key}>
                    {item.value}
                  </option>
                );
              })}
            </Input>
            <Error field="gender" />
          </div>
          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">Emergency Contact Name</label>
            <input
              type="text"
              className="form-control react-form-input"
              id="emergency_name"
              onChange={e => {
                setFieldValue("emergency_name", e.target.value);
                setFieldTouched("emergency_name", true, true);
              }}
              onBlur={handleBlur}
              value={values.emergency_name}
              placeholder="Add Emergency Contact Name"
            />
            <Error field="emergency_name" />
          </div>
          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">Emergency Contact Phone</label>
            <input
              type="text"
              className="form-control react-form-input"
              id="emergency_phone_number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.emergency_phone_number}
              placeholder="Add Emergency Contact Phone Number"
              onKeyUp={value => changeFormat(value, "emergency_phone_number")}
            />
            <Error field="emergency_phone_number" />
          </div>
          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">
              Profile Photo<span className="asterisk"> *</span>
            </label>
            <br />
            <div className="file-upload">
              <label className="c-btn c-primary form-button fs-16 demi-bold-text mt-15">
                Upload image
                <input
                  id="profile_photo"
                  className="file-upload__input"
                  name="file-upload"
                  type="file"
                  accept="image/jpg,image/png,image/jpeg"
                  onChange={e => handleImage(e, setFieldValue)}
                  onBlur={handleBlur}
                  onClick={event => {
                    event.target.value = "";
                  }}
                />
              </label>
            </div>
            <br />
            {
              <img
                src={
                  uploadImage
                    ? uploadImage
                    : values.profile_photo || PLACEHOLDER
                }
                alt=""
                className="mtb-15"
                style={{
                  width: "200px",
                  borderRadius: "4%",
                  height: "150px",
                  background: "#404040"
                }}
              />
            }
            <br />
            <Error field="profile_photo" />
          </div>
        </div>
        <div>
          <Button
            type="button"
            className="c-btn c-info form-button fs-16 demi-bold-text"
            style={{ maxWidth: "125px" }}
            onClick={() => handleSubmit()}
            loading={isLoading}
            disabled={isLoading}
          >
            Submit
          </Button>
        </div>
      </form>
      <Toaster ref={toaster} />
    </div>
  );
};

export default compose(enhancer)(ClientEditForm);
