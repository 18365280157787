import React, { useState, useEffect } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import RdBasicView from "../rd/RdBasicViewDetails";
import InsuranceTab from "../rd/InsuranceTab";
import PerformanceTab from "../rd/PerformanceTab";
import CdrTab from "../rd/CdrTab";
import StateLicenseTab from "../rd/StateLicenseTab";
import CaqhTab from "../rd/CaqhTab";
// import StateTab from "../rd/StateTab";
import LiabilityInsuranceTab from "../rd/LiabilityInsuranceTab";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import RdServices from "../../api/RdServices";
import GroupServices from "../../api/GroupServices";
import RdSettings from "./RdSettings";
import BusinessServiceAgreementTab from "./BusinessServiceAgreementTab";
import { MODULE_NAMES, PERMISSION_TYPE, SERVICE_TYPE } from "helper/constant";
import CategoryTab from "./CategoryTab";
import ReferralCodeTable from "./ReferralCodeTable";
import CalendlyTab from "./CalendlyTab";
import { hasAccess } from "util/sidebar";

const RdViewDetails = props => {
  const { permissions } = props;
  const [activeTab, setActiveTab] = useState("1");
  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const [editedData, setEditedData] = useState(null);
  const [insuranceData, setInsuranceData] = useState([]);
  const [insuranceList, setInsuranceList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [disableTab, setDisableTab] = useState(false);
  const [stateData, setStateData] = useState([]);
  const [stateList, setStateList] = useState([]);
  const hasParam = props.match.params.id;
  const type = props.location?.state?.type;
  const serviceId = props.location?.state?.service?.id;
  const step = props.location?.state?.step;

  useEffect(() => {
    if (step === "1") {
      setActiveTab("1");
      setDisableTab(true);
    } else if (step === "2") {
      setActiveTab("2");
      setDisableTab(true);
    } else if (step === "3") {
      setActiveTab("11");
      setDisableTab(true);
    } else if (step === "4") {
      setActiveTab("4");
      setDisableTab(true);
    } else if (step === "5") {
      setActiveTab("5");
      setDisableTab(false);
    }
  }, [step]);

  useEffect(() => {
    if (
      hasAccess(
        MODULE_NAMES.PROVIDER_MANAGEMENT_CATEGORIES,
        PERMISSION_TYPE.VIEW,
        permissions
      )
    ) {
      if (editedData) {
        RdServices.listCategory({
          service_id: editedData?.rd_detail?.service_id
        }).then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          setCategoryList(data.data);
        });
      }
    }
  }, [editedData]);

  useEffect(() => {
    if (hasParam) {
      const params = {
        id: hasParam
      };
      RdServices.view(params).then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        setEditedData(data.data);
      });

      if (
        hasAccess(
          MODULE_NAMES.PROVIDER_MANAGEMENT_INSURANCE_CARRIERS,
          PERMISSION_TYPE.VIEW,
          permissions
        )
      ) {
        RdServices.rdInsuranceList(params).then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          setInsuranceData(data.data);
        });
      }

      if (
        hasAccess(
          MODULE_NAMES.PROVIDER_MANAGEMENT_CATEGORIES,
          PERMISSION_TYPE.VIEW,
          permissions
        )
      ) {
        RdServices.rdCategoryList(params).then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          setCategoryData(data.data);
        });
      }

      if (
        hasAccess(
          MODULE_NAMES.PROVIDER_MANAGEMENT_INSURANCE_CARRIERS,
          PERMISSION_TYPE.VIEW,
          permissions
        )
      ) {
        GroupServices.insuranceList().then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          setInsuranceList(data.data);
        });
      }

      RdServices.rdStateList(params).then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        setStateData(data.data);
      });

      RdServices.stateList().then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        setStateList(data.data);
      });
    }
  }, []);

  const submitFormHandler = (data, action, activeTab) => {
    setActiveTab(activeTab);
  };

  return (
    <div className="plr-15">
      <div className="roe-card-style mtb-15">
        <div className="roe-card-header module-header">
          <div className="flex-1 fs-16 demi-bold-text">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab === "1" },
                    "doc-title"
                  )}
                  onClick={() => {
                    toggle("1");
                  }}
                  disabled={disableTab}
                >
                  Profile
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab === "2" },
                    "doc-title"
                  )}
                  onClick={() => {
                    toggle("2");
                  }}
                  disabled={
                    disableTab ||
                    !hasAccess(
                      MODULE_NAMES.PROVIDER_MANAGEMENT_SETTINGS,
                      PERMISSION_TYPE.VIEW,
                      permissions
                    )
                  }
                >
                  Settings
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab === "12" },
                    "doc-title"
                  )}
                  onClick={() => {
                    toggle("12");
                  }}
                  disabled={disableTab}
                >
                  Calendly
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab === "3" },
                    "doc-title"
                  )}
                  onClick={() => {
                    toggle("3");
                  }}
                  disabled={
                    disableTab ||
                    !hasAccess(
                      MODULE_NAMES.PROVIDER_REGISTRATION_CODE,
                      PERMISSION_TYPE.VIEW,
                      permissions
                    )
                  }
                >
                  Registration Codes
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab === "11" },
                    "doc-title"
                  )}
                  onClick={() => {
                    toggle("11");
                  }}
                  disabled={
                    disableTab ||
                    !hasAccess(
                      MODULE_NAMES.PROVIDER_MANAGEMENT_CATEGORIES,
                      PERMISSION_TYPE.VIEW,
                      permissions
                    )
                  }
                >
                  Categories
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab === "4" },
                    "doc-title"
                  )}
                  onClick={() => {
                    toggle("4");
                  }}
                  disabled={
                    disableTab ||
                    !hasAccess(
                      MODULE_NAMES.PROVIDER_MANAGEMENT_STATE_LICENCES,
                      PERMISSION_TYPE.VIEW,
                      permissions
                    )
                  }
                >
                  State Licenses
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab === "5" },
                    "doc-title"
                  )}
                  onClick={() => {
                    toggle("5");
                  }}
                  disabled={
                    disableTab ||
                    !hasAccess(
                      MODULE_NAMES.PROVIDER_MANAGEMENT_INSURANCE_CARRIERS,
                      PERMISSION_TYPE.VIEW,
                      permissions
                    )
                  }
                >
                  Insurance Carriers
                </NavLink>
              </NavItem>
              {serviceId && serviceId === SERVICE_TYPE.NUTRITION && (
                <NavItem>
                  <NavLink
                    className={classnames(
                      { active: activeTab === "6" },
                      "doc-title"
                    )}
                    onClick={() => {
                      toggle("6");
                    }}
                    disabled={
                      disableTab ||
                      !hasAccess(
                        MODULE_NAMES.PROVIDER_MANAGEMENT_PERFORMANCE_METRICS,
                        PERMISSION_TYPE.VIEW,
                        permissions
                      )
                    }
                  >
                    Performance Metrics
                  </NavLink>
                </NavItem>
              )}
              {serviceId && serviceId === SERVICE_TYPE.NUTRITION && (
                <NavItem>
                  <NavLink
                    className={classnames(
                      { active: activeTab === "7" },
                      "doc-title"
                    )}
                    onClick={() => {
                      toggle("7");
                    }}
                    disabled={
                      disableTab ||
                      !hasAccess(
                        MODULE_NAMES.PROVIDER_MANAGEMENT_CDR,
                        PERMISSION_TYPE.VIEW,
                        permissions
                      )
                    }
                  >
                    CDR
                  </NavLink>
                </NavItem>
              )}
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab === "8" },
                    "doc-title"
                  )}
                  onClick={() => {
                    toggle("8");
                  }}
                  disabled={
                    disableTab ||
                    !hasAccess(
                      MODULE_NAMES.PROVIDER_MANAGEMENT_CAQH,
                      PERMISSION_TYPE.VIEW,
                      permissions
                    )
                  }
                >
                  CAQH
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab === "9" },
                    "doc-title"
                  )}
                  onClick={() => {
                    toggle("9");
                  }}
                  disabled={
                    disableTab ||
                    !hasAccess(
                      MODULE_NAMES.PROVIDER_MANAGEMENT_LIABILITY_INSURANCE,
                      PERMISSION_TYPE.VIEW,
                      permissions
                    )
                  }
                >
                  Liability Insurance
                </NavLink>
              </NavItem>
              {type && type === "ibo" && (
                <NavItem>
                  <NavLink
                    className={classnames(
                      { active: activeTab === "10" },
                      "doc-title"
                    )}
                    onClick={() => {
                      toggle("10");
                    }}
                    disabled={
                      disableTab ||
                      !hasAccess(
                        MODULE_NAMES.PROVIDER_MANAGEMENT_EDIT_BUSINESS_SERVICES_AGREEMENT,
                        PERMISSION_TYPE.VIEW,
                        permissions
                      )
                    }
                  >
                    Business Services Agreement
                  </NavLink>
                </NavItem>
              )}
            </Nav>
          </div>
        </div>
        <div className="roe-card-body">
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <RdBasicView {...props} />
            </TabPane>
            <TabPane tabId="2">
              <RdSettings {...props} />
            </TabPane>
            <TabPane tabId="3">
              <ReferralCodeTable
                service={serviceId || editedData?.rd_detail?.service_id}
                {...props}
              />
            </TabPane>

            <TabPane tabId="4">
              <StateLicenseTab {...props} />
              {/* <StateTab
                onSubmit={submitFormHandler}
                editedData={editedData}
                stateData={stateData}
                stateList={stateList}
                {...props}
              /> */}
            </TabPane>
            <TabPane tabId="5">
              <InsuranceTab
                onSubmit={submitFormHandler}
                editedData={editedData}
                insuranceData={insuranceData}
                insuranceList={insuranceList}
                {...props}
              />
            </TabPane>
            <TabPane tabId="11">
              <CategoryTab
                onSubmit={submitFormHandler}
                editedData={editedData}
                insuranceData={categoryData}
                insuranceList={categoryList}
                {...props}
              />
            </TabPane>
            {serviceId && serviceId === SERVICE_TYPE.NUTRITION && (
              <TabPane tabId="6">
                <PerformanceTab {...props} />
              </TabPane>
            )}
            {serviceId && serviceId === SERVICE_TYPE.NUTRITION && (
              <TabPane tabId="7">
                <CdrTab {...props} />
              </TabPane>
            )}
            <TabPane tabId="8">
              <CaqhTab {...props} />
            </TabPane>
            <TabPane tabId="9">
              <LiabilityInsuranceTab {...props} />
            </TabPane>
            {type && type === "ibo" && (
              <TabPane tabId="10">
                <BusinessServiceAgreementTab {...props} />
              </TabPane>
            )}
            <TabPane tabId="12">
              <CalendlyTab {...props} />
            </TabPane>
          </TabContent>
        </div>
      </div>
    </div>
  );
};
export default RdViewDetails;
