import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import Loader from "../common/Loader";
import Toaster from "../common/Toaster";
import { MODULE_NAMES, PERMISSION_TYPE, TABLE } from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import DeleteModal from "components/common/DeleteModal";
import CommonModel from "components/common/CommonModel";
import FaxManagementServices from "api/FaxMangementServices";
import { hasAccess } from "util/sidebar";

const FaxTable = props => {
  const { permissions } = props;
  const [tblData, settblData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("id");
  const [sortBy, setSortBy] = useState("ASC");
  const [deleteModal, setDeleteModal] = useState(false);
  const [id, setId] = useState(undefined);
  const toaster = useRef();

  useEffect(() => {
    getList(field, sortBy, searchText, activePage + 1);
    setPages(10);
  }, []);

  const deleteClick = data => {
    setId(data.id);
    setDeleteModal(true);
  };

  function deleteToggle() {
    setDeleteModal(false);
  }

  function deleteHandler() {
    setLoader(true);
    const params = {
      id: id
    };
    FaxManagementServices.delete(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current) toaster.current.success(data.message);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
        setLoader(false);
        setDeleteModal(false);
        setTimeout(() => {
          getList(field, sortBy, searchText, activePage + 1);
        }, 1000);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
        setLoader(false);
        setDeleteModal(false);
      });
  }

  const columns = [
    {
      Header: "Fax Number",
      accessor: "number",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 200,
      sortable: true
    },
    {
      Header: "Description",
      accessor: "description",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 300,
      sortable: true
    },
    {
      Header: "Access",
      accessor: "admin_fax_numbers",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 300,
      sortable: true,
      Cell: props =>
        props?.original?.admin_fax_numbers
          ?.map(item => item.admin.first_name + " " + item.admin.last_name)
          .join(", ")
    },

    {
      Header: "Action",
      accessor: "action",
      className: "",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 200,
      Cell: props => (
        <div className="react-action-class">
          {hasAccess(MODULE_NAMES.FAX, PERMISSION_TYPE.VIEW, permissions) ? (
            <Button
              className="c-btn c-info mr-10"
              onClick={() => formAction("view", props.original)}
            >
              <div className="fs-14 medium-text" title="View">
                <i className="fas fa-eye" />
              </div>
            </Button>
          ) : null}
          {hasAccess(MODULE_NAMES.FAX, PERMISSION_TYPE.EDIT, permissions) ? (
            <Button
              className="c-btn c-success mr-10"
              onClick={() => formAction("edit", props.original)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-edit" />
              </div>
            </Button>
          ) : null}
          {hasAccess(MODULE_NAMES.FAX, PERMISSION_TYPE.DELETE, permissions) ? (
            <Button
              className="c-btn c-danger"
              onClick={() => deleteClick(props.original)}
            >
              <div className="fs-14 medium-text" title="Delete">
                <i className="fas fa-trash" />
              </div>
            </Button>
          ) : null}
        </div>
      ) // Custom cell components!
    }
  ];

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  const formAction = (action, data = null) => {
    // Here you can view the data and make forward action for edit data
    if (action === "add") {
      props.history.push("/fax/add", { data });
    } else if (action === "edit") {
      props.history.push(`/fax/edit/${data.id}`, { ...data });
    } else if (action === "view") {
      props.history.push(`/fax/view/${data.id}`, { ...data });
    }
  };

  function getList(
    field = "created_at",
    sortBy = "DESC",
    search = "",
    page = "1"
  ) {
    const params = {
      sortBy: sortBy,
      field: field,
      search: search,
      page: page
    };
    setLoader(true);

    FaxManagementServices.list(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count / TABLE.LIMIT));
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });
  }

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Fax Number List
            </div>
            {hasAccess(MODULE_NAMES.FAX, PERMISSION_TYPE.ADD, permissions) ? (
              <div>
                <Button
                  className="c-btn c-primary ma-5"
                  onClick={() => formAction("add", props?.admin?.id)}
                >
                  <i className="fas fa-plus mr-10" />
                  New Fax Number
                </Button>
              </div>
            ) : null}
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              LoadingComponent={Loader}
              onFetchData={onChangePageGetData}
              onPageChange={pageIndex => {
                setActivePage(pageIndex);
                getList(field, sortBy, searchText, pageIndex + 1);
              }}
              onSortedChange={sortProperties => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getList(sortProperties[0].id, sort, searchText, 1);
              }}
            />
          </div>
        </div>
      </div>
      <Toaster ref={toaster} />
      <CommonModel
        modal={deleteModal}
        toggle={deleteToggle}
        children={
          <DeleteModal
            text={"Are you sure you want to delete this Fax number?"}
            deleteHandler={deleteHandler}
            toggle={deleteToggle}
          />
        }
      />
    </div>
  );
};

export default withRouter(FaxTable);
