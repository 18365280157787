import React, { useState, useRef } from "react";
import { ForgotIcon } from "helper/constant";
import AdminServices from "../../../api/AdminServices";
import Toaster from "../../../components/common/Toaster";
import { withRouter } from "react-router-dom";
import Otp from "./Otp";
import Button from "../../../components/button/Button";
import EncryptDecrypt from "../../../api/EncryptDecrypt";

const ForgotPassword = props => {
  const loginContainer = {
    // backgroundImage: `url(${loginBack})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0,
    backgroundColor: "#AECE38"
  };

  const toaster = useRef();
  const [email, setEmail] = useState();
  const [view, setView] = useState(true);
  const [loading, setloading] = useState(false);
  const [Token, setToken] = useState(null);
  function submitHandler(e) {
    e.preventDefault();
    const params = {
      email: email
    };
    setloading(true);
    AdminServices.forgetPassword(params.email)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setToken(data.data.otp_token);
          if (toaster.current) toaster.current.success(data.message);
          setTimeout(() => {
            // props.history.push("./otp");
            setView(false);
            setloading(false);
          }, 500);
        } else {
          if (toaster.current) toaster.current.error(data.message);
          setloading(false);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
        setloading(false);
      });
  }

  return view ? (
    <div className="container-fluid" style={loginContainer}>
      <div className="form-container">
        <div className="login-icon">
          <img src={ForgotIcon} alt="icon" height="100px" />
        </div>
        <div className="login-title">Forgot Password ?</div>
        <div className="text-center form-info-text plr-24 mt-16">
          Provide your e-mail address to reset your password
        </div>
        <form className="pa-24" onSubmit={e => submitHandler(e)}>
          <div className="form-group">
            <input
              type="email"
              required
              onChange={e => {
                setEmail(e.target.value);
              }}
              className="form-control react-form-input"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter email"
            />
          </div>
          <Button
            type="submit"
            loading={loading}
            className="btn form-button"
            dataStyle="expand-right"
            style={{ position: "relative" }}
          >
            Get OTP
          </Button>
          <button
            type="button"
            className="btn c-secondary form-button "
            style={{ marginTop: "10px" }}
            onClick={() => props.history.push("./login")}
          >
            Back
          </button>
        </form>
      </div>
      <Toaster ref={toaster} />
    </div>
  ) : (
    <Otp email={email} otp_token={Token} />
  );
};

export default withRouter(ForgotPassword);
