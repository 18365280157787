import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import ReportServices from "../../api/ReportServices";
import Loader from "../common/Loader";
import Toaster from "../common/Toaster";
import { TABLE } from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import moment from "moment-timezone";
import Button from "components/button/Button";
import CommonModel from "../common/CommonModel";
import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
import GroupServices from "../../api/GroupServices";
import ClientServices from "../../api/ClientServices";

const BillingTransactionTable = props => {
  const toaster = useRef(null);
  const [tblData, settblData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("id");
  const [sortBy, setSortBy] = useState("ASC");
  const [dateRange, setDateRange] = useState({});
  const [modal, setModal] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [paymentMethodValue, setPaymentMethodValue] = useState("");
  const [parentDropdownOpenPayment, setParentDropdownOpenPayment] = useState(
    false
  );
  const [insuranceList, setInsuranceList] = useState([]);
  const [insuranceId, setInsuranceId] = useState(null);
  const [insuranceName, setInsuranceName] = useState(null);
  const [insuranceDropdownOpen, setInsuranceDropdownOpen] = useState(false);
  const [parentDropdownOpen, setParentDropdownOpen] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [groupId, setGroupId] = useState(null);
  const [groupName, setGroupName] = useState(null);
  const [isExporting, setIsExporting] = useState(false);

  useEffect(() => {
    getList(field, sortBy, searchText, activePage + 1, paymentMethodValue);
    setPages(10);

    GroupServices.insuranceList().then(res => {
      let data = EncryptDecrypt.decrypt(res.data);
      setInsuranceList(data.data);
    });

    ClientServices.groupList().then(result => {
      let data = EncryptDecrypt.decrypt(result.data);
      setGroupList(data.data);
    });
  }, []);

  useEffect(() => {
    getList(field, sortBy, searchText, activePage + 1, paymentMethodValue);
    setPages(10);
  }, [insuranceId, groupId, dateRange.startDate, dateRange.endDate]);

  const toggle = () => {
    setOpen(!open);
    setModal(!open);
  };

  const toggleOnePayment = () =>
    setParentDropdownOpenPayment(prevState => !prevState);
  const toggleInsurance = () =>
    setInsuranceDropdownOpen(prevState => !prevState);
  const toggleOne = () => setParentDropdownOpen(prevState => !prevState);

  const columns = [
    {
      Header: "Client ID",
      accessor: "user.id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 150
    },
    {
      Header: "Client First Name",
      accessor: "user.first_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200
    },
    {
      Header: "Client Last Name",
      accessor: "user.last_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200
    },
    {
      Header: "Client DOB",
      accessor: "user.dob",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200,
      Cell: props =>
        props.original.user.dob
          ? moment(props.original.user.dob).format("MM/DD/YYYY")
          : ""
    },
    {
      Header: "Group",
      accessor: "group.name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200
    },
    {
      Header: "Service Date",
      accessor: "service_date",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 150,
      Cell: props =>
        props.original.calendly_appointment.appointment_type === "Manual"
          ? moment
              .tz(
                moment(
                  props.original.calendly_appointment.appointment_date +
                    " " +
                    props.original.calendly_appointment.appointment_time
                ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                moment.tz.guess()
              )
              .format("MM/DD/YYYY")
          : moment
              .tz(
                props.original.calendly_appointment.event_detail.start_time,
                moment.tz.guess()
              )
              .format("MM/DD/YYYY")
    },
    {
      Header: "Service Time",
      accessor: "calendly_appointment.appointment_time",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 150,
      Cell: props =>
        props.original.calendly_appointment.appointment_type === "Manual"
          ? moment
              .tz(
                moment(
                  props.original.calendly_appointment.appointment_date +
                    " " +
                    props.original.calendly_appointment.appointment_time
                ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                moment.tz.guess()
              )
              .format("h:mm A")
          : moment
              .tz(
                props.original.calendly_appointment.event_detail.start_time,
                moment.tz.guess()
              )
              .format("h:mm A")
    },
    {
      Header: "Provider",
      accessor: "admin.first_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      Cell: props => (
        <div className="react-action-class">
          {props.original.admin
            ? props.original.admin.first_name +
              " " +
              props.original.admin.last_name
            : ""}
        </div>
      ),
      width: 220
    },
    {
      Header: "Service Location",
      accessor: "service_location_detail.name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 220
    },
    {
      Header: "State of Service",
      accessor: "state.name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200
    },
    {
      Header: "Payment Method",
      accessor: "payment_method",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200
    },
    {
      Header: "Insurance Carrier",
      accessor: "`user_insurance->insurance`.`name`",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200,
      Cell: props => (
        <div className="react-action-class">
          {props.original.user_insurance &&
          props.original.user_insurance.insurance
            ? props.original.user_insurance.insurance.name
            : ""}
        </div>
      )
    },
    {
      Header: "Member ID",
      accessor: "`user_insurance.member_id`",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 150,
      Cell: props => (
        <div className="react-action-class">
          {props.original.user_insurance
            ? props.original.user_insurance.member_id
            : ""}
        </div>
      )
    },
    {
      Header: "Group ID",
      accessor: "`user_insurance.group_id`",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 150,
      Cell: props => (
        <div className="react-action-class">
          {props.original.user_insurance
            ? props.original.user_insurance.group_id
            : ""}
        </div>
      )
    },
    {
      Header: "Primary subscriber",
      accessor: "`user_insurance.is_primary_subscriber`",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200,
      Cell: props => (
        <div className="react-action-class">
          {props.original.user_insurance
            ? props.original.user_insurance.is_primary_subscriber
            : ""}
        </div>
      )
    },
    {
      Header: "Primary subscriber First Name",
      accessor: "`user_insurance.primary_subscriber_first_name`",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 300,
      Cell: props => (
        <div className="react-action-class">
          {props.original.user_insurance
            ? props.original.user_insurance.primary_subscriber_first_name
            : ""}
        </div>
      )
    },
    {
      Header: "Primary subscriber Last Name",
      accessor: "`user_insurance.primary_subscriber_last_name`",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 300,
      Cell: props => (
        <div className="react-action-class">
          {props.original.user_insurance
            ? props.original.user_insurance.primary_subscriber_last_name
            : ""}
        </div>
      )
    },
    {
      Header: "Primary subscriber DOB",
      accessor: "`user_insurance.primary_subscriber_dob`",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 250,
      Cell: props => (
        <div className="react-action-class">
          {props.original.user_insurance &&
          props.original.user_insurance.primary_subscriber_dob &&
          props.original.user_insurance.primary_subscriber_dob !== "0000-00-00"
            ? moment(
                props.original.user_insurance.primary_subscriber_dob
              ).format("MM/DD/YYYY")
            : ""}
        </div>
      )
    },
    {
      Header: "Method of Service",
      accessor: "method_of_services",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200,
      Cell: props => (
        <div className="react-action-class">
          {props.original.method_of_services
            ? props.original.method_of_services === "Virtual"
              ? "Virtual (GT - 95 Modifier)"
              : "In Person"
            : ""}
        </div>
      )
    },
    {
      Header: "Requires prequalification",
      accessor: "is_prequalification",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 240,
      Cell: props => (
        <div className="react-action-class">
          {props.original.is_prequalification ? "Yes" : "No"}
        </div>
      ) // Custom cell components!
    },
    {
      Header: "CPT Code",
      accessor: "cpt_code.name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200
    },
    {
      Header: "Primary Diagnosis Code",
      accessor: "primary_diagnosis.name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200
    },
    {
      Header: "Diagnosis Code",
      accessor: "diagnoses_code",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      width: 200
    },
    {
      Header: "Billable Units",
      accessor: "billable_unit",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 150
    },
    {
      Header: "Visit Note",
      accessor: "visit_note",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      width: 500
    },
    {
      Header: "Client POS",
      accessor: "client_pos",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 150
    },
    {
      Header: "Notes for Medical Biller",
      accessor: "notes_for_medical_biller",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 250
    }
  ];

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  function exportList(
    field = "name",
    sortBy = "DESC",
    search = "",
    page = "1",
    bill_to
  ) {
    setIsExporting(true);
    const params = {
      sort_by: field,
      order: sortBy,
      search: search,
      page: page,
      bill_to: bill_to,
      insurance_id: insuranceId,
      group_id: groupId,
      start_date: dateRange.startDate
        ? moment(dateRange.startDate).format("YYYY-MM-DD")
        : null,
      end_date: dateRange.endDate
        ? moment(dateRange.endDate).format("YYYY-MM-DD")
        : null,
      practice_id:
        props?.admin_role_id === 6 && props?.practice
          ? props?.practice
          : undefined,
      service_id:
        props?.admin_role_id > 2 && props?.service_id
          ? props?.service_id
          : undefined
    };
    ReportServices.billingTransactionListCsv(params)
      .then(res => {
        setIsExporting(false);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error("Something went wrong!");
        setIsExporting(false);
      });
  }

  const formAction = (action, data = null) => {
    // Here you can view the data and make forward action for edit data
    if (action === "export") {
      exportList(field, sortBy, searchText, activePage + 1, paymentMethodValue);
    }
  };

  function getList(
    field = "name",
    sortBy = "DESC",
    search = "",
    page = "1",
    bill_to
  ) {
    const params = {
      sort_by: field,
      order: sortBy,
      search: search,
      page: page,
      bill_to: bill_to,
      insurance_id: insuranceId,
      group_id: groupId,
      start_date: dateRange.startDate
        ? moment(dateRange.startDate).format("YYYY-MM-DD")
        : null,
      end_date: dateRange.endDate
        ? moment(dateRange.endDate).format("YYYY-MM-DD")
        : null,
      practice_id:
        props?.admin_role_id === 6 && props?.practice
          ? props?.practice
          : undefined,
      service_id:
        props?.admin_role_id > 2 && props?.service_id
          ? props?.service_id
          : undefined
    };
    setLoader(true);
    ReportServices.billingTransactionList(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count.length / TABLE.LIMIT));
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
        setLoader(false);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.message);
        setLoader(false);
      });
  }

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Bill Transaction Report
            </div>
            <div>
              <Button
                className="c-btn c-primary ma-5"
                onClick={() => formAction("export")}
                disabled={!tblData?.length || isExporting}
              >
                <i className="fas fa-download mr-10" />
                {!isExporting ? "Export" : "Exporting..."}
              </Button>
            </div>
          </div>
          <div className="roe-card-header module-header">
            <div className="d-flex">
              <div className="mr-10">
                <span className="fs-16">Date</span>
                <br></br>
                <Button
                  className="btn btn-secondary btn-md"
                  onClick={() => toggle()}
                >
                  {dateRange.startDate
                    ? `${moment(dateRange.startDate).format(
                        "MM/DD/YYYY"
                      )} - ${moment(dateRange.endDate).format("MM/DD/YYYY")}`
                    : "Select Date"}
                </Button>
                {dateRange && dateRange.startDate && (
                  <Button
                    className="btn btn-secondary btn-md ml-2"
                    onClick={() => {
                      setActivePage(0);
                      setDateRange({});
                    }}
                  >
                    Reset date
                  </Button>
                )}
              </div>
            </div>
            <div className="fs-16 mr-10">
              <span className="fs-16">Payment Method</span>
              <Dropdown
                isOpen={parentDropdownOpenPayment}
                toggle={toggleOnePayment}
              >
                <DropdownToggle caret size="md" className="w-100 float-left">
                  {paymentMethodValue ? paymentMethodValue : "All"}
                </DropdownToggle>
                <DropdownMenu
                  modifiers={{
                    setMaxHeight: {
                      enabled: true,
                      order: 890,
                      fn: data => {
                        return {
                          ...data,
                          styles: {
                            ...data.styles,
                            overflow: "auto",
                            maxHeight: "300px"
                          }
                        };
                      }
                    }
                  }}
                >
                  <DropdownItem
                    key="0"
                    onClick={() => {
                      setPaymentMethodValue("");
                      setLoader(true);
                      setActivePage(0);
                      setTimeout(() => {
                        getList(field, sortBy, searchText, 1, "");
                      }, 500);
                    }}
                  >
                    All
                  </DropdownItem>
                  <DropdownItem
                    key="Insurance Funded"
                    onClick={() => {
                      setPaymentMethodValue("Insurance Funded");
                      setLoader(true);
                      setActivePage(0);
                      setTimeout(() => {
                        getList(
                          field,
                          sortBy,
                          searchText,
                          1,
                          "Insurance Funded"
                        );
                      }, 500);
                    }}
                  >
                    Insurance Funded
                  </DropdownItem>
                  <DropdownItem
                    key="Group Funded"
                    onClick={() => {
                      setPaymentMethodValue("Group Funded");
                      setLoader(true);
                      setActivePage(0);
                      setTimeout(() => {
                        getList(field, sortBy, searchText, 1, "Group Funded");
                      }, 500);
                    }}
                  >
                    Group Funded
                  </DropdownItem>
                  <DropdownItem
                    key="Client Funded"
                    onClick={() => {
                      setPaymentMethodValue("Client Funded");
                      setLoader(true);
                      setActivePage(0);
                      setTimeout(() => {
                        getList(field, sortBy, searchText, 1, "Client Funded");
                      }, 500);
                    }}
                  >
                    Client Funded
                  </DropdownItem>
                  <DropdownItem
                    key="Consumer Model"
                    onClick={() => {
                      setPaymentMethodValue("Consumer Model");
                      setLoader(true);
                      setActivePage(0);
                      setTimeout(() => {
                        getList(field, sortBy, searchText, 1, "Consumer Model");
                      }, 500);
                    }}
                  >
                    Consumer Model
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            {paymentMethodValue === "Insurance Funded" ? (
              <div className="fs-14  mr-10">
                <span className="fs-16">Insurance</span>
                <Dropdown
                  isOpen={insuranceDropdownOpen}
                  toggle={toggleInsurance}
                >
                  <DropdownToggle caret size="md">
                    {insuranceId ? insuranceName : "All"}
                  </DropdownToggle>
                  <DropdownMenu
                    modifiers={{
                      setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: data => {
                          return {
                            ...data,
                            styles: {
                              ...data.styles,
                              overflow: "auto",
                              maxHeight: "300px"
                            }
                          };
                        }
                      }
                    }}
                  >
                    <DropdownItem
                      key="-1"
                      onClick={() => {
                        setActivePage(0);
                        setInsuranceId("");
                        setInsuranceName("All");
                      }}
                    >
                      All
                    </DropdownItem>
                    {insuranceList.length > 0 &&
                      insuranceList.map((result, i) => {
                        return (
                          <DropdownItem
                            key={i}
                            onClick={() => {
                              setActivePage(0);
                              setInsuranceId(result.id);
                              setInsuranceName(`${result.name}`);
                            }}
                          >
                            {result.name}
                          </DropdownItem>
                        );
                      })}
                  </DropdownMenu>
                </Dropdown>
              </div>
            ) : (
              ""
            )}
            {paymentMethodValue === "Group Funded" ? (
              <div className="fs-14 mr-10">
                <span className="fs-16">Group</span>
                <Dropdown isOpen={parentDropdownOpen} toggle={toggleOne}>
                  <DropdownToggle caret size="md">
                    {groupName ? groupName : "Select Group"}
                  </DropdownToggle>
                  <DropdownMenu
                    modifiers={{
                      setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: data => {
                          return {
                            ...data,
                            styles: {
                              ...data.styles,
                              overflow: "auto",
                              maxHeight: "300px"
                            }
                          };
                        }
                      }
                    }}
                  >
                    <DropdownItem
                      key="0"
                      onClick={() => {
                        setActivePage(0);
                        setGroupId("");
                        setGroupName("All");
                      }}
                    >
                      All
                    </DropdownItem>
                    {groupList.length > 0 &&
                      groupList.map((result, i) => {
                        return (
                          <DropdownItem
                            key={i}
                            onClick={() => {
                              setActivePage(0);
                              setGroupId(result.id);
                              setGroupName(result.name);
                            }}
                          >
                            {result.name}
                          </DropdownItem>
                        );
                      })}
                  </DropdownMenu>
                </Dropdown>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              LoadingComponent={Loader}
              onFetchData={onChangePageGetData}
              onPageChange={pageIndex => {
                setActivePage(pageIndex);
                getList(
                  field,
                  sortBy,
                  searchText,
                  pageIndex + 1,
                  paymentMethodValue
                );
              }}
              onSortedChange={sortProperties => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getList(
                  sortProperties[0].id,
                  sort,
                  searchText,
                  1,
                  paymentMethodValue
                );
                // alert("sort handler", sortProperties);
                // const orderByDirection = sortProperties[0].desc ? 1 : 0;

                // updateMapStateInfoOrderByColumn(sortProperties[0].id, orderByDirection);
              }}
            />
          </div>
          <CommonModel
            modal={modal}
            toggle={toggle}
            size="lg"
            children={
              <DateRangePicker
                open={open}
                toggle={() => toggle()}
                onChange={range => {
                  setActivePage(0);
                  setDateRange(range);
                  // setModal(!modal)
                  toggle();
                }}
                closeOnClickOutside={true}
              />
            }
            // header={editedData ? "" : "Add Registration Code"}
          />
        </div>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};

export default withRouter(BillingTransactionTable);
