import React from "react";
import Button from "../button/Button";

const ArchiveModal = props => {
  return (
    <div className="text-center">
      <div>{props.text}</div>
      <div>
        <Button
          className="c-btn c-info mr-10 mt-10"
          style={{ maxWidth: "125px" }}
          type="button"
          onClick={props.activeInactiveStatusHandler}
        >
          Archive
        </Button>
        <Button
          className="c-btn c-danger mr-10 mt-10"
          style={{ maxWidth: "125px" }}
          onClick={props.activeToggle}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default ArchiveModal;
