import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
import { compose } from "redux";
import enhancer from "./validator";
import GoalTracking from "../../api/GoalTrackingService";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import Button from "components/button/Button";
import GoalQuestion from "./GoalQuestion";
import GoalLongTerm from "./GoalLongTerm";

let goalCategory = ["Short-Term", "Long-Term"];
const CreateGoal = props => {
  const {
    handleChange,
    handleBlur,
    setFieldTouched,
    setFieldValue,
    submitCount,
    errors,
    values,
    touched,
    isValid,
    handleSubmit
  } = props;
  let [categoryOpen, setCategoryOpen] = useState(false);
  let [goalOpen, setGoalOpen] = useState(false);
  let [goalQuestion, setGoalQuestion] = useState(false);
  let [categoryList, setCategoryList] = useState([]);

  const toggleCategory = () => setCategoryOpen(!categoryOpen);
  const toggleGoal = () => setGoalOpen(!goalOpen);
  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <div className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </div>
      );
    } else {
      return <span />;
    }
  };
  useEffect(() => {
    GoalTracking.categoryList({ type: values.goal_type }).then(result => {
      let data = EncryptDecrypt.decrypt(result.data);
      setCategoryList(data.data);
    });
  }, [values.goal_type]);

  const handleSubmits = () => {
    if (isValid) {
      setGoalQuestion(!goalQuestion);
    }
    handleSubmit();
  };

  return (
    <div>
      <div
        className="close-arw float-right cursor-pointer mb-2"
        onClick={props.toggle1}
      >
        <i
          className="fa fa-times-circle-o fa-lg"
          onClick={props.toggle1}
          aria-hidden="true"
        ></i>
      </div>
      {!goalQuestion && (
        <div className="roe-card-body">
          <div className="">
            Select Type:
            <Dropdown isOpen={categoryOpen} toggle={toggleCategory}>
              <DropdownToggle caret size="md">
                {values.goal_type && values.goal_type
                  ? values.goal_type
                  : "Select Goal Type"}
              </DropdownToggle>
              <DropdownMenu>
                {goalCategory.map((result, i) => {
                  return (
                    <DropdownItem
                      key={`goal_type_${i}`}
                      onClick={() => {
                        setFieldTouched("goal_type", true, true);
                        setFieldValue("goal_type", result);
                      }}
                    >
                      {result}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
            <Error field="goal_type" />
          </div>
          {values.goal_type && (
            <div className="mt-4">
              Select Category:
              {categoryList.length && (
                <Dropdown isOpen={goalOpen} toggle={toggleGoal}>
                  <DropdownToggle caret size="md">
                    {values.goal_category
                      ? values.goal_category.name
                      : "Select Goal Category"}
                  </DropdownToggle>
                  <DropdownMenu>
                    {categoryList.map((result, i) => {
                      return (
                        <DropdownItem
                          key={`goal_type_${i}`}
                          onClick={() => {
                            setFieldTouched("goal_category", true, true);
                            setFieldValue("goal_category", result);
                          }}
                        >
                          {result.name}
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
              )}
              <Error field="goal_category" />
            </div>
          )}
          <div className="mt-3">
            <Button
              type="button"
              className="c-btn c-info form-button fs-16 demi-bold-text"
              style={{ maxWidth: "125px" }}
              onClick={() => handleSubmits()}
            >
              Next
            </Button>
          </div>
        </div>
      )}

      {values.goal_type === "Short-Term" && goalQuestion && (
        <GoalQuestion
          valuess={values}
          toggle1={props.toggle1}
          pageRefresh={() => props.pageRefresh()}
        ></GoalQuestion>
      )}
      {values.goal_type === "Long-Term" && goalQuestion && (
        <GoalLongTerm
          valuess={values}
          toggle1={props.toggle1}
          pageRefresh={() => props.pageRefresh()}
        ></GoalLongTerm>
      )}
    </div>
  );
};
export default compose(enhancer)(withRouter(CreateGoal));
