import { withFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    state_id: Yup.string().required("This field is required"),
    valid_from: Yup.date()
      .required("This field is required")
      .nullable(),
    valid_to: Yup.date()
      .required("This field is required")
      .nullable()
      .min(Yup.ref("valid_from"), "To Date can't be before From Date"),
    image: Yup.string()
      .optional()
      .nullable(),
    license_number: Yup.string()
      .optional()
      .nullable()
  }),
  mapPropsToValues: props => ({
    id: props.editedData
      ? props && props.editedData && props.editedData.id
      : "",
    state_id: props.editedData
      ? props && props.editedData && props.editedData.state_id
      : "",
    license_number: props.editedData
      ? props && props.editedData && props.editedData.license_number
      : "",
    image: props.editedData
      ? props && props.editedData && props.editedData.image
      : "",
    valid_from: props.editedData
      ? props &&
        props.editedData &&
        new Date(moment(props.editedData.valid_from))
      : "",
    valid_to: props.editedData
      ? props && props.editedData && new Date(moment(props.editedData.valid_to))
      : ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
