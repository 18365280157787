import { withFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    first_name: Yup.string()
      .trim("Cannot include leading and trailing spaces")
      .strict(true)
      .matches(/^[A-Za-z0-9 ]+$/, "Cannot include special characters")
      .required("This field is required")
      .max(100, "First Name must be less than 100 characters"),
    last_name: Yup.string()
      .trim("Cannot include leading and trailing spaces")
      .strict(true)
      .matches(/^[A-Za-z0-9 ]+$/, "Cannot include special characters")
      .required("This field is required")
      .max(100, "Last Name must be less than 100 characters"),
    email: Yup.string()
      .trim("Cannot include leading and trailing spaces")
      .strict(true)
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Enter valid email"
      )
      .required("Email is required"),
    category_id: Yup.string()
      .trim()
      .required("This field is required"),
    address: Yup.string()
      .trim()
      .required("This field is required"),
    city: Yup.string()
      .trim()
      .required("This field is required"),
    state_id: Yup.string()
      .trim()
      .required("This field is required"),
    zipcode: Yup.string()
      .trim()
      .required("This field is required"),
    dob: Yup.string()
      .trim()
      .required("This field is required")
      .nullable(),
    gender: Yup.string()
      .trim()
      .required("This field is required"),
    profile_photo: Yup.string()
      .trim()
      .notRequired(),
    emergency_name: Yup.string()
      .trim("Cannot include leading and trailing spaces")
      .strict(true)
      .matches(/^[A-Za-z0-9 ]+$/, "Cannot include special characters")
      .max(100, "Emergency Contact Name must be less than 100 characters"),
    emergency_phone_number: Yup.string().length(14, "Enter valid phone number"),
    phone_number: Yup.string().length(14, "Enter valid phone number")
  }),
  mapPropsToValues: props => ({
    id:
      props && props.editedData && props.editedData.id
        ? props.editedData.id
        : "",
    first_name:
      props && props.editedData && props.editedData.first_name
        ? props.editedData.first_name
        : "",
    last_name:
      props && props.editedData && props.editedData.last_name
        ? props.editedData.last_name
        : "",
    email:
      props && props.editedData && props.editedData.email
        ? props.editedData.email
        : "",
    phone_number:
      props && props.editedData && props.editedData.phone_number
        ? props.editedData.phone_number
            .toString()
            .replace(/\D+/g, "")
            .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
        : "",
    profile_photo:
      props && props.editedData && props.editedData.profile_photo
        ? props.editedData.profile_photo
        : "",
    category_id:
      props.editedData && props.editedData.category_id
        ? props.editedData.category_id
        : "",
    address:
      props.editedData && props.editedData.address
        ? props.editedData.address
        : "",
    city:
      props.editedData && props.editedData.city ? props.editedData.city : "",
    state_id:
      props.editedData && props.editedData.state_id
        ? props.editedData.state_id
        : "",
    zipcode:
      props.editedData && props.editedData.zipcode
        ? props.editedData.zipcode
        : "",
    dob: props.editedData && props.editedData.dob ? props.editedData.dob : "",
    gender:
      props.editedData && props.editedData.gender
        ? props.editedData.gender
        : "",
    emergency_phone_number:
      props.editedData && props.editedData.emergency_phone_number
        ? props.editedData.emergency_phone_number
        : "",
    emergency_name:
      props.editedData && props.editedData.emergency_name
        ? props.editedData.emergency_name
        : ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
