import React, { useRef, useState, useEffect } from "react";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import RdServices from "../../api/RdServices";
import { MODULE_NAMES, PERMISSION_TYPE, PLACEHOLDER } from "helper/constant";
import Button from "../button/Button";
import Toaster from "../common/Toaster";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { hasAccess } from "util/sidebar";

const RdBasicViewDetails = props => {
  const { permissions } = props;
  const [editedData, setEditedData] = useState(undefined);
  const hasParam = props.match.params.id || props?.id;
  const toaster = useRef();
  let history = useHistory();

  useEffect(() => {
    if (hasParam) {
      const params = {
        id: hasParam
      };
      RdServices.view(params).then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        setEditedData(data.data);
      });
    }
  }, []);

  const welcomeEmail = () => {
    const params = {
      admin_id: hasParam
    };

    RdServices.sendWelcomeMail(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current) toaster.current.success(data.message);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });
  };

  const formAction = () => {
    if (!props?.isRD) {
      history.push({
        pathname: `/rd/edit/${editedData.id}`,
        state: "profile"
      });
    } else {
      history.push("/rd-edit-profile/edit", { isRD: true, rdID: props?.id });
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <div style={{ position: "absolute", top: 10, right: 40 }}>
        {hasAccess(
          MODULE_NAMES.PROVIDER,
          PERMISSION_TYPE.EDIT,
          permissions
        ) && (
          <Button
            className="c-btn c-success mr-10"
            onClick={() => {
              formAction();
            }}
          >
            <div className="fs-14 medium-text-copy">
              <i className="fas fa-edit" />
            </div>
          </Button>
        )}
      </div>
      <table style={{ width: "100%", lineHeight: "35px" }}>
        <tbody>
          <tr>
            <td
              style={{ fontWeight: "bolder", textAlign: "left", width: "25%" }}
            >
              Timezone
            </td>
            <td>
              {editedData &&
                editedData.rd_detail &&
                editedData.rd_detail.timezone &&
                editedData.rd_detail.timezone.name}
            </td>
          </tr>
          <tr>
            <td
              style={{ fontWeight: "bolder", textAlign: "left", width: "25%" }}
            >
              Language
            </td>
            <td>
              {editedData &&
                editedData.languages &&
                editedData.languages.map((type, i) => {
                  return (
                    <spam>
                      {i > 0 ? ", " : ""}
                      {type.name}
                    </spam>
                  );
                })}
            </td>
          </tr>
          <tr>
            <td
              style={{ fontWeight: "bolder", textAlign: "left", width: "25%" }}
            >
              Profile Image
            </td>
            <td>
              <img
                className="top-header-profile-class"
                style={{
                  height: "100px",
                  width: "100px",
                  marginBottom: "15px",
                  borderRadius: "10px",
                  marginTop: "10px"
                }}
                src={editedData && (editedData.profile_image || PLACEHOLDER)}
                alt="rd image"
              />
            </td>
          </tr>
          <tr>
            <td
              style={{ fontWeight: "bolder", textAlign: "left", width: "25%" }}
            >
              ID
            </td>
            <td>{editedData && editedData.rd_detail && editedData.id}</td>
          </tr>
          <tr>
            <td
              style={{ fontWeight: "bolder", textAlign: "left", width: "25%" }}
            >
              First Name
            </td>
            <td>{editedData && editedData.first_name}</td>
          </tr>
          <tr>
            <td
              style={{ fontWeight: "bolder", textAlign: "left", width: "25%" }}
            >
              Last Name
            </td>
            <td>{editedData && editedData.last_name}</td>
          </tr>
          <tr>
            <td
              style={{ fontWeight: "bolder", textAlign: "left", width: "25%" }}
            >
              Email
            </td>
            <td>{editedData && editedData.email}</td>
          </tr>
          <tr>
            <td
              style={{ fontWeight: "bolder", textAlign: "left", width: "25%" }}
            >
              Profile Text
            </td>
            <td>
              {editedData &&
                editedData.rd_detail &&
                editedData.rd_detail.profile_text}
            </td>
          </tr>
          {/* <tr>
            <td
              style={{ fontWeight: "bolder", textAlign: "left", width: "25%" }}
            >
              Primary Category
            </td>
            <td>
              {editedData &&
                editedData.rd_detail &&
                editedData.rd_detail.primary_category &&
                editedData.rd_detail.primary_category.name}
            </td>
          </tr>
          <tr>
            <td
              style={{ fontWeight: "bolder", textAlign: "left", width: "25%" }}
            >
              {" "}
              Secondary Category{" "}
            </td>
            <td>
              {editedData &&
                editedData.rd_detail &&
                editedData.rd_detail.secondary_category &&
                editedData.rd_detail.secondary_category.name}
            </td>
          </tr> */}
          <tr>
            <td
              style={{ fontWeight: "bolder", textAlign: "left", width: "25%" }}
            >
              Phone number
            </td>
            <td>
              {editedData &&
                editedData.rd_detail &&
                editedData.rd_detail.phone_number &&
                editedData.rd_detail.phone_number
                  .toString()
                  .replace(/\D+/g, "")
                  .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: "bolder",
                textAlign: "left",
                width: "25%",
                fontSize: "18px"
              }}
            >
              Home Address
            </td>
          </tr>
          <tr>
            <td
              style={{ fontWeight: "bolder", textAlign: "left", width: "25%" }}
            >
              Address
            </td>
            <td>
              {editedData &&
                editedData.rd_detail &&
                editedData.rd_detail.address}
            </td>
          </tr>
          <tr>
            <td
              style={{ fontWeight: "bolder", textAlign: "left", width: "25%" }}
            >
              City
            </td>
            <td>
              {editedData && editedData.rd_detail && editedData.rd_detail.city}
            </td>
          </tr>
          <tr>
            <td
              style={{ fontWeight: "bolder", textAlign: "left", width: "25%" }}
            >
              State
            </td>
            <td>
              {editedData &&
                editedData.rd_detail &&
                editedData.rd_detail.state &&
                editedData.rd_detail.state.name}
            </td>
          </tr>
          <tr>
            <td
              style={{ fontWeight: "bolder", textAlign: "left", width: "25%" }}
            >
              Zip
            </td>
            <td>
              {editedData && editedData.rd_detail && editedData.rd_detail.zip}
            </td>
          </tr>
          <tr>
            <td
              style={{ fontWeight: "bolder", textAlign: "left", width: "25%" }}
            >
              MFA QR Code
            </td>
            <td>
              <img
                className="top-header-profile-class"
                style={{
                  height: "150px",
                  width: "150px",
                  marginBottom: "15px",
                  borderRadius: "10px",
                  marginTop: "10px"
                }}
                src={editedData && (editedData.mfa_qr_code || PLACEHOLDER)}
                alt="rd image"
              />
            </td>
          </tr>
          {!props?.isRD && (
            <tr>
              <td
                style={{
                  fontWeight: "bolder",
                  textAlign: "left",
                  width: "25%"
                }}
              >
                Send Welcome Mail
              </td>
              <td>
                <Button
                  type="button"
                  className="c-btn c-info form-button fs-16 demi-bold-text"
                  style={{ maxWidth: "125px" }}
                  onClick={() => welcomeEmail()}
                >
                  Send
                </Button>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Toaster ref={toaster} />
    </div>
  );
};

export default RdBasicViewDetails;
