import axios from "axios";
import { baseUrl } from "../helper/constant";
import Header from "./Header";
import EncryptDecrypt from "./EncryptDecrypt";

export default class UserServices {
  static CPTCodeList(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/visit-bills/cpt-code-list`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }
  static stateList() {
    Header.setHeaders();
    return axios.get(`${baseUrl}/visit-bills/state-list`);
  }
  static diagnoseList(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/visit-bills/diagnose-list`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static addVisitBill(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/visit-bills/add`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static editVisitBill(params) {
    Header.setHeaders();
    return axios.put(
      `${baseUrl}/visit-bills/edit`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static userInsuranceList(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/visit-bills/user-insurance`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static providerServiceLocationList(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/visit-bills/provider-service-location-list`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }
}
