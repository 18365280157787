import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import ClientServices from "../../api/ClientServices";
import Loader from "../common/Loader";
import Toaster from "../common/Toaster";
import { MODULE_NAMES, PERMISSION_TYPE, TABLE } from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import CommonModel from "../common/CommonModel";
import DeleteModal from "../common/DeleteModal";
import moment from "moment";
import AddDocument from "./AddDocument";
import { hasAccess } from "util/sidebar";

const UserDocument = props => {
  const { permissions } = props;
  const [tblData, settblData] = useState();
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [field, setField] = useState("created_at");
  const [sortBy, setSortBy] = useState("DESC");
  const [modal, setModal] = useState(false);
  const [id, setId] = useState(undefined);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editedData, setEditedData] = useState(undefined);
  const [searchText, setSearchText] = useState(undefined);

  const toaster = useRef();

  useEffect(() => {
    if (
      hasAccess(MODULE_NAMES.CLIENT_DOCUMENT, PERMISSION_TYPE.VIEW, permissions)
    ) {
      getList(field, sortBy, searchText, activePage + 1);
      setPages(10);
    }
  }, []);

  function toggle() {
    setModal(!modal);
  }

  function handleDownload(presignedUrl, fileName) {
    const link = document.createElement("a");
    link.href = presignedUrl;
    link.download = fileName;
    link.target = "_blank";
    link.click();
  }

  const deleteClick = data => {
    setId(data.id);
    setDeleteModal(true);
  };

  function deleteToggle() {
    setDeleteModal(false);
  }

  function deleteHandler() {
    setLoader(true);
    const params = {
      id: id
    };
    ClientServices.deleteDocument(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current) toaster.current.success(data.message);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
        setLoader(false);
        setDeleteModal(false);
        setTimeout(() => {
          getList(field, sortBy, searchText, activePage + 1);
        }, 1000);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
        setLoader(false);
        setDeleteModal(false);
      });
  }

  const formAction = props => {
    window.open(props.document, "_blank");
  };

  const columns = [
    {
      Header: "Date Uploaded",
      accessor: "created_at",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      Cell: props =>
        moment(props.original.created_at).format("MM/DD/YYYY HH:mm"),
      width: 200,
      sortable: true
    },
    {
      Header: "Uploaded by",
      accessor: "admin.first_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 250,
      sortable: true,
      Cell: props =>
        props.original.admin.first_name + " " + props.original.admin.last_name
    },
    {
      Header: "Name",
      accessor: "name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 250,
      sortable: true
    },
    {
      Header: "Category",
      accessor: "service.name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 250,
      sortable: true
    },
    {
      Header: "Action",
      accessor: "",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 300,
      sortable: false,
      Cell: props => {
        const checkFormat = () => {
          let fileName = props?.original?.name;
          let fileArray = fileName.split(".");
          let fileFormat = fileArray[fileArray.length - 1];
          return fileFormat === "docx" || fileFormat === "doc";
        };
        return (
          <>
            <Button
              className="c-btn c-info mr-10"
              onClick={() => formAction(props.original)}
              disabled={checkFormat()}
              title="View"
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-eye" />
              </div>
            </Button>
            {hasAccess(
              MODULE_NAMES.CLIENT_DOCUMENT,
              PERMISSION_TYPE.EDIT,
              permissions
            ) ? (
              <Button
                className="c-btn c-success mr-10"
                onClick={() => {
                  setModal(!modal);
                  setEditedData({ type: "edit", ...props.original });
                }}
                title="Edit"
              >
                <div className="fs-14 medium-text">
                  <i className="fas fa-edit" />
                </div>
              </Button>
            ) : (
              ""
            )}
            {hasAccess(
              MODULE_NAMES.CLIENT_DOCUMENT,
              PERMISSION_TYPE.DELETE,
              permissions
            ) ? (
              <Button
                className="c-btn c-danger mr-10"
                onClick={() => deleteClick(props.original)}
                title="Delete"
              >
                <div className="fs-14 medium-text">
                  <i className="fas fa-trash" />
                </div>
              </Button>
            ) : (
              ""
            )}
            <Button
              className="c-btn c-info"
              onClick={() =>
                handleDownload(props.original.download, props.original.name)
              }
              title="Download"
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-download" />
              </div>
            </Button>
          </>
        );
      }
    }
  ];
  const handleSubmit = () => {
    getList(field, sortBy, searchText, activePage + 1);
    setPages(10);
  };

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  function getList(
    field = "created_at",
    sortBy = "DESC",
    search = "",
    page = "1"
  ) {
    const hasParam = props.match.params.id;
    const params = {
      field: field,
      order: sortBy,
      search: search,
      page: page,
      user_id: hasParam
    };

    setLoader(true);
    ClientServices.listDocument(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count / TABLE.LIMIT));
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current) toaster.current.error(err?.message);
        }
      });
  }

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span>Documents
            </div>
            <div className="d-flex">
              {hasAccess(
                MODULE_NAMES.CLIENT_DOCUMENT,
                PERMISSION_TYPE.ADD,
                permissions
              ) ? (
                <div>
                  <Button
                    className="c-btn c-primary ma-5"
                    onClick={() => {
                      setModal(!modal);
                      setEditedData({ type: "add" });
                    }}
                  >
                    <i className="fas fa-plus mr-10" />
                    Add Document
                  </Button>
                </div>
              ) : (
                ""
              )}
              {/* )} */}
            </div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;
                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class ReactTable1"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              LoadingComponent={Loader}
              onFetchData={onChangePageGetData}
              onPageChange={pageIndex => {
                setActivePage(pageIndex);
                getList(field, sortBy, searchText, pageIndex + 1);
              }}
              onSortedChange={sortProperties => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getList(sortProperties[0].id, sort, searchText, 1);
              }}
            />
          </div>
        </div>
      </div>
      <Toaster ref={toaster} />
      <CommonModel
        modal={deleteModal}
        toggle={deleteToggle}
        children={
          <DeleteModal
            text={"Are you sure you want to delete this Document?"}
            deleteHandler={deleteHandler}
            toggle={deleteToggle}
            type={"SWITCH"}
          />
        }
      />
      {modal && (
        <CommonModel
          modal={modal}
          toggle={toggle}
          onSubmit={handleSubmit}
          size="md"
          children={
            <AddDocument
              onSubmit={handleSubmit}
              editedData={editedData}
              toggle={toggle}
              action={editedData ? "edit" : "add"}
              pageRefresh={() => {
                getList(field, sortBy, searchText, activePage + 1);
              }}
              {...props}
            />
          }
          header={editedData.type === "edit" ? "Edit Document" : "Add Document"}
        />
      )}
    </div>
  );
};

export default withRouter(UserDocument);
