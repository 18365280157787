import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import Loader from "../common/Loader";
import Toaster from "../common/Toaster";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import CommonModel from "components/common/CommonModel";
import { MODULE_NAMES, PERMISSION_TYPE, TABLE } from "helper/constant";
import RewardsManagementServices from "api/RewardsMangementServices";
import EditRewardsActivityModal from "./EditRewardsActivityModal";
import { hasAccess } from "util/sidebar";

const RewardsActivityTable = props => {
  const { permissions } = props;
  const toaster = useRef();
  const [tblData, settblData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [editRewardsActivityModal, setEditRewardsActivityModal] = useState(
    false
  );
  const [editedData, setEditedData] = useState({});

  useEffect(() => {
    getList();
    setPages(10);
  }, [props]);

  function editRewardsActivityToggle() {
    setEditRewardsActivityModal(!editRewardsActivityModal);
  }

  const columns = [
    {
      Header: "ID",
      accessor: "id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 50,
      sortable: true
    },
    {
      Header: "Name",
      accessor: "display_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 300,
      sortable: true,
      style: { whiteSpace: "unset" }
    },
    {
      Header: "Unit Points",
      accessor: "unit_point",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 100,
      sortable: true
    },

    {
      Header: "Max Points",
      accessor: "max_point",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 100,
      sortable: true
    },
    {
      Header: "Note",
      accessor: "note",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 300,
      sortable: true,
      // style: { whiteSpace: "unset" },
      Cell: props => (
        <div className="react-action-class">
          <textarea
            value={props?.original?.note}
            readOnly={true}
            rows={4}
            cols={50}
            style={{ maxWidth: "100%", width: 448 }}
          >
            {props?.original?.visit_note}
          </textarea>
        </div>
      )
    },
    {
      Header: "Repeat Yearly",
      accessor: "is_repeat",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 150,
      sortable: true,
      Cell: props => <div>{props.original?.is_repeat ? "YES" : "NO"}</div>
    },

    {
      Header: "Action",
      accessor: "action",
      className: "",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 300,
      Cell: props => (
        <div className="react-action-class">
          {hasAccess(
            MODULE_NAMES.REWARDS,
            PERMISSION_TYPE.EDIT,
            permissions
          ) ? (
            <Button
              className="c-btn c-success mr-10"
              onClick={() => {
                setEditRewardsActivityModal(!editRewardsActivityModal);
                setEditedData({ ...props.original });
              }}
            >
              <div className="fs-14 medium-text" title="Edit">
                <i className="fas fa-edit" />
              </div>
            </Button>
          ) : null}
        </div>
      ) // Custom cell components!
    }
  ];

  const handleSubmit = () => {
    getList();
    setPages(10);
  };

  function getList() {
    setLoader(true);
    RewardsManagementServices.listRewardActivity()
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count / TABLE.LIMIT));
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });
  }

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Activity List
            </div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              minRows={2}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              showPaginationBottom={false}
              loading={loader}
              LoadingComponent={Loader}
            />
          </div>
        </div>
      </div>
      <Toaster ref={toaster} />

      <CommonModel
        modal={editRewardsActivityModal}
        toggle={editRewardsActivityToggle}
        onSubmit={handleSubmit}
        size="lg"
        children={
          <EditRewardsActivityModal
            onSubmit={handleSubmit}
            editedData={editedData}
            toggle={editRewardsActivityToggle}
            pageRefresh={() => {
              getList();
            }}
            {...props}
          />
        }
        header={"Edit Activity"}
      />
    </div>
  );
};

export default withRouter(RewardsActivityTable);
