import React, { useEffect, useRef, useState } from "react";
import { compose } from "redux";
import enhancer from "../Rewards/productValidator";
import EncryptDecrypt from "api/EncryptDecrypt";
import { PLACEHOLDER, TABLE } from "helper/constant";
import Button from "components/button/Button";
import RewardsManagementServices from "api/RewardsMangementServices";
import Toaster from "components/common/Toaster";
import Wrapper from "./Wrapper";
import ReactTable from "react-table";
import Loader from "components/common/Loader";

const AddProductModal = props => {
  const toaster = useRef();
  const [tblData, settblData] = useState([]);
  const [pages, setPages] = useState(0);
  const [loader, setLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activePage, setActivePage] = useState(0);

  useEffect(() => {
    getList(activePage + 1);
    setPages(10);
  }, [props]);

  const handleAddProduct = value => {
    let params = {
      product_id: value.id
    };
    setIsLoading(true);
    RewardsManagementServices.add(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current) toaster.current.success(data.message);
          props.toggle();
          props.pageRefresh();
          setIsLoading(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
          setIsLoading(false);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
        setIsLoading(false);
      });
  };

  const columns = [
    {
      Header: "ID",
      accessor: "id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 100,
      sortable: true
    },
    {
      Header: "Name",
      accessor: "product_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 250,
      sortable: true
    },
    {
      Header: "Image",
      accessor: "image",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 100,
      sortable: true,
      Cell: props => (
        <div>
          <img
            src={props.original.image || PLACEHOLDER}
            alt="error"
            style={{ height: "35px", width: "35px", borderRadius: "5%" }}
          />
        </div>
      )
    },
    {
      Header: "SKU Number",
      accessor: "sku_no",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",
      width: 200,
      sortable: true
    },
    {
      Header: "Action",
      accessor: "action",
      className: "",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 100,
      Cell: props => (
        <div className="react-action-class">
          <Button
            className="c-btn c-info mr-10"
            onClick={() => {
              handleAddProduct({ ...props.original });
            }}
          >
            <div className="fs-14 medium-text" title="Add">
              Add
            </div>
          </Button>
        </div>
      ) // Custom cell components!
    }
  ];

  function getList(page = "1") {
    const params = {
      page: page
    };
    setLoader(true);
    RewardsManagementServices.listiRedeemProduct(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count / TABLE.LIMIT));
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });
  }

  return (
    <Wrapper>
      <div className="flex flex-y">
        <div className="roe-card-body">
          <ReactTable
            style={{
              border: "none",
              boxShadow: "none"
            }}
            data={tblData}
            columns={columns}
            minRows={2}
            manual
            showPaginationBottom={false}
            loading={loader}
            LoadingComponent={Loader}
          />
        </div>
      </div>

      <Toaster ref={toaster} />
    </Wrapper>
  );
};
export default compose(enhancer)(AddProductModal);
