import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    first_name: Yup.string().required("This field is required"),
    last_name: Yup.string().required("This field is required"),
    image: Yup.string()
      .required("This field is required")
      .nullable(),
    profile_text: Yup.string().required("This field is required"),
    primary_category: Yup.string().notRequired()
  }),
  mapPropsToValues: props => {
    return {
      first_name: props.editedData ? props.editedData.first_name : "",
      last_name: props.editedData ? props.editedData.last_name : "",
      image: props.editedData ? props.editedData.profile_image : "",
      profile_text: "",
      primary_category: "",
      secondary_category: ""
    };
  },
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
