import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import ReportServices from "../../api/ReportServices";
import Loader from "../common/Loader";
import Toaster from "../common/Toaster";
import {
  DEFAULT_RD_DROPDOWN,
  PROVIDER_TYPE,
  PROVIDER_TYPES,
  TABLE
} from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import moment from "moment";
import Button from "components/button/Button";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
import ClientServices from "../../api/ClientServices";
import CategoryServices from "api/CategoryServices";
import CommonServices from "api/CommonServices";

const RdUnitGoalTable = props => {
  const toaster = useRef(null);
  const [tblData, settblData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("id");
  const [sortBy, setSortBy] = useState("ASC");
  const [RDId, setRDId] = useState(null);
  const [RDName, setRDName] = useState(null);
  const [RDList, setRDList] = useState([]);
  const [RDDropdownOpen, setRDDropdownOpen] = useState(false);

  const [serviceId, setServiceId] = useState(null);
  const [serviceName, setServiceName] = useState(null);
  const [serviceList, setServiceList] = useState([]);
  const [serviceDropdownOpen, setServiceDropdownOpen] = useState(false);

  const [typeName, setTypeName] = useState(null);
  const [typeValue, setTypeValue] = useState(null);
  const [typeDropdownOpen, setTypeDropdownOpen] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const [practiceList, setPracticeList] = useState([]);
  const [practiceId, setPracticeId] = useState(null);
  const [practiceName, setPracticeName] = useState(null);
  const [practiceDropdownOpen, setPracticeDropdownOpen] = useState(false);

  const [filteredRDList, setFilteredRDList] = useState([]);

  const togglePractice = () => setPracticeDropdownOpen(prevState => !prevState);

  useEffect(() => {
    getList(field, sortBy, searchText, activePage + 1);
    setPages(10);
    const params1 = {
      type: "",
      practice_id:
        props?.admin_role_id === 6 && props?.practice
          ? props?.practice
          : practiceId
          ? practiceId
          : undefined,
      id: props?.admin_role_id > 2 ? props?.service_id : undefined
    };
    ClientServices.RDList(params1).then(result => {
      let data = EncryptDecrypt.decrypt(result.data);
      setRDList(data.data);
    });
  }, [practiceId]);

  useEffect(() => {
    let filtered = [...RDList];

    if (serviceId) {
      filtered = filtered.filter(rd => {
        return (
          rd?.rd_detail?.service_id === serviceId ||
          rd?.id === Number(DEFAULT_RD_DROPDOWN)
        );
      });
    }

    if (typeValue) {
      filtered = filtered.filter(rd => {
        return rd?.rd_detail?.type === typeValue;
      });
    }

    // if (!serviceId && !typeValue) filtered = [];

    setFilteredRDList([...filtered]);
  }, [serviceId, typeValue, RDList]);

  useEffect(() => {
    getList(field, sortBy, searchText, activePage + 1);
    setPages(10);
  }, [RDId, typeValue, serviceId, practiceId]);

  useEffect(() => {
    CategoryServices.serviceDropdown()
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setServiceList([...data.data]);
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      });
  }, []);

  useEffect(() => {
    const params = {
      get_all: true,
      service_id:
        props.admin_role_id > 3
          ? props.service_id
          : serviceId
          ? serviceId
          : undefined
    };
    CommonServices.iboPracticeDropdownList(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setPracticeList([...data.data]);
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch Practice!");
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch Practice!");
        }
      });
  }, [serviceId, props?.admin_role_id]);

  const toggleRD = () => setRDDropdownOpen(prevState => !prevState);

  const toggleType = () => setTypeDropdownOpen(prevState => !prevState);

  const toggleService = () => setServiceDropdownOpen(prevState => !prevState);

  const columns = [
    {
      Header: "Provider ID",
      accessor: "rd_id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 130
    },
    {
      Header: "Provider First name",
      accessor: "admin.first_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 150
    },
    {
      Header: "Provider Last name",
      accessor: "admin.last_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 150
    },
    {
      Header: "Month Year",
      accessor: "month_year",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 150,
      Cell: props => moment(props.original.month_year).format("MM/YYYY")
    },
    {
      Header: "Actual Unit",
      accessor: "unit",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 150
    },
    {
      Header: "Goal Unit",
      accessor: "goal",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 150
    }
  ];

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  function exportList(
    field = "name",
    sortBy = "DESC",
    search = "",
    page = "1"
  ) {
    const params = {
      sort_by: field,
      order: sortBy,
      search: search,
      page: page,
      rd_id: RDId,
      service_id: props?.admin_role_id < 3 ? serviceId : props.service_id,
      rd_type: typeValue,
      practice_id:
        props?.admin_role_id === 6 && props?.practice
          ? props?.practice
          : practiceId
          ? practiceId
          : undefined
    };
    setIsExporting(true);
    ReportServices.rdUnitGoalListCsv(params)
      .then(res => {
        setIsExporting(false);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error("Something went wrong!");
        setIsExporting(false);
      });
  }

  const formAction = (action, data = null) => {
    // Here you can view the data and make forward action for edit data
    if (action === "export") {
      exportList(field, sortBy, searchText, activePage + 1);
    }
  };

  function getList(field = "name", sortBy = "DESC", search = "", page = "1") {
    const params = {
      sort_by: field,
      order: sortBy,
      search: search,
      page: page,
      rd_id: RDId,
      service_id: props?.admin_role_id < 3 ? serviceId : props.service_id,
      rd_type: typeValue,
      practice_id:
        props?.admin_role_id === 6 && props?.practice
          ? props?.practice
          : practiceId
          ? practiceId
          : undefined
    };
    setLoader(true);
    ReportServices.rdUnitGoalList(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count / TABLE.LIMIT));
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
        setLoader(false);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.message);
        setLoader(false);
      });
  }

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Provider Unit to Goal Report
            </div>
            <div>
              <Button
                className="c-btn c-primary ma-5"
                onClick={() => formAction("export")}
                disabled={!tblData?.length || isExporting}
              >
                <i className="fas fa-download mr-10" />
                {isExporting ? "Exporting.." : "Export"}
              </Button>
            </div>
          </div>
          <div className="roe-card-header module-header">
            {props.admin_role_id < 3 && (
              <>
                <div className="d-flex">
                  {props.admin_role_id < 3 && (
                    <div className="fs-14  mr-10">
                      <Dropdown
                        isOpen={serviceDropdownOpen}
                        toggle={toggleService}
                      >
                        <DropdownToggle caret size="md">
                          {serviceName ? serviceName : "Select Service"}
                        </DropdownToggle>
                        <DropdownMenu
                          modifiers={{
                            setMaxHeight: {
                              enabled: true,
                              order: 890,
                              fn: data => {
                                return {
                                  ...data,
                                  styles: {
                                    ...data.styles,
                                    overflow: "auto",
                                    maxHeight: "300px"
                                  }
                                };
                              }
                            }
                          }}
                        >
                          <DropdownItem
                            key="-1"
                            onClick={() => {
                              setActivePage(0);
                              setPracticeList([]);
                              setPracticeId("");
                              setPracticeName("");
                              setServiceId("");
                              setServiceName("All");
                              setRDId("");
                              setRDName("");
                              setTypeName(null);
                              setTypeValue(null);
                            }}
                          >
                            All
                          </DropdownItem>
                          {serviceList.length > 0 &&
                            serviceList.map((result, i) => {
                              return (
                                <DropdownItem
                                  key={i}
                                  onClick={() => {
                                    setActivePage(0);
                                    setPracticeList([]);
                                    setPracticeId("");
                                    setPracticeName("");
                                    setServiceId(result.id);
                                    setServiceName(`${result.name}`);
                                    setRDId("");
                                    setRDName("");
                                    setTypeName(null);
                                    setTypeValue(null);
                                  }}
                                >
                                  {result.name}
                                </DropdownItem>
                              );
                            })}
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  )}
                  {props?.admin_role_id !== 6 && props?.admin_role_id !== 3 && (
                    <>
                      <div className="fs-14 mr-10">
                        <Dropdown isOpen={typeDropdownOpen} toggle={toggleType}>
                          <DropdownToggle caret size="md">
                            {typeName ? typeName : "Type"}
                          </DropdownToggle>
                          <DropdownMenu
                            modifiers={{
                              setMaxHeight: {
                                enabled: true,
                                order: 890,
                                fn: data => {
                                  return {
                                    ...data,
                                    styles: {
                                      ...data.styles,
                                      overflow: "auto",
                                      maxHeight: "300px"
                                    }
                                  };
                                }
                              }
                            }}
                          >
                            <DropdownItem
                              key="-1"
                              onClick={() => {
                                setRDId(null);
                                setRDName(null);
                                setTypeName("All");
                                setTypeValue("");
                                setActivePage(0);
                              }}
                            >
                              All
                            </DropdownItem>
                            {PROVIDER_TYPES.map((result, i) => {
                              return (
                                <DropdownItem
                                  key={i}
                                  onClick={() => {
                                    setRDId(null);
                                    setRDName(null);
                                    setTypeName(`${result.name}`);
                                    setTypeValue(`${result.value}`);
                                    setActivePage(0);
                                    setPracticeId("");
                                    setPracticeName("");
                                  }}
                                >
                                  {result.name}
                                </DropdownItem>
                              );
                            })}
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                      <div className="fs-14 mr-10">
                        <Dropdown
                          isOpen={practiceDropdownOpen}
                          toggle={togglePractice}
                          disabled={typeValue && typeValue !== "ibo practice"}
                        >
                          <DropdownToggle
                            caret
                            size="md"
                            className={
                              typeValue &&
                              typeValue !== "ibo practice" &&
                              "cursor-not-allowed"
                            }
                          >
                            {" "}
                            {practiceId ? practiceName : "Select Practice"}
                          </DropdownToggle>
                          <DropdownMenu
                            modifiers={{
                              setMaxHeight: {
                                enabled: true,
                                order: 890,
                                fn: data => {
                                  return {
                                    ...data,
                                    styles: {
                                      ...data.styles,
                                      overflow: "auto",
                                      maxHeight: "300px"
                                    }
                                  };
                                }
                              }
                            }}
                          >
                            <DropdownItem
                              key="-1"
                              onClick={() => {
                                setRDId(null);
                                setRDName(null);
                                setPracticeId("");
                                setPracticeName("All");
                                setActivePage(0);
                              }}
                            >
                              All
                            </DropdownItem>
                            {practiceList.length > 0 &&
                              practiceList.map((result, i) => {
                                return (
                                  <DropdownItem
                                    key={i}
                                    onClick={() => {
                                      setRDId(null);
                                      setRDName(null);
                                      setPracticeId(result.id);
                                      setPracticeName(
                                        `${result.practice_name}`
                                      );
                                      setActivePage(0);
                                    }}
                                  >
                                    {result.practice_name}
                                  </DropdownItem>
                                );
                              })}
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
            {props.admin_role_id !== 3 && (
              <div className="d-flex">
                <div className="fs-14  mr-10">
                  <Dropdown isOpen={RDDropdownOpen} toggle={toggleRD}>
                    <DropdownToggle caret size="md">
                      {RDName ? RDName : "Providers"}
                    </DropdownToggle>
                    <DropdownMenu
                      modifiers={{
                        setMaxHeight: {
                          enabled: true,
                          order: 890,
                          fn: data => {
                            return {
                              ...data,
                              styles: {
                                ...data.styles,
                                overflow: "auto",
                                maxHeight: "300px"
                              }
                            };
                          }
                        }
                      }}
                    >
                      <DropdownItem
                        key="-1"
                        onClick={() => {
                          setActivePage(0);
                          setRDId("");
                          setRDName("All");
                        }}
                      >
                        All
                      </DropdownItem>

                      {filteredRDList.length > 0 &&
                        filteredRDList.map((result, i) => {
                          return (
                            <DropdownItem
                              key={i + 1}
                              onClick={() => {
                                setActivePage(0);
                                setRDId(result.id);
                                setRDName(
                                  `${result.first_name} ${result.last_name}`
                                );
                              }}
                            >
                              {result.first_name} {result.last_name}{" "}
                              {result?.rd_detail?.type
                                ? `(${PROVIDER_TYPE[result?.rd_detail?.type]})`
                                : "(-)"}
                            </DropdownItem>
                          );
                        })}
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
            )}
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              LoadingComponent={Loader}
              onFetchData={onChangePageGetData}
              onPageChange={pageIndex => {
                setActivePage(pageIndex);
                getList(field, sortBy, searchText, pageIndex + 1);
              }}
              onSortedChange={sortProperties => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getList(sortProperties[0].id, sort, searchText, 1);
                // alert("sort handler", sortProperties);
                // const orderByDirection = sortProperties[0].desc ? 1 : 0;

                // updateMapStateInfoOrderByColumn(sortProperties[0].id, orderByDirection);
              }}
            />
          </div>
        </div>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};

export default withRouter(RdUnitGoalTable);
