import React, { Fragment, useEffect, useState } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { ThemeProvider } from "styled-components";
// Redux store provider
import { Provider } from "react-redux";
import { store, history, persistor } from "./redux/store";
// Style Root for making media queries to inline css
import { StyleRoot } from "radium";
// Layout Routes
import layoutRoutes from "./routes/index.jsx";
import themes from "./settings/themes";
import AppLocale from "./languageProvider";
import { themeConfig } from "./settings";
import config, { getCurrentLanguage } from "./settings/languageConfig";
import { PersistGate } from "redux-persist/integration/react";
import "./assets/scss/app.scss";
import "pretty-checkbox/src/pretty-checkbox.scss";
import "react-datepicker/dist/react-datepicker.css";
import "react-dropdown-tree-select/dist/styles.css";
import IdleTimeOutHandler from "components/idleTimeoutHandler/idleTimeoutHandler";
import EncryptDecrypt from "api/EncryptDecrypt";
import StaticpageServices from "./api/StaticpageServices";

const currentAppLocale =
  AppLocale[getCurrentLanguage(config.defaultLanguage || "english").locale];

const App = props => {
  const [timeOutInterval, setTimeOutInterval] = useState(120);
  const [timeLoading, setTimeLoading] = useState(true);

  window.onbeforeunload = () => {
    localStorage.setItem("isRefresh", true);
  };

  useEffect(() => {
    const params = {
      id: 5
    };
    StaticpageServices.view(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        setTimeOutInterval(
          Number(data?.data?.content ? data?.data?.content : 120) - 1
        );
        setTimeLoading(false);
      })
      .catch(err => {
        setTimeLoading(false);
      });
  }, []);

  return (
    <Fragment>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <ThemeProvider theme={themes[themeConfig.theme]}>
          <StyleRoot>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                {!timeLoading && (
                  <IdleTimeOutHandler
                    timeOutInterval={timeOutInterval * 60000}
                  />
                )}
                {/*Start layout routes */}
                <Router history={history}>
                  <Switch>
                    <Route
                      exact
                      path="/"
                      render={() => <Redirect to="/dashboard" />}
                    />
                    {layoutRoutes.map((prop, key) => {
                      return (
                        <Route
                          path={prop.path}
                          component={prop.component}
                          key={key}
                          history={history}
                        />
                      );
                    })}
                  </Switch>
                </Router>
                {/*End layout routes */}
              </PersistGate>
            </Provider>
          </StyleRoot>
        </ThemeProvider>
      </IntlProvider>
    </Fragment>
  );
};

export default App;

// If you want to choose different color schema go to settings/index.jsx and set your theme and language.

// If you want to change sidebar nav list then go to util/data/sidebar.jsx
