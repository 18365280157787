import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    first_name: Yup.string().required("This field is required"),
    last_name: Yup.string().required("This field is required")
  }),
  mapPropsToValues: props => ({
    first_name: props.first_name,
    last_name: props.last_name,
    email: props.email,
    profile_image: props.profile_image,
    practice: props.practice,
    role: props.admin_role_id
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
