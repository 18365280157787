import React from "react";
import Button from "../button/Button";

const ViewOutboxFaxModal = props => {
  return (
    <div>
      <form className="pa-24 row">
        <div className="form-group col-md-12">
          <label className="fs-16 medium-text">Note:</label>
          <textarea
            value={props?.viewData}
            readOnly={true}
            rows={6}
            cols={50}
            style={{ maxWidth: "100%", width: 448 }}
          >
            {props?.original?.visit_note}
          </textarea>
        </div>
        <div className="col-12 text-center">
          <Button
            type="button"
            className="c-btn c-secondary form-button fs-16 demi-bold-text mr-10"
            style={{ maxWidth: "200px" }}
            onClick={props.toggle}
          >
            Close
          </Button>
        </div>
      </form>
    </div>
  );
};
export default ViewOutboxFaxModal;
