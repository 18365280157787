import React, { useState, useEffect, useRef } from "react";
import { compose } from "redux";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import BioMetricsTrackingService from "../../api/BioMetricsTracking";
import enhancer from "../viewclient/reassign-rd";
import PageWrapper from "./biometrictracking.style";
import { Line } from "react-chartjs-2";
import CommonModel from "../common/CommonModel";
import EditBioMetricTrackingModal from "./EditBioMetricTrackingModal";
import { Label } from "reactstrap";
import Toaster from "../common/Toaster";

const BioMetricsTrackingDetails = props => {
  const [todayData, setTodayData] = useState([]);

  const [weightMonthData, setWeightMonthData] = useState([]);
  const [weightCountData, setWeightCountData] = useState([]);
  const [weightIndex, setWeightIndex] = useState(-1);

  const [heightMonthData, setHeightMonthData] = useState([]);
  const [heightCountData, setHeightCountData] = useState([]);
  const [heightIndex, setHeightIndex] = useState(-1);

  const [bmiMonthData, setBmiMonthData] = useState([]);
  const [bmiCountData, setBmiCountData] = useState([]);
  const [bmiIndex, setBmiIndex] = useState(-1);

  const [bodyfatMonthData, setBodyFatMonthData] = useState([]);
  const [bodyfatCountData, setBodyFatCountData] = useState([]);
  const [bodyfatIndex, setBodyFatIndex] = useState(-1);

  const [muscleMonthData, setMuscleMonthData] = useState([]);
  const [muscleCountData, setMuscleCountData] = useState([]);
  const [muscleIndex, setMuscleIndex] = useState(-1);

  const [muscleMassMonthData, setMuscleMassMonthData] = useState([]);
  const [muscleMassCountData, setMuscleMassCountData] = useState([]);
  const [muscleMassIndex, setMuscleMassIndex] = useState(-1);

  const hasParam = props.match.params.id;
  const toaster = useRef();
  const [modal, setModal] = useState(undefined);
  const [activityType, setActivityType] = useState(undefined);

  useEffect(() => {
    if (hasParam) {
      getList();
    }
  }, []);

  function getList() {
    const params = {
      user_id: hasParam,
      track_type: "Biometric Tracking"
    };

    BioMetricsTrackingService.list(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.data.today_data) {
          let details = data.data.today_data.tracking_detail;
          details.find((item, i) => {
            if (item.activity_type === "weight") {
              setWeightIndex(i);
            } else if (item.activity_type === "height") {
              setHeightIndex(i);
            } else if (item.activity_type === "bmi") {
              setBmiIndex(i);
            } else if (item.activity_type === "body_fat") {
              setBodyFatIndex(i);
            } else if (item.activity_type === "muscle") {
              setMuscleIndex(i);
            } else if (item.activity_type === "muscle_mass") {
              setMuscleMassIndex(i);
            }
          });
          setTodayData(data.data.today_data.tracking_detail);
        }

        setWeightMonthData(
          data.data.monthly_data.weight.map(data => data.month)
        );
        setWeightCountData(
          data.data.monthly_data.weight.map(data => data.values)
        );
        setHeightMonthData(
          data.data.monthly_data.height.map(data => data.month)
        );
        setHeightCountData(
          data.data.monthly_data.height.map(data => data.values)
        );
        setBmiMonthData(data.data.monthly_data.bmi.map(data => data.month));
        setBmiCountData(data.data.monthly_data.bmi.map(data => data.values));
        setBodyFatMonthData(
          data.data.monthly_data.body_fat.map(data => data.month)
        );
        setBodyFatCountData(
          data.data.monthly_data.body_fat.map(data => data.values)
        );
        setMuscleMonthData(
          data.data.monthly_data.muscle.map(data => data.month)
        );
        setMuscleCountData(
          data.data.monthly_data.muscle.map(data => data.values)
        );
        setMuscleMassMonthData(
          data.data.monthly_data.muscle_mass.map(data => data.month)
        );
        setMuscleMassCountData(
          data.data.monthly_data.muscle_mass.map(data => data.values)
        );
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current) toaster.current.error(err?.message);
        }
      });
  }

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          }
          // scaleLabel: {
          //   display: true,
          //   labelString: 'Visceral Fat (%)'
          // }
        }
      ]
      // xAxes: [
      //   {
      //     scaleLabel: {
      //       display: true,
      //       labelString: 'Month'
      //     }
      //   },
      // ],
    }
  };

  const weightData = {
    labels: weightMonthData,
    datasets: [
      {
        label: "Weight (lbs)",
        data: weightCountData,
        fill: true,
        backgroundColor: "#AECE38",
        borderColor: "#AECE38"
      }
    ]
  };

  const heightData = {
    labels: heightMonthData,
    datasets: [
      {
        label: "Height (ft & in)",
        data: heightCountData,
        fill: true,
        backgroundColor: "#AECE38",
        borderColor: "#AECE38"
      }
    ]
  };

  const bmiData = {
    labels: bmiMonthData,
    datasets: [
      {
        label: "BMI (count)",
        data: bmiCountData,
        fill: true,
        backgroundColor: "#AECE38",
        borderColor: "#AECE38"
      }
    ]
  };

  const bodyFatData = {
    labels: bodyfatMonthData,
    datasets: [
      {
        label: "Body Fat (%)",
        data: bodyfatCountData,
        fill: true,
        backgroundColor: "#AECE38",
        borderColor: "#AECE38"
      }
    ]
  };

  const muscleData = {
    labels: muscleMonthData,
    datasets: [
      {
        label: "Muscle (%)",
        data: muscleCountData,
        fill: true,
        backgroundColor: "#AECE38",
        borderColor: "#AECE38"
      }
    ]
  };

  const muscleMassData = {
    labels: muscleMassMonthData,
    datasets: [
      {
        label: "Muscle Mass (lbs)",
        data: muscleMassCountData,
        fill: true,
        backgroundColor: "#AECE38",
        borderColor: "#AECE38"
      }
    ]
  };

  function toggle1() {
    setModal(false);
  }

  const formAction = activity_type => {
    // Here you can view the data and make forward action for edit data
    setModal(true);
    setActivityType(todayData[activity_type]);
  };

  const submitFormHandler1 = () => {
    setModal(false);
    getList();
  };

  return (
    <PageWrapper>
      <div className="">
        <h4 className="text-center pb-2">Bio Metric Tracking</h4>
        <div className="row">
          <div className="col-md-6">
            <table className="w-100 border">
              <tbody>
                <tr className="text-center">
                  <td>
                    <div
                      className="p-2 w-100"
                      style={{ display: "inline-grid" }}
                    >
                      <Label
                        style={{
                          backgroundColor: "#9b9b9c",
                          color: "white",
                          padding: "10px"
                        }}
                      >
                        Weight
                        {/* {weightIndex !== -1 ? ( */}
                        <span
                          className="float-right"
                          onClick={() => formAction(weightIndex)}
                        >
                          <i className="fas fa-edit"></i>
                        </span>
                        {/* ) : (
                          ""
                        )} */}
                      </Label>
                      <Line data={weightData} options={options} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-6">
            <table className="w-100 border">
              <tbody>
                <tr className="text-center">
                  <td>
                    <div
                      className="p-2 w-100"
                      style={{ display: "inline-grid" }}
                    >
                      <Label
                        style={{
                          backgroundColor: "#9b9b9c",
                          color: "white",
                          padding: "10px"
                        }}
                      >
                        Height
                        {heightIndex !== -1 ? (
                          <span
                            className="float-right"
                            onClick={() => formAction(heightIndex)}
                          >
                            <i className="fas fa-edit"></i>
                          </span>
                        ) : (
                          ""
                        )}
                      </Label>
                      <Line data={heightData} options={options} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-6 mt-4">
            <table className="w-100 border">
              <tbody>
                <tr className="text-center">
                  <td>
                    <div
                      className="p-2 w-100"
                      style={{ display: "inline-grid" }}
                    >
                      <Label
                        style={{
                          backgroundColor: "#9b9b9c",
                          color: "white",
                          padding: "10px"
                        }}
                      >
                        BMI
                        {bmiIndex !== -1 ? (
                          <span
                            className="float-right"
                            onClick={() => formAction(bmiIndex)}
                          >
                            <i className="fas fa-edit"></i>
                          </span>
                        ) : (
                          ""
                        )}
                      </Label>
                      <Line data={bmiData} options={options} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-6 mt-4">
            <table className="w-100 border">
              <tbody>
                <tr className="text-center">
                  <td>
                    <div
                      className="p-2 w-100"
                      style={{ display: "inline-grid" }}
                    >
                      <Label
                        style={{
                          backgroundColor: "#9b9b9c",
                          color: "white",
                          padding: "10px"
                        }}
                      >
                        Body Fat %
                        {bodyfatIndex !== -1 ? (
                          <span
                            className="float-right"
                            onClick={() => formAction(bodyfatIndex)}
                          >
                            <i className="fas fa-edit"></i>
                          </span>
                        ) : (
                          ""
                        )}
                      </Label>
                      <Line data={bodyFatData} options={options} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-6 mt-4">
            <table className="w-100 border">
              <tbody>
                <tr className="text-center">
                  <td>
                    <div
                      className="p-2 w-100"
                      style={{ display: "inline-grid" }}
                    >
                      <Label
                        style={{
                          backgroundColor: "#9b9b9c",
                          color: "white",
                          padding: "10px"
                        }}
                      >
                        Muscle %
                        {muscleIndex !== -1 ? (
                          <span
                            className="float-right"
                            onClick={() => formAction(muscleIndex)}
                          >
                            <i className="fas fa-edit"></i>
                          </span>
                        ) : (
                          ""
                        )}
                      </Label>
                      <Line data={muscleData} options={options} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-6 mt-4">
            <table className="w-100 border">
              <tbody>
                <tr className="text-center">
                  <td>
                    <div
                      className="p-2 w-100"
                      style={{ display: "inline-grid" }}
                    >
                      <Label
                        style={{
                          backgroundColor: "#9b9b9c",
                          color: "white",
                          padding: "10px"
                        }}
                      >
                        Muscle Mass
                        {muscleMassIndex !== -1 ? (
                          <span
                            className="float-right"
                            onClick={() => formAction(muscleMassIndex)}
                          >
                            <i className="fas fa-edit"></i>
                          </span>
                        ) : (
                          ""
                        )}
                      </Label>
                      <Line data={muscleMassData} options={options} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <CommonModel
        modal={modal}
        toggle1={toggle1}
        children={
          <EditBioMetricTrackingModal
            onSubmit1={submitFormHandler1}
            todayData={todayData}
            activityType={activityType}
            toggle1={toggle1}
            {...props}
          />
        }
        header={"Edit Tracking Data"}
      />
      <Toaster ref={toaster} />
    </PageWrapper>
  );
};

export default compose(enhancer)(BioMetricsTrackingDetails);
