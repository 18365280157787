import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    number: Yup.string()
      .min(14, "Must be exactly 10 digits")
      .max(14, "Must be exactly 10 digits")
      .required("This field is required"),
    description: Yup.string()
      .trim()
      .required("This field is required")
      .max(200, "Maximum 200 character"),
    adminAccess: Yup.array()
      .min(1, "Please select at least one person!")
      .of(Yup.mixed().required())
  }),
  mapPropsToValues: props => ({
    number: props.editedData
      ? props && props.editedData && props.editedData.number
      : "",
    description: props.editedData
      ? props && props.editedData && props.editedData.description
      : "",
    adminAccess: props.editedData
      ? props &&
        props.editedData &&
        props.editedData.admin_fax_numbers.map(
          adminDetail => adminDetail.admin.id
        )
      : "",
    id: props.editedData ? props && props.editedData && props.editedData.id : []
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
