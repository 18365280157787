import React, { useEffect, useState, useRef } from "react";
import RegisteredDietitian from "../../api/RegisteredDietitian";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import enhancer from "./statelicence";
import { compose } from "redux";
import DatePicker from "react-datepicker";
import Button from "components/button/Button";
import Toaster from "../common/Toaster";
import StateLicenceWrapper from "./statelicence.style";
import AuthActions from "redux/auth/actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
const { login } = AuthActions;

const StepTwoStateLicence = props => {
  const {
    setFieldValue,
    onSubmit,
    values,
    errors,
    action,
    touched,
    submitCount,
    handleChange
  } = props;
  useEffect(() => {
    return getStateList();
  }, []);
  const toaster = useRef();

  const [stateList, setStateList] = useState([]);
  const [selectedInput, setSelectedInput] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const getStateList = () => {
    RegisteredDietitian.stateList()
      .then(result => {
        let data = EncryptDecrypt.decrypt(result.data);
        setStateList(data);
      })
      .catch(error => {
        console.log("getStateList -> error", error);
      });
  };
  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };
  const checkHandleChange = e => {
    const targtValue = parseInt(e.currentTarget.value);
    setSelectedInput(prev =>
      prev.some(data => data === targtValue)
        ? prev.filter(data => data !== targtValue)
        : [...prev, targtValue]
    );

    let objIndex = values.state.findIndex(obj => obj.state_id === targtValue);
    if (objIndex > -1) {
      values.state.splice(objIndex, 1);
      setFieldValue("state", values.state);
    } else {
      values.state.push({
        state_id: targtValue,
        valid_from: new Date(),
        valid_to: new Date()
      });
    }
    handleChange(e);
  };

  const handlerValidFromDate = (e, id) => {
    let objIndex = values.state.findIndex(obj => obj.state_id === id);
    values.state[objIndex].valid_from = new Date(e);
    setFieldValue(`valid_from${id}`, new Date(e));
    // setFieldValue("state", values.state);
  };

  const handlerValidToDate = (e, id) => {
    let objIndex = values.state.findIndex(obj => obj.state_id === id);
    values.state[objIndex].valid_to = new Date(e);
    setFieldValue(`valid_to${id}`, new Date(e));
  };

  const handleSubmit = () => {
    let { values, isValid, handleSubmit, errors } = props;
    if (toaster.current && props.errors.state !== undefined) {
      setLoading(false);

      toaster.current.error("Please enter valid date");
      return 0;
    }

    const params = {
      state: values.state
    };
    setLoading(true);

    RegisteredDietitian.completeStateLicence(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          let loginData = {
            ...props.auth,
            step: 4
          };
          setLoading(false);

          props.login(loginData);
          if (toaster.current) toaster.current.success(data.message);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });

    // if (values.state.length <= 0) {
    //   setLoading(false);
    //   toaster.current.error("Please select state licence");
    // }
    handleSubmit();
  };
  return (
    <StateLicenceWrapper {...props}>
      <div className="profile-head">
        <h3>Complete State Licence</h3>
      </div>
      <div className="profile-head">
        Select the state(s) where you are licensed.
      </div>
      <form>
        <div className="form-group col-md-12 h-500 overflow-auto">
          <br />
          {stateList.data &&
            stateList.data.map((type, i) => {
              return (
                <div key={i} className="mt-2">
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td style={{ width: "25%" }}>
                          <input
                            type="checkbox"
                            id={type.id}
                            name={`state_id`}
                            onChange={checkHandleChange}
                            value={type.id}
                            checked={
                              selectedInput && selectedInput.includes(type.id)
                                ? "checked"
                                : false
                            }
                            className="checkbox"
                          />
                          {" " + type.name}
                        </td>
                        <td
                          className={
                            selectedInput && selectedInput.includes(type.id)
                              ? ""
                              : "d-none"
                          }
                        >
                          <label className="fs-16 medium-text pr-2">
                            Valid From
                          </label>
                          <DatePicker
                            className="form-control"
                            selected={
                              values.state &&
                              values.state[
                                values.state.findIndex(
                                  obj => obj.state_id === type.id
                                )
                              ] &&
                              values.state[
                                values.state.findIndex(
                                  obj => obj.state_id === type.id
                                )
                              ].valid_from &&
                              new Date(
                                values.state[
                                  values.state.findIndex(
                                    obj => obj.state_id === type.id
                                  )
                                ].valid_from
                              )
                            }
                            id={`valid_from${type.id}`}
                            data-id={type.id}
                            onChange={e => {
                              handlerValidFromDate(e, type.id);
                            }}
                            name={`valid_from${type.id}`}
                            dateFormat="MM/dd/yyyy"
                          />
                        </td>
                        <td
                          className={
                            selectedInput && selectedInput.includes(type.id)
                              ? ""
                              : "d-none"
                          }
                        >
                          <label className="fs-16 medium-text pr-2">
                            Valid To
                          </label>
                          <DatePicker
                            className="form-control"
                            selected={
                              values.state &&
                              values.state[
                                values.state.findIndex(
                                  obj => obj.state_id === type.id
                                )
                              ] &&
                              values.state[
                                values.state.findIndex(
                                  obj => obj.state_id === type.id
                                )
                              ].valid_to &&
                              new Date(
                                values.state[
                                  values.state.findIndex(
                                    obj => obj.state_id === type.id
                                  )
                                ].valid_to
                              )
                            }
                            name={`valid_to${type.id}`}
                            id={`valid_to${type.id}`}
                            onChange={e => {
                              handlerValidToDate(e, type.id);
                            }}
                            dateFormat="MM/dd/yyyy"
                            placeholder="Valid To Date"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              );
            })}
        </div>
        <div>
          <Button
            loading={loading}
            disabled={loading}
            type="button"
            className="c-btn c-info form-button fs-16 demi-bold-text"
            style={{ maxWidth: "150px" }}
            onClick={() => handleSubmit()}
            dataStyle="expand-right"
          >
            Next
          </Button>
        </div>
      </form>
      <Toaster ref={toaster} />
    </StateLicenceWrapper>
  );
};
const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};
export default compose(
  withRouter,
  enhancer,
  connect(mapStateToProps, { login })
)(StepTwoStateLicence);
