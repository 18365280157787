import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    activity_value: Yup.string()
      .trim()
      .required("This field is required"),
    selected_date: Yup.string()
      .trim()
      .required("This field is required")
  }),
  mapPropsToValues: props => ({
    activity_value:
      props.activityType && props && props.activityType
        ? props.activityType.values
        : ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
