import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import ClientServices from "../../api/ClientServices";
import Loader from "../common/Loader";
import Toaster from "../common/Toaster";
import {
  TABLE,
  DEFAULT_RD_DROPDOWN,
  MODULE_NAMES,
  PERMISSION_TYPE,
  SERVICE_NAME
} from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import CommonModel from "../common/CommonModel";
import DeleteModal from "../common/DeleteModal";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
import moment from "moment";
import SendInviteModal from "./sendInviteModal";
import SharedUserDetail from "components/contentLibrary/SharedUserDetail";
import { useDebounce } from "helper/useDebounce";
import CategoryServices from "api/CategoryServices";
import axios from "axios";
import { hasAccess } from "util/sidebar";
import { compose } from "redux";
import { connect } from "react-redux";
import clientFilterActions from "redux/clientFilters/actions";

const { setClientFilters } = clientFilterActions;

const ViewClient = props => {
  const { permissions } = props;
  const [tblData, settblData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("created_at");
  const [sortBy, setSortBy] = useState("DESC");
  const [modal, setModal] = useState(undefined);
  const [groupList, setGroupList] = useState([]);
  const [groupId, setGroupId] = useState(null);
  const [groupName, setGroupName] = useState(null);
  const [clientId, setClientId] = useState("");
  const [RDList, setRDList] = useState([]);
  const [RDId, setRDId] = useState(null);
  const [parentDropdownOpen, setParentDropdownOpen] = useState(false);
  const [RDDropdownOpen, setRDDropdownOpen] = useState(false);
  const [RDName, setRDName] = useState(null);
  const [id, setId] = useState(undefined);
  const [inviteModal, setInviteModal] = useState(undefined);
  const [clientIds, setClientIds] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [serviceId, setServiceId] = useState(null);
  const [filteredRDList, setFilteredRDList] = useState([]);
  const [serviceName, setServiceName] = useState(null);
  const [serviceDropdownOpen, setServiceDropdownOpen] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const debouncedSearchTerm = useDebounce(searchText, 500);
  const debouncedClientId = useDebounce(clientId, 500);
  const toaster = useRef();

  useEffect(() => {
    if (props?.clientFilters?.search) {
      setSearchText(props?.clientFilters?.search);
    }
    if (props?.clientFilters?.clientId) {
      setClientId(props?.clientFilters?.clientId);
    }
    if (
      props?.clientFilters?.groupId ||
      props?.clientFilters?.groupName === "All"
    ) {
      setGroupId(props?.clientFilters?.groupId);
      setGroupName(props?.clientFilters?.groupName);
    }
    if (
      props?.clientFilters?.serviceId ||
      props?.clientFilters?.serviceName === "All"
    ) {
      setServiceId(props?.clientFilters?.serviceId);
      setServiceName(props?.clientFilters?.serviceName);
    }
    if (props?.clientFilters?.RDId) {
      setRDId(props?.clientFilters?.RDId);
      setRDName(props?.clientFilters?.RDName);
    }
    if (props?.clientFilters?.field) {
      setField(props?.clientFilters?.field);
    }
    if (props?.clientFilters?.sortBy) {
      setSortBy(props?.clientFilters?.sortBy);
    }
    if (props?.clientFilters?.activePage) {
      setActivePage(props?.clientFilters?.activePage);
      // setTimeout(() => {
      //   getList(
      //     field,
      //     sortBy,
      //     searchText,
      //     props?.clientFilters?.activePage + 1,
      //     groupId,
      //     clientId
      //   );
      // }, 1800);
    }
  }, [props?.clientFilters]);

  useEffect(() => {
    CategoryServices.serviceDropdown()
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setServiceList([...data.data]);
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      });
  }, []);

  useEffect(() => {
    if (serviceId == null) {
      setFilteredRDList(RDList);
    } else {
      const filtered = RDList.filter(rd => {
        return (
          rd?.rd_detail?.service_id === serviceId ||
          rd?.id === Number(DEFAULT_RD_DROPDOWN)
        );
      });
      setFilteredRDList(filtered);
    }
  }, [serviceId, RDList]);

  useEffect(() => {
    if (props?.auth?.admin_role_id > 2) {
      setServiceId(props?.auth?.service_id);
      setServiceName(SERVICE_NAME[props?.auth?.service_id]);
    }
  }, [props.auth]);

  useEffect(() => {
    const ourRequest = axios.CancelToken.source();
    setTimeout(() => {
      getList(
        field,
        sortBy,
        debouncedSearchTerm,
        activePage + 1,
        groupId,
        debouncedClientId,
        serviceId,
        ourRequest
      );
    }, 500);
    setPages(10);
    return () => {
      ourRequest.cancel(); // <-- 3rd step
    };
  }, [
    groupId,
    RDId,
    debouncedSearchTerm,
    debouncedClientId,
    serviceId,
    field,
    sortBy,
    activePage
  ]);

  useEffect(() => {
    const groupParams = {
      service_id:
        props.auth.admin_role_id < 3 ? serviceId : props.auth.service_id
    };
    ClientServices.groupList(groupParams).then(result => {
      let data = EncryptDecrypt.decrypt(result.data);
      setGroupList(data.data);
    });

    const params1 = {
      type: "",
      practice_id:
        props.auth && props.auth?.admin_role_id === 6 && props.auth?.practice
          ? props.auth?.practice
          : undefined
    };
    ClientServices.RDList(params1).then(result => {
      let data = EncryptDecrypt.decrypt(result.data);
      setRDList(data.data);
    });
    setRDId(
      props.auth && props.auth.admin_role_id !== 3
        ? props?.clientFilters?.RDId
          ? props?.clientFilters?.RDId
          : null
        : props.auth.id
    );
  }, [serviceId, props?.clientFilters?.RDId]);

  const applyClientFilters = () => {
    const clientFiltersTemp = {};
    if (groupId || groupName === "All") {
      clientFiltersTemp.groupId = groupId;
      clientFiltersTemp.groupName = groupName;
    }
    if (serviceId || serviceName === "All") {
      clientFiltersTemp.serviceId = serviceId;
      clientFiltersTemp.serviceName = serviceName;
    }
    if (RDId) {
      clientFiltersTemp.RDId = RDId;
      clientFiltersTemp.RDName = RDName;
    }
    clientFiltersTemp.search = searchText;
    clientFiltersTemp.clientId = clientId;
    clientFiltersTemp.activePage = activePage;
    clientFiltersTemp.field = field;
    clientFiltersTemp.sortBy = sortBy;
    props.setClientFilters({ ...props?.clientFilters, ...clientFiltersTemp });
  };

  const toggleOne = () => setParentDropdownOpen(prevState => !prevState);

  const toggleRD = () => setRDDropdownOpen(prevState => !prevState);

  const toggleService = () => setServiceDropdownOpen(prevState => !prevState);

  const deleteClick = data => {
    setId(data.id);
    setModal(true);
  };

  const handleClientSelect = (values, status) => {
    let update = [...clientIds];
    const index = update.findIndex(value => values?.id === value?.id);
    if (index !== -1) {
      update.splice(index, 1);
    } else if (update.length < 25) {
      update.push(values);
    }
    setClientIds([...update]);
  };
  const handleResetSelectedClient = () => {
    setClientIds([]);
  };

  const handleSelectAllClient = () => {
    let update = [...clientIds];
    !!tblData.length &&
      tblData.forEach(data => {
        if (
          !update.find(el => el.id === data.id) && //includes(data.id)
          update.length < 25 &&
          (data?.nutrition_provider || data?.mental_health_provider)
        ) {
          update.push(data);
        }
      });
    setClientIds([...update]);
  };

  let columns = [
    {
      Header: "Select",
      accessor: "id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 80,
      sortable: false,

      Cell: props =>
        (props?.original?.nutrition_provider ||
          props?.original?.mental_health_provider) && (
          <div
            className="flex justify-content-center align-items-center"
            style={{ paddingTop: 4 }}
          >
            <div
              style={{
                height: 14,
                width: 14,
                borderRadius: 4,
                backgroundColor: "#d4d4d4",
                backgroundColor: clientIds.find(el => el.id === props.value)
                  ? "#3f3f40"
                  : "#d4d4d4",
                cursor:
                  !clientIds.find(el => el.id === props.value) &&
                  clientIds.length >= 25
                    ? "not-allowed"
                    : "pointer"
              }}
              role="button"
              onClick={() => handleClientSelect(props.original)}
            />
          </div>
        )
    },
    {
      Header: "ID",
      accessor: "id",
      className: "",
      filterable: false,
      headerClassName: `react-table-header-class sort-sign ${
        field === "id" ? (sortBy === "DESC" ? "-sort-desc" : "-sort-asc") : ""
      }`,
      width: 100,
      sortable: true
    },
    {
      Header: "First Name",
      accessor: "first_name",
      className: "",
      filterable: false,
      headerClassName: `react-table-header-class sort-sign ${
        field === "first_name"
          ? sortBy === "DESC"
            ? "-sort-desc"
            : "-sort-asc"
          : ""
      }`,
      width: 200,
      sortable: true
    },
    {
      Header: "Last Name",
      accessor: "last_name",
      className: "",
      filterable: false,
      headerClassName: `react-table-header-class sort-sign ${
        field === "last_name"
          ? sortBy === "DESC"
            ? "-sort-desc"
            : "-sort-asc"
          : ""
      }`,
      width: 200,
      sortable: true
    },
    {
      Header: "Email",
      accessor: "email",
      className: "",
      filterable: false,
      headerClassName: `react-table-header-class sort-sign ${
        field === "email"
          ? sortBy === "DESC"
            ? "-sort-desc"
            : "-sort-asc"
          : ""
      }`,
      width: 250,
      sortable: true
    },
    {
      Header: "Nutrition Group name",
      accessor: "groupDetails[0].group.name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 200,
      sortable: false
    },
    {
      Header: "MH Group name",
      accessor: "groupDetails[1].group.name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 200,
      sortable: false
    },
    {
      Header: "Nutrition Provider",
      accessor: "nutrition_provider",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 200,
      sortable: false,
      Cell: props => (
        <div className="react-action-class">
          {props.original?.nutrition_provider?.admin
            ? props.original?.nutrition_provider?.admin?.first_name +
              " " +
              props.original?.nutrition_provider?.admin?.last_name
            : "-"}
        </div>
      )
    },
    {
      Header: "Mental Health Provider",
      accessor: "mental_health_provider",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 200,
      sortable: false,
      Cell: props => (
        <div className="react-action-class">
          {props.original?.mental_health_provider?.admin
            ? props.original?.mental_health_provider?.admin?.first_name +
              " " +
              props.original?.mental_health_provider?.admin?.last_name
            : "-"}
        </div>
      )
    },

    // {
    //   Header: "Active Client",
    //   accessor: "is_appointment_booked",
    //   className: "",
    //   filterable: false,
    //   headerClassName: "react-table-header-class sort-sign",
    //   width: 150,
    //   sortable: true,
    //   Cell: (props) => (
    //     <div className="react-action-class">
    //       {props.original.is_appointment_booked ? "Active" : "InActive"}
    //     </div>
    //   ),
    // },
    // {
    //     Header: "Status",
    //     accessor: "status",
    //     className: "",
    //     headerClassName: "react-table-header-class",
    //     sortable: false,
    //     filterable: false,
    //     width: 270,
    //     Cell: props => (
    //         <div className="react-action-class">
    //             {props.original.status === ACTIVE_ACCEPTING_CLIENT
    //                 ? "Active, accepting new clients"
    //                 : props.original.status === ACTIVE_NOT_ACCEPTING_CLIENT
    //                     ? "Active, not accepting new clients"
    //                     : props.original.status === TEMPORARY_INACTIVE
    //                         ? "Temporarily inactive"
    //                         : props.original.status === PERMANENTALY_INACTIVE
    //                             ? "Permanently inactive"
    //                             : ""}
    //         </div>
    //     ) // Custom cell components!
    // },
    {
      Header: "Action",
      accessor: "action",
      className: "",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 150,
      Cell: colProps => (
        <div className="react-action-class">
          {hasAccess(MODULE_NAMES.CLIENT, PERMISSION_TYPE.VIEW, permissions) ? (
            <Button
              className="c-btn c-info mr-10"
              onClick={() => formAction("view", colProps.original)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-eye" />
              </div>
            </Button>
          ) : null}
          {hasAccess(MODULE_NAMES.CLIENT, PERMISSION_TYPE.EDIT, permissions) ? (
            <Button
              className="c-btn c-success mr-10"
              onClick={() => formAction("edit", colProps.original)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-edit" />
              </div>
            </Button>
          ) : null}
          {hasAccess(
            MODULE_NAMES.CLIENT,
            PERMISSION_TYPE.DELETE,
            permissions
          ) ? (
            <Button
              className="c-btn c-danger"
              onClick={() => deleteClick(colProps.original)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-trash" />
              </div>
            </Button>
          ) : (
            ""
          )}
        </div>
      ) // Custom cell components!
    }
  ];

  if (props.auth) {
    let nutrition = {
      Header: "Days since last visit - Nutrition",
      accessor: "nutrition_last_visit",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",
      sortable: false,
      width: 250,
      Cell: ({ value }) => (
        <div>
          {value && value?.length
            ? Math.floor(
                moment(moment().format("YYYY-MM-DD"))
                  .utc()
                  .diff(moment(value[0]?.appointment_date), "days", false)
              )
            : "-"}
        </div>
      )
    };

    let mentalHealth = {
      Header: "Days since last visit - Mental Health",
      accessor: "mental_health_last_visit",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",
      sortable: false,
      width: 290,
      Cell: ({ value }) => (
        <div>
          {value && value?.length
            ? Math.floor(
                moment(moment().format("YYYY-MM-DD"))
                  .utc()
                  .diff(moment(value[0]?.appointment_date), "days", false)
              )
            : "-"}
        </div>
      )
    };
    if (props.auth.admin_role_id <= 2) {
      columns.splice(8, 0, nutrition);
      columns.splice(9, 0, mentalHealth);
    }

    if (props.auth.admin_role_id > 2) {
      columns.splice(
        8,
        0,
        props.auth.service_id === 1 ? nutrition : mentalHealth
      );
    }
  }

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  function exportList(
    field = "name",
    sortBy = "DESC",
    search = "",
    page = "1"
  ) {
    const params = {
      sort_by: field,
      order: sortBy,
      service_id: serviceId,
      search: search,
      page: page,
      group_id: groupId,
      id: clientId,
      rd_id: RDId
    };
    setIsExporting(true);
    ClientServices.clientDetailListCsvExport(params)
      .then(res => {
        setIsExporting(false);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error("Something went wrong!");
        setIsExporting(false);
      });
  }

  const formAction = (action, data = null) => {
    // Here you can view the data and make forward action for edit data
    // if (action === "add") {
    //   props.history.push("/rd/add");
    // } else if (action === "edit") {
    //   props.history.push(`/rd/edit/${data.id}`);
    // }
    applyClientFilters();
    if (action === "view") {
      props.history.push(`/client/view/${data.id}`, { ...data });
    } else if (action === "edit") {
      props.history.push(`/client/edit/${data.id}`);
    } else if (action === "add") {
      props.history.push(`/client/add`);
    } else if (action === "export") {
      exportList(field, sortBy, searchText, activePage + 1);
    }
  };

  function deleteHandler() {
    setLoader(true);
    const params = {
      id: id
    };
    ClientServices.deleteClient(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current) toaster.current.success(data.message);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
        setLoader(false);
        setModal(false);
        setTimeout(() => {
          getList(field, sortBy, searchText, activePage + 1, groupId, clientId);
        }, 1000);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });
  }

  function toggle() {
    setModal(false);
  }
  function inviteToggle() {
    setInviteModal(false);
  }

  function getList(
    field = "created_at",
    sortBy = "DESC",
    search = "",
    page = "1",
    group_id = null,
    client_id = null,
    service_id = null,
    ourRequest = null
  ) {
    setLoader(true);
    const params = {
      field: field,
      sort_by: sortBy,
      order: sortBy,
      search: search,
      page: page,
      group_id: group_id,
      rd_id:
        props.auth && props.auth.admin_role_id !== 3 ? RDId : props.auth.id,
      id: client_id,
      service_id:
        props.auth && props.auth.admin_role_id > 2
          ? props?.auth?.service_id
          : serviceId,
      practice_id:
        props.auth && props.auth?.admin_role_id === 6 && props.auth?.practice
          ? props.auth?.practice
          : undefined
    };
    setLoader(true);
    let cT = {};

    if (ourRequest?.token) {
      cT.cancelToken = ourRequest?.token;
    }

    ClientServices.listClient(params, cT)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data?.data?.count?.length / TABLE.LIMIT));
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
          setLoader(false);
        }
      })
      .catch(err => {
        if (err?.message) {
          if (toaster.current) toaster.current.error(err.message);
          setLoader(false);
        }
      });
  }

  const removeFromList = id => {
    const update = [...clientIds];
    let index = update.findIndex(x => x.id === +id);
    update.splice(index, 1);
    setClientIds([...update]);
  };

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Client List
            </div>

            {hasAccess(
              MODULE_NAMES.CLIENT,
              PERMISSION_TYPE.ADD,
              permissions
            ) ? (
              <div>
                <Button
                  className="c-btn c-primary ma-5"
                  onClick={() => formAction("add")}
                >
                  <i className="fas fa-plus mr-10" />
                  Add Client
                </Button>
              </div>
            ) : null}
            <div>
              <Button
                className="c-btn c-primary ma-5"
                // onClick={() => formAction("add")}
                onClick={() => setInviteModal(true)}
                disabled={!clientIds.length}
                title={!clientIds.length && "Select a Client first!"}
              >
                <i className="fas fa-paper-plane mr-10" />
                Invite Client
              </Button>
            </div>
            {hasAccess(
              MODULE_NAMES.CLIENT,
              PERMISSION_TYPE.LOOKUP,
              permissions
            ) && (
              <div>
                <Button
                  className="c-btn c-primary ma-5"
                  onClick={() => formAction("export")}
                  disabled={
                    !tblData?.length ||
                    RDId !== "null" ||
                    !serviceId ||
                    isExporting
                  }
                >
                  <i className="fas fa-download mr-10" />
                  {isExporting ? "Exporting.." : "Export"}
                </Button>
              </div>
            )}
          </div>
          <div
            className="fs-16"
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap"
            }}
          >
            {clientIds.map(user => (
              <SharedUserDetail onSubmit={removeFromList} user={user} />
            ))}
          </div>
          <div className="d-flex">
            <div className="roe-card-header module-header">
              {clientIds.length > 0 && (
                <div className="flex fs-16 demi-bold-text pr-10">
                  <span className="hash"></span> {clientIds.length + " of 25 "}
                  Selected
                </div>
              )}
              <Button
                className="c-btn c-primary ma-5"
                onClick={() => handleSelectAllClient()}
                disabled={!tblData.length || clientIds?.length >= 25}
              >
                Select All on this page
              </Button>
            </div>

            <div className="align-self-center">
              {!!clientIds?.length && (
                <Button
                  className="c-btn c-primary ma-5"
                  onClick={handleResetSelectedClient}
                >
                  Unselect All
                </Button>
              )}
            </div>
          </div>

          <div className="roe-card-header module-header">
            <div className="d-flex">
              {/* <div className="fs-14 mr-10">
                                <Dropdown isOpen={true} toggle={true}>
                                    <DropdownToggle caret size="md">
                                        Test
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {categoryList.map((result, i) => {
                                            return (
                                                <DropdownItem
                                                    key={i}
                                                //onClick={() => setParentCategoryId(i + 1)}
                                                >
                                                    {result}
                                                </DropdownItem>
                                            );
                                        })}
                                    </DropdownMenu>
                                </Dropdown>

                            </div> */}
              <div className="fs-14 mr-10">
                <Dropdown isOpen={parentDropdownOpen} toggle={toggleOne}>
                  <DropdownToggle caret size="md">
                    {groupName ? groupName : "Select Group"}
                  </DropdownToggle>
                  <DropdownMenu
                    modifiers={{
                      setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: data => {
                          return {
                            ...data,
                            styles: {
                              ...data.styles,
                              overflow: "auto",
                              maxHeight: "300px"
                            }
                          };
                        }
                      }
                    }}
                  >
                    <DropdownItem
                      key="0"
                      onClick={() => {
                        setLoader(true);
                        setActivePage(0);
                        setGroupId("");
                        setGroupName("All");
                      }}
                    >
                      All
                    </DropdownItem>
                    {groupList.length > 0 &&
                      groupList.map((result, i) => {
                        return (
                          <DropdownItem
                            key={i}
                            onClick={() => {
                              setLoader(true);
                              setActivePage(0);
                              setGroupId(result.id);
                              setGroupName(result.name);
                            }}
                          >
                            {result.name}
                          </DropdownItem>
                        );
                      })}
                  </DropdownMenu>
                </Dropdown>
              </div>
              {props.auth && props.auth.admin_role_id <= 2 && (
                <div className="fs-14  mr-10">
                  <Dropdown isOpen={serviceDropdownOpen} toggle={toggleService}>
                    <DropdownToggle caret size="md">
                      {serviceName ? serviceName : "Select Service"}
                    </DropdownToggle>
                    <DropdownMenu
                      modifiers={{
                        setMaxHeight: {
                          enabled: true,
                          order: 890,
                          fn: data => {
                            return {
                              ...data,
                              styles: {
                                ...data.styles,
                                overflow: "auto",
                                maxHeight: "300px"
                              }
                            };
                          }
                        }
                      }}
                    >
                      <DropdownItem
                        key="-1"
                        onClick={() => {
                          setLoader(true);
                          setActivePage(0);
                          setServiceId("");
                          setServiceName("All");
                          setRDId("");
                          setRDName("");
                        }}
                      >
                        All
                      </DropdownItem>
                      {serviceList.length > 0 &&
                        serviceList.map((result, i) => {
                          return (
                            <DropdownItem
                              key={i}
                              onClick={() => {
                                setLoader(true);
                                setActivePage(0);
                                setServiceId(result.id);
                                setServiceName(`${result.name}`);
                                setRDId("");
                                setRDName("");
                              }}
                            >
                              {result.name}
                            </DropdownItem>
                          );
                        })}
                    </DropdownMenu>
                  </Dropdown>
                </div>
              )}
              {props.auth &&
                (props.auth.admin_role_id !== 3 ||
                  props.auth.admin_role_id === 6) && (
                  <div className="fs-14  mr-10">
                    <Dropdown
                      isOpen={RDDropdownOpen}
                      toggle={toggleRD}
                      disabled={!filteredRDList.length || !serviceId}
                      title="Please select service first!"
                    >
                      <DropdownToggle
                        caret
                        size="md"
                        className={
                          (!filteredRDList.length || !serviceId) &&
                          "cursor-not-allowed"
                        }
                      >
                        {RDName ? RDName : "Providers"}
                      </DropdownToggle>
                      <DropdownMenu
                        modifiers={{
                          setMaxHeight: {
                            enabled: true,
                            order: 890,
                            fn: data => {
                              return {
                                ...data,
                                styles: {
                                  ...data.styles,
                                  overflow: "auto",
                                  maxHeight: "300px"
                                }
                              };
                            }
                          }
                        }}
                      >
                        {/* <DropdownItem
                        key="-1"
                        onClick={() => {
                          setRDId("");
                          setRDName("All");
                        }}
                      >
                        All
                      </DropdownItem> */}
                        {filteredRDList.length > 0 && (
                          <DropdownItem
                            key="0"
                            onClick={() => {
                              setLoader(true);
                              setActivePage(0);
                              setRDId("null");
                              setRDName("Not Assigned");
                            }}
                          >
                            Not Assigned
                          </DropdownItem>
                        )}
                        {filteredRDList.length > 0 &&
                          filteredRDList.map((result, i) => {
                            return (
                              <DropdownItem
                                key={i + 1}
                                onClick={() => {
                                  setLoader(true);
                                  setActivePage(0);
                                  setRDId(result.id);
                                  setRDName(
                                    `${result.first_name} ${result.last_name}`
                                  );
                                }}
                              >
                                {result.first_name} {result.last_name}
                              </DropdownItem>
                            );
                          })}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                )}
              <div className="fs-14 mr-10">
                <input
                  value={searchText}
                  onChange={e => {
                    setSearchText(e.target.value);
                    setLoader(true);
                    setActivePage(0);
                  }}
                  type="text"
                  placeholder="Search..."
                  className="fs-14 medium-text plr-10 form-control react-form-input  mr-10"
                />
              </div>
              <div className="fs-14 mr-10">
                <input
                  value={clientId}
                  onChange={e => {
                    setClientId(e.target.value);
                    setLoader(true);
                    setActivePage(0);
                  }}
                  type="number"
                  placeholder="Search by ID"
                  className="fs-14 medium-text plr-10 form-control react-form-input  mr-10"
                />
              </div>
            </div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              LoadingComponent={Loader}
              onFetchData={onChangePageGetData}
              onPageChange={pageIndex => {
                setLoader(true);
                setActivePage(pageIndex);
                // getList(
                //   field,
                //   sortBy,
                //   searchText,
                //   pageIndex + 1,
                //   groupId,
                //   clientId,
                //   serviceId
                // );
              }}
              onSortedChange={sortProperties => {
                setLoader(true);
                const sort = sortProperties[0].desc ? "DESC" : "ASC";

                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                // getList(
                //   sortProperties[0].id,
                //   sort,
                //   searchText,
                //   1,
                //   groupId,
                //   clientId,
                //   serviceId
                // );
                // alert("sort handler", sortProperties);
                // const orderByDirection = sortProperties[0].desc ? 1 : 0;

                // updateMapStateInfoOrderByColumn(sortProperties[0].id, orderByDirection);
              }}
            />
          </div>
          <CommonModel
            modal={modal}
            toggle={toggle}
            children={
              <DeleteModal
                text={"Are you sure you want to delete this user?"}
                deleteHandler={deleteHandler}
                toggle={toggle}
              />
            }
          />
          <CommonModel
            modal={inviteModal}
            toggle={inviteToggle}
            size="lg"
            children={
              <SendInviteModal
                clientIds={clientIds}
                setClientIds={setClientIds}
                toggle={inviteToggle}
                service={props?.auth?.service_id || 0}
              />
            }
          />
        </div>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    clientFilters: state.clientFiltersReducer.clientFilters
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, { setClientFilters })
)(ViewClient);
