import React, { Fragment, useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
import ProfileWrapper from "./profile.style";
import enhancer from "./step-one-validator";
import { compose } from "redux";
import Button from "components/button/Button";
import { ImageResolution } from "../../helper/constant";
import RegisteredDietitian from "../../api/RegisteredDietitian";
import AuthActions from "redux/auth/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
const { login } = AuthActions;
const StepOneProfileForm = props => {
  const {
    handleChange,
    handleBlur,
    setFieldTouched,
    setFieldValue,
    submitCount,
    errors,
    values,
    touched
  } = props;
  let categoryList = [
    "Weight Management",
    "Disease Management",
    "Healthy Lifestyle"
  ];
  const [parentDropdownOpen, setParentDropdownOpen] = useState(false);
  const [secondDropdownOpen, setSecondDropdownOpen] = useState(false);
  const [parentCategoryId, setParentCategoryId] = useState(null);
  const [secondCategoryId, setSecondCategoryId] = useState(null);

  // const [preview, setPreview] = useState(null);

  const toggleOne = () => setParentDropdownOpen(prevState => !prevState);
  const toggleTwo = () => setSecondDropdownOpen(prevState => !prevState);

  const [imageObj, setImageObj] = useState(null);
  const [uploadImage, setUploadImage] = useState(null);
  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;
    if (isValid) {
      values.primary_category_id = parentCategoryId;
      values.secondary_category_id = secondCategoryId;
      values.image = imageObj;
      RegisteredDietitian.completeProfile(values)
        .then(result => {
          let loginData = {
            ...props.auth,
            step: 3
          };
          props.login(loginData);
        })
        .catch(error => {
          console.log("handleSubmit -> error", error);
        });
    }
    handleSubmit();
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };
  const handleImage = (e, setValue) => {
    if (e.target.files[0]) {
      let fileObj = e.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(fileObj);
      reader.onloadend = () => {
        setImageObj(reader.result);
      };

      if (fileObj) {
        const img = new Image();
        img.src = window.URL.createObjectURL(fileObj);
        img.onload = function() {
          const width = img.naturalWidth,
            height = img.naturalHeight;

          window.URL.revokeObjectURL(img.src);
          setValue("width", width);
          setValue("height", height);
          setFieldTouched("image", true, true);
          if (
            width >= ImageResolution.WIDTH &&
            height >= ImageResolution.HEIGHT
          ) {
            setValue("image", URL.createObjectURL(fileObj));
            setUploadImage(URL.createObjectURL(fileObj));
          }
        };
      } else {
        //No file was input or browser doesn't support client side reading
        // form.submit();
      }
    } else {
      setUploadImage();
    }
  };

  return (
    <Fragment>
      <ProfileWrapper {...props}>
        <div className="profile-head">
          <h3>Complete RD profile</h3>
        </div>
      </ProfileWrapper>
      {!props.isModal && (
        <form>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 ">
              <div className="form-group row ">
                <label className="fs-16 medium-text col-sm-3">
                  First Name:{" "}
                </label>
                <div className="col-8">
                  <input
                    type="text"
                    className="form-control "
                    id="first_name"
                    name="first_name"
                    placeholder="First name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.first_name}
                  />
                  <Error field="first_name" />
                </div>
              </div>
              <div className="form-group row">
                <label className="fs-16 medium-text col-sm-3">
                  Last Name:{" "}
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    id="last_name"
                    name="last_name"
                    placeholder="Last name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.last_name}
                  />
                  <Error field="last_name" />
                </div>
              </div>

              <div className="form-group row ">
                <label className="fs-16 medium-text  col-sm-3">
                  Profile pic:{" "}
                </label>
                <div className="col-sm-8">
                  <div className="form-group">
                    <label className="fs-16 medium-text c-btn c-primary demi-bold-text mt-15 wp-17">
                      Upload Image
                      <input
                        id="image"
                        className="file-upload__input"
                        name="image"
                        type="file"
                        onChange={e => handleImage(e, setFieldValue)}
                        onBlur={handleBlur}
                        required={true}
                        accept="image/jpg,image/png,image/jpeg"
                      />
                    </label>
                    <Error field="image" />
                  </div>
                  {imageObj && (
                    <img
                      src={imageObj}
                      alt=""
                      className="mtb-15"
                      style={{
                        width: "200px",
                        borderRadius: "4%",
                        height: "150px",
                        background: "#404040"
                        // display: displaya,
                      }}
                      // onChange={handleImage}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="form-group row ">
                <label className="fs-16 medium-text col-sm-4">
                  Primary Category:
                </label>
                <div className="col-sm-8">
                  <Dropdown isOpen={parentDropdownOpen} toggle={toggleOne}>
                    <DropdownToggle caret size="md">
                      {parentCategoryId
                        ? categoryList[parentCategoryId - 1]
                        : "Primary Category"}
                    </DropdownToggle>
                    <DropdownMenu>
                      {categoryList.map((result, i) => {
                        return (
                          <DropdownItem
                            key={i}
                            onClick={() => setParentCategoryId(i + 1)}
                          >
                            {result}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
              <div className="form-group row ">
                <label className="fs-16 medium-text col-sm-4">
                  Secondary Category:
                </label>
                <div className="col-sm-8">
                  <Dropdown isOpen={secondDropdownOpen} toggle={toggleTwo}>
                    <DropdownToggle caret size="md">
                      {secondCategoryId
                        ? categoryList[secondCategoryId - 1]
                        : "Secondary Category"}
                    </DropdownToggle>
                    <DropdownMenu>
                      {categoryList.map((result, i) => {
                        return (
                          <DropdownItem
                            key={i}
                            onClick={() => setSecondCategoryId(i + 1)}
                          >
                            {result}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
              <div className="form-group row ">
                <label className="fs-16 medium-text col-sm-3">
                  Profile Text:
                </label>
                <div className="col-sm-8">
                  <textarea
                    className="form-control react-form-input"
                    rows="3"
                    placeholder="Profile text"
                    id="profile_text"
                    name="profile_text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.profile_text}
                  ></textarea>
                  <Error field="profile_text" />
                </div>
              </div>
              {/* <div className="form-group row ">
                <label className="fs-16 medium-text col-sm-3">
                  Calendly:
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    id="calendly_name"
                    name="calendly_name"
                    placeholder="Calendly"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.calendly_name}
                  />
                  <Error field="profile_text" />
                </div>
              </div> */}
              {/* <div className="form-group row  ">
                <div className="col-sm-8  d-flex align-item-center">
                  <Button
                    loading={true}
                    disabled={false}
                    dataStyle="expand-right"
                    type="button"
                    className="c-btn c-info form-button fs-16 demi-bold-text"
                    style={{ maxWidth: "150px" }}
                    onClick={() => handleSubmit()}
                  >
                    Next
                  </Button>
                </div>
              </div> */}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 form-group row d-flex align-item-center">
              <div className="col-sm-2 ">
                <Button
                  // loading={true}
                  disabled={false}
                  dataStyle="expand-right"
                  type="button"
                  className="c-btn c-info form-button fs-16 demi-bold-text"
                  style={{ maxWidth: "150px" }}
                  onClick={() => handleSubmit()}
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </form>
      )}
    </Fragment>
  );
};
const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};
export default compose(
  withRouter,
  enhancer,
  connect(mapStateToProps, { login })
)(StepOneProfileForm);
