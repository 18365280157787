import React from "react";
import PageWrapper from "./VisitNote.style";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";
import ViewVisitNote from "../../components/visittracking/ViewVisitNote";

const Insurance = props => {
  return (
    <PageWrapper {...props}>
      <div className="user-tbl">
        <ViewVisitNote {...props} />
      </div>
    </PageWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    authData: {
      token: state.auth.token,
      isLogin: state.auth.isLogin,
      profileImage: state.auth.profile_image,
      adminRoleId: state.auth.admin_role_id,
      adminRole: state.auth.admin_role,
      full_name: state.auth.first_name + " " + state.auth.last_name
    }
  };
};

export default connect(mapStateToProps, null)(Insurance);
