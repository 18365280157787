import React, { Fragment, useState, useEffect, useRef } from "react";
import { compose } from "redux";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import FoodTrackingService from "../../api/FoodTracking";
import enhancer from "../viewclient/reassign-rd";
import Button from "../button/Button";
import Toaster from "../common/Toaster";
import CommonModel from "../common/CommonModel";
import FoodModel from "./FoodModel";
import PageWrapper from "./foodtracking.style";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
import { PLACEHOLDER } from "helper/constant";
const filterArr = [
  {
    id: 0,
    label: "All",
    db_label: "All"
  },
  {
    id: 1,
    label: "Breakfast",
    db_label: "Breakfast"
  },
  {
    id: 2,
    label: "Lunch",
    db_label: "Lunch"
  },
  {
    id: 3,
    label: "Snack",
    db_label: "Brunch"
  },
  {
    id: 4,
    label: "Dinner",
    db_label: "Dinner"
  }
];
const FoodTrackingDetails = props => {
  const { touched, submitCount, errors } = props;

  const [editedData, setEditedData] = useState([]);
  const [selectedFood, setSelectedFood] = useState(null);
  const [mealCount, setMealCount] = useState(null);
  const [dayCount, setDayCount] = useState(null);
  const [filterIndex, setFilterIndex] = useState(0);

  const [modal, setModal] = useState(undefined);
  const [menuOpen, setMenuOpen] = useState(false);

  const hasParam = props.match.params.id;
  const toaster = useRef();
  const toggleMenu = () => setMenuOpen(prevState => !prevState);
  function toggle1() {
    setModal(false);
  }
  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  useEffect(() => {
    if (hasParam) {
      const params = {
        userId: hasParam
      };
      FoodList(params);
    }
  }, []);
  useEffect(() => {
    if (hasParam) {
      const params = {
        userId: hasParam,
        search: filterIndex < 1 ? "" : filterArr[filterIndex].db_label
      };
      FoodList(params);
    }
  }, [filterIndex]);
  const FoodList = params => {
    FoodTrackingService.list(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        setEditedData(data.data.food_list);
        setMealCount(data.data.meal_count);
        setDayCount(data.data.day_count);
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current) toaster.current.error(err?.message);
        }
      });
  };
  return (
    <PageWrapper>
      <div className="d-flex justify-content-between m-3">
        <div className="d-flex">
          <div className="Card-3 p-3 text-center mr-4">
            <div className="font-style-2">{mealCount && mealCount}</div>
            <div className="font-style-2">Meal count</div>
          </div>

          <div className="Card-3 p-3 text-center ">
            <div className="font-style-2">{dayCount && dayCount}</div>
            <div className="font-style-2">Day count</div>
          </div>
        </div>

        <div className="d-flex ">
          <div className="col-sm-8">
            <Dropdown isOpen={menuOpen} toggle={toggleMenu}>
              <DropdownToggle caret size="md">
                {filterIndex > 0 ? filterArr[filterIndex].label : "Filter By"}
              </DropdownToggle>
              <DropdownMenu>
                {filterArr.map((result, i) => {
                  return (
                    <DropdownItem
                      key={i}
                      onClick={() => setFilterIndex(result.id)}
                    >
                      {result.label}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </div>
      <div className="food-container">
        {editedData && editedData.length
          ? editedData.map(result => {
              return (
                <div
                  className="col-md-3 food-box"
                  onClick={() => {
                    setSelectedFood(result);
                    setModal(true);
                  }}
                >
                  <img
                    src={result.meal_image || PLACEHOLDER}
                    className="food-img"
                  ></img>
                  <div className="food-goal-container">
                    {result?.user_food_items?.slice(0, 25).map(item => (
                      <div
                        className="food-goal"
                        style={{
                          backgroundColor: `${item?.food_item?.food_category?.color}`
                        }}
                      >
                        {item?.food_item?.food_name}
                      </div>
                    ))}
                    {result?.user_food_items?.length > 25 && (
                      <div
                        className="food-goal cursor-pointer"
                        style={{
                          backgroundColor: "#93c5fd"
                        }}
                      >
                        ...
                      </div>
                    )}
                  </div>
                  <div className="food-title">{result.description}</div>
                </div>
              );
            })
          : "No food track"}
        {props.auth && props.auth.admin_role_id <= 2 && (
          <div className="col-md-6">
            <Toaster ref={toaster} />
          </div>
        )}
        <CommonModel
          modal={modal}
          toggle1={toggle1}
          size="xl"
          children={
            <FoodModel
              // onSubmit={submitFormHandler}
              editedData={selectedFood}
              toggle1={toggle1}
              auth={props.auth}
            />
          }
          // header={editedData ? "" : "Add Registration Code"}
        />
      </div>
    </PageWrapper>
  );
};

export default compose(enhancer)(FoodTrackingDetails);
