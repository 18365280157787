import React from "react";

import RdProfileForm from "./RdProfileForm";
import RdSettingForm from "./RdSettingForm";

const RdForm = props => {
  return (
    <div>
      {props?.pageView !== "SETTING" ? (
        <RdProfileForm {...props} />
      ) : (
        <RdSettingForm {...props} />
      )}
    </div>
  );
};

export default RdForm;
