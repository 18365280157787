import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import Loader from "../common/Loader";
import Toaster from "../common/Toaster";
import { TABLE } from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import moment from "moment-timezone";
import Button from "components/button/Button";
import CommonModel from "../common/CommonModel";
import { DateRangePicker } from "materialui-daterange-picker";
import ClientServices from "../../api/ClientServices";
import FinancialReportServices from "api/FinancialReportServices";

const ROLE_SERVICE_ID = {
  4: 1,
  5: 2
};

const FinancialReportTable = props => {
  const hasParam = props.match.params.id;
  const toaster = useRef(null);
  const [tblData, settblData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [field, setField] = useState("id");
  const [sortBy, setSortBy] = useState("ASC");
  const [dateRange, setDateRange] = useState({});
  const [modal, setModal] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [RDId, setRDId] = useState(null);
  const [RDList, setRDList] = useState([]);
  useEffect(() => {
    if (props.auth && props.auth.admin_role_id !== 3) {
      const params1 = {
        type: "",
        practice_id:
          props.auth && props.auth?.admin_role_id === 6 && props.auth?.practice
            ? props.auth?.practice
            : undefined,
        id:
          props.auth &&
          (props.auth?.admin_role_id === 4 || props.auth?.admin_role_id === 5)
            ? ROLE_SERVICE_ID[props.auth?.admin_role_id]
            : undefined
      };
      ClientServices.RDList(params1).then(result => {
        let data = EncryptDecrypt.decrypt(result.data);
        setRDList(data.data);
      });
    }
    setRDId(
      props.auth && props.auth.admin_role_id !== 3 ? null : props?.auth?.id
    );
  }, []);

  useEffect(() => {
    getList(field, sortBy, "", activePage + 1, "");
    setPages(10);
  }, [RDId, dateRange.startDate, dateRange.endDate]);

  const toggle = () => {
    setOpen(!open);
    setModal(!open);
  };

  const columns = [
    {
      Header: "Provider ID",
      accessor: "admin.id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      width: 150
    },
    {
      Header: "Provider First Name",
      accessor: "admin.first_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200
    },
    {
      Header: "Provider Last Name",
      accessor: "admin.last_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200
    },
    {
      Header: "CPT Code",
      accessor: "cpt_code",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      width: 200
    },
    {
      Header: "Chart",
      accessor: "chart",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      width: 200
    },
    {
      Header: "Patient First Name",
      accessor: "patient_first_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200
    },
    {
      Header: "Patient Last Name",
      accessor: "patient_last_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200
    },
    {
      Header: "Date of Service",
      accessor: "dos",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200,
      Cell: ({ value }) =>
        value ? <div>{moment(value).format("MM/DD/YYYY")}</div> : <div>-</div>
    },
    {
      Header: "Total Charge",
      accessor: "total_charge",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      width: 200,
      Cell: ({ value }) =>
        value ? <div>${Number(value).toFixed(2)}</div> : <div>-</div>
    },
    {
      Header: "Payments",
      accessor: "payments",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      width: 200,
      Cell: ({ value }) =>
        value ? <div>${Number(value).toFixed(2)}</div> : <div>-</div>
    },
    {
      Header: "Write Offs",
      accessor: "writeOffs",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      width: 200,
      Cell: ({ value }) =>
        value ? <div>${Number(value).toFixed(2)}</div> : <div>-</div>
    }
  ];

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  function getList(field = "name", sortBy = "DESC", search = "", page = "1") {
    const params = {
      sort_by: field,
      order: sortBy,
      search: search,
      page: page,
      start_date: dateRange.startDate
        ? moment(dateRange.startDate).format("YYYY-MM-DD")
        : null,
      end_date: dateRange.endDate
        ? moment(dateRange.endDate).format("YYYY-MM-DD")
        : null,
      uploaded_csv_id: hasParam
    };

    setLoader(true);
    FinancialReportServices.view(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data?.status) {
          settblData(data?.data?.rows);
          setPages(Math.ceil(data?.data?.count / TABLE.LIMIT));
        } else {
          if (toaster.current) toaster.current.error(data?.message);
        }
        setLoader(false);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err?.message);
        setLoader(false);
      });
  }

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Financial Report
            </div>
          </div>
          <div className="roe-card-header module-header">
            <div className="d-flex">
              <div className="mr-10">
                <span className="fs-16">Date</span>
                <br></br>
                <Button
                  className="btn btn-secondary btn-md"
                  onClick={() => toggle()}
                >
                  {dateRange.startDate
                    ? `${moment(dateRange.startDate).format(
                        "MM/DD/YYYY"
                      )} - ${moment(dateRange.endDate).format("MM/DD/YYYY")}`
                    : "Select Date"}
                </Button>
                {dateRange && dateRange.startDate && (
                  <Button
                    className="btn btn-secondary btn-md ml-2"
                    onClick={() => {
                      setActivePage(0);
                      setDateRange({});
                    }}
                  >
                    Reset date
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              LoadingComponent={Loader}
              onFetchData={onChangePageGetData}
              onPageChange={pageIndex => {
                setActivePage(pageIndex);
                getList(field, sortBy, "", pageIndex + 1, "");
              }}
              onSortedChange={sortProperties => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getList(sortProperties[0].id, sort, "", 1, "");
                // alert("sort handler", sortProperties);
                // const orderByDirection = sortProperties[0].desc ? 1 : 0;

                // updateMapStateInfoOrderByColumn(sortProperties[0].id, orderByDirection);
              }}
            />
          </div>
          <CommonModel
            modal={modal}
            toggle={toggle}
            size="lg"
            children={
              <DateRangePicker
                open={open}
                toggle={() => toggle()}
                value={[dateRange.startDate, dateRange.endDate]}
                onChange={range => {
                  setActivePage(0);
                  setDateRange(range);
                  // setModal(!modal)
                  toggle();
                }}
                closeOnClickOutside={true}
              />
            }
            // header={editedData ? "" : "Add Registration Code"}
          />
        </div>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};

export default withRouter(FinancialReportTable);
