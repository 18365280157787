import React, { Fragment, useEffect, useRef, useState } from "react";
import { compose } from "redux";
import Button from "../button/Button";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import Toaster from "../common/Toaster";
import { Input } from "reactstrap";
import {
  MODULE_NAMES,
  PERMISSION_TYPE,
  PROVIDER_TYPE,
  PROVIDER_TYPES
} from "helper/constant";
import CategoryServices from "api/CategoryServices";
import ClientServices from "api/ClientServices";
import enhancer from "./validator";
import FinancialReportServices from "api/FinancialReportServices";
import { hasAccess } from "util/sidebar";

const UploadFinancialReportForm = props => {
  const {
    handleBlur,
    errors,
    setFieldValue,
    touched,
    submitCount,
    onSubmit,
    action,
    handleChange,
    values,
    permissions
  } = props;

  const toaster = useRef();
  const [imageObj, setImageObj] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [serviceList, setServiceList] = useState([]);
  const [rdList, setRdList] = useState([]);
  const [filteredRdList, setFilteredRdList] = useState([]);
  const [isRDListLoading, setIsRDListLoading] = useState(false);
  const [isServiceLoading, setIsServiceLoading] = useState(false);

  useEffect(() => {
    if (props?.admin_role_id === 6) {
      if (!values?.type) setFieldValue("type", "ibo practice");
      if (!!rdList?.length && !!values?.service_id) {
        filterRdOnType("ibo practice");
      }
    }
    if (props?.admin_role_id > 2) {
      setFieldValue("service_id", props?.service_id);
    }
  }, [props?.admin_role_id, rdList, values?.service_id]);

  useEffect(() => {
    if (props?.admin_role_id > 2) rdListChangeHandle(props?.service_id);
  }, [values?.service_id]);

  useEffect(() => {
    setIsServiceLoading(true);
    CategoryServices.serviceDropdown()
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setServiceList([...data.data]);
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
        setIsServiceLoading(false);
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
        setIsServiceLoading(false);
      });
  }, []);

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const handleImage = (e, setValue) => {
    if (e.target.files[0]) {
      let fileObj = e.target.files[0];
      let reader = new FileReader();
      reader.readAsText(fileObj);
      reader.onloadend = () => {
        setImageObj(reader.result);
      };
      setValue("upload_file", fileObj);
    }
  };

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;

    let param = {
      provider_id: values?.rd_id,
      csvData: imageObj
    };
    if (isValid) {
      setIsLoading(true);
      FinancialReportServices.upload(param)
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          if (data.status) {
            if (toaster.current) toaster.current.success(data.message);
            setTimeout(() => {
              onSubmit(values, action);
            }, 1000);
          } else {
            if (toaster.current) toaster.current.error(data.message);
          }
          setIsLoading(false);
        })
        .catch(err => {
          if (toaster.current) toaster.current.error(err?.message);
          setIsLoading(false);
        });
    }
    handleSubmit();
  };

  const rdListChangeHandle = val => {
    setIsRDListLoading(true);
    setRdList([]);
    setFilteredRdList([]);
    const param = {
      id: val,
      practice_id:
        props?.admin_role_id === 6 && props?.practice
          ? props?.practice
          : undefined
    };

    ClientServices.RDList(param)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setRdList(data.data);
          setFilteredRdList([...data?.data]);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
        setIsRDListLoading(false);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
        setIsRDListLoading(false);
      });
  };

  const filterRdOnType = type => {
    const filtered = [...rdList].filter(el => el?.rd_detail?.type === type);
    setFilteredRdList([...filtered]);
  };

  return (
    <div>
      <form>
        <div>
          <div className="form-group">
            <label className="fs-16 medium-text">
              Service<span className="asterisk"> *</span>
            </label>
            <Input
              type="select"
              name="service_id"
              id="service_id"
              onChange={e => {
                setFieldValue("rd_id", "");
                setFieldValue("type", "");
                rdListChangeHandle(e.target.value);
                handleChange(e);
              }}
              value={values?.service_id}
              onBlur={handleBlur}
              disabled={props?.admin_role_id > 2}
            >
              <option value="">
                {isServiceLoading ? "Loading..." : "Select Service"}
              </option>
              {serviceList.map((service, i) => {
                return (
                  <Fragment>
                    <option key={`option${i}`} value={service?.id}>
                      {service.name}
                    </option>
                  </Fragment>
                );
              })}
            </Input>
            <Error field="service_id" />
          </div>

          <div className="form-group">
            <label className="fs-16 medium-text">
              Type<span className="asterisk"> *</span>
            </label>
            <Input
              type="select"
              name=""
              id="type"
              onChange={e => {
                setFieldValue("rd_id", "");
                handleChange(e);
                filterRdOnType(e.target.value);
              }}
              value={values.type}
              onBlur={handleBlur}
              disabled={
                !filteredRdList?.length ||
                isRDListLoading ||
                props?.admin_role_id === 6
              }
            >
              <option value="">Select Type</option>
              {PROVIDER_TYPES.map((type, i) => {
                return (
                  <Fragment>
                    <option key={`option${i}`} value={type.value}>
                      {type.name}
                    </option>
                  </Fragment>
                );
              })}
            </Input>
            <Error field="type" />
          </div>

          <div className="form-group">
            <label className="fs-16 medium-text">
              Provider<span className="asterisk"> *</span>
            </label>
            <Input
              type="select"
              name="rd_id"
              id="rd_id"
              onChange={handleChange}
              value={values?.rd_id}
              onBlur={handleBlur}
              disabled={
                !values?.service_id || !values?.type || !filteredRdList?.length
              }
            >
              <option value="">
                {isRDListLoading ? "Loading..." : "Select Provider"}
              </option>
              {filteredRdList.map((item, i) => {
                return (
                  <option key={`option${i}`} value={item.id}>
                    {item.first_name} {item.last_name} (
                    {item?.rd_detail?.type
                      ? PROVIDER_TYPE[item?.rd_detail?.type]
                      : "-"}
                    )
                  </option>
                );
              })}
            </Input>
            <Error field="rd_id" />
          </div>

          <div className="form-group">
            <label className="fs-16 medium-text">
              {" "}
              Upload CSV File<span className="asterisk"> *</span>
            </label>
            <br />
            <div className="">
              <input
                className=""
                name="upload_file"
                type="file"
                accept=".csv"
                onChange={e => handleImage(e, setFieldValue)}
                onBlur={handleBlur}
              />
            </div>

            <br />
            <Error field="upload_file" />
          </div>
        </div>
        <div>
          {hasAccess(
            MODULE_NAMES.UPLOAD_FINANCIAL,
            PERMISSION_TYPE.ADD,
            permissions
          ) && (
            <Button
              type="button"
              className="c-btn c-info form-button fs-16 demi-bold-text max-w-125"
              onClick={() => handleSubmit()}
              loading={isLoading}
              disabled={isLoading}
              dataStyle={"expand-right"}
            >
              Submit
            </Button>
          )}
        </div>
      </form>
      <Toaster ref={toaster} />
    </div>
  );
};

export default compose(enhancer)(UploadFinancialReportForm);
