import React, { useEffect, useRef } from "react";
import { withRouter } from "react-router";
import { compose } from "redux";

import enhancer from "./editLongTermValidator";
import GoalTracking from "../../api/GoalTrackingService";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import Button from "components/button/Button";
import Toaster from "../common/Toaster";

const GoalLongTerm = props => {
  const {
    handleChange,
    handleBlur,
    submitCount,
    errors,
    values,
    touched
  } = props;

  const toaster = useRef();

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;

    let sentence = values.goal_sentence.replace("I want", "");
    if (sentence != "") {
      const params = {
        user_goal_id: values.goal_id,
        goal_sentence: values.goal_sentence
      };
      GoalTracking.editlongTermGoal(params)
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          if (data.status) {
            if (toaster.current) toaster.current.success(data.message);
          } else {
            if (toaster.current) toaster.current.error(data.message);
          }
          setTimeout(() => {
            props.toggle1();
          }, 1800);
        })
        .catch(err => {
          if (toaster.current) toaster.current.error(err.response);
          setTimeout(() => {
            props.toggle1();
          }, 1800);
        });
    }

    handleSubmit();
  };

  return (
    <div>
      <div className="d-flex">
        <h3 className="w-100">Edit Long Term Goal</h3>
        <div
          className="close-arw float-right cursor-pointer mb-2"
          onClick={props.toggle1}
        >
          <i
            className="fa fa-times-circle-o fa-lg"
            onClick={props.toggle1}
            aria-hidden="true"
          ></i>
        </div>
      </div>
      <div className="mt-3">
        <input
          type="text"
          name="goal_sentence"
          id="goal_sentence"
          className="form-control react-form-input w-100"
          style={{ marginTop: "5px", marginLeft: "5px" }}
          value={values.goal_sentence}
          onChange={handleChange}
          onBlur={handleBlur}
        ></input>
        <Error field="goal_sentence" />
      </div>
      <div>
        <Button
          type="button"
          className="c-btn c-info form-button fs-16 demi-bold-text mr-10"
          style={{ maxWidth: "125px", marginTop: "5px", marginLeft: "5px" }}
          onClick={() => handleSubmit()}
        >
          Submit
        </Button>
        <Button
          type="button"
          className="c-btn c-secondary form-button fs-16 demi-bold-text"
          style={{ maxWidth: "125px" }}
          onClick={props.toggle1}
        >
          Close
        </Button>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};
export default compose(withRouter, enhancer)(GoalLongTerm);
