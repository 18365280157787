import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import PushNotificationServices from "../../api/PushNotificationServices";
import Loader from "../common/Loader";
import Toaster from "../common/Toaster";
import {
  TABLE,
  SYSTEMADMIN,
  MODULE_NAMES,
  PERMISSION_TYPE
} from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
import moment from "moment";
import { hasAccess } from "util/sidebar";

const PushNotificationTable = props => {
  const { permissions } = props;
  const [tblData, settblData] = useState();
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [userTypeText, setUserTypeText] = useState("");
  const [field, setField] = useState("id");
  const [sortBy, setSortBy] = useState("ASC");
  const [id, setId] = useState(undefined);
  const [modal, setModal] = useState(undefined);
  const [status, setStatus] = useState(undefined);
  const [customModal, setCustomModal] = useState(undefined);
  const [parentDropdownOpen, setParentDropdownOpen] = useState(false);

  const toaster = useRef();

  const toggleOne = () => setParentDropdownOpen(prevState => !prevState);

  useEffect(() => {
    getList(field, sortBy, searchText, activePage + 1, userTypeText);
    setPages(10);
  }, []);

  const columns = [
    {
      Header: "ID",
      accessor: "id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 100,
      sortable: true
    },
    {
      Header: "User Type",
      accessor: "user_type",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200,
      Cell: props => (
        <div className="react-action-class">
          {props.original.user_type === "user" ? "Clients" : "Providers"}
        </div>
      ) // Custom cell components!
    },
    {
      Header: "Notification",
      accessor: "notification_text",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 400,
      sortable: true
    },
    {
      Header: "Created Date",
      accessor: "created_at",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 150,
      sortable: true,
      Cell: props => moment(props.original.created_at).format("MM/DD/YYYY")
    }
  ];

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  const formAction = (action, data = null) => {
    // Here you can view the data and make forward action for edit data
    if (action === "add") {
      props.history.push("/push-notification/add");
    }
  };

  function getList(
    field = "created_at",
    sortBy = "DESC",
    search = "",
    page = "1",
    user_type = ""
  ) {
    const params = {
      sort_by: field,
      order: sortBy,
      search: search,
      page: page,
      user_type: user_type
    };
    setLoader(true);
    PushNotificationServices.list(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count / TABLE.LIMIT));
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });
  }

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Push Notification List
            </div>
            <div className="d-flex">
              <div className="mr-10">
                <input
                  value={searchText}
                  onChange={e => {
                    setSearchText(e.target.value);
                    const search = e.target.value;
                    setLoader(true);
                    setActivePage(0);
                    setTimeout(() => {
                      getList(field, sortBy, search, 1, userTypeText);
                    }, 500);
                  }}
                  type="text"
                  placeholder="Search..."
                  className="fs-14 medium-text plr-10 form-control react-form-input"
                />
              </div>
              <div className="fs-14 mr-10">
                <Dropdown isOpen={parentDropdownOpen} toggle={toggleOne}>
                  <DropdownToggle caret size="md">
                    {userTypeText ? userTypeText : "User Type"}
                  </DropdownToggle>
                  <DropdownMenu
                    modifiers={{
                      setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: data => {
                          return {
                            ...data,
                            styles: {
                              ...data.styles,
                              overflow: "auto",
                              maxHeight: "300px"
                            }
                          };
                        }
                      }
                    }}
                  >
                    <DropdownItem
                      key="0"
                      onClick={() => {
                        setUserTypeText("");
                        const userType = "";
                        setLoader(true);
                        setActivePage(0);
                        setTimeout(() => {
                          getList(field, sortBy, searchText, 1, userType);
                        }, 500);
                      }}
                    >
                      All
                    </DropdownItem>
                    <DropdownItem
                      key="User"
                      onClick={() => {
                        setUserTypeText("User");
                        const userType = "user";
                        setLoader(true);
                        setActivePage(0);
                        setTimeout(() => {
                          getList(field, sortBy, searchText, 1, userType);
                        }, 500);
                      }}
                    >
                      Clients
                    </DropdownItem>
                    <DropdownItem
                      key="RD"
                      onClick={() => {
                        setUserTypeText("RD");
                        const userType = "rd";
                        setLoader(true);
                        setActivePage(0);
                        setTimeout(() => {
                          getList(field, sortBy, searchText, 1, userType);
                        }, 500);
                      }}
                    >
                      Providers
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
            <div>
              {hasAccess(
                MODULE_NAMES.PUSH_NOTIFICATION,
                PERMISSION_TYPE.ADD,
                permissions
              ) && (
                <Button
                  className="c-btn c-primary ma-5"
                  onClick={() => formAction("add")}
                >
                  <i className="fas fa-plus mr-10" />
                  Add Push Notification
                </Button>
              )}
            </div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              LoadingComponent={Loader}
              onFetchData={onChangePageGetData}
              onPageChange={pageIndex => {
                setActivePage(pageIndex);
                getList(field, sortBy, searchText, pageIndex + 1, userTypeText);
              }}
              onSortedChange={sortProperties => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getList(
                  sortProperties[0].id,
                  sort,
                  searchText,
                  1,
                  userTypeText
                );
                // alert("sort handler", sortProperties);
                // const orderByDirection = sortProperties[0].desc ? 1 : 0;

                // updateMapStateInfoOrderByColumn(sortProperties[0].id, orderByDirection);
              }}
            />
          </div>
        </div>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};

export default withRouter(PushNotificationTable);
