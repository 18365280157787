export const sidebarData = [
  {
    name: "sidebar.dashboard",
    iconClass: "fas fa-chalkboard",
    routepath: "/dashboard"
    // subAdmin: true,
    // isRD: true,
  },
  {
    name: "sidebar.admin",
    iconClass: "fas fa-users",
    routepath: "/sub-admin"
    // subAdmin: true,
    // isRD: true,
  },
  {
    name: "sidebar.roleAndPermission",
    iconClass: "fa fa-clone",
    routepath: "/roles-and-permissions"
    // subAdmin: true,
  },
  {
    name: "sidebar.formManagement",
    iconClass: "fa fa-clone",
    routepath: "/form"
    // subAdmin: true,
  },
  {
    name: "sidebar.service_management",
    iconClass: "fa fa-clone",
    routepath: "/service"
    // subAdmin: true,
  },
  {
    name: "sidebar.tier",
    iconClass: "fa fa-clone",
    routepath: "/tier"
    // subAdmin: true,
  },
  {
    name: "sidebar.staticpage",
    iconClass: "fa fa-clone",
    routepath: "/static-page"
    // subAdmin: true,
  },
  {
    name: "sidebar.state",
    iconClass: "fa fa-clone",
    routepath: "/state"
    // subAdmin: true,
  },
  {
    name: "sidebar.fax",
    iconClass: "fa fa-clone",
    routepath: "/fax"
    // subAdmin: true,
  },
  {
    name: "sidebar.efax",
    iconClass: "fa fa-clone",
    routepath: "/efax"
    // subAdmin: true,
  },
  {
    name: "sidebar.category",
    iconClass: "fa fa-clone",
    routepath: "/category"
    // subAdmin: true,
  },
  {
    name: "sidebar.insurance",
    iconClass: "fa fa-clone",
    routepath: "/insurance"
    // subAdmin: true,
  },
  {
    name: "sidebar.group",
    iconClass: "fa fa-clone",
    routepath: "/group"
    // subAdmin: true,
    // isRD: true,
  },
  {
    name: "sidebar.client_management",
    iconClass: "fa fa-clone",
    routepath: "/client"
    // isRD: true,
    // subAdmin: true,
  },
  // {
  //   name: "sidebar.clientdetails",
  //   iconClass: "fa fa-clone",
  //   routepath: "/client-details",
  //   isRD: true
  // },
  {
    name: "sidebar.provider",
    iconClass: "fa fa-clone",
    routepath: "/rd"
    // subAdmin: true,
  },
  {
    name: "sidebar.external_provider",
    iconClass: "fa fa-clone",
    routepath: "/external-provider"
    // subAdmin: true,
    // isRD: true,
  },
  {
    name: "sidebar.rewards",
    iconClass: "fa fa-clone",
    routepath: "/rewards"
    // subAdmin: true,
  },
  {
    name: "sidebar.message",
    iconClass: "fa fa-clone",
    routepath: "/message"
    // isRD: true,
    // subAdmin: true,
  },
  {
    name: "sidebar.appointment_management",
    iconClass: "fa fa-clone",
    routepath: "/appointment"
    // isRD: true,
    // subAdmin: true,
  },
  {
    name: "sidebar.pendingBill",
    iconClass: "fa fa-clone",
    routepath: "/pending-billing"
    // isRD: true,
    // subAdmin: true,
  },
  {
    name: "sidebar.visit_program",
    iconClass: "fa fa-clone",
    routepath: "/visit-program"
    // isRD: false,
    // subAdmin: true,
  },
  {
    name: "sidebar.scale_program",
    iconClass: "fa fa-clone",
    routepath: "/scale-program"
    // isRD: false,
    // subAdmin: true,
  },
  {
    name: "sidebar.diagnoses_code",
    iconClass: "fa fa-clone",
    routepath: "/diagnoses-code"
    // isRD: false,
    // subAdmin: true,
  },
  {
    name: "sidebar.cpt_code",
    iconClass: "fa fa-clone",
    routepath: "/cpt-code"
    // isRD: false,
    // subAdmin: true,
  },
  {
    name: "sidebar.service_location",
    iconClass: "fa fa-clone",
    routepath: "/service-location"
    // isRD: false,
    // subAdmin: true,
  },
  {
    name: "sidebar.content_library",
    iconClass: "fa fa-clone",
    routepath: "/content-library"
    // subAdmin: true,
    // isRD: true,
  },
  {
    name: "sidebar.push_notification",
    iconClass: "fa fa-clone",
    routepath: "/push-notification"
    // isRD: false,
    // subAdmin: true,
  },
  {
    name: "sidebar.report",
    iconClass: "fa fa-clone",
    // isRD: true,
    // subAdmin: true,
    child: [
      {
        listname: "sidebar.visit_details_report",
        routepath: "/visit-details-report"
        // isRD: true,
      },
      {
        listname: "sidebar.bill_transaction_report",
        routepath: "/bill-transaction-report"
        // isRD: true,
      },
      {
        listname: "sidebar.client_details_report",
        routepath: "/client-details-report"
        // isRD: true,
      },
      {
        listname: "sidebar.unbill_insurance_client_report",
        routepath: "/unbillable-client-report"
      },
      {
        listname: "sidebar.provider_unit_to_goal_report",
        routepath: "/rd-unit-goal-report"
        // isRD: true,
      },
      {
        listname: "sidebar.scale_redemption_report",
        routepath: "/scale-redemption-report"
      },
      {
        listname: "sidebar.activation_report_nutrition",
        routepath: "/activation-report-nutrition"
      },
      {
        listname: "sidebar.activation_report_mentalHealth",
        routepath: "/activation-report-mentalHealth"
      },
      {
        listname: "sidebar.provider_insurance_report",
        routepath: "/rd-insurance-report"
      },
      {
        listname: "sidebar.provider_state_report",
        routepath: "/rd-state-report"
      },
      {
        listname: "sidebar.insurance_bill_report",
        routepath: "/insurance-bill-report"
      },
      {
        listname: "sidebar.meal_delivery_report",
        routepath: "/meal-delivery-report"
      },
      {
        listname: "sidebar.daily_billing_failures_report",
        routepath: "/daily-billing-failure-report"
      },
      {
        listname: "sidebar.provider_caqh_report",
        routepath: "/rd-caqh-report"
      },
      {
        listname: "sidebar.liability_insurance_report",
        routepath: "/rd-liability-insurance-report"
      },
      {
        listname: "sidebar.provider_cdr_report",
        routepath: "/rd-cdr-report"
      },
      {
        listname: "sidebar.provider_retention_report",
        routepath: "/rd-retention-report"
      },
      {
        listname: "sidebar.financial_report",
        routepath: "/financial-report"
        // isRD: true,
      },
      {
        listname: "sidebar.ibo_practice_report",
        routepath: "/ibo-practice-report"
      },
      {
        listname: "sidebar.outstanding_claim_report",
        routepath: "/outstanding-claim-report"
      },
      {
        listname: "sidebar.late_cancel_no_show_report",
        routepath: "/late-cancel-no-show-report"
      }
    ]
  },
  {
    name: "sidebar.notification",
    iconClass: "fa fa-clone",
    routepath: "/activity"
    // isRD: true,
    // subAdmin: false,
  },
  {
    name: "sidebar.import_user",
    iconClass: "fa fa-clone",
    routepath: "/import-user"
    // isRD: false,
    // subAdmin: true,
  },
  {
    name: "sidebar.discovery_queue",
    iconClass: "fa fa-clone",
    routepath: "/discovery-queue"
    // isRD: false,
    // subAdmin: true,
  },
  {
    name: "sidebar.upload_financial_report",
    iconClass: "fa fa-clone",
    routepath: "/upload-financial-report"
    // isRD: false,
    // subAdmin: true,
  },
  {
    name: "sidebar.practice",
    iconClass: "fa fa-clone",
    routepath: "/practice"
    // isRD: false,
    // subAdmin: true,
  }
];

export const HorizontalSidebarData = [
  {
    name: "sidebar.intro",
    routepath: "/Intro",
    iconClass: "fas fa-chalkboard"
  },
  {
    name: "sidebar.users",
    iconClass: "far fa-user",
    child: [
      {
        name: "sidebar.lists",
        routepath: "/users"
      }
    ]
  }
];
