import React, { useState, useEffect } from "react";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import GroupServices from "../../api/GroupServices";
import moment from "moment";
import Loader from "components/common/Loader";
import { hasAccess } from "util/sidebar";
import { MODULE_NAMES, PERMISSION_TYPE } from "helper/constant";

const GroupBasicViewDetails = props => {
  const { permissions } = props;
  const [editedData, setEditedData] = useState(null);
  const [loader, setLoader] = useState(true);
  const hasParam = props.match.params.id;

  useEffect(() => {
    if (hasAccess(MODULE_NAMES.GROUP, PERMISSION_TYPE.VIEW, permissions)) {
      if (hasParam) {
        const params = {
          id: hasParam
        };
        GroupServices.view(params)
          .then(res => {
            let data = EncryptDecrypt.decrypt(res.data);
            setEditedData(data.data);
            setLoader(false);
          })
          .catch(err => {
            setLoader(false);
          });
      }
    }
  }, []);

  return (
    <div>
      {loader ? (
        <Loader loading={loader} />
      ) : (
        <table style={{ width: "100%", lineHeight: "35px" }}>
          <tbody>
            <tr>
              <td
                style={{
                  fontWeight: "bolder",
                  textAlign: "left",
                  width: "20%"
                }}
              >
                ID
              </td>
              <td>{editedData && editedData.id}</td>
            </tr>
            <tr>
              <td
                style={{
                  fontWeight: "bolder",
                  textAlign: "left",
                  width: "20%"
                }}
              >
                Name
              </td>
              <td>{editedData && editedData.name}</td>
            </tr>
            <tr>
              <td
                style={{
                  fontWeight: "bolder",
                  textAlign: "left",
                  width: "20%"
                }}
              >
                Service
              </td>
              <td>{editedData && editedData?.service?.name}</td>
            </tr>
            <tr>
              <td
                style={{
                  fontWeight: "bolder",
                  textAlign: "left",
                  width: "20%"
                }}
              >
                Type
              </td>
              <td>{editedData && editedData.type}</td>
            </tr>
            <tr>
              <td
                style={{
                  fontWeight: "bolder",
                  textAlign: "left",
                  width: "20%"
                }}
              >
                Payment Method
              </td>
              <td>{editedData && editedData.payment_method}</td>
            </tr>
            <tr>
              <td
                style={{
                  fontWeight: "bolder",
                  textAlign: "left",
                  width: "20%"
                }}
              >
                Launch Date
              </td>
              <td>
                {editedData &&
                  moment(editedData.launch_date).format("MM/DD/YYYY")}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontWeight: "bolder",
                  textAlign: "left",
                  width: "20%"
                }}
              >
                Auto Renew
              </td>
              <td>
                {editedData
                  ? editedData?.auto_renew
                    ? "Enable"
                    : "Disable"
                  : ""}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontWeight: "bolder",
                  textAlign: "left",
                  width: "20%"
                }}
              >
                Universal Login
              </td>
              <td>
                {editedData
                  ? editedData?.universal_login
                    ? "Enable"
                    : "Disable"
                  : ""}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontWeight: "bolder",
                  textAlign: "left",
                  width: "20%"
                }}
              >
                Status
              </td>
              <td>
                {editedData
                  ? editedData?.is_active
                    ? "Active"
                    : "Inactive"
                  : ""}
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};

export default GroupBasicViewDetails;
