import { withFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    goal: Yup.number().required("This field is required")
  }),
  mapPropsToValues: props => ({
    id:
      props && props.editedData && props.editedData.id
        ? props.editedData.id
        : "",
    month:
      props && props.editedData && props.editedData.month_year
        ? parseInt(moment(props.editedData.month_year).format("MM"))
        : parseInt(moment().format("MM")),
    year:
      props && props.editedData && props.editedData.month_year
        ? parseInt(moment(props.editedData.month_year).format("YYYY"))
        : parseInt(moment().format("YYYY")),
    goal:
      props && props.editedData && props.editedData.goal
        ? props.editedData.goal
        : ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
