import React, { useRef } from "react";
import { compose } from "redux";
import enhancer from "../group/benefit-period-validator";
import Button from "../button/Button";
import Toaster from "../common/Toaster";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import GroupServices from "../../api/GroupServices";
import DatePicker from "react-datepicker";
import moment from "moment";

const BenefitPeriodModal = props => {
  const {
    errors,
    touched,
    submitCount,
    setFieldValue,
    onSubmit,
    values,
    action
  } = props;

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const toaster = useRef();

  const hasParam = props.match.params.id;

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;

    if (isValid) {
      onSubmit(values, action);
      if (action === "add") {
        const params = {
          group_id: hasParam,
          start_date: moment(values.start_date).format("YYYY-MM-DD"),
          end_date: moment(values.end_date).format("YYYY-MM-DD")
        };
        GroupServices.benefitPeriodAdd(params)
          .then(res => {
            let data = EncryptDecrypt.decrypt(res.data);
            if (data.status) {
              if (toaster.current) toaster.current.success(data.message);
            } else {
              if (toaster.current) toaster.current.error(data.message);
            }
          })
          .catch(err => {
            if (toaster.current) toaster.current.error(err.response);
          });
      } else if (action === "edit") {
        const params = {
          id: values.id,
          start_date: moment(values.start_date).format("YYYY-MM-DD"),
          end_date: moment(values.end_date).format("YYYY-MM-DD")
        };
        GroupServices.benefitPeriodEdit(params)
          .then(res => {
            let data = EncryptDecrypt.decrypt(res.data);
            if (data.status) {
              if (toaster.current) toaster.current.success(data.message);
            } else {
              if (toaster.current) toaster.current.error(data.message);
            }
          })
          .catch(err => {
            if (toaster.current) toaster.current.error(err.response);
          });
      }
    }

    handleSubmit();
  };

  return (
    <form>
      <div className="form-group">
        <label className="fs-16 medium-text">
          Start Date <span className="asterisk">*</span>
        </label>
        <br />
        <DatePicker
          className="form-control"
          selected={values.start_date}
          id="start_date"
          onChange={e => {
            setFieldValue("start_date", e);
          }}
          dateFormat="MM/dd/yyyy"
          placeholder="Add Start Date"
        />
        <br />
        <Error field="start_date" />
      </div>
      <div className="form-group">
        <label className="fs-16 medium-text">
          End Date <span className="asterisk">*</span>
        </label>
        <br />
        <DatePicker
          className="form-control"
          selected={values.end_date}
          id="end_date"
          onChange={e => {
            setFieldValue("end_date", e);
          }}
          dateFormat="MM/dd/yyyy"
          placeholder="Add End Date"
        />
        <br />
        <Error field="end_date" />
      </div>
      <div>
        <Button
          type="button"
          className="c-btn c-info form-button fs-16 demi-bold-text mr-10"
          style={{ maxWidth: "125px" }}
          onClick={() => handleSubmit()}
        >
          Submit
        </Button>
        <Button
          type="button"
          className="c-btn c-secondary form-button fs-16 demi-bold-text"
          style={{ maxWidth: "125px" }}
          onClick={props.toggle}
        >
          Close
        </Button>
      </div>
      <Toaster ref={toaster} />
    </form>
  );
};

export default compose(enhancer)(BenefitPeriodModal);
