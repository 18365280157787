import React, { useEffect, useState } from "react";
import UserWrapper from "./PushNotification.style";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";
import PushNotificationForm from "../../components/pushNotification/PushNotificationForm";

const PushNotificationEdit = props => {
  const submitFormHandler = (data, action) => {
    setTimeout(() => {
      props.history.push("/push-notification");
    }, 2000);
  };

  return (
    <UserWrapper {...props}>
      <div className="pos-relative">
        <PageTitle
          props={props}
          title="sidebar.push_notification"
          className="plr-15"
          breadCrumb={[
            {
              name: "sidebar.dashboard",
              url: "/dashboard"
            },
            {
              name: "sidebar.push_notification",
              url: "/push-notification"
            },
            {
              name: "action.add"
            }
          ]}
        />
        <div
          className="back-icon fs-15 demi-bold-text cursor-pointer"
          onClick={() => props.history.push("/push-notification")}
        >
          <i className="fas fa-step-backward"></i> Back
        </div>
      </div>

      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Add Push Notification
            </div>
          </div>
          <div className="roe-card-body">
            <PushNotificationForm onSubmit={submitFormHandler} action={"add"} />
          </div>
        </div>
      </div>
    </UserWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(PushNotificationEdit);
