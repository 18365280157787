import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
import { compose } from "redux";

import enhancer from "./longTermValidator";
import GoalTracking from "../../api/GoalTrackingService";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import Button from "components/button/Button";
import Toaster from "../common/Toaster";

const GoalLongTerm = props => {
  const hasParam = props.match.params.id;
  const {
    handleChange,
    handleBlur,
    setFieldTouched,
    setFieldValue,
    submitCount,
    errors,
    values,
    touched,
    isValid,
    onSubmit
  } = props;

  useEffect(() => {
    values.goal_sentence = "I want ";
  }, []);

  const toaster = useRef();

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;

    let sentence = values.goal_sentence.replace("I want", "");
    if (sentence != "") {
      const params = {
        user_id: hasParam,
        goal_category_id: values.goal_category_id,
        goal_sentence: values.goal_sentence
      };
      GoalTracking.longTermsAnswer(params)
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          if (data.status) {
            if (toaster.current) toaster.current.success(data.message);
          } else {
            if (toaster.current) toaster.current.error(data.message);
          }
          setTimeout(() => {
            props.toggle1();
          }, 1800);
        })
        .catch(err => {
          if (err?.response?.status === 403) {
            if (toaster.current) {
              toaster.current.error("You cannot access this user info!");
              setTimeout(() => {
                props.history.push("/client");
              }, 3000);
            }
          } else {
            if (toaster.current) toaster.current.error(err?.message);
          }
          setTimeout(() => {
            props.toggle1();
          }, 1800);
        });
    }

    handleSubmit();
  };

  return (
    <div>
      <h3>Add Long Term Goal</h3>
      <div className="mt-3">
        <input
          type="text"
          name="goal_sentence"
          id="goal_sentence"
          className="form-control react-form-input w-100"
          style={{ marginTop: "5px", marginLeft: "5px" }}
          value={values.goal_sentence}
          onChange={handleChange}
          onBlur={handleBlur}
        ></input>
        <Error field="goal_sentence" />
      </div>
      <div>
        <Button
          type="button"
          className="c-btn c-info form-button fs-16 demi-bold-text mr-10"
          style={{ maxWidth: "125px", marginTop: "5px", marginLeft: "5px" }}
          onClick={() => handleSubmit()}
        >
          Submit
        </Button>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};
export default compose(withRouter, enhancer)(GoalLongTerm);
