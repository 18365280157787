import React from "react";
import { compose } from "redux";
import enhancer from "./add-referral-validator";
import Button from "../button/Button";

const ViewNoteModal = props => {
  return (
    <form>
      <div className="form-group">
        <div>
          <p>{props.referralNote}</p>
        </div>
      </div>

      <div className="flex-x center">
        <Button
          type="button"
          className="c-btn c-secondary form-button fs-16 demi-bold-text"
          style={{ maxWidth: "125px" }}
          onClick={props.toggle}
        >
          Close
        </Button>
      </div>
    </form>
  );
};

export default compose(enhancer)(ViewNoteModal);
