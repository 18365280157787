import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    first_name: Yup.string().required("This field is required"),
    last_name: Yup.string().required("This field is required"),
    image: Yup.string()
      .required("This field is required")
      .nullable(),
    profile_text: Yup.string().required("This field is required"),
    email: Yup.string().notRequired(),
    max_client: Yup.string().notRequired(),
    primary_category: Yup.string().notRequired(),
    calendly_link: Yup.string().notRequired(),
    zip: Yup.string()
      .matches(/^[0-9]+$/i, "Must Contain number only.")
      .min(5, "Must be exactly 5 digits")
      .max(5, "Must be exactly 5 digits"),
    phone_number: Yup.string()
      .min(14, "Must be exactly 10 digits")
      .max(14, "Must be exactly 10 digits"),
    timezone_id: Yup.string()
      .trim()
      .required("This field is required"),
    language_id: Yup.string()
      .trim()
      .required("This field is required")
  }),
  mapPropsToValues: props => {
    return {
      first_name: props.editedData ? props.editedData.first_name : "",
      last_name: props.editedData ? props.editedData.last_name : "",
      max_client:
        props.editedData && props.editedData.rd_detail
          ? props.editedData.rd_detail.max_client
          : 0,
      image: props.editedData ? props.editedData.profile_image : "",
      profile_text:
        props.editedData && props.editedData.rd_detail
          ? props.editedData.rd_detail.profile_text
          : "",
      email: props.editedData ? props.editedData.email : "",
      // primary_category:
      //   props.editedData && props.editedData.rd_detail
      //     ? props.editedData.rd_detail.primary_category.id
      //     : "",
      // secondary_category:
      //   props.editedData && props.editedData.rd_detail
      //     ? props.editedData.rd_detail.secondary_category.id
      //     : "",
      calendy_link:
        props.editedData && props.editedData.rd_detail
          ? props.editedData.rd_detail.calendly_link
          : "",
      advancemd_provider_id:
        props &&
        props.editedData &&
        props.editedData.rd_detail &&
        props.editedData.rd_detail.advancemd_provider_id
          ? props.editedData.rd_detail.advancemd_provider_id
          : "",
      provider_npi:
        props &&
        props.editedData &&
        props.editedData.rd_detail &&
        props.editedData.rd_detail.provider_npi
          ? props.editedData.rd_detail.provider_npi
          : "",
      phone_number:
        props &&
        props.editedData &&
        props.editedData.rd_detail &&
        props.editedData.rd_detail.phone_number
          ? props.editedData.rd_detail.phone_number
              .toString()
              .replace(/\D+/g, "")
              .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
          : "",
      address:
        props &&
        props.editedData &&
        props.editedData.rd_detail &&
        props.editedData.rd_detail.address
          ? props.editedData.rd_detail.address
          : "",
      city:
        props &&
        props.editedData &&
        props.editedData.rd_detail &&
        props.editedData.rd_detail.city
          ? props.editedData.rd_detail.city
          : "",
      state_id:
        props &&
        props.editedData &&
        props.editedData.rd_detail &&
        props.editedData.rd_detail.state_id
          ? props.editedData.rd_detail.state_id
          : "",
      zip:
        props &&
        props.editedData &&
        props.editedData.rd_detail &&
        props.editedData.rd_detail.zip
          ? props.editedData.rd_detail.zip
          : "",
      language_id:
        props && props.editedData && props.editedData.language_id
          ? props.editedData.language_id
          : [],
      timezone_id:
        props &&
        props.editedData &&
        props.editedData.rd_detail &&
        props.editedData.rd_detail.timezone_id
          ? props.editedData.rd_detail.timezone_id
          : ""
    };
  },
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
