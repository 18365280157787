import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import Loader from "../common/Loader";
import Toaster from "../common/Toaster";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import CommonModel from "components/common/CommonModel";
import { MODULE_NAMES, PERMISSION_TYPE, TABLE } from "helper/constant";
import RewardsManagementServices from "api/RewardsMangementServices";
import EditTierModal from "./EditTierModal";
import { hasAccess } from "util/sidebar";

const RewardsTierTable = props => {
  const { permissions } = props;
  const toaster = useRef();
  const [tblData, settblData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [editTierModal, setEditTierModal] = useState(false);
  const [editedData, setEditedData] = useState({});

  useEffect(() => {
    getList();
    setPages(10);
  }, [props]);

  function editTierToggle() {
    setEditTierModal(!editTierModal);
  }

  const columns = [
    {
      Header: "ID",
      accessor: "id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 70,
      sortable: true
    },
    {
      Header: "Tier",
      accessor: "name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 70,
      sortable: true
    },
    {
      Header: "Min Points",
      accessor: "min_point",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 100,
      sortable: true
    },
    {
      Header: "Max Points",
      accessor: "max_point",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 100,
      sortable: true
    },
    {
      Header: "To Display",
      accessor: "is_display",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 100,
      sortable: true,
      Cell: props => <div>{props.original?.is_display ? "YES" : "NO"}</div>
    }
  ];

  const handleSubmit = () => {
    getList();
    setPages(10);
  };

  function getList() {
    setLoader(true);
    RewardsManagementServices.tierList()
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count / TABLE.LIMIT));
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });
  }

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Tier List
            </div>
            {tblData?.length && (
              <div>
                {hasAccess(
                  MODULE_NAMES.REWARDS,
                  PERMISSION_TYPE.EDIT,
                  permissions
                ) ? (
                  <Button
                    className="c-btn c-primary ma-5 "
                    onClick={() => {
                      setEditTierModal(!editTierModal);
                      setEditedData([...tblData]);
                    }}
                  >
                    <i className="fas fa-edit mr-2" />
                    Edit Tier
                  </Button>
                ) : null}
              </div>
            )}
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              minRows={2}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              showPaginationBottom={false}
              loading={loader}
              LoadingComponent={Loader}
            />
          </div>
        </div>
      </div>
      <CommonModel
        modal={editTierModal}
        toggle={editTierToggle}
        onSubmit={handleSubmit}
        size="lg"
        children={
          <EditTierModal
            onSubmit={handleSubmit}
            editedData={editedData}
            toggle={editTierToggle}
            pageRefresh={() => {
              getList();
            }}
            {...props}
          />
        }
        header={"Edit Tier"}
      />
      <Toaster ref={toaster} />
    </div>
  );
};

export default withRouter(RewardsTierTable);
