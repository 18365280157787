import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import RdServices from "../../api/RdServices";
import Loader from "../common/Loader";
import CommonModel from "../common/CommonModel";
import Toaster from "../common/Toaster";
import { CALENDLY_TYPE, TABLE } from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import DeleteModal from "../common/DeleteModal";
import { getCalendlyStatusAndTime } from "util/calendly";
import CommonServices from "api/CommonServices";
import CalendlyModal from "./CalendlyModal";

const CalendlyTab = props => {
  const [tblData, settblData] = useState();
  const [loader, setLoader] = useState(false);
  const [statusLoader, setStatusLoader] = useState(true);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("id");
  const [sortBy, setSortBy] = useState("ASC");
  const [id, setId] = useState(undefined);
  const [modal, setModal] = useState(undefined);
  const [modal1, setModal1] = useState(undefined);
  const [editedData, setEditedData] = useState(undefined);
  const [providerData, setProviderData] = useState(null);
  const [refreshData, setRefreshData] = useState(0);
  const [isReconnecting, setIsReconnectiong] = useState(false);

  const toaster = useRef();

  const hasParam = props.match.params.id;

  useEffect(() => {
    if (hasParam) {
      const params = {
        id: hasParam
      };
      setStatusLoader(true);
      RdServices.view(params)
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          setProviderData(data.data);
          setStatusLoader(false);
        })
        .catch(err => {
          setStatusLoader(false);
        });
    }
  }, [refreshData]);

  function toggle() {
    setModal(false);
  }

  function toggle1() {
    setModal1(false);
  }

  const reconnectRDCalendly = async info => {
    setIsReconnectiong(true);
    const isAdminAllowedReconnect = getCalendlyStatusAndTime(info)
      .adminReconnect;
    if (!isAdminAllowedReconnect) {
      if (toaster.current)
        toaster.current.info(
          "Provider needs to reconnect Calendly to allow access permissions."
        );
    }
    if (isAdminAllowedReconnect) {
      const params = {
        rd_id: hasParam
      };
      try {
        const res = await CommonServices.reconnectRDCalendly(params);
        let data = EncryptDecrypt.decrypt(res.data);
        setRefreshData(prev => prev + 1);
        if (data.status) {
          if (toaster.current) toaster.current.success(data.message);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
        setIsReconnectiong(false);
      } catch (err) {
        setIsReconnectiong(false);
        if (toaster.current) toaster.current.error(err.message);
      }
    }

    setIsReconnectiong(false);
  };

  const formAction = (action, data = null) => {
    if (action === "edit") {
      setId(data.id);
      setEditedData(data);
      setModal(true);
    } else if (action === "add") {
      setId(data);
      setModal(true);
      setEditedData(null);
    }
  };

  useEffect(() => {
    getList(field, sortBy, searchText, activePage + 1);
    setPages(10);
  }, []);

  const submitFormHandler = () => {
    setTimeout(() => {
      setModal(false);
      getList(field, sortBy, searchText, activePage + 1);
    }, 2000);
  };

  const deleteClick = data => {
    setId(data.id);
    setModal1(true);
  };

  function deleteHandler() {
    setLoader(true);
    const params = {
      rd_calendly_url_id: id
    };
    RdServices.calendlyURLDelete(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current) toaster.current.success(data.message);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
        setLoader(false);
        setModal1(false);
        setTimeout(() => {
          getList(field, sortBy, searchText, activePage + 1);
        }, 1000);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });
  }

  const columns = [
    {
      Header: "ID",
      accessor: "id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 100,
      sortable: true
    },
    {
      Header: "Type",
      accessor: "type",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 150,
      sortable: true,
      Cell: ({ value }) => <div>{value ? CALENDLY_TYPE[value] : "-"}</div>
    },
    {
      Header: "URL",
      accessor: "url",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 500,
      sortable: true
    },
    {
      Header: "Event Name",
      accessor: "event_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 420,
      sortable: true,
      Cell: ({ value }) => <div>{value ? value : "N/A"}</div>
    },
    {
      Header: "Action",
      accessor: "action",
      className: "",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 150,
      Cell: ({ original }) => (
        <div className="react-action-class">
          <Button
            className="c-btn c-success mr-10"
            onClick={() => formAction("edit", original)}
          >
            <div className="fs-14 medium-text">
              <i className="fas fa-edit" />
            </div>
          </Button>
          {!original?.is_default && (
            <Button
              className="c-btn c-danger"
              onClick={() => deleteClick(original)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-trash" />
              </div>
            </Button>
          )}
        </div>
      ) // Custom cell components!
    }
  ];

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  function getList(
    field = "created_at",
    sortBy = "DESC",
    search = "",
    page = "1"
  ) {
    const params = {
      sort_by: field,
      order: sortBy,
      search: search,
      page: page,
      rd_id: hasParam
    };
    setLoader(true);
    RdServices.listCalendlyUrl(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count / TABLE.LIMIT));
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });
  }

  return (
    <div>
      {!statusLoader && providerData && (
        <div
          className="flex border-radius-8 border-dark"
          style={{ width: "40%", marginBlock: 6, padding: 20 }}
        >
          <div className="flex flex-column fill-width">
            <div className="flex align-items-center">
              <div className="font-bold">Calendly Status :</div>
              <div className="flex align-items-center">
                <div
                  style={{
                    height: 16,
                    width: 16,
                    backgroundColor:
                      getCalendlyStatusAndTime(providerData?.rd_calendly_status)
                        .status === "Not Connected"
                        ? "#df5151"
                        : "#AECE38",
                    borderRadius: "50%",
                    marginInline: 4
                  }}
                />
                {
                  getCalendlyStatusAndTime(providerData?.rd_calendly_status)
                    .status
                }
              </div>
            </div>
            <div className="mtb-6">
              Webhook{" "}
              {getCalendlyStatusAndTime(providerData?.rd_calendly_status)
                .status === "Not Connected"
                ? "disabled"
                : "enabled"}{" "}
              since{" "}
              {getCalendlyStatusAndTime(providerData?.rd_calendly_status).time}
            </div>
            <div className="fill-width">
              <Button
                className="c-btn c-primary fill-width mtb-6"
                disabled={
                  getCalendlyStatusAndTime(providerData?.rd_calendly_status)
                    .status !== "Not Connected" || isReconnecting
                }
                onClick={() =>
                  reconnectRDCalendly(providerData?.rd_calendly_status)
                }
              >
                <div className="font-bold pa-4">
                  {isReconnecting ? "Reconnecting..." : "Reconnect"}
                </div>
              </Button>
            </div>
          </div>
        </div>
      )}

      {statusLoader && (
        <div
          className="fs-16 medium-text form-group"
          style={{ paddingBlock: 6 }}
        >
          Loading Calendly Status....
        </div>
      )}

      {!statusLoader && !providerData && (
        <div
          className="fs-16 medium-text form-group"
          style={{ paddingBlock: 6 }}
        >
          Something went wrong while fetching Calendly Staus!{" "}
          <i
            className="fas fa-redo-alt cursor-pointer"
            onClick={() => setRefreshData(prev => prev + 1)}
          />
        </div>
      )}

      <div className="module-header">
        <div className="flex-1 fs-16 demi-bold-text"></div>
        <div>
          <Button
            className="c-btn c-primary ma-5"
            onClick={() => formAction("add")}
          >
            <i className="fas fa-plus mr-10" />
            Add New In Person Link
          </Button>
        </div>
      </div>
      <ReactTable
        style={{
          border: "none",
          boxShadow: "none"
        }}
        data={tblData}
        columns={columns}
        defaultPageSize={10}
        minRows={2}
        manual
        defaultFilterMethod={(filter, row) => {
          const id = filter.pivotId || filter.id;

          return row[id] !== undefined
            ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
            : true;
        }}
        className="-striped -highlight custom-react-table-theme-class"
        pages={pages}
        page={activePage}
        PaginationComponent={Pagination}
        loading={loader}
        LoadingComponent={Loader}
        onFetchData={onChangePageGetData}
        onPageChange={pageIndex => {
          setActivePage(pageIndex);
          getList(field, sortBy, searchText, pageIndex + 1);
        }}
        onSortedChange={sortProperties => {
          const sort = sortProperties[0].desc ? "DESC" : "ASC";
          setSortBy(sort);
          setActivePage(0);
          setField(sortProperties[0].id);
          getList(sortProperties[0].id, sort, searchText, 1);
        }}
      />
      <Toaster ref={toaster} />
      <CommonModel
        modal={modal}
        toggle={toggle}
        size="xl"
        children={
          <CalendlyModal
            onSubmit={submitFormHandler}
            editedData={editedData}
            action={editedData ? "edit" : "add"}
            toggle={toggle}
            {...props}
            pageRefresh={() => {
              getList(field, sortBy, searchText, activePage + 1);
              setPages(10);
            }}
          />
        }
        header={
          editedData && editedData?.type === "main_url"
            ? "Edit Main URL"
            : `${
                editedData
                  ? "Edit Calendly Link"
                  : "Add New In Person Calendly Link"
              }`
        }
      />
      <CommonModel
        modal={modal1}
        toggle1={toggle1}
        children={
          <DeleteModal
            text={"Are you sure you want to delete this liability insurance?"}
            deleteHandler={deleteHandler}
            toggle={toggle1}
          />
        }
      />
    </div>
  );
};
export default withRouter(CalendlyTab);
