import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import RdServices from "../../api/RdServices";
import Loader from "../common/Loader";
import CommonModel from "../common/CommonModel";
import PerformanceModal from "../rd/PerformanceModal";
import Toaster from "../common/Toaster";
import { MODULE_NAMES, PERMISSION_TYPE, TABLE } from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
import moment from "moment";
import { hasAccess } from "util/sidebar";

const PerformanceTab = props => {
  const { permissions } = props;
  const [tblData, settblData] = useState();
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("created_at");
  const [sortBy, setSortBy] = useState("DESC");
  const [id, setId] = useState(undefined);
  const [modal, setModal] = useState(undefined);
  const [editedData, setEditedData] = useState(undefined);
  const [parentDropdownOpen, setParentDropdownOpen] = useState(false);
  const [summaryData, setSummaryData] = useState("");
  const [year, setYear] = useState(moment().format("YYYY"));

  const toaster = useRef();
  const toggleOne = () => setParentDropdownOpen(prevState => !prevState);

  const hasParam = props.match.params.id;

  const rangeOfYears = (start, end) =>
    Array(end - start + 1)
      .fill(start)
      .map((year, index) => year + index);

  let years = rangeOfYears(1900, new Date().getFullYear() + 50);

  function toggle() {
    setModal(false);
  }

  useEffect(() => {
    if (
      hasAccess(
        MODULE_NAMES.PROVIDER_MANAGEMENT_PERFORMANCE_METRICS,
        PERMISSION_TYPE.VIEW,
        permissions
      )
    ) {
      getList(field, sortBy, searchText, activePage + 1, year);
      setPages(10);
    }
  }, [year]);

  useEffect(() => {
    if (
      hasAccess(
        MODULE_NAMES.PROVIDER_MANAGEMENT_PERFORMANCE_METRICS,
        PERMISSION_TYPE.VIEW,
        permissions
      )
    ) {
      const params = {
        rd_id: hasParam
      };
      RdServices.summary(params).then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        setSummaryData(data.data);
      });
    }
  }, []);

  const formAction = (action, data = null) => {
    // Here you can view the data and make forward action for edit data
    if (action === "edit") {
      setId(data.id);
      setModal(true);
      const params = {
        id: data.id
      };
      RdServices.goalView(params).then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        setEditedData(data.data);
      });
    } else if (action === "add") {
      setId(data);
      setModal(true);
      setEditedData(null);
    }
  };

  useEffect(() => {
    if (
      hasAccess(
        MODULE_NAMES.PROVIDER_MANAGEMENT_PERFORMANCE_METRICS,
        PERMISSION_TYPE.VIEW,
        permissions
      )
    ) {
      const params = {
        id: hasParam
      };
      getList(field, sortBy, searchText, activePage + 1, year);
      setPages(10);
    }
  }, []);

  const submitFormHandler = () => {
    setTimeout(() => {
      setModal(false);
      getList(field, sortBy, searchText, activePage + 1);
    }, 2000);
  };

  const columns = [
    {
      Header: "Month",
      accessor: "month_year",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true
    },
    {
      Header: "Units",
      accessor: "unit",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true
    },
    {
      Header: "Goal",
      accessor: "goal",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true
    },
    {
      Header: "Action",
      accessor: "action",
      className: "",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      Cell: colProps => (
        <div className="react-action-class">
          {hasAccess(
            MODULE_NAMES.PROVIDER_MANAGEMENT_PERFORMANCE_METRICS,
            PERMISSION_TYPE.EDIT,
            permissions
          ) && (
            <Button
              className="c-btn c-success mr-10"
              onClick={() => formAction("edit", colProps.original)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-edit" />
              </div>
            </Button>
          )}
        </div>
      ) // Custom cell components!
    }
  ];

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  function getList(
    field = "created_at",
    sortBy = "DESC",
    search = "",
    page = "1",
    year = year
  ) {
    const params = {
      sort_by: field,
      order: sortBy,
      search: search,
      page: page,
      rd_id: hasParam,
      year: year
    };
    setLoader(true);
    RdServices.goalList(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count / 12));
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });
  }

  return (
    <div>
      <div className="fs-16 demi-bold-text mb-3">Monthly Summary</div>
      <div className="module-header p-2 mb-3">
        <div style={{ display: "inline-grid", paddingRight: "3rem" }}>
          <lable
            style={{
              backgroundColor: "#9b9b9c",
              color: "white",
              padding: "10px"
            }}
          >
            Total Client
          </lable>
          <lable
            style={{
              padding: "10px",
              textAlign: "center",
              border: "1px solid #e0e0e0"
            }}
          >
            {summaryData && summaryData.total_client}
          </lable>
        </div>

        <div style={{ display: "inline-grid" }}>
          <lable
            style={{
              backgroundColor: "#9b9b9c",
              color: "white",
              padding: "10px"
            }}
          >
            Active Client
          </lable>
          <lable
            style={{
              padding: "10px",
              textAlign: "center",
              border: "1px solid #e0e0e0"
            }}
          >
            {summaryData && summaryData.active_client}
          </lable>
        </div>
      </div>
      <div className="module-header">
        <div className="flex-1 fs-16 demi-bold-text">Performance vs. Goal </div>
        <div className="fs-14 mr-10">
          <Dropdown isOpen={parentDropdownOpen} toggle={toggleOne}>
            <DropdownToggle caret size="md">
              {year ? year : "Year"}
            </DropdownToggle>
            <DropdownMenu
              modifiers={{
                setMaxHeight: {
                  enabled: true,
                  order: 890,
                  fn: data => {
                    return {
                      ...data,
                      styles: {
                        ...data.styles,
                        overflow: "auto",
                        maxHeight: "300px"
                      }
                    };
                  }
                }
              }}
            >
              {years.length > 0 &&
                years.map((result, i) => {
                  return (
                    <DropdownItem key={i} onClick={() => setYear(result)}>
                      {result}
                    </DropdownItem>
                  );
                })}
            </DropdownMenu>
          </Dropdown>
        </div>
        <div>
          {hasAccess(
            MODULE_NAMES.PROVIDER_MANAGEMENT_PERFORMANCE_METRICS,
            PERMISSION_TYPE.ADD,
            permissions
          ) && (
            <Button
              className="c-btn c-primary ma-5"
              onClick={() => formAction("add")}
            >
              <i className="fas fa-plus mr-10" />
              Add Goal
            </Button>
          )}
        </div>
      </div>
      <ReactTable
        style={{
          border: "none",
          boxShadow: "none"
        }}
        data={tblData}
        columns={columns}
        defaultPageSize={10}
        minRows={2}
        manual
        defaultFilterMethod={(filter, row) => {
          const id = filter.pivotId || filter.id;

          return row[id] !== undefined
            ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
            : true;
        }}
        className="-striped -highlight custom-react-table-theme-class"
        pages={pages}
        page={activePage}
        PaginationComponent={Pagination}
        loading={loader}
        LoadingComponent={Loader}
        onFetchData={onChangePageGetData}
        onPageChange={pageIndex => {
          setActivePage(pageIndex);
          getList(field, sortBy, searchText, pageIndex + 1);
        }}
        onSortedChange={sortProperties => {
          const sort = sortProperties[0].desc ? "DESC" : "ASC";
          setSortBy(sort);
          setActivePage(0);
          setField(sortProperties[0].id);
          getList(sortProperties[0].id, sort, searchText, 1);
          // alert("sort handler", sortProperties);
          // const orderByDirection = sortProperties[0].desc ? 1 : 0;

          // updateMapStateInfoOrderByColumn(sortProperties[0].id, orderByDirection);
        }}
      />
      <Toaster ref={toaster} />
      <CommonModel
        modal={modal}
        toggle={toggle}
        children={
          <PerformanceModal
            onSubmit={submitFormHandler}
            editedData={editedData}
            action={editedData ? "edit" : "add"}
            toggle={toggle}
            {...props}
          />
        }
        header={editedData ? "Edit Goal" : "Add Goal"}
      />
    </div>
  );
};
export default withRouter(PerformanceTab);
