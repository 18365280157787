import EncryptDecrypt from "api/EncryptDecrypt";
import RdServices from "api/RdServices";
import Toaster from "components/common/Toaster";
import { ImageResolution } from "helper/constant";
import React, { Fragment, useRef, useState } from "react";
import { Input } from "reactstrap";
import { PLACEHOLDER } from "helper/constant";
import Button from "components/button/Button";
import enhancer from "./profile-validator";
import { compose } from "redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const RdProfileForm = props => {
  const history = useHistory();
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    setFieldTouched,
    setFieldValue,
    onSubmit,
    values,
    action,
    category,
    stateList,
    languageList,
    timezoneList
  } = props;

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };
  const toaster = useRef();
  const [imageObj, setImageObj] = useState(null);
  const [uploadImage, setUploadImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleImage = (e, setValue) => {
    console.log("eee", e.target.files[0]);
    if (e.target.files[0]) {
      let fileObj = e.target.files[0];

      if (fileObj.size > 10 * 1024 * 1024) {
        if (toaster.current)
          toaster.current.error("Please upload a file smaller than 10 MB");
        return false;
      }

      if (!fileObj.name.match(/\.(jpg|jpeg|png|gif|heif)$/)) {
        if (toaster.current)
          toaster.current.error(
            "Only .jpg, .jpeg, .gif, .png, .heif are allowed"
          );
        return false;
      }

      if (fileObj) {
        const img = new Image();

        img.src = window.URL.createObjectURL(fileObj);

        img.onload = function() {
          const width = img.naturalWidth,
            height = img.naturalHeight;

          window.URL.revokeObjectURL(img.src);
          setValue("width", width);
          setValue("height", height);
          setFieldTouched("profile_image", true, true);
          if (
            width >= ImageResolution.WIDTH &&
            height >= ImageResolution.HEIGHT
          ) {
            setImageObj(fileObj);
            setValue("profile_image", URL.createObjectURL(fileObj));
            setUploadImage(URL.createObjectURL(fileObj));
          }
        };
      }
    }
  };

  const changeFormat = e => {
    var x = e.target.value
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    let no = "(" + x[1] + ") " + x[2] + "-" + x[3];
    setFieldValue("phone_number", no);
  };

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;
    const id = props.editedData.id;

    if (isValid) {
      setLoading(true);
      const params = {
        admin_id: id,
        first_name: values.first_name,
        last_name: values.last_name,
        profile_text: values.profile_text,
        // primary_category_id: values.primary_category_id,
        // secondary_category_id: values.secondary_category_id,
        timezone_id: values.timezone_id,
        language_id: values.language_id,
        address: values.address,
        city: values.city,
        state_id: values.state_id,
        zip: values.zip,
        phone_number: values.phone_number.replace(/[()-\s]/g, "")
      };

      const formdata = new FormData();
      const encryptData = EncryptDecrypt.encrypt(params);
      formdata.append("data", encryptData?.data);
      if (imageObj) formdata.append("data1", imageObj);

      RdServices.edit(formdata)
        .then(res => {
          setLoading(false);
          let data = EncryptDecrypt.decrypt(res.data);
          if (data.status) {
            if (props?.actionFlow?.actionFlow === "add") {
              history.push(`/rd/view/${props?.providerId}`, {
                actionFlow: props?.actionFlow?.actionFlow,
                step: "2"
              });
            } else {
              toaster.current.success(data.message);
              onSubmit(values, action);
            }
          } else {
            if (toaster.current) toaster.current.error(data.message);
          }
        })
        .catch(err => {
          if (toaster.current) toaster.current.error(err.response);
        });
    }
    handleSubmit();
  };

  return (
    <div>
      <form>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">
              First Name <span className="asterisk">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="first_name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.first_name}
              placeholder="Add First Name"
            />
            <Error field="first_name" />
          </div>
          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">
              Last Name <span className="asterisk">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="last_name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.last_name}
              placeholder="Add Last Name"
            />
            <Error field="last_name" />
          </div>
          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">
              Email <span className="asterisk">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              placeholder="Add Email"
              disabled
            />
            <Error field="email" />
          </div>

          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">
              Profile Text <span className="asterisk">*</span>
            </label>
            <textarea
              className="form-control react-form-input"
              rows="3"
              placeholder="Add Profile Text"
              id="profile_text"
              name="profile_text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.profile_text}
            ></textarea>
            <Error field="profile_text" />
          </div>

          {/* <div className="form-group col-md-6">
            <label className="fs-16 medium-text">
              Primary Category<span className="asterisk"> *</span>
            </label>
            <Input
              type="select"
              name="primary_category_id"
              id="primary_category_id"
              onChange={handleChange}
              value={values.primary_category_id}
              onBlur={handleBlur}
              // disabled
            >
              <option value="">Select Primary Category</option>
              {category.map((item, i) => {
                return (
                  <option key={`option${i}`} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </Input>
            <Error field="primary_category_id" />
          </div>
          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">
              Secondary Category<span className="asterisk"> *</span>
            </label>
            <Input
              type="select"
              name="secondary_category_id"
              id="secondary_category_id"
              onChange={handleChange}
              value={values.secondary_category_id}
              onBlur={handleBlur}
              // disabled
            >
              <option value="">Select Secondary Category</option>
              {category.map((item, i) => {
                return (
                  <option key={`option${i}`} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </Input>
            <Error field="secondary_category_id" />
          </div> */}

          <div className="col-md-6 form-group">
            <label className="fs-16 medium-text">
              Phone Number<span className="asterisk">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="phone_number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phone_number}
              placeholder="Add Phone Number"
              onKeyUp={value => changeFormat(value)}
            />
            <Error field="phone_number" />
          </div>
          <div className="col-md-6 form-group">
            <label className="fs-16 medium-text">
              Language<span className="asterisk">*</span>
            </label>
            <Input
              type="select"
              name=""
              id="language_id"
              onChange={event => {
                let opts = [],
                  opt;
                for (
                  let i = 0, len = event.target.options.length;
                  i < len;
                  i++
                ) {
                  opt = event.target.options[i];
                  if (opt.selected) {
                    opts.push(opt.value);
                  }
                }
                setFieldValue("language_id", opts);
              }}
              value={values.language_id}
              onBlur={handleBlur}
              multiple
            >
              {languageList.map((type, i) => {
                return (
                  <Fragment>
                    <option key={`option${i}`} value={type.id}>
                      {type.name}
                    </option>
                  </Fragment>
                );
              })}
            </Input>
            <Error field="language_id" />
          </div>
          <div className="col-md-6 form-group">
            <label className="fs-16 medium-text">
              Timezone<span className="asterisk">*</span>
            </label>
            <Input
              type="select"
              name=""
              id="timezone_id"
              onChange={handleChange}
              value={values.timezone_id}
              onBlur={handleBlur}
            >
              <option value="">Select Timezone</option>
              {timezoneList.map((type, i) => {
                return (
                  <Fragment>
                    <option key={`option${i}`} value={type.id}>
                      {type.name}
                    </option>
                  </Fragment>
                );
              })}
            </Input>
            <Error field="timezone_id" />
          </div>
          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">
              Profile Image<span className="asterisk"> *</span>
            </label>
            <br />
            <div className="file-upload">
              <label className="c-btn c-primary form-button fs-16 demi-bold-text mt-15">
                Upload image
                <input
                  id="profile_image"
                  className="file-upload__input"
                  name="file-upload"
                  type="file"
                  accept="image/jpg,image/png,image/jpeg"
                  onChange={e => handleImage(e, setFieldValue)}
                  onBlur={handleBlur}
                  onClick={event => {
                    event.target.value = "";
                  }}
                />
              </label>
            </div>
            <br />
            {
              <img
                src={
                  uploadImage
                    ? uploadImage
                    : values.profile_image || PLACEHOLDER
                }
                alt=""
                className="mtb-15"
                style={{
                  width: "200px",
                  borderRadius: "4%",
                  height: "150px",
                  background: "#404040"
                }}
              />
            }
            <br />
            <Error field="profile_image" />
          </div>
          <div className="col-md-12">
            <label className="fs-18 medium-text">Home Address</label>
            <div className="row">
              <div className="col-md-6 form-group">
                <label className="fs-16 medium-text">Address</label>
                <input
                  type="text"
                  className="form-control react-form-input"
                  id="address"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address}
                  placeholder="Add Address"
                />
              </div>
              <div className="col-md-6 form-group">
                <label className="fs-16 medium-text">City</label>
                <input
                  type="text"
                  className="form-control react-form-input"
                  id="city"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.city}
                  placeholder="Add City"
                />
              </div>
              <div className="col-md-6 form-group">
                <label className="fs-16 medium-text">State</label>
                <Input
                  type="select"
                  name=""
                  id="state_id"
                  onChange={handleChange}
                  value={values.state_id}
                  onBlur={handleBlur}
                >
                  <option value="">Select State</option>
                  {stateList.map((type, i) => {
                    return (
                      <Fragment>
                        <option key={`option${i}`} value={type.id}>
                          {type.name}
                        </option>
                      </Fragment>
                    );
                  })}
                </Input>
              </div>
              <div className="col-md-6 form-group">
                <label className="fs-16 medium-text">Zip</label>
                <input
                  type="text"
                  className="form-control react-form-input"
                  id="zip"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.zip}
                  placeholder="Add Zip"
                />
                <Error field="zip" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <Button
            type="button"
            loading={loading}
            disabled={loading}
            className="c-btn c-info form-button fs-16 demi-bold-text"
            style={{ maxWidth: "180px" }}
            onClick={() => handleSubmit()}
            dataStyle="expand-right"
          >
            {props?.actionFlow?.actionFlow === "add" ? (
              <span>Save and Continue</span>
            ) : (
              <span>Submit</span>
            )}
          </Button>
        </div>
      </form>

      <Toaster ref={toaster} />
    </div>
  );
};

export default compose(enhancer)(RdProfileForm);
