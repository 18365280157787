import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import GroupServices from "../../api/GroupServices";
import Loader from "../common/Loader";
import CommonModel from "../common/CommonModel";
import DeleteModal from "../common/DeleteModal";
import CustomModal from "../common/CustomModal";
//import Moment from "react-moment";
import Toaster from "../common/Toaster";
import {
  TABLE,
  SYSTEMADMIN,
  RD,
  MODULE_NAMES,
  PERMISSION_TYPE
} from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import moment from "moment";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
import { hasAccess } from "util/sidebar";
import CategoryServices from "api/CategoryServices";

const GroupTable = props => {
  const { permissions } = props;
  const [tblData, settblData] = useState();
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("id");
  const [sortBy, setSortBy] = useState("ASC");
  const [id, setId] = useState(undefined);
  const [status, setStatus] = useState(undefined);
  const [totalUser, setTotalUser] = useState(undefined);
  const [modal, setModal] = useState(undefined);
  const [customModal, setCustomModal] = useState(undefined);
  const [idText, setIdText] = useState("");
  const [statusText, setStatusText] = useState("");
  const [statusValue, setStatusValue] = useState("");
  const [typeValue, setTypeValue] = useState("");
  const [paymentMethodValue, setPaymentMethodValue] = useState("");
  const [parentDropdownOpen, setParentDropdownOpen] = useState(false);
  const [parentDropdownOpenType, setParentDropdownOpenType] = useState(false);
  const [parentDropdownOpenPayment, setParentDropdownOpenPayment] = useState(
    false
  );
  const [serviceId, setServiceId] = useState(null);
  const [serviceList, setServiceList] = useState([]);
  const [categoryLabel, setCategoryLabel] = useState(null);

  const toaster = useRef();
  const toggleOne = () => setParentDropdownOpen(prevState => !prevState);
  const toggleOneType = () =>
    setParentDropdownOpenType(prevState => !prevState);
  const toggleOnePayment = () =>
    setParentDropdownOpenPayment(prevState => !prevState);
  const [serviceDropdownOpen, setServiceDropdownOpen] = useState(false);
  const toggleService = () => setServiceDropdownOpen(prevState => !prevState);

  useEffect(() => {
    if (serviceId || props.authData.adminRoleId <= 3) {
      getList(
        field,
        sortBy,
        searchText,
        activePage + 1,
        statusValue,
        idText,
        typeValue,
        paymentMethodValue
      );
      setPages(10);
    }
  }, [serviceId]);

  useEffect(() => {
    if (props.authData.adminRoleId > 2) {
      setServiceId(props.authData.service_id);
    }
  }, []);

  useEffect(() => {
    CategoryServices.serviceDropdown({ is_general: true })
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setServiceList([...data.data]);
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      });
  }, []);

  const deleteClick = data => {
    setId(data.id);
    setModal(true);
  };

  function deleteHandler() {
    setLoader(true);
    const params = {
      id: id
    };
    GroupServices.delete(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current) toaster.current.success(data.message);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
        setLoader(false);
        setModal(false);
        setTimeout(() => {
          getList(
            field,
            sortBy,
            searchText,
            activePage + 1,
            statusValue,
            idText,
            typeValue,
            paymentMethodValue
          );
        }, 1000);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });
  }

  function toggle() {
    setModal(false);
  }

  function activeToggle() {
    setCustomModal(false);
  }

  const activeDeactive = data => {
    setId(data.id);
    setStatus(data.is_active);
    setTotalUser(data.user_count);
    setCustomModal(true);
  };

  const activeInactiveStatusHandler = oData => {
    let statusData = status;
    const params = {
      is_active: !statusData,
      id: id
    };
    setLoader(true);
    GroupServices.status(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current)
            toaster.current.success(
              statusData === true
                ? "Group has been deactivated successfully."
                : "Group has been activated successfully."
            );
          setCustomModal(false);
          getList(
            field,
            sortBy,
            searchText,
            activePage + 1,
            statusValue,
            idText,
            typeValue,
            paymentMethodValue
          );
          setLoader(false);
        } else {
          setCustomModal(false);
          if (toaster.current) toaster.current.error(data.message);
          setLoader(false);
        }
      })
      .catch(err => {
        setCustomModal(false);
        if (toaster.current) toaster.current.error(err.response);
        setLoader(false);
      });
  };

  const columns = [
    {
      Header: "ID",
      accessor: "id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 100,
      sortable: true
    },
    // {
    //   Header: "Group Code",
    //   accessor: "group_unique_id",
    //   className: "",
    //   filterable: false,
    //   headerClassName: "react-table-header-class",
    //   width: 150
    // },
    {
      Header: "Name",
      accessor: "name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 250,
      sortable: true
    },
    {
      Header: "Type",
      accessor: "type",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 150,
      sortable: true
    },
    {
      Header: "Service",
      accessor: "service.name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 150,
      sortable: false
    },
    {
      Header: "Payment Method",
      accessor: "payment_method",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 200,
      sortable: true
    },
    {
      Header: "Is Benefit Expired?",
      accessor: "benefit_count",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 200,
      sortable: true,
      Cell: props => (
        <div className="react-action-class">
          {props.original.benefit_count > 0 ? "No" : "Yes"}
        </div>
      ) // Custom cell components!
    },
    {
      Header: "Launch Date",
      accessor: "launch_date",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      Cell: props => moment(props.original.launch_date).format("MM/DD/YYYY"),
      width: 150,
      sortable: true
    },
    {
      Header: "Universal Login",
      accessor: "universal_login",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 180,
      sortable: true,
      Cell: props => (
        <div className="react-action-class">
          {props.original.universal_login ? "Enable" : "Disable"}
        </div>
      ) // Custom cell components!
    },
    {
      Header: "Status",
      accessor: "is_active",
      className: "",
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      filterable: false,
      width: 150,
      Cell: cellProps => (
        <div className="react-action-class">
          {props.authData.adminRoleId !== RD ? (
            cellProps.original.is_active ? (
              <div id={`active-${cellProps.original.state_id}`}>
                <Button
                  style={{ minWidth: "125px" }}
                  disabled={
                    !hasAccess(
                      MODULE_NAMES.GROUP,
                      PERMISSION_TYPE.STATUS,
                      permissions
                    )
                  }
                  className="c-btn c-info mr-10"
                  onClick={() => activeDeactive(cellProps.original)}
                >
                  <div className="fs-14 medium-text">
                    <i className="fas fa-toggle-off mr-6" /> Active
                  </div>
                </Button>
              </div>
            ) : (
              <div id={`deactive-${cellProps.original.state_id}`}>
                <Button
                  style={{ minWidth: "125px" }}
                  disabled={
                    !hasAccess(
                      MODULE_NAMES.GROUP,
                      PERMISSION_TYPE.STATUS,
                      permissions
                    )
                  }
                  className="c-btn c-warning mr-10"
                  onClick={() => activeDeactive(cellProps.original)}
                >
                  <div className="fs-14 medium-text">
                    <i className="fas fa-toggle-on mr-6" /> Inactive
                  </div>
                </Button>
              </div>
            )
          ) : cellProps.original.is_active ? (
            "Active"
          ) : (
            "Inactive"
          )}
        </div>
      ) // Custom cell components!
    },
    {
      Header: "Action",
      accessor: "action",
      className: "",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 150,
      Cell: colProps => (
        <div className="react-action-class">
          {hasAccess(MODULE_NAMES.GROUP, PERMISSION_TYPE.VIEW, permissions) ? (
            <Button
              className="c-btn c-info mr-10"
              onClick={() => formAction("view", colProps.original)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-eye" />
              </div>
            </Button>
          ) : null}
          {colProps.original.id !== 1 &&
          hasAccess(MODULE_NAMES.GROUP, PERMISSION_TYPE.EDIT, permissions) ? (
            <Button
              className="c-btn c-success mr-10"
              onClick={() => formAction("edit", colProps.original)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-edit" />
              </div>
            </Button>
          ) : (
            ""
          )}
          {colProps.original.id !== 1 &&
          hasAccess(MODULE_NAMES.GROUP, PERMISSION_TYPE.DELETE, permissions) ? (
            <Button
              className="c-btn c-danger"
              onClick={() => deleteClick(colProps.original)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-trash" />
              </div>
            </Button>
          ) : (
            ""
          )}
        </div>
      ) // Custom cell components!
    }
  ];

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  const formAction = (action, data = null) => {
    // Here you can view the data and make forward action for edit data
    if (action === "add") {
      props.history.push("/group/add");
    } else if (action === "edit") {
      props.history.push(`/group/edit/${data.id}`);
    } else if (action === "view") {
      props.history.push(`/group/view/${data.id}`);
    }
  };

  function getList(
    field = "created_at",
    sortBy = "DESC",
    search = "",
    page = "1",
    is_active = statusValue,
    idSearch = "",
    type = "",
    payment_method = "",
    service_id = null
  ) {
    const params = {
      sort_by: field,
      order: sortBy,
      search: search,
      page: page,
      expire:
        props.location.search && props.location.search === "?expire"
          ? true
          : false,
      is_active: is_active,
      id: idSearch,
      type: type,
      payment_method: payment_method,
      service_id:
        props.authData.adminRoleId >= 3
          ? props.authData.service_id
          : serviceId || service_id
    };
    setLoader(true);
    GroupServices.list(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count / TABLE.LIMIT));
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });
  }

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Group List
            </div>
            <div>
              {hasAccess(
                MODULE_NAMES.GROUP,
                PERMISSION_TYPE.ADD,
                permissions
              ) ? (
                <Button
                  className="c-btn c-primary ma-5"
                  onClick={() => formAction("add")}
                >
                  <i className="fas fa-plus mr-10" />
                  Add Group
                </Button>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="roe-card-header module-header">
            <div className="d-flex">
              <div className="mr-10">
                <span className="fs-16">ID</span>
                <input
                  value={idText}
                  onChange={e => {
                    setIdText(e.target.value);
                    const idSearch = e.target.value;
                    setLoader(true);
                    setActivePage(0);
                    setTimeout(() => {
                      getList(
                        field,
                        sortBy,
                        searchText,
                        1,
                        statusValue,
                        idSearch,
                        typeValue,
                        paymentMethodValue
                      );
                    }, 500);
                  }}
                  type="text"
                  placeholder="Search By ID"
                  className="fs-14 medium-text plr-10 form-control react-form-input"
                />
              </div>
              <div className="mr-10">
                <span className="fs-16">Name</span>
                <input
                  value={searchText}
                  onChange={e => {
                    setSearchText(e.target.value);
                    const search = e.target.value;
                    setLoader(true);
                    setActivePage(0);
                    setTimeout(() => {
                      getList(
                        field,
                        sortBy,
                        search,
                        1,
                        statusValue,
                        idText,
                        typeValue,
                        paymentMethodValue
                      );
                    }, 500);
                  }}
                  type="text"
                  placeholder="Search By Name"
                  className="fs-14 medium-text plr-10 form-control react-form-input"
                />
              </div>
              <div className="fs-16 mr-10">
                <span className="fs-16">Type</span>
                <Dropdown
                  isOpen={parentDropdownOpenType}
                  toggle={toggleOneType}
                >
                  <DropdownToggle caret size="md">
                    {typeValue ? typeValue : "All"}
                  </DropdownToggle>
                  <DropdownMenu
                    modifiers={{
                      setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: data => {
                          return {
                            ...data,
                            styles: {
                              ...data.styles,
                              overflow: "auto",
                              maxHeight: "300px"
                            }
                          };
                        }
                      }
                    }}
                  >
                    <DropdownItem
                      key="0"
                      onClick={() => {
                        setTypeValue("");
                        setLoader(true);
                        setActivePage(0);
                        setTimeout(() => {
                          getList(
                            field,
                            sortBy,
                            searchText,
                            1,
                            statusValue,
                            idText,
                            "",
                            paymentMethodValue
                          );
                        }, 500);
                      }}
                    >
                      All
                    </DropdownItem>
                    <DropdownItem
                      key="Employer"
                      onClick={() => {
                        setTypeValue("Employer");
                        setLoader(true);
                        setActivePage(0);
                        setTimeout(() => {
                          getList(
                            field,
                            sortBy,
                            searchText,
                            1,
                            statusValue,
                            idText,
                            "Employer",
                            paymentMethodValue
                          );
                        }, 500);
                      }}
                    >
                      Employer
                    </DropdownItem>
                    <DropdownItem
                      key="Individual"
                      onClick={() => {
                        setTypeValue("Individual");
                        setLoader(true);
                        setActivePage(0);
                        setTimeout(() => {
                          getList(
                            field,
                            sortBy,
                            searchText,
                            1,
                            statusValue,
                            idText,
                            "Individual",
                            paymentMethodValue
                          );
                        }, 500);
                      }}
                    >
                      Individual
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
              <div className="fs-16 mr-10">
                <span className="fs-16">Payment Method</span>
                <Dropdown
                  isOpen={parentDropdownOpenPayment}
                  toggle={toggleOnePayment}
                >
                  <DropdownToggle caret size="md" className="w-100 float-left">
                    {paymentMethodValue ? paymentMethodValue : "All"}
                  </DropdownToggle>
                  <DropdownMenu
                    modifiers={{
                      setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: data => {
                          return {
                            ...data,
                            styles: {
                              ...data.styles,
                              overflow: "auto",
                              maxHeight: "300px"
                            }
                          };
                        }
                      }
                    }}
                  >
                    <DropdownItem
                      key="0"
                      onClick={() => {
                        setPaymentMethodValue("");
                        setLoader(true);
                        setActivePage(0);
                        setTimeout(() => {
                          getList(
                            field,
                            sortBy,
                            searchText,
                            1,
                            statusValue,
                            idText,
                            typeValue,
                            ""
                          );
                        }, 500);
                      }}
                    >
                      All
                    </DropdownItem>
                    <DropdownItem
                      key="Insurance Funded"
                      onClick={() => {
                        setPaymentMethodValue("Insurance Funded");
                        setLoader(true);
                        setActivePage(0);
                        setTimeout(() => {
                          getList(
                            field,
                            sortBy,
                            searchText,
                            1,
                            statusValue,
                            idText,
                            typeValue,
                            "Insurance Funded"
                          );
                        }, 500);
                      }}
                    >
                      Insurance Funded
                    </DropdownItem>
                    <DropdownItem
                      key="Group Funded"
                      onClick={() => {
                        setPaymentMethodValue("Group Funded");
                        setLoader(true);
                        setActivePage(0);
                        setTimeout(() => {
                          getList(
                            field,
                            sortBy,
                            searchText,
                            1,
                            statusValue,
                            idText,
                            typeValue,
                            "Group Funded"
                          );
                        }, 500);
                      }}
                    >
                      Group Funded
                    </DropdownItem>
                    <DropdownItem
                      key="Client Funded"
                      onClick={() => {
                        setPaymentMethodValue("Client Funded");
                        setLoader(true);
                        setActivePage(0);
                        setTimeout(() => {
                          getList(
                            field,
                            sortBy,
                            searchText,
                            1,
                            statusValue,
                            idText,
                            typeValue,
                            "Client Funded"
                          );
                        }, 500);
                      }}
                    >
                      Client Funded
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
              <div className="fs-16 mr-10">
                <span className="fs-16">Status</span>
                <Dropdown isOpen={parentDropdownOpen} toggle={toggleOne}>
                  <DropdownToggle caret size="md">
                    {statusText ? statusText : "All"}
                  </DropdownToggle>
                  <DropdownMenu
                    modifiers={{
                      setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: data => {
                          return {
                            ...data,
                            styles: {
                              ...data.styles,
                              overflow: "auto",
                              maxHeight: "300px"
                            }
                          };
                        }
                      }
                    }}
                  >
                    <DropdownItem
                      key="0"
                      onClick={() => {
                        setStatusText("");
                        setStatusValue("");
                        setLoader(true);
                        setActivePage(0);
                        setTimeout(() => {
                          getList(
                            field,
                            sortBy,
                            searchText,
                            1,
                            "",
                            idText,
                            typeValue,
                            paymentMethodValue
                          );
                        }, 500);
                      }}
                    >
                      All
                    </DropdownItem>
                    <DropdownItem
                      key="Active"
                      onClick={() => {
                        setStatusText("Active");
                        setStatusValue("1");
                        setLoader(true);
                        setActivePage(0);
                        setTimeout(() => {
                          getList(
                            field,
                            sortBy,
                            searchText,
                            1,
                            "1",
                            idText,
                            typeValue,
                            paymentMethodValue
                          );
                        }, 500);
                      }}
                    >
                      Active
                    </DropdownItem>
                    <DropdownItem
                      key="Inactive"
                      onClick={() => {
                        setStatusText("Inactive");
                        setStatusValue("0");
                        setLoader(true);
                        setActivePage(0);
                        setTimeout(() => {
                          getList(
                            field,
                            sortBy,
                            searchText,
                            1,
                            "0",
                            idText,
                            typeValue,
                            paymentMethodValue
                          );
                        }, 500);
                      }}
                    >
                      Inactive
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
              {props.authData.adminRoleId <= 2 && (
                <div className="fs-16 mr-10">
                  <span className="fs-16">Service</span>
                  <Dropdown isOpen={serviceDropdownOpen} toggle={toggleService}>
                    <DropdownToggle caret size="md" style={{ width: "150px" }}>
                      {categoryLabel ? categoryLabel : "Select Service"}
                    </DropdownToggle>
                    <DropdownMenu
                      modifiers={{
                        setMaxHeight: {
                          enabled: true,
                          order: 890,
                          fn: data => {
                            return {
                              ...data,
                              styles: {
                                ...data.styles,
                                overflow: "auto",
                                maxHeight: "300px"
                              }
                            };
                          }
                        }
                      }}
                    >
                      <DropdownItem
                        key="-1"
                        onClick={() => {
                          setActivePage(0);
                          setServiceId("");
                          setCategoryLabel("All");
                        }}
                      >
                        All
                      </DropdownItem>
                      {serviceList.length > 0 &&
                        serviceList.map((result, i) => {
                          return (
                            <DropdownItem
                              key={i}
                              onClick={() => {
                                setActivePage(0);
                                setCategoryLabel(result.name);
                                setServiceId(result.id);
                              }}
                            >
                              {result.name}
                            </DropdownItem>
                          );
                        })}
                    </DropdownMenu>
                  </Dropdown>
                </div>
              )}
            </div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              LoadingComponent={Loader}
              onFetchData={onChangePageGetData}
              onPageChange={pageIndex => {
                setActivePage(pageIndex);
                getList(
                  field,
                  sortBy,
                  searchText,
                  pageIndex + 1,
                  statusValue,
                  idText,
                  typeValue,
                  paymentMethodValue
                );
              }}
              onSortedChange={sortProperties => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getList(
                  sortProperties[0].id,
                  sort,
                  searchText,
                  1,
                  statusValue,
                  idText,
                  typeValue,
                  paymentMethodValue
                );
                // alert("sort handler", sortProperties);
                // const orderByDirection = sortProperties[0].desc ? 1 : 0;

                // updateMapStateInfoOrderByColumn(sortProperties[0].id, orderByDirection);
              }}
            />
          </div>
          <CommonModel
            modal={modal}
            toggle={toggle}
            children={
              <DeleteModal
                text={"Are you sure you want to delete this group?"}
                deleteHandler={deleteHandler}
                toggle={toggle}
              />
            }
          />
          <CommonModel
            modal={customModal}
            toggle={activeToggle}
            children={
              <CustomModal
                text={`${
                  status === false
                    ? "Are you sure you want to activate group?"
                    : (totalUser > 0
                        ? totalUser + " users will deactivate."
                        : "") + " Are you sure you want to deactivate group?"
                }`}
                activeInactiveStatusHandler={activeInactiveStatusHandler}
                activeToggle={activeToggle}
              />
            }
          />
        </div>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};

export default withRouter(GroupTable);
