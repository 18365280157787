import Pagination from "components/common/Pagination";
import Toaster from "components/common/Toaster";
import React, { useEffect, useRef, useState } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import CommonModel from "../common/CommonModel";
import { withRouter } from "react-router";
import CareTeamServices from "api/CareTeamServices";
import EncryptDecrypt from "api/EncryptDecrypt";
import {
  OTHER_PHYSICIAN,
  PRIMARY_CARE_PHYSICIAN,
  TABLE
} from "../../helper/constant";
import Loader from "components/common/Loader";
import moment from "moment";
import AddExternalProviderModal from "./AddExternalProviderModal";
import DeleteModal from "components/common/DeleteModal";
import EditProviderModal from "./EditProviderModal";

const CareTeam = ({ clientData, ...props }) => {
  const toaster = useRef();
  const [careTeamTblData, setCareTeamTblData] = useState([]);
  const [externalCareTeamTblData, setExternalCareTeamTblData] = useState([]);
  const [careTeamLoader, setCareTeamLoader] = useState(false);
  const [externalCareTeamLoader, setExternalCareTeamLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [pagesEx, setPagesEx] = useState(0);
  const [activePageEx, setActivePageEx] = useState(0);
  const [externalProviderModal, setExternalProviderModal] = useState(false);
  const [providerModal, setProviderModal] = useState(false);
  const [serviceId, setServiceId] = useState(null);
  const [editedData, setEditedData] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [colData, setColData] = useState(null);
  const [providerData, setProviderData] = useState({});
  const hasParam = props.match.params.id;

  useEffect(() => {
    getCareTeamList(activePage + 1);
    setPages(10);
  }, []);

  useEffect(() => {
    getExternalCareTeamList(activePageEx + 1);
    setPagesEx(10);
  }, []);

  function providerToggle() {
    setProviderModal(false);
  }

  function externalProviderToggle() {
    setExternalProviderModal(false);
  }

  const submitFormHandler = () => {
    setTimeout(() => {
      setExternalProviderModal(false);
      getExternalCareTeamList(activePageEx + 1);
    }, 1000);
  };

  function getCareTeamList(page = "1") {
    const params = {
      page: page,
      user_id: hasParam
    };
    setCareTeamLoader(true);
    CareTeamServices.listCareTeam(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setCareTeamTblData(data.data.rows);
          setPages(Math.ceil(data.data.count / TABLE.LIMIT));
          setCareTeamLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
          setCareTeamLoader(false);
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current) toaster.current.error(err?.message);
        }
        setCareTeamLoader(false);
      });
  }

  const handleSubmit = () => {
    getCareTeamList(activePage + 1);
    setPages(10);
  };

  function getExternalCareTeamList(page = "1") {
    const params = {
      page: page,
      user_id: hasParam
    };
    setExternalCareTeamLoader(true);
    CareTeamServices.listExternalCareTeam(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setExternalCareTeamTblData(data.data.rows);
          setPagesEx(Math.ceil(data.data.count / TABLE.LIMIT));
          setExternalCareTeamLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
          setExternalCareTeamLoader(false);
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current) toaster.current.error(err?.message);
        }
        setExternalCareTeamLoader(false);
      });
  }

  const onChangePageGetData = state => {
    if (state.page !== activePageEx) {
      // alert("on page change you have to call api and get data");
    }
  };

  const activeInactiveStatusHandler = oData => {
    const params = {
      status: !oData.is_active,
      user_rd_service_id: oData.id
    };

    setCareTeamLoader(true);
    CareTeamServices.statusCareTeam(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);

        if (data.status) {
          if (toaster.current)
            toaster.current.success(
              oData.is_active
                ? "Status has been deactivated successfully."
                : "Status has been activated successfully."
            );
          getCareTeamList(activePage + 1);

          setCareTeamLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
          setCareTeamLoader(false);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
        setCareTeamLoader(false);
      });
  };

  const deleteClick = data => {
    setColData(data);
    setDeleteModal(true);
    setProviderData(data);
  };

  function deleteToggle() {
    setDeleteModal(false);
  }

  function deleteHandler() {
    setExternalCareTeamLoader(true);
    const params = {
      id: colData?.id,
      service_id: colData?.service_id
    };
    CareTeamServices.deleteExternalCareTeam(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current) toaster.current.success(data.message);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
        setExternalCareTeamLoader(false);
        setDeleteModal(false);
        setTimeout(() => {
          getExternalCareTeamList(activePageEx + 1);
        }, 500);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
        setExternalCareTeamLoader(false);
        setDeleteModal(false);
      });
  }

  const viewAction = (data = null) => {
    props.history.push(`/external-provider/view/${data?.id}`);
  };

  const columns = [
    {
      Header: "Services",
      accessor: "service",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 200,
      Cell: props => <div>{props?.original?.service.name}</div>
    },
    {
      Header: "Provider",
      accessor: "admin",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",
      width: 250,
      Cell: props => (
        <div>
          {props?.original?.admin === null ? (
            <div>Not assigned</div>
          ) : (
            <div>
              {props?.original?.admin?.first_name +
                " " +
                props?.original?.admin?.last_name}
            </div>
          )}
        </div>
      )
    },
    {
      Header: "Category",
      accessor: "category",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 200,
      Cell: props => <div>{props?.original?.category?.name}</div>
    },
    {
      Header: "State",
      accessor: "admin",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",
      width: 150,
      sortable: true,
      Cell: ({ original, value }) => (
        <div>
          <i
            className={
              value && value?.rd_state_licenses.length >= 1
                ? "far fa-check-circle tick-bg-color"
                : "far fa-check-circle tick-no-bg-color"
            }
          ></i>
        </div>
      )
    },
    {
      Header: "Insurance",
      accessor: "admin",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",
      sortable: false,
      width: 150,
      Cell: ({ original, value }) => (
        <div>
          <i
            className={
              value && value?.rd_insurances.length >= 1
                ? "far fa-check-circle tick-bg-color"
                : "far fa-check-circle tick-no-bg-color"
            }
          ></i>
        </div>
      )
    },
    {
      Header: "Days since last visit",
      accessor: "service.last_visit",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",
      sortable: false,
      width: 200,
      Cell: ({ value }) => (
        <div>
          {value && value?.length
            ? Math.floor(
                moment(moment().format("YYYY-MM-DD"))
                  .utc()
                  .diff(moment(value[0]?.appointment_date), "days", false)
              )
            : "-"}
        </div>
      )
    },
    {
      Header: "Days until next visit",
      accessor: "service.next_visit",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",
      sortable: false,
      width: 200,
      Cell: ({ value }) => (
        <div>
          {value && value?.length
            ? Math.floor(
                moment(value[0]?.appointment_date).diff(
                  moment(moment().format("YYYY-MM-DD")).utc(),
                  "days",
                  true
                )
              )
            : "-"}
        </div>
      )
    },
    {
      Header: "Status",
      accessor: "is_active",
      className: "",
      headerClassName: "react-table-header-class",
      sortable: true,
      filterable: false,
      width: 200,
      Cell: cellProps => (
        <div className="react-action-class">
          {cellProps?.original?.admin === null ? (
            <div>Not Applicable</div>
          ) : (
            <>
              {cellProps.original.is_active ? (
                <div id={`active-${cellProps.original.id}`}>
                  <Button
                    style={{ minWidth: "80px" }}
                    className="c-btn c-info mr-10"
                    onClick={() =>
                      activeInactiveStatusHandler(cellProps.original)
                    }
                    disabled={
                      props?.auth?.admin_role_id !== 1 &&
                      cellProps?.original?.service?.id !==
                        props?.auth?.service_id
                    }
                  >
                    <div className="fs-14 medium-text">
                      <i className="fas fa-toggle-off mr-6" /> Active
                    </div>
                  </Button>
                </div>
              ) : (
                <div id={`deactive-${cellProps.original.id}`}>
                  <Button
                    style={{ minWidth: "80px" }}
                    className="c-btn c-warning mr-10"
                    onClick={() =>
                      activeInactiveStatusHandler(cellProps.original)
                    }
                    disabled={
                      props?.auth?.admin_role_id !== 1 &&
                      cellProps?.original?.service?.id !==
                        props?.auth?.service_id
                    }
                  >
                    <div className="fs-14 medium-text">
                      <i className="fas fa-toggle-on mr-6" /> Inactive
                    </div>
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      )
    },
    {
      Header: "Action",
      accessor: "action",
      className: "",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 200,
      Cell: colProps => (
        <div className="react-action-class">
          <Button
            className="c-btn c-success mr-10"
            onClick={() => formActionCare(colProps.original)}
          >
            <div className="fs-14 medium-text">EDIT PROVIDER</div>
          </Button>
        </div>
      ) // Custom cell components!
    }
  ];

  const externalProvidercolumns = [
    {
      Header: "Services",
      accessor: "service",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 200,
      Cell: props => (
        <div>
          {props?.original?.service === "pcp"
            ? PRIMARY_CARE_PHYSICIAN[props?.original?.service]
            : OTHER_PHYSICIAN[props?.original?.service]}
        </div>
      )
    },
    {
      Header: "Provider",
      accessor: "admin",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",
      width: 250,
      Cell: props => (
        <div>
          {props?.original?.external_rd === null ? (
            <div>Not assigned</div>
          ) : (
            <div>
              {props?.original?.external_rd?.first_name +
                " " +
                props?.original?.external_rd?.last_name}
            </div>
          )}
        </div>
      )
    },
    {
      Header: "Service",
      accessor: "services.name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",
      width: 120,
      sortable: false
    },
    {
      Header: "Taxonomy",
      accessor: "taxonomy",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",
      width: 300,
      sortable: true,
      Cell: props => (
        <div>
          {props?.original?.external_rd === null ? (
            <div>Not assigned</div>
          ) : (
            <div>{props?.original?.external_rd?.taxonomy}</div>
          )}
        </div>
      )
    },

    {
      Header: "Action",
      accessor: "action",
      className: "",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 300,
      Cell: props => (
        <div className="react-action-class">
          <Button
            className="c-btn c-success mr-10"
            onClick={() => formAction("edit", props.original)}
          >
            <div className="fs-14 medium-text">EDIT PROVIDER</div>
          </Button>
          <Button
            className="c-btn c-info mr-10"
            onClick={() => viewAction(props?.original?.external_rd)}
            disabled={props?.original?.external_rd === null}
          >
            <div className="fs-14 medium-text">
              <i className="fas fa-eye" />
            </div>
          </Button>
          <Button
            className="c-btn c-danger"
            onClick={() => deleteClick(props.original)}
            disabled={props?.original?.external_rd === null}
          >
            <div className="fs-14 medium-text">
              <i className="fas fa-trash" />
            </div>
          </Button>
        </div>
      ) // Custom cell components!
    }
  ];

  const formAction = (action, data = null) => {
    if (action === "edit") {
      setExternalProviderModal(true);
      setEditedData({ action, ...data });
    } else if (action === "add") {
      setExternalProviderModal(true);
      setEditedData({ action: action });
    }
  };

  const formActionCare = data => {
    setProviderModal(true);
    setServiceId(data.service.id);
  };

  return (
    <div>
      <section>
        <div className="plr-15">
          <div className="roe-card-style mtb-15">
            <div className="roe-card-header module-header">
              <div className="flex-1 fs-16 demi-bold-text">
                <span className="hash"># </span>HUSK Care Team
              </div>
            </div>
            <div className="roe-card-body">
              <ReactTable
                style={{
                  border: "none",
                  boxShadow: "none"
                }}
                data={careTeamTblData}
                columns={columns}
                defaultPageSize={10}
                minRows={2}
                manual
                className="-striped -highlight custom-react-table-theme-class"
                pages={pages}
                page={activePage}
                PaginationComponent={Pagination}
                loading={careTeamLoader}
                LoadingComponent={Loader}
                onPageChange={pageIndex => {
                  setActivePage(pageIndex);
                  getExternalCareTeamList(pageIndex + 1);
                }}
              />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="plr-15">
          <div className="roe-card-style mtb-15">
            <div className="roe-card-header module-header">
              <div className="flex-1 fs-16 demi-bold-text">
                <span className="hash"># </span> External Care Team
              </div>
              <div>
                <Button
                  className="c-btn c-primary ma-5"
                  // onClick={() => {
                  //   setExternalProviderModal(true);
                  //   setEditedData({ type: "add" });
                  // }}
                  onClick={() => formAction("add")}
                >
                  <i className="fas fa-plus mr-10" />
                  Add External Provider
                </Button>
              </div>
            </div>
            <div className="roe-card-body">
              <ReactTable
                style={{
                  border: "none",
                  boxShadow: "none"
                }}
                data={externalCareTeamTblData}
                columns={externalProvidercolumns}
                defaultPageSize={10}
                minRows={2}
                manual
                className="-striped -highlight custom-react-table-theme-class"
                pages={pagesEx}
                page={activePageEx}
                PaginationComponent={Pagination}
                loading={externalCareTeamLoader}
                LoadingComponent={Loader}
                onFetchData={onChangePageGetData}
                onPageChange={pageIndex => {
                  setActivePageEx(pageIndex);
                  getExternalCareTeamList(pageIndex + 1);
                }}
              />
            </div>
          </div>
        </div>
      </section>

      <CommonModel
        modal={externalProviderModal}
        toggle={externalProviderToggle}
        size="xl"
        children={
          <AddExternalProviderModal
            onSubmit={submitFormHandler}
            editedData={editedData}
            action={editedData?.action === "edit" ? "edit" : "add"}
            toggle={externalProviderToggle}
            pageRefresh={() => {
              getExternalCareTeamList(activePageEx + 1);
            }}
            {...props}
          />
        }
        header={
          editedData?.action === "edit"
            ? "Edit External provider"
            : "Add External provider"
        }
      />

      <CommonModel
        modal={providerModal}
        toggle={providerToggle}
        size="lg"
        children={
          <EditProviderModal
            {...props}
            toggle={providerToggle}
            service_id={serviceId}
            pageRefresh={() => {
              getCareTeamList(activePage + 1);
            }}
            onSubmit={handleSubmit}
          />
        }
      />

      <CommonModel
        modal={deleteModal}
        toggle={deleteToggle}
        children={
          <DeleteModal
            text={`Are you sure you want to remove ${providerData?.external_rd
              ?.first_name +
              " " +
              providerData?.external_rd
                ?.last_name} from ${clientData?.first_name +
              " " +
              clientData?.last_name} care team?`}
            deleteHandler={deleteHandler}
            toggle={deleteToggle}
          />
        }
      />
      <Toaster ref={toaster} />
    </div>
  );
};

export default withRouter(CareTeam);
