import React, { useEffect, useRef, useState } from "react";
import { compose } from "redux";
import enhancer from "./patient-validator";
import Button from "../button/Button";
import Toaster from "../common/Toaster";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  InputGroup,
  Input
} from "reactstrap";
import EfaxManagementServices from "api/EfaxMangementServices";

const PatientModal = props => {
  const toaster = useRef();
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [client, setClient] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const toggle = () => setDropdownOpen(prevState => !prevState);

  const { errors, touched, submitCount, setFieldValue, editedData } = props;

  useEffect(() => {
    const params = {
      service_id: editedData?.service_id
    };
    setLoading(true);
    EfaxManagementServices.clientList(params).then(res => {
      let data = EncryptDecrypt.decrypt(res.data);
      setClientList(data.data.rows);
    });
    return () => {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    };
  }, []);

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const handleAssignRd = () => {
    let { isValid, handleSubmit } = props;
    if (isValid) {
      setIsLoading(true);
      let params = {
        user_id: client?.id,
        fax_id: editedData?.id,
        attachment_id: editedData?.attachment
      };
      EfaxManagementServices.attachDocument(params)
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          if (data.status) {
            if (toaster.current) toaster.current.success(data.message);
            setIsLoading(false);
            props.toggle();
            props.pageRefresh();
          } else {
            if (toaster.current) toaster.current.error(data.message);
            props.toggle();
          }
        })
        .catch(err => {
          if (toaster.current) toaster.current.error(err.response);
          props.toggle();
        });
    }
    handleSubmit();
  };

  return (
    <form className="pa-20">
      <div className="form-group ">
        <div>
          <span className="fs-20 demi-bold-text">Select Patient</span>
          <div className="flex pt-4 pb-100">
            <div>
              <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle caret style={{ width: "420px" }}>
                  {client
                    ? client.first_name + " " + client.last_name
                    : "Select Client"}
                </DropdownToggle>
                <DropdownMenu
                  style={{
                    width: "100%",
                    height: "20vh",
                    overflow: "scroll"
                  }}
                >
                  <div style={{ position: "static", overflow: "hidden" }}>
                    <InputGroup style={{ padding: "10px" }}>
                      <Input
                        placeholder="Type to search..."
                        value={inputValue}
                        onChange={e => setInputValue(e.target.value)}
                      />
                    </InputGroup>
                  </div>
                  {clientList
                    .filter(item =>
                      (
                        item?.first_name +
                        " " +
                        item?.last_name +
                        "(" +
                        item?.email +
                        ")"
                      )
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                    )
                    .map((result, i) => {
                      return (
                        <DropdownItem
                          key={i}
                          onClick={() => {
                            setClient(result);
                            setFieldValue("id", result.id);
                          }}
                        >
                          {result?.first_name +
                            " " +
                            result?.last_name +
                            "  " +
                            "(" +
                            result?.email +
                            ")"}
                        </DropdownItem>
                      );
                    })}
                </DropdownMenu>
              </Dropdown>
              <Error field="id" />
            </div>
          </div>
        </div>
      </div>
      <div className="flex-x center">
        <Button
          type="button"
          className="c-btn c-secondary form-button fs-16 demi-bold-text mr-10"
          style={{ maxWidth: "125px" }}
          onClick={props.toggle}
        >
          Cancel
        </Button>
        <Button
          type="button"
          className="c-btn c-info form-button fs-16 demi-bold-text"
          style={{ maxWidth: "125px" }}
          onClick={() => handleAssignRd()}
          loading={isLoading}
          disabled={isLoading}
          dataStyle={"expand-right"}
        >
          {isLoading ? "Attaching..." : "Attach Document"}
        </Button>
      </div>
      <Toaster ref={toaster} />
    </form>
  );
};

export default compose(enhancer)(PatientModal);
