const authActions = {
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  ADMIN: "ADMIN",

  login: data => {
    return {
      type: authActions.LOGIN,
      isLogin: data.isLogin,
      token: data.token,
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      id: data.id,
      admin_role_id: data.admin_role_id,
      admin_role: data.admin_role,
      profile_image: data.profile_image,
      step: data.step,
      stream_io_token: data.stream_io_token,
      calendly_link: data.calendly_link,
      service_id: data.service_id,
      adminType: data.adminType,
      tier_id: data.tier_id,
      practice: data.practice,
      is_messaging_active: data.is_messaging_active
    };
  },
  logout: () => {
    return {
      type: authActions.LOGOUT,
      isLogin: false,
      token: null,
      admin: null,
      stream_io_token: null
    };
  },
  admin: data => {
    return {
      type: authActions.ADMIN,
      first_name: data.first_name,
      last_name: data.last_name,
      profile_image: data.profile_image
    };
  }
};

export default authActions;
