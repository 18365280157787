import React, { useEffect, useState } from "react";
import UserWrapper from "./Practice.style";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";
import PracticeForm from "components/practice/PracticeForm";

const PracticeEdit = props => {
  const [editedData, setEditedData] = useState(null);
  const hasParam = props.match.params.id;

  useEffect(() => {
    if (hasParam) {
      setEditedData(props.location.state);
    }
  }, []);

  return (
    <UserWrapper {...props}>
      <div className="pos-relative">
        <PageTitle
          props={props}
          title="sidebar.practice"
          className="plr-15"
          breadCrumb={[
            {
              name: "sidebar.dashboard",
              url: "/dashboard"
            },
            {
              name: "sidebar.practice",
              url: "/practice"
            },
            {
              name: hasParam ? "action.edit" : "action.add"
            }
          ]}
        />
        <div
          className="back-icon fs-15 demi-bold-text cursor-pointer"
          onClick={() => props.history.push("/practice")}
        >
          <i className="fas fa-step-backward"></i> Back
        </div>
      </div>

      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> {hasParam ? "Edit" : "Add"}{" "}
              Practice
            </div>
          </div>
          <div className="roe-card-body">
            <PracticeForm
              editedData={editedData}
              action={hasParam ? "edit" : "add"}
              history={props.history}
            />
          </div>
        </div>
      </div>
    </UserWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(PracticeEdit);
