import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  // ChannelContext,
  useChannelStateContext,
  useChatContext
} from "stream-chat-react";

import "./MessagingChannelHeader.css";

import { TypingIndicator } from "../TypingIndicator/TypingIndicator";

import {
  ChannelInfoIcon,
  ChannelSaveIcon,
  HamburgerIcon
} from "../../../assets";
import CommonModel from "components/common/CommonModel";
import AwaySettingsModal from "./AwaySettingsModal";
import CommonServices from "api/CommonServices";
import EncryptDecrypt from "api/EncryptDecrypt";
import { PencilIcon } from "./EditPencil";
import Toaster from "components/common/Toaster";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const MessagingChannelHeader = props => {
  const { channel } = useChannelStateContext(); //useContext(ChannelContext);

  // channel.enableSlowMode(1);
  // channel.disableSlowMode();

  const { client } = useChatContext();
  const history = useHistory();
  const toaster = useRef(null);
  const inputRef = useRef();
  const [channelName, setChannelName] = useState(channel?.data.name || "");
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState("");
  const [modal, setModal] = useState(false);
  const [modalFromDash, setModalFromDash] = useState(
    props?.settingsOpen || false
  );
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!modal) {
      CommonServices.getAwaySettings()
        .then(res => {
          const data = EncryptDecrypt.decrypt(res.data);

          if (data.status) {
            setData(data?.data);
          } else {
            if (toaster.current) toaster.current.error(data.message);
          }
          setIsLoading(false);
        })
        .catch(err => {
          if (toaster.current)
            toaster.current.error(
              err?.response ? err?.response : "Something went wrong!"
            );
          setIsLoading(false);
        });
    }
  }, [modal]);

  useEffect(() => {
    if (!!data && modalFromDash) {
      setModal(true);
      history.replace();
    }
  }, [data]);

  const members = Object.values(channel.state?.members || {}).filter(
    member => member.user?.id !== client?.user?.id
  );

  const toggle = () => {
    setModal(prev => !prev);
  };

  const updateChannel = async e => {
    if (e) e.preventDefault();

    if (channelName && channelName !== channel.data.name) {
      await channel.update(
        { name: channelName },
        { text: `Channel name changed to ${channelName}` }
      );
    }
    setIsEditing(false);
  };

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  useEffect(() => {
    setTitle(
      members
        .map(member => member.user?.name || member.user?.id || "Unnamed User")
        .join(", ")
    );
  }, [channelName, members]);

  const EditHeader = () => (
    <form
      style={{ flex: 1 }}
      onSubmit={e => {
        e.preventDefault();
        inputRef.current.blur();
      }}
    >
      <input
        autoFocus
        className="channel-header__edit-input"
        onBlur={updateChannel}
        onChange={e => setChannelName(e.target.value)}
        placeholder="Type a new name for the chat"
        ref={inputRef}
        value={channelName}
      />
    </form>
  );

  return (
    <>
      <div className="messaging__channel-header">
        <div
          id="mobile-nav-icon"
          className={`${props.theme}`}
          onClick={() => props.toggleMobile()}
        >
          <HamburgerIcon />
        </div>
        {/* {getAvatarGroup(members)} */}
        {!isEditing ? (
          <div className="channel-header__name">{title || channelName}</div>
        ) : (
          <EditHeader />
        )}
        {!isLoading && !!data && (
          <div
            className="font-bold flex align-items-center"
            style={{ paddingInline: 8 }}
          >
            <div role="button cursor-pointer" style={{ paddingRight: 2 }}>
              Away Message:{" "}
              {!data?.is_away ? (
                <span style={{ color: "#AECE38" }}>OFF</span>
              ) : (
                <span style={{ color: "#df5151" }}>ON</span>
              )}
            </div>
            <PencilIcon
              height={18}
              width={18}
              className="cursor-pointer"
              onClick={toggle}
            />
          </div>
        )}
        {!isLoading && !data && (
          <div
            className="font-bold flex align-items-center"
            style={{ paddingInline: 8 }}
          >
            <div role="button cursor-pointer" style={{ paddingRight: 2 }}>
              Away Message:<span style={{ color: "#df5151" }}>Error</span>
            </div>
          </div>
        )}
        {!!isLoading && (
          <div
            className="font-bold flex align-items-center"
            style={{ paddingInline: 8 }}
          >
            <div role="button cursor-pointer" style={{ paddingRight: 2 }}>
              Away Message:
              <span style={{ color: "#9b9b9c", paddingLeft: 2 }}>
                Loading..
              </span>
            </div>
          </div>
        )}
        <div className="messaging__channel-header__right">
          <TypingIndicator />
          {/* {channelName !== "chargechat" &&
          (!isEditing ? (
            <ChannelInfoIcon {...{ isEditing, setIsEditing }} />
          ) : (
            <ChannelSaveIcon />
          ))} */}
        </div>
        <CommonModel
          modal={modal}
          toggle={toggle}
          size={"lg"}
          children={
            <AwaySettingsModal
              toggle={toggle}
              data={data}
              modalFromDash={modalFromDash}
              setModalFromDash={setModalFromDash}
            />
          }
          header={"Away Message Settings"}
        />
      </div>
      <Toaster ref={toaster} />
    </>
  );
};

export default React.memo(MessagingChannelHeader);
