import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .trim()
      .required("This field is required")
      .max(100, "Name must be less than 100 characters")
  }),
  mapPropsToValues: props => ({
    action: props.action,
    id: props.editedData
      ? props && props.editedData && props.editedData.id
      : "",
    name: props.editedData
      ? props && props.editedData && props.editedData.name
      : ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
