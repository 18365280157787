import { withFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    group_id: Yup.number().required("This field is required"),
    group_visit_program_id: Yup.number().required("This field is required"),
    benefit_start: Yup.date()
      .required("This field is required")
      .nullable(),
    benefit_end: Yup.date()
      .required("This field is required")
      .nullable()
      .min(Yup.ref("benefit_start"), "End Date can't be before Start Date")
  }),
  mapPropsToValues: props => ({
    group_id:
      props &&
      props.editedData &&
      props.editedData.program_detail &&
      props.editedData.program_detail.group_id
        ? props.editedData.program_detail.group_id
        : "",
    benefit_start:
      props &&
      props.editedData &&
      props.editedData.program_detail &&
      props.editedData.program_detail.benefit_start_date
        ? new Date(moment(props.editedData.program_detail.benefit_start_date))
        : "",
    benefit_end:
      props &&
      props.editedData &&
      props.editedData.program_detail &&
      props.editedData.program_detail.benefit_end_date
        ? new Date(moment(props.editedData.program_detail.benefit_end_date))
        : "",
    group_visit_program_id:
      props &&
      props.editedData &&
      props.editedData.program_detail &&
      props.editedData.program_detail.group_visit_program_id
        ? props.editedData.program_detail.group_visit_program_id
        : ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
