import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import RDServices from "../../api/RdServices";
import Loader from "../common/Loader";
import CommonModel from "../common/CommonModel";
// import DeleteModal from "../common/DeleteModal";
//import Moment from "react-moment";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
import Toaster from "../common/Toaster";
import {
  APPOINTMENT_STATUS_LABEL,
  MODULE_NAMES,
  PERMISSION_TYPE,
  TABLE
} from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import moment from "moment-timezone";
import ClientServices from "../../api/ClientServices";

import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import CategoryServices from "api/CategoryServices";
import { hasAccess } from "util/sidebar";
const monthArr = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12"
];
const filterArr = [
  {
    id: 0,
    label: "All"
  },
  {
    id: 1,
    label: "Month"
  },
  {
    id: 2,
    label: "Date"
  }
];

const AppointmentScheduleTable = props => {
  const { permissions } = props;
  const toaster = useRef(null);
  const [tblData, settblData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("created_at");
  const [sortBy, setSortBy] = useState("DESC");
  const [parentDropdownOpen, setParentDropdownOpen] = useState(false);
  const [parentCategoryId, setParentCategoryId] = useState(null);
  const [filterIndex, setFilterIndex] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [RDDropdownOpen, setRDDropdownOpen] = useState(false);
  const [RDId, setRDId] = useState(null);
  const [RDName, setRDName] = useState(null);
  const [RDList, setRDList] = useState([]);
  const [dateRange, setDateRange] = useState({});
  const [serviceId, setServiceId] = useState(null);
  const [serviceName, setServiceName] = useState(null);
  const [modal, setModal] = useState(undefined);
  const [serviceList, setServiceList] = useState([]);
  const [serviceDropdownOpen, setServiceDropdownOpen] = useState(false);

  useEffect(() => {
    CategoryServices.serviceDropdown()
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setServiceList([...data.data]);
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      });
  }, []);

  useEffect(() => {
    const params1 = {
      type: "",
      practice_id:
        props.auth && props.auth?.admin_role_id === 6 && props.auth?.practice
          ? props.auth?.practice
          : undefined,
      id: props.auth?.service_id
        ? props.auth?.service_id
        : serviceId
        ? serviceId
        : undefined
    };
    ClientServices.RDList(params1).then(result => {
      let data = EncryptDecrypt.decrypt(result.data);
      setRDList(data.data);
    });
    if (props.auth && props.auth.admin_role_id === 3) setRDId(props.auth.id);
    // getList(field, sortBy, searchText, activePage + 1);
  }, [serviceId]);

  useEffect(() => {
    if (RDId || (!RDId && RDName === "All")) {
      getList(field, sortBy, searchText, activePage + 1);
      setPages(10);
    }
  }, [RDId]);

  useEffect(() => {
    getList(field, sortBy, searchText, activePage + 1);
    setPages(10);
  }, [serviceId]);

  useEffect(() => {
    if (dateRange.startDate && dateRange.endDate) {
      getList(field, sortBy, searchText, activePage + 1);
      setParentCategoryId(null);
    }
  }, [dateRange.startDate, dateRange.endDate]);

  useEffect(() => {
    if (searchText) {
      getList(field, sortBy, searchText, activePage + 1);
      setParentCategoryId(null);
    }
  }, [searchText]);

  useEffect(() => {
    if (parentCategoryId) {
      getList(field, sortBy, searchText, activePage + 1);
      setStartDate(null);
      setEndDate(null);
    }
  }, [parentCategoryId]);

  // useEffect(() => {
  //   if (filterIndex === 0) {
  //     setStartDate(null);
  //     setParentCategoryId(null);
  //     setEndDate(null);
  //     getList(field, sortBy, searchText, activePage + 1);
  //   }
  // }, [filterIndex]);

  const toggleRD = () => setRDDropdownOpen(prevState => !prevState);

  const toggleOne = () => setParentDropdownOpen(prevState => !prevState);

  const toggleMenu = () => setMenuOpen(prevState => !prevState);

  const toggleService = () => setServiceDropdownOpen(prevState => !prevState);

  const columns = [
    {
      Header: "Id",
      accessor: "id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 100
    },

    {
      Header: "Client Name",
      accessor: "user.first_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200,
      Cell: props =>
        props.original.user
          ? props.original.user.first_name + " " + props.original.user.last_name
          : ""
    },
    {
      Header: "Meeting",
      accessor: "appointment_type",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 300,
      Cell: props => {
        return props.original.appointment_type === "Manual"
          ? "Manual"
          : props.original.event_detail && props.original.event_detail.name
          ? `${props.original.event_detail.name}(Calendly)`
          : "";
      }
    },
    {
      Header: "Appointment Date",
      accessor: "appointment_date",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 180,
      Cell: props =>
        props.original.appointment_type === "Manual"
          ? moment
              .tz(
                moment(
                  props.original.appointment_date +
                    " " +
                    props.original.appointment_time
                ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                moment.tz.guess()
              )
              .format("MM/DD/YYYY")
          : moment
              .tz(props.original.event_detail.start_time, moment.tz.guess())
              .format("MM/DD/YYYY")
    },
    {
      Header: "Appointment Time",
      accessor: "appointment_time",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 180,
      Cell: props =>
        `${
          props.original.appointment_type === "Manual"
            ? moment
                .tz(
                  moment(
                    props.original.appointment_date +
                      " " +
                      props.original.appointment_time
                  ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                  moment.tz.guess()
                )
                .format("h:mm A")
            : moment
                .tz(props.original.event_detail.start_time, moment.tz.guess())
                .format("h:mm A")
        } (${moment()
          .tz(moment.tz.guess())
          .zoneAbbr()})`
    },
    {
      Header: "Status",
      accessor: "status",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 150,
      Cell: props => APPOINTMENT_STATUS_LABEL[props.original.status]
    },
    {
      Header: "Action",
      accessor: "action",
      className: "",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 200,
      Cell: props => (
        <div className="react-action-class">
          <Button
            className="c-btn c-info mr-10"
            onClick={() => formAction("view", props.original)}
          >
            <div className="fs-14 medium-text" title="View">
              <i className="fas fa-eye" />
            </div>
          </Button>
          <Button
            className="c-btn c-success mr-10"
            onClick={() => formAction("visit", props.original)}
          >
            <div className="fs-14 medium-text " title="View">
              <i className="fas fa-calendar-check mr-2" />
              Go to Visit
            </div>
          </Button>
        </div>
      ) // Custom cell components!
    }
  ];

  if (props.auth && props.auth.admin_role_id !== 3) {
    let element = {
      Header: "Provider",
      accessor: "admin.first_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200,
      Cell: props =>
        props.original.admin
          ? props.original.admin.first_name +
            " " +
            props.original.admin.last_name
          : ""
    };
    let serviceElement = {
      Header: "Service",
      accessor: "service.name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 120,
      Cell: props =>
        props?.original?.service?.name ? props?.original?.service?.name : ""
    };
    columns.splice(1, 0, element);
    columns.splice(2, 0, serviceElement);
  }

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };
  const formAction = (action, data = null) => {
    // Here you can view the data and make forward action for edit data
    if (action === "view") {
      props.history.push(`/appointment/view/${data.id}`, data);
    }
    if (action === "visit") {
      props.history.push(`/client/view/${data?.user?.id}?visit`, data);
    }
  };

  function getList(field = "title", sortBy = "DESC", search = "", page = "1") {
    const params = {
      sort_by: field,
      order: sortBy,
      search: search,
      page: page,
      start_date: dateRange.startDate
        ? moment(dateRange.startDate).format("YYYY-MM-DD")
        : null,
      end_date: dateRange.endDate
        ? moment(dateRange.endDate).format("YYYY-MM-DD")
        : null,
      month: parentCategoryId ? parentCategoryId : null,
      rd_id:
        props.auth && props.auth.admin_role_id !== 3 ? RDId : props.auth.id,
      service_id:
        props?.auth?.admin_role_id > 2 ? props?.auth?.service_id : serviceId,
      practice_id:
        props.auth && props.auth?.admin_role_id === 6 && props.auth?.practice
          ? props.auth?.practice
          : undefined,
      timezone: moment.tz.guess()
    };

    setLoader(true);
    RDServices.appointmentScheduleList(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);

        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count / TABLE.LIMIT));
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
        setLoader(false);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.message);
        setLoader(false);
      });
  }
  const openCalendly = () => {
    window.open(props.calendly_link, "_target");
  };

  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);
    setModal(!open);
  };
  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Appointment List
            </div>
            {/* <div className="d-flex ">
              <div className="col-sm-8">
                <Dropdown isOpen={menuOpen} toggle={toggleMenu}>
                  <DropdownToggle caret size="md">
                    {filterIndex > 0
                      ? filterArr[filterIndex].label
                      : "Filter By"}
                  </DropdownToggle>
                  <DropdownMenu>
                    {filterArr.map((result, i) => {
                      return (
                        <DropdownItem
                          key={i}
                          onClick={() => setFilterIndex(result.id)}
                        >
                          {result.label}
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div> */}

            {/* {filterIndex === 2 ? ( */}
            <div>
              <div className=" d-flex ml-10">
                {/* <DatePicker
                  selected={startDate}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  onChange={date => setStartDate(date)}
                  placeholderText="Select start date"
                  className="form-control react-form-input"
                />

                <DatePicker
                  selected={endDate}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  onChange={date => setEndDate(date)}
                  placeholderText="Select end date"
                  className="form-control react-form-input"
                /> */}

                <div className="d-flex align-items-center flex-1 fs-16 demi-bold-text">
                  <span className="hash"># </span>
                  {dateRange.startDate &&
                    `${moment(dateRange.startDate).format(
                      "MM/DD/YYYY"
                    )} -${moment(dateRange.endDate).format("MM/DD/YYYY")}`}
                </div>
                <Button
                  className="c-btn c-primary ma-5"
                  onClick={() => toggle()}
                >
                  Select Date / Month
                </Button>
                {dateRange && dateRange.startDate && (
                  <Button
                    className="c-btn c-primary ma-5"
                    onClick={() => {
                      setActivePage(0);
                      setDateRange({});
                    }}
                  >
                    Reset date
                  </Button>
                )}
              </div>
              {/* <Button
                  className="c-btn c-primary ma-5"
                  onClick={() => toggle()}
                >

                  Select date
            </Button> */}
            </div>
            {/* ) : filterIndex === 1 ? (
              <div className="d-flex ">
                <div className="col-sm-8">
                  <Dropdown isOpen={parentDropdownOpen} toggle={toggleOne}>
                    <DropdownToggle caret size="md">
                      {parentCategoryId
                        ? monthArr[parentCategoryId - 1]
                        : "Select Month"}
                    </DropdownToggle>
                    <DropdownMenu>
                      {monthArr.map((result, i) => {
                        return (
                          <DropdownItem
                            key={i}
                            onClick={() => setParentCategoryId(i + 1)}
                          >
                            {result}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
            ) : null} */}
            {props.auth && props.auth.admin_role_id <= 2 && (
              <div className="fs-14  mr-10">
                {/* <span className="fs-16">Service</span> */}
                <Dropdown isOpen={serviceDropdownOpen} toggle={toggleService}>
                  <DropdownToggle caret size="md">
                    {serviceId ? serviceName : "Service"}
                  </DropdownToggle>
                  <DropdownMenu
                    modifiers={{
                      setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: data => {
                          return {
                            ...data,
                            styles: {
                              ...data.styles,
                              overflow: "auto",
                              maxHeight: "300px"
                            }
                          };
                        }
                      }
                    }}
                  >
                    <DropdownItem
                      key="-1"
                      onClick={() => {
                        setActivePage(0);
                        setRDId("");
                        setRDName("");
                        setServiceId("");
                        setServiceName("All");
                      }}
                    >
                      All
                    </DropdownItem>
                    {serviceList.length > 0 &&
                      serviceList.map((result, i) => {
                        return (
                          <DropdownItem
                            key={i}
                            onClick={() => {
                              setActivePage(0);
                              setRDId("");
                              setRDName("");
                              setServiceId(result.id);
                              setServiceName(`${result.name}`);
                            }}
                          >
                            {result.name}
                          </DropdownItem>
                        );
                      })}
                  </DropdownMenu>
                </Dropdown>
              </div>
            )}
            {props.auth && props.auth.admin_role_id !== 3 && (
              <div className="fs-14  mr-10">
                <Dropdown isOpen={RDDropdownOpen} toggle={toggleRD}>
                  <DropdownToggle caret size="md">
                    {RDId ? RDName : "Providers"}
                  </DropdownToggle>
                  <DropdownMenu
                    modifiers={{
                      setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: data => {
                          return {
                            ...data,
                            styles: {
                              ...data.styles,
                              overflow: "auto",
                              maxHeight: "300px"
                            }
                          };
                        }
                      }
                    }}
                  >
                    <DropdownItem
                      key="-1"
                      onClick={() => {
                        setActivePage(0);
                        setRDId("");
                        setRDName("All");
                      }}
                    >
                      All
                    </DropdownItem>
                    {RDList.length > 0 &&
                      RDList.map((result, i) => {
                        return (
                          <DropdownItem
                            key={i}
                            onClick={() => {
                              setActivePage(0);
                              setRDId(result.id);
                              setRDName(
                                `${result.first_name} ${result.last_name}`
                              );
                            }}
                          >
                            {result.first_name} {result.last_name}
                          </DropdownItem>
                        );
                      })}
                  </DropdownMenu>
                </Dropdown>
              </div>
            )}
            <div className="mr-10">
              <input
                value={searchText}
                onChange={e => {
                  setSearchText(e.target.value);
                  const search = e.target.value;
                  setLoader(true);
                  setActivePage(0);
                  setTimeout(() => {
                    getList(field, sortBy, search, 1);
                  }, 500);
                }}
                type="text"
                placeholder="Search..."
                className="fs-14 medium-text plr-10 form-control react-form-input"
              />
            </div>
            {hasAccess(
              MODULE_NAMES.APPOINTMENT,
              PERMISSION_TYPE.ADD,
              permissions
            ) &&
              !!props.calendly_link && (
                <div>
                  <Button
                    className="c-btn c-primary ma-5"
                    onClick={() => openCalendly()}
                  >
                    <i className="fas fa-plus mr-10" />
                    Add Appointment
                  </Button>
                </div>
              )}
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              LoadingComponent={Loader}
              onFetchData={onChangePageGetData}
              onPageChange={pageIndex => {
                setActivePage(pageIndex);
                getList(field, sortBy, searchText, pageIndex + 1);
              }}
              onSortedChange={sortProperties => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getList(sortProperties[0].id, sort, searchText, 1);
                // alert("sort handler", sortProperties);
                // const orderByDirection = sortProperties[0].desc ? 1 : 0;

                // updateMapStateInfoOrderByColumn(sortProperties[0].id, orderByDirection);
              }}
            />
          </div>
          <CommonModel
            modal={modal}
            toggle={toggle}
            size="lg"
            children={
              <DateRangePicker
                open={open}
                toggle={() => toggle()}
                onChange={range => {
                  setActivePage(0);
                  setDateRange(range);
                  // setModal(!modal)
                  toggle();
                }}
                closeOnClickOutside={true}
              />
            }
            // header={editedData ? "" : "Add Registration Code"}
          />
        </div>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};

export default withRouter(AppointmentScheduleTable);
