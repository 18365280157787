import React, { useRef } from "react";
import { compose } from "redux";

const FoodModel = props => {
  const { editedData } = props;

  return (
    <div className="form-group">
      <label className="fs-16 medium-text" style={{ wordBreak: "break-word" }}>
        {editedData?.visit_bill.advancemd_logs?.length > 0
          ? editedData?.visit_bill?.advancemd_logs[0]?.custom_message
          : ""}
      </label>
      <hr></hr>
      <label className="fs-16 medium-text" style={{ wordBreak: "break-word" }}>
        {editedData?.visit_bill.advancemd_logs?.length > 0
          ? editedData?.visit_bill?.advancemd_logs[0]?.response
          : ""}
      </label>
    </div>
  );
};

export default compose(FoodModel);
