import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const CommonModel = ({
  modal,
  toggle,
  className,
  children,
  size,
  style,
  header
}) => {
  return (
    <div>
      <div>
        <Modal
          centered
          isOpen={modal}
          toggle={toggle}
          className={className}
          size={size}
          style={style}
        >
          {header ? <ModalHeader>{header}</ModalHeader> : ""}
          <ModalBody>{children}</ModalBody>
        </Modal>
      </div>
    </div>
  );
};

export default CommonModel;
