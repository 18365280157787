import axios from "axios";
import { baseUrl } from "../helper/constant";
import Header from "./Header";
import EncryptDecrypt from "./EncryptDecrypt";

export default class ClientManagementForm {
  static list(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/form/list-user-forms`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static form_list(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/form/list-forms`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static formData(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/form/detail`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static reassign_form(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/form/reassign-form`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static getStaticPage(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/admin/view-static-content`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static deleteForm(params) {
    Header.setHeaders();
    return axios.delete(
      `${baseUrl}/form/delete-user-form`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }
}
