import CommonModel from "components/common/CommonModel";
import React, { useEffect, useRef, useState } from "react";
import ViewVisitNote from "../../views/VisitNote/VisitNote";
import EncryptDecrypt from "api/EncryptDecrypt";
import VisitServices from "api/VisitService";
import { TABLE } from "helper/constant";
import ResizableDraggableDiv from "views/VisitNote/VisitNoteDrag";
import EditVisitNote from "./EditVisitNote";

export const ViewAddEditVisitNote = props => {
  const { viewNoteModal, toggle, data, pageRefresh } = props;

  const [tblData, settblData] = useState();
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [displayOnFormText, setDisplayOnFormText] = useState("");
  const [field, setField] = useState("id");
  const [sortBy, setSortBy] = useState("ASC");
  const [id, setId] = useState(undefined);
  const [modal, setModal] = useState(undefined);
  const [editedData, setEditedData] = useState(undefined);
  const [defaultNote, setDefaultNote] = useState(undefined);
  const toaster = useRef();

  useEffect(() => {
    getList(field, sortBy, searchText, activePage + 1, displayOnFormText);
    setPages(10);

    VisitServices.visitNoteDefaultNote()
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setDefaultNote(data.data.content);
        } else {
          setDefaultNote("");
        }
      })
      .catch(error => {
        console.log("handleNote -> error", error);
      });
  }, []);

  function toggleView() {
    setModal(false);
  }

  function getList(
    field = "created_at",
    sortBy = "DESC",
    search = "",
    page = "1"
  ) {
    const params = {
      sort_by: field,
      order: sortBy,
      search: search,
      page: page,
      calendly_appointment_id: data.id
    };
    setLoader(true);
    VisitServices.visitNoteList(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count / TABLE.LIMIT));
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });
  }

  return (
    <>
      {viewNoteModal && (
        <CommonModel
          modal={viewNoteModal}
          toggle={toggle}
          size="xl"
          children={
            <ViewVisitNote
              data={data}
              toggle={toggle}
              pageRefresh={pageRefresh}
              tblData={tblData}
              loader={loader}
              editedData={editedData}
              setEditedData={setEditedData}
              getList={getList}
              defaultNote={defaultNote}
              pages={pages}
              setPages={setPages}
              setActivePage={setActivePage}
              activePage={activePage}
              displayOnFormText={displayOnFormText}
              field={field}
              setField={setField}
              id={id}
              setId={setId}
              modal={modal}
              setModal={setModal}
              setSortBy={setSortBy}
              sortBy={sortBy}
              searchText={searchText}
              {...props}
            />
          }
        />
      )}
      {modal && (
        <ResizableDraggableDiv
          children={
            <EditVisitNote
              editedData={editedData}
              setEditedData={setEditedData}
              defaultNote={defaultNote}
              calendlyId={data.id}
              toggle={toggleView}
              pageRefresh={() => {
                getList(field, sortBy, searchText, activePage + 1);
                setPages(10);
              }}
              adminRole={props?.auth?.admin_role_id}
              id={id}
            />
          }
          header={
            editedData
              ? "Edit Note" + " - " + props?.getClientHeader
              : "Add Note" + " -  " + props?.getClientHeader
          }
          {...props}
        />
      )}
    </>
  );
};
