import axios from "axios";
import { baseUrl } from "../helper/constant";
import Header from "./Header";
import EncryptDecrypt from "./EncryptDecrypt";

export default class AdminServices {
  static login(params) {
    return axios.post(`${baseUrl}/admin/login`, EncryptDecrypt.encrypt(params));
  }

  static mfa(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/admin/verify-mfa`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static setupMfa(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/admin/setup-mfa`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static update(params) {
    Header.setHeaders();
    return axios.put(`${baseUrl}/admin/edit-profile`, params);
  }

  static forgetPassword(email) {
    Header.setHeaders();
    return axios.get(`${baseUrl}/admin/forgot-password/${email}`);
  }

  static otp(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/admin/verify-otp`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static resetpassword(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/admin/reset-password`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static changepassword(params) {
    Header.setHeaders();
    return axios.put(
      `${baseUrl}/admin/change-password`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static logout() {
    Header.setHeaders();
    return axios.delete(`${baseUrl}/admin/logout`);
  }

  static setPassword(params) {
    return axios.post(
      `${baseUrl}/sub-admin/set-password`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static registerInGetStream() {
    Header.setHeaders();
    return axios.get(`${baseUrl}/admin/register-admin-getstream`);
  }
}
