import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    code: Yup.string()
      .trim("Cannot include leading and trailing spaces")
      .strict(true)
      .required("This field is required")
      .max(50, "Code must be less than 50 characters")
      .matches(/^[a-zA-Z0-9]*$/, "Cannot contain whitespaces"),
    description: Yup.string()
      .trim()
      .required("This field is required")
      .max(100, "Description must be less than 50 characters")
  }),
  mapPropsToValues: props => ({
    id: props.editedData
      ? props && props.editedData && props.editedData.id
      : "",
    code: props.editedData
      ? props && props.editedData && props.editedData.code
      : "",
    description: props.editedData
      ? props && props.editedData && props.editedData.description
      : ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
