import React, { Fragment, useRef, useState, useEffect } from "react";
import { compose } from "redux";
import enhancer from "../viewclient/program-details-validator";
import Button from "../button/Button";
import Toaster from "../common/Toaster";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import { Input } from "reactstrap";
import DatePicker from "react-datepicker";
import ClientServices from "../../api/ClientServices";
import moment from "moment";
import { MODULE_NAMES, PERMISSION_TYPE } from "helper/constant";
import { hasAccess } from "util/sidebar";

const PerformanceModal = props => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    submitCount,
    onSubmit1,
    values,
    action,
    groupList,
    permissions
  } = props;

  const [visitProgramList, setVisitProgramList] = useState([]);

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  useEffect(() => {
    const param = {
      group_id: values.group_id
    };
    ClientServices.listVisitProgramByGroup(param)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setVisitProgramList(data.data);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });
  }, []);

  const toaster = useRef();

  const hasParam = props.match.params.id;

  const changeHandleChange = e => {
    const param = {
      group_id: e.currentTarget.value
    };
    ClientServices.listVisitProgramByGroup(param)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setVisitProgramList(data.data);
          setFieldValue("group_visit_program_id", "");
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });
    ClientServices.viewGroup(param)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setFieldValue(
            "benefit_start",
            new Date(moment(data.data.group_benefits[0].start_date))
          );
          setFieldValue(
            "benefit_end",
            new Date(moment(data.data.group_benefits[0].end_date))
          );
          setFieldValue("group_id", data.data.id);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });
  };

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;

    if (isValid) {
      onSubmit1(values, "edit");
      const params = {
        user_id: hasParam,
        group_id: values.group_id,
        group_visit_program_id: values.group_visit_program_id,
        start_date: moment(values.benefit_start).format("YYYY-MM-DD"),
        end_date: moment(values.benefit_end).format("YYYY-MM-DD")
      };
      ClientServices.changeProgram(params)
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          if (data.status) {
            if (toaster.current) toaster.current.success(data.message);
          } else {
            if (toaster.current) toaster.current.error(data.message);
          }
        })
        .catch(err => {
          if (toaster.current) toaster.current.error(err.response);
        });
    }
    handleSubmit();
  };

  return (
    <form>
      <div className="form-group">
        <label className="fs-16 medium-text">Group ID</label>
        <input
          type="number"
          className="form-control react-form-input"
          value={values.group_id}
          disabled={true}
        />
      </div>
      <div className="form-group">
        <label className="fs-16 medium-text">
          Group Name<span className="asterisk">*</span>
        </label>
        <Input
          type="select"
          name="group_id"
          id="group_id"
          onChange={changeHandleChange}
          value={values.group_id}
          onBlur={handleBlur}
        >
          {groupList.map((type, i) => {
            return (
              <Fragment key={i}>
                <option key={`option${i}`} value={type.id}>
                  {type.name}
                </option>
              </Fragment>
            );
          })}
        </Input>
        <Error field="group_id" />
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="fs-16 medium-text">Benefit Start Date</label>
            <br />
            <DatePicker
              className="form-control"
              selected={values.benefit_start}
              dateFormat="MM/dd/yyyy"
              onChange={e => {
                setFieldValue("benefit_start", e);
              }}
              placeholder="Start Date"
            />
            <Error field="benefit_start" />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="fs-16 medium-text">Benefit End Date</label>
            <br />
            <DatePicker
              className="form-control"
              selected={values.benefit_end}
              dateFormat="MM/dd/yyyy"
              onChange={e => {
                setFieldValue("benefit_end", e);
              }}
              placeholder="End Date"
              minDate={new Date(values?.benefit_start)}
              disabled={!values.benefit_start}
            />
            <Error field="benefit_end" />
          </div>
        </div>
      </div>
      <div className="form-group">
        <label className="fs-16 medium-text">
          Program<span className="asterisk">*</span>
        </label>
        <Input
          type="select"
          name="group_visit_program_id"
          id="group_visit_program_id"
          onChange={handleChange}
          value={values.group_visit_program_id}
          onBlur={handleBlur}
        >
          <option value="">Select Program</option>
          {visitProgramList.map((type, i) => {
            return (
              <Fragment key={i}>
                <option key={`option${i}`} value={type.id}>
                  {type.visit_program.name}
                </option>
              </Fragment>
            );
          })}
        </Input>
        <Error field="group_visit_program_id" />
      </div>

      <div>
        <Button
          type="button"
          className="c-btn c-info form-button fs-16 demi-bold-text mr-10"
          style={{ maxWidth: "125px" }}
          onClick={() => handleSubmit()}
          disabled={
            !hasAccess(
              MODULE_NAMES.CLIENT_PROGRAM_DETAILS,
              PERMISSION_TYPE.EDIT,
              permissions
            )
          }
        >
          Submit
        </Button>
        <Button
          type="button"
          className="c-btn c-secondary form-button fs-16 demi-bold-text"
          style={{ maxWidth: "125px" }}
          onClick={props.toggle}
        >
          Close
        </Button>
      </div>
      <Toaster ref={toaster} />
    </form>
  );
};

export default compose(enhancer)(PerformanceModal);
