import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    type: Yup.string()
      .trim()
      .required("This field is required"),
    rd: Yup.string()
      .trim()
      .required("This field is required"),

    service: Yup.string()
      .trim()
      .required("This field is required"),
    referral_note: Yup.string()
      .trim()
      .required("This field is required")
  }),
  mapPropsToValues: props => ({
    type: "",
    rd: "",
    service: "",
    referral_note: "",
    internal_rd: ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
