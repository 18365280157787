import React from "react";

const TagDetail = props => {
  return (
    <div
      style={{
        padding: "5px 10px",
        background: "#aece38",
        color: "#fff",
        margin: "5px",
        borderRadius: "10px"
      }}
    >
      {props.tag}
    </div>
  );
};

export default TagDetail;
