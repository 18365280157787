import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: props => {
    return Yup.object().shape({
      title: Yup.string()
        .trim()
        .required("This field is required"),
      content:
        props?.editedData?.id && props?.editedData?.id != 5
          ? Yup.string()
              .trim()
              .required("This field is required")
          : Yup.number()
              .integer("Invalid number")
              .min(1, "Minimun value should be 1")
              .max(120, "Maximum value should be 120")
              .typeError("Must be number")
              .required("This field is required")
    });
  },
  mapPropsToValues: props => ({
    id: props.editedData
      ? props && props.editedData && props.editedData.id
      : "",
    content: props.editedData
      ? props && props.editedData && props.editedData.content
      : "",
    title: props.editedData
      ? props && props.editedData && props.editedData.title
      : ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
