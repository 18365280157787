import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import Loader from "../common/Loader";
import Toaster from "../common/Toaster";
import {
  FAX_STATUS,
  FAX_TYPE,
  MODULE_NAMES,
  PERMISSION_TYPE,
  TABLE
} from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import EfaxManagementServices from "api/EfaxMangementServices";
import moment from "moment";
import { hasAccess } from "util/sidebar";

const ArchivedTable = props => {
  const { permissions } = props;
  const [tblData, settblData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("received_at");
  const [sortBy, setSortBy] = useState("DESC");
  const toaster = useRef();

  useEffect(() => {
    if (hasAccess(MODULE_NAMES.EFAX_INBOX, PERMISSION_TYPE.VIEW, permissions)) {
      getList(field, sortBy, searchText, activePage + 1);
      setPages(10);
    }
  }, [props]);

  function updateStatus(props) {
    setLoader(true);
    const params = {
      fax_id: props.original.id,
      status: FAX_STATUS["UNREAD"]
    };

    EfaxManagementServices.updateStatus(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current) toaster.current.success(data.message);
          getList(field, sortBy, searchText, activePage + 1);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
        setLoader(false);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
        setLoader(false);
      });
  }

  const columns = [
    {
      Header: "ID",
      accessor: "id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 60,
      sortable: true
    },
    {
      Header: "To Number",
      accessor: "to_number",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 130,
      sortable: true,
      Cell: props =>
        props.original.to_number
          .toString()
          .replace(/\D+/g, "")
          .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
    },
    {
      Header: "From Number",
      accessor: "from_number",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 140,
      sortable: true,
      Cell: props =>
        props.original.from_number
          .toString()
          .replace(/\D+/g, "")
          .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
    },
    {
      Header: "Date Received",
      accessor: "received_at",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 150,
      sortable: true,
      Cell: props =>
        props.original.received_at
          ? moment(props.original.received_at).format("MM/DD/YYYY HH:mm")
          : ""
    },

    {
      Header: "Status",
      accessor: "status",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 90,
      sortable: true,
      Cell: props =>
        props?.original?.status === "archived" &&
        FAX_TYPE[`${props.original.status}`]
    },
    {
      Header: "Pages",
      accessor: "pages",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 90,
      sortable: true
    },
    {
      Header: "Category",
      accessor: "service.name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 120,
      sortable: false
    },
    {
      Header: "Notes",
      accessor: "notes",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 100,
      sortable: true
    },

    {
      Header: "Action",
      accessor: "action",
      className: "",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 200,
      Cell: props => (
        <div className="react-action-class">
          {hasAccess(
            MODULE_NAMES.EFAX_ARCHIVE,
            PERMISSION_TYPE.ADD,
            permissions
          ) && (
            <Button
              className="c-btn c-info mr-10"
              onClick={() => updateStatus(props)}
            >
              <div className="fs-14 medium-text" title="Move to Inbox">
                <i class="fas fa-inbox"></i>
              </div>
            </Button>
          )}
        </div>
      ) // Custom cell components!
    }
  ];

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
    }
  };

  function getList(
    field = "created_at",
    sortBy = "DESC",
    search = "",
    page = "1"
  ) {
    const params = {
      order: sortBy,
      sort_by: field,
      search: search,
      page: page,
      faxType: "archived",
      service_id:
        props?.auth?.admin_role_id > 2 ? props?.auth?.service_id : undefined
    };
    setLoader(true);

    EfaxManagementServices.list(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count / TABLE.LIMIT));
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
          setLoader(false);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
        setLoader(false);
      });
  }

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Fax Number List
            </div>
            <div className="mr-10">
              <input
                value={searchText}
                onChange={e => {
                  setSearchText(e.target.value);
                  const search = e.target.value;
                  setLoader(true);
                  setActivePage(0);
                  setTimeout(() => {
                    getList(field, sortBy, search, 1);
                  }, 500);
                }}
                type="text"
                placeholder="Search..."
                className="fs-14 medium-text plr-10 form-control react-form-input"
              />
            </div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              LoadingComponent={Loader}
              onFetchData={onChangePageGetData}
              onPageChange={pageIndex => {
                setActivePage(pageIndex);
                getList(field, sortBy, searchText, pageIndex + 1);
              }}
              onSortedChange={sortProperties => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getList(sortProperties[0].id, sort, searchText, 1);
              }}
            />
          </div>
        </div>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};

export default withRouter(ArchivedTable);
