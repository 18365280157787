import styled from "styled-components";

const ProfileWrapper = styled.div`
  h3 {
    color: #aece38;
    font-weight: bold;
    font-size: 2.2rem;
  }
  .profile-head {
    margin-bottom: 20px;
  }
  .calendly-btn {
    border: solid;
    border-radius: 50px;
    height: 60px;
    width: 60px;
    color: #aece38;
    cursor: pointer;
  }
`;
export default ProfileWrapper;
