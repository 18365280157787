import React, { useState, useRef } from "react";
import { compose } from "redux";
import enhancer from "./calendly-validator";
import Button from "../button/Button";
import Toaster from "../common/Toaster";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import RdServices from "../../api/RdServices";

const CalendlyModal = props => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    values,
    action
  } = props;

  const [loading, setLoading] = useState(false);
  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const toaster = useRef();

  const hasParam = props.match.params.id;

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;
    if (isValid) {
      setLoading(true);
      if (action === "add") {
        const params = {
          rd_id: Number(hasParam),
          event_name: values?.eventName,
          location: values?.location
        };
        RdServices.calendlyURLAdd(params)
          .then(res => {
            let data = EncryptDecrypt.decrypt(res.data);
            if (data.status) {
              if (toaster.current) toaster.current.success(data.message);
              setTimeout(() => {
                props.pageRefresh();
                props.toggle();
                setLoading(false);
              }, 1000);
            } else {
              if (toaster.current) toaster.current.error(data.message);
              setLoading(false);
            }
          })
          .catch(err => {
            if (toaster.current) toaster.current.error(err.response);
            setLoading(false);
          });
      } else if (action === "edit") {
        const params = {
          rd_calendly_url_id: values?.id,
          rd_id: Number(hasParam)
        };

        if (props?.editedData?.type === "main_url") {
          params.url = values?.url;
        } else {
          params.event_name = values?.eventName;
          params.location = values?.location;
        }

        RdServices.calendlyURLEdit(params)
          .then(res => {
            let data = EncryptDecrypt.decrypt(res.data);

            if (data.status) {
              if (toaster.current) toaster.current.success(data.message);
              setTimeout(() => {
                props.pageRefresh();
                props.toggle();
                setLoading(false);
              }, 1000);
            } else {
              if (toaster.current) toaster.current.error(data.message);
              setLoading(false);
            }
          })
          .catch(err => {
            if (toaster.current) toaster.current.error(err.response);
            setLoading(false);
          });
      }
    }

    handleSubmit();
  };

  return (
    <form>
      {action === "add" ||
      (action === "edit" && props.editedData.type !== "main_url") ? (
        <>
          <div className="form-group">
            <label className="fs-18 medium-text">
              Event Name <span className="asterisk">*</span>
            </label>
            <label className="fs-16">
              Copy and paste the event name from Calendly that you want to add
              as an initial in person event type. The event name must match
              exactly, please make sure to copy it direct from Calendly.
            </label>
            <br />
            <input
              type="text"
              className="form-control react-form-input"
              id="eventName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values?.eventName}
              placeholder="Add Event Name"
            />
            <Error field="eventName" />
          </div>

          <div className="form-group">
            <label className="fs-18 medium-text">
              Location <span className="asterisk">*</span>
            </label>
            <br />
            <input
              type="text"
              className="form-control react-form-input"
              id="location"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values?.location}
              placeholder="Add Location"
            />
            <Error field="location" />
          </div>
        </>
      ) : (
        <div className="form-group">
          <label className="fs-18 medium-text">
            URL <span className="asterisk">*</span>
          </label>
          <br />
          <input
            type="text"
            className="form-control react-form-input"
            id="url"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values?.url}
            placeholder="Add URL"
          />
          <Error field="url" />
        </div>
      )}
      <div>
        <Button
          type="button"
          className="c-btn c-info form-button fs-16 demi-bold-text mr-10"
          style={{ maxWidth: "125px" }}
          loading={loading}
          onClick={() => handleSubmit()}
          disabled={loading}
        >
          Submit
        </Button>
        <Button
          type="button"
          className="c-btn c-secondary form-button fs-16 demi-bold-text"
          style={{ maxWidth: "125px" }}
          onClick={props.toggle}
        >
          Close
        </Button>
      </div>
      <Toaster ref={toaster} />
    </form>
  );
};

export default compose(enhancer)(CalendlyModal);
