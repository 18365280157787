import axios from "axios";
import { baseUrl } from "../helper/constant";
import Header from "./Header";
import EncryptDecrypt from "./EncryptDecrypt";

export default class GroupServices {
  static list(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/group/list`, EncryptDecrypt.encrypt(params));
  }

  static add(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/group/add`, EncryptDecrypt.encrypt(params));
  }

  static edit(params) {
    Header.setHeaders();
    return axios.put(`${baseUrl}/group/edit`, EncryptDecrypt.encrypt(params));
  }

  static view(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/group/view`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static delete(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/group/delete`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static status(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/group/status`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static registrationCodeList(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/group/list-code`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static registrationCodeAdd(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/group/add-code`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static registrationCodeEdit(params) {
    Header.setHeaders();
    return axios.put(
      `${baseUrl}/group/edit-code`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static registrationCodeView(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/group/view-code`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static registrationCodeStatus(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/group/status-code`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static benefitPeriodList(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/group/list-benefit`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static benefitPeriodAdd(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/group/add-benefit`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static benefitPeriodEdit(params) {
    Header.setHeaders();
    return axios.put(
      `${baseUrl}/group/edit-benefit`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static benefitPeriodView(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/group/view-benefit`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static benefitAutoRenewStatus(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/group/auto-renew-benefit`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static benefitActiveInactiveStatus(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/group/status-benefit`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static insuranceList() {
    Header.setHeaders();
    return axios.get(`${baseUrl}/group/list-insurance`);
  }

  static groupInsuranceList(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/group/list-group-insurance`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static addGroupInsurance(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/group/add-insurance`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static listVisitProgram(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/group/list-visit-program`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static addVisitProgram(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/group/add-visit-program`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static editVisitProgram(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/group/edit-visit-program`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static listGroupVisitProgram(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/group/list-group-visit-program`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static visitProgramActiveInactiveStatus(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/group/status-visit-program`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static visitProgramView(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/group/view-visit-program`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static listScaleProgram() {
    Header.setHeaders();
    return axios.get(`${baseUrl}/group/list-scale-program`);
  }

  static addScaleProgram(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/group/add-scale-program`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static editScaleProgram(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/group/edit-scale-program`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static listGroupScaleProgram(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/group/list-group-scale-program`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static scaleProgramActiveInactiveStatus(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/group/status-scale-program`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static scaleProgramView(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/group/view-scale-program`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }
}
