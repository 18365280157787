import React, { useEffect, useRef, useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink, Button } from "reactstrap";
import classnames from "classnames";
import ProductMangementTable from "./ProductMangementTable";
import OrderManagementTable from "./OrderManagementTable";
import RewardsTierTable from "./RewardsTierTable";
import RewardsActivityTable from "./RewardsActivityTable";
import EncryptDecrypt from "api/EncryptDecrypt";
import RewardsManagementServices from "api/RewardsMangementServices";
import CommonModel from "components/common/CommonModel";
import CustomModal from "components/common/CustomModal";

const RewardsViewDetails = props => {
  const toaster = useRef();
  const [activeTab, setActiveTab] = useState("1");
  const [loader, setLoader] = useState(false);
  const [customModal, setCustomModal] = useState(false);
  const [status, setStatus] = useState(null);
  const [activeStatus, setActiveStatus] = useState(null);
  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    const params = {
      backend_name: "points_reward_system"
    };
    setLoader(true);
    RewardsManagementServices.getModuleStatus(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setActiveStatus(data?.data?.permission);
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch();
  }, []);

  function activeToggle() {
    setCustomModal(false);
  }

  const activeDeactive = data => {
    setStatus(data.is_active);
    setCustomModal(true);
  };

  const activeInactiveStatusHandler = oData => {
    let statusData = status;
    const params = {
      backend_name: "points_reward_system",
      status: statusData
    };
    RewardsManagementServices.updateRewardModuleStatus(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setActiveStatus(status);
          if (toaster.current)
            toaster.current.success(
              oData.is_active === true
                ? "Reward Module has been deactivated successfully."
                : "Reward Module has been activated successfully."
            );
          setCustomModal(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
          setCustomModal(false);
        }
      })
      .catch(err => {
        setCustomModal(false);
        if (toaster.current) toaster.current.error(err.response);
      });
  };

  return (
    <div className="plr-15">
      <div className="roe-card-style mtb-15">
        <div className="roe-card-header module-header">
          <div className="flex-1 fs-16 demi-bold-text cursor-pointer">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab === "1" },
                    "doc-title"
                  )}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  Products
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab === "2" },
                    "doc-title"
                  )}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  Orders
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab === "3" },
                    "doc-title"
                  )}
                  onClick={() => {
                    toggle("3");
                  }}
                >
                  Tier
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab === "4" },
                    "doc-title"
                  )}
                  onClick={() => {
                    toggle("4");
                  }}
                >
                  Rewards Activity
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          {props?.admin_role_id === 1 && (
            <div className="flex ">
              {loader ? (
                <div className="flex pr-4">Loading...</div>
              ) : (
                <div>
                  {activeStatus ? (
                    <div id={`active`}>
                      <Button
                        style={{ minWidth: "125px" }}
                        className="c-btn c-info mr-10"
                        onClick={() => activeDeactive({ is_active: false })}
                      >
                        <div className="fs-14 medium-text">
                          <i className="fas fa-toggle-off mr-6" /> Reward Module
                          Active
                        </div>
                      </Button>
                    </div>
                  ) : (
                    <div id={`deactive`}>
                      <Button
                        style={{ minWidth: "125px" }}
                        className="c-btn c-danger mr-10"
                        onClick={() => activeDeactive({ is_active: true })}
                      >
                        <div className="fs-14 medium-text">
                          <i className="fas fa-toggle-on mr-6" /> Reward Module
                          Deactivated
                        </div>
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        <div className="roe-card-body">
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <ProductMangementTable {...props} />
            </TabPane>
            <TabPane tabId="2">
              <OrderManagementTable {...props} />
            </TabPane>
            <TabPane tabId="3">
              <RewardsTierTable {...props} />
            </TabPane>
            <TabPane tabId="4">
              <RewardsActivityTable {...props} />
            </TabPane>
          </TabContent>
        </div>
      </div>
      <CommonModel
        modal={customModal}
        toggle={activeToggle}
        children={
          <CustomModal
            text={`${
              status === true
                ? "Are you sure you want to activate Rewards Module?"
                : "Are you sure you want to deactivate Rewards Module?"
            }`}
            activeInactiveStatusHandler={activeInactiveStatusHandler}
            activeToggle={activeToggle}
          />
        }
      />
    </div>
  );
};
export default RewardsViewDetails;
