import { withFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";

const formikEnhancer = withFormik({
  validationSchema: props =>
    Yup.object().shape({
      isAway: Yup.boolean().required("This field is required"), //
      message: Yup.string().when("isAway", {
        is: true,
        then: schema => schema.required("This field is required").nullable(),
        otherwise: schema => schema.nullable().notRequired()
      }),
      startDate: Yup.date()
        .when("isAway", {
          is: true,
          then: schema => schema.required("This field is required").nullable(),
          otherwise: schema => schema.nullable().notRequired()
        })
        .nullable(),
      endDate: Yup.date()
        .nullable()
        .when("isAway", {
          is: true,
          then: schema =>
            schema
              .when("startDate", (startDate, schema) =>
                startDate
                  ? schema.min(
                      moment(startDate).add(1, "days"),
                      "End time cannot be before start time"
                    )
                  : schema.min(
                      moment().add(1, "days"),
                      "Start Time is required"
                    )
              )
              .notRequired()
        })
    }),

  mapPropsToValues: props => ({
    startDate: props?.data?.start_date ? props?.data?.start_date : null,
    endDate: props?.data?.end_date ? props?.data?.end_date : null,
    message: props?.data?.message ? props?.data?.message : null,
    isAway: props?.data?.is_away ? props?.data?.is_away : false
  }),

  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
