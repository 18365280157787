import React, { Fragment, useState, useEffect, useRef } from "react";
import { compose } from "redux";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import ClientServices from "../../api/ClientServices";
import {
  GENDER,
  MODULE_NAMES,
  PERMISSION_TYPE,
  PLACEHOLDER,
  PREFER_LANG
} from "helper/constant";
import moment from "moment";
// import { Input } from "reactstrap";
import enhancer from "../viewclient/reassign-rd";
import Button from "../button/Button";
import Toaster from "../common/Toaster";
import { calculateAgeFromDOB } from "util/getAge";
import { hasAccess } from "util/sidebar";

const ClientBasicViewDetails = ({ editedData, ...props }) => {
  // const {
  //   handleChange,
  //   handleBlur,
  //   values,
  //   onSubmit,
  //   touched,
  //   submitCount,
  //   errors,
  //   setFieldValue,
  // } = props;

  // const [editedData, setEditedData] = useState(undefined);
  // const [RDList, setRDList] = useState([]);
  const { permissions } = props;
  const [sentCode, setSentCode] = useState(false);
  const hasParam = props.match.params.id;
  const toaster = useRef();

  // const Error = (props) => {
  //   const field1 = props.field;
  //   if ((errors[field1] && touched[field1]) || submitCount > 0) {
  //     return (
  //       <span className={props.class ? props.class : "error-msg"}>
  //         {errors[field1]}
  //       </span>
  //     );
  //   } else {
  //     return <span />;
  //   }
  // };

  // useEffect(() => {
  //   if (hasParam) {
  //     const params = {
  //       id: hasParam,
  //     };
  //     ClientServices.view(params).then((res) => {
  //       let data = EncryptDecrypt.decrypt(res.data);
  //       setEditedData(data.data);
  //     });

  //     // const params1 = {
  //     //   type: "assign",
  //     // };
  //     // ClientServices.RDList(params1).then((result) => {
  //     //   let data = EncryptDecrypt.decrypt(result.data);
  //     //   setRDList(data.data);
  //     // });
  //   }
  // }, []);

  // const handleSubmit = () => {
  //   let { values, isValid, handleSubmit } = props;

  //   if (isValid) {
  //     onSubmit(values, "", "1");
  //     const params = {
  //       rd_id: values.rd_id,
  //       user_id: hasParam,
  //     };
  //     ClientServices.assignRd(params)
  //       .then((res) => {
  //         let data = EncryptDecrypt.decrypt(res.data);
  //         if (data.status) {
  //           if (toaster.current) toaster.current.success(data.message);
  //           const params = {
  //             id: hasParam,
  //           };
  //           ClientServices.view(params).then((res) => {
  //             let data = EncryptDecrypt.decrypt(res.data);
  //             setEditedData(data.data);
  //           });
  //         } else {
  //           if (toaster.current) toaster.current.error(data.message);
  //         }
  //       })
  //       .catch((err) => {
  //         if (toaster.current) toaster.current.error(err.response);
  //       });
  //   }

  //   handleSubmit();
  // };

  const handleResend = () => {
    setSentCode(true);
    const params = {
      id: hasParam
    };
    ClientServices.resendOTP(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current) toaster.current.success(data.message);
          setTimeout(() => {
            setSentCode(false);
            window.location.reload();
          }, 2000);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current) toaster.current.error(err?.message);
        }
      });
  };

  return (
    <div className="row">
      <div className="col-md-6">
        <table style={{ width: "100%", lineHeight: "35px" }}>
          <tbody>
            <tr>
              <td
                style={{
                  fontWeight: "bolder",
                  textAlign: "left",
                  width: "50%"
                }}
              >
                Profile Image
              </td>
              <td>
                <img
                  className="top-header-profile-class"
                  style={{
                    height: "100px",
                    width: "100px",
                    marginBottom: "15px",
                    borderRadius: "10px",
                    marginTop: "10px"
                  }}
                  src={editedData && (editedData.profile_photo || PLACEHOLDER)}
                  alt="Profile Photo"
                />
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontWeight: "bolder",
                  textAlign: "left",
                  width: "50%"
                }}
              >
                ID
              </td>
              <td>{editedData && editedData.id}</td>
            </tr>
            <tr>
              <td
                style={{
                  fontWeight: "bolder",
                  textAlign: "left",
                  width: "50%"
                }}
              >
                First Name
              </td>
              <td>{editedData && editedData.first_name}</td>
            </tr>
            <tr>
              <td
                style={{
                  fontWeight: "bolder",
                  textAlign: "left",
                  width: "50%"
                }}
              >
                Last Name
              </td>
              <td>{editedData && editedData.last_name}</td>
            </tr>
            <tr>
              <td
                style={{
                  fontWeight: "bolder",
                  textAlign: "left",
                  width: "50%"
                }}
              >
                Email
              </td>
              <td>{editedData && editedData.email}</td>
            </tr>
            <tr>
              <td
                style={{
                  fontWeight: "bolder",
                  textAlign: "left",
                  width: "50%"
                }}
              >
                Phone Number
              </td>
              <td>{editedData && editedData.phone_number}</td>
            </tr>
            <tr>
              <td
                style={{
                  fontWeight: "bolder",
                  textAlign: "left",
                  width: "50%"
                }}
              >
                Date of Birth
              </td>
              <td>
                {editedData &&
                  editedData.dob &&
                  moment(editedData.dob).format("MM/DD/YYYY")}{" "}
                {editedData?.dob &&
                  `(${calculateAgeFromDOB(
                    moment(editedData?.dob).format("YYYY/MM/DD")
                  )})`}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontWeight: "bolder",
                  textAlign: "left",
                  width: "50%"
                }}
              >
                Gender
              </td>
              <td>
                {editedData &&
                  editedData.gender &&
                  GENDER[editedData.gender.toUpperCase()]}
              </td>
            </tr>
            {/* <tr>
              <td
                style={{
                  fontWeight: "bolder",
                  textAlign: "left",
                  width: "50%"
                }}
              >
                Category
              </td>
              <td>
                {editedData && editedData.category && editedData.category.name}
              </td>
            </tr> */}
            <tr>
              <td
                style={{
                  fontWeight: "bolder",
                  textAlign: "left",
                  width: "50%"
                }}
              >
                Street address
              </td>
              <td>{editedData && editedData.address}</td>
            </tr>
            <tr>
              <td
                style={{
                  fontWeight: "bolder",
                  textAlign: "left",
                  width: "50%"
                }}
              >
                City
              </td>
              <td>{editedData && editedData.city}</td>
            </tr>
            <tr>
              <td
                style={{
                  fontWeight: "bolder",
                  textAlign: "left",
                  width: "50%"
                }}
              >
                State
              </td>
              <td>{editedData && editedData.state && editedData.state.name}</td>
            </tr>
            <tr>
              <td
                style={{
                  fontWeight: "bolder",
                  textAlign: "left",
                  width: "50%"
                }}
              >
                Zipcode
              </td>
              <td>{editedData && editedData.zipcode}</td>
            </tr>
            <tr>
              <td
                style={{
                  fontWeight: "bolder",
                  textAlign: "left",
                  width: "50%"
                }}
              >
                Preferred Language
              </td>
              <td>
                {editedData && editedData?.language
                  ? PREFER_LANG[editedData?.language]
                  : "-"}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontWeight: "bolder",
                  textAlign: "left",
                  width: "50%"
                }}
              >
                Nutrition Registration Code
              </td>
              <td>
                {editedData?.rdDetails.find(detail => detail.service_id === 1)
                  ?.registration_code || "-"}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontWeight: "bolder",
                  textAlign: "left",
                  width: "50%"
                }}
              >
                MH Registration Code
              </td>
              <td>
                {" "}
                {editedData?.rdDetails.find(detail => detail.service_id === 2)
                  ?.registration_code || "-"}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontWeight: "bolder",
                  textAlign: "left",
                  width: "50%"
                }}
              >
                Verification Code
              </td>
              <td>{editedData && editedData.otp ? editedData.otp : "-"}</td>
              <td>
                {" "}
                <Button
                  type="button"
                  className="c-btn c-info form-button fs-16 demi-bold-text"
                  style={{ maxWidth: "125px" }}
                  disabled={sentCode}
                  onClick={() => handleResend()}
                >
                  Resend
                </Button>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontWeight: "bolder",
                  textAlign: "left",
                  width: "50%"
                }}
              >
                Emergency Contact Name
              </td>
              <td>
                {editedData && editedData?.emergency_name
                  ? editedData?.emergency_name
                  : "-"}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontWeight: "bolder",
                  textAlign: "left",
                  width: "50%"
                }}
              >
                Emergency Contact Phone
              </td>
              <td>
                {editedData && editedData?.emergency_phone_number
                  ? editedData?.emergency_phone_number
                  : "-"}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="col-md-6">
        <form>
          <div>
            {hasAccess(
              MODULE_NAMES.CLIENT,
              PERMISSION_TYPE.EDIT,
              permissions
            ) && (
              <Button
                className="c-btn c-success mr-50 float-right"
                onClick={() =>
                  props.history.push(`/client/edit/${editedData.id}`)
                }
              >
                <div className="fs-14 medium-text">
                  <i className="fas fa-edit" />
                </div>
              </Button>
            )}
          </div>

          {editedData?.user_referrals?.length > 0 ? (
            <div
              className="c-btn c-success   mr-10 float-right cursor-pointer"
              onClick={() => props.toggle("8")}
            >
              <i class="fas fa-sync pr-1"></i>
              <span>Referral</span>
            </div>
          ) : null}

          {/* <table style={{ width: "100%", lineHeight: "35px" }}>
            <tbody>
              <tr>
                <td colSpan={2}>
                  <Button
                    className="c-btn c-success mr-10 float-right"
                    onClick={() =>
                      props.history.push(`/client/edit/${editedData.id}`)
                    }
                  >
                    <div className="fs-14 medium-text">
                      <i className="fas fa-edit" />
                    </div>
                  </Button>
                </td>
              </tr>
              {props.auth && props.auth.admin_role_id <= 2 && (
                <>
                  <tr>
                    <td
                      style={{
                        fontWeight: "bolder",
                        textAlign: "left",
                        width: "50%"
                      }}
                    >
                      Current Provider
                    </td>
                    <td>
                      {editedData &&
                        editedData.admin &&
                        editedData.admin.first_name +
                          " " +
                          editedData.admin.last_name}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "bolder",
                        textAlign: "left",
                        width: "50%"
                      }}
                    >
                      {editedData && editedData.admin
                        ? "Reassign Provider"
                        : "Assign Provider"}
                    </td>
                    <td>
                      <Input
                        type="select"
                        name=""
                        id="rd_id"
                        onChange={handleChange}
                        value={values.rd_id}
                        onBlur={handleBlur}
                      >
                        <option value="">Select Provider</option>
                        {RDList.map((type, i) => {
                          return (
                            <Fragment key={i}>
                              <option key={`option${i}`} value={type.id}>
                                {type.first_name + " " + type.last_name}
                              </option>
                            </Fragment>
                          );
                        })}
                      </Input>
                      <Error field="rd_id" />
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <Button
                        type="button"
                        className="c-btn c-info form-button fs-16 demi-bold-text"
                        style={{ maxWidth: "125px" }}
                        onClick={() => handleSubmit()}
                      >
                        Submit
                      </Button>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table> */}
        </form>
        <Toaster ref={toaster} />
      </div>
    </div>
  );
};

export default compose(enhancer)(ClientBasicViewDetails);
