import Toaster from "components/common/Toaster";
import React, { useEffect, useRef, useState } from "react";
import Wrapper from "./Wrapper";
import ReactTable from "react-table";
import Button from "components/button/Button";
import Loader from "components/common/Loader";
import ExternalProviderServices from "api/ExternalProviderServices";
import EncryptDecrypt from "api/EncryptDecrypt";
import { TABLE } from "helper/constant";
import enhancer from "./AddProviderValidator";
import { compose } from "redux";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "reactstrap";
import RdServices from "api/RdServices";

const AddProviderModal = props => {
  const toaster = useRef(null);
  const [loader, setLoader] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [tblData, setTblData] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [stateName, setStateName] = useState(null);
  const [stateDropdownOpen, setStateDropdownOpen] = useState(false);
  const [stateCode, setStateCode] = useState(null);
  const [searchText, setSearchText] = useState("");
  const toggleState = () => setStateDropdownOpen(prevState => !prevState);

  const {
    handleChange,
    handleBlur,
    submitCount,
    errors,
    values,
    touched,
    setFieldValue,
    resetForm
  } = props;

  useEffect(() => {
    RdServices.stateList().then(res => {
      let data = EncryptDecrypt.decrypt(res.data);

      setStateList(data.data);
    });
  }, []);

  useEffect(() => {
    handleSearch(pageNo);
  }, [pageNo]);

  const formAction = params => {
    ExternalProviderServices.add({ provider_npi: params })
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current) toaster.current.success(data.message);
          props.pageRefresh();
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });
  };

  const handlePageChange = type => {
    if (type == "prev" && pageNo > 1) {
      setPageNo(pageNo => pageNo - 1);
    } else if (type == "next" && tblData.length >= 10) {
      setPageNo(pageNo => pageNo + 1);
    }
  };

  const handleResetForm = () => {
    resetForm();
    setStateCode("");
    setStateName("Select state");
  };

  const handleSearch = (pageNo = 1) => {
    let { values, isValid, handleSubmit } = props;
    if (isValid) {
      setLoader(true);
      setSearchText(true);
      const params = {
        first_name: values.first_name,
        last_name: values.last_name,
        provider_npi: values.provider_npi,
        state: values.state,
        page: pageNo,
        limit: TABLE.LIMIT
      };
      ExternalProviderServices.search(params)
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          if (data.status) {
            setTblData(data?.data);
            setLoader(false);
          } else {
            if (toaster.current) toaster.current.error(data.message);
            setLoader(false);
          }
        })
        .catch(err => {
          if (toaster.current) toaster.current.error("Something went wrong!");
          setLoader(false);
        });
    }
    handleSubmit();
  };

  const columns = [
    {
      Header: "NPI",
      accessor: "provider_npi",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",
      width: 120,
      sortable: false
    },
    {
      Header: "First Name",
      accessor: "first_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",
      width: 120,
      sortable: false
    },
    {
      Header: "Last Name",
      accessor: "last_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",
      width: 120,
      sortable: false
    },
    {
      Header: "Address",
      accessor: "address",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",
      width: 150,
      sortable: false
    },
    {
      Header: "City",
      accessor: "city",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 120,
      sortable: false
    },
    {
      Header: "State",
      accessor: "state",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",
      width: 60,
      sortable: false
    },
    {
      Header: "Postal Code",
      accessor: "zip",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",
      width: 120,
      sortable: false
    },
    {
      Header: "Category",
      accessor: "taxonomy",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class ",
      width: 200,
      sortable: false
    },

    {
      Header: "Action",
      accessor: "action",
      className: "",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 150,
      Cell: colProps => (
        <div className="react-action-class">
          <Button
            className="c-btn c-info mr-10"
            onClick={() => formAction(colProps.original.provider_npi)}
          >
            <div className="fs-14 medium-text">Add</div>
          </Button>
        </div>
      ) // Custom cell components!
    }
  ];

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <div className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </div>
      );
    } else {
      return <span />;
    }
  };

  return (
    <Wrapper>
      <div className="flex flex-y">
        <div className="flex flex-x align-items-center justify-content-between pb-20">
          <div className="font-bold fs-16">Add External provider</div>
          <div
            className="fs-15 demi-bold-text cursor-pointer"
            onClick={props.toggle}
          >
            <i class="fas fa-times"></i>
          </div>
        </div>

        <div className="flex-x space-between">
          <div className="flex-1 ">
            <div className="pr-2">
              <label className="fs-16 ">First name:</label>
              <input
                type="text"
                className="form-control react-form-input"
                id="first_name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.first_name}
              />
            </div>
          </div>

          <div className="flex-1  ">
            <div className="pr-2">
              <label className="fs-16 ">Last name:</label>
              <input
                type="text"
                className="form-control react-form-input"
                id="last_name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.last_name}
              />
            </div>
          </div>

          <div className="flex-1 ">
            <div className="pr-2">
              <label className="fs-16 ">NPI:</label>
              <input
                type="number"
                className="form-control react-form-input"
                id="provider_npi"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.provider_npi}
              />
            </div>
          </div>

          <div className="flex flex-1 flex-row">
            <div className="pr-2">
              <label className="fs-16 ">State</label>
              <Dropdown isOpen={stateDropdownOpen} toggle={toggleState}>
                <DropdownToggle caret size="md">
                  {stateCode ? stateName : "Select State"}
                </DropdownToggle>
                <DropdownMenu
                  modifiers={{
                    setMaxHeight: {
                      enabled: true,
                      order: 890,
                      fn: data => {
                        return {
                          ...data,
                          styles: {
                            ...data.styles,
                            overflow: "auto",
                            maxHeight: "300px"
                          }
                        };
                      }
                    }
                  }}
                >
                  <DropdownItem
                    onClick={() => {
                      setFieldValue("state", "");
                      setStateName("Select State");
                    }}
                  >
                    Select None
                  </DropdownItem>
                  {stateList.length > 0 &&
                    stateList.map((result, i) => {
                      return (
                        <DropdownItem
                          key={i}
                          onClick={() => {
                            setFieldValue("state", result.code);
                            setStateCode(result.code);
                            setStateName(`${result.name}`);
                          }}
                        >
                          {result.name}
                        </DropdownItem>
                      );
                    })}
                </DropdownMenu>
              </Dropdown>
            </div>
            <div className="align-self-end">
              {searchText && (
                <Button
                  type="button"
                  className="c-primary c-btn form-button fs-16 demi-bold-text"
                  onClick={() => {
                    setSearchText(false);
                    handleResetForm();
                  }}
                  title="Undo/Clear"
                >
                  Clear
                </Button>
              )}
            </div>
          </div>

          <div className="flex-1 align-self-end">
            <Button
              type="button"
              className="c-btn c-info form-button fs-16 demi-bold-text"
              style={{ maxWidth: "125px" }}
              onClick={() => {
                setPageNo(1);
                handleSearch();
              }}
              disabled={
                !values?.first_name &&
                !values?.last_name &&
                !values?.provider_npi
              }
            >
              Search
            </Button>
          </div>
        </div>

        <div className="flex-x space-between">
          <div className="flex-1">
            <Error field="first_name" />
          </div>
          <div className="flex-1">
            <Error field="last_name" />
          </div>
          <div className="flex-1">
            <Error field="provider_npi" />
          </div>
          <div className="flex-1">
            <Error field="state" />
          </div>
          <div className="flex-1"></div>
        </div>

        <div className="roe-card-body">
          <ReactTable
            style={{
              border: "none",
              boxShadow: "none"
            }}
            data={tblData}
            columns={columns}
            minRows={2}
            manual
            showPaginationBottom={false}
            loading={loader}
            LoadingComponent={Loader}
          />
          <div className="flex flex-x right  " style={{ paddingRight: "30px" }}>
            <div
              className="cursor-pointer"
              style={{ paddingRight: "24px" }}
              title="Prev"
            >
              <i
                class="fas fa-backward"
                onClick={() => handlePageChange("prev")}
              ></i>
            </div>
            <div className="cursor-pointer" title="Next">
              <i
                class="fas fa-forward"
                onClick={() => handlePageChange("next")}
              ></i>
            </div>
          </div>
        </div>
      </div>

      <Toaster ref={toaster} />
    </Wrapper>
  );
};

export default compose(enhancer)(AddProviderModal);
