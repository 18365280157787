import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { compose } from "redux";
import enhancer from "./validator";
import Button from "../button/Button";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import ServiceLocationServices from "../../api/ServiceLocationServices";
import Toaster from "../common/Toaster";
import CategoryServices from "api/CategoryServices";
import Select from "react-select";
import Loader from "components/common/Loader";

const typeList = [
  { value: "ibo", label: "IBO" },
  { value: "employee", label: "Employee" },
  { value: "ibo practice", label: "IBO Practice" }
];

// const typeListMH = [{ value: "employee", label: "Employee" }]; Commented for future Ref

const ServiceLocationForm = props => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    onSubmit,
    values,
    action,
    editedData,
    setFieldValue
  } = props;

  const toaster = useRef();
  const [serviceList, setServiceList] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setLoading(true);
    CategoryServices.serviceDropdown()
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setServiceList([...data.data]);
          setLoading(false);
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      });
  }, []);

  useEffect(() => {
    if (editedData && serviceList?.length) {
      const convertedData = [...editedData?.meta];
      convertedData.forEach(el => {
        const convertedType = [];

        el &&
          el.type[0] &&
          el.type.length &&
          el.type.forEach(el => {
            convertedType.push({ value: el, label: el.toUpperCase() });
          });
        el.type = convertedType;
      });
      setFieldValue("meta", [...convertedData]);
      setData([...convertedData]);
    }
  }, [editedData, serviceList]);

  const typeErrors = useMemo(() => {
    if (data?.length) {
      const index = data.findIndex(el => !el?.type.length);
      return index !== -1;
    } else {
      return false;
    }
  }, [data]);

  const getTypesByService = serviceId => {
    if (data?.length) {
      const index = data.findIndex(
        el => Number(el?.service_id) === Number(serviceId)
      );
      if (index !== -1) {
        return data[index]?.type?.length ? data[index]?.type : null;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const handleDataChange = ({ id, types = [], action }) => {
    let data1 = [...data];
    const index = data1.findIndex(obj => Number(obj.service_id) === id);

    if (index !== -1) {
      if (action === "SERVICE") {
        data1.splice(index, 1);
      } else if (action === "TYPE") {
        data1[index].type = types ? [...types] : [];
      }
    } else {
      data1.push({ service_id: id, type: types ? [...types] : [] });
    }

    // Commented for future Ref
    // const mhIndex = data1.findIndex(
    //   (el) => el.service_id === 2 && !el?.types?.length
    // );

    // if (mhIndex !== -1) {
    //   data1[mhIndex].type.push({ label: "Employee", value: "employee" });
    // }

    setFieldValue("meta", [...data1]);
    setData([...data1]);
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;

    const updatedData = JSON.parse(JSON.stringify(data));
    updatedData.forEach(el => {
      const updatedType = [];
      el.type.forEach(el => updatedType.push(el.value));
      el.type = updatedType;
    });

    if (isValid && !typeErrors && data?.length) {
      setIsSubmitting(true);
      if (action === "add") {
        const params = {
          name: values.name,
          code: values.code,
          meta: [...updatedData]
        };

        ServiceLocationServices.add(params)
          .then(res => {
            let data = EncryptDecrypt.decrypt(res.data);
            if (data.status) {
              if (toaster.current) toaster.current.success(data.message);
              onSubmit(values, action);
            } else {
              if (toaster.current) toaster.current.error(data.message);
              setIsSubmitting(false);
            }
          })
          .catch(err => {
            if (toaster.current) toaster.current.error(err.response);
            setIsSubmitting(false);
          });
      } else if (action === "edit") {
        const params = {
          id: values.id,
          name: values.name,
          code: values.code,
          meta: [...updatedData]
        };

        ServiceLocationServices.edit(params)
          .then(res => {
            let data = EncryptDecrypt.decrypt(res.data);
            if (data.status) {
              if (toaster.current) toaster.current.success(data.message);
              onSubmit(values, action);
            } else {
              if (toaster.current) toaster.current.error(data.message);
              setIsSubmitting(false);
            }
          })
          .catch(err => {
            if (toaster.current) toaster.current.error(err.response);
            setIsSubmitting(false);
          });
      }
    }
    handleSubmit();
  };

  return (
    <div>
      <form>
        <div className="row">
          <div className="col-md-6 form-group">
            <label className="fs-16 medium-text">
              Name <span className="asterisk">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              placeholder="Add Service Location Name"
            />
            <Error field="name" />
          </div>
          <div className="col-md-6 form-group">
            <label className="fs-16 medium-text">Code</label>
            <input
              type="text"
              className="form-control react-form-input"
              id="code"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.code}
              placeholder="Add Service Location Code"
            />
            <Error field="code" />
          </div>
          <div className="col-md-6 form-group">
            <label className="fs-16 medium-text">
              Check Service And Select Types:{" "}
              <span className="asterisk">*</span>
            </label>
            {loading ? (
              <Loader loading={loading} />
            ) : (
              <>
                {serviceList.map((service, i) => {
                  return (
                    <Fragment>
                      <div className="flex pb-20 pa-20" key={i}>
                        <div className="flex">
                          <div>
                            <input
                              value={service.id}
                              checked={
                                !!data?.length &&
                                !!data.find(
                                  obj => Number(obj.service_id) === service.id
                                )
                              }
                              type="checkbox"
                              id="meta"
                              onChange={() =>
                                handleDataChange({
                                  id: service.id,
                                  action: "SERVICE"
                                })
                              }
                            />
                          </div>

                          <div className="pl-20 wa-15 ">
                            <span id="service">{service.name}</span>
                          </div>
                        </div>
                        <div className="wa-70">
                          <Select
                            isClearable={true}
                            isMulti
                            options={typeList}
                            className="basic-multi-select"
                            value={getTypesByService(service.id)}
                            isDisabled={
                              !data.some(
                                obj => Number(obj.service_id) === service.id
                              )
                            }
                            onChange={value =>
                              handleDataChange({
                                id: service.id,
                                types: value,
                                action: "TYPE"
                              })
                            }
                            placeholder={<div>Type</div>}
                          />
                        </div>
                      </div>
                    </Fragment>
                  );
                })}
              </>
            )}
            {typeErrors && props?.submitCount > 0 && (
              <div className="error-msg">Select type for selected service</div>
            )}
            {!data?.length && props?.submitCount > 0 && (
              <div className="error-msg">Select a service</div>
            )}
          </div>
        </div>
        <div className="flex-y">
          <Button
            type="button"
            className="c-btn c-info form-button fs-16 demi-bold-text max-w-180"
            onClick={handleSubmit}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Submit
          </Button>
        </div>
      </form>
      <Toaster ref={toaster} />
    </div>
  );
};

export default compose(enhancer)(ServiceLocationForm);
