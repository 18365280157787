import React from "react";
import UserWrapper from "./ExternalProvider.style";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";

import EditExternalProvider from "components/ExternalProvider/EditExternalProvider";

const ExternalProviderEdit = props => {
  const hasParam = props.match.params.id;
  const data = props.location.state;

  return (
    <UserWrapper {...props}>
      <div className="pos-relative">
        <PageTitle
          props={props}
          title="sidebar.external_provider"
          className="plr-15"
          breadCrumb={[
            {
              name: "sidebar.dashboard",
              url: "/dashboard"
            },
            {
              name: "sidebar.external_provider",
              url: "/external-provider"
            },
            {
              name: "Edit"
            }
          ]}
        />

        <div
          className="back-icon fs-15 demi-bold-text cursor-pointer"
          onClick={() => props.history.push("/external-provider")}
        >
          <i className="fas fa-step-backward"></i> Back
        </div>
      </div>

      <EditExternalProvider
        props={props}
        hasParam={hasParam}
        editedData={data}
      />
    </UserWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(ExternalProviderEdit);
