import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { Button, ButtonGroup, Button as RButton } from "reactstrap";
import { compose } from "redux";
import enhancer from "./away-settings-validator";
import moment from "moment";
import CommonServices from "api/CommonServices";
import EncryptDecrypt from "api/EncryptDecrypt";
import Toaster from "components/common/Toaster";

const AwaySettingsModal = ({
  toggle,
  data,
  modalFromDash,
  setModalFromDash,
  ...props
}) => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    setFieldValue,
    setFieldTouched,
    values,
    handleSubmit,
    isValid
  } = props;

  const toaster = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (modalFromDash) {
      setModalFromDash(false);
    }
  }, []);

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const onSubmit = () => {
    if (isValid) {
      setIsLoading(true);
      const params = {
        is_away: values?.isAway,
        start_date: values?.startDate
          ? moment(values?.startDate).format("YYYY-MM-DD")
          : null,
        end_date: values?.endDate
          ? moment(values?.endDate).format("YYYY-MM-DD")
          : null,
        message: values?.message
      };

      CommonServices.setAwaySettings(params)
        .then(res => {
          const data = EncryptDecrypt.decrypt(res?.data);
          if (data.status) {
            if (toaster.current) toaster.current.success(data.message);
            setTimeout(() => {
              toggle();
            }, 500);
          } else {
            if (toaster.current) toaster.current.error(data.message);
            setIsLoading(false);
          }
        })
        .catch(err => {
          if (toaster.current)
            toaster.current.error(
              err?.response ? err?.response : "Something went wrong!"
            );
          setIsLoading(false);
        });
    }
    handleSubmit();
  };

  return (
    <div>
      <form>
        <div className="form-group">
          <label className="fs-16 medium-text pr-12">Away Message</label>
          <ButtonGroup>
            <RButton
              color="secondary"
              outline
              onClick={() => {
                setFieldValue("isAway", true);
                setFieldValue("startDate", new Date());
                setFieldValue("endDate", new Date());
              }}
              active={values?.isAway}
            >
              ON
            </RButton>
            <RButton
              color="secondary"
              outline
              onClick={() => {
                setFieldValue("isAway", false);
                setFieldValue("startDate", null);
                setFieldValue("endDate", null);
              }}
              active={!values?.isAway}
            >
              OFF
            </RButton>
          </ButtonGroup>
          <Error field="isAway" />
        </div>
        <div className="d-flex justify-content-start">
          <div className="form-group">
            <label>First Day:</label>
            <div className="roe-card-body flex flex-y">
              <DatePicker
                id="startDate"
                name="startDate"
                selected={
                  values?.startDate ? new Date(moment(values?.startDate)) : null
                }
                onChange={e => setFieldValue("startDate", e)}
                className="custom-datepicker form-control react-form-input"
                calendarClassName="custom-calender-class"
                selectsStart
                disabled={!values?.isAway}
                minDate={new Date()}
              />
              <Error field="startDate" />
            </div>
          </div>
          <div className="form-group" style={{ paddingLeft: 48 }}>
            <label className="flex align-items-center">
              <div style={{ paddingLeft: 2 }}>Last Day:</div>
            </label>
            <div className="roe-card-body flex flex-y">
              <DatePicker
                id="endDate"
                name="endDate"
                selected={
                  values?.endDate ? new Date(moment(values?.endDate)) : null
                }
                onChange={e => setFieldValue("endDate", e)}
                className="custom-datepicker form-control react-form-input"
                calendarClassName="custom-calender-class"
                selectsStart
                disabled={!values?.isAway}
                minDate={values?.startDate && new Date(values?.startDate)}
              />
              <Error field="endDate" />
            </div>
          </div>
        </div>
        <div className="form-group">
          <label>Message:</label>
          <textarea
            className="form-control react-form-input"
            rows="4"
            onChange={handleChange}
            value={values?.message}
            onBlur={handleBlur}
            placeholder="Message"
            id="message"
            disabled={!values?.isAway}
          ></textarea>
          <Error field="message" />
        </div>
        <div className="d-flex justify-content-end">
          <Button
            type="button"
            className="c-btn c-info form-button fs-16 demi-bold-text mr-10"
            style={{ maxWidth: "125px" }}
            onClick={onSubmit}
            disabled={isLoading}
          >
            {isLoading ? "Saving..." : "Save"}
          </Button>
          <Button
            type="button"
            className="c-btn c-secondary form-button fs-16 demi-bold-text"
            style={{ maxWidth: "125px" }}
            onClick={toggle}
          >
            Close
          </Button>
        </div>
      </form>
      <Toaster ref={toaster} />
    </div>
  );
};

export default compose(enhancer)(AwaySettingsModal);
