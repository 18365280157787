import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    type: Yup.string()
      .trim()
      .required("This field is required")
      .max(100, "Type must be less than 100 characters"),
    service: Yup.string()
      .trim()
      .required("This field is required")
      .max(100, "Type must be less than 100 characters")
      .nullable(),
    title: Yup.string()
      .trim()
      .required("This field is required")
      .max(100, "Title must be less than 100 characters"),
    author_id: Yup.string()
      .trim()
      .required("This field is required"),
    tags: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string()
            .trim("Tag cannot include leading and trailing spaces")
            .strict(true)
            .matches(/^[A-Za-z0-9 ]+$/, "Tag cannot include special characters")
            .required("This field is required"),
          text: Yup.string()
            .trim("Tag cannot include leading and trailing spaces")
            .strict(true)
            .matches(/^[A-Za-z0-9 ]+$/, "Tag cannot include special characters")
            .required("This field is required")
        })
      )
      .required("This field is required"),
    description: Yup.string()
      .trim()
      .required("This field is required"),
    article_body: Yup.string().when("type", {
      is: "article",
      then: Yup.string()
        .trim()
        .required("This field is required")
    }),
    recipe_serving: Yup.string().when("type", {
      is: "recipe",
      then: Yup.string()
        .trim()
        .required("This field is required")
    }),
    recipe_ingredient: Yup.string().when("type", {
      is: "recipe",
      then: Yup.string()
        .trim()
        .required("This field is required")
    }),
    recipe_instruction: Yup.string().when("type", {
      is: "recipe",
      then: Yup.string()
        .trim()
        .required("This field is required")
    }),
    video_url: Yup.string().when("type", {
      is: "video",
      then: Yup.string()
        .trim()
        .required("This field is required")
    })
  }),
  mapPropsToValues: props => ({
    id: props.editedData
      ? props && props.editedData && props.editedData.id
      : "",
    type: props.editedData
      ? props && props.editedData && props.editedData.type
      : "",
    service: props.editedData
      ? props && props.editedData && props.editedData.service.id
      : "",
    title: props.editedData
      ? props && props.editedData && props.editedData.title
      : "",
    author_id: props.editedData
      ? props && props.editedData && props.editedData.author_id
      : "",
    content_library_tags: props.editedData
      ? props && props.editedData && props.editedData.content_library_tags
      : [],
    description: props.editedData
      ? props && props.editedData && props.editedData.description
      : "",
    article_body:
      props && props.editedData && props.editedData.article_body
        ? props.editedData.article_body.replaceAll(
            "<table border=1>",
            "<table>"
          )
        : "",
    recipe_serving: props.editedData
      ? props && props.editedData && props.editedData.recipe_serving
      : "",
    recipe_ingredient: props.editedData
      ? props && props.editedData && props.editedData.recipe_ingredient
      : "",
    recipe_instruction:
      props && props.editedData && props.editedData.recipe_instruction
        ? props.editedData.recipe_instruction.replaceAll(
            "<table border=1>",
            "<table>"
          )
        : "",
    video_url: props.editedData
      ? props && props.editedData && props.editedData.video_url
      : "",
    video_original_url: props.editedData
      ? props && props.editedData && props.editedData.video_original_url
      : "",
    video_url_status: props.editedData
      ? props && props.editedData && props.editedData.video_url_status
      : "",
    status: props.editedData
      ? props && props.editedData && props.editedData.status
      : "save_draft"
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
