import React, { useState, useEffect } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import GroupBasicView from "../../components/group/GroupBasicViewDetails";
import RegistrationCodeTable from "../../components/group/RegistrationCodeTable";
import BenefitPeriodTable from "../../components/group/BenefitPeriodTable";
import VisitProgramTable from "../../components/group/VisitProgramTable";
import ScaleProgramTable from "../../components/group/ScaleProgramTable";
import InsuranceTab from "../../components/group/InsuranceTab";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import GroupServices from "../../api/GroupServices";
import { hasAccess } from "util/sidebar";
import { MODULE_NAMES, PERMISSION_TYPE } from "helper/constant";
import { connect } from "react-redux";

const GroupViewDetails = props => {
  const { permissions } = props;
  const [activeTab, setActiveTab] = useState("1");
  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const [editedData, setEditedData] = useState(undefined);
  const [insuranceData, setInsuranceData] = useState(undefined);
  const [insuranceList, setInsuranceList] = useState(undefined);
  const hasParam = props.match.params.id;

  useEffect(() => {
    if (hasParam) {
      const params = {
        id: hasParam
      };
      GroupServices.view(params).then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        setEditedData(data.data);
      });

      if (
        hasAccess(
          MODULE_NAMES.GROUP_INSURANCE,
          PERMISSION_TYPE.VIEW,
          permissions
        )
      ) {
        GroupServices.groupInsuranceList(params).then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          setInsuranceData(data.data);
        });
      }

      if (
        hasAccess(
          MODULE_NAMES.GROUP_INSURANCE,
          PERMISSION_TYPE.VIEW,
          permissions
        )
      ) {
        GroupServices.insuranceList().then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          setInsuranceList(data.data);
        });
      }
    }
  }, []);
  const submitFormHandler = (data, action) => {
    setActiveTab("4");
  };

  return (
    <div className="plr-15">
      <div className="roe-card-style mtb-15">
        <div className="roe-card-header module-header">
          <div className="flex-1 fs-16 demi-bold-text">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab === "1" },
                    "doc-title"
                  )}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  Group Details
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab === "2" },
                    "doc-title"
                  )}
                  onClick={() => {
                    toggle("2");
                  }}
                  disabled={
                    !hasAccess(
                      MODULE_NAMES.GROUP_REG_CODES,
                      PERMISSION_TYPE.VIEW,
                      permissions
                    )
                  }
                >
                  Registration Codes
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab === "3" },
                    "doc-title"
                  )}
                  onClick={() => {
                    toggle("3");
                  }}
                  disabled={
                    !hasAccess(
                      MODULE_NAMES.GROUP_BENEFIT,
                      PERMISSION_TYPE.VIEW,
                      permissions
                    )
                  }
                >
                  Benefit Periods
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab === "4" },
                    "doc-title"
                  )}
                  onClick={() => {
                    toggle("4");
                  }}
                  disabled={
                    !hasAccess(
                      MODULE_NAMES.GROUP_INSURANCE,
                      PERMISSION_TYPE.VIEW,
                      permissions
                    )
                  }
                >
                  Insurance Carriers
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab === "5" },
                    "doc-title"
                  )}
                  onClick={() => {
                    toggle("5");
                  }}
                  disabled={
                    !hasAccess(
                      MODULE_NAMES.GROUP_VISIT_PROGRAM,
                      PERMISSION_TYPE.VIEW,
                      permissions
                    )
                  }
                >
                  Visit Program
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab === "6" },
                    "doc-title"
                  )}
                  onClick={() => {
                    toggle("6");
                  }}
                  disabled={
                    !hasAccess(
                      MODULE_NAMES.GROUP_SCALE,
                      PERMISSION_TYPE.VIEW,
                      permissions
                    )
                  }
                >
                  Scale Program
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </div>
        <div className="roe-card-body">
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <GroupBasicView {...props} />
            </TabPane>
            <TabPane tabId="2">
              <RegistrationCodeTable
                service={editedData?.service_id}
                {...props}
              />
            </TabPane>
            <TabPane tabId="3">
              <BenefitPeriodTable {...props} />
            </TabPane>
            <TabPane tabId="4">
              <InsuranceTab
                onSubmit={submitFormHandler}
                editedData={editedData}
                insuranceData={insuranceData}
                insuranceList={insuranceList}
                {...props}
              />
            </TabPane>
            <TabPane tabId="5">
              <VisitProgramTable service={editedData?.service_id} {...props} />
            </TabPane>
            <TabPane tabId="6">
              <ScaleProgramTable {...props} />
            </TabPane>
          </TabContent>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    ...state.permissionReducer
  };
};

export default connect(mapStateToProps, null)(GroupViewDetails);
