import axios from "axios";
import { baseUrl } from "../helper/constant";
import Header from "./Header";
import EncryptDecrypt from "./EncryptDecrypt";

export default class GoalTracking {
  static list(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/goal-tracking/list`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static categoryList(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/goal-tracking/category/list`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static shortGoalQuestion(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/goal-tracking/question/list`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static shortGoalAnswer(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/goal-tracking/answer/add`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static longTermsAnswer(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/goal-tracking/long-term-goal-submit`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static trackShortTermGoal(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/goal-tracking/track-short-term-goal`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static createShortTermGoal(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/goal-tracking/create-short-goal`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static saveUserAnswer(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/goal-tracking/save-user-answer`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static goalTrackingDetail(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/goal-tracking/goal-tracking-detail`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static deleteGoal(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/goal-tracking/delete-goal`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static achiveGoal(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/goal-tracking/archive-goal`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static editlongTermGoal(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/goal-tracking/edit-long-term-goal`,
      EncryptDecrypt.encrypt(params)
    );
  }
}
