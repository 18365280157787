import axios from "axios";
import { baseUrl } from "../helper/constant";
import Header from "./Header";
import EncryptDecrypt from "./EncryptDecrypt";

export default class VisitProgramServices {
  static list(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/visit-program/list`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static add(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/visit-program/add`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static edit(params) {
    Header.setHeaders();
    return axios.put(
      `${baseUrl}/visit-program/edit`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static view(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/visit-program/view`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static delete(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/visit-program/delete`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static status(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/visit-program/status`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }
}
