import axios from "axios";
import { baseUrl } from "../helper/constant";
import Header from "./Header";
import EncryptDecrypt from "./EncryptDecrypt";

export default class RolesAndPermissionService {
  static list(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/roles-permission/list-role-permission`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static edit(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/roles-permission/update-role-permission`,
      EncryptDecrypt.encrypt(params)
    );
  }
}
