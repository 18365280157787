import React, { Fragment, useEffect, useState, useRef } from "react";
import RegisteredDietitian from "../../api/RegisteredDietitian";
import { connect } from "react-redux";
import enhancer from "./validator";
import { compose } from "redux";
import Toaster from "../common/Toaster";
import Button from "components/button/Button";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
import { ImageResolution } from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import AuthActions from "../../redux/auth/actions";
import { Input } from "reactstrap";

let categoryList = [
  "Weight Management",
  "Disease Management",
  "Healthy Lifestyle"
];

const { admin } = AuthActions;

const ProfileForm = props => {
  const {
    handleChange,
    handleBlur,
    setFieldTouched,
    setFieldValue,
    submitCount,
    errors,
    values,
    touched,
    stateList,
    languageList,
    timezoneList
  } = props;

  const [parentDropdownOpen, setParentDropdownOpen] = useState(false);
  const [secondDropdownOpen, setSecondDropdownOpen] = useState(false);
  // const [parentCategoryId, setParentCategoryId] = useState(null);
  // const [secondCategoryId, setSecondCategoryId] = useState(null);
  const [imageObj, setImageObj] = useState(null);
  const [uploadImage, setUploadImage] = useState(null);
  const [loader, setLoader] = useState(false);

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };
  const toggleOne = () => setParentDropdownOpen(prevState => !prevState);
  const toggleTwo = () => setSecondDropdownOpen(prevState => !prevState);
  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;

    if (isValid) {
      setLoader(true);
      values.phone_number = values.phone_number.replace(/[()-\s]/g, "");
      values.admin_id = props.id;
      // values.primary_category_id = parentCategoryId;
      // values.secondary_category_id = secondCategoryId;
      values.image = imageObj;

      RegisteredDietitian.editProfile(values)
        .then(result => {
          setLoader(false);
          let data = EncryptDecrypt.decrypt(result.data);
          if (toaster.current) toaster.current.success(data.message);
          props.admin({
            first_name: data.data.first_name,
            last_name: data.data.last_name,
            profile_image: data.data.profile_image
          });
        })
        .catch(error => {
          setLoader(false);
          console.log("handleSubmit -> error", error);
        });
    }
    handleSubmit();
  };

  const changeFormat = e => {
    var x = e.target.value
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    let no = "(" + x[1] + ") " + x[2] + "-" + x[3];
    setFieldValue("phone_number", no);
  };

  const toaster = useRef();
  const handleImage = (e, setValue) => {
    if (e.target.files[0]) {
      let fileObj = e.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(fileObj);
      reader.onloadend = () => {
        setImageObj(reader.result);
      };

      if (fileObj) {
        const img = new Image();
        img.src = window.URL.createObjectURL(fileObj);
        img.onload = function() {
          const width = img.naturalWidth,
            height = img.naturalHeight;
          window.URL.revokeObjectURL(img.src);
          setValue("width", width);
          setValue("height", height);
          setFieldTouched("image", true, true);
          if (
            width >= ImageResolution.WIDTH &&
            height >= ImageResolution.HEIGHT
          ) {
            setValue("image", URL.createObjectURL(fileObj));
            setUploadImage(URL.createObjectURL(fileObj));
          }
        };
      } else {
        //No file was input or browser doesn't support client side reading
        // form.submit();
      }
    } else {
      setUploadImage();
    }
  };
  // useEffect(() => {
  //   setParentCategoryId(values.primary_category);
  //   setSecondCategoryId(values.secondary_category);
  // }, [values]);

  return (
    <Fragment>
      {!props.isModal && (
        <form>
          <div className="row">
            <div className="col-6">
              <div className="form-group row">
                <label className="fs-16 medium-text col-sm-3">ID: </label>
                <label className="fs-16 medium-text col-sm-8">
                  {props.id}{" "}
                </label>
              </div>
              <div className="form-group row ">
                <label className="fs-16 medium-text col-sm-3">
                  First Name:{" "}
                </label>
                <div className="col-8">
                  <input
                    type="text"
                    className="form-control "
                    id="first_name"
                    name="first_name"
                    placeholder="First name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.first_name}
                  />
                  <Error field="first_name" />
                </div>
              </div>
              <div className="form-group row">
                <label className="fs-16 medium-text col-sm-3">
                  Last Name:{" "}
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    id="last_name"
                    name="last_name"
                    placeholder="Last name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.last_name}
                  />
                  <Error field="last_name" />
                </div>
              </div>
              <div className="form-group row ">
                <label className="fs-16 medium-text col-sm-3">
                  Client Max:{" "}
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    id="max_client"
                    name="max_client"
                    placeholder="Client max"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.max_client}
                    disabled={true}
                  />
                </div>
                <Error field="max_client" />
              </div>
              <div className="form-group row ">
                <label className="fs-16 medium-text col-sm-3">Email: </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder="Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    disabled={true}
                  />
                </div>
              </div>
              {/* <div className="form-group row mt-20">
                <label className="fs-16 medium-text col-sm-3">
                  Primary Category:
                </label>
                <div className="col-sm-8">
                  <Dropdown isOpen={parentDropdownOpen} toggle={toggleOne}>
                    <DropdownToggle caret size="md">
                      {parentCategoryId
                        ? categoryList[parentCategoryId - 1]
                        : "Primary Category"}
                    </DropdownToggle>
                    <DropdownMenu>
                      {categoryList.map((result, i) => {
                        return (
                          <DropdownItem
                            key={i}
                            onClick={() => setParentCategoryId(i + 1)}
                          >
                            {result}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
              <div className="form-group row mt-20">
                <label className="fs-16 medium-text col-sm-3">
                  Secondary Category:
                </label>
                <div className="col-sm-8">
                  <Dropdown isOpen={secondDropdownOpen} toggle={toggleTwo}>
                    <DropdownToggle caret size="md">
                      {secondCategoryId
                        ? categoryList[secondCategoryId - 1]
                        : "Secondary Category"}
                    </DropdownToggle>
                    <DropdownMenu>
                      {categoryList.map((result, i) => {
                        return (
                          <DropdownItem
                            key={i}
                            onClick={() => setSecondCategoryId(i + 1)}
                          >
                            {result}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div> */}
              <div className="form-group row ">
                <label className="fs-16 medium-text col-sm-3">
                  AdvancedMD Provider ID:{" "}
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    id="advancemd_provider_id"
                    name="advancemd_provider_id"
                    placeholder="AdvancedMD Provider ID"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.advancemd_provider_id}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="form-group row ">
                <label className="fs-16 medium-text col-sm-3">
                  Provider NPI:{" "}
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    id="provider_npi"
                    name="provider_npi"
                    placeholder="Provider NPI"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.provider_npi}
                    disabled={true}
                  />
                  <Error field="provider_npi" />
                </div>
              </div>
              <div className="form-group row ">
                <label className="fs-16 medium-text col-sm-3">
                  Phone Number:{" "}
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    id="phone_number"
                    name="phone_number"
                    placeholder="Phone Number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone_number}
                    onKeyUp={value => changeFormat(value)}
                  />
                  <Error field="phone_number" />
                </div>
              </div>
              <div className="form-group row ">
                <label className="fs-16 medium-text">Language</label>
                <Input
                  type="select"
                  name=""
                  id="language_id"
                  onChange={event => {
                    let opts = [],
                      opt;
                    for (
                      let i = 0, len = event.target.options.length;
                      i < len;
                      i++
                    ) {
                      opt = event.target.options[i];
                      if (opt.selected) {
                        opts.push(opt.value);
                      }
                    }
                    setFieldValue("language_id", opts);
                  }}
                  value={values.language_id}
                  onBlur={handleBlur}
                  multiple
                >
                  {languageList.map((type, i) => {
                    return (
                      <Fragment>
                        <option key={`option${i}`} value={type.id}>
                          {type.name}
                        </option>
                      </Fragment>
                    );
                  })}
                </Input>
                <Error field="language_id" />
              </div>
              <div className="form-group row ">
                <label className="fs-16 medium-text">Timezone</label>
                <Input
                  type="select"
                  name=""
                  id="timezone_id"
                  onChange={handleChange}
                  value={values.timezone_id}
                  onBlur={handleBlur}
                >
                  <option value="">Select Timezone</option>
                  {timezoneList.map((type, i) => {
                    return (
                      <Fragment>
                        <option key={`option${i}`} value={type.id}>
                          {type.name}
                        </option>
                      </Fragment>
                    );
                  })}
                </Input>
                <Error field="timezone_id" />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group row ">
                <label className="fs-16 medium-text  col-sm-3">
                  Profile Photo:{" "}
                </label>
                <div className="col-sm-8">
                  <div className="form-group">
                    <label className="fs-16 medium-text c-btn c-primary demi-bold-text mt-15 wp-17">
                      Upload Image
                      <input
                        id="image"
                        className="file-upload__input"
                        name="image"
                        type="file"
                        onChange={e => handleImage(e, setFieldValue)}
                        onBlur={handleBlur}
                        required={true}
                        accept="image/jpg,image/png,image/jpeg"
                      />
                    </label>
                  </div>
                  {imageObj ? (
                    <img
                      src={imageObj}
                      alt=""
                      className="mtb-15"
                      style={{
                        width: "200px",
                        borderRadius: "4%",
                        height: "150px",
                        background: "#404040"
                        // display: displaya,
                      }}
                      // onChange={handleImage}
                    />
                  ) : (
                    <img
                      src={props.editedData.profile_image}
                      alt=""
                      className="mtb-15"
                      style={{
                        width: "200px",
                        borderRadius: "4%",
                        height: "150px",
                        background: "#404040"
                        // display: displaya,
                      }}
                      // onChange={handleImage}
                    />
                  )}
                </div>
              </div>
              <div className="form-group row ">
                <label className="fs-16 medium-text col-sm-3">
                  Profile Text:
                </label>
                <div className="col-sm-8">
                  <textarea
                    className="form-control react-form-input"
                    rows="3"
                    placeholder="Profile text"
                    id="profile_text"
                    name="profile_text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.profile_text}
                  ></textarea>
                  <Error field="profile_text" />
                </div>
              </div>
              <div className="form-group row ">
                <label className="fs-16 medium-text col-sm-3">
                  Calendly Link:
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    id="calendy_link"
                    name="calendy_link"
                    placeholder="Calendly link"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.calendy_link}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="form-group row ">
                <label className="fs-18 medium-text col-sm-3">
                  Home Address
                </label>
              </div>
              <div className="form-group row ">
                <label className="fs-16 medium-text col-sm-3">Address: </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    id="address"
                    name="address"
                    placeholder="Address"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address}
                  />
                  <Error field="address" />
                </div>
              </div>
              <div className="form-group row ">
                <label className="fs-16 medium-text col-sm-3">City: </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    id="city"
                    name="city"
                    placeholder="City"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.city}
                  />
                  <Error field="city" />
                </div>
              </div>
              <div className="form-group row">
                <label className="fs-16 medium-text col-sm-3">State</label>
                <div className="col-sm-8">
                  <Input
                    type="select"
                    name=""
                    id="state_id"
                    onChange={handleChange}
                    value={values.state_id}
                    onBlur={handleBlur}
                  >
                    <option value="">Select State</option>
                    {stateList.map((type, i) => {
                      return (
                        <Fragment>
                          <option key={`option${i}`} value={type.id}>
                            {type.name}
                          </option>
                        </Fragment>
                      );
                    })}
                  </Input>
                </div>
                <Error field="state_id" />
              </div>
              <div className="form-group row ">
                <label className="fs-16 medium-text col-sm-3">Zip: </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    id="zip"
                    name="zip"
                    placeholder="Zip"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.zip}
                  />
                  <Error field="zip" />
                </div>
              </div>
            </div>
            <div className="col-sm-12 d-flex  ">
              <div className="form-group row">
                <div className="col-sm-12">
                  <Button
                    loading={loader}
                    disabled={false}
                    dataStyle="expand-right"
                    type="button"
                    className="c-btn c-info form-button fs-16 demi-bold-text"
                    style={{ maxWidth: "250px" }}
                    onClick={() => handleSubmit()}
                  >
                    Update profile
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
      <Toaster ref={toaster} />
    </Fragment>
  );
};
const mapStateToProps = state => {
  return {
    ...state.auth
  };
};
export default compose(
  enhancer,
  connect(mapStateToProps, { admin })
)(ProfileForm);
