import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import RdServices from "../../api/RdServices";
import Loader from "../common/Loader";
import CommonModel from "../common/CommonModel";
import StateLicenseModal from "./StateLicenseModal";
//import Moment from "react-moment";
import Toaster from "../common/Toaster";
import {
  MODULE_NAMES,
  PERMISSION_TYPE,
  TABLE,
  USER_WEB_URL
} from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import moment from "moment";
import { PLACEHOLDER } from "helper/constant";
import DeleteModal from "../common/DeleteModal";
import CustomModal from "../common/CustomModal";
import { hasAccess } from "util/sidebar";

const StateLicenseTab = props => {
  const { permissions } = props;
  const [tblData, settblData] = useState();
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("created_at");
  const [sortBy, setSortBy] = useState("DESC");
  const [id, setId] = useState(undefined);
  const [modal, setModal] = useState(undefined);
  const [modal1, setModal1] = useState(undefined);
  const [editedData, setEditedData] = useState(undefined);
  // const [stateList, setStateList] = useState([]);
  const [status, setStatus] = useState(undefined);
  const [customModal, setCustomModal] = useState(undefined);
  const toaster = useRef();

  const hasParam = props.match.params.id;

  useEffect(() => {
    if (props?.location?.state?.step === "4") {
      setModal(true);
    }
  }, [props?.location?.state?.step]);

  useEffect(() => {
    if (
      hasAccess(
        MODULE_NAMES.PROVIDER_MANAGEMENT_STATE_LICENCES,
        PERMISSION_TYPE.VIEW,
        permissions
      )
    ) {
      getList(field, sortBy, searchText, activePage + 1);
      setPages(10);
    }
  }, []);

  function toggle() {
    setModal(false);
  }

  function toggle1() {
    setModal1(false);
  }

  function activeToggle() {
    setCustomModal(false);
  }

  const activeDeactive = data => {
    setId(data.id);
    setStatus(data.is_active);
    setCustomModal(true);
  };

  const activeInactiveStatusHandler = oData => {
    let statusData = status;
    const params = {
      is_active: !statusData,
      id: id
    };
    setLoader(true);
    RdServices.stateLicenseStatus(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current)
            toaster.current.success(
              statusData === true
                ? "State License has been deactivated successfully."
                : "State License has been activated successfully."
            );
          setCustomModal(false);
          getList(field, sortBy, searchText, activePage + 1);
          setLoader(false);
        } else {
          setCustomModal(false);
          if (toaster.current) toaster.current.error(data.message);
          setLoader(false);
        }
      })
      .catch(err => {
        setCustomModal(false);
        if (toaster.current) toaster.current.error(err.response);
        setLoader(false);
      });
  };

  const formAction = (action, data = null) => {
    // RdServices.stateList().then((res) => {
    //   let data = EncryptDecrypt.decrypt(res.data);
    //   setStateList(data.data);
    // });

    // Here you can view the data and make forward action for edit data
    if (action === "edit") {
      setId(data.id);
      setModal(true);
      const params = {
        id: data.id
      };
      RdServices.stateLicenseView(params).then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        setEditedData(data.data);
      });
    } else if (action === "add") {
      setId(data);
      setModal(true);
      setEditedData(null);
    }
  };

  const submitFormHandler = () => {
    setTimeout(() => {
      setModal(false);
      getList(field, sortBy, searchText, activePage + 1);
    }, 2000);
  };

  const deleteClick = data => {
    setId(data.id);
    setModal1(true);
  };

  function deleteHandler() {
    setLoader(true);
    const params = {
      id: id
    };
    RdServices.stateLicenseDelete(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current) toaster.current.success(data.message);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
        setLoader(false);
        setModal1(false);
        setTimeout(() => {
          getList(field, sortBy, searchText, activePage + 1);
        }, 1000);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });
  }

  const columns = [
    {
      Header: "ID",
      accessor: "id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 100,
      sortable: true
    },
    {
      Header: "State",
      accessor: "state.name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 250,
      sortable: true
    },
    {
      Header: "License Number",
      accessor: "license_number",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 250,
      sortable: true
    },
    {
      Header: "Valid From",
      accessor: "valid_from",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      Cell: props => moment(props.original.valid_from).format("MM/DD/YYYY"),
      width: 200,
      sortable: true
    },
    {
      Header: "Valid To",
      accessor: "valid_to",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      Cell: props => moment(props.original.valid_to).format("MM/DD/YYYY"),
      width: 200,
      sortable: true
    },
    {
      Header: "Image",
      accessor: "image",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 250,
      sortable: false,
      Cell: props => (
        <div>
          <img
            src={props.original.image || PLACEHOLDER}
            alt="error"
            style={{ height: "100px", width: "100px" }}
          />
        </div>
      )
    },
    {
      Header: "Status",
      accessor: "is_active",
      className: "",
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      filterable: false,
      width: 200,
      Cell: props => (
        <div className="react-action-class">
          {props.original.is_active ? (
            <div id={`active-${props.original.state_id}`}>
              <Button
                style={{ minWidth: "125px" }}
                className="c-btn c-info mr-10"
                onClick={() => activeDeactive(props.original)}
                disabled={
                  !hasAccess(
                    MODULE_NAMES.PROVIDER_MANAGEMENT_STATE_LICENCES,
                    PERMISSION_TYPE.STATUS,
                    permissions
                  )
                }
              >
                <div className="fs-14 medium-text">
                  <i className="fas fa-toggle-off mr-6" /> Active
                </div>
              </Button>
            </div>
          ) : (
            <div id={`deactive-${props.original.state_id}`}>
              <Button
                style={{ minWidth: "125px" }}
                className="c-btn c-warning mr-10"
                onClick={() => activeDeactive(props.original)}
                disabled={
                  !hasAccess(
                    MODULE_NAMES.PROVIDER_MANAGEMENT_STATE_LICENCES,
                    PERMISSION_TYPE.STATUS,
                    permissions
                  )
                }
              >
                <div className="fs-14 medium-text">
                  <i className="fas fa-toggle-on mr-6" /> Inactive
                </div>
              </Button>
            </div>
          )}
        </div>
      ) // Custom cell components!
    },
    {
      Header: "Action",
      accessor: "action",
      className: "",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 300,
      Cell: colProps => (
        <div className="react-action-class">
          {hasAccess(
            MODULE_NAMES.PROVIDER_MANAGEMENT_STATE_LICENCES,
            PERMISSION_TYPE.EDIT,
            permissions
          ) && (
            <Button
              className="c-btn c-success mr-10"
              onClick={() => formAction("edit", colProps.original)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-edit" />
              </div>
            </Button>
          )}
          {hasAccess(
            MODULE_NAMES.PROVIDER_MANAGEMENT_STATE_LICENCES,
            PERMISSION_TYPE.DELETE,
            permissions
          ) && (
            <Button
              className="c-btn c-danger"
              onClick={() => deleteClick(colProps.original)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-trash" />
              </div>
            </Button>
          )}
        </div>
      ) // Custom cell components!
    }
  ];

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  function getList(
    field = "created_at",
    sortBy = "DESC",
    search = "",
    page = "1"
  ) {
    const params = {
      sort_by: field,
      order: sortBy,
      search: search,
      page: page,
      rd_id: hasParam
    };
    setLoader(true);
    RdServices.stateLicenseList(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count / TABLE.LIMIT));
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });
  }

  return (
    <div>
      <div className="module-header">
        <div className="flex-1 fs-16 demi-bold-text"></div>
        <div>
          {hasAccess(
            MODULE_NAMES.PROVIDER_MANAGEMENT_STATE_LICENCES,
            PERMISSION_TYPE.ADD,
            permissions
          ) && (
            <Button
              className="c-btn c-primary ma-5"
              onClick={() => formAction("add")}
            >
              <i className="fas fa-plus mr-10" />
              Add State License
            </Button>
          )}
        </div>
      </div>
      <ReactTable
        style={{
          border: "none",
          boxShadow: "none"
        }}
        data={tblData}
        columns={columns}
        defaultPageSize={10}
        minRows={2}
        manual
        defaultFilterMethod={(filter, row) => {
          const id = filter.pivotId || filter.id;

          return row[id] !== undefined
            ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
            : true;
        }}
        className="-striped -highlight custom-react-table-theme-class"
        pages={pages}
        page={activePage}
        PaginationComponent={Pagination}
        loading={loader}
        LoadingComponent={Loader}
        onFetchData={onChangePageGetData}
        onPageChange={pageIndex => {
          setActivePage(pageIndex);
          getList(field, sortBy, searchText, pageIndex + 1);
        }}
        onSortedChange={sortProperties => {
          const sort = sortProperties[0].desc ? "DESC" : "ASC";
          setSortBy(sort);
          setActivePage(0);
          setField(sortProperties[0].id);
          getList(sortProperties[0].id, sort, searchText, 1);
          // alert("sort handler", sortProperties);
          // const orderByDirection = sortProperties[0].desc ? 1 : 0;

          // updateMapStateInfoOrderByColumn(sortProperties[0].id, orderByDirection);
        }}
      />

      <Toaster ref={toaster} />
      <CommonModel
        modal={modal}
        toggle={toggle}
        children={
          <StateLicenseModal
            // stateList={stateList}
            onSubmit={submitFormHandler}
            editedData={editedData}
            action={editedData ? "edit" : "add"}
            toggle={toggle}
            {...props}
            pageRefresh={() => {
              getList(field, sortBy, searchText, activePage + 1);
              setPages(10);
            }}
          />
        }
        header={editedData ? "Edit State License" : "Add State License"}
      />
      <CommonModel
        modal={modal1}
        toggle1={toggle1}
        children={
          <DeleteModal
            text={"Are you sure you want to delete this state license?"}
            deleteHandler={deleteHandler}
            toggle={toggle1}
          />
        }
      />
      <CommonModel
        modal={customModal}
        toggle={activeToggle}
        children={
          <CustomModal
            text={`${
              status === false
                ? "Are you sure you want to activate state license?"
                : "Are you sure you want to deactivate state license?"
            }`}
            activeInactiveStatusHandler={activeInactiveStatusHandler}
            activeToggle={activeToggle}
          />
        }
      />
    </div>
  );
};
export default withRouter(StateLicenseTab);
