import React, { useState, useRef } from "react";
import { compose } from "redux";
import enhancer from "./caqh-validator";
import Button from "../button/Button";
import Toaster from "../common/Toaster";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import RdServices from "../../api/RdServices";
import DatePicker from "react-datepicker";
import showPwdImg from "../../assets/images/show-password.svg";
import hidePwdImg from "../../assets/images/hide-password.svg";

const CaqhModel = props => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    setFieldValue,
    setFieldTouched,
    onSubmit,
    values,
    action
  } = props;

  const [isReveal, setIsReveal] = useState(false);

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const toaster = useRef();

  const hasParam = props.match.params.id;

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;
    if (isValid) {
      onSubmit(values, action);
      if (action === "add") {
        const params = {
          rd_id: hasParam,
          caqh_id: values.caqh_id,
          username: values.username,
          password: values.password,
          attested_date: values.attested_date,
          reattested_date: values.reattested_date
        };
        RdServices.caqhAdd(params)
          .then(res => {
            let data = EncryptDecrypt.decrypt(res.data);
            if (data.status) {
              if (toaster.current) toaster.current.success(data.message);
            } else {
              if (toaster.current) toaster.current.error(data.message);
            }
          })
          .catch(err => {
            if (toaster.current) toaster.current.error(err.response);
          });
      } else if (action === "edit") {
        const params = {
          id: values.id,
          rd_id: hasParam,
          caqh_id: values.caqh_id,
          username: values.username,
          password: values.password,
          attested_date: values.attested_date,
          reattested_date: values.reattested_date
        };
        RdServices.caqhEdit(params)
          .then(res => {
            let data = EncryptDecrypt.decrypt(res.data);
            if (data.status) {
              if (toaster.current) toaster.current.success(data.message);
            } else {
              if (toaster.current) toaster.current.error(data.message);
            }
          })
          .catch(err => {
            if (toaster.current) toaster.current.error(err.response);
          });
      }
    }

    handleSubmit();
  };

  return (
    <form>
      <div className="row">
        <div className="col-md-12 form-group">
          <label className="fs-16 medium-text">
            CAQH ID<span className="asterisk">*</span>
          </label>
          <input
            type="text"
            className="form-control react-form-input"
            id="caqh_id"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.caqh_id}
            placeholder="Add CAQH ID"
          />
          <Error field="caqh_id" />
        </div>
        <div className="col-md-6 form-group">
          <label className="fs-16 medium-text">
            Username<span className="asterisk">*</span>
          </label>
          <input
            type="text"
            className="form-control react-form-input"
            id="username"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.username}
            placeholder="Add Username"
          />
          <Error field="username" />
        </div>
        <div className="col-md-6 form-group">
          <label className="fs-16 medium-text">
            Password<span className="asterisk">*</span>
          </label>
          <div style={{ position: "relative" }}>
            <input
              type={isReveal ? "text" : "password"}
              onPaste={e => {
                e.preventDefault();
              }}
              className="form-control react-form-input"
              id="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              placeholder="Password"
              autoComplete="new-password"
            />
            <img
              style={{
                cursor: "pointer",
                position: "absolute",
                width: "20px",
                right: "8px",
                top: "8px"
              }}
              title={isReveal ? "Hide password" : "Show password"}
              src={isReveal ? hidePwdImg : showPwdImg}
              onClick={() => setIsReveal(prevState => !prevState)}
            />
          </div>
          <Error field="password" />
        </div>
        <div className="col-md-6 form-group">
          <label className="fs-16 medium-text">
            Attested Date<span className="asterisk">*</span>
          </label>
          <br />
          <DatePicker
            className="form-control"
            selected={values.attested_date}
            id="attested_date"
            onChange={e => {
              setFieldValue("attested_date", e);
            }}
            dateFormat="MM/dd/yyyy"
            placeholder="Add Attested Date"
          />
          <br />
          <Error field="attested_date" />
        </div>
        <div className="col-md-6 form-group">
          <label className="fs-16 medium-text">
            Reattested Date<span className="asterisk">*</span>
          </label>
          <br />
          <DatePicker
            className="form-control"
            selected={values.reattested_date}
            id="reattested_date"
            onChange={e => {
              setFieldValue("reattested_date", e);
            }}
            dateFormat="MM/dd/yyyy"
            placeholder="Add Reattested Date"
          />
          <br />
          <Error field="reattested_date" />
        </div>
      </div>
      <div>
        <Button
          type="button"
          className="c-btn c-info form-button fs-16 demi-bold-text mr-10"
          style={{ maxWidth: "125px" }}
          onClick={() => handleSubmit()}
        >
          Submit
        </Button>
        <Button
          type="button"
          className="c-btn c-secondary form-button fs-16 demi-bold-text"
          style={{ maxWidth: "125px" }}
          onClick={props.toggle}
        >
          Close
        </Button>
      </div>
      <Toaster ref={toaster} />
    </form>
  );
};

export default compose(enhancer)(CaqhModel);
