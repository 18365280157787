import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    insurance: Yup.array().of(
      Yup.object().shape({
        insurance_id: Yup.string().required("This field is required"),
        effective_date: Yup.date()
          .required("This field is required")
          .nullable()
      })
    )
  }),
  mapPropsToValues: props => ({
    insurance: []
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
