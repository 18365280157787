import axios from "axios";
import { baseUrl } from "../helper/constant";
import Header from "./Header";
import EncryptDecrypt from "./EncryptDecrypt";

export default class AdminServices {
  static list(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/admin/static-content/list`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static add(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/admin/static-content/add`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static edit(params) {
    Header.setHeaders();
    return axios.put(
      `${baseUrl}/admin/static-content/edit`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static view(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/admin/view-static-content`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static visitNoteTemplatelist(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/visit-note-template/list`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static addVisitNoteTemplate(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/visit-note-template/add`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static editVisitNoteTemplate(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/visit-note-template/edit`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static viewVisitNoteTemplate(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/visit-note-template/get`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static deleteVisitNoteTemplate(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/visit-note-template/delete`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }
}
