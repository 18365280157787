import React, { Fragment, useEffect, useRef, useState } from "react";
import { compose } from "redux";
import enhancer from "./validator";
import Button from "../button/Button";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import CategoryServices from "../../api/CategoryServices";
import FormServices from "../../api/FormServices";
import Toaster from "../../components/common/Toaster";
import { Input } from "reactstrap";
import { Document, PDFViewer, Page } from "@react-pdf/renderer";

const FormForm = props => {
  const {
    handleChange,
    handleBlur,
    errors,
    setFieldTouched,
    setFieldValue,
    touched,
    submitCount,
    onSubmit,
    values,
    action
  } = props;
  const toaster = useRef(null);
  const [serviceList, setServiceList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFlieChanged, setIsFlieChanged] = useState(false);
  const [imageObj, setImageObj] = useState(null);
  const [imgError, setImgError] = useState(null);
  const [serviceId, setServiceId] = useState(null);

  useEffect(() => {
    CategoryServices.serviceDropdown({ is_general: true })
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setServiceList([...data.data]);
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      });
  }, []);

  useEffect(() => {
    if (props?.authData?.adminRoleId > 2) {
      setFieldValue("service", props?.authData?.service_id);
    }
  }, [props?.authData]);

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {field1 !== "image"
            ? errors[field1]
            : !imgError
            ? errors[field1]
            : imgError}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const handleImage = (e, setValue) => {
    if (e.target.files[0]) {
      let fileObj = e.target.files[0];
      if (fileObj?.type === "application/pdf") {
        let reader = new FileReader();
        reader.readAsDataURL(fileObj);
        reader.onloadend = () => {
          setImageObj(reader.result);
        };
        setValue("image", fileObj);
        setImgError(null);
      } else {
        setImageObj(null);
        setValue("image", "");
        setImgError("Only Pdf files are allowed.");
      }
    }
  };

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;

    if (isValid) {
      // onSubmit(values, action);
      setIsLoading(true);
      if (action === "add") {
        const params = {
          name: values.name,
          service_id: values.service
        };

        const formdata = new FormData();
        const encryptData = EncryptDecrypt.encrypt(params);
        formdata.append("data", encryptData?.data);
        if (values?.image) formdata.append("data1", values?.image);

        FormServices.add(formdata)
          .then(res => {
            let data = EncryptDecrypt.decrypt(res.data);
            if (data.status) {
              if (toaster.current) toaster.current.success(data.message);
              onSubmit(values, action);
            } else {
              if (toaster.current) toaster.current.error(data.message);
            }
            setIsLoading(false);
          })
          .catch(err => {
            if (toaster.current) toaster.current.error(err.response);
            setIsLoading(false);
          });
      } else if (action === "edit") {
        const params = {
          form_id: values.id,
          name: values.name,
          service_id: values.service
        };

        const formdata = new FormData();
        const encryptData = EncryptDecrypt.encrypt(params);
        formdata.append("data", encryptData?.data);
        if (isFlieChanged && values?.image)
          formdata.append("data1", values?.image);

        FormServices.edit(formdata)
          .then(res => {
            let data = EncryptDecrypt.decrypt(res.data);
            if (data.status) {
              if (toaster.current) toaster.current.success(data.message);
              onSubmit(values, action);
            } else {
              if (toaster.current) toaster.current.error(data.message);
            }
            setIsLoading(false);
          })
          .catch(err => {
            if (toaster.current) toaster.current.error(err.response);
            setIsLoading(false);
          });
      }
    }

    handleSubmit();
  };

  return (
    <div>
      <form>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Type <span className="asterisk">*</span>
          </label>
          <input
            type="text"
            className="form-control react-form-input"
            id="type"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.type}
            defaultValue={"standard"}
            disabled={true}
          />
          <Error field="type" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Name <span className="asterisk">*</span>
          </label>
          <input
            type="text"
            className="form-control react-form-input"
            id="name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            placeholder="Add Form Name"
          />
          <Error field="name" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Service<span className="asterisk">*</span>
          </label>
          <Input
            type="select"
            name="service"
            id="service"
            onChange={handleChange}
            value={values.service ? values.service : serviceId}
            onBlur={handleBlur}
            disabled={props?.authData?.adminRoleId > 2}
          >
            <option value="">Select Service</option>
            {serviceList.map((service, i) => {
              return (
                <Fragment>
                  <option key={`option${i}`} value={service.id}>
                    {service.name}
                  </option>
                </Fragment>
              );
            })}
          </Input>
          <Error field="service" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text c-btn c-primary demi-bold-text mt-15 wp-17">
            Choose File
            <input
              id="image"
              className="file-upload__input"
              name="image"
              type="file"
              onChange={e => {
                handleImage(e, setFieldValue);
                setFieldTouched("image", true, true);
                setIsFlieChanged(true);
              }}
              onBlur={handleBlur}
              required={true}
              accept=".pdf"
            />
          </label>
          <div>
            <Error field="image" />
          </div>
          {action !== "add" && !imgError && props?.editedData && (
            <>
              <div>
                <PDFViewer
                  src={
                    imageObj
                      ? imageObj + "#toolbar=0"
                      : values?.image + "#toolbar=0"
                  }
                  width="320"
                  height="350"
                >
                  <Document>
                    <Page> </Page>
                  </Document>
                </PDFViewer>
              </div>
              {!imageObj && values?.image && (
                <div style={{ fontWeight: "bolder", textAlign: "left" }}>
                  <a href={values?.image} target="_blank">
                    URL to the file
                  </a>
                </div>
              )}
            </>
          )}
          {action === "add" && imageObj && (
            <>
              <div>
                <PDFViewer
                  src={imageObj + "#toolbar=0"}
                  width="320"
                  height="350"
                >
                  <Document>
                    <Page> </Page>
                  </Document>
                </PDFViewer>
              </div>
              <div>{values?.image?.name}</div>
            </>
          )}
        </div>
        <div>
          <Button
            type="button"
            className="c-btn c-info form-button fs-16 demi-bold-text"
            style={{ maxWidth: "125px" }}
            onClick={() => handleSubmit()}
            loading={isLoading}
            ddisabled={isLoading}
            dataStyle={"expand-right"}
          >
            Submit
          </Button>
        </div>
      </form>
      <Toaster ref={toaster} />
    </div>
  );
};

export default compose(enhancer)(FormForm);
