import styled from "styled-components";

const UserWrapper = styled.div`
  .module-header {
    display: flex;
    align-items: center;
  }

  .custom-react-table-theme-class {
    .rt-tbody {
      .rt-td {
        font-family: "montserrat-medium";
        color: #757575;
      }
    }
  }

  .Table__itemCount {
    font-size: 14px;
  }

  .Table__pagination {
    display: flex;
    justify-content: flex-end;
    padding: 20px 10px 0px;
  }

  .Table__pageButton {
    font-size: 18px;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: #757575 !important;
    margin: 0 5px;
  }

  .Table__pageButton:disabled {
    cursor: not-allowed;
    color: gray;
  }

  .Table__pageButton--active {
    font-weight: bold;
    background: ${props => props.sidebarTheme.activeColor};
    color: ${props => props.sidebarTheme.textColor} !important;
    width: 30px;
    height: 30px;
    border-radius: 6px;
  }

  .tabl-search {
    &:focus {
      outline: 0;
    }
  }

  .back-icon {
    position: absolute;
    right: 15px;
    bottom: 20px;
    color: rgba(255, 255, 255, 0.85) !important;
  }

  .-sort-desc {
    box-shadow: none !important;
    &:before {
      content: "▼";
      float: right;
      margin-right: 14px;
      color: #58585a !important;
    }
  }

  .-sort-asc {
    box-shadow: none !important;
    &:before {
      content: "▲";
      float: right;
      margin-right: 14px;
      color: #58585a !important;
    }
  }
`;

export default UserWrapper;
