import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import RdServices from "../../api/RdServices";
import Loader from "../common/Loader";
import Toaster from "../common/Toaster";
import {
  TABLE,
  ACTIVE_ACCEPTING_CLIENT,
  ACTIVE_NOT_ACCEPTING_CLIENT,
  TEMPORARY_INACTIVE,
  PERMANENTALY_INACTIVE,
  MODULE_NAMES,
  PERMISSION_TYPE,
  SERVICE_NAME
} from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import { Input } from "reactstrap";
import GroupServices from "../../api/GroupServices";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
import { getCalendlyStatusAndTime } from "util/calendly";
import CategoryServices from "api/CategoryServices";
import CommonModel from "components/common/CommonModel";
import { CommonLookUpSelectionModal } from "components/common/CommonLookUpSelectionModal";
import { hasAccess } from "util/sidebar";

const RdTable = props => {
  const { permissions } = props;

  const toaster = useRef(null);
  const [tblData, settblData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("id");
  const [sortBy, setSortBy] = useState("ASC");
  const [customModal, setCustomModal] = useState(undefined);
  const [insuranceList, setInsuranceList] = useState([]);
  const [insuranceId, setInsuranceId] = useState(null);
  const [insuranceName, setInsuranceName] = useState(null);
  const [insuranceDropdownOpen, setInsuranceDropdownOpen] = useState(false);
  const [serviceId, setServiceId] = useState(null);
  const [serviceName, setServiceName] = useState(null);
  const [serviceDropdownOpen, setServiceDropdownOpen] = useState(false);
  const [exportService, setExportService] = useState(null);
  const [exportServiceDropdownOpen, setExportServiceDropdownOpen] = useState(
    false
  );
  const [isExportModal, setIsExportModal] = useState(false);

  const [stateList, setStateList] = useState([]);
  const [stateId, setStateId] = useState(null);
  const [stateName, setStateName] = useState(null);
  const [stateDropdownOpen, setStateDropdownOpen] = useState(false);
  const [isLookup, setIsLookup] = useState(false);
  const [loopkupModal, setLookupModal] = useState(false);
  const [statusText, setStatusText] = useState("");
  const [statusValue, setStatusValue] = useState("");
  const [parentDropdownOpen, setParentDropdownOpen] = useState(false);
  const [serviceList, setServiceList] = useState([]);

  useEffect(() => {
    CategoryServices.serviceDropdown()
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setServiceList([...data.data]);
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      });
  }, []);

  useEffect(() => {
    //getList(field, sortBy, searchText, activePage + 1);
    setPages(10);

    GroupServices.insuranceList().then(res => {
      let data = EncryptDecrypt.decrypt(res.data);
      setInsuranceList(data.data);
    });

    RdServices.stateList().then(res => {
      let data = EncryptDecrypt.decrypt(res.data);
      setStateList(data.data);
    });
  }, []);

  useEffect(() => {
    getList(field, sortBy, searchText, activePage + 1);
    setPages(10);
  }, [stateId, insuranceId, statusValue, serviceId]);

  const toggleInsurance = () =>
    setInsuranceDropdownOpen(prevState => !prevState);

  const toggleState = () => setStateDropdownOpen(prevState => !prevState);

  const toggleService = () => setServiceDropdownOpen(prevState => !prevState);

  const toggleOne = () => setParentDropdownOpen(prevState => !prevState);

  const toggleExportService = () =>
    setExportServiceDropdownOpen(prevState => !prevState);

  const handleApproveChange = (data, e) => {
    let is_approved = e.currentTarget.value;
    const params = {
      is_approved: is_approved,
      admin_id: data.id
    };
    setLoader(true);
    RdServices.approve(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current)
            toaster.current.success(
              is_approved === "pending"
                ? "Rd has been pending successfully."
                : is_approved === "approved"
                ? "Rd has been approved successfully."
                : "Rd has been rejected successfully."
            );
          setCustomModal(false);
          getList(field, sortBy, searchText, activePage + 1, 1);
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
          setLoader(false);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
        setLoader(false);
      });
  };

  const toggleModal = () => {
    setIsExportModal(prev => !prev);
  };

  const toggleLookup = () => {
    setLookupModal(prev => !prev);
  };

  const columns = [
    {
      Header: "ID",
      accessor: "id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 100,
      sortable: true
    },
    {
      Header: "Provider Code",
      accessor: "rd_detail.rd_unique_id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 150,
      sortable: true,
      Cell: props => (
        <div className="react-action-class">
          {props.original.rd_detail?.rd_unique_id}
        </div>
      ) // Custom cell components!
    },
    {
      Header: "First Name",
      accessor: "first_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 250,
      sortable: true
    },
    {
      Header: "Last Name",
      accessor: "last_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 250,
      sortable: true
    },
    {
      Header: "Email",
      accessor: "email",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 250,
      sortable: true
    },
    {
      Header: "Service",
      accessor: "rd_detail.service.name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 150,
      sortable: false
    },
    {
      Header: "Client Max",
      accessor: "rd_detail.max_client",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 150,
      sortable: true,
      Cell: props => (
        <div className="react-action-class">
          {props.original.rd_detail?.max_client}
        </div>
      )
    },
    {
      Header: "Total Client",
      accessor: "total_client",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 150,
      sortable: true
    },
    {
      Header: "Active Client",
      accessor: "active_client",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 150,
      sortable: true
    },
    {
      Header: "Approved",
      accessor: "is_approved",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 200,
      sortable: false,
      Cell: props => (
        <div className="react-action-class">
          {props.original.rd_detail &&
          props.original.rd_detail?.step?.split(",")?.length === 6 ? (
            <Input
              type="select"
              name="is_approved"
              id="is_approved"
              onChange={e => handleApproveChange(props.original, e)}
              value={props.original.is_approved}
              // onBlur={handleBlur}
            >
              <option value="pending">Pending</option>
              <option value="approved">Approved</option>
              <option value="reject">Reject</option>
            </Input>
          ) : (
            "Profile In-progress"
          )}
        </div>
      ) // Custom cell components!
    },

    {
      Header: "Status",
      accessor: "status",
      className: "",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 270,
      Cell: props => (
        <div className="react-action-class">
          {props.original.status === ACTIVE_ACCEPTING_CLIENT
            ? "Active, accepting new clients"
            : props.original.status === ACTIVE_NOT_ACCEPTING_CLIENT
            ? "Active, not accepting new clients"
            : props.original.status === TEMPORARY_INACTIVE
            ? "Temporarily inactive"
            : props.original.status === PERMANENTALY_INACTIVE
            ? "Permanently inactive"
            : ""}
        </div>
      ) // Custom cell components!
    },
    {
      Header: "Calendly Status",
      accessor: "status",
      className: "",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 180,
      Cell: props => (
        <div className="flex align-items-center">
          <div
            style={{
              height: 16,
              width: 16,
              backgroundColor:
                getCalendlyStatusAndTime(props.original.rd_calendly_status)
                  .status === "Not Connected"
                  ? "#df5151"
                  : "#AECE38",
              borderRadius: "50%",
              marginRight: 4
            }}
          />
          {getCalendlyStatusAndTime(props.original.rd_calendly_status).status}
          {/* Not Connected */}
        </div>
      ) // Custom cell components!
    },
    {
      Header: "Action",
      accessor: "action",
      className: "",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 150,
      Cell: colProps => (
        <div className="react-action-class">
          <Button
            className="c-btn c-info mr-10"
            onClick={() => formAction("view", colProps.original)}
          >
            <div className="fs-14 medium-text">
              <i className="fas fa-eye" />
            </div>
          </Button>
          {hasAccess(
            MODULE_NAMES.PROVIDER,
            PERMISSION_TYPE.EDIT,
            permissions
          ) ? (
            <Button
              className="c-btn c-success mr-10"
              onClick={() => formAction("edit", colProps.original)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-edit" />
              </div>
            </Button>
          ) : (
            ""
          )}
        </div>
      ) // Custom cell components!
    }
  ];

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  const formAction = (action, data = null) => {
    // Here you can view the data and make forward action for edit data
    if (action === "add") {
      props.history.push("/rd/add");
    } else if (action === "edit") {
      props.history.push(`/rd/edit/${data.id}`, { view: "profile" });
    } else if (action === "view") {
      props.history.push(`/rd/view/${data.id}`, {
        type: data?.rd_detail?.type,
        service: data?.rd_detail?.service
      });
    }
  };

  function getList(
    field = "created_at",
    sortBy = "DESC",
    search = "",
    page = "1"
  ) {
    const params = {
      sort_by: field,
      order: sortBy,
      search: search,
      page: page,
      expire:
        props.location.search && props.location.search === "?expire"
          ? true
          : false,
      stateId: stateId,
      insuranceId: insuranceId,
      status: statusValue,
      service_id:
        props?.authData?.adminRoleId < 2 ? serviceId : props?.authData?.service,
      practice_id:
        props?.authData?.adminRoleId === 6 && props?.authData?.practice
          ? props?.authData?.practice
          : undefined
    };
    setLoader(true);
    RdServices.list(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count / TABLE.LIMIT));
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
        setLoader(false);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.message);
        setLoader(false);
      });
  }

  const handleLookup = () => {
    setLookupModal(true);
  };

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Provider List
            </div>
            <div className="mr-10">
              <input
                value={searchText}
                onChange={e => {
                  setSearchText(e.target.value);
                  const search = e.target.value;
                  setLoader(true);
                  setActivePage(0);
                  setTimeout(() => {
                    getList(field, sortBy, search, 1);
                  }, 500);
                }}
                type="text"
                placeholder="Search..."
                className="fs-14 medium-text plr-10 form-control react-form-input"
              />
            </div>
            <div>
              {hasAccess(
                MODULE_NAMES.PROVIDER,
                PERMISSION_TYPE.ADD,
                permissions
              ) && (
                <Button
                  className="c-btn c-primary ma-5"
                  onClick={() => formAction("add")}
                >
                  <i className="fas fa-plus mr-10" />
                  Add Provider
                </Button>
              )}
            </div>
            <div>
              {hasAccess(
                MODULE_NAMES.PROVIDER,
                PERMISSION_TYPE.LOOKUP,
                permissions
              ) && (
                <Button
                  className="c-btn c-primary ma-5"
                  onClick={handleLookup}
                  // disabled={isLookup}
                >
                  <i className="fas fa-bars mr-10" />
                  Lookup
                </Button>
              )}
            </div>
          </div>
          <div className="roe-card-header module-header">
            <div className="fs-14 mr-10">
              <span className="fs-16">Insurance</span>
              <Dropdown isOpen={insuranceDropdownOpen} toggle={toggleInsurance}>
                <DropdownToggle caret size="md">
                  {insuranceId ? insuranceName : "All"}
                </DropdownToggle>
                <DropdownMenu
                  modifiers={{
                    setMaxHeight: {
                      enabled: true,
                      order: 890,
                      fn: data => {
                        return {
                          ...data,
                          styles: {
                            ...data.styles,
                            overflow: "auto",
                            maxHeight: "300px"
                          }
                        };
                      }
                    }
                  }}
                >
                  <DropdownItem
                    key="-1"
                    onClick={() => {
                      setInsuranceId("");
                      setInsuranceName("All");
                      setActivePage(0);
                    }}
                  >
                    All
                  </DropdownItem>
                  {insuranceList.length > 0 &&
                    insuranceList.map((result, i) => {
                      return (
                        <DropdownItem
                          key={i}
                          onClick={() => {
                            setInsuranceId(result.id);
                            setInsuranceName(`${result.name}`);
                            setActivePage(0);
                          }}
                        >
                          {result.name}
                        </DropdownItem>
                      );
                    })}
                </DropdownMenu>
              </Dropdown>
            </div>
            <div className="fs-14 mr-10">
              <span className="fs-16">State</span>
              <Dropdown isOpen={stateDropdownOpen} toggle={toggleState}>
                <DropdownToggle caret size="md">
                  {stateId ? stateName : "All"}
                </DropdownToggle>
                <DropdownMenu
                  modifiers={{
                    setMaxHeight: {
                      enabled: true,
                      order: 890,
                      fn: data => {
                        return {
                          ...data,
                          styles: {
                            ...data.styles,
                            overflow: "auto",
                            maxHeight: "300px"
                          }
                        };
                      }
                    }
                  }}
                >
                  <DropdownItem
                    key="-1"
                    onClick={() => {
                      setStateId("");
                      setStateName("All");
                      setActivePage(0);
                    }}
                  >
                    All
                  </DropdownItem>
                  {stateList.length > 0 &&
                    stateList.map((result, i) => {
                      return (
                        <DropdownItem
                          key={i}
                          onClick={() => {
                            setStateId(result.id);
                            setStateName(`${result.name}`);
                            setActivePage(0);
                          }}
                        >
                          {result.name}
                        </DropdownItem>
                      );
                    })}
                </DropdownMenu>
              </Dropdown>
            </div>
            <div className="fs-16 mr-10">
              <span className="fs-16">Status</span>
              <Dropdown isOpen={parentDropdownOpen} toggle={toggleOne}>
                <DropdownToggle caret size="md">
                  {statusText ? statusText : "All"}
                </DropdownToggle>
                <DropdownMenu
                  modifiers={{
                    setMaxHeight: {
                      enabled: true,
                      order: 890,
                      fn: data => {
                        return {
                          ...data,
                          styles: {
                            ...data.styles,
                            overflow: "auto",
                            maxHeight: "300px"
                          }
                        };
                      }
                    }
                  }}
                >
                  <DropdownItem
                    key="All"
                    onClick={() => {
                      setStatusText("All");
                      setStatusValue("");
                      setActivePage(0);
                    }}
                  >
                    All
                  </DropdownItem>
                  <DropdownItem
                    key="Active, accepting new client"
                    onClick={() => {
                      setStatusText("Active, accepting new client");
                      setStatusValue("1");
                      setActivePage(0);
                    }}
                  >
                    Active, accepting new client
                  </DropdownItem>
                  <DropdownItem
                    key="Active, not accepting new client"
                    onClick={() => {
                      setStatusText("Active, not accepting new client");
                      setStatusValue("2");
                      setActivePage(0);
                    }}
                  >
                    Active, not accepting new client
                  </DropdownItem>
                  <DropdownItem
                    key="Temporary inactive"
                    onClick={() => {
                      setStatusText("Temporary Inactive");
                      setStatusValue("3");
                      setActivePage(0);
                    }}
                  >
                    Temporary Inactive
                  </DropdownItem>
                  <DropdownItem
                    key="Permanently Inactive"
                    onClick={() => {
                      setStatusText("Permanently Inactive");
                      setStatusValue("4");
                      setActivePage(0);
                    }}
                  >
                    Permanently Inactive
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            {props?.authData?.adminRoleId <= 2 ? (
              <div className="fs-14 mr-10">
                <span className="fs-16">Service</span>
                <Dropdown isOpen={serviceDropdownOpen} toggle={toggleService}>
                  <DropdownToggle caret size="md">
                    {serviceId ? serviceName : "All"}
                  </DropdownToggle>
                  <DropdownMenu
                    modifiers={{
                      setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: data => {
                          return {
                            ...data,
                            styles: {
                              ...data.styles,
                              overflow: "auto",
                              maxHeight: "300px"
                            }
                          };
                        }
                      }
                    }}
                  >
                    <DropdownItem
                      key="-1"
                      onClick={() => {
                        setServiceId("");
                        setServiceName("All");
                        setActivePage(0);
                      }}
                    >
                      All
                    </DropdownItem>
                    {serviceList.length > 0 &&
                      serviceList.map((result, i) => {
                        return (
                          <DropdownItem
                            key={i}
                            onClick={() => {
                              setServiceId(result.id);
                              setServiceName(`${result.name}`);
                              setActivePage(0);
                            }}
                          >
                            {result.name}
                          </DropdownItem>
                        );
                      })}
                  </DropdownMenu>
                </Dropdown>
              </div>
            ) : null}
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              LoadingComponent={Loader}
              onFetchData={onChangePageGetData}
              onPageChange={pageIndex => {
                setActivePage(pageIndex);
                getList(field, sortBy, searchText, pageIndex + 1);
              }}
              onSortedChange={sortProperties => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getList(sortProperties[0].id, sort, searchText, 1);
                // alert("sort handler", sortProperties);
                // const orderByDirection = sortProperties[0].desc ? 1 : 0;

                // updateMapStateInfoOrderByColumn(sortProperties[0].id, orderByDirection);
              }}
            />
          </div>
        </div>
      </div>
      <Toaster ref={toaster} />
      <CommonModel
        modal={isExportModal}
        toggle={toggleModal}
        children={
          <div className="d-flex flex-x justify-content-between align-items-center">
            <div className="fs-14 mr-10">
              <span className="fs-16">Select Service for AMD Key</span>
              <Dropdown
                isOpen={exportServiceDropdownOpen}
                toggle={toggleExportService}
              >
                <DropdownToggle caret size="md">
                  {exportService ? exportService?.name : "Select Service"}
                </DropdownToggle>
                <DropdownMenu
                  modifiers={{
                    setMaxHeight: {
                      enabled: true,
                      order: 890,
                      fn: data => {
                        return {
                          ...data,
                          styles: {
                            ...data.styles,
                            overflow: "auto",
                            maxHeight: "300px"
                          }
                        };
                      }
                    }
                  }}
                >
                  {serviceList.length > 0 &&
                    serviceList.map((result, i) => {
                      return (
                        <DropdownItem
                          key={i}
                          onClick={() => {
                            setExportService({
                              id: result.id,
                              name: result.name
                            });
                          }}
                        >
                          {result.name}
                        </DropdownItem>
                      );
                    })}
                </DropdownMenu>
              </Dropdown>
            </div>
            <div>
              <Button
                className="c-btn c-primary ma-5"
                onClick={() => handleLookup()}
                disabled={isLookup || !exportService}
                title={
                  !exportService && "Select Service Type for AMD Key first!"
                }
              >
                <i className="fas fa-bars mr-10" />
                Lookup
              </Button>
            </div>
          </div>
        }
        header={"Select Service Type for AMD Key"}
      />
      <CommonModel
        modal={loopkupModal}
        toggle={toggleLookup}
        size={"xl"}
        children={
          <CommonLookUpSelectionModal
            onClose={toggleLookup}
            type={"PROVIDER"}
            authData={props?.authData}
          />
        }
      />
    </div>
  );
};

export default withRouter(RdTable);
