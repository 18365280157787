import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import ReportServices from "../../api/ReportServices";
import CategoryServices from "../../api/CategoryServices";
import Loader from "../common/Loader";
import Toaster from "../common/Toaster";
import { TABLE } from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
import Button from "components/button/Button";
import ClientServices from "../../api/ClientServices";

const NutritionActivationDetailTable = props => {
  const toaster = useRef(null);
  const [tblData, settblData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("id");
  const [sortBy, setSortBy] = useState("ASC");

  const [groupList, setGroupList] = useState([]);
  const [groupId, setGroupId] = useState(null);
  const [groupName, setGroupName] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const [parentDropdownOpen, setParentDropdownOpen] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  useEffect(() => {
    getList(field, sortBy, searchText, activePage + 1);
    setPages(10);

    ClientServices.groupList({ service_id: 1 }).then(result => {
      let data = EncryptDecrypt.decrypt(result.data);
      setGroupList(data.data);
    });
  }, []);

  useEffect(() => {
    CategoryServices.listCategory({ service_id: 1 })
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status === 1) {
          setCategoryList(data.data);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });
    //
  }, []);

  useEffect(() => {
    getList(field, sortBy, searchText, activePage + 1);
    setPages(10);
  }, [groupId]);

  const toggleOne = () => setParentDropdownOpen(prevState => !prevState);

  const columns = [
    {
      Header: "Group ID",
      accessor: "id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 150
    },
    {
      Header: "Group Name",
      accessor: "name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200
    },
    {
      Header: "Total Clients",
      accessor: "total_client",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      width: 150
    },
    {
      Header: "Total Active Clients",
      accessor: "total_active_client",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      width: 200
    }
  ];

  const data =
    categoryList?.length > 0 &&
    categoryList?.map((category, i) => {
      columns.push({
        Header: "Total Clients - " + category?.name,
        accessor: category?.name,
        className: "",
        filterable: false,
        headerClassName: "react-table-header-class",
        sortable: false,
        width: 320
      });
    });

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  function exportList(
    field = "name",
    sortBy = "DESC",
    search = "",
    page = "1"
  ) {
    setIsExporting(true);
    const params = {
      sort_by: field,
      order: sortBy,
      search: search,
      page: page,
      group_id: groupId
    };
    ReportServices.activationDetailListCsvNutrition(params)
      .then(res => {
        setIsExporting(false);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error("Something went wrong!");
        setIsExporting(false);
      });
  }

  const formAction = (action, data = null) => {
    // Here you can view the data and make forward action for edit data
    if (action === "export") {
      exportList(field, sortBy, searchText, activePage + 1);
    }
  };

  function getList(field = "name", sortBy = "DESC", search = "", page = "1") {
    const params = {
      sort_by: field,
      order: sortBy,
      search: search,
      page: page,
      group_id: groupId
    };
    setLoader(true);
    ReportServices.activationDetailListNutrition(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count / TABLE.LIMIT));
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });
  }

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Activation Report - Nutrition
            </div>
            <div>
              <Button
                className="c-btn c-primary ma-5"
                onClick={() => formAction("export")}
                disabled={!tblData?.length || isExporting}
              >
                <i className="fas fa-download mr-10" />
                {isExporting ? "Exporting.." : "Export"}
              </Button>
            </div>
          </div>
          <div className="roe-card-header module-header">
            <div className="d-flex">
              <div className="fs-14 mr-10">
                <span className="fs-16">Group</span>
                <Dropdown isOpen={parentDropdownOpen} toggle={toggleOne}>
                  <DropdownToggle caret size="md">
                    {groupName ? groupName : "Select Group"}
                  </DropdownToggle>
                  <DropdownMenu
                    modifiers={{
                      setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: data => {
                          return {
                            ...data,
                            styles: {
                              ...data.styles,
                              overflow: "auto",
                              maxHeight: "300px"
                            }
                          };
                        }
                      }
                    }}
                  >
                    <DropdownItem
                      key="0"
                      onClick={() => {
                        setActivePage(0);
                        setGroupId("");
                        setGroupName("All");
                      }}
                    >
                      All
                    </DropdownItem>
                    {groupList.length > 0 &&
                      groupList.map((result, i) => {
                        return (
                          <DropdownItem
                            key={i}
                            onClick={() => {
                              setActivePage(0);
                              setGroupId(result.id);
                              setGroupName(result.name);
                            }}
                          >
                            {result.name}
                          </DropdownItem>
                        );
                      })}
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              LoadingComponent={Loader}
              onFetchData={onChangePageGetData}
              onPageChange={pageIndex => {
                setActivePage(pageIndex);
                getList(field, sortBy, searchText, pageIndex + 1);
              }}
              onSortedChange={sortProperties => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getList(sortProperties[0].id, sort, searchText, 1);
                // alert("sort handler", sortProperties);
                // const orderByDirection = sortProperties[0].desc ? 1 : 0;

                // updateMapStateInfoOrderByColumn(sortProperties[0].id, orderByDirection);
              }}
            />
          </div>
        </div>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};

export default withRouter(NutritionActivationDetailTable);
