import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import Loader from "../common/Loader";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
import Toaster from "../common/Toaster";
import {
  MODULE_NAMES,
  PERMISSION_TYPE,
  SERVICE_NAME,
  TABLE
} from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import moment from "moment-timezone";
import ClientServices from "../../api/ClientServices";
import CategoryServices from "api/CategoryServices";
import Button from "components/button/Button";
import CommonModel from "components/common/CommonModel";
import EditProviderModal from "components/viewclient/EditProviderModal";
import ArchievedNoteModal from "./ArchievedNoteModal";
import CustomModal from "components/common/CustomModal";
import { hasAccess } from "util/sidebar";

const DiscoveryQueueTable = ({ isArchieved = false, ...props }) => {
  const toaster = useRef(null);

  const [tblData, settblData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [field, setField] = useState("discovery_queue.created_at");
  const [sortBy, setSortBy] = useState("DESC");
  const [serviceId, setServiceId] = useState(null);
  const [serviceName, setServiceName] = useState(null);
  const [serviceList, setServiceList] = useState([]);
  const [serviceDropdownOpen, setServiceDropdownOpen] = useState(false);
  const [providerModal, setProviderModal] = useState(false);
  const [userData, setUserData] = useState(null);
  const [noteModal, setNoteModal] = useState(false);
  const [noteData, setNoteData] = useState(null);
  const [customModal, setCustomModal] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  useEffect(() => {
    CategoryServices.serviceDropdown()
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setServiceList([...data.data]);
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error("Could not fetch services!");
      });
  }, []);

  useEffect(() => {
    getList(field, sortBy, activePage + 1);
    setPages(10);
  }, [serviceId, props?.activeTab]);

  const toggleService = () => setServiceDropdownOpen(prevState => !prevState);

  const columns = [
    {
      Header: "Client ID",
      accessor: "id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 120
    },
    {
      Header: "First Name",
      accessor: "first_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true
    },
    {
      Header: "Last Name",
      accessor: "last_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true
    },
    {
      Header: "Email",
      accessor: "email",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 220
    },
    {
      Header: "Zip Code",
      accessor: "zipcode",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true
    },
    {
      Header: "State",
      accessor: "state.name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true
    },
    {
      Header: "Insurance",
      accessor: "insurance",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      Cell: props =>
        props?.original?.user_insurance?.insurance?.name
          ? props?.original?.user_insurance?.insurance?.name
          : ""
    },
    {
      Header: "Service",
      accessor: "discovery_queue.service.name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      width: 120
    },
    {
      Header: "Date",
      accessor: "discovery_queue.created_at",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      Cell: ({ value }) =>
        value ? (
          <div>{moment.tz(value, moment.tz.guess()).format("MM/DD/YYYY")}</div>
        ) : null
    },
    {
      Header: "Action",
      accessor: "action",
      className: "",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 280,
      Cell: colProps => (
        <div className="react-action-class">
          {hasAccess(
            MODULE_NAMES.DISCOVERY_QUEUE,
            PERMISSION_TYPE.EDIT,
            props.permissions
          ) && (
            <>
              <Button
                className="c-btn c-success mr-10"
                onClick={() => formActionCare(colProps.original)}
              >
                <div className="fs-14 medium-text">ASSIGN PROVIDER</div>
              </Button>
              {isArchieved && (
                <Button
                  className="c-btn c-focus mr-10"
                  onClick={() => {
                    setNoteModal(true);
                    setNoteData({ ...colProps.original });
                  }}
                >
                  <div className="fs-14 medium-text" title="Edit">
                    <i className="fas fa-edit" /> Note
                  </div>
                </Button>
              )}
            </>
          )}

          {!isArchieved &&
            hasAccess(
              MODULE_NAMES.DISCOVERY_QUEUE,
              PERMISSION_TYPE.STATUS,
              props.permissions
            ) && (
              <Button
                className="c-btn c-focus mr-10"
                onClick={() => hanleArchive(colProps.original)}
              >
                <div className="fs-14 medium-text">
                  <i className="fas fa-archive mr-2"></i>Archive
                </div>
              </Button>
            )}
        </div>
      )
    }
  ];

  if (isArchieved) {
    let notes = {
      Header: "Notes",
      accessor: "discovery_queue.note",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 200,
      sortable: true
    };
    columns.splice(9, 0, notes);
  }

  const archieveStatusHandler = () => {
    const params = {
      is_archive: true,
      user_id: userData?.id
    };
    setLoader(true);
    ClientServices.editDiscoveryQueueUserStatus(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current)
            toaster.current.success("Successfully Archived!");
          setCustomModal(false);
          getList(field, sortBy, activePage + 1);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
        setLoader(false);
      })
      .catch(err => {
        setCustomModal(false);
        if (toaster.current) toaster.current.error(err.response);
        setLoader(false);
      });
  };

  const formActionCare = data => {
    setProviderModal(true);
    setUserData({
      service: data?.discovery_queue.service_id,
      userId: data?.id
    });
  };

  const hanleArchive = data => {
    setUserData({ ...data });
    setCustomModal(true);
  };

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  const handleSubmit = () => {
    getList(field, sortBy, activePage + 1);
    setPages(10);
  };

  function getList(field = "title", sortBy = "DESC", page = 1) {
    const params = {
      sort_by: field,
      order: sortBy,
      page: page,
      service_id: props.auth?.admin_role_id > 2 ? props?.service_id : serviceId,
      is_archive: isArchieved,
      practice_id:
        props.auth && props.auth?.admin_role_id === 6 && props.auth?.practice
          ? props.auth?.practice
          : undefined
    };
    setLoader(true);
    ClientServices.getDiscoveryQueue(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count / TABLE.LIMIT));
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
          setLoader(false);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.message);
        setLoader(false);
      });
  }

  const toggleCustomModal = () => {
    setCustomModal(prev => !prev);
  };

  const toggleNoteModal = () => {
    setNoteModal(prev => !prev);
  };

  function exportList(field = "title", sortBy = "DESC") {
    setIsExporting(true);
    const params = {
      sort_by: field,
      order: sortBy,
      service_id: props.auth?.admin_role_id > 2 ? props?.service_id : serviceId,
      is_archive: isArchieved,
      practice_id:
        props.auth && props.auth?.admin_role_id === 6 && props.auth?.practice
          ? props.auth?.practice
          : undefined
    };
    ClientServices.exportDiscoveryQueue(params, isArchieved)
      .then(res => {
        setIsExporting(false);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error("Something went wrong!");
        setIsExporting(false);
      });
  }

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span>{" "}
              {!isArchieved ? "Discovery" : "Archived"} Queue List
            </div>

            {props.auth.admin_role_id <= 2 && (
              <div className="fs-14  mr-10">
                <Dropdown isOpen={serviceDropdownOpen} toggle={toggleService}>
                  <DropdownToggle caret size="md">
                    {serviceName ? serviceName : "Select Service"}
                  </DropdownToggle>
                  <DropdownMenu
                    modifiers={{
                      setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: data => {
                          return {
                            ...data,
                            styles: {
                              ...data.styles,
                              overflow: "auto",
                              maxHeight: "300px"
                            }
                          };
                        }
                      }
                    }}
                  >
                    <DropdownItem
                      key="-1"
                      onClick={() => {
                        setServiceId("");
                        setServiceName("All");
                      }}
                    >
                      All
                    </DropdownItem>
                    {serviceList.length > 0 &&
                      serviceList.map((result, i) => {
                        return (
                          <DropdownItem
                            key={i}
                            onClick={() => {
                              setServiceId(result.id);
                              setServiceName(`${result.name}`);
                            }}
                          >
                            {result.name}
                          </DropdownItem>
                        );
                      })}
                  </DropdownMenu>
                </Dropdown>
              </div>
            )}
            <div>
              <Button
                className="c-btn c-primary ma-5"
                onClick={() => exportList(field, sortBy)}
                disabled={!tblData?.length || isExporting}
              >
                <i className="fas fa-download mr-10" />
                {!isExporting ? "Export" : "Exporting..."}
              </Button>
            </div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              LoadingComponent={Loader}
              onFetchData={onChangePageGetData}
              onPageChange={pageIndex => {
                setActivePage(pageIndex);
                getList(field, sortBy, pageIndex + 1);
              }}
              onSortedChange={sortProperties => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getList(sortProperties[0].id, sort, 1);
                // alert("sort handler", sortProperties);
                // const orderByDirection = sortProperties[0].desc ? 1 : 0;

                // updateMapStateInfoOrderByColumn(sortProperties[0].id, orderByDirection);
              }}
            />
          </div>
        </div>
      </div>
      <CommonModel
        modal={providerModal}
        toggle={() => setProviderModal(prev => !prev)}
        size="lg"
        children={
          <EditProviderModal
            {...props}
            toggle={() => setProviderModal(prev => !prev)}
            service_id={userData?.service}
            userId={userData?.userId}
            pageRefresh={() => {
              getList(field, sortBy, activePage + 1);
            }}
            onSubmit={handleSubmit}
            heading="Assign"
          />
        }
      />
      <CommonModel
        modal={noteModal}
        toggle={toggleNoteModal}
        header={"Edit Note"}
        children={
          <ArchievedNoteModal
            editedData={noteData}
            toggle={toggleNoteModal}
            pageRefresh={() => {
              getList(field, sortBy, activePage + 1);
            }}
            {...props}
          />
        }
      />
      <CommonModel
        modal={customModal}
        toggle={toggleCustomModal}
        children={
          <CustomModal
            text={"Are you sure you want to archive this user?"}
            activeInactiveStatusHandler={archieveStatusHandler}
            activeToggle={toggleCustomModal}
          />
        }
      />
      <Toaster ref={toaster} />
    </div>
  );
};

export default withRouter(DiscoveryQueueTable);
