import React from "react";
import HeaderWrapper from "./header.style";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import { ProfileLockScreen, SERVICE_NAME_HEADER } from "helper/constant";
import { connect } from "react-redux";
import { compose } from "redux";
import AuthActions from "redux/auth/actions";
import { withRouter } from "react-router-dom";
// import PopoverBlock from "./PopoverBlock";
import AdminServices from "../../api/AdminServices";
import { StreamChat } from "stream-chat";
import { STREAM_API_KEY } from "../../helper/constant";

const { logout } = AuthActions;

const Header = props => {
  const { drawerMiniMethod, mini } = props;
  const userSignout = () => {
    AdminServices.logout();
    const apiKey = STREAM_API_KEY;
    const chatClient = StreamChat.getInstance(apiKey);
    chatClient.disconnect();
    chatClient.disconnectUser();
    props.logout();
  };

  const { profileImage } = props.authData
    ? props.authData
    : { profileImage: null };
  return (
    <HeaderWrapper {...props}>
      <div className="headerBack">
        <div className="flex-x align-center">
          <div className="drawer-handle-arrow">
            {mini ? (
              <button
                className="top-header-icon"
                onClick={() => drawerMiniMethod()}
              >
                <i className="fas fa-bars"></i>
              </button>
            ) : (
              <button
                className="top-header-icon"
                onClick={() => drawerMiniMethod()}
              >
                <i className="fas fa-bars"></i>
              </button>
            )}
          </div>
          <div
            className="mini-drawer-menu-icon"
            onClick={() => drawerMiniMethod()}
          >
            <i className="fas fa-bars" />{" "}
            <span className="app-name fs-16 bold-text">{"HUSK Nutrition"}</span>
          </div>
          {/* <div className="pl-10">
            <button id="mail" className="top-header-icon">
              <i className="far fa-envelope"></i>
              <div className="button-badge fs-11 demi-bold-text">3</div>
            </button>
            <UncontrolledPopover
              placement="bottom-start"
              target="mail"
              className="header-popover"
              trigger="focus"
            >
              <PopoverBody className="mail-popover-body">
                <div className="fs-13 bold-text mb-10">You have 3 mails.</div>
                <PopoverBlock
                  people={friend1}
                  name="Alex Woods"
                  text="Hello, How are you ?"
                  created="Just Now"
                />
                <PopoverBlock
                  people={friend2}
                  name="James Anderson"
                  text="Please check your transaction"
                  created="22nd July 2019"
                />
                <PopoverBlock
                  people={friend3}
                  name="Watson"
                  text="You won price.."
                  created="20th Jun 2019"
                />
              </PopoverBody>
            </UncontrolledPopover>
          </div> */}
          {/* <div className="pl-10 flex-1">
            <button id="notification" className="top-header-icon">
              <i className="far fa-bell"></i>
              <div className="button-badge fs-11 demi-bold-text">3</div>
            </button>
            <UncontrolledPopover
              placement="bottom-start"
              target="notification"
              className="header-popover"
              trigger="focus"
            >
              <PopoverBody className="mail-popover-body">
                <div className="fs-13 bold-text mb-10">
                  You have 3 Notifications.
                </div>
                <PopoverBlock
                  people={people1}
                  name="Juli Hacks"
                  text="Send You a message..."
                  created="Just Now"
                />
                <PopoverBlock
                  people={people2}
                  name="Scarlet JohnSon"
                  text="Like your status..."
                  created="22nd July 2019"
                />
                <PopoverBlock
                  people={people3}
                  name="Andrew Hales"
                  text="Tagged you in his status"
                  created="20th Jun 2019"
                />
              </PopoverBody>
            </UncontrolledPopover>
          </div> */}
          {/* <div className="pl-10">
            <button className="top-header-icon">
              <i className="fas fa-search"></i>
            </button>
          </div> */}
          <div className="pl-10 ml-auto">
            <div id="profile">
              <img
                className="top-header-profile-class"
                src={profileImage || ProfileLockScreen}
                alt="notify"
              />
            </div>
            <UncontrolledPopover
              className="roy-menu"
              innerClassName="roy-inner-content"
              placement="bottom-end"
              target="profile"
              trigger="legacy"
            >
              <PopoverBody>
                <div className="roy-menu-list" style={{ cursor: "auto" }}>
                  <b style={{ color: "black" }}>{props.authData.full_name}</b>
                  <br />
                  {props?.authData?.adminRoleId === 3
                    ? `${
                        props?.authData?.service_id
                          ? SERVICE_NAME_HEADER[props?.authData?.service_id]
                          : ""
                      } Provider`
                    : props?.authData?.adminRole}
                </div>
                <hr></hr>
                <div
                  className="roy-menu-list"
                  onClick={() =>
                    props.authData.adminRoleId == 3
                      ? props.history.push("/rd-edit-profile")
                      : props.history.push("/editprofile")
                  }
                >
                  <i
                    className="fas fa-user-alt mr-3"
                    style={{ color: "rgb(117, 117, 117)", fontSize: "18px" }}
                  ></i>
                  My Profile
                </div>
                <div
                  className="roy-menu-list"
                  onClick={() => props.history.push("/changePassword")}
                >
                  <i
                    className="fas fa-key mr-3"
                    style={{ color: "rgb(117, 117, 117)", fontSize: "18px" }}
                  ></i>
                  Change Password
                </div>
                <div className="roy-menu-list" onClick={userSignout}>
                  <i
                    className="fas fa-sign-out-alt mr-3"
                    style={{ color: "rgb(117, 117, 117)", fontSize: "18px" }}
                  ></i>
                  Logout
                </div>
              </PopoverBody>
            </UncontrolledPopover>
          </div>
          {/* <div className="pl-10">
            <button
              onClick={layoutSettingDrawerToggle}
              className="top-header-icon"
            >
              <i className="fas fa-th-large"></i>
            </button>
          </div> */}
        </div>
      </div>
    </HeaderWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    admin: state.auth.admin
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, { logout })
)(Header);
