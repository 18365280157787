import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { Avatar, ChatContext, useChatContext } from "stream-chat-react";
import _debounce from "lodash.debounce";

import { XButton, XButtonBackground } from "../../../assets";

import "./CreateChannel.css";
import { StreamChat } from "stream-chat";
import { STREAM_API_KEY } from "../../../helper/constant";
import { useDebounce } from "helper/useDebounce";

const UserResult = ({ user }) => (
  <li className="messaging-create-channel__user-result">
    <Avatar image={user.image} size={40} />
    {user.online && (
      <div className="messaging-create-channel__user-result-online" />
    )}
    <div className="messaging-create-channel__user-result__details">
      <span>
        {user.name}({user.id})
      </span>
      {/* <span className='messaging-create-channel__user-result__details__last-seen'>{user.online}</span> */}
    </div>
  </li>
);

const CreateChannel = props => {
  let { onClose, toggleMobile } = props;
  const inputRef = useRef(null);
  const { client, setActiveChannel } = useChatContext(); //useContext(ChatContext);

  const [focusedUser, setFocusedUser] = useState(undefined);
  const [inputText, setInputText] = useState("");
  const [resultsOpen, setResultsOpen] = useState(false);
  const [searchEmpty, setSearchEmpty] = useState(false);
  const [searching, setSearching] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const debouncedSearch = useDebounce(inputText, 500);

  const clearState = () => {
    setInputText("");
    setResultsOpen(false);
    setSearchEmpty(false);
  };

  useEffect(() => {
    const clickListener = () => {
      if (resultsOpen) clearState();
    };

    document.addEventListener("click", clickListener);

    return () => document.removeEventListener("click", clickListener);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const findUsers = async () => {
    if (searching) return;
    setSearching(true);
    try {
      let response = null;
      let r1 = null;
      response =
        props.auth.admin_role_id <= 2
          ? await client.queryUsers(
              {
                // id: { $ne: client.userID },
                type: "user",
                $and: [{ name: { $autocomplete: inputText } }]
              },
              { limit: 6 }
            )
          : await client.queryUsers(
              {
                // id: { $ne: client.userID },
                rd_id: Number(props.auth.id),
                type: "user",
                $and: [{ name: { $autocomplete: inputText } }]
              },
              { limit: 6 }
            );
      if (props.auth.admin_role_id === 3) {
        r1 = await client.queryUsers(
          {
            rd_id: String(props.auth.id),
            type: "user",
            $and: [{ name: { $autocomplete: inputText } }]
          },
          { limit: 6 }
        );
      }

      if (
        props.auth.admin_role_id === 3 &&
        !response?.users?.length &&
        !r1?.users?.length
      ) {
        setSearchEmpty(true);
      } else if (props.auth.admin_role_id <= 2 && !response?.users?.length) {
        setSearchEmpty(true);
      }

      let usersList = [];

      if (!!response?.users?.length) {
        usersList = [...response?.users];
        setSearchEmpty(false);
      }

      if (!!r1?.users?.length) {
        usersList = [...usersList, ...r1?.users];
        setSearchEmpty(false);
      }
      setUsers([...usersList]);

      // if (!response.users.length) {
      //   setSearchEmpty(true);
      // } else {
      //   let usersList = [...response.users];
      //   if (!!r1.users.length) usersList = [...response.users, ...r1.users];
      //   // if (props.auth.admin_role_id > 2) {
      //   //   console.log("userLLLL", usersList, client.userID);
      //   //   const updated = usersList.map((el) => ({
      //   //     ...el,
      //   //     rd_id: Number(el.rd_id),
      //   //   }));
      //   //   console.log("userLLLLupdated", updated);
      //   //   const filtered = updated.filter((el) => el?.rd_id === props.auth.id);
      //   //   console.log("filtered", filtered);
      //   //   usersList = [...filtered];
      //   // }
      //   setSearchEmpty(false);
      //   // setUsers(response.users);
      //   setUsers([...usersList]);
      // }
      setResultsOpen(true);
    } catch (error) {
      console.log({ error });
    }

    setSearching(false);
  };

  // const findUsersDebounce = _debounce(findUsers, 100, {
  //   trailing: true,
  // });

  useEffect(() => {
    if (inputText) {
      findUsers();
    }
  }, [debouncedSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  const createChannel = async () => {
    const selectedUsersIds = selectedUsers.map(u => u.id);

    if (!selectedUsersIds.length) return;

    const chatClient = StreamChat.getInstance(STREAM_API_KEY);

    let user_id =
      props.auth.admin_role_id === 3
        ? client.userID.split("_")[1]
        : client.userID;
    const channels = await chatClient.queryChannels(
      {
        type: "messaging",
        id: `dititans_${user_id}_${selectedUsers[0].id}`
      },
      {
        last_message_at: -1,
        updated_at: -1,
        cid: 1
      },
      { watch: true }
    );

    if (channels.length == 0) {
      const conversation = await client.channel(
        "messaging",
        `dititans_${user_id}_${selectedUsers[0].id}`,
        {
          name: selectedUsers[0].name,
          members: [...selectedUsersIds, client.userID]
        }
      );
      await conversation.watch();
      setActiveChannel(conversation);
    } else {
      await channels[0].watch();
      setActiveChannel(channels[0]);
    }
    setSelectedUsers([]);
    setUsers([]);
    onClose();
  };

  const addUser = u => {
    const isAlreadyAdded = selectedUsers.find(user => user.id === u.id);
    if (isAlreadyAdded) return;

    setSelectedUsers([...selectedUsers, u]);
    setResultsOpen(false);
    setInputText("");
    // inputRef.current.focus();
  };

  const removeUser = user => {
    const newUsers = selectedUsers.filter(item => item.id !== user.id);
    setSelectedUsers(newUsers);
    // inputRef.current.focus();
  };

  const handleKeyDown = useCallback(
    e => {
      // check for up(38) or down(40) key
      if (e.which === 38) {
        setFocusedUser(prevFocused => {
          if (prevFocused === undefined) return 0;
          return prevFocused === 0 ? users.length - 1 : prevFocused - 1;
        });
      }
      if (e.which === 40) {
        setFocusedUser(prevFocused => {
          if (prevFocused === undefined) return 0;
          return prevFocused === users.length - 1 ? 0 : prevFocused + 1;
        });
      }
      if (e.which === 13) {
        e.preventDefault();
        if (focusedUser !== undefined) {
          addUser(users[focusedUser]);
          return setFocusedUser(undefined);
        }
      }
    },
    [users, focusedUser] // eslint-disable-line
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, false);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [handleKeyDown]);

  return (
    <div className="messaging-create-channel">
      <header>
        <div className="messaging-create-channel__left">
          <div className="messaging-create-channel__left-text">To: </div>
          <div className="users-input-container">
            {!!selectedUsers?.length && (
              <div className="messaging-create-channel__users">
                {selectedUsers.map(user => (
                  <div
                    className="messaging-create-channel__user"
                    onClick={() => removeUser(user)}
                    key={user.id}
                  >
                    <div className="messaging-create-channel__user-text">
                      {user.name}
                    </div>
                    <XButton />
                  </div>
                ))}
              </div>
            )}
            {!selectedUsers.length && (
              <form onSubmit={addUser}>
                <input
                  autoFocus
                  ref={inputRef}
                  value={inputText}
                  onChange={e => setInputText(e.target.value)}
                  placeholder={
                    !selectedUsers.length ? "Start typing for suggestions" : ""
                  }
                  type="text"
                  className="messaging-create-channel__input"
                />
              </form>
            )}
          </div>
          <div className="close-mobile-create" onClick={() => toggleMobile()}>
            <XButtonBackground />
          </div>
        </div>
        <button className="create-channel-button" onClick={createChannel}>
          Start chat
        </button>
      </header>
      {inputText && (
        <main>
          <ul className="messaging-create-channel__user-results">
            {!!users?.length && !searchEmpty && (
              <div>
                {users.map((user, i) => (
                  <div
                    className={`messaging-create-channel__user-result ${focusedUser ===
                      i && "focused"}`}
                    onClick={() => addUser(user)}
                    key={user.id}
                  >
                    <UserResult user={user} />
                  </div>
                ))}
              </div>
            )}
            {searchEmpty && (
              <div
                onClick={() => {
                  inputRef.current.focus();
                  clearState();
                }}
                className="messaging-create-channel__user-result empty"
              >
                No people found...
              </div>
            )}
          </ul>
        </main>
      )}
    </div>
  );
};

export default React.memo(CreateChannel);
