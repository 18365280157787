import axios from "axios";
import { baseUrl } from "../helper/constant";
import Header from "./Header";
import EncryptDecrypt from "./EncryptDecrypt";

export default class PushNotificationServices {
  static list(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/admin/list-notification`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static add(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/admin/add-notification`,
      EncryptDecrypt.encrypt(params)
    );
  }
}
