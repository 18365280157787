import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    max_point: Yup.string()
      .trim()
      .required("This field is required"),
    unit_point: Yup.string()
      .trim()
      .required("This field is required")
      .when("max_point", (max_point, schema) => {
        return schema.test(
          "less-than-max",
          "Unit points must be less than or equal to max points",
          value => {
            return parseInt(value) <= parseInt(max_point);
          }
        );
      }),
    name: Yup.string()
      .trim()
      .required("This field is required"),
    note: Yup.string()
      .trim()
      .required("This field is required")
  }),
  mapPropsToValues: props => ({
    id: props?.editedData
      ? props && props?.editedData && props?.editedData?.id
      : "",
    unit_point: props.editedData
      ? props && props?.editedData && props?.editedData?.unit_point
      : "",
    max_point: props?.editedData
      ? props && props?.editedData && props?.editedData?.max_point
      : "",
    name: props?.editedData
      ? props && props?.editedData && props?.editedData?.display_name
      : "",
    note: props?.editedData
      ? props && props?.editedData && props?.editedData?.note
      : ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
