import { FORMS_KEYS } from "helper/constant";
import React from "react";

const FormElement = ({ question, answer }) => {
  return (
    <div className="p-2 ">
      <div className="fs-16 demi-bold-text">{question}</div>
      <div>
        <i className="fas fa-arrow-right pr-1"></i>
        {answer ? answer : "N/A"}
      </div>
    </div>
  );
};

const getDietaryplans = (sdp, odp) => {
  let plans = null;
  if (sdp?.length) {
    const filtered = sdp.filter(el => el !== "Other" && el !== "Otro");
    plans = filtered.join(", ");
  }
  if (odp) {
    plans = `${plans} ${plans ? ", " : ""} ${odp}`;
  }
  return plans;
};

const AdultLifestyle = props => {
  return (
    <div>
      <div className="fs-18 bold-text pb-2">Adult Lifestyle</div>
      <FormElement
        question={FORMS_KEYS[4]["problems_goals"]}
        answer={props?.data?.problems_brings_you}
      />
      <FormElement
        question={FORMS_KEYS[4]["family_history"]}
        answer={props?.data?.family_history_disease}
      />
      <FormElement
        question={FORMS_KEYS[4]["height"]}
        answer={
          props?.data?.height_in_feet || props?.data?.height_in_inches
            ? `${
                props?.data?.height_in_feet ? props?.data?.height_in_feet : 0
              }' ${
                props?.data?.height_in_inches
                  ? props?.data?.height_in_inches
                  : 0
              }"`
            : null
        }
      />
      <FormElement
        question={FORMS_KEYS[4]["weight"]}
        answer={
          props?.data?.weight_lbs ? props?.data?.weight_lbs + "lbs." : null
        }
      />
      <FormElement
        question={FORMS_KEYS[4]["years_at_weight"]}
        answer={props?.data?.years_at_current_weight}
      />
      <FormElement
        question={FORMS_KEYS[4]["do_you_follow_plan"]}
        answer={getDietaryplans(
          props?.data?.special_dietary_plan,
          props?.data?.other_dietary_plan
        )}
      />
      <FormElement
        question={FORMS_KEYS[4]["cook"]}
        answer={
          props?.data?.who_cooks?.length
            ? props?.data?.who_cooks?.join(", ")
            : null
        }
      />
      <FormElement
        question={FORMS_KEYS[4]["eat_often"]}
        answer={props?.data?.how_often_eat}
      />
      <FormElement
        question={FORMS_KEYS[4]["resturant_type"]}
        answer={
          props?.data?.types_of_restaurants?.length
            ? props?.data?.types_of_restaurants?.join(", ")
            : null
        }
      />
      <FormElement
        question={FORMS_KEYS[4]["reason_eat"]}
        answer={
          props?.data?.reasons_cause_to_eat?.length
            ? props?.data?.reasons_cause_to_eat?.join(", ")
            : null
        }
      />
      <FormElement
        question={FORMS_KEYS[4]["suffer_from"]}
        answer={
          props?.data?.suffer_from?.length
            ? props?.data?.suffer_from?.join(", ")
            : null
        }
      />
      <FormElement
        question={FORMS_KEYS[4]["smoke"]}
        answer={props?.data?.is_smoking}
      />
      <FormElement
        question={FORMS_KEYS[4]["smoke_count"]}
        answer={props?.data?.smoking_times_per_day}
      />
      <FormElement
        question={FORMS_KEYS[4]["alcohol"]}
        answer={props?.data?.is_consume_alcohol}
      />
      <FormElement
        question={FORMS_KEYS[4]["drink_per_week"]}
        answer={props?.data?.drinks_per_week}
      />
      <FormElement
        question={FORMS_KEYS[4]["physical_activity_limit"]}
        answer={props?.data?.is_physical_activity_limitations}
      />
      <FormElement
        question={FORMS_KEYS[4]["excercise"]}
        answer={props?.data?.is_exercise_regularly}
      />
      <FormElement
        question={FORMS_KEYS[4]["excercise_routine"]}
        answer={props?.data?.exercise_routine}
      />
      <FormElement
        question={FORMS_KEYS[4]["medications"]}
        answer={props?.data?.is_taking_medications}
      />
      <FormElement
        question={FORMS_KEYS[4]["list_medications"]}
        answer={props?.data?.medications_list}
      />
      <FormElement
        question={FORMS_KEYS[4]["vit_supp"]}
        answer={props?.data?.is_taking_vitamins_supplements}
      />
      <FormElement
        question={FORMS_KEYS[4]["list_vit_supp"]}
        answer={props?.data?.list_of_vitamins_supplements}
      />
      <FormElement
        question={FORMS_KEYS[4]["allergies"]}
        answer={props?.data?.allergies_sensitivities}
      />
      <FormElement
        question={FORMS_KEYS[4]["other"]}
        answer={props?.data?.anything_else}
      />
    </div>
  );
};

export default AdultLifestyle;
