import axios from "axios";
import { baseUrl } from "../helper/constant";
import Header from "./Header";
import EncryptDecrypt from "./EncryptDecrypt";
import FileDownload from "js-file-download";

export default class SubAdminServices {
  static list(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/sub-admin/list`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static add(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/sub-admin/add`, params);
  }

  static edit(params) {
    Header.setHeaders();
    return axios.put(`${baseUrl}/sub-admin/edit`, params);
  }

  static view(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/sub-admin/view`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static delete(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/sub-admin/delete`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static status(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/sub-admin/status`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static blockStatus(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/sub-admin/block-status`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static adminTypes(params) {
    Header.setHeaders();
    if (params) {
      return axios.get(
        `${baseUrl}/sub-admin/admin-role`,
        EncryptDecrypt.encrypt(params, 1)
      );
    } else {
      return axios.get(`${baseUrl}/sub-admin/admin-role`);
    }
  }

  static importUser(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/admin/import-user`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static exportSampleUser(params) {
    Header.setHeaders();
    return axios.get(`${baseUrl}/admin/export-sample-user`).then(res => {
      FileDownload(res.data, "Sample User File.csv");
    });
  }
}
