import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    max_client: Yup.string()
      .trim()
      .required("This field is required")
      .max(10, "Client Max must be less than 10 characters"),
    status: Yup.string()
      .trim()
      .required("This field is required"),
    service: Yup.string()
      .trim()
      .required("This field is required"),
    commission: Yup.number()
      .min(1, "Must be between 1 and 100")
      .max(100, "Must be between 1 and 100")
      .when("type", {
        is: "ibo",
        then: Yup.number().required("This field is required"),
        else: Yup.number().notRequired()
      }),

    tier: Yup.string()
      .trim()
      .required("This field is required"),
    advancemd_provider_id: Yup.string()
      .required("This field is required")
      .matches(/^[a-z0-9]+$/i, "Must contain numbers and letters only")
      .max(10, "Must be 10 characters or less"),
    provider_npi: Yup.string()
      .matches(/^[0-9]+$/i, "Must contain numbers only")
      .min(10, "Must be exactly 10 digits")
      .max(10, "Must be exactly 10 digits"),

    type: Yup.string()
      .trim()
      .required("This field is required"),
    service_location_id: Yup.string()
      .required("This field is required")
      .nullable(true),
    practice: Yup.string().when("type", {
      is: "ibo practice",
      then: Yup.string()
        .trim()
        .required("This field is required")
    })
  }),
  mapPropsToValues: props => {},
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true,
  validateOnMount: true
});

export default formikEnhancer;
