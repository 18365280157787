import React from "react";
import PageWrapper from "./staticpage.style";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";
import StaticPageTable from "../../components/staticpage/StaticPageTable";

const StaticPage = props => {
  return (
    <PageWrapper {...props}>
      <PageTitle
        props={props}
        title="sidebar.staticpage"
        className="plr-15"
        breadCrumb={[
          {
            name: "sidebar.dashboard",
            url: "/dashboard"
          },
          {
            name: "sidebar.staticpage"
          }
        ]}
      />
      <div className="user-tbl">
        <StaticPageTable {...props} />
      </div>
    </PageWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    ...state.permissionReducer
  };
};

export default connect(mapStateToProps, null)(StaticPage);
