import EncryptDecrypt from "api/EncryptDecrypt";
import TierManagementServices from "api/TierMangementServices";
import Button from "components/button/Button";
import CommonModel from "components/common/CommonModel";
import Loader from "components/common/Loader";
import Toaster from "components/common/Toaster";
import React, { useEffect, useRef, useState } from "react";
import ReactTable from "react-table";
import TierModalData from "./TierModalData";
import { SERVICE_TYPE } from "helper/constant";

const TierTable = props => {
  const [tblData, settblData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [modal, setModal] = useState(false);
  const toaster = useRef(null);
  const [selectedData, setSelectedData] = useState(null);

  function toggle() {
    setModal(false);
  }
  useEffect(() => {
    getList();
  }, []);

  function getList() {
    setLoader(true);
    TierManagementServices.listTier({})
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data);
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
          setLoader(false);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
        setLoader(false);
      });
  }

  const formAction = value => {
    setModal(true);
    // setTierId(value.id);
    setSelectedData(value);
  };

  const columns = [
    {
      Header: "Tier",
      accessor: "tier.name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 300
    },
    {
      Header: "Service",
      accessor: "service.name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 300
    },
    {
      Header: "Permission",
      accessor: "permission",
      className: "text-center",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 100,
      Cell: props => (
        <div className="react-action-class">
          <Button
            className="c-btn c-info mr-10"
            onClick={() => formAction(props.original)}
          >
            <div className="fs-14 medium-text" title="Permission">
              VIEW
            </div>
          </Button>
        </div>
      )
    }
  ];
  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Tier List
            </div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              minRows={2}
              manual
              className="-striped -highlight custom-react-table-theme-class"
              showPagination={false}
              loading={loader}
              LoadingComponent={Loader}
            />
            <CommonModel
              modal={modal}
              toggle={toggle}
              size="md"
              children={
                <TierModalData
                  selectedData={selectedData}
                  toggle={toggle}
                  {...props}
                />
              }
            />
          </div>
        </div>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};

export default TierTable;
