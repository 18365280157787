import React, { useRef } from "react";
import { compose } from "redux";
import enhancer from "./validator";
import Button from "../button/Button";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import DiagnosesCodeServices from "../../api/DiagnosesCodeServices";
import Toaster from "../common/Toaster";

const DiagnosesCodeForm = props => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    onSubmit,
    values,
    action
  } = props;
  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };
  const toaster = useRef();

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;
    if (isValid) {
      onSubmit(values, action);
      if (action === "add") {
        const params = {
          name: values.name,
          code: values.code
        };
        DiagnosesCodeServices.add(params)
          .then(res => {
            let data = EncryptDecrypt.decrypt(res.data);
            if (data.status) {
              if (toaster.current) toaster.current.success(data.message);
            } else {
              if (toaster.current) toaster.current.error(data.message);
            }
          })
          .catch(err => {
            if (toaster.current) toaster.current.error(err.response);
          });
      } else if (action === "edit") {
        const params = {
          id: values.id,
          name: values.name,
          code: values.code
        };
        DiagnosesCodeServices.edit(params)
          .then(res => {
            let data = EncryptDecrypt.decrypt(res.data);
            if (data.status) {
              if (toaster.current) toaster.current.success(data.message);
            } else {
              if (toaster.current) toaster.current.error(data.message);
            }
          })
          .catch(err => {
            if (toaster.current) toaster.current.error(err.response);
          });
      }
    }

    handleSubmit();
  };

  return (
    <div>
      <form>
        <div className="row">
          <div className="col-md-6 form-group">
            <label className="fs-16 medium-text">
              Name <span className="asterisk">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              placeholder="Add Diagnosis Code"
            />
            <Error field="name" />
          </div>
          <div className="col-md-6 form-group">
            <label className="fs-16 medium-text">Code</label>
            <input
              type="text"
              className="form-control react-form-input"
              id="code"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.code}
              placeholder="Add Diagnosis Code"
            />
            <Error field="code" />
          </div>
          <div className="col-md-6">
            <Button
              type="button"
              className="c-btn c-info form-button fs-16 demi-bold-text"
              style={{ maxWidth: "125px" }}
              onClick={() => handleSubmit()}
            >
              Submit
            </Button>
          </div>
        </div>
      </form>
      <Toaster ref={toaster} />
    </div>
  );
};

export default compose(enhancer)(DiagnosesCodeForm);
