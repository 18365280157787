import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .trim()
      .required("This field is required")
      .max(150, "Name must be less than 150 characters"),
    visits: Yup.number().required("This field is required")
  }),
  mapPropsToValues: props => ({
    id:
      props && props.editedData && props.editedData.id
        ? props.editedData.id
        : "",
    name:
      props && props.editedData && props.editedData.name
        ? props.editedData.name
        : "",
    description:
      props && props.editedData && props.editedData.description
        ? props.editedData.description
        : "",
    visits:
      props && props.editedData && props.editedData.visits
        ? props.editedData.visits
        : ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
