import React, { useRef, useState } from "react";
import { compose } from "redux";
import enhancer from "../rd/referral-code-validator";
import Button from "../button/Button";
import Toaster from "../common/Toaster";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import RdServices from "api/RdServices";

const ReferralCodeModel = props => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    setFieldValue,
    onSubmit,
    values,
    action
  } = props;

  const [loading, setLoading] = useState(false);

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const toaster = useRef();

  const hasParam = props.match.params.id || props?.id;

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;
    if (isValid) {
      setLoading(true);
      if (action === "add") {
        const params = {
          rd_id: hasParam,
          code: values.code,
          description: values.description
        };
        RdServices.referralCodeAdd(params)
          .then(res => {
            let data = EncryptDecrypt.decrypt(res.data);
            if (data.status) {
              if (toaster.current) toaster.current.success(data.message);
              onSubmit(values, action);
            } else {
              if (toaster.current) toaster.current.error(data.message);
              setLoading(false);
            }
          })
          .catch(err => {
            if (toaster.current) toaster.current.error(err.message);
            setLoading(false);
          });
      } else if (action === "edit") {
        const params = {
          id: values.id,
          // code: values.code,
          description: values.description
        };
        RdServices.referralCodeEdit(params)
          .then(res => {
            let data = EncryptDecrypt.decrypt(res.data);
            if (data.status) {
              if (toaster.current) toaster.current.success(data.message);
              setTimeout(() => {
                props.toggle();
              }, 1000);
              props.pageRefresh();
            } else {
              if (toaster.current) toaster.current.error(data.message);
              props.toggle();
            }
          })
          .catch(err => {
            if (toaster.current) toaster.current.error(err.response);
            props.toggle();
          });
      }
    }

    handleSubmit();
  };

  const generateCode = () => {
    setFieldValue("code", generateRandomString(8));
  };

  function generateRandomString(length) {
    var result = [];
    var characters = "ABCDEFGHJKMNPQRSTUVWXYZ23456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result.push(
        characters.charAt(Math.floor(Math.random() * charactersLength))
      );
    }
    return result.join("");
  }

  return (
    <form>
      <div className="form-group">
        <label className="fs-16 medium-text">
          Code <span className="asterisk">*</span>
        </label>
        <input
          type="text"
          className="form-control react-form-input"
          id="code"
          name="code"
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Add Code"
          value={values.code}
          disabled={action === "edit"}
        />
        {action === "add" ? (
          <a
            onClick={() => generateCode()}
            className="fs-16 medium-text text-decoration-none cursor-pointer"
          >
            Generate For Me
          </a>
        ) : (
          ""
        )}
        <Error field="code" />
      </div>
      <div className="form-group">
        <label className="fs-16 medium-text">
          Description <span className="asterisk">*</span>
        </label>
        <input
          type="text"
          className="form-control react-form-input"
          id="description"
          name="description"
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Add Description here"
          value={values.description}
        />
        <Error field="description" />
      </div>
      <div>
        <Button
          type="button"
          className="c-btn c-info form-button fs-16 demi-bold-text mr-10"
          style={{ maxWidth: "125px" }}
          onClick={() => handleSubmit()}
          loading={loading}
          disabled={loading}
        >
          {!loading ? "Submit" : "Loading.."}
        </Button>
        <Button
          type="button"
          className="c-btn c-secondary form-button fs-16 demi-bold-text"
          style={{ maxWidth: "125px" }}
          onClick={props.toggle}
        >
          Close
        </Button>
      </div>
      <Toaster ref={toaster} />
    </form>
  );
};

export default compose(enhancer)(ReferralCodeModel);
