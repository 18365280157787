import React, { useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { hasAccess } from "util/sidebar";
import { MODULE_NAMES, PERMISSION_TYPE } from "helper/constant";
import DiscoveryQueueTable from "./DiscoveryQueueTable";

const DiscoveryQueueTabs = props => {
  const { permissions } = props;
  const [activeTab, setActiveTab] = useState("1");
  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <div className="plr-15">
      <div className="roe-card-style mtb-15">
        <div className="roe-card-header module-header">
          <div className="flex-1 fs-16 demi-bold-text cursor-pointer">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab === "1" },
                    "doc-title"
                  )}
                  onClick={() => {
                    toggle("1");
                  }}
                  disabled={
                    !hasAccess(
                      MODULE_NAMES.DISCOVERY_QUEUE,
                      PERMISSION_TYPE.VIEW,
                      permissions
                    )
                  }
                >
                  Queue
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab === "2" },
                    "doc-title"
                  )}
                  onClick={() => {
                    toggle("2");
                  }}
                  disabled={
                    !hasAccess(
                      MODULE_NAMES.DISCOVERY_QUEUE,
                      PERMISSION_TYPE.VIEW,
                      permissions
                    )
                  }
                >
                  Archived
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </div>
        <div className="roe-card-body">
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <DiscoveryQueueTable activeTab={activeTab} {...props} />
            </TabPane>
            <TabPane tabId="2">
              <DiscoveryQueueTable
                activeTab={activeTab}
                isArchieved={true}
                {...props}
              />
            </TabPane>
          </TabContent>
        </div>
      </div>
    </div>
  );
};
export default DiscoveryQueueTabs;
