import React, { useEffect, useRef, useState } from "react";
import { compose } from "redux";
import enhancer from "./activity-metric-tracking-validator";
import Button from "../button/Button";
import Toaster from "../common/Toaster";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import BioMetricsTrackingService from "../../api/BioMetricsTracking";
import DatePicker from "react-datepicker";
import { ACTIVITY_TYPE } from "helper/constant";

const EditBioMetricTracking = props => {
  const {
    errors,
    touched,
    submitCount,
    setFieldValue,
    handleChange,
    handleBlur,
    onSubmit1,
    values,
    action,
    activityType
  } = props;

  const [selectedDate, setSelectedDate] = useState(new Date());
  useEffect(() => {
    setFieldValue("selected_date", new Date());
  }, []);

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const toaster = useRef();

  const hasParam = props.match.params.id;

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;

    if (isValid) {
      setTimeout(() => {
        onSubmit1(values, action);
      }, 2000);
      const params = {
        selected_date: selectedDate,
        activity_type: activityType.activity_type,
        values: values.activity_value,
        user_id: hasParam
      };
      BioMetricsTrackingService.edit(params)
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          if (data.status) {
            if (toaster.current) toaster.current.success(data.message);
          } else {
            if (toaster.current) toaster.current.error(data.message);
          }
        })
        .catch(err => {
          if (err?.response?.status === 403) {
            if (toaster.current) {
              toaster.current.error("You cannot access this user info!");
              setTimeout(() => {
                props.history.push("/client");
              }, 3000);
            }
          } else {
            if (toaster.current) toaster.current.error(err?.response);
          }
        });
    }

    handleSubmit();
  };
  const toCamelCase = function(text) {
    return text.substring(0, 1).toUpperCase() + text.substring(1).toLowerCase();
  };

  const changeDate = date => {
    setSelectedDate(date);
    setFieldValue("selected_date", date);
    const params = {
      selected_date: date,
      activity_type: activityType.activity_type,
      user_id: hasParam
    };
    BioMetricsTrackingService.view(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setFieldValue("activity_value", data.data.values);
          //if (toaster.current) toaster.current.success(data.message);
        } else {
          setFieldValue("activity_value", 0);
          // if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current) toaster.current.error(err?.response);
        }
      });
  };

  return (
    <form>
      <div className="form-group">
        <label className="fs-16 medium-text">
          Date <span className="asterisk">*</span>
        </label>
        <br />
        <DatePicker
          selected={selectedDate}
          onChange={date => changeDate(date)}
          className="custom-datepicker form-control react-form-input"
          calendarClassName="custom-calender-class"
          // className="form-control react-form-input"
          selectsStart
        />
        <Error field="selected_date" />
      </div>
      <div className="form-group">
        <label className="fs-16 medium-text">
          {toCamelCase(activityType.title)}
          <span className="asterisk">*</span>
        </label>
        <input
          type="text"
          className="form-control react-form-input"
          id="activity_value"
          name="activity_value"
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={
            "Add " +
            toCamelCase(activityType.title) +
            (activityType?.title === ACTIVITY_TYPE.SLEEP ? " (in hours)" : "")
          }
          value={values.activity_value}
          style={{ width: "43%" }}
        />
        <Error field="activity_value" />
      </div>
      <div>
        <Button
          type="button"
          className="c-btn c-info form-button fs-16 demi-bold-text mr-10"
          style={{ maxWidth: "125px" }}
          onClick={() => handleSubmit()}
        >
          Submit
        </Button>
        <Button
          type="button"
          className="c-btn c-secondary form-button fs-16 demi-bold-text"
          style={{ maxWidth: "125px" }}
          onClick={props.toggle1}
        >
          Close
        </Button>
      </div>
      <Toaster ref={toaster} />
    </form>
  );
};

export default compose(enhancer)(EditBioMetricTracking);
