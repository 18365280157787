import React, { useEffect, useRef } from "react";
import { Avatar, useChatContext } from "stream-chat-react";

import "./MessagingChannelList.css";
import { SkeletonLoader } from "./SkeletonLoader";

import { CreateChannelIcon } from "../../../assets";

const MessagingChannelList = ({
  children,
  error = false,
  loading,
  onCreateChannel,
  ...props
}) => {
  const { client, channel: activeChannel } = useChatContext();
  const {
    id,
    image = require("../../../assets/stream.png"),
    name = "Example User"
  } = client.user || {};

  const mainChatListRef = useRef(null);
  const scrollPosition = useRef(0);

  // Save the scroll position before the update
  const saveScrollPosition = () => {
    if (mainChatListRef.current) {
      scrollPosition.current = mainChatListRef.current.scrollTop;
    }
  };

  // Restore the scroll position after the update
  const restoreScrollPosition = () => {
    if (mainChatListRef.current) {
      mainChatListRef.current.scrollTop = scrollPosition.current;
    }
  };

  useEffect(() => {
    if (mainChatListRef.current && activeChannel?.id) {
      const intervalId = setTimeout(
        () => scrollToChild(activeChannel?.id),
        100
      );
      return () => clearTimeout(intervalId);
    }
  }, [activeChannel]);

  useEffect(() => {
    if (!loading) {
      restoreScrollPosition();
    }
  }, [children]); // eslint-disable-line

  const scrollToChild = childId => {
    const childElement = document.getElementById(childId);
    if (childElement && mainChatListRef.current) {
      const parentRect = mainChatListRef.current.getBoundingClientRect();
      const childRect = childElement.getBoundingClientRect();
      mainChatListRef.current.scrollTop += childRect.top - parentRect.top - 80;
    }
  };

  const ListHeaderWrapper = ({ children }) => {
    return (
      <div
        ref={mainChatListRef}
        id="parentChatList"
        className="messaging__channel-list"
        onScroll={saveScrollPosition}
      >
        <div className="messaging__channel-list__header">
          <Avatar image={image} name={name} size={40} />
          <div className="messaging__channel-list__header__name">
            {name || id}
          </div>
          <button
            className="messaging__channel-list__header__button"
            onClick={onCreateChannel}
          >
            <CreateChannelIcon />
          </button>
        </div>
        {children}
      </div>
    );
  };

  if (error) {
    return (
      <ListHeaderWrapper>
        <div className="messaging__channel-list__message">
          Error loading conversations, please try again momentarily.
        </div>
      </ListHeaderWrapper>
    );
  }

  if (loading) {
    return (
      <ListHeaderWrapper>
        <div className="messaging__channel-list__message">
          <SkeletonLoader />
        </div>
      </ListHeaderWrapper>
    );
  }

  return <ListHeaderWrapper>{children}</ListHeaderWrapper>;
};

export default React.memo(MessagingChannelList);
