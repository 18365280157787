import React from "react";
import {
  CONTENT_LIBRARY_TYPE,
  CONTENT_LIBRARY_STATUS
} from "../../helper/constant";
import TagDetail from "./TagDetail";

const ContentLibraryViewDetails = props => {
  const detail = props.location.state;

  return (
    <div className="plr-15">
      <div className="roe-card-style mtb-15">
        <div className="roe-card-header module-header">
          <div className="flex-1 fs-16 demi-bold-text">
            <span className="hash"># </span> View Content Library
          </div>
        </div>
        <div className="roe-card-body">
          <div className="row">
            <div className="col-md-3">
              <div style={{ fontWeight: "bolder", textAlign: "left" }}>
                Service
              </div>
              <div>{detail.service.name}</div>
            </div>
            <div className="col-md-3">
              <div style={{ fontWeight: "bolder", textAlign: "left" }}>
                Type
              </div>
              <div>{CONTENT_LIBRARY_TYPE[detail.type]}</div>
            </div>
            <div className="col-md-3">
              <div style={{ fontWeight: "bolder", textAlign: "left" }}>
                Title
              </div>
              <div>{detail.title}</div>
            </div>
            <div className="col-md-3">
              <div style={{ fontWeight: "bolder", textAlign: "left" }}>
                Author
              </div>
              <div>
                {detail.admin.first_name} {detail.admin.last_name}
              </div>
            </div>
            <div className="col-md-3">
              <div style={{ fontWeight: "bolder", textAlign: "left" }}>
                Status
              </div>
              <div>{CONTENT_LIBRARY_STATUS[detail.status]}</div>
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-md-12">
              <div style={{ fontWeight: "bolder", textAlign: "left" }}>Tag</div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap"
                }}
              >
                {detail.content_library_tags.map(tagDetail => (
                  <TagDetail tag={tagDetail.content_tag.tag} />
                ))}
              </div>
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-md-12">
              <div style={{ fontWeight: "bolder", textAlign: "left" }}>
                Description
              </div>
              <div>{detail.description}</div>
            </div>
          </div>
          {detail.type === "article" && (
            <div className="row pt-3">
              <div className="col-md-12">
                <div style={{ fontWeight: "bolder", textAlign: "left" }}>
                  Article Body
                </div>
                <div
                  className="text-left"
                  dangerouslySetInnerHTML={{ __html: detail.article_body }}
                />
              </div>
            </div>
          )}
          {detail.type === "recipe" && (
            <>
              <div className="row pt-3">
                <div className="col-md-12">
                  <div style={{ fontWeight: "bolder", textAlign: "left" }}>
                    Servings
                  </div>
                  <div>{detail.recipe_serving}</div>
                </div>
              </div>
              <div className="row pt-3">
                <div className="col-md-12">
                  <div style={{ fontWeight: "bolder", textAlign: "left" }}>
                    Ingredients
                  </div>
                  <div
                    className="text-left"
                    dangerouslySetInnerHTML={{
                      __html: detail.recipe_ingredient
                    }}
                  />
                </div>
              </div>
              <div className="row pt-3">
                <div className="col-md-12">
                  <div style={{ fontWeight: "bolder", textAlign: "left" }}>
                    Instructions
                  </div>
                  <div
                    className="text-left"
                    dangerouslySetInnerHTML={{
                      __html: detail.recipe_instruction
                    }}
                  />
                </div>
              </div>
            </>
          )}
          {detail.type === "video" && (
            <div className="row pt-3">
              <div className="col-md-12">
                <div style={{ fontWeight: "bolder", textAlign: "left" }}>
                  Video
                </div>
                <video
                  src={detail.video_url}
                  controls="controls"
                  style={{
                    width: "258px",
                    borderRadius: "4%",
                    height: "200px",
                    background: "#404040"
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContentLibraryViewDetails;
