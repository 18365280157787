import axios from "axios";
import { baseUrl } from "../helper/constant";
import Header from "./Header";
import EncryptDecrypt from "./EncryptDecrypt";

export default class ContentLibraryServices {
  static list(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/content-library/list`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static add(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/content-library/add`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static edit(params) {
    Header.setHeaders();
    return axios.put(
      `${baseUrl}/content-library/edit`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static view(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/content-library/view`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static delete(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/content-library/delete`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static status(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/content-library/status`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static authorList() {
    Header.setHeaders();
    return axios.get(`${baseUrl}/content-library/author-list`);
  }

  static tagList() {
    Header.setHeaders();
    return axios.get(`${baseUrl}/content-library/tag-list`);
  }

  static failedStatus() {
    Header.setHeaders();
    return axios.get(`${baseUrl}/content-library/failed-video-status`);
  }

  static userList(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/content-library/user-list`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static share(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/content-library/share`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static exisingUserList(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/content-library/existing-shared-user`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }
}
