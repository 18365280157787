import React from "react";
import Button from "../button/Button";
import moment from "moment";
import { ORDER_STATUS, PLACEHOLDER } from "helper/constant";

const ViewOrderModal = props => {
  return (
    <form>
      <div className="roe-card-body flex space-between pa-28">
        <div className="pr-4">
          <div className="pt-2">
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>ID</div>
            <div>{props?.orderDetails?.id}</div>
          </div>
          <div className="pt-2">
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>
              Order Date
            </div>
            <div>
              {moment
                .utc(props?.orderDetails?.order_date)
                .local()
                .format("MM/DD/YYYY")}
            </div>
          </div>
          <div className="pt-2">
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>
              Order ID
            </div>
            <div>
              <div>{props?.orderDetails?.iredeem_order_id}</div>
            </div>
          </div>
          <div className="pt-2">
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>
              Order Status
            </div>
            <div>
              <div>{ORDER_STATUS[props?.orderDetails?.order_status]}</div>
            </div>
          </div>
          <div className="pt-2">
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>
              Points
            </div>
            <div>
              <div>{props?.orderDetails?.point}</div>
            </div>
          </div>
          <div className="pt-2">
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>
              Product Name
            </div>
            <div>
              <div>{props?.orderDetails?.reward_product?.product_name}</div>
            </div>
          </div>
          <div className="pt-2">
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>
              Product Image
            </div>
            <div>
              <img
                className="top-header-profile-class"
                style={{
                  height: "150px",
                  width: "150px",
                  marginBottom: "15px",
                  borderRadius: "10px",
                  marginTop: "10px"
                }}
                src={
                  props?.orderDetails &&
                  (props?.orderDetails?.product_variation?.image || PLACEHOLDER)
                }
                alt="Product"
              />
            </div>
            <div className="pt-2">
              <div style={{ fontWeight: "bolder", textAlign: "left" }}>
                Client Name
              </div>
              <div>
                <div>
                  {props?.orderDetails?.user?.first_name +
                    " " +
                    props?.orderDetails?.user?.last_name}
                </div>
              </div>
            </div>
            <div className="pt-2">
              <div style={{ fontWeight: "bolder", textAlign: "left" }}>
                Client Email
              </div>
              <div>
                <div>{props?.orderDetails?.user?.email}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex-x center">
        <Button
          type="button"
          className="c-btn c-secondary form-button fs-16 demi-bold-text"
          style={{ maxWidth: "125px" }}
          onClick={props.toggle}
        >
          Close
        </Button>
      </div>
    </form>
  );
};

export default ViewOrderModal;
