import React, { Fragment, useEffect, useRef, useState } from "react";
import { compose } from "redux";
import enhancer from "./validator";
import Button from "../button/Button";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import Toaster from "../common/Toaster";
import PracticeMangementServices from "api/PracticeManagementServices";
import CategoryServices from "api/CategoryServices";
import { Input } from "reactstrap";
import { PRACTICE_TYPE } from "helper/constant";

const PRACTICE_TYPES_DROPDOWN = [
  {
    name: "Small",
    value: "small"
  },
  {
    name: "Large",
    value: "large"
  }
];

const PracticeForm = props => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    values,
    action,
    history,
    handleSubmit
  } = props;

  const toaster = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [serviceList, setServiceList] = useState([]);

  useEffect(() => {
    CategoryServices.serviceDropdown()
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setServiceList([...data.data]);
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error("Could not fetch services!");
      });
  }, []);

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const handleFormSubmit = () => {
    let { values, isValid, handleSubmit } = props;
    setIsLoading(true);

    if (isValid) {
      if (action === "add") {
        const params = {
          practice_name: values.name,
          office_key: values?.officeKey ? values?.officeKey : undefined,
          practice_tin: values?.tin ? values?.tin : undefined,
          practice_npi: values?.npi ? values?.npi : undefined,
          service_id: values?.service,
          type: values?.type
        };
        PracticeMangementServices.add(params)
          .then(res => {
            let data = EncryptDecrypt.decrypt(res.data);
            if (data.status) {
              if (toaster.current) toaster.current.success(data.message);
              setTimeout(() => {
                history.push("/practice");
              }, 1500);
              setIsLoading(false);
            } else {
              if (toaster.current) toaster.current.error(data.message);
              setIsLoading(false);
            }
          })
          .catch(err => {
            if (toaster.current) toaster.current.error(err.message);
            setIsLoading(false);
          });
      } else if (action === "edit") {
        const params = {
          practice_name: values.name,
          office_key: values?.officeKey ? values?.officeKey : undefined,
          practice_tin: values?.tin ? values?.tin : undefined,
          practice_npi: values?.npi ? values?.npi : undefined,
          status: values?.status,
          id: values?.id
        };
        PracticeMangementServices.edit(params)
          .then(res => {
            let data = EncryptDecrypt.decrypt(res.data);
            if (data.status === 1) {
              if (toaster.current) toaster.current.success(data.message);
              setTimeout(() => {
                history.push("/practice");
              }, 1500);
              setIsLoading(false);
            } else {
              if (toaster.current) toaster.current.error(data.message);
              setIsLoading(false);
            }
          })
          .catch(err => {
            if (toaster.current) toaster.current.error(err.message);
            setIsLoading(false);
          });
      }
    } else {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6 form-group">
            <label className="fs-16 medium-text">
              IBO Practice Name <span className="asterisk">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              placeholder="IBO Practice Name"
            />
            <Error field="name" />
          </div>
          <div className="col-md-6 form-group">
            <label className="fs-16 medium-text">
              Service<span className="asterisk">*</span>
            </label>
            <Input
              type="select"
              name="service"
              id="service"
              onChange={handleChange}
              value={values.service}
              onBlur={handleBlur}
              disabled={action === "edit" || props?.editedData?.service}
            >
              <option value="">Select Service</option>
              {serviceList.map((service, i) => {
                return (
                  <>
                    <option key={`option${i}`} value={service.id}>
                      {service.name}
                    </option>
                  </>
                );
              })}
            </Input>
            <Error field="service" />
          </div>
          <div className="col-md-6 form-group">
            <label className="fs-16 medium-text">
              Type<span className="asterisk">*</span>
            </label>
            <Input
              type="select"
              name="type"
              id="service"
              onChange={handleChange}
              value={values.type}
              onBlur={handleBlur}
              disabled={action === "edit" || props?.editedData?.type}
            >
              <option value="">Select Type</option>
              {PRACTICE_TYPES_DROPDOWN.map((type, i) => {
                return (
                  <Fragment>
                    <option key={`option${i}`} value={type.value}>
                      {type.name}
                    </option>
                  </Fragment>
                );
              })}
            </Input>
            <Error field="type" />
          </div>
          {props.values.type === PRACTICE_TYPE.Large && (
            <>
              <div className="col-md-6 form-group">
                <label className="fs-16 medium-text">IBO Practice TIN</label>
                <input
                  type="text"
                  className="form-control react-form-input"
                  id="tin"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.tin}
                  placeholder="IBO Practice TIN"
                  disabled={props?.editedData?.practice_tin}
                />
                <Error field="tin" />
              </div>
              <div className="col-md-6 form-group">
                <label className="fs-16 medium-text">IBO Practice NPI</label>
                <input
                  type="text"
                  className="form-control react-form-input"
                  id="npi"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.npi}
                  placeholder="IBO Practice NPI"
                  disabled={props?.editedData?.practice_npi}
                />
                <Error field="npi" />
              </div>
              <div className="col-md-6 form-group">
                <label className="fs-16 medium-text">IBO Office Key</label>
                <input
                  type="text"
                  className="form-control react-form-input"
                  id="officeKey"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.officeKey}
                  placeholder="IBO Office Key"
                  disabled={
                    props?.editedData?.advancemd_setting?.advancemd_office_code
                  }
                />
                <Error field="officeKey" />
              </div>
            </>
          )}

          <div className="pl-15 col-md-12">
            <Button
              type="submit"
              className="c-btn c-info form-button fs-16 demi-bold-text min-w-120 max-w-180 "
              onClick={handleFormSubmit}
              loading={isLoading}
              disabled={isLoading}
              dataStyle={"expand-right"}
            >
              Submit
            </Button>
          </div>
        </div>
      </form>
      <Toaster ref={toaster} />
    </div>
  );
};

export default compose(enhancer)(PracticeForm);
