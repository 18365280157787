import EncryptDecrypt from "api/EncryptDecrypt";
import RolesAndPermissionService from "api/RolesAndPermissionServices";
import Loader from "components/common/Loader";
import Toaster from "components/common/Toaster";
import React, { useEffect, useRef, useState } from "react";
import ReactTable from "react-table";
import { Button, Pagination } from "reactstrap";

const RoleModalData = ({ onClose, data }) => {
  const toaster = useRef();
  const [permissions, setPermissions] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    setLoader(true);
    if (data) {
      const params = {
        role_id: data.id
      };

      RolesAndPermissionService.list(params)
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          if (data.status) {
            setPermissions(data.data);
            setPages(data.data.length);
            setLoader(false);
          } else {
            if (toaster.current) toaster.current.error(data.message);
            setLoader(false);
          }
        })
        .catch(err => {
          if (toaster.current) toaster.current.error(err.response);
        });
    }
  }, [data]);

  const handleChange = (id, name) => {
    if (!isChanged) setIsChanged(true);
    const newPermissions = [...permissions];
    let index = newPermissions.findIndex(per => per.module_id === id);
    if (index !== -1) {
      newPermissions[index][name] = !newPermissions[index][name];
      setPermissions([...newPermissions]);
    }
  };

  const onSave = () => {
    setLoader(true);
    const params = {
      role_id: data.id,
      permissions: [...permissions]
    };
    RolesAndPermissionService.edit(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          // setPermissions(data.data);
          // setPages(data.data.length);
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
          setLoader(false);
        }
        onClose();
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
        setLoader(false);
        onClose();
      });
  };

  const columns = [
    {
      Header: "Module Name",
      accessor: "name",
      headerClassName: "react-table-header-class",
      filterable: false,
      width: 450,
      sortable: true
    },
    {
      Header: "View",
      accessor: "view",
      className: "text-center",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 90,
      Cell: ({ original, value }) => {
        return (
          <input
            type="checkbox"
            className="checkbox"
            checked={value}
            onChange={() => handleChange(original.module_id, "view")}
            disabled={data?.id === 1}
          />
        );
      }
    },
    {
      Header: "Add",
      accessor: "add",
      className: "text-center",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 90,
      Cell: ({ original, value }) => {
        return (
          <input
            type="checkbox"
            className="checkbox"
            checked={value}
            onChange={() => handleChange(original.module_id, "add")}
            disabled={!original.view || data?.id === 1}
          />
        );
      }
    },
    {
      Header: "Edit",
      accessor: "edit",
      className: "text-center",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 90,
      Cell: ({ original, value }) => {
        return (
          <input
            type="checkbox"
            className="checkbox"
            checked={value}
            onChange={() => handleChange(original.module_id, "edit")}
            disabled={!original.view || data?.id === 1}
          />
        );
      }
    },
    {
      Header: "Status",
      accessor: "status",
      className: "text-center",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 90,
      Cell: ({ original, value }) => {
        return (
          <input
            type="checkbox"
            className="checkbox"
            checked={value}
            onChange={() => handleChange(original.module_id, "status")}
            disabled={!original.view || data?.id === 1}
          />
        );
      }
    },
    {
      Header: "Delete",
      accessor: "delete",
      className: "text-center",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 90,
      Cell: ({ original, value }) => {
        return (
          <input
            type="checkbox"
            className="checkbox"
            checked={value}
            onChange={() => handleChange(original.module_id, "delete")}
            disabled={!original.view || data?.id === 1}
          />
        );
      }
    },
    {
      Header: "Lookup/Export",
      accessor: "lookup",
      className: "text-center",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 172,
      Cell: ({ original, value }) => {
        return (
          <input
            type="checkbox"
            className="checkbox"
            checked={value}
            onChange={() => handleChange(original.module_id, "lookup")}
            disabled={!original.view || data?.id === 1}
          />
        );
      }
    }
  ];
  return (
    <div className="flex flex-y">
      <div className="font-bold fs-20 mb-10"> Role: {data?.role}</div>
      <div>
        <ReactTable
          style={{
            border: "none",
            boxShadow: "none",
            maxHeight: "85vh"
          }}
          data={permissions}
          columns={columns}
          defaultPageSize={80}
          minRows={2}
          PaginationComponent={Pagination}
          loading={loader}
          LoadingComponent={Loader}
        />
      </div>
      {isChanged && (
        <div className="fill-width flex justify-content-end">
          <Button className="c-btn c-primary" onClick={onSave}>
            {loader ? "Saving..." : "Save"}
          </Button>
        </div>
      )}
      <Toaster ref={toaster} />
    </div>
  );
};

export default RoleModalData;
