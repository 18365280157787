import CategoryServices from "api/CategoryServices";
import EncryptDecrypt from "api/EncryptDecrypt";
import RdServices from "api/RdServices";
import Button from "components/button/Button";
import Toaster from "components/common/Toaster";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { Input } from "reactstrap";
import { compose } from "redux";
import enhancer from "./setting-validator";
import TierManagementServices from "api/TierMangementServices";
import Select from "react-select";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PracticeMangementServices from "api/PracticeManagementServices";

const RdSettingForm = props => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [practiceList, setPracticeList] = useState([]);
  const [practiceDisabled, setPracticeDisabled] = useState(false);
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    onSubmit,
    values,
    action,
    serviceLocationList,
    setFieldValue,
    setServiceLocationList,
    editedData
  } = props;

  useEffect(() => {
    if (editedData) {
      setFieldValue(
        "max_client",
        editedData?.rd_detail?.max_client
          ? editedData?.rd_detail?.max_client
          : ""
      );
      setFieldValue(
        "service",
        editedData?.rd_detail?.service ? editedData?.rd_detail.service.id : ""
      );
      setFieldValue(
        "commission",
        editedData?.rd_detail?.commission
          ? editedData?.rd_detail.commission
          : ""
      );
      setFieldValue(
        "tier",
        editedData?.rd_detail?.tier ? editedData?.rd_detail?.tier?.id : ""
      );
      setFieldValue("status", editedData?.status ? editedData?.status : "");
      setFieldValue(
        "type",
        editedData?.rd_detail?.type ? editedData?.rd_detail?.type : ""
      );
      setFieldValue(
        "service_location_id",
        editedData?.rd_service_locations?.length
          ? editedData?.rd_service_locations.map(el => ({
              value: el.service_location?.id,
              label: el.service_location?.name
            }))
          : null
      );
      setFieldValue(
        "advancemd_provider_id",
        editedData?.rd_detail?.advancemd_provider_id
          ? editedData?.rd_detail?.advancemd_provider_id
          : ""
      );
      setFieldValue(
        "provider_npi",
        editedData?.rd_detail?.provider_npi
          ? editedData?.rd_detail?.provider_npi
          : ""
      );
      setFieldValue("id", editedData?.id ? editedData?.id : "");
      setFieldValue(
        "practice",
        editedData?.practice_management?.id
          ? editedData?.practice_management.id
          : ""
      );
      setFieldValue(
        "messaging_status",
        editedData?.rd_detail?.is_messaging_active
          ? editedData?.rd_detail?.is_messaging_active
          : ""
      );
    }
  }, [editedData]);

  useEffect(() => {
    if (values?.type || values?.service) {
      const params = {
        type: values?.type ? values?.type : undefined,
        service_id: values?.service ? values?.service : undefined
      };
      RdServices.serviceLocationList(params).then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        setServiceLocationList(data.data);
      });
    }
  }, [values?.type, values?.service]);

  useEffect(() => {
    if (props?.auth?.admin_role_id === 6) {
      setPracticeDisabled(true);
      setFieldValue("practice", props?.auth?.practice);
    } else if (
      values?.type === "ibo practice" &&
      props?.auth?.admin_role_id !== 6
    ) {
      setPracticeDisabled(true);
      PracticeMangementServices.list({
        get_all: true,
        service_id: values?.service ? values?.service : undefined
      })
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          if (data?.status) {
            setPracticeList([...data?.data]);
          } else {
            if (toaster.current)
              toaster.current.error("Could not fetch practices!");
          }
          setPracticeDisabled(false);
        })
        .catch(err => {
          if (toaster.current)
            toaster.current.error("Could not fetch practices!");
          setPracticeDisabled(false);
        });
    } else {
      setFieldValue("practice", "");
    }
  }, [values?.type, props?.auth?.admin_role_id, values?.service]);

  const serviceLocationOptions = useMemo(() => {
    const updatedServiceLocation = [];
    if (serviceLocationList.length) {
      serviceLocationList.forEach(el =>
        updatedServiceLocation.push({
          label: el?.name,
          value: el?.id
        })
      );
    }
    return updatedServiceLocation;
  }, [serviceLocationList]);

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const toaster = useRef();
  const [serviceList, setServiceList] = useState([]);
  const [tierList, setTierList] = useState([]);

  useEffect(() => {
    CategoryServices.serviceDropdown()
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setServiceList([...data.data]);
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      });
  }, []);

  useEffect(() => {
    if (props?.editedData?.rd_detail?.service?.id) {
      TierManagementServices.listTier({
        service_id: props?.editedData?.rd_detail?.service?.id
      })
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          if (data.status) {
            const tierDetails = [...data.data];
            const tiers = [];
            if (tierDetails?.length) {
              tierDetails.forEach(el => {
                tiers.push(el?.tier);
              });
            }
            setTierList([...tiers]);
          } else {
            if (toaster.current)
              toaster.current.error("Could not fetch tier list!");
          }
        })
        .catch(err => {
          if (toaster.current)
            toaster.current.error("Could not fetch tier list!");
        });
    }
  }, [props?.editedData?.rd_detail?.service?.id]);

  const handleSubmit = () => {
    let { values, isValid, handleSubmit, errors } = props;
    const id = props.editedData.id;
    const serviceLocationId = [];
    if (
      values &&
      values?.service_location_id &&
      values?.service_location_id?.length
    ) {
      values.service_location_id.forEach(el => {
        serviceLocationId.push(Number(el.value));
      });
    }
    if (isValid) {
      const params = {
        admin_id: id,
        service_id: Number(values.service),
        type: values.type,
        commission: values.commission,
        tier_id: Number(values.tier),
        max_client: values.max_client,
        status: values.status,
        service_location: serviceLocationId,
        advancemd_provider_id: values.advancemd_provider_id,
        provider_npi: values?.provider_npi ? values?.provider_npi : null,
        messaging_status: values?.messaging_status,
        practice_id: values?.practice ? values?.practice : undefined
      };
      setLoading(true);
      RdServices.editSetting(params)
        .then(res => {
          setLoading(false);
          let data = EncryptDecrypt.decrypt(res.data);
          if (data.status) {
            if (props.actionFlow === "add") {
              history.push(`/rd/view/${editedData.id}`, {
                step: "3"
              });
            }
            if (toaster.current) toaster.current.success(data.message);
            onSubmit(values, action);
          } else {
            if (toaster.current) toaster.current.error(data.message);
          }
          setLoading(false);
        })
        .catch(err => {
          if (toaster.current) toaster.current.error(err.response);
          setLoading(false);
        });
    }
    handleSubmit();
  };

  return (
    <div>
      <form>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">
              Service <span className="asterisk"> *</span>
            </label>
            <Input
              type="select"
              name="service"
              id="service"
              onChange={e => {
                setFieldValue("service_location_id", null);
                handleChange(e);
              }}
              value={values.service}
              onBlur={handleBlur}
              disabled
            >
              <option value="">Select Service</option>
              {serviceList.map((service, i) => {
                return (
                  <Fragment>
                    <option key={`option${i}`} value={service.id}>
                      {service.name}
                    </option>
                  </Fragment>
                );
              })}
            </Input>

            <Error field="service" />
          </div>
          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">
              Type<span className="asterisk"> *</span>
            </label>
            <Input
              type="select"
              name="type"
              id="type"
              onChange={e => {
                setFieldValue("service_location_id", null);
                handleChange(e);
              }}
              value={values.type}
              onBlur={handleBlur}
              disabled
            >
              <option value="">Select type</option>
              <option value="employee">Employee</option>
              <option value="ibo">IBO</option>
              <option value="ibo practice">IBO Practice</option>
            </Input>
            <Error field="type" />
          </div>
          {values?.type === "ibo practice" && props?.auth?.admin_role_id !== 6 && (
            <div className="form-group col-md-6">
              <label className="fs-16 medium-text">
                Practice<span className="asterisk">*</span>
              </label>
              <Input
                type="select"
                name=""
                id="practice"
                onChange={handleChange}
                value={values.practice}
                onBlur={handleBlur}
                disabled={!!practiceDisabled}
              >
                <option value="">Select Practice</option>
                {practiceList.map((type, i) => {
                  return (
                    <Fragment>
                      <option key={`option${i}`} value={type.id}>
                        {type.practice_name}
                      </option>
                    </Fragment>
                  );
                })}
              </Input>
              <Error field="practice" />
            </div>
          )}
          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">
              Commission{" "}
              {values.type === "ibo" && <span className="asterisk">*</span>}
            </label>
            <input
              type="number"
              className="form-control react-form-input"
              id="commission"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values?.commission}
              placeholder="Add commission in %"
              disabled={values?.type === "employee"}
            />
            <Error field="commission" />
          </div>

          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">
              Client Max <span className="asterisk"> *</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="max_client"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.max_client}
              placeholder="Add Client Max"
            />
            <Error field="max_client" />
          </div>
          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">
              Tier <span className="asterisk"> *</span>
            </label>
            <Input
              type="select"
              name="tier"
              id="tier"
              onChange={handleChange}
              value={values.tier}
              onBlur={handleBlur}
              disabled={props.values.type === "employee"}
            >
              <option value="">Select Tier</option>
              {tierList.map((tier, i) => {
                return (
                  <Fragment>
                    <option key={`option${i}`} value={tier.id}>
                      {tier.name}
                    </option>
                  </Fragment>
                );
              })}
            </Input>

            <Error field="tier" />
          </div>

          <div className="form-group col-md-6">
            <label className="fs-16 medium-text">
              Status<span className="asterisk"> *</span>
            </label>
            <Input
              type="select"
              name="status"
              id="status"
              onChange={handleChange}
              value={values.status}
              onBlur={handleBlur}
            >
              <option value="">Select Status</option>
              <option value="1">Active, accepting new clients</option>
              <option value="2">Active, not accepting new clients</option>
              <option value="3">Temporarily inactive</option>
              <option value="4">Permanently inactive</option>
            </Input>
            <Error field="status" />
          </div>

          {/* <div className="col-md-6 form-group">
            <label className="fs-16 medium-text">Service Location</label>
            <Input
              type="select"
              name="service_location_id"
              id="service_location_id"
              onChange={handleChange}
              value={values.service_location_id}
              onBlur={handleBlur}
            >
              <option value="">Select Service Location</option>
              {serviceLocationList.map((type, i) => {
                return (
                  <Fragment>
                    <option key={`option${i}`} value={type.id}>
                      {type.name}
                    </option>
                  </Fragment>
                );
              })}
            </Input>
            <Error field="service_location_id" />
          </div> */}
          <div className="col-md-6 form-group">
            <label className="fs-16 medium-text">Service Location</label>
            <Select
              options={serviceLocationOptions}
              isMulti
              onChange={val => setFieldValue("service_location_id", val)}
              placeholder={"Select Service Location"}
              value={values?.service_location_id}
              isClearable
              onBlur={handleBlur}
            />
            <Error field="service_location_id" />
          </div>
          <div className="col-md-6 form-group">
            <label className="fs-16 medium-text">AdvancedMD Provider ID</label>
            <input
              type="text"
              className="form-control react-form-input"
              id="advancemd_provider_id"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.advancemd_provider_id}
              placeholder="Add AdvanceMD Provider ID"
              disabled
            />
            <Error field="advancemd_provider_id" />
          </div>
          <div className="col-md-6 form-group">
            <label className="fs-16 medium-text">Provider NPI</label>
            <input
              type="text"
              className="form-control react-form-input"
              id="provider_npi"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.provider_npi}
              placeholder="Add Provider NPI"
              disabled={props?.isRD}
            />
            <Error field="provider_npi" />
          </div>
          {editedData?.rd_detail?.service.id === 1 && (
            <div className="col-md-6 form-group flex align-base ">
              <input
                type="checkbox"
                className="checkbox"
                id="messaging_status"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.messaging_status}
                checked={values.messaging_status}
              />
              <label className="fs-16 medium-text pl-3">Messaging</label>
            </div>
          )}
        </div>
        <div>
          <Button
            type="button"
            loading={loading}
            disabled={loading}
            className="c-btn c-info form-button fs-16 demi-bold-text"
            style={{ maxWidth: "180px" }}
            onClick={() => handleSubmit()}
            dataStyle="expand-right"
          >
            {props?.actionFlow === "add" ? (
              <span>Save and Continue</span>
            ) : (
              <span>Submit</span>
            )}
          </Button>
        </div>
      </form>
      <Toaster ref={toaster} />{" "}
    </div>
  );
};

export default compose(enhancer)(RdSettingForm);
