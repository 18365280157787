import React, { useRef, useState } from "react";
import { compose } from "redux";
import enhancer from "./add-referral-validator";
import Button from "../button/Button";
import ClientServices from "api/ClientServices";
import Toaster from "components/common/Toaster";
import EncryptDecrypt from "api/EncryptDecrypt";
import { SERVICE_NAME } from "helper/constant";

const SendInviteModal = ({ clientIds = [], toggle, service, setClientIds }) => {
  const toaster = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const sendInvite = async () => {
    setIsLoading(true);
    const ids = [];
    await clientIds.forEach(data => ids.push(data.id));
    const params = {
      clientDetailsArray: [...ids]
    };
    ClientServices.sendInvite(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current) toaster.current.success(data.message);
          setTimeout(() => {
            setClientIds([]);
            toggle();
          }, 500);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
        setIsLoading(false);
      })
      .catch(err => {
        if (toaster.current)
          toaster.current.error(
            err?.message ? err?.message : "Something went wrong!"
          );
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="form-group">
        <div className="pa-20">
          <p>
            Are you sure you want to invite {clientIds.length} patients? All
            patients invited will receive an invitation for the HUSK{" "}
            {!service ? "Nutrition/HUSK Mental health" : SERVICE_NAME[service]}{" "}
            app via email. This action cannot be undone.
          </p>
        </div>
      </div>

      <div className="flex-x center pt-4">
        <Button
          type="button"
          className="c-btn c-info form-button fs-16 demi-bold-text mr-10"
          style={{ maxWidth: "125px" }}
          onClick={sendInvite}
          loading={isLoading}
          disabled={isLoading}
          dataStyle={"expand-right"}
        >
          Continue
        </Button>
        <Button
          type="button"
          className="c-btn c-secondary form-button fs-16 demi-bold-text"
          style={{ maxWidth: "125px" }}
          onClick={toggle}
        >
          Close
        </Button>
      </div>
      <Toaster ref={toaster} />
    </>
  );
};

export default compose(enhancer)(SendInviteModal);
