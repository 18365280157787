import React from "react";

export const HamburgerIcon = () => (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ cursor: "pointer", margin: "10px" }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.5 0.333344H15.5V2.00001H0.5V0.333344ZM0.5 6.16667H15.5V7.83334H0.5V6.16667ZM15.5 12H0.5V13.6667H15.5V12Z"
      fill="white"
    />
  </svg>
);
