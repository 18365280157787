import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import FormServices from "../../api/FormServices";
import Loader from "../common/Loader";
import CommonModel from "../common/CommonModel";
import DeleteModal from "../common/DeleteModal";
//import Moment from "react-moment";
import Toaster from "../common/Toaster";
import { MODULE_NAMES, PERMISSION_TYPE, TABLE } from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import { hasAccess } from "util/sidebar";

const FormTable = props => {
  const { permissions } = props;
  const toaster = useRef(null);
  const [tblData, settblData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("id");
  const [sortBy, setSortBy] = useState("ASC");
  const [id, setId] = useState(undefined);
  const [modal, setModal] = useState(undefined);
  const [serviceId, setServiceId] = useState(null);

  useEffect(() => {
    getList(field, sortBy, searchText, activePage + 1);
    setPages(10);
  }, [serviceId]);

  const deleteClick = data => {
    setId(data.id);
    setModal(true);
  };

  function deleteHandler() {
    setLoader(true);
    const params = {
      form_id: id
    };
    // Add form delete API code here
    FormServices.delete(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current) toaster.current.success(data.message);
          setLoader(false);
          setModal(false);
          setTimeout(() => {
            getList(field, sortBy, searchText, activePage + 1);
          }, 1000);
        } else {
          if (toaster.current) toaster.current.error(data.message);
          setLoader(false);
          setModal(false);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.message);
        setLoader(false);
        setModal(false);
      });
  }

  function toggle() {
    setModal(false);
  }

  const columns = [
    {
      Header: "ID",
      accessor: "id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 100,
      sortable: true
    },
    {
      Header: "Name",
      accessor: "name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true
    },
    {
      Header: "Category",
      accessor: "service.name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false
    },
    {
      Header: "Type",
      accessor: "type",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true
    },
    {
      Header: "Action",
      accessor: "action",
      className: "text-center",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,

      Cell: props => (
        <div className="react-action-class flex">
          {props.original.type !== "custom" ? (
            props.original.type === "static" ? (
              <Button
                className="c-btn c-info mr-10"
                onClick={() => formAction("view", props.original)}
              >
                <div className="fs-14 medium-text" title="View">
                  <i className="fas fa-eye" />
                </div>
              </Button>
            ) : (
              <Button
                className="c-btn c-info mr-10"
                onClick={() => window.open(props.original.file_name)}
              >
                <div className="fs-14 medium-text" title="View">
                  <i className="fas fa-eye" />
                </div>
              </Button>
            )
          ) : (
            "-"
          )}
          {props.original.type !== "custom" &&
            props.original.type !== "static" &&
            (hasAccess(MODULE_NAMES.FORM, PERMISSION_TYPE.EDIT, permissions) ? (
              <Button
                className="c-btn c-success mr-10"
                onClick={() => formAction("edit", props.original)}
              >
                <div className="fs-14 medium-text">
                  <i className="fas fa-edit" />
                </div>
              </Button>
            ) : null)}
          {props.original.type !== "custom" &&
            props.original.type !== "static" &&
            (hasAccess(
              MODULE_NAMES.FORM,
              PERMISSION_TYPE.DELETE,
              permissions
            ) ? (
              <Button
                className="c-btn c-danger"
                onClick={() => deleteClick(props.original)}
              >
                <div className="fs-14 medium-text">
                  <i className="fas fa-trash" />
                </div>
              </Button>
            ) : null)}
        </div>
      )
    }
  ];

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  const formAction = (action, data = null) => {
    // Here you can view the data and make forward action for edit data
    if (action === "add") {
      props.history.push("/form/add");
    } else if (action === "edit") {
      props.history.push(`/form/edit/${data.id}`);
    } else if (action === "view") {
      props.history.push(`/form/view/${data.id}`, { ...data });
    }
  };

  function getList(
    field = "created_at",
    sortBy = "DESC",
    search = "",
    page = "1",
    service_id = null
  ) {
    const params = {
      sort_by: field,
      order: sortBy,
      search: search,
      page: page,
      service_id:
        props.admin_role_id >= 3 ? props.service_id : serviceId || service_id
    };
    setLoader(true);
    FormServices.list(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count / TABLE.LIMIT));
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
          setLoader(false);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
        setLoader(false);
      });
  }

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Form List
            </div>
            <div className="mr-10">
              <input
                value={searchText}
                onChange={e => {
                  setSearchText(e.target.value);
                  const search = e.target.value;
                  setLoader(true);
                  setActivePage(0);
                  setTimeout(() => {
                    getList(field, sortBy, search, 1);
                  }, 500);
                }}
                type="text"
                placeholder="Search..."
                className="fs-14 medium-text plr-10 form-control react-form-input"
              />
            </div>
            {hasAccess(MODULE_NAMES.FORM, PERMISSION_TYPE.ADD, permissions) ? (
              <div>
                <Button
                  className="c-btn c-primary ma-5"
                  onClick={() => formAction("add")}
                >
                  <i className="fas fa-plus mr-10" />
                  Add Form
                </Button>
              </div>
            ) : null}
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              LoadingComponent={Loader}
              onFetchData={onChangePageGetData}
              onPageChange={pageIndex => {
                setActivePage(pageIndex);
                getList(field, sortBy, searchText, pageIndex + 1);
              }}
              onSortedChange={sortProperties => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getList(sortProperties[0].id, sort, searchText, 1);
                // alert("sort handler", sortProperties);
                // const orderByDirection = sortProperties[0].desc ? 1 : 0;

                // updateMapStateInfoOrderByColumn(sortProperties[0].id, orderByDirection);
              }}
            />
          </div>
          <CommonModel
            modal={modal}
            toggle={toggle}
            children={
              <DeleteModal
                text={"Are you sure you want to delete this form?"}
                deleteHandler={deleteHandler}
                toggle={toggle}
              />
            }
          />
        </div>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};

export default withRouter(FormTable);
