import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import ContentLibraryServices from "../../api/ContentLibraryServices";
import CategoryServices from "api/CategoryServices";
import Loader from "../common/Loader";
import CommonModel from "../common/CommonModel";
import DeleteModal from "../common/DeleteModal";
import Toaster from "../common/Toaster";
import {
  CONTENT_LIBRARY_TYPE,
  CONTENT_LIBRARY_STATUS,
  TABLE,
  SERVICE_TYPE,
  MODULE_NAMES,
  PERMISSION_TYPE
} from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import moment from "moment";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
import { hasAccess } from "util/sidebar";

const ContentLibraryTable = props => {
  const { permissions } = props;
  const toaster = useRef(null);
  const [tblData, settblData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("id");
  const [sortBy, setSortBy] = useState("ASC");
  const [id, setId] = useState(undefined);
  const [status, setStatus] = useState(undefined);
  const [modal, setModal] = useState(undefined);
  const [statusText, setStatusText] = useState("");
  const [statusValue, setStatusValue] = useState("");
  const [typeText, setTypeText] = useState("");
  const [typeValue, setTypeValue] = useState("");
  const [parentDropdownOpen, setParentDropdownOpen] = useState(false);
  const [typeDropdownOpen, setTypeDropdownOpen] = useState(false);
  const [serviceDropdownOpen, setServiceDropdownOpen] = useState(false);
  const [serviceId, setServiceId] = useState();
  const [serviceName, setServiceName] = useState(null);
  const [serviceList, setServiceList] = useState([]);

  const toggleOne = () => setParentDropdownOpen(prevState => !prevState);
  const toggleTwo = () => setTypeDropdownOpen(prevState => !prevState);
  const toggleService = () => setServiceDropdownOpen(prevState => !prevState);

  useEffect(() => {
    getList(field, sortBy, searchText, activePage + 1);
    setPages(10);
  }, [statusText, typeText, serviceId]);

  useEffect(() => {
    CategoryServices.serviceDropdown()
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setServiceList([...data.data]);
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      });
  }, []);

  useEffect(() => {
    if (props.admin_role_id === 4) {
      setServiceId(1);
      setServiceName("Nutrition");
    } else if (props.admin_role_id === 5) {
      setServiceId(2);
      setServiceName("Mental Health");
    }
  }, []);

  const deleteClick = data => {
    setId(data.id);
    setStatus(data.status);
    setModal(true);
  };

  function deleteHandler() {
    setLoader(true);
    if (
      status === "unpublished" ||
      status === "save_draft" ||
      status === "pending_review"
    ) {
      const params = {
        id: id
      };

      ContentLibraryServices.delete(params)
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          if (data.status) {
            if (toaster.current) toaster.current.success(data.message);
            setModal(false);
            setTimeout(() => {
              getList(field, sortBy, searchText, activePage + 1, 1);
            }, 1000);
          } else {
            if (toaster.current) toaster.current.error(data.message);
          }
          setLoader(false);
        })
        .catch(err => {
          if (toaster.current) toaster.current.error(err.message);
          setLoader(false);
        });
    } else {
      if (toaster.current)
        toaster.current.error(
          "You must unpublish a content item before you can delete it."
        );
      setLoader(false);
      setModal(false);
    }
  }

  function toggle() {
    setModal(false);
  }

  const tagList = tagArray => {
    if (tagArray.length > 0) {
      let names = tagArray.map(data => {
        return data.content_tag.tag;
      });
      return names.join(", ");
    } else {
      return "-";
    }
  };

  useEffect(() => {
    if (localStorage.getItem("isRefresh") === "true") {
      props.failed();
      ContentLibraryServices.failedStatus()
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          if (data.status) {
            props.clear();
          }
        })
        .catch(err => {
          if (toaster.current) toaster.current.error(err.message);
        });

      localStorage.setItem("isRefresh", false);
    }
  }, []);

  const getStatus = fileName => {
    let d = props.videoStatus.find(v => v.fileName === fileName);
    return d ? d.status : "";
  };

  const getStatusName = status => {
    switch (status) {
      case 1:
        return "In progress";
        break;
      case 2:
        return "Complete";
        break;
      case 3:
        return "Failed";
        break;

      default:
        return "Video not submitted";
        break;
    }
  };

  const columns = [
    {
      Header: "ID",
      accessor: "id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 100,
      sortable: true
    },
    {
      Header: "Title",
      accessor: "title",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 250
    },
    {
      Header: "Type",
      accessor: "type",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      Cell: props => CONTENT_LIBRARY_TYPE[props.original.type],
      width: 130
    },
    {
      Header: "Service",
      accessor: "service.name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      width: 130
    },
    {
      Header: "Status",
      accessor: "status",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      Cell: props => CONTENT_LIBRARY_STATUS[props.original.status],
      width: 150
    },
    {
      Header: "Last Updated",
      accessor: "updated_at",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      Cell: props =>
        moment(props.original.updated_at).format("MM/DD/YYYY HH:mm:ss"),
      width: 200
    },
    {
      Header: "Tags",
      accessor: "",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      Cell: props => tagList(props.original.content_library_tags),
      width: 200
    },
    {
      Header: "Video Upload Status",
      accessor: "",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      sortable: false,
      Cell: props => (
        <div>
          {props.original.video_original_url
            ? getStatus(props.original.video_original_url.split(".")[0]) == ""
              ? getStatusName(props.original.video_url_status)
              : getStatusName(
                  getStatus(props.original.video_original_url.split(".")[0])
                )
            : "-"}
        </div>
      ),
      width: 200
    },
    {
      Header: "Action",
      accessor: "action",
      className: "",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 200,
      Cell: props => (
        <div className="react-action-class">
          {hasAccess(
            MODULE_NAMES.CONTENT,
            PERMISSION_TYPE.VIEW,
            permissions
          ) ? (
            <Button
              className="c-btn c-info mr-10"
              onClick={() => formAction("view", props.original)}
            >
              <div className="fs-14 medium-text" title="View">
                <i className="fas fa-eye" />
              </div>
            </Button>
          ) : null}
          {hasAccess(
            MODULE_NAMES.CONTENT,
            PERMISSION_TYPE.EDIT,
            permissions
          ) ? (
            <Button
              className="c-btn c-success mr-10"
              onClick={() => formAction("edit", props.original)}
            >
              <div className="fs-14 medium-text" title="Edit">
                <i className="fas fa-edit" />
              </div>
            </Button>
          ) : null}
          {hasAccess(
            MODULE_NAMES.CONTENT,
            PERMISSION_TYPE.DELETE,
            permissions
          ) ? (
            <Button
              className="c-btn c-danger"
              onClick={() => deleteClick(props.original)}
            >
              <div className="fs-14 medium-text" title="Delete">
                <i className="fas fa-trash" />
              </div>
            </Button>
          ) : null}
          {props.original.status === "published" && (
            <Button
              className="c-btn c-warning mr-10 text-white"
              onClick={() => formAction("share", props.original)}
            >
              <div className="fs-14 medium-text" title="Share">
                <i className="fas fa-share" />
              </div>
            </Button>
          )}
        </div>
      ) // Custom cell components!
    }
  ];

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  const formAction = (action, data = null) => {
    // Here you can view the data and make forward action for edit data
    if (action === "add") {
      props.history.push("/content-library/add");
    } else if (action === "edit") {
      props.history.push(`/content-library/edit/${data.id}`);
    } else if (action === "view") {
      props.history.push(`/content-library/view/${data.id}`, { ...data });
    } else if (action === "share") {
      props.history.push(`/content-library/share/${data.id}`, { ...data });
    }
  };

  function getList(
    field = "created_at",
    sortBy = "DESC",
    search = "",
    page = "1",
    service_id = null
  ) {
    const params = {
      sort_by: field,
      order: sortBy,
      search: search,
      service_id:
        props.admin_role_id >= 3 ? props.service_id : serviceId || service_id,
      page: page,
      status: statusValue,
      type: typeValue
    };
    setLoader(true);
    ContentLibraryServices.list(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count / 50));
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
        setLoader(false);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.message);
        setLoader(false);
      });
  }

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Content Items
            </div>
            <div className="mr-10">
              <input
                value={searchText}
                onChange={e => {
                  setSearchText(e.target.value);
                  const search = e.target.value;
                  setLoader(true);
                  setActivePage(0);
                  setTimeout(() => {
                    getList(field, sortBy, search, 1);
                  }, 500);
                }}
                type="text"
                placeholder="Search..."
                className="fs-14 medium-text plr-10 form-control react-form-input"
              />
            </div>
            {hasAccess(
              MODULE_NAMES.CONTENT,
              PERMISSION_TYPE.ADD,
              permissions
            ) ? (
              <div>
                <Button
                  className="c-btn c-primary ma-5"
                  onClick={() => formAction("add")}
                >
                  <i className="fas fa-plus mr-10" />
                  Add New Content Item
                </Button>
              </div>
            ) : null}
          </div>
          <div className="roe-card-header module-header">
            <div className="d-flex">
              <div className="fs-16 mr-10">
                <span className="fs-16">Status</span>
                <Dropdown isOpen={parentDropdownOpen} toggle={toggleOne}>
                  <DropdownToggle caret size="md">
                    {statusText ? statusText : "All"}
                  </DropdownToggle>
                  <DropdownMenu
                    modifiers={{
                      setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: data => {
                          return {
                            ...data,
                            styles: {
                              ...data.styles,
                              overflow: "auto",
                              maxHeight: "300px"
                            }
                          };
                        }
                      }
                    }}
                  >
                    <DropdownItem
                      key="0"
                      onClick={() => {
                        setStatusText("");
                        setStatusValue("");
                      }}
                    >
                      All
                    </DropdownItem>
                    <DropdownItem
                      key="Save Draft"
                      onClick={() => {
                        setStatusText("Draft");
                        setStatusValue("save_draft");
                      }}
                    >
                      Draft
                    </DropdownItem>
                    <DropdownItem
                      key="Pending Review"
                      onClick={() => {
                        setStatusText("Pending Review");
                        setStatusValue("pending_review");
                      }}
                    >
                      Pending Review
                    </DropdownItem>
                    <DropdownItem
                      key="Published"
                      onClick={() => {
                        setStatusText("Published");
                        setStatusValue("published");
                      }}
                    >
                      Published
                    </DropdownItem>
                    <DropdownItem
                      key="Unpublished"
                      onClick={() => {
                        setStatusText("Unpublished");
                        setStatusValue("unpublished");
                      }}
                    >
                      Unpublished
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
              <div className="fs-16 mr-10">
                <span className="fs-16">Type</span>
                <Dropdown isOpen={typeDropdownOpen} toggle={toggleTwo}>
                  <DropdownToggle caret size="md">
                    {typeText ? typeText : "All"}
                  </DropdownToggle>
                  <DropdownMenu
                    modifiers={{
                      setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: data => {
                          return {
                            ...data,
                            styles: {
                              ...data.styles,
                              overflow: "auto",
                              maxHeight: "300px"
                            }
                          };
                        }
                      }
                    }}
                  >
                    <DropdownItem
                      key="0"
                      onClick={() => {
                        setTypeText("");
                        setTypeValue("");
                      }}
                    >
                      All
                    </DropdownItem>
                    <DropdownItem
                      key="Article"
                      onClick={() => {
                        setTypeText("Article");
                        setTypeValue("article");
                      }}
                    >
                      Article
                    </DropdownItem>
                    {serviceId !== SERVICE_TYPE.MENTAL_HEALTH && (
                      <DropdownItem
                        key="Recipe"
                        onClick={() => {
                          setTypeText("Recipe");
                          setTypeValue("recipe");
                        }}
                      >
                        Recipe
                      </DropdownItem>
                    )}
                    <DropdownItem
                      key="Video"
                      onClick={() => {
                        setTypeText("Video");
                        setTypeValue("video");
                      }}
                    >
                      Video
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
              {props.admin_role_id && props.admin_role_id <= 2 && (
                <div className="fs-16 mr-10">
                  <span className="fs-16">Service</span>
                  <Dropdown isOpen={serviceDropdownOpen} toggle={toggleService}>
                    <DropdownToggle caret size="md">
                      {serviceId ? serviceName : "All"}
                    </DropdownToggle>
                    <DropdownMenu
                      modifiers={{
                        setMaxHeight: {
                          enabled: true,
                          order: 890,
                          fn: data => {
                            return {
                              ...data,
                              styles: {
                                ...data.styles,
                                overflow: "auto",
                                maxHeight: "300px"
                              }
                            };
                          }
                        }
                      }}
                    >
                      <DropdownItem
                        key="-1"
                        onClick={() => {
                          setServiceId("");
                          setServiceName("All");
                        }}
                      >
                        All
                      </DropdownItem>
                      {serviceList.length > 0 &&
                        serviceList.map((result, i) => {
                          return (
                            <DropdownItem
                              key={i}
                              onClick={() => {
                                if (
                                  result?.id === 2 &&
                                  typeValue === "recipe"
                                ) {
                                  setTypeText("");
                                  setTypeValue("");
                                }
                                setServiceId(result.id);
                                setServiceName(`${result.name}`);
                              }}
                            >
                              {result.name}
                            </DropdownItem>
                          );
                        })}
                    </DropdownMenu>
                  </Dropdown>
                </div>
              )}
              {/* )} */}
            </div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={50}
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              LoadingComponent={Loader}
              onFetchData={onChangePageGetData}
              onPageChange={pageIndex => {
                setActivePage(pageIndex);
                getList(field, sortBy, searchText, pageIndex + 1);
              }}
              onSortedChange={sortProperties => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getList(sortProperties[0].id, sort, searchText, 1);
                // alert("sort handler", sortProperties);
                // const orderByDirection = sortProperties[0].desc ? 1 : 0;

                // updateMapStateInfoOrderByColumn(sortProperties[0].id, orderByDirection);
              }}
            />
          </div>
          <CommonModel
            modal={modal}
            toggle={toggle}
            children={
              <DeleteModal
                text={"Are you sure you want to delete this content item?"}
                deleteHandler={deleteHandler}
                toggle={toggle}
              />
            }
          />
        </div>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};

export default withRouter(ContentLibraryTable);
