import React, { Fragment, useEffect, useState, useRef } from "react";
import { compose } from "redux";
import enhancer from "./validator";
import Button from "../button/Button";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import VisitProgramServices from "../../api/VisitProgramServices";
import CategoryServices from "../../api/CategoryServices";
import Toaster from "../common/Toaster";
import { Input } from "reactstrap";

const VisitProgramForm = props => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    submitCount,
    onSubmit,
    values,
    action
  } = props;

  const [serviceList, setServiceList] = useState([]);

  useEffect(() => {
    CategoryServices.serviceDropdown()
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setServiceList([...data.data]);
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      });
  }, []);

  useEffect(() => {
    if (props?.authData?.adminRoleId > 2) {
      setFieldValue("service", props?.authData?.service_id);
    }
  }, []);

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };
  const toaster = useRef();

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;
    if (isValid) {
      onSubmit(values, action);
      if (action === "add") {
        const params = {
          name: values.name,
          description: values.description,
          visits: values.visits,
          time_count: values.time_count,
          time_interval: values.time_interval,
          is_bmi: values.is_bmi,
          service_id: values.service,
          bmi_threshold: values.bmi_threshold
        };
        VisitProgramServices.add(params)
          .then(res => {
            let data = EncryptDecrypt.decrypt(res.data);
            if (data.status) {
              if (toaster.current) toaster.current.success(data.message);
            } else {
              if (toaster.current) toaster.current.error(data.message);
            }
          })
          .catch(err => {
            if (toaster.current) toaster.current.error(err.response);
          });
      } else if (action === "edit") {
        const params = {
          id: values.id,
          name: values.name,
          description: values.description,
          visits: values.visits,
          time_count: values.time_count,
          time_interval: values.time_interval,
          is_bmi: values.is_bmi,
          service_id: values.service,
          bmi_threshold: values.bmi_threshold
        };
        VisitProgramServices.edit(params)
          .then(res => {
            let data = EncryptDecrypt.decrypt(res.data);
            if (data.status) {
              if (toaster.current) toaster.current.success(data.message);
            } else {
              if (toaster.current) toaster.current.error(data.message);
            }
          })
          .catch(err => {
            if (toaster.current) toaster.current.error(err.response);
          });
      }
    }

    handleSubmit();
  };

  const handleServiceChange = e => {
    setFieldValue("service", e.target.value);
  };

  return (
    <div>
      <form>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Name <span className="asterisk">*</span>
          </label>
          <input
            type="text"
            className="form-control react-form-input"
            id="name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            placeholder="Add Program Name"
          />
          <Error field="name" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Description</label>
          <input
            type="text"
            className="form-control react-form-input"
            id="description"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.description}
            placeholder="Add Description"
          />
          <Error field="description" />
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <label className="fs-16 medium-text">
                Visit <span className="asterisk">*</span>
              </label>
              <input
                type="number"
                min="1"
                className="form-control react-form-input"
                id="visits"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.visits}
                placeholder="Add Visits"
              />
              <Error field="visits" />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label className="fs-16 medium-text">
                Time Count <span className="asterisk">*</span>
              </label>
              <input
                type="number"
                min="1"
                className="form-control react-form-input"
                id="time_count"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.time_count}
                placeholder="Add Time Count"
              />
              <Error field="time_count" />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label className="fs-16 medium-text">
                Time Interval <span className="asterisk">*</span>
              </label>
              <Input
                type="select"
                name=""
                id="time_interval"
                onChange={handleChange}
                value={values.time_interval}
                onBlur={handleBlur}
              >
                <option value="">Select Time Interval</option>
                <option key="Day" value="Day">
                  Day
                </option>
                <option key="Week" value="Week">
                  Week
                </option>
                <option key="Month" value="Month">
                  Month
                </option>
                <option key="Year" value="Year">
                  Year
                </option>
              </Input>
              <Error field="time_interval" />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label className="fs-16 medium-text">
                Service<span className="asterisk">*</span>
              </label>
              <Input
                type="select"
                name="service"
                id="service"
                onChange={e => handleServiceChange(e)}
                value={values?.service}
                onBlur={handleBlur}
                disabled={props?.authData?.adminRoleId > 2}
              >
                <option value="">Select Service</option>
                {serviceList.map((service, i) => {
                  return (
                    <Fragment>
                      <option key={`option${i}`} value={service.id}>
                        {service.name}
                      </option>
                    </Fragment>
                  );
                })}
              </Input>
              <Error field="service" />
            </div>
          </div>
        </div>

        {String(values?.service) === "1" ? (
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <label className="fs-16 medium-text">
                  BMI <span className="asterisk">*</span>
                </label>
                <div className="col-sm-10">
                  <div className="form-check form-check-inline mt-7">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="is_bmi"
                      id="is_bmi"
                      value="1"
                      onChange={handleChange}
                      checked={values.is_bmi === "1" ? "checked" : false}
                    />
                    <label className="form-check-label">Yes</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="is_bmi"
                      id="is_bmi"
                      value="0"
                      onChange={handleChange}
                      checked={values.is_bmi === "0" ? "checked" : false}
                    />
                    <label className="form-check-label">No</label>
                  </div>
                </div>
                <Error field="is_bmi" />
              </div>
            </div>
            {values && values.is_bmi === "1" ? (
              <div className="col-md-4">
                <div className="form-group">
                  <label className="fs-16 medium-text">
                    BMI Threshold <span className="asterisk">*</span>
                  </label>
                  <input
                    type="number"
                    min="0"
                    className="form-control react-form-input"
                    id="bmi_threshold"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.bmi_threshold}
                    placeholder="Add BMI Threshold"
                  />
                  <Error field="bmi_threshold" />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}

        <div>
          <Button
            type="button"
            className="c-btn c-info form-button fs-16 demi-bold-text"
            style={{ maxWidth: "125px" }}
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </div>
      </form>
      <Toaster ref={toaster} />
    </div>
  );
};

export default compose(enhancer)(VisitProgramForm);
