import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    newPassword: Yup.string().required("This field is required"),
    confirmPassword: Yup.string().required("This field is required")
  }),
  validate: values => {
    const errors = {};
    const pattern = new RegExp(".*[@#$%^&+=!]");
    const pattern2 = new RegExp("(?=.*[A-Z])");
    const pattern3 = new RegExp("(?=.*[0-9])");

    if (values.newPassword) {
      if (values.newPassword.length < 8) {
        errors.newPassword = "password must have 8 characters";
      } else if (!pattern.test(values.newPassword)) {
        errors.newPassword = `password must have at-least one special character `;
      } else if (!pattern2.test(values.newPassword)) {
        errors.newPassword = `password must have at-least one Capital Letter `;
      } else if (!pattern3.test(values.newPassword)) {
        errors.newPassword = `password must have at-least one Number `;
      }
    }

    if (
      values.confirmPassword &&
      values.newPassword !== values.confirmPassword
    ) {
      errors.confirmPassword = "password not matched";
    }
    return errors;
  },
  mapPropsToValues: props => ({
    newPassword: "",
    confirmPassword: ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
