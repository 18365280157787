import React, { useEffect, useRef, useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import ClientBasicViewDetails from "../viewclient/ClientBasicViewDetails";
// import FoodTracking from "../foodtracking/FoodTracking";
import Tracking from "./Tracking";

import VisitManagement from "../visittracking/VisitManagement";
import InsuranceCarrier from "../insurancecarrier/InsuranceCarrier";
// import GoalTracking from "../GoalTracking/GoalTracking";
import ProgramDetails from "../viewclient/ProgramDetails";
import UserNote from "../userNote/userNote";
import UserForms from "components/userForms/UserForms";
import CareTeam from "./CareTeam";
import UserDocument from "components/userDocument/UserDocument";
import Referrals from "./Referrals";
import ClientServices from "api/ClientServices";
import EncryptDecrypt from "api/EncryptDecrypt";
import moment from "moment";
import Toaster from "components/common/Toaster";
import { calculateAgeFromDOB } from "util/getAge";
import { connect } from "react-redux";
import { hasAccess } from "util/sidebar";
import { MODULE_NAMES, PERMISSION_TYPE } from "helper/constant";

const TRACKINGS = ["?meal", "?goal", "?thoughts", "?feelings"];
const DOCUMENT = ["?document"];
const VISIT = ["?visit"];
const CARETEAM = ["?care-team"];

const ClientViewDetails = props => {
  const { permissions } = props;
  const toaster = useRef(null);
  const hasParam = props.match.params.id;
  const [activeTab, setActiveTab] = useState("1");
  const [editedData, setEditedData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (hasParam) {
      const params = {
        id: hasParam
      };
      ClientServices.view(params)
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          setEditedData(data.data);
          setIsLoading(false);
        })
        .catch(err => {
          if (err?.response?.status === 403) {
            if (toaster.current) {
              toaster.current.error("You cannot access this user info!");
              setTimeout(() => {
                props.history.push("/client");
              }, 3000);
            }
          } else {
            if (toaster.current) toaster.current.error(err?.message);
          }
          setIsLoading(false);
        });
    }
  }, []);

  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const submitFormHandler = (data, action, activeTab) => {
    setActiveTab(activeTab);
  };

  useEffect(() => {
    if (TRACKINGS.includes(props.location.search)) setActiveTab("2");
    if (DOCUMENT.includes(props.location.search)) setActiveTab("7");
    if (VISIT.includes(props.location.search)) setActiveTab("3");
    if (CARETEAM.includes(props.location.search)) setActiveTab("9");
  }, []);

  const showTracking = () => {
    let show = true;
    if (
      props?.auth?.tier_id === 1 &&
      props?.auth?.adminType !== "employee" &&
      props?.auth?.admin_role_id === 3
    )
      show = false;
    return show;
  };

  const getClientHeader = data => {
    return `${data?.first_name} ${data?.last_name}${
      data?.dob
        ? `, ${moment(data?.dob).format("MM/DD/YYYY")} (${calculateAgeFromDOB(
            moment(data?.dob).format("YYYY/MM/DD")
          )})`
        : " (DOB-N/A)"
    } ${data?.gender}`;
  };

  return (
    <div className="plr-15">
      <div className="roe-card-style mtb-15">
        <div className="roe-card-header module-header flex flex-column">
          {isLoading && <div className="flex fill-width pb-6">Loading..</div>}
          {editedData && (
            <div className="flex fill-width pb-6">
              {getClientHeader(editedData)}
            </div>
          )}
          <div className="flex fill-width flex-1 fs-16 demi-bold-text cursor-pointer">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab === "1" },
                    "doc-title"
                  )}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  Client Details
                </NavLink>
              </NavItem>
              {showTracking() && (
                <NavItem>
                  <NavLink
                    className={classnames(
                      { active: activeTab === "2" },
                      "doc-title"
                    )}
                    onClick={() => {
                      toggle("2");
                    }}
                    disabled={
                      !hasAccess(
                        MODULE_NAMES.CLIENT_TRACKING,
                        PERMISSION_TYPE.VIEW,
                        permissions
                      )
                    }
                  >
                    Tracking
                  </NavLink>
                </NavItem>
              )}
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab === "3" },
                    "doc-title"
                  )}
                  onClick={() => {
                    toggle("3");
                  }}
                  disabled={
                    !hasAccess(
                      MODULE_NAMES.CLIENT_VISIT_HISTORY,
                      PERMISSION_TYPE.VIEW,
                      permissions
                    )
                  }
                >
                  Visit History
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab === "4" },
                    "doc-title"
                  )}
                  onClick={() => {
                    toggle("4");
                  }}
                  disabled={
                    !hasAccess(
                      MODULE_NAMES.CLIENT_PROGRAM_DETAILS,
                      PERMISSION_TYPE.VIEW,
                      permissions
                    )
                  }
                >
                  Program Details
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab === "5" },
                    "doc-title"
                  )}
                  onClick={() => {
                    toggle("5");
                  }}
                  disabled={
                    !hasAccess(
                      MODULE_NAMES.CLIENT_INSURANCE,
                      PERMISSION_TYPE.VIEW,
                      permissions
                    )
                  }
                >
                  Insurance Carrier
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab === "6" },
                    "doc-title"
                  )}
                  onClick={() => {
                    toggle("6");
                  }}
                  disabled={
                    !hasAccess(
                      MODULE_NAMES.CLIENT_NOTES,
                      PERMISSION_TYPE.VIEW,
                      permissions
                    )
                  }
                >
                  Notes
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab === "7" },
                    "doc-title"
                  )}
                  onClick={() => {
                    toggle("7");
                  }}
                  disabled={
                    !hasAccess(
                      MODULE_NAMES.CLIENT_DOCUMENT,
                      PERMISSION_TYPE.VIEW,
                      permissions
                    )
                  }
                >
                  Documents
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab === "8" },
                    "doc-title"
                  )}
                  onClick={() => {
                    toggle("8");
                  }}
                  disabled={
                    !hasAccess(
                      MODULE_NAMES.CLIENT_FORMS,
                      PERMISSION_TYPE.VIEW,
                      permissions
                    )
                  }
                >
                  Forms
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab === "9" },
                    "doc-title"
                  )}
                  onClick={() => {
                    toggle("9");
                  }}
                >
                  Care Team
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab === "10" },
                    "doc-title"
                  )}
                  onClick={() => {
                    toggle("10");
                  }}
                >
                  Referrals
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </div>
        <div className="roe-card-body">
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <ClientBasicViewDetails
                onSubmit={submitFormHandler}
                {...props}
                toggle={data => toggle(data)}
                editedData={editedData}
              />
            </TabPane>
            {showTracking() && (
              <TabPane tabId="2">
                <Tracking
                  onSubmit={submitFormHandler}
                  {...props}
                  toggle={data => toggle(data)}
                />

                {/* <FoodTracking onSubmit={submitFormHandler} {...props} /> */}
                {/* <GoalTracking onSubmit={submitFormHandler} {...props} /> */}
              </TabPane>
            )}
            <TabPane tabId="3">
              <VisitManagement
                onSubmit={submitFormHandler}
                {...props}
                toggleOne={data => toggle(data)}
                getClientHeader={
                  isLoading
                    ? "Loading.."
                    : editedData
                    ? `${getClientHeader(editedData)}`
                    : "-"
                }
              />
            </TabPane>
            <TabPane tabId="4">
              <ProgramDetails onSubmit={submitFormHandler} {...props} />
            </TabPane>
            <TabPane tabId="5">
              <InsuranceCarrier onSubmit={submitFormHandler} {...props} />
            </TabPane>
            <TabPane tabId="6">
              {<UserNote onSubmit={submitFormHandler} {...props} />}
            </TabPane>
            <TabPane tabId="7">
              {<UserDocument onSubmit={submitFormHandler} {...props} />}
            </TabPane>
            <TabPane tabId="8">
              <UserForms onSubmit={submitFormHandler} {...props} />
            </TabPane>
            <TabPane tabId="9">
              <CareTeam clientData={editedData} {...props} />
            </TabPane>
            <TabPane tabId="10">
              <Referrals {...props} />
            </TabPane>
          </TabContent>
        </div>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};
const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    ...state.permissionReducer
  };
};

export default connect(mapStateToProps, null)(ClientViewDetails);
