import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .trim()
      .required("This field is required")
      .max(255, "Name must be less than 255 characters"),
    code: Yup.string()
      .trim("Cannot include leading and trailing spaces")
      .strict(true)
  }),
  mapPropsToValues: props => ({
    id:
      props && props.editedData && props.editedData.id
        ? props.editedData.id
        : "",
    name:
      props && props.editedData && props.editedData.name
        ? props.editedData.name
        : "",
    code:
      props && props.editedData && props.editedData.code
        ? props.editedData.code
        : ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
