import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    point: Yup.string()
      .trim()
      .required("This field is required")
  }),
  mapPropsToValues: props => ({
    action: props?.action,
    tierId: props?.editedData
      ? props && props?.editedData && props?.editedData?.reward_tier?.id
      : "",
    name: props.editedData
      ? props && props?.editedData && props?.editedData?.product_name
      : "",
    description: props?.editedData
      ? props && props?.editedData && props?.editedData?.description
      : "",
    skuNumber: props.editedData
      ? props && props?.editedData && props?.editedData?.sku_no
      : "",
    point: props.editedData
      ? props && props?.editedData && props?.editedData?.point
      : "",
    price: props.editedData
      ? props && props?.editedData && props?.editedData?.price
      : "",
    image: props.editedData
      ? props && props?.editedData && props?.editedData?.image
      : ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
