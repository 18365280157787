import EncryptDecrypt from "api/EncryptDecrypt";
import UserServices from "api/VisitService";
import Loader from "components/common/Loader";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "reactstrap";

const FinancialStatus = ({ id, toggle, visitBillId }) => {
  const toaster = useRef(null);
  const [isLoadig, setIsLoading] = useState(true);
  const [details, setDetails] = useState(null);

  useEffect(() => {
    (() => {
      UserServices.getFinancialStatus({ appointment_id: id })
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          if (data?.status) {
            setDetails(data?.data);
          } else {
            if (toaster.current) toaster.current.error(data.message);
          }
          setIsLoading(false);
        })
        .catch(err => {
          if (toaster.current) toaster.current.error(err.message);
          setIsLoading(false);
        });
    })();
  }, [id]);

  const getUpdatedFinancialStatus = () => {
    setIsLoading(true);
    UserServices.getUpdatedFinancialStatus({ visitBillId: visitBillId })
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data?.status) {
          setDetails({ ...details, ...data?.data });
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
        setIsLoading(false);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.message);
        setIsLoading(false);
      });
  };

  return (
    <div className="plr-15">
      <div className="roe-card-style mtb-15">
        <div className="roe-card-header module-header">
          <div className="flex-1 fs-20 demi-bold-text">Financial Stats</div>
        </div>
        {!!isLoadig ? (
          <Loader loading={isLoadig} />
        ) : (
          <div className="roe-card-body">
            {/* <div className="pt-2 flex">
              <div className="view-label">Claim Status:</div>
              <div
                className={
                  details?.amd_claim_status === "Denied" ||
                  !details?.amd_claim_status
                    ? "color-setting-error fs-18"
                    : "color-husk-green fs-18 fs-18"
                }
              >
                {details?.amd_claim_status ? details?.amd_claim_status : "N/A"}
              </div>
            </div> */}
            {/* {details?.amd_claim_status !== "Paid" && (
              <>
                <div className="pt-2 flex">
                  <div className="view-label">Resubmitted Date:</div>
                  <div className="color-husk-green fs-18">
                    {details?.amd_claim_submit_date
                      ? moment(details?.amd_claim_submit_date).format(
                          "MM/DD/YYYY"
                        )
                      : "-"}
                  </div>
                </div>
                <div className="pt-2 flex flex">
                  <div className="view-label">Resubmitted Note:</div>
                  <div className="color-husk-green fs-18">
                    {details?.amd_claim_submit_note
                      ? details?.amd_claim_submit_note
                      : "-"}
                  </div>
                </div>
              </>
            )} */}

            {/* <div className="pt-2 flex">
              <div className="view-label">Copay:</div>
              <div className="color-husk-green fs-18">
                {details?.copay ? details?.copay : "-"}
              </div>
            </div>
            <div className="pt-2 flex">
              <div className="view-label">Copay Type:</div>
              <div className="color-husk-green fs-18">
                {details?.copay_type ? details?.copay_type : "-"}
              </div>
            </div>
            <div className="pt-2 flex">
              <div className="view-label">Deductible:</div>
              <div className="color-husk-green fs-18">
                {details?.deductible ? details?.deductible : "-"}
              </div>
            </div>
            <div className="pt-2 flex">
              <div className="view-label">Deductible Met:</div>
              <div className="color-husk-green fs-18">
                {details?.deductible_met ? details?.deductible_met : "-"}
              </div>
            </div> */}
            {details.hasOwnProperty("category_details") && (
              <div className="pt-2 flex">
                <div className="view-label">Category Details:</div>
                <div className="color-husk-green fs-18">
                  {details.category_details ? details.category_details : "-"}
                </div>
              </div>
            )}

            {details.hasOwnProperty("check_number") && (
              <div className="pt-2 flex">
                <div className="view-label">Check Number:</div>
                <div className="color-husk-green fs-18">
                  {details.check_number ? details.check_number : "-"}
                </div>
              </div>
            )}

            {details.hasOwnProperty("status_details") && (
              <div className="pt-2 flex">
                <div className="view-label">Status Details:</div>
                <div className="color-husk-green fs-18">
                  {details.status_details ? details.status_details : "-"}
                </div>
              </div>
            )}

            {details.hasOwnProperty("payment_amount") && (
              <div className="pt-2 flex">
                <div className="view-label">Payment Amount:</div>
                <div className="color-husk-green fs-18">
                  {details.payment_amount
                    ? Number(details.payment_amount).toFixed(2)
                    : "-"}
                </div>
              </div>
            )}

            {details.hasOwnProperty("payment_writeoff") && (
              <div className="pt-2 flex">
                <div className="view-label">Payment Writeoff (Adjustment)</div>
                <div className="color-husk-green fs-18">
                  {details.payment_writeoff
                    ? Number(details.payment_writeoff).toFixed(2)
                    : "-"}
                </div>
              </div>
            )}

            {details.hasOwnProperty("payment_date") && (
              <div className="pt-2 flex">
                <div className="view-label">Payment Date:</div>
                <div className="color-husk-green fs-18">
                  {details.payment_date
                    ? moment(details.payment_date).format("YYYY-MM-DD")
                    : "-"}
                </div>
              </div>
            )}

            {details.hasOwnProperty("electronic_fund_transfer_date") && (
              <div className="pt-2 flex">
                <div className="view-label">Electronic Fund Transfer Date:</div>
                <div className="color-husk-green fs-18">
                  {details.electronic_fund_transfer_date
                    ? moment(details.electronic_fund_transfer_date).format(
                        "YYYY-MM-DD"
                      )
                    : "-"}
                </div>
              </div>
            )}

            {details.hasOwnProperty("icn_number") && (
              <div className="pt-2 flex">
                <div className="view-label">ICN Number:</div>
                <div className="color-husk-green fs-18">
                  {details.icn_number ? details.icn_number : "-"}
                </div>
              </div>
            )}

            {details.hasOwnProperty("copay") && (
              <div className="pt-2 flex">
                <div className="view-label">Copay:</div>
                <div className="color-husk-green fs-18">
                  {details.copay ? details.copay : "-"}
                </div>
              </div>
            )}

            {details.hasOwnProperty("copay_type") && (
              <div className="pt-2 flex">
                <div className="view-label">Copay Type:</div>
                <div className="color-husk-green fs-18">
                  {details.copay_type ? details.copay_type : "-"}
                </div>
              </div>
            )}

            {details.hasOwnProperty("deductible") && (
              <div className="pt-2 flex">
                <div className="view-label">Deductible:</div>
                <div className="color-husk-green fs-18">
                  {details.deductible ? details.deductible : "-"}
                </div>
              </div>
            )}

            {details.hasOwnProperty("deductible_met") && (
              <div className="pt-2 flex">
                <div className="view-label">Deductible Met:</div>
                <div className="color-husk-green fs-18">
                  {details.deductible_met ? details.deductible_met : "-"}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <Button
        type="button"
        className="c-btn c-info form-button fs-16 demi-bold-text max-w-150 mtop-28 mbottom-20"
        onClick={getUpdatedFinancialStatus}
      >
        Fetch Recent Status
      </Button>
      <Button
        type="button"
        className="c-btn c-secondary form-button fs-16 demi-bold-text max-w-125 mtop-28 mbottom-20 mleft-10"
        onClick={toggle}
      >
        Close
      </Button>
    </div>
  );
};

export default FinancialStatus;
