import React, { useRef, useState } from "react";
import { compose } from "redux";
import enhancer from "./validator";
import Button from "../button/Button";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import InsuranceServices from "../../api/InsuranceServices";
import Toaster from "../common/Toaster";

const InsuranceForm = props => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    onSubmit,
    values,
    action
  } = props;
  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };
  const toaster = useRef();

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;

    if (isValid) {
      onSubmit(values, action);
      if (action === "add") {
        const params = {
          name: values.name,
          payor_id: values.payor_id,
          is_display_registration: values.is_display_registration
        };
        InsuranceServices.add(params)
          .then(res => {
            let data = EncryptDecrypt.decrypt(res.data);
            if (data.status) {
              if (toaster.current) toaster.current.success(data.message);
            } else {
              if (toaster.current) toaster.current.error(data.message);
            }
          })
          .catch(err => {
            if (toaster.current) toaster.current.error(err.response);
          });
      } else if (action === "edit") {
        const params = {
          id: values.id,
          name: values.name,
          payor_id: values.payor_id,
          is_display_registration: values.is_display_registration
        };
        InsuranceServices.edit(params)
          .then(res => {
            let data = EncryptDecrypt.decrypt(res.data);
            if (data.status) {
              if (toaster.current) toaster.current.success(data.message);
            } else {
              if (toaster.current) toaster.current.error(data.message);
            }
          })
          .catch(err => {
            if (toaster.current) toaster.current.error(err.response);
          });
      }
    }

    handleSubmit();
  };

  return (
    <div>
      <form>
        <div className="row">
          <div className="col-md-6 form-group">
            <label className="fs-16 medium-text">
              Name <span className="asterisk">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              placeholder="Add Insurance Name"
            />
            <Error field="name" />
          </div>
          <div className="col-md-6 form-group">
            <label className="fs-16 medium-text">AdvancedMD ID</label>
            <input
              type="text"
              className="form-control react-form-input"
              id="payor_id"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.payor_id}
              placeholder="Add AdvancedMD ID"
            />
            <Error field="payor_id" />
          </div>
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Display on Registration? <span className="asterisk">*</span>
          </label>
          <div className="col-sm-10">
            <div className="form-check form-check-inline mt-7">
              <input
                className="form-check-input"
                type="radio"
                name="is_display_registration"
                id="is_display_registration"
                value="1"
                onChange={handleChange}
                checked={
                  values.is_display_registration === "1" ? "checked" : false
                }
              />
              <label className="form-check-label">Yes</label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="is_display_registration"
                id="is_display_registration"
                value="0"
                onChange={handleChange}
                checked={
                  values.is_display_registration === "0" ? "checked" : false
                }
              />
              <label className="form-check-label">No</label>
            </div>
          </div>
          <Error field="is_display_registration" />
        </div>
        <div>
          <Button
            type="button"
            className="c-btn c-info form-button fs-16 demi-bold-text"
            style={{ maxWidth: "125px" }}
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </div>
      </form>
      <Toaster ref={toaster} />
    </div>
  );
};

export default compose(enhancer)(InsuranceForm);
