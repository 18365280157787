import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import ReportServices from "../../api/ReportServices";
import Loader from "../common/Loader";
import Toaster from "../common/Toaster";
import { TABLE } from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import moment from "moment";
import Button from "components/button/Button";
import CommonModel from "../common/CommonModel";
import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import ClientServices from "../../api/ClientServices";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";

const MealDeliveryTable = props => {
  const [tblData, settblData] = useState();
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("id");
  const [sortBy, setSortBy] = useState("ASC");
  const [dateRange, setDateRange] = useState({});
  const [modal, setModal] = useState(undefined);
  const [open, setOpen] = useState(false);
  const toaster = useRef();
  const [RDId, setRDId] = useState(null);
  const [clientId, setClientId] = useState("");

  useEffect(() => {
    getList(field, sortBy, searchText, activePage + 1, clientId);
    setPages(10);
  }, []);

  const columns = [
    {
      Header: "Client ID",
      accessor: "user.id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 150
    },
    {
      Header: "Client First Name",
      accessor: "user.first_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200
    },
    {
      Header: "Client Last Name",
      accessor: "user.last_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200
    },
    {
      Header: "Client Email",
      accessor: "user.email",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 300
    },
    {
      Header: "Offer Redeemed Date",
      accessor: "created_at",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200,
      Cell: props => moment(props.original.created_at).format("MM/DD/YYYY")
    }
  ];

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  function exportList(
    field = "name",
    sortBy = "DESC",
    search = "",
    page = "1"
  ) {
    const params = {
      sort_by: field,
      order: sortBy,
      search: search,
      page: page,
      id: clientId
    };
    ReportServices.mealDeliveryListCsv(params);
  }

  const formAction = (action, data = null) => {
    // Here you can view the data and make forward action for edit data
    if (action === "export") {
      exportList(field, sortBy, searchText, activePage + 1);
    }
  };

  function getList(
    field = "name",
    sortBy = "DESC",
    search = "",
    page = "1",
    client_id = null
  ) {
    const params = {
      sort_by: field,
      order: sortBy,
      search: search,
      page: page,
      id: client_id
    };
    setLoader(true);
    ReportServices.mealDeliveryList(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count / TABLE.LIMIT));
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });
  }

  const toggle = () => {
    setOpen(!open);
    setModal(!open);
  };

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Meal Delivery Report
            </div>
            <div>
              <Button
                className="c-btn c-primary ma-5"
                onClick={() => formAction("export")}
              >
                <i className="fas fa-download mr-10" />
                Export
              </Button>
            </div>
          </div>
          <div className="roe-card-header module-header">
            <div className="fs-14 mr-10">
              <input
                value={searchText}
                onChange={e => {
                  setSearchText(e.target.value);
                  const search = e.target.value;
                  setLoader(true);
                  setActivePage(0);
                  setTimeout(() => {
                    getList(field, sortBy, search, activePage + 1, clientId);
                  }, 500);
                }}
                type="text"
                placeholder="Search..."
                className="fs-14 medium-text plr-10 form-control react-form-input  mr-10"
              />
            </div>
            <div className="fs-14 mr-10">
              <input
                value={clientId}
                onChange={e => {
                  setClientId(e.target.value);
                  const clientId = e.target.value;
                  setLoader(true);
                  setActivePage(0);
                  setTimeout(() => {
                    getList(
                      field,
                      sortBy,
                      searchText,
                      activePage + 1,
                      clientId
                    );
                  }, 500);
                }}
                type="number"
                placeholder="Search by ID"
                className="fs-14 medium-text plr-10 form-control react-form-input  mr-10"
              />
            </div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              LoadingComponent={Loader}
              onFetchData={onChangePageGetData}
              onPageChange={pageIndex => {
                setActivePage(pageIndex);
                getList(field, sortBy, searchText, pageIndex + 1);
              }}
              onSortedChange={sortProperties => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getList(sortProperties[0].id, sort, searchText, 1);
                // alert("sort handler", sortProperties);
                // const orderByDirection = sortProperties[0].desc ? 1 : 0;

                // updateMapStateInfoOrderByColumn(sortProperties[0].id, orderByDirection);
              }}
            />
          </div>
          <CommonModel
            modal={modal}
            toggle={toggle}
            size="lg"
            children={
              <DateRangePicker
                open={open}
                toggle={() => toggle()}
                onChange={range => {
                  setActivePage(0);
                  setDateRange(range);
                  // setModal(!modal)
                  toggle();
                }}
                closeOnClickOutside={true}
              />
            }
            // header={editedData ? "" : "Add Registration Code"}
          />
        </div>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};

export default withRouter(MealDeliveryTable);
