import React, { useRef } from "react";
import { PLACEHOLDER } from "helper/constant";
import Button from "../button/Button";
import Toaster from "../common/Toaster";
import RdServices from "../../api/RdServices";
import EncryptDecrypt from "../../api/EncryptDecrypt";

const SubAdminViewDetails = props => {
  const adminDetails = props.location.state;
  const toaster = useRef();
  const welcomeEmail = () => {
    const params = {
      admin_id: adminDetails.id
    };

    RdServices.sendWelcomeMail(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current) toaster.current.success(data.message);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });
  };

  return (
    <div className="plr-15">
      <div className="roe-card-style mtb-15">
        <div className="roe-card-header module-header">
          <div className="flex-1 fs-16 demi-bold-text">
            <span className="hash"># </span> View Admin
          </div>
        </div>
        <div className="roe-card-body">
          <div className="pt-2">
            <div>
              <div style={{ fontWeight: "bolder", textAlign: "left" }}>
                Image
              </div>
              <img
                className="top-header-profile-class"
                style={{
                  height: "125px",
                  width: "125px",
                  marginBottom: "15px",
                  borderRadius: "10px",
                  marginTop: "10px"
                }}
                src={adminDetails.profile_image || PLACEHOLDER}
                alt="admin image"
              />
            </div>
          </div>
          <div className="pt-2">
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>
              First Name
            </div>
            <div>{adminDetails.first_name}</div>
          </div>
          <div className="pt-2">
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>
              Last Name
            </div>
            <div>{adminDetails.last_name}</div>
          </div>
          <div className="pt-2">
            <div>
              <div style={{ fontWeight: "bolder", textAlign: "left" }}>
                Email
              </div>
              <div>{adminDetails.email}</div>
            </div>
          </div>
          <div className="pt-2">
            <div>
              <div style={{ fontWeight: "bolder", textAlign: "left" }}>
                Role
              </div>
              <div>{adminDetails.admin_role.role}</div>
            </div>
          </div>
          {adminDetails.admin_role.id == 6 && (
            <div className="pt-2">
              <div>
                <div style={{ fontWeight: "bolder", textAlign: "left" }}>
                  Practice
                </div>
                <div>{adminDetails.practice_management.practice_name}</div>
              </div>
            </div>
          )}
          <div className="pt-2">
            <div>
              <div style={{ fontWeight: "bolder", textAlign: "left" }}>
                MFA QR Code
              </div>
              <img
                className="top-header-profile-class"
                style={{
                  height: "150px",
                  width: "150px",
                  marginBottom: "15px",
                  borderRadius: "10px",
                  marginTop: "10px"
                }}
                src={adminDetails.mfa_qr_code || PLACEHOLDER}
                alt="admin image"
              />
            </div>
          </div>
          <div className="pt-2">
            <div>
              <div style={{ fontWeight: "bolder", textAlign: "left" }}>
                Send Welcome Mail
              </div>
              <Button
                type="button"
                className="c-btn c-info form-button fs-16 demi-bold-text"
                style={{ maxWidth: "125px" }}
                onClick={() => welcomeEmail()}
              >
                Send
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};

export default SubAdminViewDetails;
