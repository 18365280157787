import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import ReportServices from "../../api/ReportServices";
import Loader from "../common/Loader";
import Toaster from "../common/Toaster";
import {
  APPOINTMENT_STATUS_LABEL,
  DEFAULT_RD_DROPDOWN,
  PROVIDER_TYPE,
  PROVIDER_TYPES,
  TABLE
} from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import moment from "moment-timezone";
import Button from "components/button/Button";
import CommonModel from "../common/CommonModel";
import { DateRangePicker } from "materialui-daterange-picker";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
import CategoryServices from "api/CategoryServices";
import ClientServices from "api/ClientServices";

const LateCancelNoShowTable = props => {
  const toaster = useRef(null);
  const [tblData, settblData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("id");
  const [sortBy, setSortBy] = useState("DESC");
  const [dateRange, setDateRange] = useState({});
  const [modal, setModal] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [serviceId, setServiceId] = useState(null);
  const [serviceName, setServiceName] = useState(null);
  const [serviceList, setServiceList] = useState([]);
  const [serviceDropdownOpen, setServiceDropdownOpen] = useState(false);
  const [statusId, setStatusId] = useState(null);
  const [statusName, setStatusName] = useState(null);
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [typeName, setTypeName] = useState(null);
  const [typeValue, setTypeValue] = useState(null);
  const [RDList, setRDList] = useState([]);
  const [RDId, setRDId] = useState(null);
  const [RDDropdownOpen, setRDDropdownOpen] = useState(false);
  const [RDName, setRDName] = useState(null);
  const [typeDropdownOpen, setTypeDropdownOpen] = useState(false);
  const [filteredRDList, setFilteredRDList] = useState([]);

  useEffect(() => {
    const params1 = {
      type: "",
      practice_id:
        props?.admin_role_id === 6 && props?.practice
          ? props?.practice
          : undefined,
      id: props.admin_role_id > 3 ? props?.service_id : undefined
    };

    ClientServices.RDList(params1).then(result => {
      let data = EncryptDecrypt.decrypt(result.data);
      setRDList(data.data);
    });
  }, []);

  useEffect(() => {
    let filtered = [...RDList];

    if (serviceId) {
      filtered = filtered.filter(rd => {
        return (
          rd?.rd_detail?.service_id === serviceId ||
          rd?.id === Number(DEFAULT_RD_DROPDOWN)
        );
      });
    }

    if (typeValue) {
      filtered = filtered.filter(rd => {
        return rd?.rd_detail?.type === typeValue;
      });
    }

    // if (!serviceId && !typeValue) filtered = [];

    setFilteredRDList([...filtered]);
  }, [serviceId, typeValue, RDList]);

  useEffect(() => {
    CategoryServices.serviceDropdown()
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          setServiceList([...data.data]);
        } else {
          if (toaster.current)
            toaster.current.error("Could not fetch services!");
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error("Could not fetch services!");
      });
  }, []);

  useEffect(() => {
    getList(field, sortBy, searchText, 1);
    setPages(10);
  }, [
    dateRange.startDate,
    dateRange.endDate,
    serviceId,
    statusId,
    RDId,
    typeValue
  ]);

  const toggleService = () => setServiceDropdownOpen(prevState => !prevState);

  const toggleStatus = () => setStatusDropdownOpen(prevState => !prevState);

  const toggleType = () => setTypeDropdownOpen(prevState => !prevState);

  const toggleRD = () => setRDDropdownOpen(prevState => !prevState);

  const columns = [
    {
      Header: "Client ID",
      accessor: "user.id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 150
    },
    {
      Header: "Client First Name",
      accessor: "user.first_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200
    },
    {
      Header: "Client Last Name",
      accessor: "user.last_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200
    },
    {
      Header: "Type",
      accessor: "type",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200,
      Cell: ({ original }) => (
        <div className="react-action-class">
          {original?.admin?.rd_detail?.type
            ? PROVIDER_TYPE[original?.admin?.rd_detail?.type]
            : ""}
        </div>
      )
    },
    {
      Header: "Provider",
      accessor: "provider",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200,
      Cell: props => (
        <div className="react-action-class">
          {props.original.admin
            ? props.original.admin.first_name +
              " " +
              props.original.admin.last_name
            : ""}
        </div>
      )
    },
    {
      Header: "Appointment Date",
      accessor: "appointment_date",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200,
      Cell: props =>
        moment(props?.original?.appointment_date).format("MM/DD/YYYY")
    },
    {
      Header: "Appointment Time",
      accessor: "appointment_time",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 200,
      Cell: props =>
        `${
          props.original?.appointment_type === "Manual"
            ? moment
                .tz(
                  moment(
                    props?.original?.appointment_date +
                      " " +
                      props?.original?.appointment_time
                  ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                  moment.tz.guess()
                )
                .format("h:mm A")
            : moment
                .tz(
                  props?.original?.event_detail?.start_time,
                  moment.tz.guess()
                )
                .format("h:mm A")
        } (${moment()
          .tz(moment.tz.guess())
          .zoneAbbr()})`
    },
    {
      Header: "Status",
      accessor: "status",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      width: 150,
      Cell: props => APPOINTMENT_STATUS_LABEL[props?.original?.status]
    }
  ];

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  function exportList() {
    const params = {
      start_date: dateRange.startDate
        ? moment(dateRange.startDate).format("YYYY-MM-DD")
        : null,
      end_date: dateRange.endDate
        ? moment(dateRange.endDate).format("YYYY-MM-DD")
        : null,
      service_id: serviceId,
      status: statusId,
      rd_id:
        props?.admin_role_id && props?.admin_role_id !== 3 ? RDId : props?.id,
      rd_type: typeValue
    };
    setIsExporting(true);
    ReportServices.lateCancelNoShowListCsv(params)
      .then(res => {
        setIsExporting(false);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error("Something went wrong!");
        setIsExporting(false);
      });
  }

  const formAction = (action, data = null) => {
    // Here you can view the data and make forward action for edit data
    if (action === "export") {
      exportList();
    }
  };

  function getList(field = "name", sortBy = "DESC", search = "", page = "1") {
    const params = {
      sort_by: field,
      order: sortBy,
      search: search,
      page: page,
      start_date: dateRange.startDate
        ? moment(dateRange.startDate).format("YYYY-MM-DD")
        : null,
      end_date: dateRange.endDate
        ? moment(dateRange.endDate).format("YYYY-MM-DD")
        : null,
      service_id: serviceId,
      status: statusId,
      rd_id:
        props?.admin_role_id && props?.admin_role_id !== 3 ? RDId : props?.id,
      rd_type: typeValue
    };
    setLoader(true);
    ReportServices.lateCancelNoShowReportList(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count / TABLE.LIMIT));
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
        setLoader(false);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.message);
        setLoader(false);
      });
  }

  const toggle = () => {
    setOpen(!open);
    setModal(!open);
  };

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Late Cancel/No Show Report
            </div>
            <div>
              <Button
                className="c-btn c-primary ma-5"
                onClick={() => formAction("export")}
                disabled={isExporting || !tblData?.length}
              >
                <i className="fas fa-download mr-10" />
                {!isExporting ? "Export" : "Exporting..."}
              </Button>
            </div>
          </div>
          <div className="roe-card-header module-header flex-wrap rgap">
            <div className="d-flex">
              <div className="mr-10">
                <Button
                  className="btn btn-secondary btn-md"
                  onClick={() => toggle()}
                >
                  {dateRange.startDate
                    ? `${moment(dateRange.startDate).format(
                        "MM/DD/YYYY"
                      )} - ${moment(dateRange.endDate).format("MM/DD/YYYY")}`
                    : "Select Date"}
                </Button>
                {dateRange && dateRange.startDate && (
                  <Button
                    className="btn btn-secondary btn-md ml-2"
                    onClick={() => {
                      setActivePage(0);
                      setDateRange({});
                    }}
                  >
                    Reset date
                  </Button>
                )}
              </div>
            </div>
            <div className="fs-14  mr-10">
              {/* <span className="fs-16">Service</span> */}
              <Dropdown isOpen={serviceDropdownOpen} toggle={toggleService}>
                <DropdownToggle caret size="md">
                  {serviceId ? serviceName : "Service"}
                </DropdownToggle>
                <DropdownMenu
                  modifiers={{
                    setMaxHeight: {
                      enabled: true,
                      order: 890,
                      fn: data => {
                        return {
                          ...data,
                          styles: {
                            ...data.styles,
                            overflow: "auto",
                            maxHeight: "300px"
                          }
                        };
                      }
                    }
                  }}
                >
                  <DropdownItem
                    key="-1"
                    onClick={() => {
                      setActivePage(0);
                      setServiceId("");
                      setServiceName("All");
                      setRDId("");
                      setRDName("");
                    }}
                  >
                    All
                  </DropdownItem>
                  {serviceList.length > 0 &&
                    serviceList.map((result, i) => {
                      return (
                        <DropdownItem
                          key={i}
                          onClick={() => {
                            setActivePage(0);
                            setServiceId(result.id);
                            setServiceName(`${result.name}`);
                            setRDId("");
                            setRDName("");
                          }}
                        >
                          {result.name}
                        </DropdownItem>
                      );
                    })}
                </DropdownMenu>
              </Dropdown>
            </div>

            <div className="fs-14  mr-10">
              {/* <span className="fs-16">Status</span> */}
              <Dropdown isOpen={statusDropdownOpen} toggle={toggleStatus}>
                <DropdownToggle caret size="md">
                  {statusId ? statusName : "Status"}
                </DropdownToggle>
                <DropdownMenu
                  modifiers={{
                    setMaxHeight: {
                      enabled: true,
                      order: 890,
                      fn: data => {
                        return {
                          ...data,
                          styles: {
                            ...data.styles,
                            overflow: "auto",
                            maxHeight: "300px"
                          }
                        };
                      }
                    }
                  }}
                >
                  <DropdownItem
                    key="0"
                    onClick={() => {
                      setActivePage(0);
                      setStatusId(null);
                      setStatusName("All");
                    }}
                  >
                    All
                  </DropdownItem>

                  <DropdownItem
                    key="1"
                    onClick={() => {
                      setActivePage(0);
                      setStatusId("noshow");
                      setStatusName("No Show");
                    }}
                  >
                    No Show
                  </DropdownItem>
                  <DropdownItem
                    key="2"
                    onClick={() => {
                      setActivePage(0);
                      setStatusId("latecancel");
                      setStatusName("Late Cancel");
                    }}
                  >
                    Late Cancel
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            {props.admin_role_id !== 3 && props?.admin_role_id !== 6 && (
              <div className="fs-14 mr-10">
                <Dropdown isOpen={typeDropdownOpen} toggle={toggleType}>
                  <DropdownToggle caret size="md">
                    {typeValue ? typeName : "Type"}
                  </DropdownToggle>
                  <DropdownMenu
                    modifiers={{
                      setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: data => {
                          return {
                            ...data,
                            styles: {
                              ...data.styles,
                              overflow: "auto",
                              maxHeight: "300px"
                            }
                          };
                        }
                      }
                    }}
                  >
                    <DropdownItem
                      key="-1"
                      onClick={() => {
                        setRDId(null);
                        setRDName(null);
                        setTypeName("All");
                        setTypeValue("");
                        setActivePage(0);
                      }}
                    >
                      All
                    </DropdownItem>
                    {PROVIDER_TYPES.map((result, i) => {
                      return (
                        <DropdownItem
                          key={i}
                          onClick={() => {
                            setRDId(null);
                            setRDName(null);
                            setTypeName(`${result.name}`);
                            setTypeValue(`${result.value}`);
                            setActivePage(0);
                          }}
                        >
                          {result.name}
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
              </div>
            )}
            {props.admin_role_id !== 3 && (
              <div className="fs-14  mr-10">
                <Dropdown isOpen={RDDropdownOpen} toggle={toggleRD}>
                  <DropdownToggle caret size="md">
                    {RDId ? RDName : "Providers"}
                  </DropdownToggle>
                  <DropdownMenu
                    modifiers={{
                      setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: data => {
                          return {
                            ...data,
                            styles: {
                              ...data.styles,
                              overflow: "auto",
                              maxHeight: "300px"
                            }
                          };
                        }
                      }
                    }}
                  >
                    <DropdownItem
                      key="-1"
                      onClick={() => {
                        setActivePage(0);
                        setRDId("");
                        setRDName("All");
                      }}
                    >
                      All
                    </DropdownItem>

                    {filteredRDList.length > 0 &&
                      filteredRDList.map((result, i) => {
                        return (
                          <DropdownItem
                            key={i + 1}
                            onClick={() => {
                              setActivePage(0);
                              setRDId(result.id);
                              setRDName(
                                `${result.first_name} ${result.last_name}`
                              );
                            }}
                          >
                            {result.first_name} {result.last_name}{" "}
                            {result?.rd_detail?.type
                              ? `(${PROVIDER_TYPE[result?.rd_detail?.type]})`
                              : "(-)"}
                          </DropdownItem>
                        );
                      })}
                  </DropdownMenu>
                </Dropdown>
              </div>
            )}
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              LoadingComponent={Loader}
              onFetchData={onChangePageGetData}
              onPageChange={pageIndex => {
                setActivePage(pageIndex);
                getList(field, sortBy, searchText, pageIndex + 1);
              }}
              onSortedChange={sortProperties => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getList(sortProperties[0].id, sort, searchText, 1);
                // alert("sort handler", sortProperties);
                // const orderByDirection = sortProperties[0].desc ? 1 : 0;

                // updateMapStateInfoOrderByColumn(sortProperties[0].id, orderByDirection);
              }}
            />
          </div>
          <CommonModel
            modal={modal}
            toggle={toggle}
            size="lg"
            children={
              <DateRangePicker
                open={open}
                toggle={() => toggle()}
                onChange={range => {
                  setActivePage(0);
                  setDateRange(range);
                  // setModal(!modal)
                  toggle();
                }}
                closeOnClickOutside={true}
              />
            }
            // header={editedData ? "" : "Add Registration Code"}
          />
        </div>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};

export default withRouter(LateCancelNoShowTable);
