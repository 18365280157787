import React, { useEffect, useRef, useState } from "react";
import enhancer from "../userForms/validator";
import { compose } from "redux";
import PresentingForm from "./PresentingForm";
import PhqForm from "./PhqForm";
import GadForm from "./GadForm";
import ClientManagementForm from "api/ClientMangementForm";
import EncryptDecrypt from "api/EncryptDecrypt";
import Loader from "components/common/Loader";
import Toaster from "components/common/Toaster";
import moment from "moment";
import AdultLifestyle from "./AdultLifestyle";
import { ADULT_LIFESTYLE_FORM_ID } from "helper/constant";

const STATIC_FORMS = [
  "HIPAA Consent",
  "Terms & Conditions",
  "Privacy Policy",
  "Husk Payment Policy"
];

const FormData = props => {
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(false);
  const toaster = useRef();

  useEffect(() => {
    setLoader(true);
    if (props?.details && STATIC_FORMS.includes(props?.details?.formName)) {
      const params = {
        id: props?.details?.staticPageId
      };
      ClientManagementForm.getStaticPage(params)
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);

          setFormData(data.data);
          setLoader(false);
        })
        .catch(err => {
          if (toaster.current) toaster.current.error("Something went wrong");
          setLoader(false);
          setTimeout(() => {
            props.toggle();
          }, 500);
        });
    } else {
      const params = {
        form_id: props.formId
      };
      ClientManagementForm.formData(params)
        .then(res => {
          let data = EncryptDecrypt.decrypt(res.data);
          setFormData(data);
          setLoader(false);
        })
        .catch(err => {
          if (toaster.current) toaster.current.error("Something went wrong");
          setLoader(false);
          setTimeout(() => {
            props.toggle();
          }, 500);
        });
    }
  }, []);

  return (
    <div>
      <form className="pa-24">
        <div className="form-group">
          <div className="roe-card-body pos-relative">
            <div
              className="flex-y cursor-pointer space-between pos-absolute"
              style={{ right: 0 }}
              role="button"
              onClick={props.toggle}
            >
              <i className="fas fa-times"></i>
            </div>

            <div className="form-group ">
              {loader ? (
                <Loader loading={loader} />
              ) : props?.details &&
                STATIC_FORMS.includes(props?.details?.formName) ? (
                <div className="roe-card-body">
                  <div className="pt-2">
                    <div>{formData.title}</div>
                  </div>
                  <div className="pt-2">
                    <div
                      className="text-left"
                      dangerouslySetInnerHTML={{ __html: formData.content }}
                    />
                  </div>
                  <div className="pt-4 font-weight-bold fs-18">
                    Name : {props?.details?.userName}
                  </div>

                  <div className="pt-2 font-weight-bold fs-18">
                    Date :{" "}
                    {props?.details?.submittedDate
                      ? moment(props?.details?.submittedDate).format(
                          "MM/DD/YYYY HH:mm"
                        )
                      : "-"}
                  </div>
                </div>
              ) : formData?.data?.form?.id === 1 ? (
                <PresentingForm
                  data={formData?.data?.user_form_presenting_problem}
                />
              ) : formData?.data?.form?.id === 2 ? (
                <GadForm data={formData?.data?.user_form_gad7} />
              ) : formData?.data?.form?.id === 3 ? (
                <PhqForm data={formData?.data?.user_form_phq9} />
              ) : formData?.data?.form?.id ===
                Number(ADULT_LIFESTYLE_FORM_ID) ? (
                <AdultLifestyle data={formData?.data?.user_form_lifestyle} />
              ) : null}
            </div>
          </div>
        </div>
      </form>
      <Toaster ref={toaster} />
    </div>
  );
};

export default compose(enhancer)(FormData);
