import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    note: Yup.string().required("This field is required")
  }),
  mapPropsToValues: props => ({
    note:
      props.editedData && props.editedData.type == "edit"
        ? props.editedData.note
        : "",
    id:
      props.editedData && props.editedData.type == "edit"
        ? props.editedData.id
        : ""
  }),

  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true,
  isInitialValid: props => (props.editedData.type === "edit" ? true : false)
});

export default formikEnhancer;
