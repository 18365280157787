import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import ClientServices from "../../api/ClientServices";
import Loader from "../common/Loader";
import Toaster from "../common/Toaster";
import { MODULE_NAMES, PERMISSION_TYPE, TABLE } from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import CommonModel from "../common/CommonModel";
import DeleteModal from "../common/DeleteModal";
import moment from "moment";
import AddNote from "./AddNote";
import "./note.css";
import { hasAccess } from "util/sidebar";

const UserNote = props => {
  const { permissions } = props;
  const [tblData, settblData] = useState();
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [field, setField] = useState("created_at");
  const [sortBy, setSortBy] = useState("DESC");
  const [modal, setModal] = useState(false);
  const [id, setId] = useState(undefined);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editedData, setEditedData] = useState(undefined);
  const [searchText, setSearchText] = useState(undefined);

  const toaster = useRef();

  useEffect(() => {
    if (
      hasAccess(MODULE_NAMES.CLIENT_NOTES, PERMISSION_TYPE.VIEW, permissions)
    ) {
      getList(field, sortBy, searchText, activePage + 1);
      setPages(10);
    }
  }, []);

  function toggle() {
    setModal(!modal);
  }

  const deleteClick = data => {
    setId(data.id);
    setDeleteModal(true);
  };

  function deleteToggle() {
    setDeleteModal(false);
  }

  function deleteHandler() {
    setLoader(true);
    const params = {
      id: id
    };
    ClientServices.deleteNote(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current) toaster.current.success(data.message);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
        setLoader(false);
        setDeleteModal(false);
        setTimeout(() => {
          getList(field, sortBy, searchText, activePage + 1);
        }, 1000);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
        setLoader(false);
        setDeleteModal(false);
      });
  }

  const columns = [
    {
      Header: "Date Created",
      accessor: "created_at",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      Cell: props =>
        moment(props.original.created_at).format("MM/DD/YYYY HH:mm"),
      width: 180,
      sortable: true
    },
    {
      Header: "Author",
      accessor: "admin.first_name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 250,
      sortable: true,
      Cell: props =>
        props?.original?.admin
          ? props.original?.admin?.first_name +
            " " +
            props.original?.admin?.last_name
          : ""
    },
    {
      Header: "Note",
      accessor: "note",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      sortable: true
    },
    {
      Header: "Action",
      accessor: "",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 300,
      sortable: false,
      Cell: props => {
        return (
          <>
            {hasAccess(
              MODULE_NAMES.CLIENT_NOTES,
              PERMISSION_TYPE.EDIT,
              permissions
            ) ? (
              <Button
                className="c-btn c-success mr-10"
                onClick={() => {
                  setModal(!modal);
                  setEditedData({ type: "edit", ...props.original });
                }}
              >
                <div className="fs-14 medium-text">
                  <i className="fas fa-edit" />
                </div>
              </Button>
            ) : null}
            {hasAccess(
              MODULE_NAMES.CLIENT_NOTES,
              PERMISSION_TYPE.DELETE,
              permissions
            ) ? (
              <Button
                className="c-btn c-danger"
                onClick={() => deleteClick(props.original)}
              >
                <div className="fs-14 medium-text">
                  <i className="fas fa-trash" />
                </div>
              </Button>
            ) : null}
          </>
        );
      }
    }
  ];

  const handleSubmit = () => {
    getList(field, sortBy, searchText, activePage + 1);
    setPages(10);
  };

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  function getList(
    field = "created_at",
    sortBy = "DESC",
    search = "",
    page = "1"
  ) {
    const hasParam = props.match.params.id;
    const params = {
      field: field,
      order: sortBy,
      search: search,
      page: page,
      user_id: hasParam
    };

    setLoader(true);
    ClientServices.listNote(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count / TABLE.LIMIT));
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (err?.response?.status === 403) {
          if (toaster.current) {
            toaster.current.error("You cannot access this user info!");
            setTimeout(() => {
              props.history.push("/client");
            }, 3000);
          }
        } else {
          if (toaster.current) toaster.current.error(err?.message);
        }
      });
  }

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span>Notes
            </div>
            <div className="d-flex">
              <div>
                {hasAccess(
                  MODULE_NAMES.CLIENT_NOTES,
                  PERMISSION_TYPE.ADD,
                  permissions
                ) ? (
                  <Button
                    className="c-btn c-primary ma-5"
                    onClick={() => {
                      setModal(!modal);
                      setEditedData({ type: "add" });
                    }}
                  >
                    <i className="fas fa-plus mr-10" />
                    Add Note
                  </Button>
                ) : null}
              </div>
              {/* )} */}
            </div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;
                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class ReactTable1"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              LoadingComponent={Loader}
              onFetchData={onChangePageGetData}
              onPageChange={pageIndex => {
                setActivePage(pageIndex);
                getList(field, sortBy, searchText, pageIndex + 1);
              }}
              onSortedChange={sortProperties => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getList(sortProperties[0].id, sort, searchText, 1);
              }}
            />
          </div>
        </div>
      </div>
      <Toaster ref={toaster} />
      <CommonModel
        modal={deleteModal}
        toggle={deleteToggle}
        children={
          <DeleteModal
            text={"Are you sure you want to delete this note?"}
            deleteHandler={deleteHandler}
            toggle={deleteToggle}
          />
        }
      />
      {modal && (
        <CommonModel
          modal={modal}
          toggle={toggle}
          onSubmit={handleSubmit}
          size="lg"
          children={
            <AddNote
              onSubmit={handleSubmit}
              editedData={editedData}
              toggle={toggle}
              pageRefresh={() => {
                getList(field, sortBy, searchText, activePage + 1);
              }}
              {...props}
            />
          }
          // header={editedData ? "" : "Add Registration Code"}
        />
      )}
    </div>
  );
};

export default withRouter(UserNote);
