import React, { useRef, useState } from "react";
import { compose } from "redux";
import enhancer from "./validator";
import Button from "../button/Button";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import Toaster from "../common/Toaster";
import ServiceMangementServices from "api/ServiceManagementServices";

const ServiceForm = props => {
  const {
    handleChange,
    handleBlur,
    errors,
    setFieldTouched,
    setFieldValue,
    touched,
    submitCount,
    values,
    action,
    history,
    handleSubmit
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };
  const toaster = useRef();

  const handleFormSubmit = () => {
    let { values, isValid, handleSubmit } = props;
    setIsLoading(true);

    if (isValid) {
      if (action === "add") {
        const params = {
          name: values.name
        };
        ServiceMangementServices.add(params)
          .then(res => {
            let data = EncryptDecrypt.decrypt(res.data);
            if (data.status) {
              if (toaster.current) toaster.current.success(data.message);
              setTimeout(() => {
                history.push("/service");
              }, 1500);
              setIsLoading(false);
            } else {
              if (toaster.current) toaster.current.error(data.message);
              setIsLoading(false);
            }
          })
          .catch(err => {
            if (toaster.current) toaster.current.error(err.response);
            setIsLoading(false);
          });
      } else if (action === "edit") {
        const params = {
          service_id: values.id,
          name: values.name
        };
        ServiceMangementServices.edit(params)
          .then(res => {
            let data = EncryptDecrypt.decrypt(res.data);
            if (data.status === 1) {
              if (toaster.current) toaster.current.success(data.message);
              setTimeout(() => {
                history.push("/service");
              }, 1500);
              setIsLoading(false);
            } else {
              if (toaster.current) toaster.current.error(data.message);
              setIsLoading(false);
            }
          })
          .catch(err => {
            if (toaster.current) toaster.current.error(err.response);
            setIsLoading(false);
          });
      }
    } else {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Name <span className="asterisk">*</span>
          </label>
          <input
            type="text"
            className="form-control react-form-input"
            id="name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            placeholder="Service Name"
          />
          <Error field="name" />
        </div>

        <div>
          <Button
            type="submit"
            className="c-btn c-info form-button fs-16 demi-bold-text"
            style={{ maxWidth: "125px" }}
            onClick={handleFormSubmit}
            loading={isLoading}
            disabled={isLoading}
            dataStyle={"expand-right"}
          >
            Submit
          </Button>
        </div>
      </form>
      <Toaster ref={toaster} />
    </div>
  );
};

export default compose(enhancer)(ServiceForm);
