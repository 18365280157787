import axios from "axios";
import { baseUrl } from "../helper/constant";
import Header from "./Header";
import EncryptDecrypt from "./EncryptDecrypt";

export default class FaxManagementServices {
  static list(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/fax/list-fax`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static add(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/fax/add-fax`, EncryptDecrypt.encrypt(params));
  }

  static edit(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/fax/edit-fax`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static view(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/fax/view-fax`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static delete(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/fax/delete-fax`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static adminList(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/sub-admin/list-admin`,
      EncryptDecrypt.encrypt(params)
    );
  }
}
