import axios from "axios";
import { baseUrl } from "../helper/constant";
import Header from "./Header";
import EncryptDecrypt from "./EncryptDecrypt";
import FileDownload from "js-file-download";

export default class ClientServices {
  static listClient(params, cT) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/client/list-client`,
      EncryptDecrypt.encrypt(params),
      cT
    );
  }

  static view(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/client/view-client`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static edit(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/client/edit-client`, params);
  }

  static add(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/client/add-client`, params);
  }

  static groupList(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/client/group-list`, params);
  }

  static RDList(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/client/rd-list`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static viewClient(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/client/view-client`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static deleteClient(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/client/delete-client`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static resendOTP(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/client/send-email`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static assignRd(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/client/assign-rd`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static programDetails(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/client/program-details`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static listGroup(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/client/list-group`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static viewGroup(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/client/view-group`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static listVisitProgramByGroup(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/client/list-visit-program-by-group`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static changeProgram(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/client/edit-program-detail`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static listRegistrationCodeByGroup(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/client/list-registration-code-by-group`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static listNote(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/client/list-note`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static addNote(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/client/add-note`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static editNote(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/client/edit-note`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static deleteNote(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/client/delete-note`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static listDocument(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/client/list-document`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static deleteDocument(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/client/delete-document`,
      EncryptDecrypt.encrypt(params)
    );
  }
  static addDocument(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/client/add-document`, params);
  }

  static editDocument(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/client/edit-document`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static sendInvite(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/client/invite-client`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static listRegistrationCodeByRD(params) {
    Header.setHeaders();
    return axios.get(
      `${baseUrl}/client/list-registration-code-by-rd`,
      EncryptDecrypt.encrypt(params, 1)
    );
  }

  static getDiscoveryQueue(params) {
    Header.setHeaders();
    return axios.post(
      `${baseUrl}/client/list-discovery-queue-client`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static editDiscoveryQueueNote(params) {
    Header.setHeaders();
    return axios.put(
      `${baseUrl}/client/edit-discovery-note`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static editDiscoveryQueueUserStatus(params) {
    Header.setHeaders();
    return axios.put(
      `${baseUrl}/client/status-discovery-client`,
      EncryptDecrypt.encrypt(params)
    );
  }

  static exportDiscoveryQueue(params, isArchieved = false) {
    Header.setHeaders();
    return axios
      .post(
        `${baseUrl}/client/discovery-queue-client-csv`,
        EncryptDecrypt.encrypt(params)
      )
      .then(res => {
        FileDownload(
          res.data,
          `${!isArchieved ? "DiscoveryQueue" : "ArchivedDiscoveryQueue"}.csv`
        );
      });
  }
  static clientDetailListCsvExport(params) {
    Header.setHeaders();
    return axios
      .post(
        `${baseUrl}/client/list-not-assigned-client-csv`,
        EncryptDecrypt.encrypt(params)
      )
      .then(res => {
        FileDownload(res.data, "ClientDetail.csv");
      });
  }
}
