import React from "react";
import FooterWrapper from "./footer.style";
import moment from "moment-timezone";

const Footer = props => {
  return (
    <FooterWrapper {...props}>
      <div className="footerBack flex-x align-center">
        <div className="flex-1 fs-13 bold-text">
          ©{" "}
          {moment()
            .utc()
            .format("YYYY")}{" "}
          HUSK Nutrition, All rights reserved.
        </div>
      </div>
    </FooterWrapper>
  );
};

export default Footer;
