import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import CptCodeServices from "../../api/CptCodeServices";
import Loader from "../common/Loader";
import CommonModel from "../common/CommonModel";
import DeleteModal from "../common/DeleteModal";
import CustomModal from "../common/CustomModal";
import Toaster from "../common/Toaster";
import {
  TABLE,
  SYSTEMADMIN,
  MODULE_NAMES,
  PERMISSION_TYPE
} from "../../helper/constant";
import EncryptDecrypt from "../../api/EncryptDecrypt";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from "reactstrap";
import { CommonLookUpSelectionModal } from "components/common/CommonLookUpSelectionModal";
import { hasAccess } from "util/sidebar";

const CptCodeTable = props => {
  const { permissions } = props;
  const [tblData, settblData] = useState();
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [idText, setIdText] = useState("");
  const [statusText, setStatusText] = useState("");
  const [statusValue, setStatusValue] = useState("");
  const [field, setField] = useState("id");
  const [sortBy, setSortBy] = useState("ASC");
  const [id, setId] = useState(undefined);
  const [modal, setModal] = useState(undefined);
  const [status, setStatus] = useState(undefined);
  const [customModal, setCustomModal] = useState(undefined);
  const [parentDropdownOpen, setParentDropdownOpen] = useState(false);
  const [isLookup, setIsLookup] = useState(false);
  const [loopkupModal, setLookupModal] = useState(false);

  const toaster = useRef();

  const toggleOne = () => setParentDropdownOpen(prevState => !prevState);

  useEffect(() => {
    getList(field, sortBy, searchText, activePage + 1, statusValue, idText);
    setPages(10);
  }, []);

  const deleteClick = data => {
    setId(data.id);
    setModal(true);
  };

  function deleteHandler() {
    setLoader(true);
    const params = {
      id: id
    };
    CptCodeServices.delete(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current) toaster.current.success(data.message);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
        setLoader(false);
        setModal(false);
        setTimeout(() => {
          getList(
            field,
            sortBy,
            searchText,
            activePage + 1,
            statusValue,
            idText
          );
        }, 1000);
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });
  }

  function toggle() {
    setModal(false);
  }

  const activeDeactive = data => {
    setId(data.id);
    setStatus(data.is_active);
    setCustomModal(true);
  };

  function activeToggle() {
    setCustomModal(false);
  }
  const activeInactiveStatusHandler = oData => {
    let statusData = status;
    const params = {
      is_active: !statusData,
      id: id
    };
    setLoader(true);
    CptCodeServices.status(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          if (toaster.current)
            toaster.current.success(
              statusData === true
                ? "Cpt Code has been deactivated successfully."
                : "Cpt Code has been activated successfully."
            );
          setCustomModal(false);
          getList(
            field,
            sortBy,
            searchText,
            activePage + 1,
            statusValue,
            idText
          );
          setLoader(false);
        } else {
          setCustomModal(false);
          if (toaster.current) toaster.current.error(data.message);
          setLoader(false);
        }
      })
      .catch(err => {
        setCustomModal(false);
        if (toaster.current) toaster.current.error(err.response);
        setLoader(false);
      });
  };

  const toggleLookup = () => {
    setLookupModal(prev => !prev);
  };

  const columns = [
    {
      Header: "ID",
      accessor: "id",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 100,
      sortable: true
    },
    {
      Header: "Name",
      accessor: "name",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 300,
      sortable: true
    },
    {
      Header: "Code",
      accessor: "code",
      className: "",
      filterable: false,
      headerClassName: "react-table-header-class sort-sign",
      width: 300,
      sortable: true
    },
    {
      Header: "Status",
      accessor: "is_active",
      className: "",
      headerClassName: "react-table-header-class sort-sign",
      sortable: true,
      filterable: false,
      width: 200,
      Cell: props => (
        <div className="react-action-class">
          {props.original.is_active ? (
            <div id={`active-${props.original.state_id}`}>
              <Button
                style={{ minWidth: "125px" }}
                className="c-btn c-info mr-10"
                onClick={() => activeDeactive(props.original)}
                disabled={
                  !hasAccess(
                    MODULE_NAMES.CPT,
                    PERMISSION_TYPE.STATUS,
                    permissions
                  )
                }
              >
                <div className="fs-14 medium-text">
                  <i className="fas fa-toggle-off mr-6" /> Active
                </div>
              </Button>
            </div>
          ) : (
            <div id={`deactive-${props.original.state_id}`}>
              <Button
                style={{ minWidth: "125px" }}
                className="c-btn c-warning mr-10"
                onClick={() => activeDeactive(props.original)}
                disabled={
                  !hasAccess(
                    MODULE_NAMES.CPT,
                    PERMISSION_TYPE.STATUS,
                    permissions
                  )
                }
              >
                <div className="fs-14 medium-text">
                  <i className="fas fa-toggle-on mr-6" /> Inactive
                </div>
              </Button>
            </div>
          )}
        </div>
      ) // Custom cell components!
    },
    {
      Header: "Action",
      accessor: "action",
      className: "",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 150,
      Cell: colProps => (
        <div className="react-action-class">
          {hasAccess(MODULE_NAMES.CPT, PERMISSION_TYPE.VIEW, permissions) ? (
            <Button
              className="c-btn c-info mr-10"
              onClick={() => formAction("view", colProps.original)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-eye" />
              </div>
            </Button>
          ) : null}
          {hasAccess(MODULE_NAMES.CPT, PERMISSION_TYPE.EDIT, permissions) ? (
            <Button
              className="c-btn c-success mr-10"
              onClick={() => formAction("edit", colProps.original)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-edit" />
              </div>
            </Button>
          ) : null}
          {hasAccess(MODULE_NAMES.CPT, PERMISSION_TYPE.DELETE, permissions) ? (
            <Button
              className="c-btn c-danger"
              onClick={() => deleteClick(colProps.original)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-trash" />
              </div>
            </Button>
          ) : (
            ""
          )}
        </div>
      ) // Custom cell components!
    }
  ];

  const onChangePageGetData = state => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  const formAction = (action, data = null) => {
    // Here you can view the data and make forward action for edit data
    if (action === "add") {
      props.history.push("/cpt-code/add");
    } else if (action === "edit") {
      props.history.push(`/cpt-code/edit/${data.id}`);
    } else if (action === "view") {
      props.history.push(`/cpt-code/view/${data.id}`, { ...data });
    }
  };

  function getList(
    field = "created_at",
    sortBy = "DESC",
    search = "",
    page = "1",
    is_active = statusValue,
    idSearch = ""
  ) {
    const params = {
      sort_by: field,
      order: sortBy,
      search: search,
      page: page,
      is_active: is_active,
      id: idSearch
    };
    setLoader(true);
    CptCodeServices.list(params)
      .then(res => {
        let data = EncryptDecrypt.decrypt(res.data);
        if (data.status) {
          settblData(data.data.rows);
          setPages(Math.ceil(data.data.count / TABLE.LIMIT));
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(data.message);
        }
      })
      .catch(err => {
        if (toaster.current) toaster.current.error(err.response);
      });
  }

  const handleLookup = () => {
    setLookupModal(true);
  };

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> Cpt Code List
            </div>

            {hasAccess(MODULE_NAMES.CPT, PERMISSION_TYPE.ADD, permissions) ? (
              <div>
                <Button
                  className="c-btn c-primary ma-5"
                  onClick={() => formAction("add")}
                >
                  <i className="fas fa-plus mr-10" />
                  Add Cpt Code
                </Button>
              </div>
            ) : null}
            {hasAccess(
              MODULE_NAMES.CPT,
              PERMISSION_TYPE.LOOKUP,
              permissions
            ) ? (
              <div>
                <Button
                  className="c-btn c-primary ma-5"
                  onClick={() => handleLookup()}
                  disabled={isLookup}
                >
                  <i className="fas fa-bars mr-10" />
                  Lookup
                </Button>
              </div>
            ) : null}
          </div>
          <div className="roe-card-header module-header">
            <div className="d-flex">
              <div className="mr-10">
                <span className="fs-16">ID</span>
                <input
                  value={idText}
                  onChange={e => {
                    setIdText(e.target.value);
                    const idSearch = e.target.value;
                    setLoader(true);
                    setActivePage(0);
                    setTimeout(() => {
                      getList(
                        field,
                        sortBy,
                        searchText,
                        1,
                        statusValue,
                        idSearch
                      );
                    }, 500);
                  }}
                  type="text"
                  placeholder="Search By ID"
                  className="fs-14 medium-text plr-10 form-control react-form-input"
                />
              </div>
              <div className="mr-10">
                <span className="fs-16">Name</span>
                <input
                  value={searchText}
                  onChange={e => {
                    setSearchText(e.target.value);
                    const search = e.target.value;
                    setLoader(true);
                    setActivePage(0);
                    setTimeout(() => {
                      getList(field, sortBy, search, 1, statusValue, idText);
                    }, 500);
                  }}
                  type="text"
                  placeholder="Search By Name"
                  className="fs-14 medium-text plr-10 form-control react-form-input"
                />
              </div>
              <div className="fs-16 mr-10">
                <span className="fs-16">Status</span>
                <Dropdown isOpen={parentDropdownOpen} toggle={toggleOne}>
                  <DropdownToggle caret size="md">
                    {statusText ? statusText : "All"}
                  </DropdownToggle>
                  <DropdownMenu
                    modifiers={{
                      setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: data => {
                          return {
                            ...data,
                            styles: {
                              ...data.styles,
                              overflow: "auto",
                              maxHeight: "300px"
                            }
                          };
                        }
                      }
                    }}
                  >
                    <DropdownItem
                      key="0"
                      onClick={() => {
                        setStatusText("");
                        setStatusValue("");
                        setLoader(true);
                        setActivePage(0);
                        setTimeout(() => {
                          getList(field, sortBy, searchText, 1, "", idText);
                        }, 500);
                      }}
                    >
                      All
                    </DropdownItem>
                    <DropdownItem
                      key="Active"
                      onClick={() => {
                        setStatusText("Active");
                        setStatusValue("1");
                        setLoader(true);
                        setActivePage(0);
                        setTimeout(() => {
                          getList(field, sortBy, searchText, 1, "1", idText);
                        }, 500);
                      }}
                    >
                      Active
                    </DropdownItem>
                    <DropdownItem
                      key="Inactive"
                      onClick={() => {
                        setStatusText("Inactive");
                        setStatusValue("0");
                        setLoader(true);
                        setActivePage(0);
                        setTimeout(() => {
                          getList(field, sortBy, searchText, 1, "0", idText);
                        }, 500);
                      }}
                    >
                      Inactive
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              LoadingComponent={Loader}
              onFetchData={onChangePageGetData}
              onPageChange={pageIndex => {
                setActivePage(pageIndex);
                getList(
                  field,
                  sortBy,
                  searchText,
                  pageIndex + 1,
                  statusValue,
                  idText
                );
              }}
              onSortedChange={sortProperties => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getList(
                  sortProperties[0].id,
                  sort,
                  searchText,
                  1,
                  statusValue,
                  idText
                );
                // alert("sort handler", sortProperties);
                // const orderByDirection = sortProperties[0].desc ? 1 : 0;

                // updateMapStateInfoOrderByColumn(sortProperties[0].id, orderByDirection);
              }}
            />
          </div>
          <CommonModel
            modal={modal}
            toggle={toggle}
            children={
              <DeleteModal
                text={"Are you sure you want to delete this cpt code?"}
                deleteHandler={deleteHandler}
                toggle={toggle}
              />
            }
          />
          <CommonModel
            modal={customModal}
            toggle={activeToggle}
            children={
              <CustomModal
                text={`${
                  status === false
                    ? "Are you sure you want to activate cpt code?"
                    : "Are you sure you want to deactivate cpt code?"
                }`}
                activeInactiveStatusHandler={activeInactiveStatusHandler}
                activeToggle={activeToggle}
              />
            }
          />
          <CommonModel
            modal={loopkupModal}
            toggle={toggleLookup}
            size={"xl"}
            children={
              <CommonLookUpSelectionModal
                onClose={toggleLookup}
                type={"CPT"}
                authData={props?.authData}
              />
            }
          />
        </div>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};

export default withRouter(CptCodeTable);
